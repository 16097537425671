$s-xxxs: 2px;
$s-xxs: 4px;
$s-xs: 8px;
$s-s: 16px;
$s-m: 24px;
$s-xm: 32px;
$s-xxm: 40px;
$s-l: 48px;
$s-xl: 64px;
$s-xxl: 96px;
$s-xxxl: 128px;
$s-xxxxl: 144px;
