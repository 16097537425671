@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap);
.WindowTopBar_container__3KHKf {
  background: #eff2f5;
  height: 36px;
  width: 100vw;
  -webkit-app-region: drag;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  flex-direction: row;
  justify-content: center; }

.WindowTopBar_emptySpace__2QUM9 {
  height: 36px;
  width: 100vw; }

.WindowTopBar_toolbarActions__1zXmI {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  margin-left: 35px;
  width: 100%; }

.WindowTopBar_systemButtons__1G8F8 {
  margin-left: 0px;
  justify-content: flex-end;
  -webkit-user-select: none;
          user-select: none; }

.WindowTopBar_arrowIcon__b7bvd, .WindowTopBar_arrowLeft__X7726, .WindowTopBar_arrowRight__2A0lt, .WindowTopBar_reload__2wSeK {
  height: 22px;
  width: 22px;
  -webkit-app-region: no-drag;
  padding: 3px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 75%;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0);
  transition: 0.1 all ease-in-out; }
  .WindowTopBar_arrowIcon__b7bvd:hover, .WindowTopBar_arrowLeft__X7726:hover, .WindowTopBar_arrowRight__2A0lt:hover, .WindowTopBar_reload__2wSeK:hover {
    background-color: #dadada; }

.WindowTopBar_arrowLeft__X7726 {
  background-image: url(/static/media/left.85ccc2d7.svg);
  margin-right: 20px; }

.WindowTopBar_arrowRight__2A0lt {
  margin-right: 20px;
  background-image: url(/static/media/right.060110b1.svg); }

.WindowTopBar_reload__2wSeK {
  background-size: 60%;
  background-image: url(/static/media/reload.2b7e518f.svg); }

.WindowTopBar_systemAction__2EHKM {
  width: 50px;
  height: 100%;
  -webkit-app-region: no-drag;
  display: flex;
  justify-content: center;
  align-items: center; }
  .WindowTopBar_systemAction__2EHKM:hover {
    background: #cbcccd; }
  .WindowTopBar_systemAction__2EHKM:active {
    background: #979798; }

@media (-webkit-device-pixel-ratio: 1.5), (device-pixel-ratio: 1.5), (-webkit-device-pixel-ratio: 2), (device-pixel-ratio: 2), (-webkit-device-pixel-ratio: 3), (device-pixel-ratio: 3) {
  .WindowTopBar_systemAction__2EHKM .WindowTopBar_icon__1sdfo {
    width: 10px;
    height: 10px; } }

.WindowTopBar_close__pRQXg {
  transition: 0.2s all ease-in-out; }

.WindowTopBar_close__pRQXg:hover {
  background: #E81123 !important; }
  .WindowTopBar_close__pRQXg:hover .WindowTopBar_icon__1sdfo {
    -webkit-filter: invert(1);
            filter: invert(1); }

.WindowTopBar_close__pRQXg:active {
  background: #F1707A !important; }

.blocklySvg {
  background-color: #F2F4F6 !important;
  padding: 12px;
  border-radius: 18px 18px 0 0; }

.nProgressContainer {
  pointer-events: none; }

.nProgressWrapper {
  background: #29d;
  height: 2;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1031; }

.nProgressItem {
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  display: block;
  height: 100%;
  opacity: 1;
  position: absolute;
  right: 0;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
          transform: rotate(3deg) translate(0px, -4px);
  width: 100px; }

.loaderContainer {
  position: relative; }
  @media (min-width: 1300px) {
    .loaderContainer {
      height: calc(100vh - 3.38542vw); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .loaderContainer {
      height: calc(100vh - 3.38542vw); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .loaderContainer {
      height: calc(100vh - 3.38542vw); } }

.loaderContentContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin: auto;
  width: -webkit-max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

@media (min-width: 1300px) {
  .loaderGifContainer {
    height: 7.70833vw;
    width: 7.70833vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .loaderGifContainer {
    height: 8.56481vw;
    width: 8.56481vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .loaderGifContainer {
    height: 7.70833vw;
    width: 7.70833vw; } }

.loadingText {
  color: #403F3FB2; }
  @media (min-width: 1300px) {
    .loadingText {
      font-size: 1.25vw;
      margin-bottom: 1.77083vw;
      font-weight: 800; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .loadingText {
      font-size: 1.38889vw;
      margin-bottom: 1.96759vw;
      font-weight: 800; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .loadingText {
      font-size: 1.25vw;
      margin-bottom: 1.77083vw;
      font-weight: 800; } }

.animatedLoadingTextContainer {
  position: relative;
  width: 100vw; }

.animatedLoadingText {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: 0.2s all ease-in-out;
  color: #A8A7A7; }
  @media (min-width: 1300px) {
    .animatedLoadingText {
      font-size: 1.25vw;
      font-weight: 600; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .animatedLoadingText {
      font-size: 1.38889vw;
      font-weight: 600; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .animatedLoadingText {
      font-size: 1.25vw;
      font-weight: 600; } }

:root {
  --accent-color:$tekie-amethyst;
  --accent-color-110: $tekie-amethyst-dark; }

.successToaster {
  border-radius: 8px !important;
  background-image: linear-gradient(to bottom, #01ddb2, #16d977);
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: bold;
  font-size: 18px;
  min-width: 290px !important;
  min-height: 60px !important;
  margin: 8px; }

.errorToaster {
  border-radius: 8px !important;
  background-image: linear-gradient(to bottom, #ff5644, #ff5644 0%, #b52c00);
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: bold;
  font-size: 18px;
  min-width: 290px !important;
  min-height: 60px !important;
  margin: 8px; }

.loadingToaster {
  border-radius: 8px !important;
  background-image: linear-gradient(to bottom, #00ade6 -90%, #34e4ea 50%);
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: bold;
  font-size: 18px;
  min-width: 290px !important;
  min-height: 60px !important;
  margin: 8px; }

.Toastify__close-button.Toastify__close-button--success, .Toastify__close-button.Toastify__close-button--error,
.Toastify__close-button.Toastify__close-button--loading {
  border-radius: 50% !important;
  height: 20px !important;
  width: 20px !important;
  border: 2px solid #fff !important;
  font-size: 11px !important;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: bold !important;
  color: #fff;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  opacity: 1 !important; }

.teacher-app-theme {
  background: $tekie-amethyst;
  background: var(--accent-color);
  border-radius: 8px !important;
  letter-spacing: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: bold;
  font-size: 14px;
  min-width: 290px !important;
  min-height: 60px !important;
  margin: 8px; }

.MainSideBar_container__MLs-b {
  position: relative;
  z-index: 999999;
  box-sizing: border-box;
  height: 100vh;
  background: #ffffff; }
  @media (min-width: 1300px) {
    .MainSideBar_container__MLs-b {
      width: 17.70833vw;
      box-shadow: 0.10417vw 0vw 0.52083vw rgba(0, 0, 0, 0.08); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_container__MLs-b {
      width: 19.67593vw;
      box-shadow: 0.11574vw 0vw 0.5787vw rgba(0, 0, 0, 0.08); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_container__MLs-b {
      width: 17.70833vw;
      box-shadow: 0.10417vw 0vw 0.52083vw rgba(0, 0, 0, 0.08); } }

.MainSideBar_outSideNav__19uQ0 {
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 1300px) {
    .MainSideBar_outSideNav__19uQ0 {
      width: 6.25vw;
      height: calc(100vh - 3.38542vw); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_outSideNav__19uQ0 {
      width: 6.94444vw;
      height: calc(100vh - 3.38542vw); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_outSideNav__19uQ0 {
      width: 6.25vw;
      height: calc(100vh - 3.38542vw); } }

@media (min-width: 1000px) {
  .MainSideBar_outSideNavForTeacherApp___nBdH {
    height: calc(100vh - 5.10417vw); } }

@media (min-width: 900px) and (max-width: 1000px) {
  .MainSideBar_outSideNavForTeacherApp___nBdH {
    height: calc(100vh - 5.10417vw); } }

@media (min-width: 1001px) and (max-width: 1600px) {
  .MainSideBar_outSideNavForTeacherApp___nBdH {
    height: calc(100vh - 5.10417vw); } }

@media (min-width: 1601px) and (max-width: 2000px) {
  .MainSideBar_outSideNavForTeacherApp___nBdH {
    height: calc(100vh - 5.10417vw); } }

@media (min-width: 1000px) {
  .MainSideBar_outSideNavForTrainingApp__o97PN {
    height: 100vh; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .MainSideBar_outSideNavForTrainingApp__o97PN {
    height: 100vh; } }

@media (min-width: 1001px) and (max-width: 1600px) {
  .MainSideBar_outSideNavForTrainingApp__o97PN {
    height: 100vh; } }

@media (min-width: 1601px) and (max-width: 2000px) {
  .MainSideBar_outSideNavForTrainingApp__o97PN {
    height: 100vh; } }

.MainSideBar_sideBarTopPart__31zhx {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #FFFFFF;
  z-index: 9999999;
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .MainSideBar_sideBarTopPart__31zhx {
      margin-bottom: 1.66667vw;
      padding: 0 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_sideBarTopPart__31zhx {
      margin-bottom: 1.85185vw;
      padding: 0 0.92593vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_sideBarTopPart__31zhx {
      margin-bottom: 1.66667vw;
      padding: 0 0.83333vw; } }
  .MainSideBar_sideBarTopPart__31zhx .MainSideBar_flex__2L-uf {
    display: flex; }

.MainSideBar_backButton__26cIh {
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease; }
  @media (min-width: 1300px) {
    .MainSideBar_backButton__26cIh {
      width: 1.45833vw;
      height: 1.45833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_backButton__26cIh {
      width: 1.62037vw;
      height: 1.62037vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_backButton__26cIh {
      width: 1.45833vw;
      height: 1.45833vw; } }
  .MainSideBar_backButton__26cIh svg {
    transition: all 0.3s ease; }
  .MainSideBar_backButton__26cIh:hover > svg {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px); }

.MainSideBar_topicThumbnail__od4KX {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center; }
  @media (min-width: 1300px) {
    .MainSideBar_topicThumbnail__od4KX {
      width: 5.9375vw;
      height: 7.08333vw;
      margin-left: 3.59375vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_topicThumbnail__od4KX {
      width: 6.59722vw;
      height: 7.87037vw;
      margin-left: 3.99306vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_topicThumbnail__od4KX {
      width: 5.9375vw;
      height: 7.08333vw;
      margin-left: 3.59375vw; } }

.MainSideBar_topicTitle__1rsRx {
  text-align: center;
  font-weight: 700;
  color: #005773; }
  @media (min-width: 1300px) {
    .MainSideBar_topicTitle__1rsRx {
      font-size: 1.45833vw;
      line-height: 120%;
      margin-top: 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_topicTitle__1rsRx {
      font-size: 1.62037vw;
      line-height: 120%;
      margin-top: 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_topicTitle__1rsRx {
      font-size: 1.45833vw;
      line-height: 120%;
      margin-top: 0.41667vw; } }

.MainSideBar_sideBarNavContainer__2ZRLE {
  overflow-y: scroll;
  --accent-color: #808080;
  --accent-color-110: #808080;
  /* Define the styles for the scroll bar when it is in a focused state */ }
  @media (min-width: 1000px) {
    .MainSideBar_sideBarNavContainer__2ZRLE {
      max-height: calc(100vh - 32.29167vw); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_sideBarNavContainer__2ZRLE {
      max-height: calc(100vh - 32.29167vw); } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .MainSideBar_sideBarNavContainer__2ZRLE {
      max-height: calc(100vh - 32.29167vw); } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .MainSideBar_sideBarNavContainer__2ZRLE {
      max-height: calc(100vh - 32.29167vw); } }
  .MainSideBar_sideBarNavContainer__2ZRLE ::-webkit-scrollbar-track {
    background: linear-gradient(to bottom, #808080, #808080); }
  .MainSideBar_sideBarNavContainer__2ZRLE ::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom, #808080, #808080);
    border-radius: 5px; }
  .MainSideBar_sideBarNavContainer__2ZRLE ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(to bottom, #808080, #808080); }
  .MainSideBar_sideBarNavContainer__2ZRLE ::-webkit-scrollbar-thumb:focus {
    background: linear-gradient(to bottom, #808080, #808080); }

.MainSideBar_sideBarNavContainerUpdated__1fIVr {
  overflow-y: scroll;
  --accent-color: #808080;
  --accent-color-110: #808080;
  /* Define the styles for the scroll bar when it is in a focused state */ }
  @media (min-width: 1000px) {
    .MainSideBar_sideBarNavContainerUpdated__1fIVr {
      max-height: calc(100vh - 30.20833vw); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_sideBarNavContainerUpdated__1fIVr {
      max-height: calc(100vh - 30.20833vw); } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .MainSideBar_sideBarNavContainerUpdated__1fIVr {
      max-height: calc(100vh - 30.20833vw); } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .MainSideBar_sideBarNavContainerUpdated__1fIVr {
      max-height: calc(100vh - 30.20833vw); } }
  .MainSideBar_sideBarNavContainerUpdated__1fIVr ::-webkit-scrollbar-track {
    background: linear-gradient(to bottom, #808080, #808080); }
  .MainSideBar_sideBarNavContainerUpdated__1fIVr ::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom, #808080, #808080);
    border-radius: 5px; }
  .MainSideBar_sideBarNavContainerUpdated__1fIVr ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(to bottom, #808080, #808080); }
  .MainSideBar_sideBarNavContainerUpdated__1fIVr ::-webkit-scrollbar-thumb:focus {
    background: linear-gradient(to bottom, #808080, #808080); }

.MainSideBar_textContainer__2Rirg {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  @media (min-width: 1300px) {
    .MainSideBar_textContainer__2Rirg {
      width: 9.375vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_textContainer__2Rirg {
      width: 10.41667vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_textContainer__2Rirg {
      width: 9.375vw; } }

.MainSideBar_sideBarNavItem__W02qB, .MainSideBar_sideBarNavItemParent__39k75, .MainSideBar_sideBarNavItemChild__DVuDi {
  color: #504F4F;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  transition: 0.3s all ease-in-out;
  align-items: center;
  cursor: pointer; }
  .MainSideBar_sideBarNavItem__W02qB.MainSideBar_dirRow__3nF78, .MainSideBar_dirRow__3nF78.MainSideBar_sideBarNavItemParent__39k75, .MainSideBar_dirRow__3nF78.MainSideBar_sideBarNavItemChild__DVuDi {
    flex-direction: column;
    flex-direction: flex-start;
    align-items: flex-start;
    justify-content: center; }
    @media (min-width: 1300px) {
      .MainSideBar_sideBarNavItem__W02qB.MainSideBar_dirRow__3nF78, .MainSideBar_dirRow__3nF78.MainSideBar_sideBarNavItemParent__39k75, .MainSideBar_dirRow__3nF78.MainSideBar_sideBarNavItemChild__DVuDi {
        height: 7.13542vw !important;
        margin-bottom: 0.78125vw !important; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .MainSideBar_sideBarNavItem__W02qB.MainSideBar_dirRow__3nF78, .MainSideBar_dirRow__3nF78.MainSideBar_sideBarNavItemParent__39k75, .MainSideBar_dirRow__3nF78.MainSideBar_sideBarNavItemChild__DVuDi {
        height: 7.92824vw !important;
        margin-bottom: 0.86806vw !important; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .MainSideBar_sideBarNavItem__W02qB.MainSideBar_dirRow__3nF78, .MainSideBar_dirRow__3nF78.MainSideBar_sideBarNavItemParent__39k75, .MainSideBar_dirRow__3nF78.MainSideBar_sideBarNavItemChild__DVuDi {
        height: 7.13542vw !important;
        margin-bottom: 0.78125vw !important; } }
  .MainSideBar_sideBarNavItem__W02qB.MainSideBar_assignment__2OCbo, .MainSideBar_assignment__2OCbo.MainSideBar_sideBarNavItemParent__39k75, .MainSideBar_assignment__2OCbo.MainSideBar_sideBarNavItemChild__DVuDi {
    flex-direction: column;
    flex-direction: flex-start;
    align-items: flex-start;
    justify-content: center; }
    @media (min-width: 1300px) {
      .MainSideBar_sideBarNavItem__W02qB.MainSideBar_assignment__2OCbo, .MainSideBar_assignment__2OCbo.MainSideBar_sideBarNavItemParent__39k75, .MainSideBar_assignment__2OCbo.MainSideBar_sideBarNavItemChild__DVuDi {
        height: 8.22917vw !important; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .MainSideBar_sideBarNavItem__W02qB.MainSideBar_assignment__2OCbo, .MainSideBar_assignment__2OCbo.MainSideBar_sideBarNavItemParent__39k75, .MainSideBar_assignment__2OCbo.MainSideBar_sideBarNavItemChild__DVuDi {
        height: 9.14352vw !important; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .MainSideBar_sideBarNavItem__W02qB.MainSideBar_assignment__2OCbo, .MainSideBar_assignment__2OCbo.MainSideBar_sideBarNavItemParent__39k75, .MainSideBar_assignment__2OCbo.MainSideBar_sideBarNavItemChild__DVuDi {
        height: 8.22917vw !important; } }
  @media (min-width: 1300px) {
    .MainSideBar_sideBarNavItem__W02qB .MainSideBar_icon__3eyPV, .MainSideBar_sideBarNavItemParent__39k75 .MainSideBar_icon__3eyPV, .MainSideBar_sideBarNavItemChild__DVuDi .MainSideBar_icon__3eyPV {
      min-width: 1.45833vw;
      min-height: 1.45833vw;
      max-width: 1.45833vw;
      max-height: 1.45833vw;
      margin-right: 1.04167vw;
      display: flex;
      justify-content: center;
      align-items: center; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_sideBarNavItem__W02qB .MainSideBar_icon__3eyPV, .MainSideBar_sideBarNavItemParent__39k75 .MainSideBar_icon__3eyPV, .MainSideBar_sideBarNavItemChild__DVuDi .MainSideBar_icon__3eyPV {
      min-width: 1.62037vw;
      min-height: 1.62037vw;
      max-width: 1.62037vw;
      max-height: 1.62037vw;
      margin-right: 1.15741vw;
      display: flex;
      justify-content: center;
      align-items: center; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_sideBarNavItem__W02qB .MainSideBar_icon__3eyPV, .MainSideBar_sideBarNavItemParent__39k75 .MainSideBar_icon__3eyPV, .MainSideBar_sideBarNavItemChild__DVuDi .MainSideBar_icon__3eyPV {
      min-width: 1.45833vw;
      min-height: 1.45833vw;
      max-width: 1.45833vw;
      max-height: 1.45833vw;
      margin-right: 1.04167vw;
      display: flex;
      justify-content: center;
      align-items: center; } }
  .MainSideBar_sideBarNavItem__W02qB .MainSideBar_icon__3eyPV svg, .MainSideBar_sideBarNavItemParent__39k75 .MainSideBar_icon__3eyPV svg, .MainSideBar_sideBarNavItemChild__DVuDi .MainSideBar_icon__3eyPV svg {
    display: block; }
    @media (min-width: 1300px) {
      .MainSideBar_sideBarNavItem__W02qB .MainSideBar_icon__3eyPV svg, .MainSideBar_sideBarNavItemParent__39k75 .MainSideBar_icon__3eyPV svg, .MainSideBar_sideBarNavItemChild__DVuDi .MainSideBar_icon__3eyPV svg {
        height: 1.45833vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .MainSideBar_sideBarNavItem__W02qB .MainSideBar_icon__3eyPV svg, .MainSideBar_sideBarNavItemParent__39k75 .MainSideBar_icon__3eyPV svg, .MainSideBar_sideBarNavItemChild__DVuDi .MainSideBar_icon__3eyPV svg {
        height: 1.62037vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .MainSideBar_sideBarNavItem__W02qB .MainSideBar_icon__3eyPV svg, .MainSideBar_sideBarNavItemParent__39k75 .MainSideBar_icon__3eyPV svg, .MainSideBar_sideBarNavItemChild__DVuDi .MainSideBar_icon__3eyPV svg {
        height: 1.45833vw; } }
  .MainSideBar_sideBarNavItem__W02qB .MainSideBar_icon__3eyPV path, .MainSideBar_sideBarNavItemParent__39k75 .MainSideBar_icon__3eyPV path, .MainSideBar_sideBarNavItemChild__DVuDi .MainSideBar_icon__3eyPV path {
    fill: #111;
    transition: 0.3s all ease-in-out; }
  .MainSideBar_sideBarNavItem__W02qB .MainSideBar_visitedIcon__XH_7G path, .MainSideBar_sideBarNavItemParent__39k75 .MainSideBar_visitedIcon__XH_7G path, .MainSideBar_sideBarNavItemChild__DVuDi .MainSideBar_visitedIcon__XH_7G path {
    fill: #01AA93 !important;
    transition: 0.3s all ease-in-out; }
  @media (min-width: 1300px) {
    .MainSideBar_sideBarNavItem__W02qB, .MainSideBar_sideBarNavItemParent__39k75, .MainSideBar_sideBarNavItemChild__DVuDi {
      height: 3.33333vw;
      font-size: 1.04167vw;
      padding: 0 0.78125vw 0 2.34375vw;
      font-weight: 700; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_sideBarNavItem__W02qB, .MainSideBar_sideBarNavItemParent__39k75, .MainSideBar_sideBarNavItemChild__DVuDi {
      height: 3.7037vw;
      font-size: 1.15741vw;
      padding: 0 0.86806vw 0 2.60417vw;
      font-weight: 700; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_sideBarNavItem__W02qB, .MainSideBar_sideBarNavItemParent__39k75, .MainSideBar_sideBarNavItemChild__DVuDi {
      height: 3.33333vw;
      font-size: 1.04167vw;
      padding: 0 0.78125vw 0 2.34375vw;
      font-weight: 700; } }
  .MainSideBar_sideBarNavItem__W02qB .MainSideBar_sideBarNavText__Can7T, .MainSideBar_sideBarNavItemParent__39k75 .MainSideBar_sideBarNavText__Can7T, .MainSideBar_sideBarNavItemChild__DVuDi .MainSideBar_sideBarNavText__Can7T {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    @media (min-width: 1300px) {
      .MainSideBar_sideBarNavItem__W02qB .MainSideBar_sideBarNavText__Can7T, .MainSideBar_sideBarNavItemParent__39k75 .MainSideBar_sideBarNavText__Can7T, .MainSideBar_sideBarNavItemChild__DVuDi .MainSideBar_sideBarNavText__Can7T {
        min-width: 9.375vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .MainSideBar_sideBarNavItem__W02qB .MainSideBar_sideBarNavText__Can7T, .MainSideBar_sideBarNavItemParent__39k75 .MainSideBar_sideBarNavText__Can7T, .MainSideBar_sideBarNavItemChild__DVuDi .MainSideBar_sideBarNavText__Can7T {
        min-width: 10.41667vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .MainSideBar_sideBarNavItem__W02qB .MainSideBar_sideBarNavText__Can7T, .MainSideBar_sideBarNavItemParent__39k75 .MainSideBar_sideBarNavText__Can7T, .MainSideBar_sideBarNavItemChild__DVuDi .MainSideBar_sideBarNavText__Can7T {
        min-width: 9.375vw; } }
  .MainSideBar_sideBarNavItem__W02qB:hover, .MainSideBar_sideBarNavItemParent__39k75:hover, .MainSideBar_sideBarNavItemChild__DVuDi:hover, .MainSideBar_sideBarNavItem__W02qB.MainSideBar_active__290Pb, .MainSideBar_active__290Pb.MainSideBar_sideBarNavItemParent__39k75, .MainSideBar_active__290Pb.MainSideBar_sideBarNavItemChild__DVuDi {
    color: #00ADE6; }
    .MainSideBar_sideBarNavItem__W02qB:hover path, .MainSideBar_sideBarNavItemParent__39k75:hover path, .MainSideBar_sideBarNavItemChild__DVuDi:hover path, .MainSideBar_sideBarNavItem__W02qB.MainSideBar_active__290Pb path, .MainSideBar_active__290Pb.MainSideBar_sideBarNavItemParent__39k75 path, .MainSideBar_active__290Pb.MainSideBar_sideBarNavItemChild__DVuDi path {
      fill: #00ADE6; }
  .MainSideBar_sideBarNavItem__W02qB.MainSideBar_active__290Pb, .MainSideBar_active__290Pb.MainSideBar_sideBarNavItemParent__39k75, .MainSideBar_active__290Pb.MainSideBar_sideBarNavItemChild__DVuDi {
    background: #E6F7FD; }

@media (min-width: 1300px) {
  .MainSideBar_sideBarNavItemParent__39k75 .MainSideBar_arrow__RTaUv {
    min-width: 0.72917vw;
    min-height: 0.41667vw;
    max-width: 0.72917vw;
    max-height: 0.41667vw;
    margin-left: 1.35417vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .MainSideBar_sideBarNavItemParent__39k75 .MainSideBar_arrow__RTaUv {
    min-width: 0.81019vw;
    min-height: 0.46296vw;
    max-width: 0.81019vw;
    max-height: 0.46296vw;
    margin-left: 1.50463vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .MainSideBar_sideBarNavItemParent__39k75 .MainSideBar_arrow__RTaUv {
    min-width: 0.72917vw;
    min-height: 0.41667vw;
    max-width: 0.72917vw;
    max-height: 0.41667vw;
    margin-left: 1.35417vw; } }

.MainSideBar_sideBarNavItemParent__39k75 .MainSideBar_arrowSVG__EOXVU {
  display: block; }
  .MainSideBar_sideBarNavItemParent__39k75 .MainSideBar_arrowSVG__EOXVU path {
    fill: #504F4F; }

.MainSideBar_sideBarNavItemParent__39k75.MainSideBar_active__290Pb path, .MainSideBar_sideBarNavItemParent__39k75:hover path {
  fill: #00ADE6; }

@media (min-width: 1300px) {
  .MainSideBar_borderOnSidebar__2Onc0 {
    border-width: 1px 0px;
    border-style: solid;
    border-color: #E2E2E2; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .MainSideBar_borderOnSidebar__2Onc0 {
    border-width: 1px 0px;
    border-style: solid;
    border-color: #E2E2E2; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .MainSideBar_borderOnSidebar__2Onc0 {
    border-width: 1px 0px;
    border-style: solid;
    border-color: #E2E2E2; } }

@media (min-width: 1300px) {
  .MainSideBar_sideBarNavItemChild__DVuDi {
    height: 2.70833vw;
    font-size: 0.9375vw;
    margin-top: 0.20833vw;
    padding: 0 0.52083vw 0 3.95833vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .MainSideBar_sideBarNavItemChild__DVuDi {
    height: 3.00926vw;
    font-size: 1.04167vw;
    margin-top: 0.23148vw;
    padding: 0 0.5787vw 0 4.39815vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .MainSideBar_sideBarNavItemChild__DVuDi {
    height: 2.70833vw;
    font-size: 0.9375vw;
    margin-top: 0.20833vw;
    padding: 0 0.52083vw 0 3.95833vw; } }

@media (min-width: 1300px) {
  .MainSideBar_sideBarNavItemChild__DVuDi .MainSideBar_icon__3eyPV {
    min-width: 1.25vw;
    min-height: 1.25vw;
    margin-right: 0.9375vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .MainSideBar_sideBarNavItemChild__DVuDi .MainSideBar_icon__3eyPV {
    min-width: 1.38889vw;
    min-height: 1.38889vw;
    margin-right: 1.04167vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .MainSideBar_sideBarNavItemChild__DVuDi .MainSideBar_icon__3eyPV {
    min-width: 1.25vw;
    min-height: 1.25vw;
    margin-right: 0.9375vw; } }

@media (min-width: 1300px) {
  .MainSideBar_sideBarNavItemChild__DVuDi .MainSideBar_icon__3eyPV svg {
    height: 1.25vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .MainSideBar_sideBarNavItemChild__DVuDi .MainSideBar_icon__3eyPV svg {
    height: 1.38889vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .MainSideBar_sideBarNavItemChild__DVuDi .MainSideBar_icon__3eyPV svg {
    height: 1.25vw; } }

@media (min-width: 1300px) {
  .MainSideBar_sideBarNavWrapper__3CdnK {
    margin-bottom: 0.41667vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .MainSideBar_sideBarNavWrapper__3CdnK {
    margin-bottom: 0.46296vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .MainSideBar_sideBarNavWrapper__3CdnK {
    margin-bottom: 0.41667vw; } }

.MainSideBar_flexColumn__2W1lS {
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  @media (min-width: 1300px) {
    .MainSideBar_flexColumn__2W1lS {
      padding-top: 1.66667vw;
      grid-gap: 2.08333vw;
      gap: 2.08333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_flexColumn__2W1lS {
      padding-top: 1.85185vw;
      grid-gap: 2.31481vw;
      gap: 2.31481vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_flexColumn__2W1lS {
      padding-top: 1.66667vw;
      grid-gap: 2.08333vw;
      gap: 2.08333vw; } }

.MainSideBar_flexColumnForTeacherApp__3bDfX {
  height: inherit; }

.MainSideBar_profileContainer__3mu56 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  @media (min-width: 1300px) {
    .MainSideBar_profileContainer__3mu56 {
      height: 4.58333vw;
      margin-bottom: 0.20833vw;
      grid-gap: 0.52083vw;
      gap: 0.52083vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_profileContainer__3mu56 {
      height: 5.09259vw;
      margin-bottom: 0.23148vw;
      grid-gap: 0.5787vw;
      gap: 0.5787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_profileContainer__3mu56 {
      height: 4.58333vw;
      margin-bottom: 0.20833vw;
      grid-gap: 0.52083vw;
      gap: 0.52083vw; } }

.MainSideBar_profileImage__PvA0M {
  background: url(/static/media/profilePicture.cb8c4ad4.png);
  background-size: cover;
  background-position: center;
  border-radius: 50%; }
  @media (min-width: 1300px) {
    .MainSideBar_profileImage__PvA0M {
      width: 2.5vw;
      height: 2.5vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_profileImage__PvA0M {
      width: 2.77778vw;
      height: 2.77778vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_profileImage__PvA0M {
      width: 2.5vw;
      height: 2.5vw; } }

.MainSideBar_studentDetailsContainer__1CTGc {
  font-size: 4.83092vw;
  margin-left: 1.93237vw;
  display: flex;
  flex-direction: column; }
  @media (min-width: 1300px) {
    .MainSideBar_studentDetailsContainer__1CTGc {
      margin-left: 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_studentDetailsContainer__1CTGc {
      margin-left: 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_studentDetailsContainer__1CTGc {
      margin-left: 0.41667vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .MainSideBar_studentDetailsContainer__1CTGc {
      font-size: 4.02576vw;
      margin-left: 1.61031vw; }
      .MainSideBar_studentDetailsContainer__1CTGc else {
        font-size: 20px; }
      .MainSideBar_studentDetailsContainer__1CTGc else {
        margin-left: 8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .MainSideBar_studentDetailsContainer__1CTGc {
      font-size: 3.22061vw;
      margin-left: 1.28824vw; }
      .MainSideBar_studentDetailsContainer__1CTGc else {
        font-size: 20px; }
      .MainSideBar_studentDetailsContainer__1CTGc else {
        margin-left: 8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .MainSideBar_studentDetailsContainer__1CTGc {
      font-size: 2.84172vw;
      margin-left: 1.13669vw; }
      .MainSideBar_studentDetailsContainer__1CTGc else {
        font-size: 20px; }
      .MainSideBar_studentDetailsContainer__1CTGc else {
        margin-left: 8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .MainSideBar_studentDetailsContainer__1CTGc {
      font-size: 2.54259vw;
      margin-left: 1.01704vw; }
      .MainSideBar_studentDetailsContainer__1CTGc else {
        font-size: 20px; }
      .MainSideBar_studentDetailsContainer__1CTGc else {
        margin-left: 8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .MainSideBar_studentDetailsContainer__1CTGc {
      font-size: 2.30044vw;
      margin-left: 0.92017vw; }
      .MainSideBar_studentDetailsContainer__1CTGc else {
        font-size: 20px; }
      .MainSideBar_studentDetailsContainer__1CTGc else {
        margin-left: 8px; } }

.MainSideBar_userNameAndRollno__17LPv {
  font-size: 4.83092vw;
  line-height: 6.52174vw;
  font-weight: 700;
  color: #504F4F; }
  @media (min-width: 1300px) {
    .MainSideBar_userNameAndRollno__17LPv {
      font-size: 1.04167vw;
      line-height: 1.40625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_userNameAndRollno__17LPv {
      font-size: 1.15741vw;
      line-height: 1.5625vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_userNameAndRollno__17LPv {
      font-size: 1.04167vw;
      line-height: 1.40625vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .MainSideBar_userNameAndRollno__17LPv {
      font-size: 4.02576vw;
      line-height: 5.43478vw; }
      .MainSideBar_userNameAndRollno__17LPv else {
        font-size: 20px; }
      .MainSideBar_userNameAndRollno__17LPv else {
        line-height: 27px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .MainSideBar_userNameAndRollno__17LPv {
      font-size: 3.22061vw;
      line-height: 4.34783vw; }
      .MainSideBar_userNameAndRollno__17LPv else {
        font-size: 20px; }
      .MainSideBar_userNameAndRollno__17LPv else {
        line-height: 27px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .MainSideBar_userNameAndRollno__17LPv {
      font-size: 2.84172vw;
      line-height: 3.83632vw; }
      .MainSideBar_userNameAndRollno__17LPv else {
        font-size: 20px; }
      .MainSideBar_userNameAndRollno__17LPv else {
        line-height: 27px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .MainSideBar_userNameAndRollno__17LPv {
      font-size: 2.54259vw;
      line-height: 3.43249vw; }
      .MainSideBar_userNameAndRollno__17LPv else {
        font-size: 20px; }
      .MainSideBar_userNameAndRollno__17LPv else {
        line-height: 27px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .MainSideBar_userNameAndRollno__17LPv {
      font-size: 2.30044vw;
      line-height: 3.10559vw; }
      .MainSideBar_userNameAndRollno__17LPv else {
        font-size: 20px; }
      .MainSideBar_userNameAndRollno__17LPv else {
        line-height: 27px; } }

.MainSideBar_gradeText__e_JEP {
  color: #A8A7A7;
  font-weight: 700; }
  @media (min-width: 1300px) {
    .MainSideBar_gradeText__e_JEP {
      font-size: 0.72917vw;
      line-height: 0.98958vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_gradeText__e_JEP {
      font-size: 0.81019vw;
      line-height: 1.09954vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_gradeText__e_JEP {
      font-size: 0.72917vw;
      line-height: 0.98958vw; } }

.MainSideBar_profileAndSettingsContainer__17DjF {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  position: relative;
  align-items: center;
  background-size: cover;
  background-image: url(/static/media/profileContainerBG.39070d5d.png); }
  @media (min-width: 1300px) {
    .MainSideBar_profileAndSettingsContainer__17DjF {
      padding: 0 1.25vw 0 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_profileAndSettingsContainer__17DjF {
      padding: 0 1.38889vw 0 1.38889vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_profileAndSettingsContainer__17DjF {
      padding: 0 1.25vw 0 1.25vw; } }

.MainSideBar_profileRightArrow__chZRG {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg); }
  @media (min-width: 1300px) {
    .MainSideBar_profileRightArrow__chZRG {
      min-width: 0.78125vw;
      min-height: 0.78125vw;
      max-width: 0.78125vw;
      max-height: 0.78125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_profileRightArrow__chZRG {
      min-width: 0.86806vw;
      min-height: 0.86806vw;
      max-width: 0.86806vw;
      max-height: 0.86806vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_profileRightArrow__chZRG {
      min-width: 0.78125vw;
      min-height: 0.78125vw;
      max-width: 0.78125vw;
      max-height: 0.78125vw; } }
  .MainSideBar_profileRightArrow__chZRG svg {
    display: block; }
  .MainSideBar_profileRightArrow__chZRG path {
    fill: #000;
    transition: 0.3s all ease-in-out; }

.MainSideBar_tekieLogoDrop__2nl-E {
  background: url(/static/media/tekieLogoDrop.1398315a.png);
  background-size: contain;
  background-repeat: no-repeat; }
  @media (min-width: 1300px) {
    .MainSideBar_tekieLogoDrop__2nl-E {
      width: 2.65625vw;
      height: 2.96875vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_tekieLogoDrop__2nl-E {
      width: 2.95139vw;
      height: 3.29861vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_tekieLogoDrop__2nl-E {
      width: 2.65625vw;
      height: 2.96875vw; } }

.MainSideBar_outSidenavItem__3vqjJ {
  position: relative;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none; }
  @media (min-width: 1300px) {
    .MainSideBar_outSidenavItem__3vqjJ {
      width: 6.25vw;
      height: 3.95833vw;
      margin-bottom: 2.08333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_outSidenavItem__3vqjJ {
      width: 6.94444vw;
      height: 4.39815vw;
      margin-bottom: 2.31481vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_outSidenavItem__3vqjJ {
      width: 6.25vw;
      height: 3.95833vw;
      margin-bottom: 2.08333vw; } }
  @media (min-width: 1300px) {
    .MainSideBar_outSidenavItem__3vqjJ .MainSideBar_icon__3eyPV {
      width: 1.66667vw;
      height: 1.66667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_outSidenavItem__3vqjJ .MainSideBar_icon__3eyPV {
      width: 1.85185vw;
      height: 1.85185vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_outSidenavItem__3vqjJ .MainSideBar_icon__3eyPV {
      width: 1.66667vw;
      height: 1.66667vw; } }
  .MainSideBar_outSidenavItem__3vqjJ .MainSideBar_icon__3eyPV circle {
    transition: 0.3s all ease-in-out;
    stroke: #9F9F9F; }
  .MainSideBar_outSidenavItem__3vqjJ .MainSideBar_icon__3eyPV.MainSideBar_fill__3TW5T path {
    transition: 0.3s all ease-in-out;
    fill: #9F9F9F; }
  .MainSideBar_outSidenavItem__3vqjJ .MainSideBar_icon__3eyPV.MainSideBar_stroke__2jdoW path {
    transition: 0.3s all ease-in-out;
    stroke: #9F9F9F; }
  .MainSideBar_outSidenavItem__3vqjJ.MainSideBar_active__290Pb .MainSideBar_titleOutSideNavBar__YSwxt, .MainSideBar_outSidenavItem__3vqjJ:hover .MainSideBar_titleOutSideNavBar__YSwxt {
    color: #00ADE6; }
  .MainSideBar_outSidenavItem__3vqjJ.MainSideBar_active__290Pb circle, .MainSideBar_outSidenavItem__3vqjJ:hover circle {
    stroke: #00ADE6; }
  .MainSideBar_outSidenavItem__3vqjJ.MainSideBar_active__290Pb .MainSideBar_fill__3TW5T path, .MainSideBar_outSidenavItem__3vqjJ:hover .MainSideBar_fill__3TW5T path {
    fill: #00ADE6; }
  .MainSideBar_outSidenavItem__3vqjJ.MainSideBar_active__290Pb .MainSideBar_stroke__2jdoW path, .MainSideBar_outSidenavItem__3vqjJ:hover .MainSideBar_stroke__2jdoW path {
    stroke: #00ADE6; }
  .MainSideBar_outSidenavItem__3vqjJ.MainSideBar_active__290Pb path.MainSideBar_replaceFill__1py64, .MainSideBar_outSidenavItem__3vqjJ:hover path.MainSideBar_replaceFill__1py64 {
    fill: #00ADE6; }

.MainSideBar_titleOutSideNavBar__YSwxt {
  font-weight: 700;
  font-family: Nunito;
  text-align: center;
  transition: 0.3s all ease-in-out;
  color: #9F9F9F; }
  @media (min-width: 1300px) {
    .MainSideBar_titleOutSideNavBar__YSwxt {
      font-size: 0.83333vw;
      line-height: 110%;
      margin-top: 0.46875vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_titleOutSideNavBar__YSwxt {
      font-size: 0.92593vw;
      line-height: 110%;
      margin-top: 0.52083vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_titleOutSideNavBar__YSwxt {
      font-size: 0.83333vw;
      line-height: 110%;
      margin-top: 0.46875vw; } }

.MainSideBar_profileContainerOutSideNav__6uo19 {
  display: flex;
  align-items: center;
  flex-direction: column; }
  @media (min-width: 1300px) {
    .MainSideBar_profileContainerOutSideNav__6uo19 {
      margin-bottom: 1.92708vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_profileContainerOutSideNav__6uo19 {
      margin-bottom: 2.1412vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_profileContainerOutSideNav__6uo19 {
      margin-bottom: 1.92708vw; } }
  .MainSideBar_profileContainerOutSideNav__6uo19 .MainSideBar_profileName__7re9K {
    font-weight: 700;
    line-height: 110%;
    color: #504F4F; }
    @media (min-width: 1300px) {
      .MainSideBar_profileContainerOutSideNav__6uo19 .MainSideBar_profileName__7re9K {
        font-size: 0.83333vw;
        margin-top: 0.625vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .MainSideBar_profileContainerOutSideNav__6uo19 .MainSideBar_profileName__7re9K {
        font-size: 0.92593vw;
        margin-top: 0.69444vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .MainSideBar_profileContainerOutSideNav__6uo19 .MainSideBar_profileName__7re9K {
        font-size: 0.83333vw;
        margin-top: 0.625vw; } }
  .MainSideBar_profileContainerOutSideNav__6uo19 .MainSideBar_rollNo__1yYVv {
    font-weight: 700;
    color: #A8A7A7; }
    @media (min-width: 1300px) {
      .MainSideBar_profileContainerOutSideNav__6uo19 .MainSideBar_rollNo__1yYVv {
        font-size: 0.72917vw;
        line-height: 0.98958vw;
        margin-top: 0.20833vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .MainSideBar_profileContainerOutSideNav__6uo19 .MainSideBar_rollNo__1yYVv {
        font-size: 0.81019vw;
        line-height: 1.09954vw;
        margin-top: 0.23148vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .MainSideBar_profileContainerOutSideNav__6uo19 .MainSideBar_rollNo__1yYVv {
        font-size: 0.72917vw;
        line-height: 0.98958vw;
        margin-top: 0.20833vw; } }

.MainSideBar_activeLineIndicator__20668 {
  position: absolute;
  right: 0;
  z-index: 9;
  background-color: #00ADE6; }
  @media (min-width: 1300px) {
    .MainSideBar_activeLineIndicator__20668 {
      height: 100%;
      width: 0.36458vw;
      border-radius: 0.20833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_activeLineIndicator__20668 {
      height: 100%;
      width: 0.40509vw;
      border-radius: 0.23148vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_activeLineIndicator__20668 {
      height: 100%;
      width: 0.36458vw;
      border-radius: 0.20833vw; } }

.MainSideBar_profileAndSettingsDropdown__f4R7Q {
  left: 17.96875vw !important; }
  @media (min-width: 1300px) {
    .MainSideBar_profileAndSettingsDropdown__f4R7Q {
      top: unset !important;
      bottom: 0 !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_profileAndSettingsDropdown__f4R7Q {
      top: unset !important;
      bottom: 0 !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_profileAndSettingsDropdown__f4R7Q {
      top: unset !important;
      bottom: 0 !important; } }

.MainSideBar_learningDropdown__gLalt {
  left: 16.92708vw !important; }
  @media (min-width: 1300px) {
    .MainSideBar_learningDropdown__gLalt {
      top: unset !important;
      bottom: 0 !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_learningDropdown__gLalt {
      top: unset !important;
      bottom: 0 !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_learningDropdown__gLalt {
      top: unset !important;
      bottom: 0 !important; } }

.MainSideBar_learningContainer__faQ4g {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  background: #FFFFFF;
  box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.06);
  border-radius: 100px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  position: relative;
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .MainSideBar_learningContainer__faQ4g {
      width: 15.625vw;
      margin-bottom: 1.25vw;
      padding: 0vw 1.04167vw;
      height: 3.02083vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_learningContainer__faQ4g {
      width: 17.36111vw;
      margin-bottom: 1.38889vw;
      padding: 0vw 1.15741vw;
      height: 3.35648vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_learningContainer__faQ4g {
      width: 15.625vw;
      margin-bottom: 1.25vw;
      padding: 0vw 1.04167vw;
      height: 3.02083vw; } }
  .MainSideBar_learningContainer__faQ4g .MainSideBar_title__v_WeF {
    font-weight: 600;
    color: #005773;
    margin: 0; }
    @media (min-width: 1300px) {
      .MainSideBar_learningContainer__faQ4g .MainSideBar_title__v_WeF {
        font-size: 1.04167vw;
        line-height: 1.40625vw;
        padding: 0;
        margin: 0 0 0 1.04167vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .MainSideBar_learningContainer__faQ4g .MainSideBar_title__v_WeF {
        font-size: 1.15741vw;
        line-height: 1.5625vw;
        padding: 0;
        margin: 0 0 0 1.15741vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .MainSideBar_learningContainer__faQ4g .MainSideBar_title__v_WeF {
        font-size: 1.04167vw;
        line-height: 1.40625vw;
        padding: 0;
        margin: 0 0 0 1.04167vw; } }

@media (min-width: 1300px) {
  .MainSideBar_dropDownContainer__130lE {
    width: 16.35417vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .MainSideBar_dropDownContainer__130lE {
    width: 18.1713vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .MainSideBar_dropDownContainer__130lE {
    width: 16.35417vw; } }

.MainSideBar_profileAndResourcesContainer__31zl0 {
  display: flex;
  flex-direction: column; }

.MainSideBar_profilePictureContainer__2GdRt {
  display: flex;
  align-items: center; }

.MainSideBar_main_navbar_profilePicture__2RtGO {
  opacity: 0;
  position: relative;
  left: 5px; }

.MainSideBar_additionalProfile__22A3- {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), linear-gradient(133.17deg, #007299 -13.54%, #0E7092 110.48%);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  box-sizing: border-box;
  color: #fff;
  font-weight: bold;
  margin-left: -20px; }
  @media (min-width: 1300px) {
    .MainSideBar_additionalProfile__22A3- {
      font-size: 0.72917vw;
      width: 1.82292vw;
      height: 1.51042vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_additionalProfile__22A3- {
      font-size: 0.81019vw;
      width: 2.02546vw;
      height: 1.67824vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_additionalProfile__22A3- {
      font-size: 0.72917vw;
      width: 1.82292vw;
      height: 1.51042vw; } }

.MainSideBar_activityContainer__2YftS {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: linear-gradient(256.65deg, rgba(255, 255, 255, 0) 3.84%, #E3E3E3 99.11%);
  border: 1px solid #CCCCCC; }
  @media (min-width: 1300px) {
    .MainSideBar_activityContainer__2YftS {
      height: 70;
      padding: 0.9375vw 2.76042vw 0 2.76042vw;
      grid-gap: 0.52083vw;
      gap: 0.52083vw;
      border-radius: 1.77083vw 1.77083vw 0vw 0vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_activityContainer__2YftS {
      height: 70;
      padding: 1.04167vw 3.06713vw 0 3.06713vw;
      grid-gap: 0.5787vw;
      gap: 0.5787vw;
      border-radius: 1.96759vw 1.96759vw 0vw 0vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_activityContainer__2YftS {
      height: 70;
      padding: 0.9375vw 2.76042vw 0 2.76042vw;
      grid-gap: 0.52083vw;
      gap: 0.52083vw;
      border-radius: 1.77083vw 1.77083vw 0vw 0vw; } }

.MainSideBar_studentActivityText__1W7k- {
  display: flex;
  flex-direction: row;
  align-items: center; }
  @media (min-width: 1300px) {
    .MainSideBar_studentActivityText__1W7k- {
      box-sizing: border-box; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_studentActivityText__1W7k- {
      box-sizing: border-box; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_studentActivityText__1W7k- {
      box-sizing: border-box; } }
  .MainSideBar_studentActivityText__1W7k- p {
    font-weight: 700;
    color: #403F3F; }
    @media (min-width: 1300px) {
      .MainSideBar_studentActivityText__1W7k- p {
        font-size: 0.9375vw;
        line-height: 1.04167vw;
        margin: 0vw;
        margin-left: 0.41667vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .MainSideBar_studentActivityText__1W7k- p {
        font-size: 1.04167vw;
        line-height: 1.15741vw;
        margin: 0vw;
        margin-left: 0.46296vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .MainSideBar_studentActivityText__1W7k- p {
        font-size: 0.9375vw;
        line-height: 1.04167vw;
        margin: 0vw;
        margin-left: 0.41667vw; } }

.MainSideBar_activityToggle__3nPnE {
  align-self: center;
  justify-self: center; }
  .MainSideBar_activityToggle__3nPnE p {
    color: #504F4F; }
    @media (min-width: 1300px) {
      .MainSideBar_activityToggle__3nPnE p {
        font-size: 0.83333vw !important;
        font-weight: 500 !important; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .MainSideBar_activityToggle__3nPnE p {
        font-size: 0.92593vw !important;
        font-weight: 500 !important; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .MainSideBar_activityToggle__3nPnE p {
        font-size: 0.83333vw !important;
        font-weight: 500 !important; } }

.MainSideBar_visitedCount__1CJ2Z {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; }
  .MainSideBar_visitedCount__1CJ2Z span {
    color: #01AA93;
    font-weight: 600; }
    @media (min-width: 1300px) {
      .MainSideBar_visitedCount__1CJ2Z span {
        font-size: 0.72917vw;
        margin-top: 0.10417vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .MainSideBar_visitedCount__1CJ2Z span {
        font-size: 0.81019vw;
        margin-top: 0.11574vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .MainSideBar_visitedCount__1CJ2Z span {
        font-size: 0.72917vw;
        margin-top: 0.10417vw; } }
  .MainSideBar_visitedCount__1CJ2Z p {
    color: #403F3F;
    font-weight: 500; }
    @media (min-width: 1300px) {
      .MainSideBar_visitedCount__1CJ2Z p {
        font-size: 0.72917vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .MainSideBar_visitedCount__1CJ2Z p {
        font-size: 0.81019vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .MainSideBar_visitedCount__1CJ2Z p {
        font-size: 0.72917vw; } }

.MainSideBar_submitted__3NmkB div {
  align-items: center; }
  .MainSideBar_submitted__3NmkB div p {
    font-weight: 700;
    display: flex;
    color: #666666; }
    @media (min-width: 1300px) {
      .MainSideBar_submitted__3NmkB div p {
        font-size: 0.625vw;
        line-height: 0.78125vw;
        margin-right: 0.3125vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .MainSideBar_submitted__3NmkB div p {
        font-size: 0.69444vw;
        line-height: 0.86806vw;
        margin-right: 0.34722vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .MainSideBar_submitted__3NmkB div p {
        font-size: 0.625vw;
        line-height: 0.78125vw;
        margin-right: 0.3125vw; } }
    @media (min-width: 1300px) {
      .MainSideBar_submitted__3NmkB div p span {
        margin-left: 0.3125vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .MainSideBar_submitted__3NmkB div p span {
        margin-left: 0.34722vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .MainSideBar_submitted__3NmkB div p span {
        margin-left: 0.3125vw; } }

.MainSideBar_submitted__3NmkB span {
  font-weight: 600;
  color: #A8A7A7; }
  @media (min-width: 1300px) {
    .MainSideBar_submitted__3NmkB span {
      font-size: 0.9375vw;
      line-height: 1.14583vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_submitted__3NmkB span {
      font-size: 1.04167vw;
      line-height: 1.27315vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_submitted__3NmkB span {
      font-size: 0.9375vw;
      line-height: 1.14583vw; } }

@media (min-width: 1300px) {
  .MainSideBar_score__3x0Ht {
    margin-left: 1.25vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .MainSideBar_score__3x0Ht {
    margin-left: 1.38889vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .MainSideBar_score__3x0Ht {
    margin-left: 1.25vw; } }

.MainSideBar_score__3x0Ht p {
  font-weight: 700;
  color: #666666; }
  @media (min-width: 1300px) {
    .MainSideBar_score__3x0Ht p {
      font-size: 0.625vw;
      line-height: 0.78125vw;
      margin: 0vw;
      margin-bottom: 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_score__3x0Ht p {
      font-size: 0.69444vw;
      line-height: 0.86806vw;
      margin: 0vw;
      margin-bottom: 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_score__3x0Ht p {
      font-size: 0.625vw;
      line-height: 0.78125vw;
      margin: 0vw;
      margin-bottom: 0.41667vw; } }

.MainSideBar_score__3x0Ht span {
  background-color: rgba(250, 173, 20, 0.2);
  font-weight: 600;
  color: #A87000; }
  @media (min-width: 1300px) {
    .MainSideBar_score__3x0Ht span {
      font-size: 0.83333vw;
      padding: 0.3125vw 0.41667vw;
      line-height: 0.625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_score__3x0Ht span {
      font-size: 0.92593vw;
      padding: 0.34722vw 0.46296vw;
      line-height: 0.69444vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_score__3x0Ht span {
      font-size: 0.83333vw;
      padding: 0.3125vw 0.41667vw;
      line-height: 0.625vw; } }

@media (min-width: 1300px) {
  .MainSideBar_infoContainer__3rHZU {
    width: 10.78125vw;
    border-top: 1px solid #000000; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .MainSideBar_infoContainer__3rHZU {
    width: 11.97917vw;
    border-top: 1px solid #000000; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .MainSideBar_infoContainer__3rHZU {
    width: 10.78125vw;
    border-top: 1px solid #000000; } }

.MainSideBar_detailsContainer__3G9yQ {
  display: flex;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  align-self: flex-start;
  justify-self: center; }
  @media (min-width: 1300px) {
    .MainSideBar_detailsContainer__3G9yQ {
      padding: 0.78125vw 0.78125vw 0.78125vw 0;
      margin-top: 0.78125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_detailsContainer__3G9yQ {
      padding: 0.86806vw 0.86806vw 0.86806vw 0;
      margin-top: 0.86806vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_detailsContainer__3G9yQ {
      padding: 0.78125vw 0.78125vw 0.78125vw 0;
      margin-top: 0.78125vw; } }

.MainSideBar_informationIcon__1pjDM path {
  fill: #403F3F !important; }

.MainSideBar_gradient__1Otfc {
  height: 16px;
  background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(217, 217, 217, 0) 48.96%, #D9D9D9 87.5%); }

.MainSideBar_updatedText__gFJci {
  align-self: center; }
  .MainSideBar_updatedText__gFJci p {
    margin: 0;
    font-weight: 500; }
    @media (min-width: 1300px) {
      .MainSideBar_updatedText__gFJci p {
        font-size: 0.78125vw;
        color: grey; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .MainSideBar_updatedText__gFJci p {
        font-size: 0.86806vw;
        color: grey; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .MainSideBar_updatedText__gFJci p {
        font-size: 0.78125vw;
        color: grey; } }

.MainSideBar_sidebar__2tQ14 {
  height: 100%;
  display: grid;
  box-sizing: border-box;
  grid-template-rows: auto 1fr auto; }
  @media (min-width: 1300px) {
    .MainSideBar_sidebar__2tQ14 {
      padding-top: 1.66667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .MainSideBar_sidebar__2tQ14 {
      padding-top: 1.85185vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .MainSideBar_sidebar__2tQ14 {
      padding-top: 1.66667vw; } }

.MainSideBar_content__1SCp8 {
  overflow: auto; }

.main_navbar_container {
  width: 100%;
  height: 3.38542vw;
  background: white;
  box-shadow: 0px 4px 5px rgba(0, 31, 41, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: 9999999;
  display: none; }
  @media (min-width: 1300px) {
    .main_navbar_container {
      display: flex; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_container {
      display: flex; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_container {
      display: flex; } }

.main_navbar_allottedMentor {
  color: #aaacae;
  font-weight: 500;
  display: block; }
  @media (min-width: 1300px) {
    .main_navbar_allottedMentor {
      margin-top: 0.52083vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_allottedMentor {
      margin-top: 0.5787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_allottedMentor {
      margin-top: 0.52083vw; } }

.main_navbar_session_hide_mobile {
  display: none; }
  @media (min-width: 1300px) {
    .main_navbar_session_hide_mobile {
      display: flex; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_session_hide_mobile {
      display: flex; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_session_hide_mobile {
      display: flex; } }

.main_navbar_tekieLogo {
  width: 4.0625vw;
  height: 1.5625vw;
  background-image: url(/static/media/tekieLogo.029ba7a9.png);
  background-size: contain;
  align-self: flex-start;
  margin-left: 1.25vw;
  background-repeat: no-repeat;
  cursor: pointer; }

.main_navbar_tekie_school_logo {
  width: 3.125vw;
  height: 3.125vw;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  border: 0.3125vw solid #00ADE6;
  margin-top: 0.10417vw;
  margin-left: 2.60417vw;
  margin-right: 3.125vw; }

.main_navbar_coin {
  width: 1.64583vw;
  height: 1.64583vw;
  border-radius: 50%; }

.main_navbar_creditsText {
  font-family: Nunito;
  font-size: 1.14583vw;
  margin-left: 0.52083vw;
  color: #fff;
  font-weight: bold; }

.main_navbar_navContainer {
  flex: 1 1;
  height: 100%;
  display: flex;
  align-items: center; }

.main_navbar_navItem {
  font-size: 1.04167vw;
  color: #A8A7A7;
  margin-left: 3.125vw;
  font-weight: 700;
  text-decoration: none;
  transition: 0.4s all ease-in-out;
  text-transform: capitalize; }
  .main_navbar_navItem:hover {
    color: #00ade6; }
    .main_navbar_navItem:hover path {
      fill: #80d6f3; }
  @media (min-width: 1300px) {
    .main_navbar_navItem svg.down_Arrow {
      width: 0.52083vw;
      height: 0.36458vw;
      margin-left: 0.52083vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_navItem svg.down_Arrow {
      width: 0.5787vw;
      height: 0.40509vw;
      margin-left: 0.5787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_navItem svg.down_Arrow {
      width: 0.52083vw;
      height: 0.36458vw;
      margin-left: 0.52083vw; } }

@media (min-width: 1300px) {
  .main_navbar_navItemIcon {
    width: 1.14583vw;
    height: 1.14583vw;
    margin-right: 0.78125vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .main_navbar_navItemIcon {
    width: 1.27315vw;
    height: 1.27315vw;
    margin-right: 0.86806vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .main_navbar_navItemIcon {
    width: 1.14583vw;
    height: 1.14583vw;
    margin-right: 0.78125vw; } }

.main_navbar_navItemIcon path {
  fill: #A8A7A7;
  transition: 0.3s all ease-in-out;
  stroke: #ffffff; }

.main_navbar_profileSectiondownArrow {
  transition: 0.3s all ease-in-out;
  -webkit-transform: translateY(-0.26042vw);
          transform: translateY(-0.26042vw);
  opacity: 0;
  margin-left: auto; }
  @media (min-width: 1300px) {
    .main_navbar_profileSectiondownArrow {
      width: 0.625vw;
      height: 0.33854vw;
      margin-right: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_profileSectiondownArrow {
      width: 0.69444vw;
      height: 0.37616vw;
      margin-right: 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_profileSectiondownArrow {
      width: 0.625vw;
      height: 0.33854vw;
      margin-right: 1.04167vw; } }
  .main_navbar_profileSectiondownArrow svg {
    display: block;
    width: 100%;
    height: 100%; }

.main_navbar_profileSectionContainer {
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer; }
  .main_navbar_profileSectionContainer:hover {
    background: linear-gradient(0deg, rgba(0, 173, 230, 0.04), rgba(0, 173, 230, 0.04)), #FFFFFF; }
    .main_navbar_profileSectionContainer:hover .main_navbar_profileSectiondownArrow {
      -webkit-transform: translateY(0vw);
              transform: translateY(0vw);
      opacity: 1; }
  @media (min-width: 1300px) {
    .main_navbar_profileSectionContainer {
      width: 13.90625vw;
      height: 100%;
      padding-left: 0.83333vw;
      box-shadow: 0vw 0.20833vw 0.625vw rgba(0, 0, 0, 0.12);
      border-left: 1px solid rgba(0, 0, 0, 0.08); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_profileSectionContainer {
      width: 15.45139vw;
      height: 100%;
      padding-left: 0.92593vw;
      box-shadow: 0vw 0.23148vw 0.69444vw rgba(0, 0, 0, 0.12);
      border-left: 1px solid rgba(0, 0, 0, 0.08); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_profileSectionContainer {
      width: 13.90625vw;
      height: 100%;
      padding-left: 0.83333vw;
      box-shadow: 0vw 0.20833vw 0.625vw rgba(0, 0, 0, 0.12);
      border-left: 1px solid rgba(0, 0, 0, 0.08); } }

.main_navbar_profileDropdown {
  box-sizing: border-box;
  position: absolute;
  background: #ffffff; }
  @media (min-width: 1300px) {
    .main_navbar_profileDropdown {
      width: 12.86458vw;
      border-radius: 0.41667vw;
      box-shadow: 0vw 0vw 0.83333vw rgba(56, 56, 56, 0.12);
      top: 3.64583vw;
      left: 0;
      padding: 1.25vw;
      min-width: 12.86458vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_profileDropdown {
      width: 14.29398vw;
      border-radius: 0.46296vw;
      box-shadow: 0vw 0vw 0.92593vw rgba(56, 56, 56, 0.12);
      top: 4.05093vw;
      left: 0;
      padding: 1.38889vw;
      min-width: 14.29398vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_profileDropdown {
      width: 12.86458vw;
      border-radius: 0.41667vw;
      box-shadow: 0vw 0vw 0.83333vw rgba(56, 56, 56, 0.12);
      top: 3.64583vw;
      left: 0;
      padding: 1.25vw;
      min-width: 12.86458vw; } }

.main_navbar_switch_account_text {
  font-weight: 700; }
  @media (min-width: 1300px) {
    .main_navbar_switch_account_text {
      font-size: 0.72917vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_switch_account_text {
      font-size: 0.81019vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_switch_account_text {
      font-size: 0.72917vw !important; } }

.main_navbar__dropdown_hr {
  width: 100%; }
  @media (min-width: 1300px) {
    .main_navbar__dropdown_hr {
      height: 0.05208vw;
      margin-top: 1.25vw;
      margin-bottom: 1.25vw;
      background: rgba(0, 0, 0, 0.2); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar__dropdown_hr {
      height: 0.05787vw;
      margin-top: 1.38889vw;
      margin-bottom: 1.38889vw;
      background: rgba(0, 0, 0, 0.2); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar__dropdown_hr {
      height: 0.05208vw;
      margin-top: 1.25vw;
      margin-bottom: 1.25vw;
      background: rgba(0, 0, 0, 0.2); } }

@media (min-width: 1300px) {
  .main_navbar_profileDropdown__content {
    display: flex;
    flex-direction: column;
    align-items: center; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .main_navbar_profileDropdown__content {
    display: flex;
    flex-direction: column;
    align-items: center; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .main_navbar_profileDropdown__content {
    display: flex;
    flex-direction: column;
    align-items: center; } }

@media (min-width: 1300px) {
  .main_navbar_dropdown_item {
    width: 100%;
    height: 0.88542vw;
    grid-gap: 0.52083vw;
    gap: 0.52083vw;
    margin-top: 1.5625vw;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s all ease-in-out; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .main_navbar_dropdown_item {
    width: 100%;
    height: 0.9838vw;
    grid-gap: 0.5787vw;
    gap: 0.5787vw;
    margin-top: 1.73611vw;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s all ease-in-out; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .main_navbar_dropdown_item {
    width: 100%;
    height: 0.88542vw;
    grid-gap: 0.52083vw;
    gap: 0.52083vw;
    margin-top: 1.5625vw;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s all ease-in-out; } }

.main_navbar_dropdown_item:first-child {
  margin-top: 0; }

.main_navbar_dropdown_item:hover {
  background: rgba(0, 173, 230, 0.04); }

.main_navbar_dropdown_text {
  font-weight: 600;
  color: #504F4F; }
  @media (min-width: 1300px) {
    .main_navbar_dropdown_text {
      font-size: 1.04167vw;
      margin-left: 0.98958vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_dropdown_text {
      font-size: 1.15741vw;
      margin-left: 1.09954vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_dropdown_text {
      font-size: 1.04167vw;
      margin-left: 0.98958vw; } }
  .main_navbar_dropdown_text.danger {
    color: #FF5744;
    font-weight: 700; }

@media (min-width: 1300px) {
  .main_navbar_dropdown_item_icon {
    width: 1.25vw;
    height: 1.25vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .main_navbar_dropdown_item_icon {
    width: 1.38889vw;
    height: 1.38889vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .main_navbar_dropdown_item_icon {
    width: 1.25vw;
    height: 1.25vw; } }

.main_navbar_dropdown_item_icon svg {
  display: block;
  width: 100%;
  height: 100%; }

.main_navbar_profilePicture {
  background: #e3648b;
  border-radius: 50%;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  @media (min-width: 1300px) {
    .main_navbar_profilePicture {
      max-width: 2.5vw;
      max-height: 2.5vw;
      min-width: 2.5vw;
      min-height: 2.5vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_profilePicture {
      max-width: 2.77778vw;
      max-height: 2.77778vw;
      min-width: 2.77778vw;
      min-height: 2.77778vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_profilePicture {
      max-width: 2.5vw;
      max-height: 2.5vw;
      min-width: 2.5vw;
      min-height: 2.5vw; } }

.main_navbar_profilePicture:not(:nth-child(1)) {
  left: -10px; }

.main_navbar_profileIconContainer {
  display: flex;
  flex-direction: row;
  margin-right: 2.60417vw;
  border-radius: 1.35417vw;
  cursor: pointer;
  box-shadow: 0 0.15625vw 0.3125vw 0 rgba(0, 0, 0, 0.08);
  transition: 0.5s all ease-in-out; }

.main_navbar_profilePicture_inset_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 50%; }
  @media (min-width: 1300px) {
    .main_navbar_profilePicture_inset_overlay {
      border: 0.26042vw solid rgba(250, 250, 250, 0.6); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_profilePicture_inset_overlay {
      border: 0.28935vw solid rgba(250, 250, 250, 0.6); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_profilePicture_inset_overlay {
      border: 0.26042vw solid rgba(250, 250, 250, 0.6); } }

.main_navbar_user_name {
  color: #504F4F;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  @media (min-width: 1300px) {
    .main_navbar_user_name {
      max-width: 7.8125vw;
      font-size: 0.9375vw;
      line-height: 1.30208vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_user_name {
      max-width: 8.68056vw;
      font-size: 1.04167vw;
      line-height: 1.44676vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_user_name {
      max-width: 7.8125vw;
      font-size: 0.9375vw;
      line-height: 1.30208vw; } }

.main_navbar_user_grade {
  color: #A8A7A7;
  font-weight: 600; }
  @media (min-width: 1300px) {
    .main_navbar_user_grade {
      font-size: 0.72917vw;
      margin-top: 0.05208vw;
      line-height: 0.98958vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_user_grade {
      font-size: 0.81019vw;
      margin-top: 0.05787vw;
      line-height: 1.09954vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_user_grade {
      font-size: 0.72917vw;
      margin-top: 0.05208vw;
      line-height: 0.98958vw; } }

@media (min-width: 1300px) {
  .main_navbar_profileSectionBody {
    margin-left: 0.83333vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .main_navbar_profileSectionBody {
    margin-left: 0.92593vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .main_navbar_profileSectionBody {
    margin-left: 0.83333vw; } }

.main_navbar_profileIconContainerNoReferral {
  background: #e0fcfd; }
  .main_navbar_profileIconContainerNoReferral:hover {
    background: #d2fdff;
    box-shadow: 0 0.15625vw 0.3125vw 0 rgba(0, 0, 0, 0.08), 0 0 0 0.10417vw rgba(0, 173, 229, 0.4); }

.main_navbar_creditsContainer {
  height: 2.65625vw;
  border-radius: 1.35417vw 0 0 1.35417vw;
  background-image: linear-gradient(to bottom, #00ade6, #34e4ea);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.52083vw 0 0.36458vw; }

.main_navbar_profileIcon {
  height: 2.65625vw;
  border-radius: 0 1.35417vw 1.35417vw 0;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center; }

@media (min-width: 1300px) {
  .main_navbar_profileIconNoReferral {
    padding: 0 0.52083vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .main_navbar_profileIconNoReferral {
    padding: 0 0.5787vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .main_navbar_profileIconNoReferral {
    padding: 0 0.52083vw; } }

.main_navbar_profileWrapper {
  width: 1.64062vw;
  height: 1.86458vw;
  margin-right: 0.98958vw; }

.main_navbar_profileWrapperNoReferral {
  margin-right: 0px; }

.main_navbar_noMargin {
  margin-right: 0; }

.main_navbar_activeNav {
  color: #00ade6; }
  .main_navbar_activeNav .main_navbar_navItemIcon path {
    fill: #80d6f3;
    stroke: #00ade6; }

.main_navbar_arrowUp {
  position: absolute;
  width: 0px;
  height: 0px;
  border-left: 1.08073vw solid transparent;
  border-right: 1.08073vw solid transparent;
  border-bottom: 0.79688vw solid #e0fcfd;
  top: 2.60417vw;
  right: 2.34375vw; }

.main_navbar_arrowUpNoReferral {
  right: 0.20833vw; }

.main_navbar_dropDownContainer {
  position: absolute;
  width: 14.58333vw;
  top: 3.40104vw;
  right: -0.52083vw;
  border-radius: 10px;
  z-index: 9999;
  overflow: hidden;
  background-color: #e0fcfd;
  box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08); }

.main_navbar_dropDownBG {
  position: absolute;
  width: 100%;
  margin: -12.5vw 0px 0px 0px;
  z-index: -1;
  top: 0px;
  left: 0px; }

.main_navbar_dropDownItemsLayout {
  display: flex;
  flex-direction: column;
  height: 100%; }

.main_navbar_dropDownRow {
  display: flex;
  flex: 1 1;
  padding-left: 1.13021vw;
  align-items: center;
  min-height: 3.95833vw;
  transition: 0.3s all ease-in-out;
  background: transparent; }
  .main_navbar_dropDownRow:hover {
    background: #fff; }
    .main_navbar_dropDownRow:hover .main_navbar_rowText {
      -webkit-transform: translateX(0.52083vw);
              transform: translateX(0.52083vw); }

.main_navbar_rowText {
  padding-left: 0.78125vw;
  font-family: Nunito-SemiBold;
  font-size: 1.14583vw;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  transition: 0.2s all ease-in-out;
  letter-spacing: normal;
  text-align: center;
  color: #004e64; }

.main_navbar_horizontalLine {
  border: 1px solid rgba(255, 255, 255, 0.5);
  width: 80%;
  border-radius: 50%;
  align-self: center; }

.main_navbar_settingsIcon {
  width: 1.17063vw;
  height: 1.17063vw;
  position: relative; }

.main_navbar_logoutIcon {
  width: 1.15432vw;
  height: 1.15432vw;
  position: relative; }

.main_navbar_link {
  text-decoration: none; }

.main_navbar_timerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 6.77083vw; }

@media screen and (max-width: 1000px) {
  .main_navbar_container {
    width: 100%;
    height: 13.04348vw;
    justify-content: space-between; }
  .main_navbar_navContainer {
    display: none !important; }
  .main_navbar_tekieLogo {
    width: 17.3913vw;
    height: 9.66184vw;
    margin-left: 23%; }
  .main_navbar_tekie_school_logo {
    width: 14.49275vw;
    height: 14.49275vw;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    border: 1.44928vw solid #00ADE6;
    margin-top: 2.89855vw;
    margin-left: 20%; }
  .main_navbar_profileIconContainer {
    opacity: 0;
    pointer-events: none;
    margin-right: 3.86473vw;
    border-radius: 6.28019vw;
    cursor: pointer;
    box-shadow: 0 0.72464vw 1.44928vw 0 rgba(0, 0, 0, 0.16); }
  .main_navbar_creditsContainer {
    height: 8.9372vw;
    border-radius: 6.28019vw 0 0 6.28019vw;
    background-image: linear-gradient(to bottom, #00ade6, #34e4ea);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 2.41546vw 0 1.69082vw; }
  .main_navbar_coin {
    width: 4.90338vw;
    height: 4.90338vw;
    border-radius: 50%; }
  .main_navbar_profileIcon {
    height: 8.9372vw;
    border-radius: 0 6.28019vw 6.28019vw 0; }
  .main_navbar_creditsText {
    font-family: Nunito;
    font-size: 3.86473vw;
    margin-left: 1.44928vw; }
  .main_navbar_profileWrapper {
    width: 4.90338vw;
    height: 5.55556vw;
    margin-right: 3.1401vw; }
  .main_navbar_arrowUp {
    position: absolute;
    width: 0px;
    height: 0px;
    border-left: 3.5628vw solid transparent;
    border-right: 3.5628vw solid transparent;
    border-bottom: 3.69565vw solid #c4eff5;
    top: 9.17874vw;
    right: 2.34375vw; }
  .main_navbar_dropDownContainer {
    position: absolute;
    width: 32.6087vw;
    top: 13.04348vw;
    right: -1.93237vw;
    border-radius: 1.44928vw; }
  .main_navbar_settingsIcon {
    width: 4.94589vw;
    height: 4.70435vw;
    position: relative; }
  .main_navbar_logoutIcon {
    width: 4.62874vw;
    height: 4.62874vw;
    position: relative;
    right: -2px; }
  .main_navbar_dropDownRow {
    padding-left: 2.41546vw;
    min-height: 9.17874vw; }
  .main_navbar_rowText {
    padding-left: 1.93237vw;
    font-family: Nunito-Bold;
    font-size: 3.86473vw; }
  .main_navbar_horizontalLine {
    border: 0.5px solid #004e64;
    width: 90%; }
  .main_navbar_hamburgerWrapper {
    height: 60%;
    width: 8%;
    margin-left: 3%;
    margin-top: 2%;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(/static/media/Hamburger.77f934f0.svg); } }

@media screen and (min-width: 475px) and (max-width: 500px) {
  .main_navbar_container {
    height: 12.31884vw; }
  .main_navbar_profileWrapper {
    margin-right: 2.657vw !important; } }

@media screen and (min-width: 500px) and (max-width: 550px) {
  .main_navbar_container {
    height: 12.31884vw; }
  .main_navbar_profileWrapper {
    margin-right: 2.17391vw; }
  .main_navbar_hamburgerWrapper {
    width: 7%; } }

.main_navbar_savedCodeIcon {
  background-image: url(/static/media/All_code.f985c162.svg);
  width: 4.70435vw;
  height: 4.4628vw;
  position: relative;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }

.main_navbar_studentCommunityIcon {
  background-image: url(/static/media/studentCommunityIcon.43f7781f.svg);
  width: 4.70435vw;
  height: 4.4628vw;
  position: relative;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }

@media screen and (min-width: 550px) and (max-width: 600px) {
  .main_navbar_container {
    height: 12.07729vw; }
  .main_navbar_profileWrapper {
    margin-right: 2.41546vw; }
  .main_navbar_creditsText {
    font-size: 3.38164vw; }
  .main_navbar_hamburgerWrapper {
    width: 7%; } }

@media screen and (min-width: 600px) and (max-width: 700px) {
  .main_navbar_creditsText {
    font-size: 3.1401vw;
    margin-left: 1.20773vw; }
  .main_navbar_profileIcon {
    height: 7.72947vw; }
  .main_navbar_container {
    width: 100%;
    height: 11.83575vw; }
  .main_navbar_creditsContainer {
    height: 8.45411vw; }
  .main_navbar_tekieLogo {
    width: 15.94203vw;
    height: 8.69565vw;
    margin-left: 20%; }
  .main_navbar_tekie_school_logo {
    width: 14.49275vw;
    height: 14.49275vw;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    border: 1.44928vw solid #00ADE6;
    margin-top: 2.89855vw;
    margin-left: 20%; }
  .main_navbar_profileWrapper {
    width: 4.66184vw;
    height: 5.31401vw;
    margin-right: 3.1401vw; }
  .main_navbar_coin {
    width: 4.66184vw;
    height: 4.66184vw;
    border-radius: 50%; }
  .main_navbar_dropDownContainer {
    width: 31.40097vw; }
  .main_navbar_settingsIcon {
    width: 4.70435vw;
    height: 4.4628vw; }
  .main_navbar_logoutIcon {
    width: 4.3872vw;
    height: 4.3872vw; }
  .main_navbar_dropDownRow {
    padding-left: 1.93237vw;
    min-height: 8.69565vw; }
  .main_navbar_rowText {
    padding-left: 1.69082vw;
    font-family: Nunito-Bold;
    font-size: 3.62319vw; }
  .hamburgerWrapper main_navbar_ {
    width: 6.5%; }
  .main_navbar_savedCodeIcon {
    width: 4.70435vw;
    height: 4.4628vw; }
  .main_navbar_studentCommunityIcon {
    width: 4.70435vw;
    height: 4.4628vw; } }

@media screen and (min-width: 700px) and (max-width: 1000px) {
  main_navbar_.creditsText {
    font-size: 2.41546vw;
    margin-left: 0.96618vw; }
  .main_navbar_profileIcon {
    height: 6.76329vw; }
  .main_navbar_container {
    width: 100%;
    height: 9.42029vw; }
  .main_navbar_tekieLogo {
    width: 14.49275vw;
    height: 7.24638vw;
    margin-left: 0%; }
  .main_navbar_tekie_school_logo {
    width: 14.49275vw;
    height: 14.49275vw;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    border: 1.44928vw solid #00ADE6;
    margin-top: 2.89855vw;
    margin-left: 20%; }
  .main_navbar_creditsContainer {
    height: 6.76329vw; }
  .main_navbar_profileWrapper {
    width: 3.69565vw;
    height: 4.10628vw;
    margin-right: 2.17391vw; }
  .main_navbar_coin {
    width: 3.69565vw;
    height: 3.69565vw; }
  .main_navbar_arrowUp {
    position: absolute;
    width: 0px;
    height: 0px;
    border-left: 2.83816vw solid transparent;
    border-right: 2.83816vw solid transparent;
    border-bottom: 2.97101vw solid #c4eff5;
    top: 7.24638vw;
    right: 2.34375vw; }
  .main_navbar_dropDownContainer {
    width: 26.57005vw;
    top: 10.38647vw; }
  .main_navbar_settingsIcon {
    width: 3.73816vw;
    height: 3.49662vw; }
  .main_navbar_logoutIcon {
    width: 3.42101vw;
    height: 3.42101vw; }
  .main_navbar_dropDownRow {
    padding-left: 1.69082vw;
    min-height: 7.24638vw; }
  .main_navbar_rowText {
    padding-left: 0.96618vw;
    font-family: Nunito-Bold;
    font-size: 2.89855vw; }
  .main_navbar_hamburgerWrapper {
    width: 6%;
    margin-top: 1.8%; }
  .main_navbar_savedCodeIcon {
    width: 4.70435vw;
    height: 4.4628vw; }
  .main_navbar_studentCommunityIcon {
    width: 4.70435vw;
    height: 4.4628vw; } }

@media screen and (min-width: 800px) and (max-width: 900px) {
  .main_navbar_creditsText {
    font-size: 2.657vw;
    margin-left: 0.96618vw; }
  .main_navbar_profileIcon {
    height: 7.48792vw; }
  .main_navbar_container {
    height: 10.14493vw; }
  .main_navbar_tekieLogo {
    width: 14.97585vw;
    height: 7.97101vw;
    margin-left: 20%; }
  .main_navbar_tekie_school_logo {
    width: 14.49275vw;
    height: 14.49275vw;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    border: 1.44928vw solid #00ADE6;
    margin-top: 2.89855vw;
    margin-left: 20%; }
  .main_navbar_creditsContainer {
    height: 7.24638vw; }
  .main_navbar_profileWrapper {
    width: 4.17874vw;
    height: 4.58937vw;
    margin-right: 2.41546vw; }
  .main_navbar_coin {
    width: 4.17874vw;
    height: 4.17874vw; }
  .main_navbar_savedCodeIcon {
    width: 5.31401vw;
    height: 5.31401vw; }
  .main_navbar_studentCommunityIcon {
    width: 5.31401vw;
    height: 5.31401vw; } }

@media screen and (min-width: 900px) and (max-width: 1000px) {
  .main_navbar_creditsText {
    font-size: 2.41546vw;
    margin-left: 0.72464vw; }
  .main_navbar_profileIcon {
    height: 6.76329vw; }
  .main_navbar_container {
    height: 9.17874vw; }
  .main_navbar_tekieLogo {
    width: 14.00966vw;
    height: 7.24638vw;
    margin-left: 20%; }
  .main_navbar_tekie_school_logo {
    width: 4.83092vw;
    height: 4.83092vw;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    border: 0.48309vw solid #00ADE6;
    margin-top: 0.24155vw;
    margin-left: unset; }
  .main_navbar_creditsContainer {
    height: 6.28019vw; }
  .main_navbar_profileWrapper {
    width: 3.69565vw;
    height: 3.86473vw;
    margin-right: 1.93237vw; }
  .main_navbar_coin {
    width: 3.69565vw;
    height: 3.69565vw; }
  .main_navbar_profileIconContainer {
    height: 6.28019vw; }
  .main_navbar_dropDownContainer {
    width: 24.15459vw; }
  .main_navbar_settingsIcon {
    width: 3.25507vw;
    height: 3.01353vw; }
  .main_navbar_logoutIcon {
    width: 2.93792vw;
    height: 2.93792vw; }
  .main_navbar_dropDownRow {
    padding-left: 1.69082vw;
    min-height: 6.28019vw; }
  .main_navbar_rowText {
    font-size: 2.41546vw; }
  .main_navbar_hamburgerWrapper {
    margin-top: 1.5%; }
  .main_navbar_savedCodeIcon {
    width: 5.31401vw;
    height: 5.31401vw; }
  .main_navbar_studentCommunityIcon {
    width: 5.31401vw;
    height: 5.31401vw; } }

@media screen and (min-width: 1000px) {
  .main_navbar_savedCodeIcon {
    width: 1.5vw;
    height: 4.34783vw; }
  .main_navbar_studentCommunityIcon {
    width: 1.5vw;
    height: 4.34783vw; } }

/* dropdown menu */
.main_navbar_menus {
  position: relative;
  cursor: pointer;
  transition: 0.3s all ease-in-out; }

.main_navbar_drop {
  box-shadow: 0 4px 5px #ccc;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  position: absolute;
  z-index: 25;
  box-sizing: border-box;
  color: black;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: rotateX(90deg);
          transform: rotateX(90deg);
  background: #FFFFFF;
  border-radius: 4px;
  transition: all 0.5s ease-in-out; }
  @media (min-width: 1300px) {
    .main_navbar_drop {
      top: 4.42708vw;
      width: 14.0625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_drop {
      top: 4.91898vw;
      width: 15.625vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_drop {
      top: 4.42708vw;
      width: 14.0625vw; } }

.main_navbar_drop a {
  transition: 300ms all ease-in-out;
  text-decoration: none;
  display: block;
  position: relative;
  color: inherit; }

.main_navbar_drop::before {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border: 20px solid transparent;
  border-bottom: 25px solid #E6F7FD;
  left: 25px;
  top: -43px;
  z-index: 1; }

.main_navbar_drop div {
  display: block;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  color: #504F4F;
  transition: 0.3s all ease-in-out; }
  @media (min-width: 1300px) {
    .main_navbar_drop div {
      font-size: 1.22396vw;
      padding: 0.78125vw;
      line-height: 1.45833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_drop div {
      font-size: 1.35995vw;
      padding: 0.86806vw;
      line-height: 1.62037vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_drop div {
      font-size: 1.22396vw;
      padding: 0.78125vw;
      line-height: 1.45833vw; } }
  .main_navbar_drop div:hover {
    background-color: #E6F7FD;
    color: #00ADE6; }
    .main_navbar_drop div:hover > a {
      -webkit-transform: translateX(0.52083vw);
              transform: translateX(0.52083vw); }

.main_navbar_drop div.main_navbar_activeNav {
  background-color: #E6F7FD;
  color: #00ADE6 !important; }

.main_navbar_dropShow {
  z-index: 1;
  opacity: 1;
  visibility: visible;
  -webkit-transform: rotate(0);
          transform: rotate(0); }

.main_navbar_drop_course.main_navbar_dropShowCourse {
  z-index: 99999;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
          transform: translateY(0px); }

.main_navbar_drop_course {
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  z-index: 0;
  box-sizing: border-box;
  color: black;
  transition: all 0.3s ease-in-out;
  top: unset;
  width: unset;
  padding: unset;
  box-shadow: none;
  border: none;
  border-radius: u;
  max-height: unset;
  pointer-events: all;
  background: unset;
  position: relative;
  -webkit-transform: unset;
          transform: unset;
  visibility: visible;
  opacity: 1;
  overflow: visible; }
  @media (min-width: 1300px) {
    .main_navbar_drop_course {
      top: 2.55208vw;
      width: 35.78125vw;
      padding: 0.83333vw 1.25vw;
      box-shadow: 0vw 0.20833vw 1.04167vw rgba(0, 25, 34, 0.1);
      border: 0.05208vw solid #E0E0E0;
      border-radius: 0.20833vw;
      max-height: 70vh;
      pointer-events: none;
      background: #FFFFFF;
      position: absolute;
      -webkit-transform: translateY(10px);
              transform: translateY(10px);
      visibility: hidden;
      opacity: 0;
      overflow: scroll; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_drop_course {
      top: 2.83565vw;
      width: 39.75694vw;
      padding: 0.92593vw 1.38889vw;
      box-shadow: 0vw 0.23148vw 1.15741vw rgba(0, 25, 34, 0.1);
      border: 0.05787vw solid #E0E0E0;
      border-radius: 0.23148vw;
      max-height: 70vh;
      pointer-events: none;
      background: #FFFFFF;
      position: absolute;
      -webkit-transform: translateY(10px);
              transform: translateY(10px);
      visibility: hidden;
      opacity: 0;
      overflow: scroll; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_drop_course {
      top: 2.55208vw;
      width: 35.78125vw;
      padding: 0.83333vw 1.25vw;
      box-shadow: 0vw 0.20833vw 1.04167vw rgba(0, 25, 34, 0.1);
      border: 0.05208vw solid #E0E0E0;
      border-radius: 0.20833vw;
      max-height: 70vh;
      pointer-events: none;
      background: #FFFFFF;
      position: absolute;
      -webkit-transform: translateY(10px);
              transform: translateY(10px);
      visibility: hidden;
      opacity: 0;
      overflow: scroll; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .main_navbar_drop_course else {
      top: unset; }
    .main_navbar_drop_course else {
      width: unset; }
    .main_navbar_drop_course else {
      padding: unset; }
    .main_navbar_drop_course else {
      box-shadow: none; }
    .main_navbar_drop_course else {
      border: none; }
    .main_navbar_drop_course else {
      border-radius: u; }
    .main_navbar_drop_course else {
      max-height: unset; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .main_navbar_drop_course else {
      top: unset; }
    .main_navbar_drop_course else {
      width: unset; }
    .main_navbar_drop_course else {
      padding: unset; }
    .main_navbar_drop_course else {
      box-shadow: none; }
    .main_navbar_drop_course else {
      border: none; }
    .main_navbar_drop_course else {
      border-radius: u; }
    .main_navbar_drop_course else {
      max-height: unset; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .main_navbar_drop_course else {
      top: unset; }
    .main_navbar_drop_course else {
      width: unset; }
    .main_navbar_drop_course else {
      padding: unset; }
    .main_navbar_drop_course else {
      box-shadow: none; }
    .main_navbar_drop_course else {
      border: none; }
    .main_navbar_drop_course else {
      border-radius: u; }
    .main_navbar_drop_course else {
      max-height: unset; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .main_navbar_drop_course else {
      top: unset; }
    .main_navbar_drop_course else {
      width: unset; }
    .main_navbar_drop_course else {
      padding: unset; }
    .main_navbar_drop_course else {
      box-shadow: none; }
    .main_navbar_drop_course else {
      border: none; }
    .main_navbar_drop_course else {
      border-radius: u; }
    .main_navbar_drop_course else {
      max-height: unset; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .main_navbar_drop_course else {
      top: unset; }
    .main_navbar_drop_course else {
      width: unset; }
    .main_navbar_drop_course else {
      padding: unset; }
    .main_navbar_drop_course else {
      box-shadow: none; }
    .main_navbar_drop_course else {
      border: none; }
    .main_navbar_drop_course else {
      border-radius: u; }
    .main_navbar_drop_course else {
      max-height: unset; } }

.main_navbar_drop_course a {
  transition: 300ms all ease-in-out;
  text-decoration: none;
  display: block;
  position: relative;
  color: inherit; }

.main_navbar_drop_course > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  color: #504F4F;
  transition: 0.3s all ease-in-out; }
  @media (min-width: 1300px) {
    .main_navbar_drop_course > div {
      padding: 0.83333vw 0vw;
      line-height: 1.45833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_drop_course > div {
      padding: 0.92593vw 0vw;
      line-height: 1.62037vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_drop_course > div {
      padding: 0.83333vw 0vw;
      line-height: 1.45833vw; } }
  .main_navbar_drop_course > div:hover {
    color: #00ADE6; }
    .main_navbar_drop_course > div:hover > a {
      -webkit-transform: translateX(0.52083vw);
              transform: translateX(0.52083vw); }

.main_navbar_drop_course div.main_navbar_activeNav {
  background: rgba(230, 247, 253, 0.5);
  color: #00ADE6 !important; }
  @media (min-width: 1300px) {
    .main_navbar_drop_course div.main_navbar_activeNav {
      background: rgba(230, 247, 253, 0.3); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_drop_course div.main_navbar_activeNav {
      background: rgba(230, 247, 253, 0.3); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_drop_course div.main_navbar_activeNav {
      background: rgba(230, 247, 253, 0.3); } }

.main_navbar_thumbnail {
  width: 28.01932vw;
  height: 26.81159vw;
  margin-right: 3.86473vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center; }
  @media (min-width: 1300px) {
    .main_navbar_thumbnail {
      width: 6.04167vw;
      height: 5.78125vw;
      margin-right: 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_thumbnail {
      width: 6.71296vw;
      height: 6.42361vw;
      margin-right: 0.92593vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_thumbnail {
      width: 6.04167vw;
      height: 5.78125vw;
      margin-right: 0.83333vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .main_navbar_thumbnail {
      width: 23.34944vw;
      height: 22.343vw;
      margin-right: 3.22061vw; }
      .main_navbar_thumbnail else {
        width: 116px; }
      .main_navbar_thumbnail else {
        height: 111px; }
      .main_navbar_thumbnail else {
        margin-right: 16px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .main_navbar_thumbnail {
      width: 18.67955vw;
      height: 17.8744vw;
      margin-right: 2.57649vw; }
      .main_navbar_thumbnail else {
        width: 116px; }
      .main_navbar_thumbnail else {
        height: 111px; }
      .main_navbar_thumbnail else {
        margin-right: 16px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .main_navbar_thumbnail {
      width: 16.48196vw;
      height: 15.77153vw;
      margin-right: 2.27337vw; }
      .main_navbar_thumbnail else {
        width: 116px; }
      .main_navbar_thumbnail else {
        height: 111px; }
      .main_navbar_thumbnail else {
        margin-right: 16px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .main_navbar_thumbnail {
      width: 14.74701vw;
      height: 14.11137vw;
      margin-right: 2.03407vw; }
      .main_navbar_thumbnail else {
        width: 116px; }
      .main_navbar_thumbnail else {
        height: 111px; }
      .main_navbar_thumbnail else {
        margin-right: 16px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .main_navbar_thumbnail {
      width: 13.34254vw;
      height: 12.76743vw;
      margin-right: 1.84035vw; }
      .main_navbar_thumbnail else {
        width: 116px; }
      .main_navbar_thumbnail else {
        height: 111px; }
      .main_navbar_thumbnail else {
        margin-right: 16px; } }

.main_navbar_drop_course div.main_navbar_course_title {
  color: #504F4F;
  display: block !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 4.34783vw;
  width: 48.30918vw;
  font-weight: bold; }
  @media (min-width: 1300px) {
    .main_navbar_drop_course div.main_navbar_course_title {
      font-size: 1.35417vw;
      width: 21.875vw;
      font-weight: bold; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_drop_course div.main_navbar_course_title {
      font-size: 1.50463vw;
      width: 24.30556vw;
      font-weight: bold; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_drop_course div.main_navbar_course_title {
      font-size: 1.35417vw;
      width: 21.875vw;
      font-weight: bold; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .main_navbar_drop_course div.main_navbar_course_title {
      font-size: 3.62319vw;
      width: 40.25765vw; }
      .main_navbar_drop_course div.main_navbar_course_title else {
        font-size: 18px; }
      .main_navbar_drop_course div.main_navbar_course_title else {
        width: 200px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .main_navbar_drop_course div.main_navbar_course_title {
      font-size: 2.89855vw;
      width: 32.20612vw; }
      .main_navbar_drop_course div.main_navbar_course_title else {
        font-size: 18px; }
      .main_navbar_drop_course div.main_navbar_course_title else {
        width: 200px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .main_navbar_drop_course div.main_navbar_course_title {
      font-size: 2.55754vw;
      width: 28.41716vw; }
      .main_navbar_drop_course div.main_navbar_course_title else {
        font-size: 18px; }
      .main_navbar_drop_course div.main_navbar_course_title else {
        width: 200px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .main_navbar_drop_course div.main_navbar_course_title {
      font-size: 2.28833vw;
      width: 25.42588vw; }
      .main_navbar_drop_course div.main_navbar_course_title else {
        font-size: 18px; }
      .main_navbar_drop_course div.main_navbar_course_title else {
        width: 200px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .main_navbar_drop_course div.main_navbar_course_title {
      font-size: 2.07039vw;
      width: 23.00437vw; }
      .main_navbar_drop_course div.main_navbar_course_title else {
        font-size: 18px; }
      .main_navbar_drop_course div.main_navbar_course_title else {
        width: 200px; } }

@media (min-width: 1300px) {
  .main_navbar_noThumbnailText {
    width: 28.64583vw !important; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .main_navbar_noThumbnailText {
    width: 31.8287vw !important; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .main_navbar_noThumbnailText {
    width: 28.64583vw !important; } }

.main_navbar_drop_course div.main_navbar_activeNav div.main_navbar_course_title {
  color: #00ADE6; }

.main_navbar_course_arrow {
  width: 3.38164vw;
  height: 6.03865vw;
  margin-right: 3.38164vw; }
  @media (min-width: 1300px) {
    .main_navbar_course_arrow {
      width: 0.72917vw;
      height: 1.30208vw;
      margin-right: 0.72917vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_course_arrow {
      width: 0.81019vw;
      height: 1.44676vw;
      margin-right: 0.81019vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_course_arrow {
      width: 0.72917vw;
      height: 1.30208vw;
      margin-right: 0.72917vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .main_navbar_course_arrow {
      width: 2.81804vw;
      height: 5.03221vw;
      margin-right: 2.81804vw; }
      .main_navbar_course_arrow else {
        width: 14px; }
      .main_navbar_course_arrow else {
        height: 25px; }
      .main_navbar_course_arrow else {
        margin-right: 14px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .main_navbar_course_arrow {
      width: 2.25443vw;
      height: 4.02576vw;
      margin-right: 2.25443vw; }
      .main_navbar_course_arrow else {
        width: 14px; }
      .main_navbar_course_arrow else {
        height: 25px; }
      .main_navbar_course_arrow else {
        margin-right: 14px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .main_navbar_course_arrow {
      width: 1.9892vw;
      height: 3.55215vw;
      margin-right: 1.9892vw; }
      .main_navbar_course_arrow else {
        width: 14px; }
      .main_navbar_course_arrow else {
        height: 25px; }
      .main_navbar_course_arrow else {
        margin-right: 14px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .main_navbar_course_arrow {
      width: 1.77981vw;
      height: 3.17824vw;
      margin-right: 1.77981vw; }
      .main_navbar_course_arrow else {
        width: 14px; }
      .main_navbar_course_arrow else {
        height: 25px; }
      .main_navbar_course_arrow else {
        margin-right: 14px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .main_navbar_course_arrow {
      width: 1.61031vw;
      height: 2.87555vw;
      margin-right: 1.61031vw; }
      .main_navbar_course_arrow else {
        width: 14px; }
      .main_navbar_course_arrow else {
        height: 25px; }
      .main_navbar_course_arrow else {
        margin-right: 14px; } }
  .main_navbar_course_arrow path {
    stroke: #DCDCDC;
    transition: 0.3s all ease-in-out; }

.main_navbar_course_arrow.active path {
  stroke: #00ADE6; }

.main_navbar_hrLineCourse {
  opacity: 0.2;
  height: 0px !important;
  padding: 0px !important;
  margin-bottom: 2.41546vw;
  margin-top: 2.41546vw;
  border-top: 0.8px solid #000000; }
  @media (min-width: 1300px) {
    .main_navbar_hrLineCourse {
      margin-bottom: 0.83333vw;
      margin-top: 0.83333vw;
      border-top: 0.8px solid #000000; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_hrLineCourse {
      margin-bottom: 0.92593vw;
      margin-top: 0.92593vw;
      border-top: 0.8px solid #000000; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_hrLineCourse {
      margin-bottom: 0.83333vw;
      margin-top: 0.83333vw;
      border-top: 0.8px solid #000000; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .main_navbar_hrLineCourse {
      margin-bottom: 2.01288vw;
      margin-top: 2.01288vw; }
      .main_navbar_hrLineCourse else {
        margin-bottom: 10px; }
      .main_navbar_hrLineCourse else {
        margin-top: 10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .main_navbar_hrLineCourse {
      margin-bottom: 1.61031vw;
      margin-top: 1.61031vw; }
      .main_navbar_hrLineCourse else {
        margin-bottom: 10px; }
      .main_navbar_hrLineCourse else {
        margin-top: 10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .main_navbar_hrLineCourse {
      margin-bottom: 1.42086vw;
      margin-top: 1.42086vw; }
      .main_navbar_hrLineCourse else {
        margin-bottom: 10px; }
      .main_navbar_hrLineCourse else {
        margin-top: 10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .main_navbar_hrLineCourse {
      margin-bottom: 1.27129vw;
      margin-top: 1.27129vw; }
      .main_navbar_hrLineCourse else {
        margin-bottom: 10px; }
      .main_navbar_hrLineCourse else {
        margin-top: 10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .main_navbar_hrLineCourse {
      margin-bottom: 1.15022vw;
      margin-top: 1.15022vw; }
      .main_navbar_hrLineCourse else {
        margin-bottom: 10px; }
      .main_navbar_hrLineCourse else {
        margin-top: 10px; } }

.main_navbar_active_drop_item:hover .main_navbar_course_title {
  color: #00ADE6; }

.main_navbar_active_drop_item:hover .main_navbar_course_arrow path {
  stroke: #00ADE6; }

.main_navbar_up_next {
  display: flex;
  align-items: center;
  color: rgba(80, 79, 79, 0.5);
  font-size: 2.89855vw;
  margin-top: 1.44928vw; }
  @media (min-width: 1300px) {
    .main_navbar_up_next {
      font-size: 0.625vw;
      margin-top: 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_up_next {
      font-size: 0.69444vw;
      margin-top: 0.92593vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_up_next {
      font-size: 0.625vw;
      margin-top: 0.83333vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .main_navbar_up_next {
      font-size: 2.41546vw;
      margin-top: 1.20773vw; }
      .main_navbar_up_next else {
        font-size: 12px; }
      .main_navbar_up_next else {
        margin-top: 6px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .main_navbar_up_next {
      font-size: 1.93237vw;
      margin-top: 0.96618vw; }
      .main_navbar_up_next else {
        font-size: 12px; }
      .main_navbar_up_next else {
        margin-top: 6px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .main_navbar_up_next {
      font-size: 1.70503vw;
      margin-top: 0.85251vw; }
      .main_navbar_up_next else {
        font-size: 12px; }
      .main_navbar_up_next else {
        margin-top: 6px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .main_navbar_up_next {
      font-size: 1.52555vw;
      margin-top: 0.76278vw; }
      .main_navbar_up_next else {
        font-size: 12px; }
      .main_navbar_up_next else {
        margin-top: 6px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .main_navbar_up_next {
      font-size: 1.38026vw;
      margin-top: 0.69013vw; }
      .main_navbar_up_next else {
        font-size: 12px; }
      .main_navbar_up_next else {
        margin-top: 6px; } }

.main_navbar_up_next_arrow {
  position: relative;
  width: 2.89855vw;
  height: 2.89855vw;
  margin-left: 0.96618vw;
  top: -0.96618vw; }
  @media (min-width: 1300px) {
    .main_navbar_up_next_arrow {
      width: 0.83333vw;
      height: 0.83333vw;
      margin-left: 0.3125vw;
      top: -0.20833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_up_next_arrow {
      width: 0.92593vw;
      height: 0.92593vw;
      margin-left: 0.34722vw;
      top: -0.23148vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_up_next_arrow {
      width: 0.83333vw;
      height: 0.83333vw;
      margin-left: 0.3125vw;
      top: -0.20833vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .main_navbar_up_next_arrow {
      width: 2.41546vw;
      height: 2.41546vw;
      margin-left: 0.80515vw;
      top: -0.80515vw; }
      .main_navbar_up_next_arrow else {
        width: 12px; }
      .main_navbar_up_next_arrow else {
        height: 12px; }
      .main_navbar_up_next_arrow else {
        margin-left: 4px; }
      .main_navbar_up_next_arrow else {
        top: -4px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .main_navbar_up_next_arrow {
      width: 1.93237vw;
      height: 1.93237vw;
      margin-left: 0.64412vw;
      top: -0.64412vw; }
      .main_navbar_up_next_arrow else {
        width: 12px; }
      .main_navbar_up_next_arrow else {
        height: 12px; }
      .main_navbar_up_next_arrow else {
        margin-left: 4px; }
      .main_navbar_up_next_arrow else {
        top: -4px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .main_navbar_up_next_arrow {
      width: 1.70503vw;
      height: 1.70503vw;
      margin-left: 0.56834vw;
      top: -0.56834vw; }
      .main_navbar_up_next_arrow else {
        width: 12px; }
      .main_navbar_up_next_arrow else {
        height: 12px; }
      .main_navbar_up_next_arrow else {
        margin-left: 4px; }
      .main_navbar_up_next_arrow else {
        top: -4px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .main_navbar_up_next_arrow {
      width: 1.52555vw;
      height: 1.52555vw;
      margin-left: 0.50852vw;
      top: -0.50852vw; }
      .main_navbar_up_next_arrow else {
        width: 12px; }
      .main_navbar_up_next_arrow else {
        height: 12px; }
      .main_navbar_up_next_arrow else {
        margin-left: 4px; }
      .main_navbar_up_next_arrow else {
        top: -4px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .main_navbar_up_next_arrow {
      width: 1.38026vw;
      height: 1.38026vw;
      margin-left: 0.46009vw;
      top: -0.46009vw; }
      .main_navbar_up_next_arrow else {
        width: 12px; }
      .main_navbar_up_next_arrow else {
        height: 12px; }
      .main_navbar_up_next_arrow else {
        margin-left: 4px; }
      .main_navbar_up_next_arrow else {
        top: -4px; } }

.main_navbar_topic_title {
  color: #504F4F;
  font-size: 3.86473vw;
  margin-top: 0.48309vw;
  font-weight: 600;
  letter-spacing: -0.02em; }
  @media (min-width: 1300px) {
    .main_navbar_topic_title {
      font-size: 1.04167vw;
      margin-top: 0.15625vw;
      font-weight: bold; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_topic_title {
      font-size: 1.15741vw;
      margin-top: 0.17361vw;
      font-weight: bold; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_topic_title {
      font-size: 1.04167vw;
      margin-top: 0.15625vw;
      font-weight: bold; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .main_navbar_topic_title {
      font-size: 3.22061vw;
      margin-top: 0.40258vw; }
      .main_navbar_topic_title else {
        font-size: 16px; }
      .main_navbar_topic_title else {
        margin-top: 2px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .main_navbar_topic_title {
      font-size: 2.57649vw;
      margin-top: 0.32206vw; }
      .main_navbar_topic_title else {
        font-size: 16px; }
      .main_navbar_topic_title else {
        margin-top: 2px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .main_navbar_topic_title {
      font-size: 2.27337vw;
      margin-top: 0.28417vw; }
      .main_navbar_topic_title else {
        font-size: 16px; }
      .main_navbar_topic_title else {
        margin-top: 2px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .main_navbar_topic_title {
      font-size: 2.03407vw;
      margin-top: 0.25426vw; }
      .main_navbar_topic_title else {
        font-size: 16px; }
      .main_navbar_topic_title else {
        margin-top: 2px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .main_navbar_topic_title {
      font-size: 1.84035vw;
      margin-top: 0.23004vw; }
      .main_navbar_topic_title else {
        font-size: 16px; }
      .main_navbar_topic_title else {
        margin-top: 2px; } }

.main_navbar_course_completed_tag {
  text-transform: uppercase;
  width: -webkit-min-content;
  width: min-content;
  font-weight: bold;
  letter-spacing: 0.03em;
  border-radius: 0.96618vw;
  padding: 0.96618vw 1.20773vw;
  font-size: 2.89855vw;
  margin-top: 1.93237vw;
  background: rgba(101, 218, 122, 0.1);
  color: #65DA7A; }
  @media (min-width: 1300px) {
    .main_navbar_course_completed_tag {
      border-radius: 0.20833vw;
      padding: 0.20833vw 0.26042vw;
      font-size: 0.625vw;
      margin-top: 0.41667vw;
      background: rgba(66, 232, 209, 0.2);
      color: rgba(1, 170, 147, 0.5); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_course_completed_tag {
      border-radius: 0.23148vw;
      padding: 0.23148vw 0.28935vw;
      font-size: 0.69444vw;
      margin-top: 0.46296vw;
      background: rgba(66, 232, 209, 0.2);
      color: rgba(1, 170, 147, 0.5); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_course_completed_tag {
      border-radius: 0.20833vw;
      padding: 0.20833vw 0.26042vw;
      font-size: 0.625vw;
      margin-top: 0.41667vw;
      background: rgba(66, 232, 209, 0.2);
      color: rgba(1, 170, 147, 0.5); } }
  @media (min-width: 450px) and (max-width: 500px) {
    .main_navbar_course_completed_tag {
      border-radius: 0.80515vw;
      padding: 0.80515vw 1.00644vw;
      font-size: 2.41546vw;
      margin-top: 1.61031vw; }
      .main_navbar_course_completed_tag else {
        border-radius: 4px; }
      .main_navbar_course_completed_tag else {
        font-size: 12px; }
      .main_navbar_course_completed_tag else {
        margin-top: 8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .main_navbar_course_completed_tag {
      border-radius: 0.64412vw;
      padding: 0.64412vw 0.80515vw;
      font-size: 1.93237vw;
      margin-top: 1.28824vw; }
      .main_navbar_course_completed_tag else {
        border-radius: 4px; }
      .main_navbar_course_completed_tag else {
        font-size: 12px; }
      .main_navbar_course_completed_tag else {
        margin-top: 8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .main_navbar_course_completed_tag {
      border-radius: 0.56834vw;
      padding: 0.56834vw 0.71043vw;
      font-size: 1.70503vw;
      margin-top: 1.13669vw; }
      .main_navbar_course_completed_tag else {
        border-radius: 4px; }
      .main_navbar_course_completed_tag else {
        font-size: 12px; }
      .main_navbar_course_completed_tag else {
        margin-top: 8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .main_navbar_course_completed_tag {
      border-radius: 0.50852vw;
      padding: 0.50852vw 0.63565vw;
      font-size: 1.52555vw;
      margin-top: 1.01704vw; }
      .main_navbar_course_completed_tag else {
        border-radius: 4px; }
      .main_navbar_course_completed_tag else {
        font-size: 12px; }
      .main_navbar_course_completed_tag else {
        margin-top: 8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .main_navbar_course_completed_tag {
      border-radius: 0.46009vw;
      padding: 0.46009vw 0.57511vw;
      font-size: 1.38026vw;
      margin-top: 0.92017vw; }
      .main_navbar_course_completed_tag else {
        border-radius: 4px; }
      .main_navbar_course_completed_tag else {
        font-size: 12px; }
      .main_navbar_course_completed_tag else {
        margin-top: 8px; } }

.dropdown-text {
  white-space: nowrap;
  color: #504F4F;
  font-weight: 400; }
  @media (min-width: 1300px) {
    .dropdown-text {
      font-size: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .dropdown-text {
      font-size: 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .dropdown-text {
      font-size: 1.04167vw; } }

.profile-logout-button {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  height: unset;
  border: 2px solid #FF5744; }
  @media (min-width: 1300px) {
    .profile-logout-button {
      border-radius: 1.5625vw;
      padding: 0.3125vw 0.72917vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .profile-logout-button {
      border-radius: 1.73611vw;
      padding: 0.34722vw 0.81019vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .profile-logout-button {
      border-radius: 1.5625vw;
      padding: 0.3125vw 0.72917vw; } }

:root {
  --accent-color:$tekie-amethyst;
  --accent-color-110: $tekie-amethyst-dark; }

:root {
  --accent-color:$tekie-amethyst;
  --accent-color-110: $tekie-amethyst-dark; }

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  color: #282828;
  z-index: 999; }

.Spinner {
  /* Spins external container */
  -webkit-animation: container-rotate 2000ms linear infinite;
          animation: container-rotate 2000ms linear infinite;
  z-index: 999999999999; }

.Spinner-line {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0; }

.Spinner-line--animation {
  border-color: $tekie-amethyst;
  border-color: var(--accent-color);
  -webkit-animation: fill-unfill-rotate 6000ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, line-1-fade-in-out 6000ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
          animation: fill-unfill-rotate 6000ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, line-1-fade-in-out 6000ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

.Spinner-line-cog {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit; }

.Spinner-line-cog-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 200%;
  box-sizing: border-box;
  height: 100%;
  border-width: 3px;
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent;
  border-radius: 50%;
  -webkit-animation: none;
          animation: none; }

.Spinner-line-cog-inner--left {
  border-right-color: transparent;
  -webkit-transform: rotate(129deg);
          transform: rotate(129deg);
  -webkit-animation: left-spin 1500ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
          animation: left-spin 1500ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

.Spinner-line-cog-inner--right {
  left: -100%;
  border-left-color: transparent;
  -webkit-transform: rotate(-129deg);
          transform: rotate(-129deg);
  -webkit-animation: right-spin 1500ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
          animation: right-spin 1500ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

.Spinner-line-cog-inner--center {
  width: 1000%;
  left: -450%; }

.Spinner-line-ticker {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit; }

@-webkit-keyframes container-rotate {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes container-rotate {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  25% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); }
  37.5% {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg); }
  50% {
    -webkit-transform: rotate(540deg);
            transform: rotate(540deg); }
  62.5% {
    -webkit-transform: rotate(675deg);
            transform: rotate(675deg); }
  75% {
    -webkit-transform: rotate(810deg);
            transform: rotate(810deg); }
  87.5% {
    -webkit-transform: rotate(945deg);
            transform: rotate(945deg); }
  to {
    -webkit-transform: rotate(1080deg);
            transform: rotate(1080deg); } }

@keyframes fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  25% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); }
  37.5% {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg); }
  50% {
    -webkit-transform: rotate(540deg);
            transform: rotate(540deg); }
  62.5% {
    -webkit-transform: rotate(675deg);
            transform: rotate(675deg); }
  75% {
    -webkit-transform: rotate(810deg);
            transform: rotate(810deg); }
  87.5% {
    -webkit-transform: rotate(945deg);
            transform: rotate(945deg); }
  to {
    -webkit-transform: rotate(1080deg);
            transform: rotate(1080deg); } }

@-webkit-keyframes left-spin {
  0% {
    -webkit-transform: rotate(130deg);
            transform: rotate(130deg); }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  to {
    -webkit-transform: rotate(130deg);
            transform: rotate(130deg); } }

@keyframes left-spin {
  0% {
    -webkit-transform: rotate(130deg);
            transform: rotate(130deg); }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  to {
    -webkit-transform: rotate(130deg);
            transform: rotate(130deg); } }

@-webkit-keyframes right-spin {
  0% {
    -webkit-transform: rotate(-130deg);
            transform: rotate(-130deg); }
  50% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  to {
    -webkit-transform: rotate(-130deg);
            transform: rotate(-130deg); } }

@keyframes right-spin {
  0% {
    -webkit-transform: rotate(-130deg);
            transform: rotate(-130deg); }
  50% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  to {
    -webkit-transform: rotate(-130deg);
            transform: rotate(-130deg); } }

@-webkit-keyframes line-1-fade-in-out {
  0% {
    opacity: 1; }
  25% {
    opacity: 1; }
  26% {
    opacity: 1; }
  89% {
    opacity: 1; }
  90% {
    opacity: 1; }
  to {
    opacity: 1; } }

@keyframes line-1-fade-in-out {
  0% {
    opacity: 1; }
  25% {
    opacity: 1; }
  26% {
    opacity: 1; }
  89% {
    opacity: 1; }
  90% {
    opacity: 1; }
  to {
    opacity: 1; } }

.spinner {
  -webkit-animation: rotator 1.8s linear infinite;
          animation: rotator 1.8s linear infinite; }

@-webkit-keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); } }

@keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); } }

.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
          transform-origin: center;
  stroke: #8C61CB;
  -webkit-animation: dash 1.8s ease-in-out infinite;
          animation: dash 1.8s ease-in-out infinite; }

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg); } }

@keyframes dash {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg); } }

.cp-spinner {
  width: 48px;
  height: 48px;
  display: inline-block;
  box-sizing: border-box;
  position: relative; }

.cp-round:before {
  border-radius: 50%;
  content: " ";
  width: 48px;
  height: 48px;
  display: inline-block;
  box-sizing: border-box;
  border-top: solid 6px rgba(172, 171, 171, 0.2);
  border-right: solid 6px rgba(172, 171, 171, 0.2);
  border-bottom: solid 6px rgba(172, 171, 171, 0.2);
  border-left: solid 6px rgba(172, 171, 171, 0.2);
  position: absolute;
  top: 0;
  left: 0; }

.cp-round:after {
  border-radius: 50%;
  content: " ";
  width: 48px;
  height: 48px;
  display: inline-block;
  box-sizing: border-box;
  border-top: solid 6px #00ADE6;
  border-right: solid 6px transparent;
  border-bottom: solid 6px transparent;
  border-left: solid 6px transparent;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: cp-round-animate 1s ease-in-out infinite;
          animation: cp-round-animate 1s ease-in-out infinite; }

@-webkit-keyframes cp-round-animate {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes cp-round-animate {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.UpdatedButton_updatedButton__33UQV {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito';
  cursor: pointer;
  border: none;
  box-sizing: border-box;
  padding: 2.41546vw 3.86473vw;
  border-radius: 4.83092vw;
  box-shadow: 0vw 0.96618vw 0vw #00ADE6;
  height: 13.28502vw; }
  .UpdatedButton_updatedButton__33UQV span {
    font-style: normal;
    display: flex;
    align-items: center;
    font-size: 3.86473vw;
    line-height: 5.31401vw;
    font-weight: 700; }
    @media (min-width: 1300px) {
      .UpdatedButton_updatedButton__33UQV span {
        font-size: 1.04167vw;
        line-height: 1.14583vw;
        font-weight: 700; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .UpdatedButton_updatedButton__33UQV span {
        font-size: 1.15741vw;
        line-height: 1.27315vw;
        font-weight: 700; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .UpdatedButton_updatedButton__33UQV span {
        font-size: 1.04167vw;
        line-height: 1.14583vw;
        font-weight: 700; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .UpdatedButton_updatedButton__33UQV span {
        font-size: 3.22061vw;
        line-height: 4.42834vw; }
        .UpdatedButton_updatedButton__33UQV span else {
          font-size: 16px; }
        .UpdatedButton_updatedButton__33UQV span else {
          line-height: 22px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .UpdatedButton_updatedButton__33UQV span {
        font-size: 2.57649vw;
        line-height: 3.54267vw; }
        .UpdatedButton_updatedButton__33UQV span else {
          font-size: 16px; }
        .UpdatedButton_updatedButton__33UQV span else {
          line-height: 22px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .UpdatedButton_updatedButton__33UQV span {
        font-size: 2.27337vw;
        line-height: 3.12589vw; }
        .UpdatedButton_updatedButton__33UQV span else {
          font-size: 16px; }
        .UpdatedButton_updatedButton__33UQV span else {
          line-height: 22px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .UpdatedButton_updatedButton__33UQV span {
        font-size: 2.03407vw;
        line-height: 2.79685vw; }
        .UpdatedButton_updatedButton__33UQV span else {
          font-size: 16px; }
        .UpdatedButton_updatedButton__33UQV span else {
          line-height: 22px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .UpdatedButton_updatedButton__33UQV span {
        font-size: 1.84035vw;
        line-height: 2.53048vw; }
        .UpdatedButton_updatedButton__33UQV span else {
          font-size: 16px; }
        .UpdatedButton_updatedButton__33UQV span else {
          line-height: 22px; } }
  .UpdatedButton_updatedButton__33UQV.UpdatedButton_noShadowBtn__ZAQYb {
    border-radius: 100px;
    box-shadow: none !important; }
  @media (min-width: 1300px) {
    .UpdatedButton_updatedButton__33UQV {
      border-radius: 1.04167vw;
      padding: 0.83333vw 1.25vw;
      box-shadow: 0vw 0.20833vw 0vw #00ADE6;
      height: 2.86458vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .UpdatedButton_updatedButton__33UQV {
      border-radius: 1.15741vw;
      padding: 0.92593vw 1.38889vw;
      box-shadow: 0vw 0.23148vw 0vw #00ADE6;
      height: 3.18287vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .UpdatedButton_updatedButton__33UQV {
      border-radius: 1.04167vw;
      padding: 0.83333vw 1.25vw;
      box-shadow: 0vw 0.20833vw 0vw #00ADE6;
      height: 2.86458vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .UpdatedButton_updatedButton__33UQV {
      padding: 2.01288vw 3.22061vw;
      border-radius: 4.02576vw;
      box-shadow: 0vw 0.80515vw 0vw #00ADE6;
      height: 11.07085vw; }
      .UpdatedButton_updatedButton__33UQV else {
        border-radius: 20px; }
      .UpdatedButton_updatedButton__33UQV else {
        height: 55px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .UpdatedButton_updatedButton__33UQV {
      padding: 1.61031vw 2.57649vw;
      border-radius: 3.22061vw;
      box-shadow: 0vw 0.64412vw 0vw #00ADE6;
      height: 8.85668vw; }
      .UpdatedButton_updatedButton__33UQV else {
        border-radius: 20px; }
      .UpdatedButton_updatedButton__33UQV else {
        height: 55px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .UpdatedButton_updatedButton__33UQV {
      padding: 1.42086vw 2.27337vw;
      border-radius: 2.84172vw;
      box-shadow: 0vw 0.56834vw 0vw #00ADE6;
      height: 7.81472vw; }
      .UpdatedButton_updatedButton__33UQV else {
        border-radius: 20px; }
      .UpdatedButton_updatedButton__33UQV else {
        height: 55px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .UpdatedButton_updatedButton__33UQV {
      padding: 1.27129vw 2.03407vw;
      border-radius: 2.54259vw;
      box-shadow: 0vw 0.50852vw 0vw #00ADE6;
      height: 6.99212vw; }
      .UpdatedButton_updatedButton__33UQV else {
        border-radius: 20px; }
      .UpdatedButton_updatedButton__33UQV else {
        height: 55px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .UpdatedButton_updatedButton__33UQV {
      padding: 1.15022vw 1.84035vw;
      border-radius: 2.30044vw;
      box-shadow: 0vw 0.46009vw 0vw #00ADE6;
      height: 6.3262vw; }
      .UpdatedButton_updatedButton__33UQV else {
        border-radius: 20px; }
      .UpdatedButton_updatedButton__33UQV else {
        height: 55px; } }
  .UpdatedButton_updatedButton__33UQV svg {
    width: 5.7971vw;
    height: 5.7971vw; }
    @media (min-width: 1300px) {
      .UpdatedButton_updatedButton__33UQV svg {
        width: 1.25vw;
        height: 1.25vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .UpdatedButton_updatedButton__33UQV svg {
        width: 1.38889vw;
        height: 1.38889vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .UpdatedButton_updatedButton__33UQV svg {
        width: 1.25vw;
        height: 1.25vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .UpdatedButton_updatedButton__33UQV svg {
        width: 4.83092vw;
        height: 4.83092vw; }
        .UpdatedButton_updatedButton__33UQV svg else {
          width: 24px; }
        .UpdatedButton_updatedButton__33UQV svg else {
          height: 24px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .UpdatedButton_updatedButton__33UQV svg {
        width: 3.86473vw;
        height: 3.86473vw; }
        .UpdatedButton_updatedButton__33UQV svg else {
          width: 24px; }
        .UpdatedButton_updatedButton__33UQV svg else {
          height: 24px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .UpdatedButton_updatedButton__33UQV svg {
        width: 3.41006vw;
        height: 3.41006vw; }
        .UpdatedButton_updatedButton__33UQV svg else {
          width: 24px; }
        .UpdatedButton_updatedButton__33UQV svg else {
          height: 24px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .UpdatedButton_updatedButton__33UQV svg {
        width: 3.05111vw;
        height: 3.05111vw; }
        .UpdatedButton_updatedButton__33UQV svg else {
          width: 24px; }
        .UpdatedButton_updatedButton__33UQV svg else {
          height: 24px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .UpdatedButton_updatedButton__33UQV svg {
        width: 2.76052vw;
        height: 2.76052vw; }
        .UpdatedButton_updatedButton__33UQV svg else {
          width: 24px; }
        .UpdatedButton_updatedButton__33UQV svg else {
          height: 24px; } }

.UpdatedButton_overlayLoading__3DMsi {
  position: absolute; }

.UpdatedButton_primary__3LG3_ {
  grid-gap: 16px;
  gap: 16px;
  background: linear-gradient(287.49deg, #00ADE6 38.78%, #29C8FC 98.2%);
  color: white;
  border: none;
  font-family: 'Nunito';
  font-weight: 700;
  cursor: pointer;
  padding: 2.41546vw 3.86473vw;
  box-shadow: 0vw 0.96618vw 0vw #007FA9; }
  .UpdatedButton_primary__3LG3_:active {
    box-shadow: none;
    -webkit-transform: translateY(3px);
            transform: translateY(3px); }
  .UpdatedButton_primary__3LG3_:disabled {
    cursor: unset;
    background: #E2E2E2;
    box-shadow: none;
    color: #A8A7A7; }
    .UpdatedButton_primary__3LG3_:disabled:active {
      box-shadow: none;
      -webkit-transform: translateY(0px);
              transform: translateY(0px); }
  @media (min-width: 1300px) {
    .UpdatedButton_primary__3LG3_ {
      padding: 0.625vw 1.25vw;
      box-shadow: 0vw 0.20833vw 0vw #007FA9; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .UpdatedButton_primary__3LG3_ {
      padding: 0.69444vw 1.38889vw;
      box-shadow: 0vw 0.23148vw 0vw #007FA9; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .UpdatedButton_primary__3LG3_ {
      padding: 0.625vw 1.25vw;
      box-shadow: 0vw 0.20833vw 0vw #007FA9; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .UpdatedButton_primary__3LG3_ {
      padding: 2.01288vw 3.22061vw;
      box-shadow: 0vw 0.80515vw 0vw #007FA9; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .UpdatedButton_primary__3LG3_ {
      padding: 1.61031vw 2.57649vw;
      box-shadow: 0vw 0.64412vw 0vw #007FA9; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .UpdatedButton_primary__3LG3_ {
      padding: 1.42086vw 2.27337vw;
      box-shadow: 0vw 0.56834vw 0vw #007FA9; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .UpdatedButton_primary__3LG3_ {
      padding: 1.27129vw 2.03407vw;
      box-shadow: 0vw 0.50852vw 0vw #007FA9; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .UpdatedButton_primary__3LG3_ {
      padding: 1.15022vw 1.84035vw;
      box-shadow: 0vw 0.46009vw 0vw #007FA9; } }

.UpdatedButton_secondary__HUY0_ {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito';
  cursor: pointer;
  box-sizing: border-box;
  background: #FFFFFF; }
  @media (min-width: 1300px) {
    .UpdatedButton_secondary__HUY0_ {
      border: 0.10417vw solid #00ADE6; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .UpdatedButton_secondary__HUY0_ {
      border: 0.11574vw solid #00ADE6; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .UpdatedButton_secondary__HUY0_ {
      border: 0.10417vw solid #00ADE6; } }
  .UpdatedButton_secondary__HUY0_ span {
    color: #00ADE6; }
  .UpdatedButton_secondary__HUY0_:active {
    box-shadow: none;
    -webkit-transform: translateY(3px);
            transform: translateY(3px); }
  .UpdatedButton_secondary__HUY0_:disabled {
    background: #E2E2E2;
    box-shadow: none;
    color: #A8A7A7; }

.UpdatedButton_tertiary__1i2XX {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito';
  cursor: pointer;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 2px solid #dcdcdc; }
  .UpdatedButton_tertiary__1i2XX span {
    color: #403F3F; }
  .UpdatedButton_tertiary__1i2XX:active {
    box-shadow: none;
    -webkit-transform: translateY(3px);
            transform: translateY(3px); }
  .UpdatedButton_tertiary__1i2XX:disabled {
    background: #dcdcdc;
    box-shadow: none;
    color: #A8A7A7; }
  @media (min-width: 1300px) {
    .UpdatedButton_tertiary__1i2XX {
      box-shadow: 0vw 0.20833vw 0vw #dcdcdc; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .UpdatedButton_tertiary__1i2XX {
      box-shadow: 0vw 0.23148vw 0vw #dcdcdc; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .UpdatedButton_tertiary__1i2XX {
      box-shadow: 0vw 0.20833vw 0vw #dcdcdc; } }

.UpdatedButton_border__rcP-m {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito';
  cursor: pointer;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 2px solid #00ADE6; }
  .UpdatedButton_border__rcP-m span {
    color: #00ADE6; }
  .UpdatedButton_border__rcP-m:active {
    box-shadow: none;
    -webkit-transform: translateY(3px);
            transform: translateY(3px); }

.UpdatedButton_disabled__1rE62 {
  border: none;
  background: #E2E2E2;
  box-shadow: none;
  cursor: not-allowed; }
  .UpdatedButton_disabled__1rE62 span {
    color: #A8A7A7; }

.UpdatedButton_reportButton__3Pm0q span {
  width: 48.30918vw; }
  @media (min-width: 1300px) {
    .UpdatedButton_reportButton__3Pm0q span {
      width: 10.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .UpdatedButton_reportButton__3Pm0q span {
      width: 11.57407vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .UpdatedButton_reportButton__3Pm0q span {
      width: 10.41667vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .UpdatedButton_reportButton__3Pm0q span {
      width: 40.25765vw; }
      .UpdatedButton_reportButton__3Pm0q span else {
        width: 200px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .UpdatedButton_reportButton__3Pm0q span {
      width: 32.20612vw; }
      .UpdatedButton_reportButton__3Pm0q span else {
        width: 200px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .UpdatedButton_reportButton__3Pm0q span {
      width: 28.41716vw; }
      .UpdatedButton_reportButton__3Pm0q span else {
        width: 200px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .UpdatedButton_reportButton__3Pm0q span {
      width: 25.42588vw; }
      .UpdatedButton_reportButton__3Pm0q span else {
        width: 200px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .UpdatedButton_reportButton__3Pm0q span {
      width: 23.00437vw; }
      .UpdatedButton_reportButton__3Pm0q span else {
        width: 200px; } }

.UpdatedButton_widthFull__1jYYx {
  width: 100%; }
  @media (min-width: 1300px) {
    .UpdatedButton_widthFull__1jYYx {
      width: 100%; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .UpdatedButton_widthFull__1jYYx {
      width: 100%; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .UpdatedButton_widthFull__1jYYx {
      width: 100%; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .UpdatedButton_widthFull__1jYYx else {
      width: 100%; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .UpdatedButton_widthFull__1jYYx else {
      width: 100%; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .UpdatedButton_widthFull__1jYYx else {
      width: 100%; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .UpdatedButton_widthFull__1jYYx else {
      width: 100%; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .UpdatedButton_widthFull__1jYYx else {
      width: 100%; } }

.UpdatedButton_updatedBtn2__3PeuZ {
  width: -webkit-max-content;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito';
  font-weight: 700;
  cursor: pointer;
  box-sizing: border-box;
  color: white;
  border: none; }
  @media (min-width: 1300px) {
    .UpdatedButton_updatedBtn2__3PeuZ {
      padding: 0.72917vw 1.14583vw;
      grid-gap: 0.625vw;
      gap: 0.625vw;
      font-size: 0.9375vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .UpdatedButton_updatedBtn2__3PeuZ {
      padding: 0.81019vw 1.27315vw;
      grid-gap: 0.69444vw;
      gap: 0.69444vw;
      font-size: 1.04167vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .UpdatedButton_updatedBtn2__3PeuZ {
      padding: 0.72917vw 1.14583vw;
      grid-gap: 0.625vw;
      gap: 0.625vw;
      font-size: 0.9375vw; } }

.UpdatedButton_dangerBtn2__1GhXQ {
  background: #D34B57;
  box-shadow: 0px 4px 0px #B4313C; }
  @media (min-width: 1300px) {
    .UpdatedButton_dangerBtn2__1GhXQ {
      border-radius: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .UpdatedButton_dangerBtn2__1GhXQ {
      border-radius: 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .UpdatedButton_dangerBtn2__1GhXQ {
      border-radius: 1.04167vw; } }

.UpdatedButton_dangerBtn2__1GhXQ:active {
  box-shadow: 0px 2px 0px #B4313C;
  -webkit-transform: translateY(3px);
          transform: translateY(3px); }

.UpdatedButton_dangerBtnWrapper__3yegb {
  border: none;
  background: #D34B57;
  border-radius: 20px;
  box-shadow: 0px 7px 0px #B4313C; }

.UpdatedButton_dangerBtnWrapper__3yegb:active {
  box-shadow: 0px 2px 0px #B4313C;
  -webkit-transform: translateY(3px);
          transform: translateY(3px); }

.UpdatedButton_noSpace__3b-Y4 {
  padding: 0;
  border: none; }

.UpdatedButton_noSpace__3b-Y4:active {
  box-shadow: 0px 2px 0px #B4313C;
  -webkit-transform: translateY(3px);
          transform: translateY(3px); }

.UpdatedButton_dangerBtnWrapper__3yegb:disabled {
  background: conic-gradient(from -3.29deg at 100% -13%, #DBDBDB 0deg, #A6A6A6 360deg);
  box-shadow: 0px 7px 0px rgba(194, 194, 194, 0.5);
  pointer-events: none; }

.UpdatedButton_dangerBtn2__1GhXQ:disabled {
  background: conic-gradient(from -3.29deg at 100% -13%, #DBDBDB 0deg, #A6A6A6 360deg);
  box-shadow: 0px 7px 0px #808080; }

.UpdatedButton_noSpace__3b-Y4:disabled {
  background: conic-gradient(from -3.29deg at 100% -13%, #DBDBDB 0deg, #A6A6A6 360deg);
  box-shadow: 0px 7px 0px rgba(194, 194, 194, 0.5);
  pointer-events: none;
  box-shadow: 0px 7px 0px #808080; }

.UpdatedButton_dangerDisabled__2atZG {
  background: conic-gradient(from -3.29deg at 100% -13%, #DBDBDB 0deg, #A6A6A6 360deg);
  box-shadow: 0px 0px 30px rgba(194, 194, 194, 0.5), inset 0px 0px 8px rgba(179, 179, 179, 0.8);
  box-shadow: inset 0px 0px 8px #C2C2C2;
  pointer-events: none; }

.styles_flex__N7IuW {
  display: flex; }
  .styles_flex__N7IuW.styles_center__aVpYH {
    justify-content: center;
    align-items: center; }

.styles_container__EmCo2 {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: row; }

.styles_pane__7r1bv {
  position: relative;
  height: 100%;
  background-color: #052732;
  overflow: hidden; }

.styles_splitPane__1LL3b {
  height: calc(100%) !important;
  overflow: hidden; }

.styles_editorContainer__1Gz28 {
  background-color: #002f3e;
  flex: 1 1;
  position: relative;
  height: calc(100% - 9.17874vw); }
  @media (min-width: 450px) and (max-width: 500px) {
    .styles_editorContainer__1Gz28 else {
      height: calc(100% - 9.17874vw); } }
  @media (min-width: 500px) and (max-width: 600px) {
    .styles_editorContainer__1Gz28 else {
      height: calc(100% - 9.17874vw); } }
  @media (min-width: 600px) and (max-width: 700px) {
    .styles_editorContainer__1Gz28 else {
      height: calc(100% - 9.17874vw); } }
  @media (min-width: 700px) and (max-width: 800px) {
    .styles_editorContainer__1Gz28 else {
      height: calc(100% - 9.17874vw); } }
  @media (min-width: 800px) and (max-width: 899px) {
    .styles_editorContainer__1Gz28 else {
      height: calc(100% - 9.17874vw); } }

.styles_ace-terminal-theme__3-qPr {
  background-color: #002f3e !important; }

.styles_outputContainer__2sFF2 {
  flex: 1 1;
  height: calc(100%);
  background-color: #052732;
  box-sizing: border-box;
  padding: 15px 20px 100px 20px;
  overflow: scroll; }
  .styles_outputContainer__2sFF2::-webkit-scrollbar {
    max-width: 0.52083vw; }
  .styles_outputContainer__2sFF2::-webkit-scrollbar-thumb {
    background-color: rgba(52, 228, 234, 0.5);
    border-radius: 0; }

.styles_transparent__1l4Le {
  background-color: transparent !important;
  padding: 0px 5px 5px 5px !important; }

.styles_output__2DedB {
  width: 100%;
  box-sizing: border-box;
  border-radius: 1.20773vw;
  font-size: 3.86473vw;
  font-family: Monaco;
  color: #ffffff;
  overflow: scroll;
  position: relative;
  display: flex;
  flex-direction: row; }
  @media (min-width: 1300px) {
    .styles_output__2DedB {
      font-size: 1.25vw;
      border-radius: 0.26042vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .styles_output__2DedB {
      font-size: 1.38889vw;
      border-radius: 0.28935vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .styles_output__2DedB {
      font-size: 1.25vw;
      border-radius: 0.26042vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .styles_output__2DedB {
      border-radius: 1.00644vw;
      font-size: 3.22061vw; }
      .styles_output__2DedB else {
        border-radius: 5px; }
      .styles_output__2DedB else {
        font-size: 16px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .styles_output__2DedB {
      border-radius: 0.80515vw;
      font-size: 2.57649vw; }
      .styles_output__2DedB else {
        border-radius: 5px; }
      .styles_output__2DedB else {
        font-size: 16px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .styles_output__2DedB {
      border-radius: 0.71043vw;
      font-size: 2.27337vw; }
      .styles_output__2DedB else {
        border-radius: 5px; }
      .styles_output__2DedB else {
        font-size: 16px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .styles_output__2DedB {
      border-radius: 0.63565vw;
      font-size: 2.03407vw; }
      .styles_output__2DedB else {
        border-radius: 5px; }
      .styles_output__2DedB else {
        font-size: 16px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .styles_output__2DedB {
      border-radius: 0.57511vw;
      font-size: 1.84035vw; }
      .styles_output__2DedB else {
        border-radius: 5px; }
      .styles_output__2DedB else {
        font-size: 16px; } }
  .styles_output__2DedB:focus-visible {
    outline: none;
    border: none; }
  .styles_output__2DedB:focus {
    outline: none;
    border: none; }
  @media (min-width: 1300px) {
    .styles_output__2DedB::-webkit-scrollbar {
      max-width: 0.52083vw;
      height: 0.52083vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .styles_output__2DedB::-webkit-scrollbar {
      max-width: 0.5787vw;
      height: 0.5787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .styles_output__2DedB::-webkit-scrollbar {
      max-width: 0.52083vw;
      height: 0.52083vw; } }
  @media (min-width: 1300px) {
    .styles_output__2DedB::-webkit-scrollbar {
      max-width: 0.36458vw;
      height: 0.36458vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .styles_output__2DedB::-webkit-scrollbar {
      max-width: 0.40509vw;
      height: 0.40509vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .styles_output__2DedB::-webkit-scrollbar {
      max-width: 0.36458vw;
      height: 0.36458vw; } }
  .styles_output__2DedB::-webkit-scrollbar-thumb {
    background-color: rgba(52, 228, 234, 0.5);
    border-radius: 0; }
  .styles_output__2DedB::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0); }

.styles_title__3-F-z {
  position: relative;
  box-sizing: border-box;
  background: linear-gradient(0deg, #0f4b5f, #0f4b5f), #ffffff;
  padding-inline: 1rem;
  width: 100%;
  background-color: #00171f;
  display: flex;
  justify-content: space-between;
  position: "relative";
  align-items: center;
  color: #ffffff;
  border-right: 1px solid #00171f;
  font-weight: 600;
  font-size: 4.34783vw; }
  @media (min-width: 1300px) {
    .styles_title__3-F-z {
      height: 3.4375vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .styles_title__3-F-z {
      height: 3.81944vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .styles_title__3-F-z {
      height: 3.4375vw; } }
  @media (min-width: 1300px) {
    .styles_title__3-F-z {
      font-size: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .styles_title__3-F-z {
      font-size: 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .styles_title__3-F-z {
      font-size: 1.04167vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .styles_title__3-F-z {
      font-size: 3.62319vw; }
      .styles_title__3-F-z else {
        font-size: 18px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .styles_title__3-F-z {
      font-size: 2.89855vw; }
      .styles_title__3-F-z else {
        font-size: 18px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .styles_title__3-F-z {
      font-size: 2.55754vw; }
      .styles_title__3-F-z else {
        font-size: 18px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .styles_title__3-F-z {
      font-size: 2.28833vw; }
      .styles_title__3-F-z else {
        font-size: 18px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .styles_title__3-F-z {
      font-size: 2.07039vw; }
      .styles_title__3-F-z else {
        font-size: 18px; } }

.styles_breadcrumbs__2UXEz {
  display: flex;
  align-items: center; }
  @media (min-width: 1300px) {
    .styles_breadcrumbs__2UXEz {
      grid-gap: 0.83333vw;
      gap: 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .styles_breadcrumbs__2UXEz {
      grid-gap: 0.92593vw;
      gap: 0.92593vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .styles_breadcrumbs__2UXEz {
      grid-gap: 0.83333vw;
      gap: 0.83333vw; } }
  .styles_breadcrumbs__2UXEz > span {
    font-weight: 700;
    color: #ffffff;
    opacity: 0.5; }
    @media (min-width: 1300px) {
      .styles_breadcrumbs__2UXEz > span {
        font-size: 1.04167vw;
        line-height: 1.40625vw;
        letter-spacing: -0.02em; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .styles_breadcrumbs__2UXEz > span {
        font-size: 1.15741vw;
        line-height: 1.5625vw;
        letter-spacing: -0.02em; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .styles_breadcrumbs__2UXEz > span {
        font-size: 1.04167vw;
        line-height: 1.40625vw;
        letter-spacing: -0.02em; } }

.styles_title__brief__1N5zX {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px; }

.styles_languageIcon__U8H44 {
  display: block; }
  @media (min-width: 1300px) {
    .styles_languageIcon__U8H44 {
      width: 1.04167vw;
      height: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .styles_languageIcon__U8H44 {
      width: 1.15741vw;
      height: 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .styles_languageIcon__U8H44 {
      width: 1.04167vw;
      height: 1.04167vw; } }

.styles_toolbar__3kcbd {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center; }

.styles_clearButton__3lCfE {
  width: 1.45833vw;
  height: 1.45833vw;
  margin-right: 0.57292vw;
  margin-top: 0.10417vw;
  cursor: pointer; }

.styles_backButton__TjVov {
  position: absolute;
  width: 1.93237vw;
  height: 3.38164vw;
  top: 2.89855vw;
  left: 2.41546vw; }
  .styles_backButton__TjVov path {
    fill: #092732 !important; }
  @media (min-width: 450px) and (max-width: 500px) {
    .styles_backButton__TjVov {
      width: 1.61031vw;
      height: 2.81804vw;
      top: 2.41546vw;
      left: 2.01288vw; }
      .styles_backButton__TjVov else {
        width: 8px; }
      .styles_backButton__TjVov else {
        height: 14px; }
      .styles_backButton__TjVov else {
        top: 12px; }
      .styles_backButton__TjVov else {
        left: 10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .styles_backButton__TjVov {
      width: 1.28824vw;
      height: 2.25443vw;
      top: 1.93237vw;
      left: 1.61031vw; }
      .styles_backButton__TjVov else {
        width: 8px; }
      .styles_backButton__TjVov else {
        height: 14px; }
      .styles_backButton__TjVov else {
        top: 12px; }
      .styles_backButton__TjVov else {
        left: 10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .styles_backButton__TjVov {
      width: 1.13669vw;
      height: 1.9892vw;
      top: 1.70503vw;
      left: 1.42086vw; }
      .styles_backButton__TjVov else {
        width: 8px; }
      .styles_backButton__TjVov else {
        height: 14px; }
      .styles_backButton__TjVov else {
        top: 12px; }
      .styles_backButton__TjVov else {
        left: 10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .styles_backButton__TjVov {
      width: 1.01704vw;
      height: 1.77981vw;
      top: 1.52555vw;
      left: 1.27129vw; }
      .styles_backButton__TjVov else {
        width: 8px; }
      .styles_backButton__TjVov else {
        height: 14px; }
      .styles_backButton__TjVov else {
        top: 12px; }
      .styles_backButton__TjVov else {
        left: 10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .styles_backButton__TjVov {
      width: 0.92017vw;
      height: 1.61031vw;
      top: 1.38026vw;
      left: 1.15022vw; }
      .styles_backButton__TjVov else {
        width: 8px; }
      .styles_backButton__TjVov else {
        height: 14px; }
      .styles_backButton__TjVov else {
        top: 12px; }
      .styles_backButton__TjVov else {
        left: 10px; } }

.styles_clearButtonEditor__2fpYV {
  cursor: pointer;
  width: 4.34783vw;
  margin-right: 1.93237vw;
  margin-top: 1.69082vw; }
  @media (min-width: 1300px) {
    .styles_clearButtonEditor__2fpYV {
      width: 1.45833vw;
      margin-right: 0.57292vw;
      margin-top: 0.10417vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .styles_clearButtonEditor__2fpYV {
      width: 1.62037vw;
      margin-right: 0.63657vw;
      margin-top: 0.11574vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .styles_clearButtonEditor__2fpYV {
      width: 1.45833vw;
      margin-right: 0.57292vw;
      margin-top: 0.10417vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .styles_clearButtonEditor__2fpYV {
      width: 3.62319vw;
      margin-right: 1.61031vw;
      margin-top: 1.40902vw; }
      .styles_clearButtonEditor__2fpYV else {
        width: 18px; }
      .styles_clearButtonEditor__2fpYV else {
        margin-right: 8px; }
      .styles_clearButtonEditor__2fpYV else {
        margin-top: 7px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .styles_clearButtonEditor__2fpYV {
      width: 2.89855vw;
      margin-right: 1.28824vw;
      margin-top: 1.12721vw; }
      .styles_clearButtonEditor__2fpYV else {
        width: 18px; }
      .styles_clearButtonEditor__2fpYV else {
        margin-right: 8px; }
      .styles_clearButtonEditor__2fpYV else {
        margin-top: 7px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .styles_clearButtonEditor__2fpYV {
      width: 2.55754vw;
      margin-right: 1.13669vw;
      margin-top: 0.9946vw; }
      .styles_clearButtonEditor__2fpYV else {
        width: 18px; }
      .styles_clearButtonEditor__2fpYV else {
        margin-right: 8px; }
      .styles_clearButtonEditor__2fpYV else {
        margin-top: 7px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .styles_clearButtonEditor__2fpYV {
      width: 2.28833vw;
      margin-right: 1.01704vw;
      margin-top: 0.88991vw; }
      .styles_clearButtonEditor__2fpYV else {
        width: 18px; }
      .styles_clearButtonEditor__2fpYV else {
        margin-right: 8px; }
      .styles_clearButtonEditor__2fpYV else {
        margin-top: 7px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .styles_clearButtonEditor__2fpYV {
      width: 2.07039vw;
      margin-right: 0.92017vw;
      margin-top: 0.80515vw; }
      .styles_clearButtonEditor__2fpYV else {
        width: 18px; }
      .styles_clearButtonEditor__2fpYV else {
        margin-right: 8px; }
      .styles_clearButtonEditor__2fpYV else {
        margin-top: 7px; } }

.styles_titleMentor__4bp_5 {
  justify-content: space-around; }

.styles_skyBlue__2CMje {
  background-color: #5fc5e5; }

.styles_bottomToolbar__2HrVP {
  width: 100%;
  height: 2.86458vw;
  background-color: #013d4e;
  margin-top: 1px;
  display: flex;
  flex-direction: row;
  align-items: center; }

.styles_buttonContainer__37O7_ {
  height: 2.10938vw;
  border-radius: 1.3125vw;
  margin-left: 1.04167vw; }

.styles_buttonText__8nhgU {
  font-size: 1vw;
  margin-left: 1.5625vw;
  margin-right: 1.5625vw; }

.styles_saveButton___fO_x {
  position: absolute;
  background-color: transparent;
  border: 0;
  background-image: url(/static/media/saveButton.d5b254a4.svg);
  background-size: 117%;
  background-position: 47% 42%;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  z-index: 5;
  width: 14.49275vw;
  height: 14.49275vw;
  bottom: 4.83092vw;
  left: 2.41546vw; }
  @media (min-width: 1300px) {
    .styles_saveButton___fO_x {
      width: 3.64583vw;
      height: 3.64583vw;
      bottom: 1.5625vw;
      left: 4rem; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .styles_saveButton___fO_x {
      width: 4.05093vw;
      height: 4.05093vw;
      bottom: 1.73611vw;
      left: 4rem; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .styles_saveButton___fO_x {
      width: 3.64583vw;
      height: 3.64583vw;
      bottom: 1.5625vw;
      left: 4rem; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .styles_saveButton___fO_x {
      width: 12.07729vw;
      height: 12.07729vw;
      bottom: 4.02576vw;
      left: 2.01288vw; }
      .styles_saveButton___fO_x else {
        width: 60px; }
      .styles_saveButton___fO_x else {
        height: 60px; }
      .styles_saveButton___fO_x else {
        bottom: 20px; }
      .styles_saveButton___fO_x else {
        left: 10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .styles_saveButton___fO_x {
      width: 9.66184vw;
      height: 9.66184vw;
      bottom: 3.22061vw;
      left: 1.61031vw; }
      .styles_saveButton___fO_x else {
        width: 60px; }
      .styles_saveButton___fO_x else {
        height: 60px; }
      .styles_saveButton___fO_x else {
        bottom: 20px; }
      .styles_saveButton___fO_x else {
        left: 10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .styles_saveButton___fO_x {
      width: 8.52515vw;
      height: 8.52515vw;
      bottom: 2.84172vw;
      left: 1.42086vw; }
      .styles_saveButton___fO_x else {
        width: 60px; }
      .styles_saveButton___fO_x else {
        height: 60px; }
      .styles_saveButton___fO_x else {
        bottom: 20px; }
      .styles_saveButton___fO_x else {
        left: 10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .styles_saveButton___fO_x {
      width: 7.62777vw;
      height: 7.62777vw;
      bottom: 2.54259vw;
      left: 1.27129vw; }
      .styles_saveButton___fO_x else {
        width: 60px; }
      .styles_saveButton___fO_x else {
        height: 60px; }
      .styles_saveButton___fO_x else {
        bottom: 20px; }
      .styles_saveButton___fO_x else {
        left: 10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .styles_saveButton___fO_x {
      width: 6.90131vw;
      height: 6.90131vw;
      bottom: 2.30044vw;
      left: 1.15022vw; }
      .styles_saveButton___fO_x else {
        width: 60px; }
      .styles_saveButton___fO_x else {
        height: 60px; }
      .styles_saveButton___fO_x else {
        bottom: 20px; }
      .styles_saveButton___fO_x else {
        left: 10px; } }
  .styles_saveButton___fO_x:focus {
    border: 0;
    outline: 0;
    box-shadow: 0; }
  .styles_saveButton___fO_x:disabled {
    opacity: 0.5;
    cursor: pointer; }

.styles_saveModal__3ms4S {
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 95%, 25% 95%, 20% 100%, 15% 95%, 0 95%);
          clip-path: polygon(0% 0%, 100% 0%, 100% 95%, 25% 95%, 20% 100%, 15% 95%, 0 95%);
  -webkit-transform: translate(-10%, -105%);
          transform: translate(-10%, -105%);
  background-color: #052732;
  transition: all 0.4s ease-in-out;
  border-radius: 1.20773vw;
  margin: 0 0 5.7971vw 7.24638vw;
  width: 62.80193vw; }
  @media (min-width: 1300px) {
    .styles_saveModal__3ms4S {
      border-radius: 0.26042vw;
      margin: 0 0 1.25vw;
      width: 18.22917vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .styles_saveModal__3ms4S {
      border-radius: 0.28935vw;
      margin: 0 0 1.38889vw;
      width: 20.25463vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .styles_saveModal__3ms4S {
      border-radius: 0.26042vw;
      margin: 0 0 1.25vw;
      width: 18.22917vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .styles_saveModal__3ms4S {
      border-radius: 1.00644vw;
      margin: 0 0 4.83092vw 6.03865vw;
      width: 52.33494vw; }
      .styles_saveModal__3ms4S else {
        border-radius: 5px; }
      .styles_saveModal__3ms4S else {
        width: 260px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .styles_saveModal__3ms4S {
      border-radius: 0.80515vw;
      margin: 0 0 3.86473vw 4.83092vw;
      width: 41.86795vw; }
      .styles_saveModal__3ms4S else {
        border-radius: 5px; }
      .styles_saveModal__3ms4S else {
        width: 260px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .styles_saveModal__3ms4S {
      border-radius: 0.71043vw;
      margin: 0 0 3.41006vw 4.26257vw;
      width: 36.94231vw; }
      .styles_saveModal__3ms4S else {
        border-radius: 5px; }
      .styles_saveModal__3ms4S else {
        width: 260px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .styles_saveModal__3ms4S {
      border-radius: 0.63565vw;
      margin: 0 0 3.05111vw 3.81388vw;
      width: 33.05365vw; }
      .styles_saveModal__3ms4S else {
        border-radius: 5px; }
      .styles_saveModal__3ms4S else {
        width: 260px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .styles_saveModal__3ms4S {
      border-radius: 0.57511vw;
      margin: 0 0 2.76052vw 3.45066vw;
      width: 29.90568vw; }
      .styles_saveModal__3ms4S else {
        border-radius: 5px; }
      .styles_saveModal__3ms4S else {
        width: 260px; } }
  .styles_saveModal__3ms4S p {
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffffc1;
    font-size: 3.86473vw; }
    @media (min-width: 1300px) {
      .styles_saveModal__3ms4S p {
        font-size: 0.83333vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .styles_saveModal__3ms4S p {
        font-size: 0.92593vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .styles_saveModal__3ms4S p {
        font-size: 0.83333vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .styles_saveModal__3ms4S p {
        font-size: 3.22061vw; }
        .styles_saveModal__3ms4S p else {
          font-size: 16px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .styles_saveModal__3ms4S p {
        font-size: 2.57649vw; }
        .styles_saveModal__3ms4S p else {
          font-size: 16px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .styles_saveModal__3ms4S p {
        font-size: 2.27337vw; }
        .styles_saveModal__3ms4S p else {
          font-size: 16px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .styles_saveModal__3ms4S p {
        font-size: 2.03407vw; }
        .styles_saveModal__3ms4S p else {
          font-size: 16px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .styles_saveModal__3ms4S p {
        font-size: 1.84035vw; }
        .styles_saveModal__3ms4S p else {
          font-size: 16px; } }
  .styles_saveModal__3ms4S input,
  .styles_saveModal__3ms4S textarea {
    width: inherit;
    border: none;
    background-color: #041c24;
    color: #ffffff;
    font-family: "Nunito";
    font-style: italic;
    resize: vertical;
    margin: 0 0 1.44928vw;
    padding: 2.657vw 3.38164vw 2.657vw 3.86473vw;
    border-radius: 5px; }
    @media (min-width: 1300px) {
      .styles_saveModal__3ms4S input,
      .styles_saveModal__3ms4S textarea {
        margin: 0 0 0.26042vw;
        padding: 0.46875vw 0.72917vw 0.72917vw 0.83333vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .styles_saveModal__3ms4S input,
      .styles_saveModal__3ms4S textarea {
        margin: 0 0 0.28935vw;
        padding: 0.52083vw 0.81019vw 0.81019vw 0.92593vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .styles_saveModal__3ms4S input,
      .styles_saveModal__3ms4S textarea {
        margin: 0 0 0.26042vw;
        padding: 0.46875vw 0.72917vw 0.72917vw 0.83333vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .styles_saveModal__3ms4S input,
      .styles_saveModal__3ms4S textarea {
        margin: 0 0 1.20773vw;
        padding: 2.21417vw 2.81804vw 2.21417vw 3.22061vw; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .styles_saveModal__3ms4S input,
      .styles_saveModal__3ms4S textarea {
        margin: 0 0 0.96618vw;
        padding: 1.77134vw 2.25443vw 1.77134vw 2.57649vw; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .styles_saveModal__3ms4S input,
      .styles_saveModal__3ms4S textarea {
        margin: 0 0 0.85251vw;
        padding: 1.56294vw 1.9892vw 1.56294vw 2.27337vw; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .styles_saveModal__3ms4S input,
      .styles_saveModal__3ms4S textarea {
        margin: 0 0 0.76278vw;
        padding: 1.39842vw 1.77981vw 1.39842vw 2.03407vw; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .styles_saveModal__3ms4S input,
      .styles_saveModal__3ms4S textarea {
        margin: 0 0 0.69013vw;
        padding: 1.26524vw 1.61031vw 1.26524vw 1.84035vw; } }
  .styles_saveModal__3ms4S textarea {
    margin: 0;
    height: 28.98551vw; }
    @media (min-width: 1300px) {
      .styles_saveModal__3ms4S textarea {
        margin: 0;
        height: 7.8125vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .styles_saveModal__3ms4S textarea {
        margin: 0;
        height: 8.68056vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .styles_saveModal__3ms4S textarea {
        margin: 0;
        height: 7.8125vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .styles_saveModal__3ms4S textarea {
        height: 24.15459vw; }
        .styles_saveModal__3ms4S textarea else {
          margin: 0; }
        .styles_saveModal__3ms4S textarea else {
          height: 120px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .styles_saveModal__3ms4S textarea {
        height: 19.32367vw; }
        .styles_saveModal__3ms4S textarea else {
          margin: 0; }
        .styles_saveModal__3ms4S textarea else {
          height: 120px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .styles_saveModal__3ms4S textarea {
        height: 17.0503vw; }
        .styles_saveModal__3ms4S textarea else {
          margin: 0; }
        .styles_saveModal__3ms4S textarea else {
          height: 120px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .styles_saveModal__3ms4S textarea {
        height: 15.25553vw; }
        .styles_saveModal__3ms4S textarea else {
          margin: 0; }
        .styles_saveModal__3ms4S textarea else {
          height: 120px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .styles_saveModal__3ms4S textarea {
        height: 13.80262vw; }
        .styles_saveModal__3ms4S textarea else {
          margin: 0; }
        .styles_saveModal__3ms4S textarea else {
          height: 120px; } }

.styles_showSaveModal__CsOFI {
  opacity: 1;
  display: inline-table;
  padding: 2.17391vw 3.38164vw 7.24638vw 3.86473vw;
  min-height: 67.63285vw; }
  @media (min-width: 1300px) {
    .styles_showSaveModal__CsOFI {
      padding: 0.46875vw 0.72917vw 1.5625vw 0.83333vw;
      min-height: 15.625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .styles_showSaveModal__CsOFI {
      padding: 0.52083vw 0.81019vw 1.73611vw 0.92593vw;
      min-height: 17.36111vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .styles_showSaveModal__CsOFI {
      padding: 0.46875vw 0.72917vw 1.5625vw 0.83333vw;
      min-height: 15.625vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .styles_showSaveModal__CsOFI {
      padding: 1.81159vw 2.81804vw 6.03865vw 3.22061vw;
      min-height: 56.36071vw; }
      .styles_showSaveModal__CsOFI else {
        min-height: 280px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .styles_showSaveModal__CsOFI {
      padding: 1.44928vw 2.25443vw 4.83092vw 2.57649vw;
      min-height: 45.08857vw; }
      .styles_showSaveModal__CsOFI else {
        min-height: 280px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .styles_showSaveModal__CsOFI {
      padding: 1.27877vw 1.9892vw 4.26257vw 2.27337vw;
      min-height: 39.78403vw; }
      .styles_showSaveModal__CsOFI else {
        min-height: 280px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .styles_showSaveModal__CsOFI {
      padding: 1.14416vw 1.77981vw 3.81388vw 2.03407vw;
      min-height: 35.59624vw; }
      .styles_showSaveModal__CsOFI else {
        min-height: 280px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .styles_showSaveModal__CsOFI {
      padding: 1.0352vw 1.61031vw 3.45066vw 1.84035vw;
      min-height: 32.20612vw; }
      .styles_showSaveModal__CsOFI else {
        min-height: 280px; } }

.styles_hideSaveModal__12wWv {
  min-height: 0;
  height: 0;
  padding: 0 14px 0 16px;
  opacity: 0; }

.styles_roundedButtons__136QY {
  /* height: 3.125vw; */
  border-radius: 20px;
  /* min-width: 60px; */
  /* width: max-content; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  pointer-events: auto;
  cursor: pointer;
  background-color: #00ade6;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  color: #ffffff;
  font-family: Nunito;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  padding: 1.93237vw 7.72947vw;
  margin-bottom: 2.41546vw;
  font-size: 3.86473vw; }
  @media (min-width: 1300px) {
    .styles_roundedButtons__136QY {
      padding: 0.41667vw 1.66667vw;
      font-size: 0.83333vw;
      margin-bottom: unset; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .styles_roundedButtons__136QY {
      padding: 0.46296vw 1.85185vw;
      font-size: 0.92593vw;
      margin-bottom: unset; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .styles_roundedButtons__136QY {
      padding: 0.41667vw 1.66667vw;
      font-size: 0.83333vw;
      margin-bottom: unset; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .styles_roundedButtons__136QY {
      padding: 1.61031vw 6.44122vw;
      margin-bottom: 2.01288vw;
      font-size: 3.22061vw; }
      .styles_roundedButtons__136QY else {
        margin-bottom: 10px; }
      .styles_roundedButtons__136QY else {
        font-size: 16px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .styles_roundedButtons__136QY {
      padding: 1.28824vw 5.15298vw;
      margin-bottom: 1.61031vw;
      font-size: 2.57649vw; }
      .styles_roundedButtons__136QY else {
        margin-bottom: 10px; }
      .styles_roundedButtons__136QY else {
        font-size: 16px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .styles_roundedButtons__136QY {
      padding: 1.13669vw 4.54675vw;
      margin-bottom: 1.42086vw;
      font-size: 2.27337vw; }
      .styles_roundedButtons__136QY else {
        margin-bottom: 10px; }
      .styles_roundedButtons__136QY else {
        font-size: 16px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .styles_roundedButtons__136QY {
      padding: 1.01704vw 4.06814vw;
      margin-bottom: 1.27129vw;
      font-size: 2.03407vw; }
      .styles_roundedButtons__136QY else {
        margin-bottom: 10px; }
      .styles_roundedButtons__136QY else {
        font-size: 16px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .styles_roundedButtons__136QY {
      padding: 0.92017vw 3.6807vw;
      margin-bottom: 1.15022vw;
      font-size: 1.84035vw; }
      .styles_roundedButtons__136QY else {
        margin-bottom: 10px; }
      .styles_roundedButtons__136QY else {
        font-size: 16px; } }
  .styles_roundedButtons__136QY.styles_cancelButton__1DlQv {
    background-color: transparent;
    border: solid 2px #00ade6;
    box-sizing: border-box;
    color: #ffffffbf; }
  .styles_roundedButtons__136QY:disabled {
    opacity: 0.5;
    cursor: auto; }
  .styles_roundedButtons__136QY:focus {
    border: 0;
    outline: 0;
    box-shadow: 0; }

.styles_lastSavedAt__2mcM6 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  color: #dcdcdc;
  opacity: 1; }

.styles_fileNameContainer__2o0a_ {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end; }
  @media (min-width: 1300px) {
    .styles_fileNameContainer__2o0a_ {
      top: 0.15625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .styles_fileNameContainer__2o0a_ {
      top: 0.17361vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .styles_fileNameContainer__2o0a_ {
      top: 0.15625vw; } }

.styles_fileName__3DH5V {
  color: white;
  font-family: "Nunito"; }
  .styles_fileName__3DH5V:hover {
    text-decoration: underline;
    cursor: pointer; }
  @media (min-width: 1300px) {
    .styles_fileName__3DH5V {
      font-size: 1.04167vw;
      line-height: 1.14583vw;
      letter-spacing: -0.02em; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .styles_fileName__3DH5V {
      font-size: 1.15741vw;
      line-height: 1.27315vw;
      letter-spacing: -0.02em; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .styles_fileName__3DH5V {
      font-size: 1.04167vw;
      line-height: 1.14583vw;
      letter-spacing: -0.02em; } }

.styles_fileName__locked__WYpPW:hover {
  cursor: not-allowed;
  text-decoration: none; }

.styles_aceEditor__1P36O {
  top: 2.41546vw;
  height: 100%;
  font-size: 3.86473vw;
  line-height: 5.7971vw; }
  @media (min-width: 1300px) {
    .styles_aceEditor__1P36O {
      top: 1.04167vw;
      height: 100%;
      font-size: 1.14583vw;
      line-height: 1.71875vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .styles_aceEditor__1P36O {
      top: 1.15741vw;
      height: 100%;
      font-size: 1.27315vw;
      line-height: 1.90972vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .styles_aceEditor__1P36O {
      top: 1.04167vw;
      height: 100%;
      font-size: 1.14583vw;
      line-height: 1.71875vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .styles_aceEditor__1P36O {
      top: 2.01288vw;
      font-size: 3.22061vw;
      line-height: 4.83092vw; }
      .styles_aceEditor__1P36O else {
        top: 10px; }
      .styles_aceEditor__1P36O else {
        height: 100%; }
      .styles_aceEditor__1P36O else {
        font-size: 16px; }
      .styles_aceEditor__1P36O else {
        line-height: 24px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .styles_aceEditor__1P36O {
      top: 1.61031vw;
      font-size: 2.57649vw;
      line-height: 3.86473vw; }
      .styles_aceEditor__1P36O else {
        top: 10px; }
      .styles_aceEditor__1P36O else {
        height: 100%; }
      .styles_aceEditor__1P36O else {
        font-size: 16px; }
      .styles_aceEditor__1P36O else {
        line-height: 24px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .styles_aceEditor__1P36O {
      top: 1.42086vw;
      font-size: 2.27337vw;
      line-height: 3.41006vw; }
      .styles_aceEditor__1P36O else {
        top: 10px; }
      .styles_aceEditor__1P36O else {
        height: 100%; }
      .styles_aceEditor__1P36O else {
        font-size: 16px; }
      .styles_aceEditor__1P36O else {
        line-height: 24px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .styles_aceEditor__1P36O {
      top: 1.27129vw;
      font-size: 2.03407vw;
      line-height: 3.05111vw; }
      .styles_aceEditor__1P36O else {
        top: 10px; }
      .styles_aceEditor__1P36O else {
        height: 100%; }
      .styles_aceEditor__1P36O else {
        font-size: 16px; }
      .styles_aceEditor__1P36O else {
        line-height: 24px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .styles_aceEditor__1P36O {
      top: 1.15022vw;
      font-size: 1.84035vw;
      line-height: 2.76052vw; }
      .styles_aceEditor__1P36O else {
        top: 10px; }
      .styles_aceEditor__1P36O else {
        height: 100%; }
      .styles_aceEditor__1P36O else {
        font-size: 16px; }
      .styles_aceEditor__1P36O else {
        line-height: 24px; } }

.styles_editorLoader__3JKJW {
  background-color: transparent !important;
  background-image: none !important;
  margin-right: 1.20773vw;
  width: 3.62319vw;
  height: 3.38164vw;
  left: -1.20773vw; }
  @media (min-width: 1300px) {
    .styles_editorLoader__3JKJW {
      margin-right: 0.26042vw;
      width: 1.04167vw;
      height: 1.04167vw;
      left: -0.52083vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .styles_editorLoader__3JKJW {
      margin-right: 0.28935vw;
      width: 1.15741vw;
      height: 1.15741vw;
      left: -0.5787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .styles_editorLoader__3JKJW {
      margin-right: 0.26042vw;
      width: 1.04167vw;
      height: 1.04167vw;
      left: -0.52083vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .styles_editorLoader__3JKJW {
      margin-right: 1.00644vw;
      width: 3.01932vw;
      height: 2.81804vw;
      left: -1.00644vw; }
      .styles_editorLoader__3JKJW else {
        margin-right: 5px; }
      .styles_editorLoader__3JKJW else {
        width: 15px; }
      .styles_editorLoader__3JKJW else {
        height: 14px; }
      .styles_editorLoader__3JKJW else {
        left: -5px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .styles_editorLoader__3JKJW {
      margin-right: 0.80515vw;
      width: 2.41546vw;
      height: 2.25443vw;
      left: -0.80515vw; }
      .styles_editorLoader__3JKJW else {
        margin-right: 5px; }
      .styles_editorLoader__3JKJW else {
        width: 15px; }
      .styles_editorLoader__3JKJW else {
        height: 14px; }
      .styles_editorLoader__3JKJW else {
        left: -5px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .styles_editorLoader__3JKJW {
      margin-right: 0.71043vw;
      width: 2.13129vw;
      height: 1.9892vw;
      left: -0.71043vw; }
      .styles_editorLoader__3JKJW else {
        margin-right: 5px; }
      .styles_editorLoader__3JKJW else {
        width: 15px; }
      .styles_editorLoader__3JKJW else {
        height: 14px; }
      .styles_editorLoader__3JKJW else {
        left: -5px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .styles_editorLoader__3JKJW {
      margin-right: 0.63565vw;
      width: 1.90694vw;
      height: 1.77981vw;
      left: -0.63565vw; }
      .styles_editorLoader__3JKJW else {
        margin-right: 5px; }
      .styles_editorLoader__3JKJW else {
        width: 15px; }
      .styles_editorLoader__3JKJW else {
        height: 14px; }
      .styles_editorLoader__3JKJW else {
        left: -5px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .styles_editorLoader__3JKJW {
      margin-right: 0.57511vw;
      width: 1.72533vw;
      height: 1.61031vw;
      left: -0.57511vw; }
      .styles_editorLoader__3JKJW else {
        margin-right: 5px; }
      .styles_editorLoader__3JKJW else {
        width: 15px; }
      .styles_editorLoader__3JKJW else {
        height: 14px; }
      .styles_editorLoader__3JKJW else {
        left: -5px; } }

.styles_LoadingIcon__2hrAp {
  background-color: #23d1e9 !important;
  margin: 30px !important;
  display: block !important;
  width: 20px !important;
  height: 20px !important;
  bottom: 10px !important;
  right: 0 !important;
  left: unset !important;
  position: absolute !important; }

.styles_mobileBodyContainer__1coh5 {
  display: flex;
  flex-direction: row;
  width: 100vw;
  overflow-x: hidden;
  height: 100%; }

.styles_mobileOutputShow__1zCgJ {
  -webkit-transform: translateX(-100vw);
          transform: translateX(-100vw); }

.styles_mobileOutputHidden__1Jf0Y {
  -webkit-transform: translateX(0vw);
          transform: translateX(0vw); }

.styles_actionContainer__3CzbO {
  display: flex;
  justify-content: space-around;
  margin: 3.86473vw 0vw 0vw; }
  @media (min-width: 1300px) {
    .styles_actionContainer__3CzbO {
      margin: 0.83333vw 0vw 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .styles_actionContainer__3CzbO {
      margin: 0.92593vw 0vw 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .styles_actionContainer__3CzbO {
      margin: 0.83333vw 0vw 0.41667vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .styles_actionContainer__3CzbO {
      margin: 3.22061vw 0vw 0vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .styles_actionContainer__3CzbO {
      margin: 2.57649vw 0vw 0vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .styles_actionContainer__3CzbO {
      margin: 2.27337vw 0vw 0vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .styles_actionContainer__3CzbO {
      margin: 2.03407vw 0vw 0vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .styles_actionContainer__3CzbO {
      margin: 1.84035vw 0vw 0vw; } }

::-webkit-scrollbar {
  max-width: 0.52083vw; }

.styles_postOnCommunityBtn__2jn0F {
  font-family: "Nunito";
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  margin: 0.96618vw 0 0vw; }
  @media (min-width: 1300px) {
    .styles_postOnCommunityBtn__2jn0F {
      margin: 0.41667vw 0 0vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .styles_postOnCommunityBtn__2jn0F {
      margin: 0.46296vw 0 0vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .styles_postOnCommunityBtn__2jn0F {
      margin: 0.41667vw 0 0vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .styles_postOnCommunityBtn__2jn0F {
      margin: 0.80515vw 0 0vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .styles_postOnCommunityBtn__2jn0F {
      margin: 0.64412vw 0 0vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .styles_postOnCommunityBtn__2jn0F {
      margin: 0.56834vw 0 0vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .styles_postOnCommunityBtn__2jn0F {
      margin: 0.50852vw 0 0vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .styles_postOnCommunityBtn__2jn0F {
      margin: 0.46009vw 0 0vw; } }
  .styles_postOnCommunityBtn__2jn0F .styles_checkBoxContainer__2QlFR {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 5.7971vw;
    width: 5.7971vw; }
    @media (min-width: 450px) and (max-width: 500px) {
      .styles_postOnCommunityBtn__2jn0F .styles_checkBoxContainer__2QlFR {
        height: 4.83092vw;
        width: 4.83092vw; }
        .styles_postOnCommunityBtn__2jn0F .styles_checkBoxContainer__2QlFR else {
          height: 24px; }
        .styles_postOnCommunityBtn__2jn0F .styles_checkBoxContainer__2QlFR else {
          width: 24px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .styles_postOnCommunityBtn__2jn0F .styles_checkBoxContainer__2QlFR {
        height: 3.86473vw;
        width: 3.86473vw; }
        .styles_postOnCommunityBtn__2jn0F .styles_checkBoxContainer__2QlFR else {
          height: 24px; }
        .styles_postOnCommunityBtn__2jn0F .styles_checkBoxContainer__2QlFR else {
          width: 24px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .styles_postOnCommunityBtn__2jn0F .styles_checkBoxContainer__2QlFR {
        height: 3.41006vw;
        width: 3.41006vw; }
        .styles_postOnCommunityBtn__2jn0F .styles_checkBoxContainer__2QlFR else {
          height: 24px; }
        .styles_postOnCommunityBtn__2jn0F .styles_checkBoxContainer__2QlFR else {
          width: 24px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .styles_postOnCommunityBtn__2jn0F .styles_checkBoxContainer__2QlFR {
        height: 3.05111vw;
        width: 3.05111vw; }
        .styles_postOnCommunityBtn__2jn0F .styles_checkBoxContainer__2QlFR else {
          height: 24px; }
        .styles_postOnCommunityBtn__2jn0F .styles_checkBoxContainer__2QlFR else {
          width: 24px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .styles_postOnCommunityBtn__2jn0F .styles_checkBoxContainer__2QlFR {
        height: 2.76052vw;
        width: 2.76052vw; }
        .styles_postOnCommunityBtn__2jn0F .styles_checkBoxContainer__2QlFR else {
          height: 24px; }
        .styles_postOnCommunityBtn__2jn0F .styles_checkBoxContainer__2QlFR else {
          width: 24px; } }
    @media (min-width: 1300px) {
      .styles_postOnCommunityBtn__2jn0F .styles_checkBoxContainer__2QlFR {
        height: 0.9375vw;
        width: 1.04167vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .styles_postOnCommunityBtn__2jn0F .styles_checkBoxContainer__2QlFR {
        height: 1.04167vw;
        width: 1.15741vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .styles_postOnCommunityBtn__2jn0F .styles_checkBoxContainer__2QlFR {
        height: 0.9375vw;
        width: 1.04167vw; } }
  .styles_postOnCommunityBtn__2jn0F > span {
    padding: 0px 6px;
    -webkit-user-select: none;
            user-select: none;
    font-size: 3.86473vw; }
    @media (min-width: 1300px) {
      .styles_postOnCommunityBtn__2jn0F > span {
        font-size: 0.83333vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .styles_postOnCommunityBtn__2jn0F > span {
        font-size: 0.83333vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .styles_postOnCommunityBtn__2jn0F > span {
        font-size: 0.83333vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .styles_postOnCommunityBtn__2jn0F > span {
        font-size: 3.22061vw; }
        .styles_postOnCommunityBtn__2jn0F > span else {
          font-size: 16px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .styles_postOnCommunityBtn__2jn0F > span {
        font-size: 2.57649vw; }
        .styles_postOnCommunityBtn__2jn0F > span else {
          font-size: 16px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .styles_postOnCommunityBtn__2jn0F > span {
        font-size: 2.27337vw; }
        .styles_postOnCommunityBtn__2jn0F > span else {
          font-size: 16px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .styles_postOnCommunityBtn__2jn0F > span {
        font-size: 2.03407vw; }
        .styles_postOnCommunityBtn__2jn0F > span else {
          font-size: 16px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .styles_postOnCommunityBtn__2jn0F > span {
        font-size: 1.84035vw; }
        .styles_postOnCommunityBtn__2jn0F > span else {
          font-size: 16px; } }

.styles_editButton__2yR0M {
  width: 13.28502vw;
  height: 16.90821vw;
  background-repeat: no-repeat;
  background-image: url(/static/media/editButtonPlayground.0aa256fb.png) !important; }
  @media (min-width: 450px) and (max-width: 500px) {
    .styles_editButton__2yR0M {
      width: 11.07085vw;
      height: 14.09018vw; }
      .styles_editButton__2yR0M else {
        width: 55px; }
      .styles_editButton__2yR0M else {
        height: 70px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .styles_editButton__2yR0M {
      width: 8.85668vw;
      height: 11.27214vw; }
      .styles_editButton__2yR0M else {
        width: 55px; }
      .styles_editButton__2yR0M else {
        height: 70px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .styles_editButton__2yR0M {
      width: 7.81472vw;
      height: 9.94601vw; }
      .styles_editButton__2yR0M else {
        width: 55px; }
      .styles_editButton__2yR0M else {
        height: 70px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .styles_editButton__2yR0M {
      width: 6.99212vw;
      height: 8.89906vw; }
      .styles_editButton__2yR0M else {
        width: 55px; }
      .styles_editButton__2yR0M else {
        height: 70px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .styles_editButton__2yR0M {
      width: 6.3262vw;
      height: 8.05153vw; }
      .styles_editButton__2yR0M else {
        width: 55px; }
      .styles_editButton__2yR0M else {
        height: 70px; } }

.styles_saveCodingLoading__1Tug4 {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.4rem;
  gap: 0.4rem;
  margin-left: 5px; }
  .styles_saveCodingLoading__1Tug4 p {
    margin: 0;
    font-weight: 700; }
    @media (min-width: 1300px) {
      .styles_saveCodingLoading__1Tug4 p {
        font-size: 0.83333vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .styles_saveCodingLoading__1Tug4 p {
        font-size: 0.83333vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .styles_saveCodingLoading__1Tug4 p {
        font-size: 0.83333vw; } }
  .styles_saveCodingLoading__1Tug4 .styles_saveCodeState__3WifP {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 15px;
    height: 15px; }
    @media (min-width: 1300px) {
      .styles_saveCodingLoading__1Tug4 .styles_saveCodeState__3WifP .styles_checkIconWrapper__qcaqh {
        width: 0.83333vw;
        height: 0.83333vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .styles_saveCodingLoading__1Tug4 .styles_saveCodeState__3WifP .styles_checkIconWrapper__qcaqh {
        width: 0.92593vw;
        height: 0.92593vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .styles_saveCodingLoading__1Tug4 .styles_saveCodeState__3WifP .styles_checkIconWrapper__qcaqh {
        width: 0.83333vw;
        height: 0.83333vw; } }
    .styles_saveCodingLoading__1Tug4 .styles_saveCodeState__3WifP .styles_checkIconWrapper__qcaqh svg,
    .styles_saveCodingLoading__1Tug4 .styles_saveCodeState__3WifP .styles_checkIconWrapper__qcaqh rect {
      width: 100%;
      height: 100%; }
  .styles_saveCodingLoading__1Tug4 svg {
    display: block; }

.styles_spinner__2yJu0 {
  --spinner-size: 1;
  --line-color: #0499ff;
  --line-alpha: 1;
  --ring-color: #0499ff;
  --ring-alpha: 0.09;
  --ring-size: 6;
  width: 11px;
  height: 11px;
  border-radius: 50%; }

.styles_spinner__2yJu0 .styles_line__71ak_ {
  fill: none;
  stroke: var(--line-color);
  stroke-width: var(--ring-size);
  opacity: var(--line-alpha);
  stroke-linecap: round;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
          transform: rotate3d(0, 0, 1, 0deg);
  -webkit-animation: styles_arc__2d5-6 2156ms ease-in-out infinite, styles_rotate__2fWB2 1829ms linear infinite;
          animation: styles_arc__2d5-6 2156ms ease-in-out infinite, styles_rotate__2fWB2 1829ms linear infinite; }

.styles_spinner__2yJu0 .styles_ring__15Qfh {
  fill: none;
  stroke: var(--ring-color);
  stroke-width: var(--ring-size);
  opacity: var(--ring-alpha); }

@-webkit-keyframes styles_rotate__2fWB2 {
  to {
    -webkit-transform: rotate3d(0, 0, 1, 360deg);
            transform: rotate3d(0, 0, 1, 360deg); } }

@keyframes styles_rotate__2fWB2 {
  to {
    -webkit-transform: rotate3d(0, 0, 1, 360deg);
            transform: rotate3d(0, 0, 1, 360deg); } }

@-webkit-keyframes styles_arc__2d5-6 {
  from {
    stroke-dasharray: 0 150;
    stroke-dashoffset: 0; }
  to {
    stroke-dasharray: 100 150;
    stroke-dashoffset: -140; } }

@keyframes styles_arc__2d5-6 {
  from {
    stroke-dasharray: 0 150;
    stroke-dashoffset: 0; }
  to {
    stroke-dasharray: 100 150;
    stroke-dashoffset: -140; } }

.styles_javaEditorNavBarText__32U7i, .styles_javaEditorNavBarCopyButton__31ETk {
  display: flex;
  align-items: center;
  color: #FFFFFF;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  cursor: pointer; }
  @media (min-width: 1300px) {
    .styles_javaEditorNavBarText__32U7i, .styles_javaEditorNavBarCopyButton__31ETk {
      font-size: 0.86148vw;
      line-height: 1.19792vw;
      font-weight: 700; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .styles_javaEditorNavBarText__32U7i, .styles_javaEditorNavBarCopyButton__31ETk {
      font-size: 0.9572vw;
      line-height: 1.33102vw;
      font-weight: 700; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .styles_javaEditorNavBarText__32U7i, .styles_javaEditorNavBarCopyButton__31ETk {
      font-size: 0.86148vw;
      line-height: 1.19792vw;
      font-weight: 700; } }

@media (min-width: 1300px) {
  .styles_javaEditorNavBarCopyButton__31ETk {
    font-size: 0.625vw;
    font-weight: 500; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .styles_javaEditorNavBarCopyButton__31ETk {
    font-size: 0.69444vw;
    font-weight: 500; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .styles_javaEditorNavBarCopyButton__31ETk {
    font-size: 0.625vw;
    font-weight: 500; } }

@media (min-width: 1300px) {
  .styles_javaEditorNavBarCopyButton__31ETk p {
    margin-left: 0.20833vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .styles_javaEditorNavBarCopyButton__31ETk p {
    margin-left: 0.23148vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .styles_javaEditorNavBarCopyButton__31ETk p {
    margin-left: 0.20833vw; } }

.styles_toolTipText__3YwJ3 {
  display: flex;
  flex-direction: column; }
  @media (min-width: 1300px) {
    .styles_toolTipText__3YwJ3 {
      padding: 0vw 0.52083vw 0.52083vw 0.52083vw;
      line-height: 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .styles_toolTipText__3YwJ3 {
      padding: 0vw 0.5787vw 0.5787vw 0.5787vw;
      line-height: 1.38889vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .styles_toolTipText__3YwJ3 {
      padding: 0vw 0.52083vw 0.52083vw 0.52083vw;
      line-height: 1.25vw; } }
  .styles_toolTipText__3YwJ3 p {
    text-overflow: ellipsis;
    font-weight: 400;
    margin: 0;
    color: #504F4F; }
    @media (min-width: 1300px) {
      .styles_toolTipText__3YwJ3 p {
        font-size: 0.83333vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .styles_toolTipText__3YwJ3 p {
        font-size: 0.92593vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .styles_toolTipText__3YwJ3 p {
        font-size: 0.83333vw; } }
  .styles_toolTipText__3YwJ3 button {
    align-self: center;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-weight: 700; }
    @media (min-width: 1300px) {
      .styles_toolTipText__3YwJ3 button {
        border: 0.10417vw solid #00ADE6;
        border-radius: 1.04167vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .styles_toolTipText__3YwJ3 button {
        border: 0.11574vw solid #00ADE6;
        border-radius: 1.15741vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .styles_toolTipText__3YwJ3 button {
        border: 0.10417vw solid #00ADE6;
        border-radius: 1.04167vw; } }

.styles_gotItButton__2Wcf9 {
  color: white;
  background-color: #F0FBFF; }
  @media (min-width: 1300px) {
    .styles_gotItButton__2Wcf9 {
      font-size: 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .styles_gotItButton__2Wcf9 {
      font-size: 0.92593vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .styles_gotItButton__2Wcf9 {
      font-size: 0.83333vw; } }

.styles_editorLink__1Vx9Q {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer; }
  .styles_editorLink__1Vx9Q span {
    color: #00ADE6;
    font-weight: 700;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline; }
    @media (min-width: 1300px) {
      .styles_editorLink__1Vx9Q span {
        font-size: 0.83333vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .styles_editorLink__1Vx9Q span {
        font-size: 0.92593vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .styles_editorLink__1Vx9Q span {
        font-size: 0.83333vw; } }

.styles_viewOutput__2L8NN {
  display: flex;
  justify-content: center;
  align-items: center; }
  .styles_viewOutput__2L8NN img {
    cursor: pointer; }
    @media (min-width: 1300px) {
      .styles_viewOutput__2L8NN img {
        height: 1.30208vw;
        width: 1.30208vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .styles_viewOutput__2L8NN img {
        height: 1.44676vw;
        width: 1.44676vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .styles_viewOutput__2L8NN img {
        height: 1.30208vw;
        width: 1.30208vw; } }
  .styles_viewOutput__2L8NN span {
    letter-spacing: -0.02em;
    text-transform: capitalize;
    font-weight: bold; }
    @media (min-width: 1300px) {
      .styles_viewOutput__2L8NN span {
        margin-left: 0.52083vw;
        font-size: 1.04167vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .styles_viewOutput__2L8NN span {
        margin-left: 0.5787vw;
        font-size: 1.15741vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .styles_viewOutput__2L8NN span {
        margin-left: 0.52083vw;
        font-size: 1.04167vw; } }

.styles_settingIcon__wvcGl {
  cursor: pointer; }
  @media (min-width: 1300px) {
    .styles_settingIcon__wvcGl {
      width: 1.45823vw;
      height: 1.49271vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .styles_settingIcon__wvcGl {
      width: 1.62025vw;
      height: 1.65856vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .styles_settingIcon__wvcGl {
      width: 1.45823vw;
      height: 1.49271vw; } }
  .styles_settingIcon__wvcGl.styles_active__3W-ei {
    fill: #fff; }
  .styles_settingIcon__wvcGl:hover + .styles_settingsBox__3qbvo {
    opacity: 1; }

.styles_settingsBox__3qbvo {
  z-index: 9;
  position: absolute;
  top: 120%;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 0px 16px 0px rgba(56, 56, 56, 0.12);
  width: 300px;
  right: 20%; }
  @media (min-width: 1300px) {
    .styles_settingsBox__3qbvo {
      width: 8.64583vw;
      padding: 0.20833vw 0vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .styles_settingsBox__3qbvo {
      width: 9.60648vw;
      padding: 0.23148vw 0vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .styles_settingsBox__3qbvo {
      width: 8.64583vw;
      padding: 0.20833vw 0vw; } }

.styles_settingsContainer__2mcny {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column; }

.styles_settingsBoxItem__2FRWu {
  box-sizing: border-box;
  padding: 4px 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2); }

.styles_settingsItemSeperator__12peR {
  margin: 0;
  padding: 0; }

.styles_settingsBoxItem__2FRWu:last-child {
  border-bottom: none; }

.styles_settingBoxItemLabel__1UzZY {
  -webkit-user-select: none;
          user-select: none;
  color: #504F4F;
  color: var(--neutral-emperor, #504F4F); }
  @media (min-width: 1300px) {
    .styles_settingBoxItemLabel__1UzZY {
      font-size: 0.72917vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .styles_settingBoxItemLabel__1UzZY {
      font-size: 0.81019vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .styles_settingBoxItemLabel__1UzZY {
      font-size: 0.72917vw; } }

.styles_layoutIcon__zL3nv {
  all: unset;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 3px;
  border-style: solid;
  border-color: #313131;
  border-width: 1px;
  padding: 4px 3px 4px 3px;
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px; }
  @media (min-width: 1300px) {
    .styles_layoutIcon__zL3nv {
      width: 2.76042vw;
      height: 2.23958vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .styles_layoutIcon__zL3nv {
      width: 3.06713vw;
      height: 2.48843vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .styles_layoutIcon__zL3nv {
      width: 2.76042vw;
      height: 2.23958vw; } }
  .styles_layoutIcon__zL3nv.styles_layoutIconActive__7a4Ta {
    border-color: #00ADE6; }

.styles_layoutIconFirst__2BBlU {
  width: 100%;
  border-radius: 1px;
  flex-basis: 30%;
  background-color: #005773; }

.styles_layoutIconSecond__fKSd- {
  width: 100%;
  flex-basis: 70%;
  border-radius: 1px;
  background-color: #B2CDD5; }

.styles_increaseCounter__3o806 {
  display: flex;
  grid-gap: 6px;
  gap: 6px;
  color: #504F4F;
  align-items: center; }
  .styles_increaseCounter__3o806 > button {
    font-family: inherit;
    background-color: transparent;
    border: none;
    outline: none; }

.styles_increaseCounterButton__2JKJ8 {
  cursor: pointer;
  color: #A8A7A7; }
  @media (min-width: 1300px) {
    .styles_increaseCounterButton__2JKJ8 {
      width: 1.77083vw;
      height: 1.77083vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .styles_increaseCounterButton__2JKJ8 {
      width: 1.96759vw;
      height: 1.96759vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .styles_increaseCounterButton__2JKJ8 {
      width: 1.77083vw;
      height: 1.77083vw; } }

div[data-type="Resizer"] {
  position: relative;
  background: #121b1d;
  opacity: 1;
  z-index: 9;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  width: 10px;
  margin: 0 -5px;
  cursor: col-resize;
  background-repeat: no-repeat;
  background-image: url(/static/media/splitterHandle.fb58978f.svg);
  background-position: 50% 50%;
  border: 0 !important; }

div[data-type="web-splitter"] {
  position: relative;
  background: #121b1d;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  width: 10px;
  margin: 0 -5px;
  cursor: col-resize; }
  div[data-type="web-splitter"][data-dir="horizontal"] {
    height: 10px;
    width: 100%; }
    div[data-type="web-splitter"][data-dir="horizontal"] svg {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); }

.side-navbar-container {
  position: relative;
  width: 18.33333vw;
  height: 100%;
  background-image: linear-gradient(to bottom, #c9fcfd, #34e4ea);
  height: calc(100vh - 4.58333vw);
  overflow: overlay;
  display: none; }
  .side-navbar-container::-webkit-scrollbar {
    max-width: 0.41667vw;
    display: flex; }
  @media (min-width: 1300px) {
    .side-navbar-container {
      display: block; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .side-navbar-container {
      display: block; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .side-navbar-container {
      display: block; } }

.side-navbar-navBarCloudsContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 12.34375vw;
  pointer-events: none; }

.side-navbar-topicTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3.48958vw;
  margin-bottom: 1.97917vw;
  cursor: pointer; }

.side-navbar-topicTitle {
  font-size: 1.35417vw;
  color: #0B535F;
  font-weight: bold; }

.side-navbar-backArrow {
  margin-left: 1.04167vw;
  margin-right: 0.9375vw;
  width: 0.54688vw;
  height: 0.9375vw; }

.side-navbar-backIcon {
  position: relative;
  top: -0.17318vw; }

.side-navbar-logoutButton {
  position: absolute;
  bottom: 4.32812vw;
  left: 4.01042vw; }

.side-navbar-settingsSidebar {
  padding-top: 4.16667vw; }

.side-navbar-buttonContainer {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 4.32812vw; }

.side-navbar-disabled {
  opacity: 0.85; }

.side-navbar-emptyDiv {
  width: 100%;
  height: 3.54167vw; }

.SideNavItem_container__JpfIc, .SideNavItem_childContainer__1vkVD {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 3.54167vw;
  position: relative;
  z-index: 1;
  cursor: pointer;
  transition: 0s border ease-in-out 0.3s, 0.3s all ease-in-out;
  border-bottom: 1px solid #ffffff;
  -webkit-user-select: none;
          user-select: none; }

.SideNavItem_childHover__2-WDd:hover {
  background: #abffff;
  padding-left: 0.15625vw; }

.SideNavItem_noClick__23vy7 {
  cursor: not-allowed; }

.SideNavItem_parentHover__UNo88:hover {
  background: rgba(255, 255, 255, 0.4);
  padding-left: 0.15625vw; }

.SideNavItem_active__1AgwG {
  background-color: white; }

.SideNavItem_childContainer__1vkVD {
  border-bottom: none;
  transition: 0.3s color ease-in-out, 0.3s height ease-in-out, 0.3s background ease-in-out, 0.3s padding ease-in-out;
  background-color: #dcfafa;
  overflow: hidden; }

.SideNavItem_active__1AgwG {
  background-color: white; }

.SideNavItem_noPointer__2TgNJ {
  cursor: auto; }

.SideNavItem_space__3BIUW {
  justify-content: space-around; }

.SideNavItem_statusContainer__EI4_7 {
  width: 1.30208vw;
  height: 1.30208vw;
  margin-left: 2.5vw;
  margin-right: 1.04167vw; }

.SideNavItem_statusContainerChild__2SNQG {
  margin-left: 4.58333vw; }

.SideNavItem_title__8Lfan {
  font-size: 1.04167vw;
  font-weight: bold;
  color: #504f4f;
  flex: 1 1; }

.SideNavItem_dropdownContainer__f_Acv {
  width: 0.5vw;
  height: 0.85417vw;
  margin-right: 0.9375vw;
  transition: 0.3s all ease-in-out;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }

.SideNavItem_dropdownContainerOpen__34rg9 {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg); }

.SideNavItem_dropdownArrow__3GMN- {
  position: relative;
  top: -0.26042vw; }

.SideNavItem_noBorder__qSX4C {
  border: none;
  border-bottom: none; }

.SideNavItem_settingsLeftPadding__1vf6y {
  padding-left: 2.34375vw; }

.NextButton_iconContainer__n5q4G {
  position: relative;
  width: 11.3px;
  height: 11.6px;
  top: -2px;
  left: 2px; }

.NextButton_tickIconContainer__1vF4F {
  position: relative;
  width: 0.74479vw;
  height: 0.8125vw;
  top: -0.18229vw;
  left: -0.26042vw; }

.NextButton_saveIconContainer__3ogPY {
  position: relative;
  width: 16.3px;
  height: 16.6px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .NextButton_saveIconContainer__3ogPY svg {
    fill: #1ac9e8 !important;
    height: 20px;
    width: 18px;
    top: -2px;
    position: relative; }

.ActionButton_buttonContainer__28SJf {
  border-radius: 1.45833vw;
  display: flex;
  align-items: center;
  flex-direction: row;
  pointer-events: auto;
  cursor: pointer;
  background-image: linear-gradient(to top, #00ade6, #34e4ea);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin: 2.41546vw auto;
  height: 46.875vw;
  width: auto; }
  @media (min-width: 1300px) {
    .ActionButton_buttonContainer__28SJf {
      margin: unset;
      height: 2.8125vw;
      width: -webkit-max-content;
      width: max-content; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ActionButton_buttonContainer__28SJf {
      margin: unset;
      height: 2.8125vw;
      width: -webkit-max-content;
      width: max-content; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .ActionButton_buttonContainer__28SJf {
      margin: unset;
      height: 2.8125vw;
      width: -webkit-max-content;
      width: max-content; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .ActionButton_buttonContainer__28SJf {
      margin: 2.01288vw auto; }
      .ActionButton_buttonContainer__28SJf else {
        height: 46.875vw; }
      .ActionButton_buttonContainer__28SJf else {
        width: auto; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .ActionButton_buttonContainer__28SJf {
      margin: 1.61031vw auto; }
      .ActionButton_buttonContainer__28SJf else {
        height: 46.875vw; }
      .ActionButton_buttonContainer__28SJf else {
        width: auto; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .ActionButton_buttonContainer__28SJf {
      margin: 1.42086vw auto; }
      .ActionButton_buttonContainer__28SJf else {
        height: 46.875vw; }
      .ActionButton_buttonContainer__28SJf else {
        width: auto; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .ActionButton_buttonContainer__28SJf {
      margin: 1.27129vw auto; }
      .ActionButton_buttonContainer__28SJf else {
        height: 46.875vw; }
      .ActionButton_buttonContainer__28SJf else {
        width: auto; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .ActionButton_buttonContainer__28SJf {
      margin: 1.15022vw auto; }
      .ActionButton_buttonContainer__28SJf else {
        height: 46.875vw; }
      .ActionButton_buttonContainer__28SJf else {
        width: auto; } }

.ActionButton_buttonContainerPadding__2z3KY {
  padding: 0.05208vw 0.52083vw 0.05208vw 0; }

.ActionButton_smallButtonContainer__17C6W {
  height: 1.875vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 1.45833vw; }

.ActionButton_smallButtonText__r0sfe {
  height: 100%;
  font-size: 0.83333vw;
  font-weight: bold;
  margin: 0 0.98958vw 0 0.98958vw;
  display: flex;
  align-items: center; }

.ActionButton_buttonText__LE-_9 {
  -webkit-user-select: none;
          user-select: none;
  white-space: nowrap;
  font-weight: bold;
  color: #fff;
  margin-right: 0.625vw;
  font-size: 4.44444vw;
  margin-left: 0; }
  @media (min-width: 1300px) {
    .ActionButton_buttonText__LE-_9 {
      font-size: 1.19792vw;
      margin-left: 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ActionButton_buttonText__LE-_9 {
      font-size: 1.19792vw;
      margin-left: 1.25vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .ActionButton_buttonText__LE-_9 {
      font-size: 1.19792vw;
      margin-left: 1.25vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .ActionButton_buttonText__LE-_9 {
      font-size: 3.7037vw; }
      .ActionButton_buttonText__LE-_9 else {
        font-size: 16px; }
      .ActionButton_buttonText__LE-_9 else {
        margin-left: 0; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .ActionButton_buttonText__LE-_9 {
      font-size: 2.96296vw; }
      .ActionButton_buttonText__LE-_9 else {
        font-size: 16px; }
      .ActionButton_buttonText__LE-_9 else {
        margin-left: 0; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .ActionButton_buttonText__LE-_9 {
      font-size: 2.61438vw; }
      .ActionButton_buttonText__LE-_9 else {
        font-size: 16px; }
      .ActionButton_buttonText__LE-_9 else {
        margin-left: 0; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .ActionButton_buttonText__LE-_9 {
      font-size: 2.33918vw; }
      .ActionButton_buttonText__LE-_9 else {
        font-size: 16px; }
      .ActionButton_buttonText__LE-_9 else {
        margin-left: 0; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .ActionButton_buttonText__LE-_9 {
      font-size: 2.1164vw; }
      .ActionButton_buttonText__LE-_9 else {
        font-size: 16px; }
      .ActionButton_buttonText__LE-_9 else {
        margin-left: 0; } }

.ActionButton_chooseProductButtonContainer__3dLs3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #27d6e9;
  background-color: #ffffff;
  height: 5.7971vw;
  border-radius: 3.38164vw; }
  @media (min-width: 450px) and (max-width: 500px) {
    .ActionButton_chooseProductButtonContainer__3dLs3 {
      height: 4.83092vw;
      border-radius: 2.81804vw; }
      .ActionButton_chooseProductButtonContainer__3dLs3 else {
        height: 24px; }
      .ActionButton_chooseProductButtonContainer__3dLs3 else {
        border-radius: 14px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .ActionButton_chooseProductButtonContainer__3dLs3 {
      height: 3.86473vw;
      border-radius: 2.25443vw; }
      .ActionButton_chooseProductButtonContainer__3dLs3 else {
        height: 24px; }
      .ActionButton_chooseProductButtonContainer__3dLs3 else {
        border-radius: 14px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .ActionButton_chooseProductButtonContainer__3dLs3 {
      height: 3.41006vw;
      border-radius: 1.9892vw; }
      .ActionButton_chooseProductButtonContainer__3dLs3 else {
        height: 24px; }
      .ActionButton_chooseProductButtonContainer__3dLs3 else {
        border-radius: 14px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .ActionButton_chooseProductButtonContainer__3dLs3 {
      height: 3.05111vw;
      border-radius: 1.77981vw; }
      .ActionButton_chooseProductButtonContainer__3dLs3 else {
        height: 24px; }
      .ActionButton_chooseProductButtonContainer__3dLs3 else {
        border-radius: 14px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .ActionButton_chooseProductButtonContainer__3dLs3 {
      height: 2.76052vw;
      border-radius: 1.61031vw; }
      .ActionButton_chooseProductButtonContainer__3dLs3 else {
        height: 24px; }
      .ActionButton_chooseProductButtonContainer__3dLs3 else {
        border-radius: 14px; } }
  @media (min-width: 1300px) {
    .ActionButton_chooseProductButtonContainer__3dLs3 {
      height: 1.875vw;
      border-radius: 1.45833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ActionButton_chooseProductButtonContainer__3dLs3 {
      height: 2.08333vw;
      border-radius: 1.62037vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .ActionButton_chooseProductButtonContainer__3dLs3 {
      height: 1.875vw;
      border-radius: 1.45833vw; } }

.ActionButton_active__1ZfeY {
  background-color: #cbf7fc;
  color: #2c505f; }

.ActionButton_disabled__3zq5Z {
  background-color: #d1d1d1 !important;
  background-image: none !important;
  cursor: default !important;
  pointer-events: none;
  color: #fff; }

.ActionButton_activeProductButton__1LOWf {
  background-image: linear-gradient(to bottom, #00ade6, #34e4ea); }

.ActionButton_disabledProductButton__2a1j_ {
  background-color: #ffffff !important;
  background-image: none !important;
  cursor: default !important; }

.ActionButton_activeProductButtonText__AOaEN {
  color: #ffffff !important; }

.ActionButton_disabledProductButtonText__YogIU {
  color: #27d6e9 !important; }

.ActionButton_chooseProductButtonText__wf7AD {
  height: 100%;
  font-weight: bold;
  margin: 0 0.98958vw 0 0.98958vw;
  display: flex;
  align-items: center;
  color: #27d6e9;
  font-size: 2.89855vw; }
  @media (min-width: 450px) and (max-width: 500px) {
    .ActionButton_chooseProductButtonText__wf7AD {
      font-size: 2.41546vw; }
      .ActionButton_chooseProductButtonText__wf7AD else {
        font-size: 12px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .ActionButton_chooseProductButtonText__wf7AD {
      font-size: 1.93237vw; }
      .ActionButton_chooseProductButtonText__wf7AD else {
        font-size: 12px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .ActionButton_chooseProductButtonText__wf7AD {
      font-size: 1.70503vw; }
      .ActionButton_chooseProductButtonText__wf7AD else {
        font-size: 12px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .ActionButton_chooseProductButtonText__wf7AD {
      font-size: 1.52555vw; }
      .ActionButton_chooseProductButtonText__wf7AD else {
        font-size: 12px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .ActionButton_chooseProductButtonText__wf7AD {
      font-size: 1.38026vw; }
      .ActionButton_chooseProductButtonText__wf7AD else {
        font-size: 12px; } }
  @media (min-width: 1300px) {
    .ActionButton_chooseProductButtonText__wf7AD {
      font-size: 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ActionButton_chooseProductButtonText__wf7AD {
      font-size: 0.92593vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .ActionButton_chooseProductButtonText__wf7AD {
      font-size: 0.83333vw; } }

.ActionButton_iconCircle__1eCrT {
  background-color: white;
  box-shadow: 0 3px 6px 0 #34e4ea;
  border-radius: 50%;
  margin-right: 0.36458vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 5.72917vw;
  height: 5.72917vw;
  margin-left: 1.20773vw; }
  @media (min-width: 450px) and (max-width: 500px) {
    .ActionButton_iconCircle__1eCrT {
      margin-left: 1.00644vw; }
      .ActionButton_iconCircle__1eCrT else {
        width: 5.72917vw; }
      .ActionButton_iconCircle__1eCrT else {
        height: 5.72917vw; }
      .ActionButton_iconCircle__1eCrT else {
        margin-left: 5px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .ActionButton_iconCircle__1eCrT {
      margin-left: 0.80515vw; }
      .ActionButton_iconCircle__1eCrT else {
        width: 5.72917vw; }
      .ActionButton_iconCircle__1eCrT else {
        height: 5.72917vw; }
      .ActionButton_iconCircle__1eCrT else {
        margin-left: 5px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .ActionButton_iconCircle__1eCrT {
      margin-left: 0.71043vw; }
      .ActionButton_iconCircle__1eCrT else {
        width: 5.72917vw; }
      .ActionButton_iconCircle__1eCrT else {
        height: 5.72917vw; }
      .ActionButton_iconCircle__1eCrT else {
        margin-left: 5px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .ActionButton_iconCircle__1eCrT {
      margin-left: 0.63565vw; }
      .ActionButton_iconCircle__1eCrT else {
        width: 5.72917vw; }
      .ActionButton_iconCircle__1eCrT else {
        height: 5.72917vw; }
      .ActionButton_iconCircle__1eCrT else {
        margin-left: 5px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .ActionButton_iconCircle__1eCrT {
      margin-left: 0.57511vw; }
      .ActionButton_iconCircle__1eCrT else {
        width: 5.72917vw; }
      .ActionButton_iconCircle__1eCrT else {
        height: 5.72917vw; }
      .ActionButton_iconCircle__1eCrT else {
        margin-left: 5px; } }
  @media (min-width: 1300px) {
    .ActionButton_iconCircle__1eCrT {
      width: 2.08333vw;
      height: 2.08333vw;
      margin-left: unset; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ActionButton_iconCircle__1eCrT {
      width: 2.08333vw;
      height: 2.08333vw;
      margin-left: unset; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .ActionButton_iconCircle__1eCrT {
      width: 2.08333vw;
      height: 2.08333vw;
      margin-left: unset; } }

.ActionButton_hoverButtonContainer__2knR-:hover {
  cursor: pointer !important;
  opacity: 0.93; }

.ActionButton_defaultHover__bkkWM {
  cursor: default !important; }

@media screen and (max-width: 1199px) {
  .ActionButton_buttonContainer__28SJf {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px 8px 16px;
    height: 24px;
    background: linear-gradient(180deg, #35E4E9 -8.62%, #00ADE6 109.2%);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    width: auto; } }
  @media screen and (max-width: 1199px) and (min-width: 1300px) {
    .ActionButton_buttonContainer__28SJf {
      width: 5.83333vw; } }
  @media screen and (max-width: 1199px) and (min-width: 900px) and (max-width: 1000px) {
    .ActionButton_buttonContainer__28SJf {
      width: 6.48148vw; } }
  @media screen and (max-width: 1199px) and (min-width: 1000px) and (max-width: 1300px) {
    .ActionButton_buttonContainer__28SJf {
      width: 5.83333vw; } }
  @media screen and (max-width: 1199px) and (min-width: 450px) and (max-width: 500px) {
    .ActionButton_buttonContainer__28SJf else {
      width: auto; } }
  @media screen and (max-width: 1199px) and (min-width: 500px) and (max-width: 600px) {
    .ActionButton_buttonContainer__28SJf else {
      width: auto; } }
  @media screen and (max-width: 1199px) and (min-width: 600px) and (max-width: 700px) {
    .ActionButton_buttonContainer__28SJf else {
      width: auto; } }
  @media screen and (max-width: 1199px) and (min-width: 700px) and (max-width: 800px) {
    .ActionButton_buttonContainer__28SJf else {
      width: auto; } }
  @media screen and (max-width: 1199px) and (min-width: 800px) and (max-width: 899px) {
    .ActionButton_buttonContainer__28SJf else {
      width: auto; } }

@media screen and (max-width: 1199px) {
  .ActionButton_centerAlignedButtonText__LJLZ0 {
    font-size: 3.38164vw; }
  .ActionButton_smallButtonContainer__17C6W {
    height: 5.7971vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 6.76329vw; }
  .ActionButton_smallButtonText__r0sfe {
    height: 100%;
    font-size: 2.89855vw;
    font-weight: bold;
    margin: 0 2.41546vw 0 2.41546vw;
    display: flex;
    align-items: center; } }

@media screen and (min-width: 500px) and (max-width: 600px) {
  .ActionButton_buttonContainer__28SJf {
    height: 5.31401vw;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    justify-content: space-between; }
  .ActionButton_centerAlignedButtonText__LJLZ0 {
    font-size: 2.89855vw; }
  .ActionButton_smallButtonContainer__17C6W {
    height: 4.83092vw; }
  .ActionButton_smallButtonText__r0sfe {
    font-size: 2.41546vw; } }

@media screen and (min-width: 600px) and (max-width: 700px) {
  .ActionButton_buttonContainer__28SJf {
    height: 4.10628vw;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    justify-content: center; }
  .ActionButton_centerAlignedButtonText__LJLZ0 {
    font-size: 2.41546vw; }
  .ActionButton_smallButtonContainer__17C6W {
    height: 4.58937vw;
    border-radius: 7.24638vw; }
  .ActionButton_smallButtonText__r0sfe {
    font-size: 2.17391vw; } }

@media screen and (min-width: 700px) and (max-width: 800px) {
  .ActionButton_buttonContainer__28SJf {
    height: 3.64583vw;
    width: 100px;
    justify-content: center; }
  .ActionButton_centerAlignedButtonText__LJLZ0 {
    font-size: 2.17391vw; }
  .ActionButton_smallButtonContainer__17C6W {
    height: 4.10628vw;
    border-radius: 7.24638vw; }
  .ActionButton_smallButtonText__r0sfe {
    font-size: 1.93237vw; } }

@media screen and (min-width: 800px) and (max-width: 1199px) {
  .ActionButton_buttonContainer__28SJf {
    height: 3.90625vw;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    justify-content: center; }
  .ActionButton_centerAlignedButtonText__LJLZ0 {
    font-size: 1.93237vw; }
  .ActionButton_smallButtonContainer__17C6W {
    height: 3.38164vw;
    border-radius: 7.24638vw; }
  .ActionButton_smallButtonText__r0sfe {
    font-size: 1.44928vw; } }

@media screen and (min-width: 900px) and (max-width: 1199px) {
  .ActionButton_buttonContainer__28SJf {
    height: 1.97917vw;
    padding: 0.72917vw 0.83333vw 0.625vw 0.83333vw; }
  .ActionButton_buttonText__LE-_9 {
    font-size: 1.25vw; }
  .ActionButton_smallButtonText__r0sfe {
    margin: 0 1.5625vw 0 1.5625vw; } }

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .ActionButton_smallButtonContainer__17C6W {
    height: 3.1401vw; }
  .ActionButton_centerAlignedButtonText__LJLZ0 {
    font-size: 1.69082vw; }
  .ActionButton_smallButtonText__r0sfe {
    font-size: 1.3285vw; } }

@media screen and (min-width: 1100px) and (max-width: 1199px) {
  .ActionButton_smallButtonContainer__17C6W {
    height: 2.89855vw; }
  .ActionButton_centerAlignedButtonText__LJLZ0 {
    font-size: 1.44928vw; } }

.ActionButton_mbButtonContainer__1A_KF {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px 8px 16px;
  width: 112px;
  background: linear-gradient(270deg, #35E4E9 -8.62%, #00ADE6 109.2%);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  margin: 0; }

.ActionButton_noMarginTop__2uU7m {
  margin-top: 0px !important; }

.ActionButton_mbButtonText__2svuT {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  margin-left: 1px; }

.ActionButton_mbIconCircle__1Ya1C {
  width: 19.5px;
  height: 19.5px; }

@media screen {
  .SimpleButtonLoader_loader__3ihip {
    border: 0.15625vw solid #fff;
    background-image: linear-gradient(to bottom, #00ade6, #34e4ea);
    border-radius: 50%;
    border-top: 0.15625vw solid #bfbfbf;
    width: 1.04167vw;
    height: 1.04167vw;
    -webkit-animation: SimpleButtonLoader_spin__2WlVY 2s linear infinite;
    /* Safari */
    animation: SimpleButtonLoader_spin__2WlVY 2s linear infinite;
    position: relative;
    left: -0.52083vw; }
  .SimpleButtonLoader_loader__3ihip.SimpleButtonLoader_noGradient__1aJ34 {
    background-image: none; }
  .SimpleButtonLoader_loaderRightPositioned__2o54_ {
    left: 0.41667vw !important; }
  .SimpleButtonLoader_visible__18Z93 {
    display: block; }
  .SimpleButtonLoader_hidden__2x6CR {
    display: none; }
  @-webkit-keyframes SimpleButtonLoader_spin__2WlVY {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(360deg); } }
  @keyframes SimpleButtonLoader_spin__2WlVY {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg); } } }

@media screen and (max-width: 900px) {
  .SimpleButtonLoader_loader__3ihip {
    border: 0.48309vw solid #fff;
    border-top: 0.48309vw solid #bfbfbf;
    width: 2.41546vw;
    height: 2.41546vw; } }

@media screen and (min-width: 500px) and (max-width: 900px) {
  .SimpleButtonLoader_loader__3ihip {
    width: 1.93237vw;
    height: 1.93237vw; } }

@media screen and (min-width: 600px) and (max-width: 900px) {
  .SimpleButtonLoader_loader__3ihip {
    border: 0.36232vw solid #fff;
    border-top: 0.36232vw solid #bfbfbf;
    height: 1.69082vw;
    width: 1.69082vw; } }

@media screen and (min-width: 700px) and (max-width: 900px) {
  .SimpleButtonLoader_loader__3ihip {
    width: 1.44928vw;
    height: 1.44928vw; } }

@media screen and (min-width: 800px) and (max-width: 900px) {
  .SimpleButtonLoader_loader__3ihip {
    border: 0.28986vw solid #fff;
    border-top: 0.28986vw solid #bfbfbf; } }

.SubmitOverlayMenu_container__3nGIb {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 98;
  background-color: rgba(0, 0, 0, 0.7); }

.SubmitOverlayMenu_modalContainer__kwCVt {
  width: 28.64583vw;
  height: 14.84896vw;
  bottom: 38vh;
  left: 35.67708vw;
  position: absolute;
  border-radius: 1.04167vw;
  display: flex;
  flex-direction: column;
  padding-left: 1.11979vw;
  padding-right: 1.11979vw;
  padding-bottom: 1.52083vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(/static/media/submitOverlayBG.1a8924da.svg); }

.SubmitOverlayMenu_modalContainer__kwCVt:focus {
  outline: none; }

.SubmitOverlayMenu_submit__34eiG {
  width: 20.83333vw;
  height: 1.53646vw;
  object-fit: contain;
  font-family: Nunito;
  font-size: 1.35417vw;
  float: left;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #1ac9e8;
  margin-top: 4.27083vw; }

.SubmitOverlayMenu_confirmText__398MI {
  font-family: Nunito;
  font-size: 1.04167vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  margin-top: 0.9375vw; }

.SubmitOverlayMenu_buttonArea__vBW7L {
  margin-top: 2.34375vw;
  display: flex;
  justify-content: space-around;
  align-items: center; }

.SubmitOverlayMenu_cancelBtn__3yEs1 {
  cursor: pointer;
  width: 9.79167vw;
  height: 3.10938vw;
  object-fit: contain;
  border-radius: 1.5625vw;
  border: solid 1px #1ac9e8;
  font-family: Nunito;
  font-size: 1.30208vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1ac9e8;
  display: flex;
  justify-content: center;
  align-items: center; }

.SubmitOverlayMenu_submitBtn__1zWc3 {
  cursor: default;
  width: 9.79167vw;
  height: 3.10938vw;
  border-radius: 1.5625vw;
  background-image: linear-gradient(to bottom, #34e4ea, #00ade6);
  font-family: Nunito;
  font-size: 1.30208vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row; }
  .SubmitOverlayMenu_submitBtn__1zWc3:hover {
    opacity: 0.7; }

.SubmitOverlayMenu_disabled__1VT_5 {
  background-color: #d1d1d1 !important;
  background-image: none !important; }
  .SubmitOverlayMenu_disabled__1VT_5:hover {
    opacity: 1 !important; }

@media screen and (max-width: 1100px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    background-size: contain;
    width: 60.38647vw;
    height: 25vh;
    border-radius: 1.2vh;
    padding: 2%;
    left: 24%;
    bottom: 30%; }
  .SubmitOverlayMenu_submit__34eiG {
    width: 70%;
    object-fit: contain;
    font-family: Nunito;
    font-size: 3vh;
    margin-top: 7vh; }
  .SubmitOverlayMenu_confirmText__398MI {
    font-size: 2.34vh;
    margin-top: 3.6vh; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    width: 100%; }
  .SubmitOverlayMenu_cancelBtn__3yEs1 {
    width: 30%;
    height: 125%;
    border-radius: 3vh;
    font-size: 2.3vh;
    padding-bottom: 0.1vh; }
  .SubmitOverlayMenu_submitBtn__1zWc3 {
    width: 30%;
    height: 125%;
    border-radius: 3vh;
    font-size: 2.3vh;
    padding-bottom: 0.1vh; }
  .SubmitOverlayMenu_disabled__1VT_5 {
    background-color: #d1d1d1 !important;
    background-image: none !important; }
    .SubmitOverlayMenu_disabled__1VT_5:hover {
      opacity: 1 !important; } }

@media screen and (max-width: 350px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    width: 67.63285vw; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    margin-top: 7.24638vw !important; } }

@media screen and (max-width: 500px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    left: 13.04348vw;
    bottom: 96.61836vw; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    position: relative;
    left: -0.96618vw;
    margin-top: 8.45411vw; }
  .SubmitOverlayMenu_submit__34eiG {
    font-size: 4.10628vw;
    margin-top: 10.62802vw; }
  .SubmitOverlayMenu_confirmText__398MI {
    font-size: 3.62319vw;
    margin-top: 5.7971vw; }
  .SubmitOverlayMenu_submitBtn__1zWc3 {
    font-size: 3.38164vw; }
  .SubmitOverlayMenu_cancelBtn__3yEs1 {
    font-size: 3.38164vw; } }

@media screen and (min-width: 460px) and (max-width: 500px) {
  .SubmitOverlayMenu_buttonArea__vBW7L {
    left: -4.83092vw; } }

@media screen and (min-width: 350px) and (max-width: 500px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    width: 72.46377vw;
    bottom: 72.46377vw; } }

@media screen and (min-width: 460px) and (max-width: 480px) {
  .SubmitOverlayMenu_buttonArea__vBW7L {
    margin-top: 7.24638vw; } }

@media screen and (min-width: 480px) and (max-width: 500px) {
  .SubmitOverlayMenu_buttonArea__vBW7L {
    margin-top: 6.03865vw; } }

@media screen and (min-width: 501px) and (max-width: 540px) {
  .SubmitOverlayMenu_submit__34eiG {
    font-size: 3.62319vw; }
  .SubmitOverlayMenu_confirmText__398MI {
    font-size: 2.89855vw; }
  .SubmitOverlayMenu_modalContainer__kwCVt {
    left: 16.90821vw;
    bottom: 60.38647vw; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    margin-top: 6.76329vw; } }

@media screen and (min-width: 541px) and (max-width: 600px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    left: 18.11594vw;
    bottom: 55.55556vw; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    margin-top: 6.03865vw;
    position: relative;
    left: -1.44928vw; }
  .SubmitOverlayMenu_submit__34eiG {
    font-size: 3.38164vw; }
  .SubmitOverlayMenu_confirmText__398MI {
    font-size: 2.89855vw; } }

@media screen and (min-width: 565px) and (max-width: 600px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    left: 19.32367vw; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    position: relative;
    left: -2.41546vw; }
  .SubmitOverlayMenu_submit__34eiG {
    font-size: 3.1401vw; }
  .SubmitOverlayMenu_confirmText__398MI {
    font-size: 2.657vw; } }

@media screen and (min-width: 600px) and (max-width: 900px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    left: 19.32367vw;
    bottom: 43.47826vw; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    position: relative;
    left: -4.83092vw;
    margin-top: 6.03865vw; }
  .SubmitOverlayMenu_submit__34eiG {
    font-size: 2.89855vw; }
  .SubmitOverlayMenu_confirmText__398MI {
    font-size: 2.41546vw; }
  .SubmitOverlayMenu_submitBtn__1zWc3 {
    width: 14.49275vw; }
  .SubmitOverlayMenu_cancelBtn__3yEs1 {
    width: 14.49275vw; } }

@media screen and (min-width: 650px) and (max-width: 700px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    bottom: 38.64734vw;
    left: 22.94686vw; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    position: relative;
    left: -5.7971vw; } }

@media screen and (min-width: 675px) and (max-width: 700px) {
  .SubmitOverlayMenu_buttonArea__vBW7L {
    left: -6.76329vw; }
  .SubmitOverlayMenu_submitBtn__1zWc3 {
    width: 13.28502vw; }
  .SubmitOverlayMenu_cancelBtn__3yEs1 {
    width: 13.28502vw; } }

@media screen and (min-width: 700px) and (max-width: 800px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    left: 25.36232vw; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    margin-top: 4.83092vw;
    left: -8.45411vw; }
  .SubmitOverlayMenu_submit__34eiG {
    font-size: 2.657vw; }
  .SubmitOverlayMenu_confirmText__398MI {
    font-size: 2.17391vw; }
  .SubmitOverlayMenu_submitBtn__1zWc3 {
    width: 12.07729vw; }
  .SubmitOverlayMenu_cancelBtn__3yEs1 {
    width: 12.07729vw; } }

@media screen and (min-width: 750px) and (max-width: 800px) {
  .SubmitOverlayMenu_buttonArea__vBW7L {
    width: 50.72464vw;
    left: -3.62319vw;
    margin-top: 5.31401vw; }
  .SubmitOverlayMenu_submitBtn__1zWc3 {
    width: 13.28502vw; }
  .SubmitOverlayMenu_cancelBtn__3yEs1 {
    width: 13.28502vw; }
  .SubmitOverlayMenu_submit__34eiG {
    font-size: 2.41546vw; }
  .SubmitOverlayMenu_confirmText__398MI {
    margin-top: 2.89855vw;
    font-size: 1.93237vw; } }

@media screen and (min-width: 766px) and (max-width: 769px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    width: 48.30918vw !important;
    bottom: 67.63285vw; }
  .SubmitOverlayMenu_submit__34eiG {
    margin-top: 7.24638vw !important; }
  .SubmitOverlayMenu_submitBtn__1zWc3 {
    width: 15.70048vw !important;
    font-size: 2.17391vw !important; }
  .SubmitOverlayMenu_cancelBtn__3yEs1 {
    width: 15.70048vw !important;
    font-size: 2.17391vw !important; }
  .SubmitOverlayMenu_submit__34eiG {
    font-size: 2.41546vw !important; }
  .SubmitOverlayMenu_confirmText__398MI {
    font-size: 2.17391vw !important; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    margin-top: 6.03865vw !important;
    width: 55.55556vw !important; } }

@media screen and (min-width: 775px) and (max-width: 800px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    bottom: 36.23188vw; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    left: -4.83092vw; } }

@media screen and (min-width: 801px) and (max-width: 1000px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    left: 26.57005vw;
    bottom: 31.40097vw; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    width: 45.89372vw;
    left: -2.89855vw;
    margin-top: 5.31401vw; }
  .SubmitOverlayMenu_submitBtn__1zWc3 {
    width: 12.07729vw; }
  .SubmitOverlayMenu_cancelBtn__3yEs1 {
    width: 12.07729vw; }
  .SubmitOverlayMenu_submit__34eiG {
    font-size: 2.17391vw; }
  .SubmitOverlayMenu_confirmText__398MI {
    margin-top: 2.657vw;
    font-size: 1.69082vw; } }

@media screen and (min-width: 851px) and (max-width: 900px) {
  .SubmitOverlayMenu_buttonArea__vBW7L {
    left: -4.34783vw;
    margin-top: 4.58937vw; } }

@media screen and (min-width: 1020px) and (max-width: 1030px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    width: 40%;
    bottom: 55%;
    left: 28% !important; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    margin-top: 12% !important;
    width: 100% !important; }
  .SubmitOverlayMenu_submit__34eiG {
    margin-top: 4vh !important;
    font-size: 1.7vh; }
  .SubmitOverlayMenu_confirmText__398MI {
    margin-top: 1.5vh !important;
    font-size: 1.2vh; }
  .SubmitOverlayMenu_cancelBtn__3yEs1 {
    height: 90%;
    font-size: 1.6vh;
    padding: 0.3vh 0 0.3vh 0; }
  .SubmitOverlayMenu_submitBtn__1zWc3 {
    height: 90%;
    font-size: 1.6vh;
    padding: 0.3vh 0 0.3vh 0; } }

@media screen and (min-width: 901px) and (max-width: 1100px) {
  .SubmitOverlayMenu_submitBtn__1zWc3 {
    width: 10.86957vw; }
  .SubmitOverlayMenu_cancelBtn__3yEs1 {
    width: 10.86957vw; }
  .SubmitOverlayMenu_submit__34eiG {
    font-size: 1.93237vw; }
  .SubmitOverlayMenu_confirmText__398MI {
    margin-top: 2.41546vw; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    position: relative;
    margin-top: 4.34783vw;
    left: -4.83092vw; } }

@media screen and (min-width: 951px) and (max-width: 1000px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    bottom: 24.15459vw;
    left: 31.40097vw; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    width: 41.0628vw;
    left: -3.1401vw; }
  .SubmitOverlayMenu_submit__34eiG {
    font-size: 1.93237vw; }
  .SubmitOverlayMenu_confirmText__398MI {
    margin-top: 1.93237vw;
    font-size: 1.61836vw; } }

@media screen and (min-width: 1000px) and (max-width: 1100px) {
  .SubmitOverlayMenu_buttonArea__vBW7L {
    width: 38.64734vw;
    left: -3.1401vw; }
  .SubmitOverlayMenu_submit__34eiG {
    font-size: 1.8599vw; }
  .SubmitOverlayMenu_confirmText__398MI {
    margin-top: 1.93237vw;
    font-size: 1.54589vw; } }

@media screen and (min-width: 1023px) and (max-width: 1025px) {
  .SubmitOverlayMenu_buttonArea__vBW7L {
    width: 48.30918vw !important; }
  .SubmitOverlayMenu_submit__34eiG {
    margin-top: 6.03865vw !important;
    font-size: 1.93237vw !important; }
  .SubmitOverlayMenu_confirmText__398MI {
    font-size: 1.69082vw !important;
    margin-top: 2.41546vw !important; }
  .SubmitOverlayMenu_submitBtn__1zWc3 {
    width: 12.07729vw;
    font-size: 1.69082vw !important; }
  .SubmitOverlayMenu_cancelBtn__3yEs1 {
    width: 12.07729vw;
    font-size: 1.69082vw !important; } }

@media screen and (min-width: 1051px) and (max-width: 1100px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    left: 33.81643vw;
    bottom: 24.15459vw; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    width: 37.43961vw;
    left: -3.1401vw; } }

@media screen and (min-width: 1101px) and (max-width: 1200px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    bottom: 24.15459vw;
    left: 33.81643vw; }
  .SubmitOverlayMenu_submit__34eiG {
    font-size: 1.54589vw; }
  .SubmitOverlayMenu_confirmText__398MI {
    margin-top: 1.69082vw;
    font-size: 1.37681vw; }
  .SubmitOverlayMenu_submitBtn__1zWc3 {
    width: 8.45411vw;
    font-size: 1.20773vw; }
  .SubmitOverlayMenu_cancelBtn__3yEs1 {
    width: 8.45411vw;
    font-size: 1.20773vw; } }

.styles_fullScreenWrapper__2TY5D {
  width: 100%;
  height: calc(100vh - 4.58333vw);
  background-color: rgba(0, 0, 0, 0.27);
  position: absolute;
  left: 0px;
  top: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center; }

.styles_modalContainer__BsPkR {
  width: 28.59375vw;
  height: 87.96296vh;
  border-radius: 0.78125vw;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.06);
  background-image: linear-gradient(to bottom, #00ade5, #34e0ea);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; }

.styles_badgeModalBG__f7h_n {
  width: 100%;
  height: 100%;
  position: absolute; }

.styles_congratsBanner__148CD {
  width: 41.93519vh;
  margin-top: 7.44444vh; }

.styles_unlockText__1ijW9 {
  font-family: Nunito;
  font-size: 1.25vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  margin-top: 8.2963vh;
  margin-bottom: 4.93519vh; }

.styles_badgeContainer__12P6v {
  width: 10.21875vw;
  height: 18.16667vh; }

.styles_thumbnailStyle__p_HES {
  width: 13.59259vh;
  height: 13.59259vh; }

.styles_badgeTextHolder__3dZMZ {
  width: 15.83333vh;
  height: 4.2037vh;
  border-radius: 0.83333vh; }

.styles_badgeText__1zqz0 {
  font-size: 1.19792vw;
  line-height: 1.35; }

.styles_badgeDescription__2T0Qv {
  margin-top: 10.30556vh;
  font-family: Nunito;
  font-size: 0.9375vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  flex: 1 1; }

.styles_nextButton__3TOcP {
  margin-top: 1.5625vw; }

.styles_badgeContainer__2jf7_ {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 7.78646vw;
  height: 7.78646vw;
  position: relative; }

.styles_holderLayout__2CzcW {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }

.styles_thumbnail__1x_ac {
  width: 5.5625vw;
  height: 5.5625vw;
  position: absolute;
  border-radius: 50%; }

.styles_badgeTextHolder__3OLQ8 {
  width: 6.47917vw;
  height: 1.72396vw;
  border-radius: 0.46875vw;
  border: solid 0.05208vw #34e4ea;
  background-image: linear-gradient(to bottom, #00ade6, #2875e5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -0.52083vw; }

.styles_badgeText__22LFe {
  font-family: Nunito;
  font-size: 0.88542vw;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: #ffffff; }

.styles_unlockStyle__25gwq {
  cursor: pointer; }

.styles_buttonContainer__2Kf6H {
  position: relative;
  width: 7.61979vw;
  height: 2.85937vw;
  border-radius: 59px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-image: linear-gradient(to bottom, #34e4ea, #00ade6);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 3.125vw; }

.styles_imageBG__2YxGh {
  width: 100%;
  position: absolute; }

.styles_btnText__1pMzb {
  font-family: Nunito;
  font-size: 0.9375vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #ffffff;
  z-index: 1; }

:root {
  --accent-color:$tekie-amethyst;
  --accent-color-110: $tekie-amethyst-dark; }

.Button_Inter__2lfyJ, .Button_Inter-regular__3GLIb, .Button_Inter-SemiBold__1BDr4, .Button_Inter-Bold__2hWZX {
  font-family: 'Inter', 'Nunito', sans-serif; }

.Button_Inter-regular__3GLIb {
  font-weight: 400; }

.Button_Inter-SemiBold__1BDr4 {
  font-weight: 600; }

.Button_Inter-Bold__2hWZX {
  font-weight: 800; }

.Button_lato__3gwJ6, .Button_lato-light__99wh-, .Button_lato-regular__25ESV, .Button_lato-hairline__92jvU {
  font-family: 'Lato', sans-serif; }

.Button_lato-light__99wh- {
  font-weight: 300; }

.Button_lato-regular__25ESV {
  font-weight: 400; }

.Button_lato-hairline__92jvU {
  font-weight: 100; }

.Button_Inter__2lfyJ, .Button_Inter-regular__3GLIb, .Button_Inter-SemiBold__1BDr4, .Button_Inter-Bold__2hWZX {
  font-family: 'Inter', 'Nunito', sans-serif; }

.Button_Inter-regular__3GLIb {
  font-weight: 400; }

.Button_Inter-SemiBold__1BDr4 {
  font-weight: 600; }

.Button_Inter-Bold__2hWZX {
  font-weight: 800; }

.Button_btn__GGD-k {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff; }
  .Button_btn__GGD-k:disabled {
    color: #CCCCCC; }

.Button_primary__3tSJC, .Button_secondary__3Pa4P, .Button_default__2PB-_ {
  background-color: $tekie-amethyst;
  background-color: var(--accent-color);
  color: #ffffff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  font-family: "Inter"; }
  @media (min-width: 1300px) {
    .Button_primary__3tSJC, .Button_secondary__3Pa4P, .Button_default__2PB-_ {
      padding: 0.67708vw 1.04167vw;
      grid-gap: 0.3125vw;
      gap: 0.3125vw;
      border-radius: 0.52083vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .Button_primary__3tSJC, .Button_secondary__3Pa4P, .Button_default__2PB-_ {
      padding: 0.75231vw 1.15741vw;
      grid-gap: 0.34722vw;
      gap: 0.34722vw;
      border-radius: 0.5787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .Button_primary__3tSJC, .Button_secondary__3Pa4P, .Button_default__2PB-_ {
      padding: 0.67708vw 1.04167vw;
      grid-gap: 0.3125vw;
      gap: 0.3125vw;
      border-radius: 0.52083vw; } }
  .Button_primary__3tSJC:disabled, .Button_secondary__3Pa4P:disabled, .Button_default__2PB-_:disabled {
    pointer-events: none;
    cursor: default;
    background-color: rgba(245, 245, 245, 0.5); }
    .Button_primary__3tSJC:disabled:hover, .Button_secondary__3Pa4P:disabled:hover, .Button_default__2PB-_:disabled:hover {
      background-color: #CCCCCC; }
  .Button_primary__3tSJC:hover, .Button_secondary__3Pa4P:hover, .Button_default__2PB-_:hover {
    background-color: $tekie-amethyst-dark;
    background-color: var(--accent-color-110);
    transition: 0.4s ease-in-out; }
  .Button_primary__3tSJC span, .Button_secondary__3Pa4P span, .Button_default__2PB-_ span {
    white-space: nowrap; }
    @media (min-width: 1300px) {
      .Button_primary__3tSJC span, .Button_secondary__3Pa4P span, .Button_default__2PB-_ span {
        font-size: 0.88542vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .Button_primary__3tSJC span, .Button_secondary__3Pa4P span, .Button_default__2PB-_ span {
        font-size: 0.9838vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .Button_primary__3tSJC span, .Button_secondary__3Pa4P span, .Button_default__2PB-_ span {
        font-size: 0.88542vw; } }

.Button_secondary__3Pa4P {
  background-color: #F3EFFA;
  color: $tekie-amethyst;
  color: var(--accent-color);
  font-family: "Inter"; }
  .Button_secondary__3Pa4P:disabled {
    pointer-events: none;
    cursor: default; }
    .Button_secondary__3Pa4P:disabled:hover {
      background-color: #CCCCCC; }
  .Button_secondary__3Pa4P:hover {
    cursor: pointer;
    background-color: #e2ceff;
    transition: 0.2s ease-in-out; }
  .Button_secondary__3Pa4P:active {
    background: #f3ebff;
    box-shadow: 0px 2px 8px rgba(113, 62, 188, 0.24);
    transition: 0.2s ease-in-out; }
  .Button_secondary__3Pa4P span {
    white-space: nowrap; }
    @media (min-width: 1300px) {
      .Button_secondary__3Pa4P span {
        font-size: 0.88542vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .Button_secondary__3Pa4P span {
        font-size: 0.9838vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .Button_secondary__3Pa4P span {
        font-size: 0.88542vw; } }

.Button_tertiary__2vWmp {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  color: $tekie-amethyst;
  color: var(--accent-color);
  border: 0.48309vw solid $tekie-amethyst;
  border: 0.48309vw solid var(--accent-color);
  padding: 2.17391vw 2.89855vw;
  border-radius: 1.93237vw;
  font-family: "Inter";
  font-weight: 500;
  background-color: #FAF6FF; }
  @media (min-width: 1300px) {
    .Button_tertiary__2vWmp {
      border: 0.10417vw solid $tekie-amethyst;
      border: 0.10417vw solid var(--accent-color);
      padding: 0.46875vw 0.625vw;
      border-radius: 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .Button_tertiary__2vWmp {
      border: 0.11574vw solid $tekie-amethyst;
      border: 0.11574vw solid var(--accent-color);
      padding: 0.52083vw 0.69444vw;
      border-radius: 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .Button_tertiary__2vWmp {
      border: 0.10417vw solid $tekie-amethyst;
      border: 0.10417vw solid var(--accent-color);
      padding: 0.46875vw 0.625vw;
      border-radius: 0.41667vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .Button_tertiary__2vWmp {
      border: 0.40258vw solid $tekie-amethyst;
      border: 0.40258vw solid var(--accent-color);
      padding: 1.81159vw 2.41546vw;
      border-radius: 1.61031vw; }
      .Button_tertiary__2vWmp else {
        border-radius: 8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .Button_tertiary__2vWmp {
      border: 0.32206vw solid $tekie-amethyst;
      border: 0.32206vw solid var(--accent-color);
      padding: 1.44928vw 1.93237vw;
      border-radius: 1.28824vw; }
      .Button_tertiary__2vWmp else {
        border-radius: 8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .Button_tertiary__2vWmp {
      border: 0.28417vw solid $tekie-amethyst;
      border: 0.28417vw solid var(--accent-color);
      padding: 1.27877vw 1.70503vw;
      border-radius: 1.13669vw; }
      .Button_tertiary__2vWmp else {
        border-radius: 8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .Button_tertiary__2vWmp {
      border: 0.25426vw solid $tekie-amethyst;
      border: 0.25426vw solid var(--accent-color);
      padding: 1.14416vw 1.52555vw;
      border-radius: 1.01704vw; }
      .Button_tertiary__2vWmp else {
        border-radius: 8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .Button_tertiary__2vWmp {
      border: 0.23004vw solid $tekie-amethyst;
      border: 0.23004vw solid var(--accent-color);
      padding: 1.0352vw 1.38026vw;
      border-radius: 0.92017vw; }
      .Button_tertiary__2vWmp else {
        border-radius: 8px; } }
  .Button_tertiary__2vWmp:disabled {
    pointer-events: none;
    cursor: default; }
  .Button_tertiary__2vWmp:hover {
    cursor: pointer;
    background-color: #F3EBFF;
    transition: 0.4s ease-in-out; }

.Button_ghost__L1S_1 {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  color: #282828;
  font-family: "Inter";
  background-color: transparent;
  border: none;
  font-weight: 500;
  text-decoration: none; }
  .Button_ghost__L1S_1:disabled {
    pointer-events: none;
    cursor: default; }
  .Button_ghost__L1S_1:hover {
    cursor: pointer; }
  .Button_ghost__L1S_1:disabled {
    pointer-events: none;
    color: #9E9E9E; }

.Button_widthFull__2Lx9l {
  width: 100%; }

.Button_rounded__35bff {
  border-radius: 100%; }

.Button_posAbsolute__tqECD {
  position: absolute;
  bottom: 24px;
  right: 24px;
  z-index: 999999; }

.Button_addIcon__3ZS8b svg {
  height: 20px;
  width: 20px; }

.Button_hoverStateAmethystDark__1qmPe:hover {
  background: $tekie-amethyst-dark;
  background: var(--accent-color-110);
  transition: 0.4s ease-in-out; }

.Button_embedFooterSecondaryText__2ALR1, .Button_embedFooterPrimaryText__1gsIX {
  font-weight: 500;
  display: flex;
  align-items: center;
  color: #8C61CB; }
  @media (min-width: 1000px) {
    .Button_embedFooterSecondaryText__2ALR1, .Button_embedFooterPrimaryText__1gsIX {
      font-size: 0.9375vw !important;
      line-height: 1.40625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .Button_embedFooterSecondaryText__2ALR1, .Button_embedFooterPrimaryText__1gsIX {
      font-size: 1.04167vw !important;
      line-height: 1.5625vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .Button_embedFooterSecondaryText__2ALR1, .Button_embedFooterPrimaryText__1gsIX {
      font-size: 0.9375vw !important;
      line-height: 1.40625vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .Button_embedFooterSecondaryText__2ALR1, .Button_embedFooterPrimaryText__1gsIX {
      font-size: 0.75vw !important;
      line-height: 1.125vw; } }

.Button_embedFooterPrimaryText__1gsIX {
  color: white; }

.Button_default__2PB-_ {
  background: rgba(220, 220, 220, 0.4);
  border: 1px solid rgba(186, 186, 186, 0.3);
  color: #8C61CB; }
  .Button_default__2PB-_:hover {
    background-color: unset; }

.hp-container {
  box-sizing: border-box;
  width: 90%;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  background: #FAFAFA;
  border: 1px solid #A8A7A7;
  color: #504F4F;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 24px; }
  @media (min-width: 1300px) {
    .hp-container {
      grid-gap: 0.57292vw;
      gap: 0.57292vw;
      border-radius: 1.25vw;
      padding-left: 1.25vw;
      padding-right: 1.25vw;
      padding-top: 0.83333vw;
      padding-bottom: 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .hp-container {
      grid-gap: 0.63657vw;
      gap: 0.63657vw;
      border-radius: 1.38889vw;
      padding-left: 1.38889vw;
      padding-right: 1.38889vw;
      padding-top: 0.92593vw;
      padding-bottom: 0.92593vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .hp-container {
      grid-gap: 0.57292vw;
      gap: 0.57292vw;
      border-radius: 1.25vw;
      padding-left: 1.25vw;
      padding-right: 1.25vw;
      padding-top: 0.83333vw;
      padding-bottom: 0.83333vw; } }
  .hp-container.fromSubmit {
    box-shadow: none;
    border: none;
    padding: 0; }
  .hp-container .hp-container--title {
    font-weight: 700; }
    @media (min-width: 1300px) {
      .hp-container .hp-container--title {
        font-size: 0.83333vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .hp-container .hp-container--title {
        font-size: 0.92593vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .hp-container .hp-container--title {
        font-size: 0.83333vw; } }
  .hp-container .hp-container--progress {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center; }
    @media (min-width: 1300px) {
      .hp-container .hp-container--progress {
        grid-gap: 0.41667vw;
        gap: 0.41667vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .hp-container .hp-container--progress {
        grid-gap: 0.46296vw;
        gap: 0.46296vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .hp-container .hp-container--progress {
        grid-gap: 0.41667vw;
        gap: 0.41667vw; } }
    .hp-container .hp-container--progress.fromSubmit {
      grid-template-columns: 1fr;
      justify-content: center; }
  .hp-container .hp-container--progress--bar {
    position: relative;
    height: 100%;
    background: #E6F7FD;
    overflow: hidden;
    border-radius: 24px; }
    @media (min-width: 1300px) {
      .hp-container .hp-container--progress--bar {
        height: 0.72917vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .hp-container .hp-container--progress--bar {
        height: 0.81019vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .hp-container .hp-container--progress--bar {
        height: 0.72917vw; } }
  .hp-container .hp-container--progress--bar--fill {
    position: absolute;
    height: 100%;
    background: #00ADE6;
    border-radius: 24px;
    inset: 0; }
  .hp-container .hp-container--progress--text {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-inline: auto;
    font-weight: 700;
    color: #858585; }
    @media (min-width: 1300px) {
      .hp-container .hp-container--progress--text {
        font-size: 1.04167vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .hp-container .hp-container--progress--text {
        font-size: 1.15741vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .hp-container .hp-container--progress--text {
        font-size: 1.04167vw; } }
  @media (min-width: 1300px) {
    .hp-container .hp-container--button {
      padding: 0.83333vw 1.04167vw 0.83333vw 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .hp-container .hp-container--button {
      padding: 0.92593vw 1.15741vw 0.92593vw 1.38889vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .hp-container .hp-container--button {
      padding: 0.83333vw 1.04167vw 0.83333vw 1.25vw; } }
  .hp-container.fromSubmit {
    grid-template-columns: 1fr;
    justify-content: center; }
    .hp-container.fromSubmit .hp-container--progress {
      justify-self: center; }

.submit-btn-skeleton {
  overflow: hidden; }
  @media (min-width: 1300px) {
    .submit-btn-skeleton {
      width: 12.76042vw;
      border-radius: 1.5625vw;
      height: 2.44792vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .submit-btn-skeleton {
      width: 14.17824vw;
      border-radius: 1.73611vw;
      height: 2.71991vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .submit-btn-skeleton {
      width: 12.76042vw;
      border-radius: 1.5625vw;
      height: 2.44792vw; } }

.styles_cardLayout__1ypTX {
  height: 100%;
  background-color: #f9f9f9;
  border-radius: 8px; }
  .styles_cardLayout__1ypTX svg {
    background: none; }

.styles_cardLayoutMobile__1xdmI {
  margin-bottom: 0.9375vw;
  width: 100%;
  height: 90vh; }

.styles_card__1aUAL {
  border-radius: 4px; }

.styles_footer__3YjfD {
  width: 83.33333vw;
  height: 3.125vw;
  display: flex; }

.SubmitOverlayMenu_container__f_5Ky {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0.7); }

.SubmitOverlayMenu_modalContainer__fFHYP {
  position: relative;
  display: flex;
  background: #FFFFFF;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 9999999;
  box-sizing: border-box;
  max-width: 70%;
  max-height: auto; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_modalContainer__fFHYP {
      border-radius: 1.04167vw;
      max-height: 90%; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_modalContainer__fFHYP {
      border-radius: 1.15741vw;
      max-height: 90%; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_modalContainer__fFHYP {
      border-radius: 1.04167vw;
      max-height: 90%; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_modalContainer__fFHYP else {
      max-height: auto; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_modalContainer__fFHYP else {
      max-height: auto; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_modalContainer__fFHYP else {
      max-height: auto; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_modalContainer__fFHYP else {
      max-height: auto; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_modalContainer__fFHYP else {
      max-height: auto; } }

.SubmitOverlayMenu_containerPadding__1ONCi {
  padding-left: 4.83092vw;
  padding-right: 4.83092vw; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_containerPadding__1ONCi {
      padding-left: 1.11979vw;
      padding-right: 1.11979vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_containerPadding__1ONCi {
      padding-left: 1.11979vw;
      padding-right: 1.11979vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_containerPadding__1ONCi {
      padding-left: 1.11979vw;
      padding-right: 1.11979vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_containerPadding__1ONCi {
      padding-left: 4.02576vw;
      padding-right: 4.02576vw; }
      .SubmitOverlayMenu_containerPadding__1ONCi else {
        padding-left: 20px; }
      .SubmitOverlayMenu_containerPadding__1ONCi else {
        padding-right: 20px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_containerPadding__1ONCi {
      padding-left: 3.22061vw;
      padding-right: 3.22061vw; }
      .SubmitOverlayMenu_containerPadding__1ONCi else {
        padding-left: 20px; }
      .SubmitOverlayMenu_containerPadding__1ONCi else {
        padding-right: 20px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_containerPadding__1ONCi {
      padding-left: 2.84172vw;
      padding-right: 2.84172vw; }
      .SubmitOverlayMenu_containerPadding__1ONCi else {
        padding-left: 20px; }
      .SubmitOverlayMenu_containerPadding__1ONCi else {
        padding-right: 20px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_containerPadding__1ONCi {
      padding-left: 2.54259vw;
      padding-right: 2.54259vw; }
      .SubmitOverlayMenu_containerPadding__1ONCi else {
        padding-left: 20px; }
      .SubmitOverlayMenu_containerPadding__1ONCi else {
        padding-right: 20px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_containerPadding__1ONCi {
      padding-left: 2.30044vw;
      padding-right: 2.30044vw; }
      .SubmitOverlayMenu_containerPadding__1ONCi else {
        padding-left: 20px; }
      .SubmitOverlayMenu_containerPadding__1ONCi else {
        padding-right: 20px; } }

.SubmitOverlayMenu_modalContainerWidth__3j_tH {
  min-width: 90%;
  width: 90%; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_modalContainerWidth__3j_tH {
      min-width: 52.08333vw;
      width: auto; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_modalContainerWidth__3j_tH {
      min-width: 52.08333vw;
      width: auto; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_modalContainerWidth__3j_tH {
      min-width: 52.08333vw;
      width: auto; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_modalContainerWidth__3j_tH else {
      min-width: 90%; }
    .SubmitOverlayMenu_modalContainerWidth__3j_tH else {
      width: 90%; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_modalContainerWidth__3j_tH else {
      min-width: 90%; }
    .SubmitOverlayMenu_modalContainerWidth__3j_tH else {
      width: 90%; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_modalContainerWidth__3j_tH else {
      min-width: 90%; }
    .SubmitOverlayMenu_modalContainerWidth__3j_tH else {
      width: 90%; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_modalContainerWidth__3j_tH else {
      min-width: 90%; }
    .SubmitOverlayMenu_modalContainerWidth__3j_tH else {
      width: 90%; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_modalContainerWidth__3j_tH else {
      min-width: 90%; }
    .SubmitOverlayMenu_modalContainerWidth__3j_tH else {
      width: 90%; } }

.SubmitOverlayMenu_modalContainerBgImage__2adUd {
  background-image: url(/static/media/modalStarsBg.ea7d12a5.png); }

.SubmitOverlayMenu_overlayCloseIcon__3fzTX {
  position: relative;
  width: 2.89855vw;
  height: 2.89855vw;
  top: -1.93237vw; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_overlayCloseIcon__3fzTX {
      width: 1.04167vw;
      height: 1.04167vw;
      top: -0.05208vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_overlayCloseIcon__3fzTX {
      width: 1.15741vw;
      height: 1.15741vw;
      top: -0.05787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_overlayCloseIcon__3fzTX {
      width: 1.04167vw;
      height: 1.04167vw;
      top: -0.05208vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_overlayCloseIcon__3fzTX {
      width: 2.41546vw;
      height: 2.41546vw;
      top: -1.61031vw; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        width: 12px; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        height: 12px; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        top: -8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_overlayCloseIcon__3fzTX {
      width: 1.93237vw;
      height: 1.93237vw;
      top: -1.28824vw; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        width: 12px; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        height: 12px; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        top: -8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_overlayCloseIcon__3fzTX {
      width: 1.70503vw;
      height: 1.70503vw;
      top: -1.13669vw; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        width: 12px; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        height: 12px; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        top: -8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_overlayCloseIcon__3fzTX {
      width: 1.52555vw;
      height: 1.52555vw;
      top: -1.01704vw; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        width: 12px; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        height: 12px; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        top: -8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_overlayCloseIcon__3fzTX {
      width: 1.38026vw;
      height: 1.38026vw;
      top: -0.92017vw; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        width: 12px; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        height: 12px; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        top: -8px; } }
  .SubmitOverlayMenu_overlayCloseIcon__3fzTX path {
    fill: #FFF;
    opacity: 1; }

.SubmitOverlayMenu_overlayClose__3x6Fj {
  position: absolute;
  background: #00ADE6;
  -webkit-filter: drop-shadow(-2px 2px 4px #026282);
          filter: drop-shadow(-2px 2px 4px #026282);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);
  width: 9.66184vw;
  height: 9.66184vw;
  top: -3.01932vw;
  right: -1.81159vw;
  transition: 0.3s all ease-in-out; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_overlayClose__3x6Fj {
      width: 2.60417vw;
      height: 2.60417vw;
      top: -0.91146vw;
      right: -0.91146vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_overlayClose__3x6Fj {
      width: 2.89352vw;
      height: 2.89352vw;
      top: -1.01273vw;
      right: -1.01273vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_overlayClose__3x6Fj {
      width: 2.60417vw;
      height: 2.60417vw;
      top: -0.91146vw;
      right: -0.91146vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_overlayClose__3x6Fj {
      width: 8.05153vw;
      height: 8.05153vw;
      top: -2.5161vw;
      right: -1.50966vw; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        width: 40px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        height: 40px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        top: -12.5px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        right: -7.5px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_overlayClose__3x6Fj {
      width: 6.44122vw;
      height: 6.44122vw;
      top: -2.01288vw;
      right: -1.20773vw; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        width: 40px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        height: 40px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        top: -12.5px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        right: -7.5px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_overlayClose__3x6Fj {
      width: 5.68343vw;
      height: 5.68343vw;
      top: -1.77607vw;
      right: -1.06564vw; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        width: 40px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        height: 40px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        top: -12.5px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        right: -7.5px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_overlayClose__3x6Fj {
      width: 5.08518vw;
      height: 5.08518vw;
      top: -1.58912vw;
      right: -0.95347vw; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        width: 40px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        height: 40px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        top: -12.5px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        right: -7.5px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_overlayClose__3x6Fj {
      width: 4.60087vw;
      height: 4.60087vw;
      top: -1.43777vw;
      right: -0.86266vw; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        width: 40px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        height: 40px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        top: -12.5px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        right: -7.5px; } }
  .SubmitOverlayMenu_overlayClose__3x6Fj:hover {
    box-shadow: 0px 6px 6px rgba(51, 51, 51, 0.2), 0px 5px 20px rgba(51, 51, 51, 0.08); }

.SubmitOverlayMenu_modalTitle__3sEAE {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: rgba(0, 173, 230, 0.1);
  border-radius: 0px 0px 20px 20px;
  font-family: Nunito;
  align-self: center;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #00ADE6;
  font-size: 4.34783vw;
  padding: 1.44928vw 14.00966vw; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_modalTitle__3sEAE {
      font-size: 0.9375vw;
      padding: 0.41667vw 3.54167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_modalTitle__3sEAE {
      font-size: 1.04167vw;
      padding: 0.46296vw 3.93519vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_modalTitle__3sEAE {
      font-size: 0.9375vw;
      padding: 0.41667vw 3.54167vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_modalTitle__3sEAE {
      font-size: 3.62319vw;
      padding: 1.20773vw 11.67472vw; }
      .SubmitOverlayMenu_modalTitle__3sEAE else {
        font-size: 18px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_modalTitle__3sEAE {
      font-size: 2.89855vw;
      padding: 0.96618vw 9.33977vw; }
      .SubmitOverlayMenu_modalTitle__3sEAE else {
        font-size: 18px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_modalTitle__3sEAE {
      font-size: 2.55754vw;
      padding: 0.85251vw 8.24098vw; }
      .SubmitOverlayMenu_modalTitle__3sEAE else {
        font-size: 18px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_modalTitle__3sEAE {
      font-size: 2.28833vw;
      padding: 0.76278vw 7.37351vw; }
      .SubmitOverlayMenu_modalTitle__3sEAE else {
        font-size: 18px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_modalTitle__3sEAE {
      font-size: 2.07039vw;
      padding: 0.69013vw 6.67127vw; }
      .SubmitOverlayMenu_modalTitle__3sEAE else {
        font-size: 18px; } }

.SubmitOverlayMenu_modalIconContainer__eAOIY {
  background: linear-gradient(44.71deg, #7CFFED -8.43%, #01AA93 114.08%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 100%;
  margin-top: 4.83092vw; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_modalIconContainer__eAOIY {
      margin-top: 1.45833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_modalIconContainer__eAOIY {
      margin-top: 1.62037vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_modalIconContainer__eAOIY {
      margin-top: 1.45833vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_modalIconContainer__eAOIY {
      margin-top: 4.02576vw; }
      .SubmitOverlayMenu_modalIconContainer__eAOIY else {
        margin-top: 20px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_modalIconContainer__eAOIY {
      margin-top: 3.22061vw; }
      .SubmitOverlayMenu_modalIconContainer__eAOIY else {
        margin-top: 20px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_modalIconContainer__eAOIY {
      margin-top: 2.84172vw; }
      .SubmitOverlayMenu_modalIconContainer__eAOIY else {
        margin-top: 20px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_modalIconContainer__eAOIY {
      margin-top: 2.54259vw; }
      .SubmitOverlayMenu_modalIconContainer__eAOIY else {
        margin-top: 20px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_modalIconContainer__eAOIY {
      margin-top: 2.30044vw; }
      .SubmitOverlayMenu_modalIconContainer__eAOIY else {
        margin-top: 20px; } }
  .SubmitOverlayMenu_modalIconContainer__eAOIY > .SubmitOverlayMenu_saveIcon__27tFb {
    width: 32px;
    height: 32px;
    background-image: url(/static/media/save-outline.6976b85d.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain; }

.SubmitOverlayMenu_modalContainer__fFHYP:focus {
  outline: none; }

.SubmitOverlayMenu_submit__3rU81 {
  width: 20.83333vw;
  height: 1.53646vw;
  object-fit: contain;
  font-family: Nunito;
  font-size: 1.35417vw;
  float: left;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #1ac9e8;
  margin-top: 4.27083vw; }

.SubmitOverlayMenu_confirmText__2p0HW {
  font-family: Nunito;
  font-weight: bold;
  letter-spacing: -0.02em;
  color: #504F4F;
  text-align: center;
  font-size: 4.83092vw;
  margin-top: 4.83092vw; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_confirmText__2p0HW {
      font-size: 1.5625vw;
      margin-top: 1.30208vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_confirmText__2p0HW {
      font-size: 1.73611vw;
      margin-top: 1.30208vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_confirmText__2p0HW {
      font-size: 1.5625vw;
      margin-top: 1.30208vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_confirmText__2p0HW {
      font-size: 4.02576vw;
      margin-top: 4.02576vw; }
      .SubmitOverlayMenu_confirmText__2p0HW else {
        font-size: 20px; }
      .SubmitOverlayMenu_confirmText__2p0HW else {
        margin-top: 20px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_confirmText__2p0HW {
      font-size: 3.22061vw;
      margin-top: 3.22061vw; }
      .SubmitOverlayMenu_confirmText__2p0HW else {
        font-size: 20px; }
      .SubmitOverlayMenu_confirmText__2p0HW else {
        margin-top: 20px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_confirmText__2p0HW {
      font-size: 2.84172vw;
      margin-top: 2.84172vw; }
      .SubmitOverlayMenu_confirmText__2p0HW else {
        font-size: 20px; }
      .SubmitOverlayMenu_confirmText__2p0HW else {
        margin-top: 20px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_confirmText__2p0HW {
      font-size: 2.54259vw;
      margin-top: 2.54259vw; }
      .SubmitOverlayMenu_confirmText__2p0HW else {
        font-size: 20px; }
      .SubmitOverlayMenu_confirmText__2p0HW else {
        margin-top: 20px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_confirmText__2p0HW {
      font-size: 2.30044vw;
      margin-top: 2.30044vw; }
      .SubmitOverlayMenu_confirmText__2p0HW else {
        font-size: 20px; }
      .SubmitOverlayMenu_confirmText__2p0HW else {
        margin-top: 20px; } }
  .SubmitOverlayMenu_confirmText__2p0HW > span {
    display: block;
    text-align: center;
    font-weight: normal;
    color: #504F4F;
    opacity: 0.7;
    margin-top: 12px;
    font-size: 4.34783vw; }
    @media (min-width: 1300px) {
      .SubmitOverlayMenu_confirmText__2p0HW > span {
        font-size: 1.14583vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .SubmitOverlayMenu_confirmText__2p0HW > span {
        font-size: 1.27315vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .SubmitOverlayMenu_confirmText__2p0HW > span {
        font-size: 1.14583vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .SubmitOverlayMenu_confirmText__2p0HW > span {
        font-size: 3.62319vw; }
        .SubmitOverlayMenu_confirmText__2p0HW > span else {
          font-size: 18px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .SubmitOverlayMenu_confirmText__2p0HW > span {
        font-size: 2.89855vw; }
        .SubmitOverlayMenu_confirmText__2p0HW > span else {
          font-size: 18px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .SubmitOverlayMenu_confirmText__2p0HW > span {
        font-size: 2.55754vw; }
        .SubmitOverlayMenu_confirmText__2p0HW > span else {
          font-size: 18px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .SubmitOverlayMenu_confirmText__2p0HW > span {
        font-size: 2.28833vw; }
        .SubmitOverlayMenu_confirmText__2p0HW > span else {
          font-size: 18px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .SubmitOverlayMenu_confirmText__2p0HW > span {
        font-size: 2.07039vw; }
        .SubmitOverlayMenu_confirmText__2p0HW > span else {
          font-size: 18px; } }

.SubmitOverlayMenu_buttonArea__1aFHK {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 5.31401vw; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_buttonArea__1aFHK {
      margin-top: 2.34375vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_buttonArea__1aFHK {
      margin-top: 2.34375vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_buttonArea__1aFHK {
      margin-top: 2.34375vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_buttonArea__1aFHK {
      margin-top: 4.42834vw; }
      .SubmitOverlayMenu_buttonArea__1aFHK else {
        margin-top: 22px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_buttonArea__1aFHK {
      margin-top: 3.54267vw; }
      .SubmitOverlayMenu_buttonArea__1aFHK else {
        margin-top: 22px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_buttonArea__1aFHK {
      margin-top: 3.12589vw; }
      .SubmitOverlayMenu_buttonArea__1aFHK else {
        margin-top: 22px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_buttonArea__1aFHK {
      margin-top: 2.79685vw; }
      .SubmitOverlayMenu_buttonArea__1aFHK else {
        margin-top: 22px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_buttonArea__1aFHK {
      margin-top: 2.53048vw; }
      .SubmitOverlayMenu_buttonArea__1aFHK else {
        margin-top: 22px; } }

.SubmitOverlayMenu_cancelBtn__235Nc {
  cursor: pointer;
  object-fit: contain;
  border: solid 2px #1ac9e8;
  font-family: Nunito;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1ac9e8;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  align-items: center;
  font-size: 3.86473vw;
  padding: 1.93237vw 3.86473vw;
  border-radius: 9.66184vw; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_cancelBtn__235Nc {
      font-size: 0.9375vw;
      padding: 0.52083vw 0.9375vw;
      border-radius: 1.5625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_cancelBtn__235Nc {
      font-size: 1.04167vw;
      padding: 0.5787vw 1.04167vw;
      border-radius: 1.5625vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_cancelBtn__235Nc {
      font-size: 0.9375vw;
      padding: 0.52083vw 0.9375vw;
      border-radius: 1.5625vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_cancelBtn__235Nc {
      font-size: 3.22061vw;
      padding: 1.61031vw 3.22061vw;
      border-radius: 8.05153vw; }
      .SubmitOverlayMenu_cancelBtn__235Nc else {
        font-size: 16px; }
      .SubmitOverlayMenu_cancelBtn__235Nc else {
        border-radius: 40px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_cancelBtn__235Nc {
      font-size: 2.57649vw;
      padding: 1.28824vw 2.57649vw;
      border-radius: 6.44122vw; }
      .SubmitOverlayMenu_cancelBtn__235Nc else {
        font-size: 16px; }
      .SubmitOverlayMenu_cancelBtn__235Nc else {
        border-radius: 40px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_cancelBtn__235Nc {
      font-size: 2.27337vw;
      padding: 1.13669vw 2.27337vw;
      border-radius: 5.68343vw; }
      .SubmitOverlayMenu_cancelBtn__235Nc else {
        font-size: 16px; }
      .SubmitOverlayMenu_cancelBtn__235Nc else {
        border-radius: 40px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_cancelBtn__235Nc {
      font-size: 2.03407vw;
      padding: 1.01704vw 2.03407vw;
      border-radius: 5.08518vw; }
      .SubmitOverlayMenu_cancelBtn__235Nc else {
        font-size: 16px; }
      .SubmitOverlayMenu_cancelBtn__235Nc else {
        border-radius: 40px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_cancelBtn__235Nc {
      font-size: 1.84035vw;
      padding: 0.92017vw 1.84035vw;
      border-radius: 4.60087vw; }
      .SubmitOverlayMenu_cancelBtn__235Nc else {
        font-size: 16px; }
      .SubmitOverlayMenu_cancelBtn__235Nc else {
        border-radius: 40px; } }

.SubmitOverlayMenu_submitBtn__2o_xm {
  cursor: pointer;
  background: conic-gradient(from -3.29deg at 100% -13%, #35E4E9 0deg, #00ADE6 360deg);
  border-radius: 40px;
  font-family: Nunito;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-transform: uppercase;
  font-size: 3.86473vw;
  padding: 2.41546vw 3.86473vw;
  border-radius: 9.66184vw;
  margin-left: 4.83092vw; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_submitBtn__2o_xm {
      font-size: 0.9375vw;
      padding: 0.625vw 0.9375vw;
      margin-left: 0.625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_submitBtn__2o_xm {
      font-size: 1.04167vw;
      padding: 0.69444vw 1.04167vw;
      margin-left: 0.69444vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_submitBtn__2o_xm {
      font-size: 0.9375vw;
      padding: 0.625vw 0.9375vw;
      margin-left: 0.625vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_submitBtn__2o_xm {
      font-size: 3.22061vw;
      padding: 2.01288vw 3.22061vw;
      border-radius: 8.05153vw;
      margin-left: 4.02576vw; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        font-size: 16px; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        border-radius: 40px; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        margin-left: 20px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_submitBtn__2o_xm {
      font-size: 2.57649vw;
      padding: 1.61031vw 2.57649vw;
      border-radius: 6.44122vw;
      margin-left: 3.22061vw; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        font-size: 16px; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        border-radius: 40px; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        margin-left: 20px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_submitBtn__2o_xm {
      font-size: 2.27337vw;
      padding: 1.42086vw 2.27337vw;
      border-radius: 5.68343vw;
      margin-left: 2.84172vw; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        font-size: 16px; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        border-radius: 40px; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        margin-left: 20px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_submitBtn__2o_xm {
      font-size: 2.03407vw;
      padding: 1.27129vw 2.03407vw;
      border-radius: 5.08518vw;
      margin-left: 2.54259vw; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        font-size: 16px; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        border-radius: 40px; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        margin-left: 20px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_submitBtn__2o_xm {
      font-size: 1.84035vw;
      padding: 1.15022vw 1.84035vw;
      border-radius: 4.60087vw;
      margin-left: 2.30044vw; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        font-size: 16px; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        border-radius: 40px; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        margin-left: 20px; } }
  .SubmitOverlayMenu_submitBtn__2o_xm:hover {
    opacity: 0.7; }

.SubmitOverlayMenu_disabled__2nNK5 {
  background-color: #d1d1d1 !important;
  background-image: none !important; }
  .SubmitOverlayMenu_disabled__2nNK5:hover {
    opacity: 1 !important; }

.SubmitOverlayMenu_confirmSubmissionLabel__r31hX {
  background: rgba(0, 173, 230, 0.1);
  border: 2px solid #DCDCDC;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.96354vw 0px;
  font-family: Nunito;
  font-weight: bold;
  letter-spacing: -0.02em;
  color: #00ADE6;
  font-size: 4.34783vw;
  border-radius: 5.7971vw 5.7971vw 0vw 0vw;
  margin: 0vw 0vw 3.86473vw; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_confirmSubmissionLabel__r31hX {
      font-size: 1.35417vw;
      border-radius: 0;
      margin: 1.45833vw 0vw 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_confirmSubmissionLabel__r31hX {
      font-size: 1.50463vw;
      border-radius: 0;
      margin: 1.45833vw 0vw 1.04167vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_confirmSubmissionLabel__r31hX {
      font-size: 1.35417vw;
      border-radius: 0;
      margin: 1.45833vw 0vw 1.04167vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_confirmSubmissionLabel__r31hX {
      font-size: 3.62319vw;
      border-radius: 4.83092vw 4.83092vw 0vw 0vw;
      margin: 0vw 0vw 3.22061vw; }
      .SubmitOverlayMenu_confirmSubmissionLabel__r31hX else {
        font-size: 18px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_confirmSubmissionLabel__r31hX {
      font-size: 2.89855vw;
      border-radius: 3.86473vw 3.86473vw 0vw 0vw;
      margin: 0vw 0vw 2.57649vw; }
      .SubmitOverlayMenu_confirmSubmissionLabel__r31hX else {
        font-size: 18px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_confirmSubmissionLabel__r31hX {
      font-size: 2.55754vw;
      border-radius: 3.41006vw 3.41006vw 0vw 0vw;
      margin: 0vw 0vw 2.27337vw; }
      .SubmitOverlayMenu_confirmSubmissionLabel__r31hX else {
        font-size: 18px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_confirmSubmissionLabel__r31hX {
      font-size: 2.28833vw;
      border-radius: 3.05111vw 3.05111vw 0vw 0vw;
      margin: 0vw 0vw 2.03407vw; }
      .SubmitOverlayMenu_confirmSubmissionLabel__r31hX else {
        font-size: 18px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_confirmSubmissionLabel__r31hX {
      font-size: 2.07039vw;
      border-radius: 2.76052vw 2.76052vw 0vw 0vw;
      margin: 0vw 0vw 1.84035vw; }
      .SubmitOverlayMenu_confirmSubmissionLabel__r31hX else {
        font-size: 18px; } }

.SubmitOverlayMenu_summaryContainer__1w00A {
  box-sizing: border-box;
  border-radius: 15px;
  width: 95%;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 36.45833vw;
  padding: 2.89855vw;
  margin: 0vw 0.96618vw; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_summaryContainer__1w00A {
      padding: 1.14583vw 2.60417vw 1.14583vw 1.5625vw;
      margin: 0vw 0.9375vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_summaryContainer__1w00A {
      padding: 1.27315vw 2.89352vw 1.27315vw 1.73611vw;
      margin: 0vw 1.04167vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_summaryContainer__1w00A {
      padding: 1.14583vw 2.60417vw 1.14583vw 1.5625vw;
      margin: 0vw 0.9375vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_summaryContainer__1w00A {
      padding: 2.41546vw;
      margin: 0vw 0.80515vw; }
      .SubmitOverlayMenu_summaryContainer__1w00A else {
        padding: 12px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_summaryContainer__1w00A {
      padding: 1.93237vw;
      margin: 0vw 0.64412vw; }
      .SubmitOverlayMenu_summaryContainer__1w00A else {
        padding: 12px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_summaryContainer__1w00A {
      padding: 1.70503vw;
      margin: 0vw 0.56834vw; }
      .SubmitOverlayMenu_summaryContainer__1w00A else {
        padding: 12px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_summaryContainer__1w00A {
      padding: 1.52555vw;
      margin: 0vw 0.50852vw; }
      .SubmitOverlayMenu_summaryContainer__1w00A else {
        padding: 12px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_summaryContainer__1w00A {
      padding: 1.38026vw;
      margin: 0vw 0.46009vw; }
      .SubmitOverlayMenu_summaryContainer__1w00A else {
        padding: 12px; } }
  .SubmitOverlayMenu_summaryContainer__1w00A::-webkit-scrollbar {
    border-radius: 10px; }

.SubmitOverlayMenu_summaryTitle__Kztd9 {
  font-family: Nunito;
  font-weight: bold;
  letter-spacing: -0.02em;
  color: #00ADE6;
  display: block;
  margin-bottom: 8px;
  font-size: 4.34783vw; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_summaryTitle__Kztd9 {
      font-size: 1.35417vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_summaryTitle__Kztd9 {
      font-size: 1.50463vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_summaryTitle__Kztd9 {
      font-size: 1.35417vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_summaryTitle__Kztd9 {
      font-size: 3.62319vw; }
      .SubmitOverlayMenu_summaryTitle__Kztd9 else {
        font-size: 18px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_summaryTitle__Kztd9 {
      font-size: 2.89855vw; }
      .SubmitOverlayMenu_summaryTitle__Kztd9 else {
        font-size: 18px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_summaryTitle__Kztd9 {
      font-size: 2.55754vw; }
      .SubmitOverlayMenu_summaryTitle__Kztd9 else {
        font-size: 18px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_summaryTitle__Kztd9 {
      font-size: 2.28833vw; }
      .SubmitOverlayMenu_summaryTitle__Kztd9 else {
        font-size: 18px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_summaryTitle__Kztd9 {
      font-size: 2.07039vw; }
      .SubmitOverlayMenu_summaryTitle__Kztd9 else {
        font-size: 18px; } }

.SubmitOverlayMenu_questionContainer__3yf0c {
  width: 100%;
  font-family: Nunito;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: #403F3F;
  padding: 12px 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 4.34783vw; }
  .SubmitOverlayMenu_questionContainer__3yf0c:last-child {
    border-bottom: none; }
  .SubmitOverlayMenu_questionContainer__3yf0c > span:nth-child(1) {
    flex: 1 1 70%; }
  .SubmitOverlayMenu_questionContainer__3yf0c > span:nth-child(2) {
    flex: 1 1 20%;
    position: relative; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_questionContainer__3yf0c {
      font-size: 1.14583vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_questionContainer__3yf0c {
      font-size: 1.27315vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_questionContainer__3yf0c {
      font-size: 1.14583vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_questionContainer__3yf0c {
      font-size: 3.62319vw; }
      .SubmitOverlayMenu_questionContainer__3yf0c else {
        font-size: 18px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_questionContainer__3yf0c {
      font-size: 2.89855vw; }
      .SubmitOverlayMenu_questionContainer__3yf0c else {
        font-size: 18px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_questionContainer__3yf0c {
      font-size: 2.55754vw; }
      .SubmitOverlayMenu_questionContainer__3yf0c else {
        font-size: 18px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_questionContainer__3yf0c {
      font-size: 2.28833vw; }
      .SubmitOverlayMenu_questionContainer__3yf0c else {
        font-size: 18px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_questionContainer__3yf0c {
      font-size: 2.07039vw; }
      .SubmitOverlayMenu_questionContainer__3yf0c else {
        font-size: 18px; } }

.SubmitOverlayMenu_questionText__R9XWy {
  box-sizing: border-box;
  align-items: center;
  text-overflow: ellipsis;
  display: flex;
  white-space: nowrap;
  width: 100%;
  color: #858585; }
  .SubmitOverlayMenu_questionText__R9XWy pre:nth-child(1) {
    background: #FAFAFA;
    padding: 8px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-right: 7px;
    border-radius: 50%;
    font-family: Nunito;
    color: #858585; }
  .SubmitOverlayMenu_questionText__R9XWy p, .SubmitOverlayMenu_questionText__R9XWy img, .SubmitOverlayMenu_questionText__R9XWy li {
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    counter-set: li; }
    @media (min-width: 1300px) {
      .SubmitOverlayMenu_questionText__R9XWy p, .SubmitOverlayMenu_questionText__R9XWy img, .SubmitOverlayMenu_questionText__R9XWy li {
        max-width: 36.45833vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .SubmitOverlayMenu_questionText__R9XWy p, .SubmitOverlayMenu_questionText__R9XWy img, .SubmitOverlayMenu_questionText__R9XWy li {
        max-width: 40.50926vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .SubmitOverlayMenu_questionText__R9XWy p, .SubmitOverlayMenu_questionText__R9XWy img, .SubmitOverlayMenu_questionText__R9XWy li {
        max-width: 36.45833vw; } }
  .SubmitOverlayMenu_questionText__R9XWy ul, .SubmitOverlayMenu_questionText__R9XWy ol {
    counter-reset: my-awesome-counter; }
  .SubmitOverlayMenu_questionText__R9XWy li {
    -webkit-padding-start: 11px;
            padding-inline-start: 11px;
    counter-increment: my-awesome-counter; }
  .SubmitOverlayMenu_questionText__R9XWy li::before {
    content: counter(my-awesome-counter);
    font-weight: bold;
    position: absolute;
    left: -2px; }
  .SubmitOverlayMenu_questionText__R9XWy > code, .SubmitOverlayMenu_questionText__R9XWy > img, .SubmitOverlayMenu_questionText__R9XWy > ol, .SubmitOverlayMenu_questionText__R9XWy pre:not(:nth-child(1)) {
    display: none; }

.SubmitOverlayMenu_tagContainer__1XBZP {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: right; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_tagContainer__1XBZP {
      justify-content: center; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_tagContainer__1XBZP {
      justify-content: center; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_tagContainer__1XBZP {
      justify-content: center; } }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_tagContainer__1XBZP svg {
      width: 1.66667vw;
      height: 1.66667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_tagContainer__1XBZP svg {
      width: 1.85185vw;
      height: 1.85185vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_tagContainer__1XBZP svg {
      width: 1.66667vw;
      height: 1.66667vw; } }

.SubmitOverlayMenu_answeredTag__2Dpq5 {
  border-radius: 18px;
  font-weight: bold;
  letter-spacing: .03em;
  color: #FFFFFF;
  margin-left: auto;
  margin-right: 20px;
  padding: 6px 12px;
  font-size: 3.38164vw; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_answeredTag__2Dpq5 {
      font-size: 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_answeredTag__2Dpq5 {
      font-size: 0.92593vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_answeredTag__2Dpq5 {
      font-size: 0.83333vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_answeredTag__2Dpq5 {
      font-size: 2.81804vw; }
      .SubmitOverlayMenu_answeredTag__2Dpq5 else {
        font-size: 14px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_answeredTag__2Dpq5 {
      font-size: 2.25443vw; }
      .SubmitOverlayMenu_answeredTag__2Dpq5 else {
        font-size: 14px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_answeredTag__2Dpq5 {
      font-size: 1.9892vw; }
      .SubmitOverlayMenu_answeredTag__2Dpq5 else {
        font-size: 14px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_answeredTag__2Dpq5 {
      font-size: 1.77981vw; }
      .SubmitOverlayMenu_answeredTag__2Dpq5 else {
        font-size: 14px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_answeredTag__2Dpq5 {
      font-size: 1.61031vw; }
      .SubmitOverlayMenu_answeredTag__2Dpq5 else {
        font-size: 14px; } }

.SubmitOverlayMenu_unAnsweredTag__1AoTF {
  margin-left: auto;
  background-color: transparent !important;
  border: 2px solid #00ADE6;
  color: #00ADE6; }

.SubmitOverlayMenu_headerText__2PAw1 {
  font-family: Nunito;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: #A8A7A7;
  font-size: 3.38164vw; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_headerText__2PAw1 {
      font-size: 0.88542vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_headerText__2PAw1 {
      font-size: 0.9838vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_headerText__2PAw1 {
      font-size: 0.88542vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_headerText__2PAw1 {
      font-size: 2.81804vw; }
      .SubmitOverlayMenu_headerText__2PAw1 else {
        font-size: 14px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_headerText__2PAw1 {
      font-size: 2.25443vw; }
      .SubmitOverlayMenu_headerText__2PAw1 else {
        font-size: 14px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_headerText__2PAw1 {
      font-size: 1.9892vw; }
      .SubmitOverlayMenu_headerText__2PAw1 else {
        font-size: 14px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_headerText__2PAw1 {
      font-size: 1.77981vw; }
      .SubmitOverlayMenu_headerText__2PAw1 else {
        font-size: 14px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_headerText__2PAw1 {
      font-size: 1.61031vw; }
      .SubmitOverlayMenu_headerText__2PAw1 else {
        font-size: 14px; } }
  .SubmitOverlayMenu_headerText__2PAw1:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center; }

.SubmitOverlayMenu_editIcon__2WXuk {
  width: 20px;
  height: 20px;
  background-image: url(/static/media/confirmEditIcon.45a960f3.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  box-sizing: border-box;
  position: absolute;
  right: -10px;
  cursor: pointer; }

.SubmitOverlayMenu_qrLoaderCard__3Y6XM {
  width: 100%;
  height: 100%; }
  .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH {
    y: 11.11111vw;
    x: 42.77778vw;
    height: 19.44444vw;
    width: 125vw;
    rx: 10px; }
    @media (min-width: 1300px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH {
        y: 0.52083vw;
        x: 2.60417vw;
        height: 3.125vw;
        width: 90%;
        rx: 6px; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH {
        y: 0.5787vw;
        x: 2.89352vw;
        height: 3.47222vw;
        width: 90%;
        rx: 6px; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH {
        y: 0.52083vw;
        x: 2.60417vw;
        height: 3.125vw;
        width: 90%;
        rx: 6px; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH {
        y: 9.25926vw;
        x: 35.64815vw;
        height: 16.2037vw;
        width: 104.16667vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          width: 450px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH {
        y: 7.40741vw;
        x: 28.51852vw;
        height: 12.96296vw;
        width: 83.33333vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          width: 450px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH {
        y: 6.53595vw;
        x: 25.1634vw;
        height: 11.43791vw;
        width: 73.52941vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          width: 450px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH {
        y: 5.84795vw;
        x: 22.51462vw;
        height: 10.23392vw;
        width: 65.78947vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          width: 450px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH {
        y: 5.29101vw;
        x: 20.37037vw;
        height: 9.25926vw;
        width: 59.52381vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          width: 450px; } }
  .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ {
    y: 3.33333vw;
    x: 3.33333vw;
    height: 19.44444vw;
    width: 83.33333vw;
    rx: 10px; }
    @media (min-width: 450px) and (max-width: 500px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ {
        y: 2.77778vw;
        x: 2.77778vw;
        height: 16.2037vw;
        width: 69.44444vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          y: 12px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          x: 12px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          width: 300px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ {
        y: 2.22222vw;
        x: 2.22222vw;
        height: 12.96296vw;
        width: 55.55556vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          y: 12px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          x: 12px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          width: 300px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ {
        y: 1.96078vw;
        x: 1.96078vw;
        height: 11.43791vw;
        width: 49.01961vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          y: 12px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          x: 12px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          width: 300px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ {
        y: 1.75439vw;
        x: 1.75439vw;
        height: 10.23392vw;
        width: 43.85965vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          y: 12px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          x: 12px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          width: 300px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ {
        y: 1.5873vw;
        x: 1.5873vw;
        height: 9.25926vw;
        width: 39.68254vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          y: 12px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          x: 12px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          width: 300px; } }
  .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA {
    y: 11.11111vw;
    x: 42.77778vw;
    height: 19.44444vw;
    width: 125vw;
    rx: 10px; }
    @media (min-width: 1300px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA {
        y: 4.16667vw;
        x: 2.60417vw;
        height: 3.125vw;
        width: 90%;
        rx: 6px; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA {
        y: 4.62963vw;
        x: 2.89352vw;
        height: 3.47222vw;
        width: 90%;
        rx: 6px; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA {
        y: 4.16667vw;
        x: 2.60417vw;
        height: 3.125vw;
        width: 90%;
        rx: 6px; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA {
        y: 9.25926vw;
        x: 35.64815vw;
        height: 16.2037vw;
        width: 104.16667vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          width: 450px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA {
        y: 7.40741vw;
        x: 28.51852vw;
        height: 12.96296vw;
        width: 83.33333vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          width: 450px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA {
        y: 6.53595vw;
        x: 25.1634vw;
        height: 11.43791vw;
        width: 73.52941vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          width: 450px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA {
        y: 5.84795vw;
        x: 22.51462vw;
        height: 10.23392vw;
        width: 65.78947vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          width: 450px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA {
        y: 5.29101vw;
        x: 20.37037vw;
        height: 9.25926vw;
        width: 59.52381vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          width: 450px; } }
  .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j {
    y: 11.11111vw;
    x: 42.77778vw;
    height: 19.44444vw;
    width: 125vw;
    rx: 10px; }
    @media (min-width: 1300px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j {
        y: 7.8125vw;
        x: 2.60417vw;
        height: 3.125vw;
        width: 90%;
        rx: 6px; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j {
        y: 8.68056vw;
        x: 2.89352vw;
        height: 3.47222vw;
        width: 90%;
        rx: 6px; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j {
        y: 7.8125vw;
        x: 2.60417vw;
        height: 3.125vw;
        width: 90%;
        rx: 6px; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j {
        y: 9.25926vw;
        x: 35.64815vw;
        height: 16.2037vw;
        width: 104.16667vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          width: 450px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j {
        y: 7.40741vw;
        x: 28.51852vw;
        height: 12.96296vw;
        width: 83.33333vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          width: 450px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j {
        y: 6.53595vw;
        x: 25.1634vw;
        height: 11.43791vw;
        width: 73.52941vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          width: 450px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j {
        y: 5.84795vw;
        x: 22.51462vw;
        height: 10.23392vw;
        width: 65.78947vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          width: 450px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j {
        y: 5.29101vw;
        x: 20.37037vw;
        height: 9.25926vw;
        width: 59.52381vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          width: 450px; } }

.SubmitOverlayMenu_overlayCloseIconForEmbed__3Wt0y {
  display: flex;
  top: unset; }

.SubmitOverlayMenu_footer__3p9-U {
  margin-top: auto;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
  background: #F9F9F9; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_footer__3p9-U {
      border-radius: 0vw 0vw 1.04167vw 1.04167vw;
      box-shadow: 0vw -0.10417vw 0vw rgba(0, 0, 0, 0.1);
      padding: 0.9375vw 2.5vw 0.9375vw 0.52083vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_footer__3p9-U {
      border-radius: 0vw 0vw 1.15741vw 1.15741vw;
      box-shadow: 0vw -0.11574vw 0vw rgba(0, 0, 0, 0.1);
      padding: 1.04167vw 2.77778vw 1.04167vw 0.5787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_footer__3p9-U {
      border-radius: 0vw 0vw 1.04167vw 1.04167vw;
      box-shadow: 0vw -0.10417vw 0vw rgba(0, 0, 0, 0.1);
      padding: 0.9375vw 2.5vw 0.9375vw 0.52083vw; } }

.SubmitOverlayMenu_header__5fhL1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: #FAFAFA; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_header__5fhL1 {
      border-radius: 1.04167vw 1.04167vw 0vw 0vw;
      grid-gap: 0.83333vw;
      gap: 0.83333vw;
      padding: 1.45833vw 11.45833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_header__5fhL1 {
      border-radius: 1.15741vw 1.15741vw 0vw 0vw;
      grid-gap: 0.92593vw;
      gap: 0.92593vw;
      padding: 1.62037vw 12.73148vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_header__5fhL1 {
      border-radius: 1.04167vw 1.04167vw 0vw 0vw;
      grid-gap: 0.83333vw;
      gap: 0.83333vw;
      padding: 1.45833vw 11.45833vw; } }

.SubmitOverlayMenu_headerTitle__3yCTE {
  font-weight: 700; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_headerTitle__3yCTE {
      font-size: 1.45833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_headerTitle__3yCTE {
      font-size: 1.62037vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_headerTitle__3yCTE {
      font-size: 1.45833vw; } }

.SubmitOverlayMenu_componentName__22-Ey {
  display: flex;
  align-items: center;
  font-weight: 700;
  color: #504F4F; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_componentName__22-Ey {
      font-size: 1.35417vw;
      grid-gap: 0.78125vw;
      gap: 0.78125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_componentName__22-Ey {
      font-size: 1.50463vw;
      grid-gap: 0.86806vw;
      gap: 0.86806vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_componentName__22-Ey {
      font-size: 1.35417vw;
      grid-gap: 0.78125vw;
      gap: 0.78125vw; } }

@media (min-width: 1300px) {
  .SubmitOverlayMenu_componentIcon__1PWY3 {
    min-width: 1.45833vw;
    min-height: 1.45833vw;
    max-width: 1.45833vw;
    max-height: 1.45833vw;
    display: flex;
    justify-content: center;
    align-items: center; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .SubmitOverlayMenu_componentIcon__1PWY3 {
    min-width: 1.62037vw;
    min-height: 1.62037vw;
    max-width: 1.62037vw;
    max-height: 1.62037vw;
    display: flex;
    justify-content: center;
    align-items: center; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .SubmitOverlayMenu_componentIcon__1PWY3 {
    min-width: 1.45833vw;
    min-height: 1.45833vw;
    max-width: 1.45833vw;
    max-height: 1.45833vw;
    display: flex;
    justify-content: center;
    align-items: center; } }

.SubmitOverlayMenu_componentIcon__1PWY3 svg {
  display: block;
  fill: #504F4F !important; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_componentIcon__1PWY3 svg {
      height: 1.45833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_componentIcon__1PWY3 svg {
      height: 1.62037vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_componentIcon__1PWY3 svg {
      height: 1.45833vw; } }

.SubmitOverlayMenu_componentHeader__2E00k {
  position: -webkit-sticky;
  position: sticky;
  top: -10px;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background: #FAFAFA; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_componentHeader__2E00k {
      padding: 1.04167vw 1.25vw;
      height: 3.48958vw;
      border-radius: 0.625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_componentHeader__2E00k {
      padding: 1.15741vw 1.38889vw;
      height: 3.87731vw;
      border-radius: 0.69444vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_componentHeader__2E00k {
      padding: 1.04167vw 1.25vw;
      height: 3.48958vw;
      border-radius: 0.625vw; } }

.SubmitOverlayMenu_noBottom__3Zx6z {
  border: none; }

.SubmitOverlayMenu_componentStatus__3R0K5 {
  color: #504F4F;
  letter-spacing: 0.1em;
  text-transform: uppercase; }
  .SubmitOverlayMenu_componentStatus__3R0K5 span {
    font-weight: 700;
    color: #282828; }
    @media (min-width: 1300px) {
      .SubmitOverlayMenu_componentStatus__3R0K5 span {
        font-size: 1.04167vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .SubmitOverlayMenu_componentStatus__3R0K5 span {
        font-size: 1.15741vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .SubmitOverlayMenu_componentStatus__3R0K5 span {
        font-size: 1.04167vw; } }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_componentStatus__3R0K5 {
      font-size: 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_componentStatus__3R0K5 {
      font-size: 0.92593vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_componentStatus__3R0K5 {
      font-size: 0.83333vw; } }

.SubmitOverlayMenu_previewQuestion__1Ygww {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_previewQuestion__1Ygww {
      max-width: 36.45833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_previewQuestion__1Ygww {
      max-width: 40.50926vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_previewQuestion__1Ygww {
      max-width: 36.45833vw; } }
  .SubmitOverlayMenu_previewQuestion__1Ygww > ol {
    margin: 10px;
    margin-inline: 0;
    -webkit-padding-start: 20px;
            padding-inline-start: 20px; }
  .SubmitOverlayMenu_previewQuestion__1Ygww img {
    display: none !important; }
  .SubmitOverlayMenu_previewQuestion__1Ygww > p {
    margin: 0px; }

.previewComponent {
  position: relative;
  width: 100%;
  background: inherit; }
  .previewComponent .tox-tinymce {
    box-shadow: none;
    border: none;
    background-color: transparent; }
  .previewComponent iframe {
    background: inherit !important;
    background-color: inherit !important; }
  .previewComponent::-webkit-scrollbar {
    width: 4px;
    background-color: transparent; }
  .previewComponent::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #00ADE6; }

.sf-feedback-modal--close-btn {
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background: #fff;
  right: -16px;
  top: -16px;
  padding: 10px;
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .sf-feedback-modal--close-btn {
      width: 2.70833vw;
      height: 2.70833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sf-feedback-modal--close-btn {
      width: 3.00926vw;
      height: 3.00926vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sf-feedback-modal--close-btn {
      width: 2.70833vw;
      height: 2.70833vw; } }
  .sf-feedback-modal--close-btn svg {
    width: 100%;
    height: 100%; }

.UpdatedToolTip_Inter__2H4XR, .UpdatedToolTip_Inter-regular__1_i25, .UpdatedToolTip_Inter-SemiBold__2DPIy, .UpdatedToolTip_Inter-Bold__PPSMh {
  font-family: 'Inter', 'Nunito', sans-serif; }

.UpdatedToolTip_Inter-regular__1_i25 {
  font-weight: 400; }

.UpdatedToolTip_Inter-SemiBold__2DPIy {
  font-weight: 600; }

.UpdatedToolTip_Inter-Bold__PPSMh {
  font-weight: 800; }

.UpdatedToolTip_tooltipWrapper__6mq0o {
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
  position: relative;
  cursor: pointer;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 1000; }

.UpdatedToolTip_tooltipTip__9Rlog {
  position: absolute;
  margin: 0;
  background: #4A336C;
  color: #fff;
  font-family: sans-serif;
  z-index: 10000;
  white-space: nowrap; }
  @media (min-width: 1000px) {
    .UpdatedToolTip_tooltipTip__9Rlog {
      font-size: 1.40625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .UpdatedToolTip_tooltipTip__9Rlog {
      font-size: 1.5625vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .UpdatedToolTip_tooltipTip__9Rlog {
      font-size: 1.40625vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .UpdatedToolTip_tooltipTip__9Rlog {
      font-size: 1.125vw; } }

.UpdatedToolTip_tooltipbottomLeft__1Q8E8 {
  bottom: 100%;
  left: 50%; }
  @media (min-width: 1000px) {
    .UpdatedToolTip_tooltipbottomLeft__1Q8E8 {
      border-radius: 0.625vw 0.625vw 0.625vw 0vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .UpdatedToolTip_tooltipbottomLeft__1Q8E8 {
      border-radius: 0.69444vw 0.69444vw 0.69444vw 0vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .UpdatedToolTip_tooltipbottomLeft__1Q8E8 {
      border-radius: 0.625vw 0.625vw 0.625vw 0vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .UpdatedToolTip_tooltipbottomLeft__1Q8E8 {
      border-radius: 0.5vw 0.5vw 0.5vw 0vw; } }

.UpdatedToolTip_tooltiptopLeft__2w1ty {
  top: 0;
  left: 100%; }
  @media (min-width: 1000px) {
    .UpdatedToolTip_tooltiptopLeft__2w1ty {
      border-radius: 0vw 0.625vw 0.625vw 0.625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .UpdatedToolTip_tooltiptopLeft__2w1ty {
      border-radius: 0vw 0.69444vw 0.69444vw 0.69444vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .UpdatedToolTip_tooltiptopLeft__2w1ty {
      border-radius: 0vw 0.625vw 0.625vw 0.625vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .UpdatedToolTip_tooltiptopLeft__2w1ty {
      border-radius: 0vw 0.5vw 0.5vw 0.5vw; } }

.UpdatedToolTip_bottomLeft__2phO8 {
  position: absolute;
  left: 50%;
  display: flex; }

.UpdatedToolTip_topLeft__1bSXE {
  position: absolute;
  left: 0;
  bottom: 0;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }
  .UpdatedToolTip_topLeft__1bSXE svg {
    margin: 0;
    padding: 0; }

.UpdatedToolTip_tooltipTip__9Rlog ::-webkit-scrollbar {
  background: #4A336C; }

.UpdatedToolTip_tooltipTip__9Rlog ::-webkit-scrollbar-thumb {
  background: #eeeeeeb7; }

.StudentListTooltip_Inter__2tsRh, .StudentListTooltip_Inter-regular__33Etl, .StudentListTooltip_Inter-SemiBold__i1I_3, .StudentListTooltip_Inter-Bold__16hBx {
  font-family: 'Inter', 'Nunito', sans-serif; }

.StudentListTooltip_Inter-regular__33Etl {
  font-weight: 400; }

.StudentListTooltip_Inter-SemiBold__i1I_3 {
  font-weight: 600; }

.StudentListTooltip_Inter-Bold__16hBx {
  font-weight: 800; }

.StudentListTooltip_tooltipContainer__24o1Y {
  display: flex;
  flex-direction: column;
  z-index: 99; }
  @media (min-width: 1000px) {
    .StudentListTooltip_tooltipContainer__24o1Y {
      margin: 0.9375vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .StudentListTooltip_tooltipContainer__24o1Y {
      margin: 1.04167vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .StudentListTooltip_tooltipContainer__24o1Y {
      margin: 0.9375vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .StudentListTooltip_tooltipContainer__24o1Y {
      margin: 0.75vw; } }

.StudentListTooltip_tooltipHeadingContainer__2zkBt {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ffffff33; }
  @media (min-width: 1000px) {
    .StudentListTooltip_tooltipHeadingContainer__2zkBt {
      padding-bottom: 0.625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .StudentListTooltip_tooltipHeadingContainer__2zkBt {
      padding-bottom: 0.69444vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .StudentListTooltip_tooltipHeadingContainer__2zkBt {
      padding-bottom: 0.625vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .StudentListTooltip_tooltipHeadingContainer__2zkBt {
      padding-bottom: 0.5vw; } }
  .StudentListTooltip_tooltipHeadingContainer__2zkBt h2 {
    font-family: 'Inter';
    font-style: normal;
    color: #FFFFFF;
    font-weight: 600; }
    @media (min-width: 1000px) {
      .StudentListTooltip_tooltipHeadingContainer__2zkBt h2 {
        font-size: 0.9375vw;
        line-height: 120%;
        margin-left: 0.3125vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .StudentListTooltip_tooltipHeadingContainer__2zkBt h2 {
        font-size: 1.04167vw;
        line-height: 120%;
        margin-left: 0.34722vw; } }
    @media (min-width: 1001px) and (max-width: 1600px) {
      .StudentListTooltip_tooltipHeadingContainer__2zkBt h2 {
        font-size: 0.9375vw;
        line-height: 120%;
        margin-left: 0.3125vw; } }
    @media (min-width: 1601px) and (max-width: 2000px) {
      .StudentListTooltip_tooltipHeadingContainer__2zkBt h2 {
        font-size: 0.75vw;
        line-height: 120%;
        margin-left: 0.25vw; } }

.StudentListTooltip_tooltipBodyContainer__ovqFo {
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  list-style: none;
  overflow: scroll; }
  @media (min-width: 1000px) {
    .StudentListTooltip_tooltipBodyContainer__ovqFo {
      margin: 0;
      margin-top: 0.625vw;
      max-height: 7.34375vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .StudentListTooltip_tooltipBodyContainer__ovqFo {
      margin: 0;
      margin-top: 0.69444vw;
      max-height: 8.15972vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .StudentListTooltip_tooltipBodyContainer__ovqFo {
      margin: 0;
      margin-top: 0.625vw;
      max-height: 7.34375vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .StudentListTooltip_tooltipBodyContainer__ovqFo {
      margin: 0;
      margin-top: 0.5vw;
      max-height: 5.875vw; } }
  .StudentListTooltip_tooltipBodyContainer__ovqFo li {
    font-weight: 400; }
    @media (min-width: 1000px) {
      .StudentListTooltip_tooltipBodyContainer__ovqFo li {
        font-size: 0.78125vw;
        line-height: 160%;
        margin-bottom: 0.3125vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .StudentListTooltip_tooltipBodyContainer__ovqFo li {
        font-size: 0.86806vw;
        line-height: 160%;
        margin-bottom: 0.34722vw; } }
    @media (min-width: 1001px) and (max-width: 1600px) {
      .StudentListTooltip_tooltipBodyContainer__ovqFo li {
        font-size: 0.78125vw;
        line-height: 160%;
        margin-bottom: 0.3125vw; } }
    @media (min-width: 1601px) and (max-width: 2000px) {
      .StudentListTooltip_tooltipBodyContainer__ovqFo li {
        font-size: 0.625vw;
        line-height: 160%;
        margin-bottom: 0.25vw; } }

.tooltip_tooltip__25oLw {
  position: absolute;
  background: #c1e8f4;
  font-weight: 600;
  color: #023343;
  z-index: 99999999; }
  @media (min-width: 1300px) {
    .tooltip_tooltip__25oLw {
      font-size: 0.83333vw;
      line-height: 150%;
      padding: 0.625vw 0.83333vw;
      border-radius: 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .tooltip_tooltip__25oLw {
      font-size: 0.92593vw;
      line-height: 150%;
      padding: 0.69444vw 0.92593vw;
      border-radius: 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .tooltip_tooltip__25oLw {
      font-size: 0.83333vw;
      line-height: 150%;
      padding: 0.625vw 0.83333vw;
      border-radius: 0.41667vw; } }
  .tooltip_tooltip__25oLw .tooltip_beak__3j51X {
    color: #c1e8f4; }

.tooltip_secondary__3lxUK {
  background: #403F3F;
  color: #fff; }
  .tooltip_secondary__3lxUK .tooltip_beak__3j51X {
    color: #403F3F; }

.tooltip_beak__3j51X {
  position: absolute;
  display: flex; }

.tooltip_beakLeft__2psM3 {
  top: -14px;
  right: 10px; }

.tooltip_beakRight__3xvyB {
  top: -14px;
  left: 10px;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg); }

.tooltip_beakBottom__3x2lK {
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%) rotateX(180deg);
          transform: translateX(-50%) rotateX(180deg); }

.tooltip_beakMiddleLeft__3SrAr {
  top: 40%;
  right: 99%;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg); }

.tooltip_beakBottomRight__2fHcC {
  top: 100%;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  right: 10px; }

.tooltip_beakBottomLeft__3OvHl {
  top: 100%;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  left: 15px; }

.tooltip_tertiary__UjuBR {
  background-color: #4A336C;
  color: #fff; }
  .tooltip_tertiary__UjuBR .tooltip_beak__3j51X {
    color: #403F3F; }

.tooltip_open__1YofK {
  opacity: 1;
  pointer-events: all; }

.tooltip_close__3Iaz4 {
  opacity: 0;
  pointer-events: none; }

.stack_stack__uxurZ {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  grid-gap: 1.93237vw;
  gap: 1.93237vw; }
  @media (min-width: 1300px) {
    .stack_stack__uxurZ {
      grid-gap: 0.41667vw;
      gap: 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .stack_stack__uxurZ {
      grid-gap: 0.46296vw;
      gap: 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .stack_stack__uxurZ {
      grid-gap: 0.41667vw;
      gap: 0.41667vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .stack_stack__uxurZ {
      grid-gap: 1.61031vw;
      gap: 1.61031vw; }
      .stack_stack__uxurZ else {
        grid-gap: 8px;
        gap: 8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .stack_stack__uxurZ {
      grid-gap: 1.28824vw;
      gap: 1.28824vw; }
      .stack_stack__uxurZ else {
        grid-gap: 8px;
        gap: 8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .stack_stack__uxurZ {
      grid-gap: 1.13669vw;
      gap: 1.13669vw; }
      .stack_stack__uxurZ else {
        grid-gap: 8px;
        gap: 8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .stack_stack__uxurZ {
      grid-gap: 1.01704vw;
      gap: 1.01704vw; }
      .stack_stack__uxurZ else {
        grid-gap: 8px;
        gap: 8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .stack_stack__uxurZ {
      grid-gap: 0.92017vw;
      gap: 0.92017vw; }
      .stack_stack__uxurZ else {
        grid-gap: 8px;
        gap: 8px; } }

.stack_row__19pPl {
  flex-direction: row;
  align-items: center; }

@media (min-width: 1300px) {
  .stack_s1__3u72H {
    grid-gap: 0.10417vw;
    gap: 0.10417vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .stack_s1__3u72H {
    grid-gap: 0.11574vw;
    gap: 0.11574vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .stack_s1__3u72H {
    grid-gap: 0.10417vw;
    gap: 0.10417vw; } }

@media (min-width: 1300px) {
  .stack_s2__1tpOk {
    grid-gap: 0.20833vw;
    gap: 0.20833vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .stack_s2__1tpOk {
    grid-gap: 0.23148vw;
    gap: 0.23148vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .stack_s2__1tpOk {
    grid-gap: 0.20833vw;
    gap: 0.20833vw; } }

@media (min-width: 1300px) {
  .stack_s3__2xBFf {
    grid-gap: 0.3125vw;
    gap: 0.3125vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .stack_s3__2xBFf {
    grid-gap: 0.34722vw;
    gap: 0.34722vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .stack_s3__2xBFf {
    grid-gap: 0.3125vw;
    gap: 0.3125vw; } }

@media (min-width: 1300px) {
  .stack_s4__38Ba_ {
    grid-gap: 0.41667vw;
    gap: 0.41667vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .stack_s4__38Ba_ {
    grid-gap: 0.46296vw;
    gap: 0.46296vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .stack_s4__38Ba_ {
    grid-gap: 0.41667vw;
    gap: 0.41667vw; } }

@media (min-width: 1300px) {
  .stack_s5__3cVS1 {
    grid-gap: 0.52083vw;
    gap: 0.52083vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .stack_s5__3cVS1 {
    grid-gap: 0.5787vw;
    gap: 0.5787vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .stack_s5__3cVS1 {
    grid-gap: 0.52083vw;
    gap: 0.52083vw; } }

@media (min-width: 1300px) {
  .stack_s6__1r_Za {
    grid-gap: 0.625vw;
    gap: 0.625vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .stack_s6__1r_Za {
    grid-gap: 0.69444vw;
    gap: 0.69444vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .stack_s6__1r_Za {
    grid-gap: 0.625vw;
    gap: 0.625vw; } }

@media (min-width: 1300px) {
  .stack_s7__1SIIz {
    grid-gap: 0.72917vw;
    gap: 0.72917vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .stack_s7__1SIIz {
    grid-gap: 0.81019vw;
    gap: 0.81019vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .stack_s7__1SIIz {
    grid-gap: 0.72917vw;
    gap: 0.72917vw; } }

@media (min-width: 1300px) {
  .stack_s8__1eC97 {
    grid-gap: 0.83333vw;
    gap: 0.83333vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .stack_s8__1eC97 {
    grid-gap: 0.92593vw;
    gap: 0.92593vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .stack_s8__1eC97 {
    grid-gap: 0.83333vw;
    gap: 0.83333vw; } }

@media (min-width: 1300px) {
  .stack_s9__126Ij {
    grid-gap: 0.9375vw;
    gap: 0.9375vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .stack_s9__126Ij {
    grid-gap: 1.04167vw;
    gap: 1.04167vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .stack_s9__126Ij {
    grid-gap: 0.9375vw;
    gap: 0.9375vw; } }

@media (min-width: 1300px) {
  .stack_s10__1i-_f {
    grid-gap: 1.04167vw;
    gap: 1.04167vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .stack_s10__1i-_f {
    grid-gap: 1.15741vw;
    gap: 1.15741vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .stack_s10__1i-_f {
    grid-gap: 1.04167vw;
    gap: 1.04167vw; } }

@media (min-width: 1300px) {
  .stack_s11__3OyTR {
    grid-gap: 1.14583vw;
    gap: 1.14583vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .stack_s11__3OyTR {
    grid-gap: 1.27315vw;
    gap: 1.27315vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .stack_s11__3OyTR {
    grid-gap: 1.14583vw;
    gap: 1.14583vw; } }

@media (min-width: 1300px) {
  .stack_s12__2e5p2 {
    grid-gap: 1.25vw;
    gap: 1.25vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .stack_s12__2e5p2 {
    grid-gap: 1.38889vw;
    gap: 1.38889vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .stack_s12__2e5p2 {
    grid-gap: 1.25vw;
    gap: 1.25vw; } }

@media (min-width: 1300px) {
  .stack_s13__31NS- {
    grid-gap: 1.35417vw;
    gap: 1.35417vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .stack_s13__31NS- {
    grid-gap: 1.50463vw;
    gap: 1.50463vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .stack_s13__31NS- {
    grid-gap: 1.35417vw;
    gap: 1.35417vw; } }

@media (min-width: 1300px) {
  .stack_s14__1YN3n {
    grid-gap: 1.45833vw;
    gap: 1.45833vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .stack_s14__1YN3n {
    grid-gap: 1.62037vw;
    gap: 1.62037vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .stack_s14__1YN3n {
    grid-gap: 1.45833vw;
    gap: 1.45833vw; } }

@media (min-width: 1300px) {
  .stack_s15__1UYF_ {
    grid-gap: 1.5625vw;
    gap: 1.5625vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .stack_s15__1UYF_ {
    grid-gap: 1.73611vw;
    gap: 1.73611vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .stack_s15__1UYF_ {
    grid-gap: 1.5625vw;
    gap: 1.5625vw; } }

@media (min-width: 1300px) {
  .stack_s16__11oea {
    grid-gap: 1.66667vw;
    gap: 1.66667vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .stack_s16__11oea {
    grid-gap: 1.85185vw;
    gap: 1.85185vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .stack_s16__11oea {
    grid-gap: 1.66667vw;
    gap: 1.66667vw; } }

@media (min-width: 1300px) {
  .stack_s17__1XpgW {
    grid-gap: 1.77083vw;
    gap: 1.77083vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .stack_s17__1XpgW {
    grid-gap: 1.96759vw;
    gap: 1.96759vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .stack_s17__1XpgW {
    grid-gap: 1.77083vw;
    gap: 1.77083vw; } }

@media (min-width: 1300px) {
  .stack_s18__aUnsl {
    grid-gap: 1.875vw;
    gap: 1.875vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .stack_s18__aUnsl {
    grid-gap: 2.08333vw;
    gap: 2.08333vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .stack_s18__aUnsl {
    grid-gap: 1.875vw;
    gap: 1.875vw; } }

@media (min-width: 1300px) {
  .stack_s19__1Hspc {
    grid-gap: 1.97917vw;
    gap: 1.97917vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .stack_s19__1Hspc {
    grid-gap: 2.19907vw;
    gap: 2.19907vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .stack_s19__1Hspc {
    grid-gap: 1.97917vw;
    gap: 1.97917vw; } }

@media (min-width: 1300px) {
  .stack_s20__13TB8 {
    grid-gap: 2.08333vw;
    gap: 2.08333vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .stack_s20__13TB8 {
    grid-gap: 2.31481vw;
    gap: 2.31481vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .stack_s20__13TB8 {
    grid-gap: 2.08333vw;
    gap: 2.08333vw; } }

.button_button__3DQfx {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: inherit;
  background-color: #00ADE6;
  border: none;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  padding: 1.44928vw 3.38164vw;
  grid-gap: 1.93237vw;
  gap: 1.93237vw; }
  @media (min-width: 1300px) {
    .button_button__3DQfx {
      padding: 0.3125vw 0.72917vw;
      grid-gap: 0.41667vw;
      gap: 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .button_button__3DQfx {
      padding: 0.34722vw 0.81019vw;
      grid-gap: 0.46296vw;
      gap: 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .button_button__3DQfx {
      padding: 0.3125vw 0.72917vw;
      grid-gap: 0.41667vw;
      gap: 0.41667vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .button_button__3DQfx {
      padding: 1.20773vw 2.81804vw;
      grid-gap: 1.61031vw;
      gap: 1.61031vw; }
      .button_button__3DQfx else {
        grid-gap: 8px;
        gap: 8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .button_button__3DQfx {
      padding: 0.96618vw 2.25443vw;
      grid-gap: 1.28824vw;
      gap: 1.28824vw; }
      .button_button__3DQfx else {
        grid-gap: 8px;
        gap: 8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .button_button__3DQfx {
      padding: 0.85251vw 1.9892vw;
      grid-gap: 1.13669vw;
      gap: 1.13669vw; }
      .button_button__3DQfx else {
        grid-gap: 8px;
        gap: 8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .button_button__3DQfx {
      padding: 0.76278vw 1.77981vw;
      grid-gap: 1.01704vw;
      gap: 1.01704vw; }
      .button_button__3DQfx else {
        grid-gap: 8px;
        gap: 8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .button_button__3DQfx {
      padding: 0.69013vw 1.61031vw;
      grid-gap: 0.92017vw;
      gap: 0.92017vw; }
      .button_button__3DQfx else {
        grid-gap: 8px;
        gap: 8px; } }
  .button_button__3DQfx span {
    font-style: normal;
    font-weight: 700;
    color: #ffffff; }
    @media (min-width: 1300px) {
      .button_button__3DQfx span {
        font-size: 0.83333vw;
        line-height: 1.14583vw;
        letter-spacing: -0.02em; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .button_button__3DQfx span {
        font-size: 0.92593vw;
        line-height: 1.27315vw;
        letter-spacing: -0.02em; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .button_button__3DQfx span {
        font-size: 0.83333vw;
        line-height: 1.14583vw;
        letter-spacing: -0.02em; } }
  .button_button__3DQfx svg {
    display: block; }
    @media (min-width: 1300px) {
      .button_button__3DQfx svg {
        width: 1.04167vw;
        height: 1.04167vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .button_button__3DQfx svg {
        width: 1.15741vw;
        height: 1.15741vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .button_button__3DQfx svg {
        width: 1.04167vw;
        height: 1.04167vw; } }

.button_secondary__1xOJS {
  background-color: transparent;
  border: 1px solid rgba(2, 51, 67, 0.2); }
  .button_secondary__1xOJS span {
    color: #023343; }

.button_tertiary__248na {
  background-color: rgba(220, 220, 220, 0.6);
  border: none; }
  .button_tertiary__248na span {
    color: #504F4F; }

.button_quaternary__15CLY {
  background-color: #8C61CB;
  border: none; }

.button_inverted__1oCsJ {
  background-color: rgba(255, 255, 255, 0.1); }

.button_text__1djhf {
  background-color: transparent; }
  .button_text__1djhf span {
    color: #00ADE6; }

.button_pill__1HDIu {
  border-radius: 20px; }

.button_disabled__3cXm6 {
  cursor: not-allowed;
  mix-blend-mode: luminosity;
  opacity: 0.4; }
  .button_disabled__3cXm6 svg {
    opacity: 0.5; }

@media (min-width: 1300px) {
  .button_small__26a6r {
    padding: 0.20833vw 0.41667vw;
    grid-gap: 0.20833vw;
    gap: 0.20833vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .button_small__26a6r {
    padding: 0.23148vw 0.46296vw;
    grid-gap: 0.23148vw;
    gap: 0.23148vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .button_small__26a6r {
    padding: 0.20833vw 0.41667vw;
    grid-gap: 0.20833vw;
    gap: 0.20833vw; } }

.button_small__26a6r span {
  font-size: 14px;
  line-height: 18px; }
  @media (min-width: 1300px) {
    .button_small__26a6r span {
      font-size: 0.72917vw;
      line-height: 0.9375vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .button_small__26a6r span {
      font-size: 0.81019vw;
      line-height: 1.04167vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .button_small__26a6r span {
      font-size: 0.72917vw;
      line-height: 0.9375vw; } }

@media (min-width: 1300px) {
  .button_large__1Ktfm {
    padding: 0.625vw 0.83333vw;
    grid-gap: 0.41667vw;
    gap: 0.41667vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .button_large__1Ktfm {
    padding: 0.69444vw 0.92593vw;
    grid-gap: 0.46296vw;
    gap: 0.46296vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .button_large__1Ktfm {
    padding: 0.625vw 0.83333vw;
    grid-gap: 0.41667vw;
    gap: 0.41667vw; } }

.button_large__1Ktfm span {
  font-size: 18px;
  line-height: 24px; }
  @media (min-width: 1300px) {
    .button_large__1Ktfm span {
      font-size: 0.9375vw;
      line-height: 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .button_large__1Ktfm span {
      font-size: 1.04167vw;
      line-height: 1.38889vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .button_large__1Ktfm span {
      font-size: 0.9375vw;
      line-height: 1.25vw; } }

.button_block__3CooP {
  display: block;
  width: 100%; }

.dd-select-container {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center; }

.dd-select-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  grid-gap: 11px;
  gap: 11px;
  font-weight: bold; }
  .dd-select-header .dd-header-title {
    display: flex;
    align-items: center; }
    @media (min-width: 1300px) {
      .dd-select-header .dd-header-title {
        grid-gap: 0.625vw;
        gap: 0.625vw;
        font-size: 1.04167vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .dd-select-header .dd-header-title {
        grid-gap: 0.69444vw;
        gap: 0.69444vw;
        font-size: 1.15741vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .dd-select-header .dd-header-title {
        grid-gap: 0.625vw;
        gap: 0.625vw;
        font-size: 1.04167vw; } }
    @media (min-width: 1300px) {
      .dd-select-header .dd-header-title svg {
        width: 1.30208vw;
        height: 1.30208vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .dd-select-header .dd-header-title svg {
        width: 1.44676vw;
        height: 1.44676vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .dd-select-header .dd-header-title svg {
        width: 1.30208vw;
        height: 1.30208vw; } }
  .dd-select-header .dd-list {
    position: absolute;
    top: calc(100% + 4px);
    padding: 5px;
    background: #fff;
    color: #00171F; }
    .dd-select-header .dd-list > * {
      box-sizing: border-box; }
    @media (min-width: 1300px) {
      .dd-select-header .dd-list {
        border-radius: 0.41667vw;
        margin-left: 1.25vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .dd-select-header .dd-list {
        border-radius: 0.46296vw;
        margin-left: 1.38889vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .dd-select-header .dd-list {
        border-radius: 0.41667vw;
        margin-left: 1.25vw; } }
    .dd-select-header .dd-list .dd-header-icon {
      display: flex;
      justify-content: center;
      align-items: center; }
    .dd-select-header .dd-list .dd-list-item {
      display: flex;
      align-items: center;
      color: #00171F;
      font-weight: bold;
      white-space: nowrap;
      transition: background-color 0.2s ease-in-out; }
      @media (min-width: 1300px) {
        .dd-select-header .dd-list .dd-list-item {
          grid-gap: 0.36458vw;
          gap: 0.36458vw;
          height: 2.1875vw;
          font-size: 0.72917vw;
          padding-inline: 10px 17px; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .dd-select-header .dd-list .dd-list-item {
          grid-gap: 0.40509vw;
          gap: 0.40509vw;
          height: 2.43056vw;
          font-size: 0.81019vw;
          padding-inline: 10px 17px; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .dd-select-header .dd-list .dd-list-item {
          grid-gap: 0.36458vw;
          gap: 0.36458vw;
          height: 2.1875vw;
          font-size: 0.72917vw;
          padding-inline: 10px 17px; } }
      .dd-select-header .dd-list .dd-list-item svg {
        width: 14px;
        height: 14px; }
      @media (min-width: 1300px) {
        .dd-select-header .dd-list .dd-list-item[data-size='big'] {
          grid-gap: 0.36458vw;
          gap: 0.36458vw;
          height: 2.1875vw;
          font-size: 15.625vw;
          padding-inline: 10px 17px; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .dd-select-header .dd-list .dd-list-item[data-size='big'] {
          grid-gap: 0.40509vw;
          gap: 0.40509vw;
          height: 2.43056vw;
          font-size: 17.36111vw;
          padding-inline: 10px 17px; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .dd-select-header .dd-list .dd-list-item[data-size='big'] {
          grid-gap: 0.36458vw;
          gap: 0.36458vw;
          height: 2.1875vw;
          font-size: 15.625vw;
          padding-inline: 10px 17px; } }
      .dd-select-header .dd-list .dd-list-item.selected {
        border-radius: 4px;
        border: unset; }
      .dd-select-header .dd-list .dd-list-item.selected-sibling {
        border: unset; }

.dd-list {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(100% + 4px);
  padding: 7px;
  background: #fff;
  color: #00171F; }
  .dd-list > * {
    box-sizing: border-box; }
  @media (min-width: 1300px) {
    .dd-list {
      border-radius: 0.41667vw;
      margin-left: 1.25vw;
      grid-gap: 0.52083vw;
      gap: 0.52083vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .dd-list {
      border-radius: 0.46296vw;
      margin-left: 1.38889vw;
      grid-gap: 0.5787vw;
      gap: 0.5787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .dd-list {
      border-radius: 0.41667vw;
      margin-left: 1.25vw;
      grid-gap: 0.52083vw;
      gap: 0.52083vw; } }
  .dd-list .dd-header-icon {
    display: flex;
    justify-content: center;
    align-items: center; }
  .dd-list .dd-list-item {
    display: flex;
    align-items: center;
    color: #00171F;
    font-weight: bold;
    white-space: nowrap;
    transition: background-color 0.2s ease-in-out; }
    .dd-list .dd-list-item:hover {
      background-color: #e6f7fdba; }
    @media (min-width: 1300px) {
      .dd-list .dd-list-item {
        grid-gap: 0.52083vw;
        gap: 0.52083vw;
        height: 3.22917vw;
        font-size: 0.83333vw;
        padding-inline: 12px 12px; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .dd-list .dd-list-item {
        grid-gap: 0.5787vw;
        gap: 0.5787vw;
        height: 3.58796vw;
        font-size: 0.92593vw;
        padding-inline: 12px 12px; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .dd-list .dd-list-item {
        grid-gap: 0.52083vw;
        gap: 0.52083vw;
        height: 3.22917vw;
        font-size: 0.83333vw;
        padding-inline: 12px 12px; } }
    .dd-list .dd-list-item svg {
      width: 14px;
      height: 14px; }
    .dd-list .dd-list-item.selected {
      border-radius: 4px;
      background-color: #E6F7FD;
      border: unset; }
    .dd-list .dd-list-item.selected-sibling {
      border: unset; }
  .dd-list[data-direction="right"] {
    left: 100%;
    top: unset; }
  .dd-list[data-size="big"] {
    padding: 8px; }
    @media (min-width: 1300px) {
      .dd-list[data-size="big"] .dd-list-item {
        grid-gap: 0.625vw;
        gap: 0.625vw;
        height: 2.70833vw;
        font-size: 0.9375vw;
        padding-inline: 12px 14px; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .dd-list[data-size="big"] .dd-list-item {
        grid-gap: 0.69444vw;
        gap: 0.69444vw;
        height: 3.00926vw;
        font-size: 1.04167vw;
        padding-inline: 12px 14px; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .dd-list[data-size="big"] .dd-list-item {
        grid-gap: 0.625vw;
        gap: 0.625vw;
        height: 2.70833vw;
        font-size: 0.9375vw;
        padding-inline: 12px 14px; } }

.dd-header-icon {
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
  .dd-header-icon.rotate {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

.dd-list-icon {
  object-fit: contain;
  width: 25px;
  height: 25px; }

.dd-list-item-selected {
  border-radius: 4px;
  background-color: #E6F7FD;
  z-index: -1;
  border: unset; }

.dropdown-item {
  display: flex;
  align-items: center; }

.dropdown-item-text {
  font-weight: 600;
  color: #504F4F;
  margin-left: 15px; }

.dropdown-item-text:hover {
  color: #00ADE6; }

.blockBased-side-navbar-container {
  height: calc(100vh - 4.58333vw);
  z-index: 999;
  pointer-events: none;
  width: 0vw;
  opacity: 0;
  visibility: hidden; }
  @media (min-width: 1300px) {
    .blockBased-side-navbar-container {
      width: 10.41667vw;
      opacity: 1;
      visibility: visible; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .blockBased-side-navbar-container {
      width: 10.41667vw;
      opacity: 1;
      visibility: visible; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .blockBased-side-navbar-container {
      width: 10.41667vw;
      opacity: 1;
      visibility: visible; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .blockBased-side-navbar-container else {
      width: 0vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .blockBased-side-navbar-container else {
      width: 0vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .blockBased-side-navbar-container else {
      width: 0vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .blockBased-side-navbar-container else {
      width: 0vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .blockBased-side-navbar-container else {
      width: 0vw; } }
  .blockBased-side-navbar-container::-webkit-scrollbar {
    max-width: 0.41667vw;
    display: flex; }

.blockBased-side-navbar-container-electron {
  height: calc(100vh - 4.58333vw - 36px); }

.additionalRenderer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box; }

.navbar {
  overflow: hidden;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  padding-left: 12px;
  padding-right: 12px;
  z-index: 998;
  box-sizing: border-box; }

.flashContainer {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.4); }

.nav-menu {
  background-color: white;
  background-image: url(/static/media/mobileSidebarBg.4c1e66dc.png);
  background-position: bottom;
  background-repeat: no-repeat;
  width: 270px;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  z-index: 999;
  left: -100%;
  transition: 850ms;
  display: flex;
  flex-direction: column; }

.nav-menu-items {
  width: 100%;
  margin-top: 20px; }

.nav-menu.active {
  left: 0;
  transition: 350ms; }

.arrow-back {
  height: 30px;
  width: 30px; }

.navbar-toggle {
  background-color: #060b26;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.updatedSidebar-heading {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  color: #00ADE6;
  margin-left: 15px; }

.hamburger-icon {
  width: 20px;
  height: 3px;
  background-color: #333333;
  margin-bottom: 3px;
  border-radius: 10px; }
  .hamburger-icon:nth-child(1) {
    margin-top: 3px; }

.front-bar {
  display: flex;
  flex-direction: row;
  align-items: center; }

.sidebar-top {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  align-items: center;
  margin-top: 10px;
  text-decoration: none; }

.blockBased-side-navbar-bgOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  z-index: -1;
  background: white; }

.sidebar-top-heading {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: #005773;
  margin-left: 10px;
  text-decoration: none; }

.sidebar-image {
  margin-right: 15px;
  margin-left: 15px; }
  .sidebar-image > svg {
    stroke: #A8A7A7;
    fill: white; }
  .sidebar-image > .docIcon {
    stroke: none;
    fill: #A8A7A7; }

.sidebar-image-available > svg {
  stroke: #005773;
  fill: white; }
  .sidebar-image-available > svg .pQpath:nth-child(3) {
    fill: #005773; }
  .sidebar-image-available > svg.docIcon > path {
    stroke: none;
    fill: #005773; }
  .sidebar-image-available > svg.hintIcon > path {
    stroke: none;
    fill: #005773; }
  .sidebar-image-available > svg.brushIcon > path {
    stroke: none;
    fill: #005773; }

.sidebar-image-active > svg {
  stroke: white;
  fill: #00ADE6; }
  .sidebar-image-active > svg .pQpath:nth-child(3) {
    fill: #FFF; }
  .sidebar-image-active > svg.docIcon > path {
    stroke: none;
    fill: #FFF; }
  .sidebar-image-active > svg.hintIcon > path {
    stroke: none;
    fill: #FFF; }
  .sidebar-image-active > svg.brushIcon > path {
    stroke: none;
    fill: #FFF; }

.active-sidebar-image {
  margin-left: 15px;
  margin-right: 15px; }

.losidebar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  pointer-events: none; }

.losidebar-available {
  pointer-events: auto; }

.sidebar-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0px 5px;
  text-decoration: none;
  -webkit-transform: scale(1);
          transform: scale(1);
  pointer-events: none; }

.sidebar-item-active {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #00ADE6;
  padding-top: 10px;
  color: white;
  width: 100%;
  pointer-events: auto; }
  .sidebar-item-active ~ .forward-arrow svg {
    fill: white; }

.forward-arrow {
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 4px;
  right: 0px;
  cursor: pointer;
  bottom: 50%;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  position: absolute; }

.forward-arrow-rotate {
  padding-left: 15px;
  padding-bottom: 15px;
  cursor: pointer;
  -webkit-transform: rotateZ(90deg);
          transform: rotateZ(90deg); }

.sub-menu {
  position: static;
  display: none;
  transition: ease-in; }

.sub-menu.show {
  display: flex;
  flex-direction: column;
  transition: 320ms; }

.active-sidebar-item {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  align-items: center;
  background-color: #00ADE6;
  padding-top: 10px;
  padding-bottom: 10px; }

.options {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: #A8A7A7;
  margin-bottom: 7px;
  pointer-events: none;
  width: 100%; }

.losidebar .options {
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.options-available {
  color: #005773;
  pointer-events: auto; }

.options-active {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  pointer-events: auto;
  color: #ffffff; }

.circle-background,
.circle-progress {
  fill: none; }

.circle-background {
  stroke: rgba(168, 167, 167, 0.2); }

.circle-progress {
  stroke: #00C187;
  stroke-linecap: square;
  stroke-linejoin: square; }

.progressIndicator-wh-40 {
  width: 5.31401vw !important;
  height: 5.31401vw !important; }
  @media (min-width: 1300px) {
    .progressIndicator-wh-40 {
      width: 1.82292vw !important;
      height: 1.82292vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .progressIndicator-wh-40 {
      width: 2.02546vw !important;
      height: 2.02546vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .progressIndicator-wh-40 {
      width: 1.82292vw !important;
      height: 1.82292vw !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .progressIndicator-wh-40 {
      width: 4.42834vw !important;
      height: 4.42834vw !important; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .progressIndicator-wh-40 {
      width: 3.54267vw !important;
      height: 3.54267vw !important; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .progressIndicator-wh-40 {
      width: 3.12589vw !important;
      height: 3.12589vw !important; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .progressIndicator-wh-40 {
      width: 2.79685vw !important;
      height: 2.79685vw !important; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .progressIndicator-wh-40 {
      width: 2.53048vw !important;
      height: 2.53048vw !important; } }

.progressIndicator-overall-progress {
  margin-top: 0;
  margin-left: 7.72947vw; }
  @media (min-width: 1300px) {
    .progressIndicator-overall-progress {
      margin-top: 3.02083vw;
      margin-left: 0; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .progressIndicator-overall-progress {
      margin-top: 3.35648vw;
      margin-left: 0; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .progressIndicator-overall-progress {
      margin-top: 3.02083vw;
      margin-left: 0; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .progressIndicator-overall-progress {
      margin-left: 6.44122vw; }
      .progressIndicator-overall-progress else {
        margin-top: 0; }
      .progressIndicator-overall-progress else {
        margin-left: 32px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .progressIndicator-overall-progress {
      margin-left: 5.15298vw; }
      .progressIndicator-overall-progress else {
        margin-top: 0; }
      .progressIndicator-overall-progress else {
        margin-left: 32px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .progressIndicator-overall-progress {
      margin-left: 4.54675vw; }
      .progressIndicator-overall-progress else {
        margin-top: 0; }
      .progressIndicator-overall-progress else {
        margin-left: 32px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .progressIndicator-overall-progress {
      margin-left: 4.06814vw; }
      .progressIndicator-overall-progress else {
        margin-top: 0; }
      .progressIndicator-overall-progress else {
        margin-left: 32px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .progressIndicator-overall-progress {
      margin-left: 3.6807vw; }
      .progressIndicator-overall-progress else {
        margin-top: 0; }
      .progressIndicator-overall-progress else {
        margin-left: 32px; } }

.progressIndicator-mainContainer {
  box-sizing: border-box;
  display: flex;
  margin: auto;
  top: 0;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 4.83092vw 2.89855vw;
  position: relative;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start; }
  @media (min-width: 1300px) {
    .progressIndicator-mainContainer {
      top: 1.04167vw;
      height: 100%;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding: 0vw 1.97917vw;
      position: -webkit-sticky;
      position: sticky;
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .progressIndicator-mainContainer {
      top: 1.15741vw;
      height: 100%;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding: 0vw 2.19907vw;
      position: -webkit-sticky;
      position: sticky;
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .progressIndicator-mainContainer {
      top: 1.04167vw;
      height: 100%;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding: 0vw 1.97917vw;
      position: -webkit-sticky;
      position: sticky;
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .progressIndicator-mainContainer {
      padding: 4.02576vw 2.41546vw; }
      .progressIndicator-mainContainer else {
        top: 0; }
      .progressIndicator-mainContainer else {
        width: 100%; }
      .progressIndicator-mainContainer else {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .progressIndicator-mainContainer {
      padding: 3.22061vw 1.93237vw; }
      .progressIndicator-mainContainer else {
        top: 0; }
      .progressIndicator-mainContainer else {
        width: 100%; }
      .progressIndicator-mainContainer else {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .progressIndicator-mainContainer {
      padding: 2.84172vw 1.70503vw; }
      .progressIndicator-mainContainer else {
        top: 0; }
      .progressIndicator-mainContainer else {
        width: 100%; }
      .progressIndicator-mainContainer else {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .progressIndicator-mainContainer {
      padding: 2.54259vw 1.52555vw; }
      .progressIndicator-mainContainer else {
        top: 0; }
      .progressIndicator-mainContainer else {
        width: 100%; }
      .progressIndicator-mainContainer else {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .progressIndicator-mainContainer {
      padding: 2.30044vw 1.38026vw; }
      .progressIndicator-mainContainer else {
        top: 0; }
      .progressIndicator-mainContainer else {
        width: 100%; }
      .progressIndicator-mainContainer else {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content; } }

.progressIndicator-individual-progress {
  box-sizing: border-box;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start; }
  @media (min-width: 1300px) {
    .progressIndicator-individual-progress {
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .progressIndicator-individual-progress {
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .progressIndicator-individual-progress {
      flex-direction: column;
      justify-content: center;
      align-items: center; } }

.progressIndicator-progress-bar {
  position: absolute;
  background: #ddd;
  z-index: -1;
  height: 1.93237vw;
  top: 50%;
  width: 100%; }
  @media (min-width: 1300px) {
    .progressIndicator-progress-bar {
      height: 100%;
      top: 0;
      width: 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .progressIndicator-progress-bar {
      height: 100%;
      top: 0;
      width: 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .progressIndicator-progress-bar {
      height: 100%;
      top: 0;
      width: 0.41667vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .progressIndicator-progress-bar {
      height: 1.61031vw; }
      .progressIndicator-progress-bar else {
        height: 8px; }
      .progressIndicator-progress-bar else {
        top: 50%; }
      .progressIndicator-progress-bar else {
        width: 100%; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .progressIndicator-progress-bar {
      height: 1.28824vw; }
      .progressIndicator-progress-bar else {
        height: 8px; }
      .progressIndicator-progress-bar else {
        top: 50%; }
      .progressIndicator-progress-bar else {
        width: 100%; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .progressIndicator-progress-bar {
      height: 1.13669vw; }
      .progressIndicator-progress-bar else {
        height: 8px; }
      .progressIndicator-progress-bar else {
        top: 50%; }
      .progressIndicator-progress-bar else {
        width: 100%; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .progressIndicator-progress-bar {
      height: 1.01704vw; }
      .progressIndicator-progress-bar else {
        height: 8px; }
      .progressIndicator-progress-bar else {
        top: 50%; }
      .progressIndicator-progress-bar else {
        width: 100%; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .progressIndicator-progress-bar {
      height: 0.92017vw; }
      .progressIndicator-progress-bar else {
        height: 8px; }
      .progressIndicator-progress-bar else {
        top: 50%; }
      .progressIndicator-progress-bar else {
        width: 100%; } }
  .progressIndicator-progress-bar .progressIndicator-progress-bar-active {
    position: absolute;
    background: #00ADE6;
    z-index: 1;
    top: 0;
    width: 0%;
    height: 1.93237vw; }
    @media (min-width: 1300px) {
      .progressIndicator-progress-bar .progressIndicator-progress-bar-active {
        height: 0%;
        width: 0.41667vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .progressIndicator-progress-bar .progressIndicator-progress-bar-active {
        height: 0%;
        width: 0.46296vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .progressIndicator-progress-bar .progressIndicator-progress-bar-active {
        height: 0%;
        width: 0.41667vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .progressIndicator-progress-bar .progressIndicator-progress-bar-active {
        height: 1.61031vw; }
        .progressIndicator-progress-bar .progressIndicator-progress-bar-active else {
          width: 0%; }
        .progressIndicator-progress-bar .progressIndicator-progress-bar-active else {
          height: 8px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .progressIndicator-progress-bar .progressIndicator-progress-bar-active {
        height: 1.28824vw; }
        .progressIndicator-progress-bar .progressIndicator-progress-bar-active else {
          width: 0%; }
        .progressIndicator-progress-bar .progressIndicator-progress-bar-active else {
          height: 8px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .progressIndicator-progress-bar .progressIndicator-progress-bar-active {
        height: 1.13669vw; }
        .progressIndicator-progress-bar .progressIndicator-progress-bar-active else {
          width: 0%; }
        .progressIndicator-progress-bar .progressIndicator-progress-bar-active else {
          height: 8px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .progressIndicator-progress-bar .progressIndicator-progress-bar-active {
        height: 1.01704vw; }
        .progressIndicator-progress-bar .progressIndicator-progress-bar-active else {
          width: 0%; }
        .progressIndicator-progress-bar .progressIndicator-progress-bar-active else {
          height: 8px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .progressIndicator-progress-bar .progressIndicator-progress-bar-active {
        height: 0.92017vw; }
        .progressIndicator-progress-bar .progressIndicator-progress-bar-active else {
          width: 0%; }
        .progressIndicator-progress-bar .progressIndicator-progress-bar-active else {
          height: 8px; } }

.progressIndicator-icon-ProgressCircle {
  background: #eeeded;
  padding: 6px;
  border-radius: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  pointer-events: none;
  z-index: 9;
  transition: 0.1s all ease-in-out;
  margin-bottom: 0;
  margin-right: 6.76329vw; }
  @media (min-width: 1300px) {
    .progressIndicator-icon-ProgressCircle {
      margin-bottom: 1.66667vw;
      margin-right: 0vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .progressIndicator-icon-ProgressCircle {
      margin-bottom: 1.85185vw;
      margin-right: 0vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .progressIndicator-icon-ProgressCircle {
      margin-bottom: 1.66667vw;
      margin-right: 0vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .progressIndicator-icon-ProgressCircle {
      margin-right: 5.63607vw; }
      .progressIndicator-icon-ProgressCircle else {
        margin-bottom: 0; }
      .progressIndicator-icon-ProgressCircle else {
        margin-right: 28px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .progressIndicator-icon-ProgressCircle {
      margin-right: 4.50886vw; }
      .progressIndicator-icon-ProgressCircle else {
        margin-bottom: 0; }
      .progressIndicator-icon-ProgressCircle else {
        margin-right: 28px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .progressIndicator-icon-ProgressCircle {
      margin-right: 3.9784vw; }
      .progressIndicator-icon-ProgressCircle else {
        margin-bottom: 0; }
      .progressIndicator-icon-ProgressCircle else {
        margin-right: 28px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .progressIndicator-icon-ProgressCircle {
      margin-right: 3.55962vw; }
      .progressIndicator-icon-ProgressCircle else {
        margin-bottom: 0; }
      .progressIndicator-icon-ProgressCircle else {
        margin-right: 28px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .progressIndicator-icon-ProgressCircle {
      margin-right: 3.22061vw; }
      .progressIndicator-icon-ProgressCircle else {
        margin-bottom: 0; }
      .progressIndicator-icon-ProgressCircle else {
        margin-right: 28px; } }
  .progressIndicator-icon-ProgressCircle:hover > .loOverlayMenu {
    opacity: 1;
    width: auto;
    visibility: visible; }

.progressIndicator-icon-locked {
  z-index: 9; }

.progressIndicator-icon-ProgressCircle:nth-last-child(2) {
  margin-right: 0; }
  @media (min-width: 1300px) {
    .progressIndicator-icon-ProgressCircle:nth-last-child(2) {
      margin-bottom: 0; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .progressIndicator-icon-ProgressCircle:nth-last-child(2) {
      margin-bottom: 0; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .progressIndicator-icon-ProgressCircle:nth-last-child(2) {
      margin-bottom: 0; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .progressIndicator-icon-ProgressCircle:nth-last-child(2) else {
      margin-right: 0; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .progressIndicator-icon-ProgressCircle:nth-last-child(2) else {
      margin-right: 0; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .progressIndicator-icon-ProgressCircle:nth-last-child(2) else {
      margin-right: 0; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .progressIndicator-icon-ProgressCircle:nth-last-child(2) else {
      margin-right: 0; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .progressIndicator-icon-ProgressCircle:nth-last-child(2) else {
      margin-right: 0; } }

.progressIndicator-icon-container {
  background-color: #A8A7A7;
  padding: 10px;
  border-radius: 100%;
  border: 4px solid #FFF;
  z-index: 1; }

.progressIndicator-icon {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  align-self: flex-start;
  transition: all .5s;
  width: 4.34783vw;
  height: 4.34783vw; }
  @media (min-width: 1300px) {
    .progressIndicator-icon {
      width: 1.25vw;
      height: 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .progressIndicator-icon {
      width: 1.38889vw;
      height: 1.38889vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .progressIndicator-icon {
      width: 1.25vw;
      height: 1.25vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .progressIndicator-icon {
      width: 3.62319vw;
      height: 3.62319vw; }
      .progressIndicator-icon else {
        width: 18px; }
      .progressIndicator-icon else {
        height: 18px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .progressIndicator-icon {
      width: 2.89855vw;
      height: 2.89855vw; }
      .progressIndicator-icon else {
        width: 18px; }
      .progressIndicator-icon else {
        height: 18px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .progressIndicator-icon {
      width: 2.55754vw;
      height: 2.55754vw; }
      .progressIndicator-icon else {
        width: 18px; }
      .progressIndicator-icon else {
        height: 18px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .progressIndicator-icon {
      width: 2.28833vw;
      height: 2.28833vw; }
      .progressIndicator-icon else {
        width: 18px; }
      .progressIndicator-icon else {
        height: 18px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .progressIndicator-icon {
      width: 2.07039vw;
      height: 2.07039vw; }
      .progressIndicator-icon else {
        width: 18px; }
      .progressIndicator-icon else {
        height: 18px; } }

.progressIndicator-completed {
  background: linear-gradient(180deg, #35e4e9 -8.62%, #00ade6 109.2%);
  pointer-events: all;
  cursor: pointer; }
  .progressIndicator-completed > .progressIndicator-icon-container {
    background-color: #00ADE6; }

.progressIndicator-active {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  pointer-events: all;
  background: #bff4f8; }
  .progressIndicator-active > .lo-component-cicle-stretched-rightArrow {
    background-color: #00ADE6 !important; }
  .progressIndicator-active > .progressIndicator-icon-container {
    background-color: #00ADE6; }

.progressIndicator-active-completed {
  background: linear-gradient(100deg, #35e4e9 -8.62%, #00ade6 109.2%); }
  .progressIndicator-active-completed > .lo-component-cicle-stretched-rightArrow {
    background-color: #FFF !important; }

.overallProgressIndicator-success {
  background-color: #00ADE6 !important; }

.loOverlayMenu {
  background: rgba(0, 173, 230, 0.1);
  border-radius: 8px 50px 50px 8px;
  width: 0%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  opacity: 0;
  visibility: hidden;
  padding: 0px 10px 0px 40px; }

.updated-input-tooltip.cn-tooltip {
  background: #005773 !important;
  box-shadow: 0px 2px 4px rgba(65, 87, 102, 0.12), 0px 4px 10px rgba(65, 87, 102, 0.1);
  color: #FFF !important;
  padding: 8px 16px !important;
  opacity: 1 !important;
  text-align: center !important;
  font-weight: bold !important; }
  @media (min-width: 1300px) {
    .updated-input-tooltip.cn-tooltip {
      font-size: 0.9375vw !important;
      border-radius: 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .updated-input-tooltip.cn-tooltip {
      font-size: 1.04167vw !important;
      border-radius: 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .updated-input-tooltip.cn-tooltip {
      font-size: 0.9375vw !important;
      border-radius: 0.41667vw; } }

.updated-input-tooltip.cn-sub-tooltip {
  background: #00ADE6 !important;
  white-space: nowrap;
  box-shadow: 0px 2px 4px rgba(65, 87, 102, 0.12), 0px 4px 10px rgba(65, 87, 102, 0.1) !important; }

.lo-component-cicle-stretched {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0px;
  z-index: -10;
  height: 100%;
  -webkit-transform: rotate(315deg);
          transform: rotate(315deg);
  background: inherit;
  border-radius: 100%;
  border-bottom-right-radius: 40%; }

.lo-component-cicle-stretched-rightArrow {
  -webkit-mask-image: url(/static/media/dropdownArrow.9dab8a2f.svg);
  mask-image: url(/static/media/dropdownArrow.9dab8a2f.svg);
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-position: center;
          mask-position: center;
  background-color: #FFF;
  width: 10px;
  height: 10px;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  position: absolute;
  top: 40%;
  right: -6px; }

.sidebar-loader-card {
  width: 100%;
  height: 100%; }
  @media (min-width: 1300px) {
    .sidebar-loader-card .sidebar-loader-1 {
      y: 13.02083vw;
      x: 2.60417vw;
      height: 4.6875vw;
      width: 4.6875vw;
      rx: 100px; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sidebar-loader-card .sidebar-loader-1 {
      y: 14.46759vw;
      x: 2.89352vw;
      height: 5.20833vw;
      width: 5.20833vw;
      rx: 100px; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sidebar-loader-card .sidebar-loader-1 {
      y: 13.02083vw;
      x: 2.60417vw;
      height: 4.6875vw;
      width: 4.6875vw;
      rx: 100px; } }
  @media (min-width: 1300px) {
    .sidebar-loader-card .sidebar-loader-2 {
      y: 18.75vw;
      x: 2.60417vw;
      height: 4.6875vw;
      width: 4.6875vw;
      rx: 100px; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sidebar-loader-card .sidebar-loader-2 {
      y: 20.83333vw;
      x: 2.89352vw;
      height: 5.20833vw;
      width: 5.20833vw;
      rx: 100px; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sidebar-loader-card .sidebar-loader-2 {
      y: 18.75vw;
      x: 2.60417vw;
      height: 4.6875vw;
      width: 4.6875vw;
      rx: 100px; } }
  @media (min-width: 1300px) {
    .sidebar-loader-card .sidebar-loader-3 {
      y: 24.47917vw;
      x: 2.60417vw;
      height: 4.6875vw;
      width: 4.6875vw;
      rx: 100px; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sidebar-loader-card .sidebar-loader-3 {
      y: 27.19907vw;
      x: 2.89352vw;
      height: 5.20833vw;
      width: 5.20833vw;
      rx: 100px; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sidebar-loader-card .sidebar-loader-3 {
      y: 24.47917vw;
      x: 2.60417vw;
      height: 4.6875vw;
      width: 4.6875vw;
      rx: 100px; } }

.sidebar-LoTitle {
  background: #005773;
  border-radius: 8px 8px 0px 0px;
  position: absolute;
  bottom: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #EEE;
  max-width: 50%;
  padding: 4px 12px;
  font-size: 8px;
  text-align: center;
  font-weight: bold; }

.photon-label {
  font-family: Nunito;
  color: #504F4F;
  color: rgba(17, 17, 17, 0.48);
  opacity: 0.7;
  font-size: 2.96296vw;
  margin-top: 2.22222vw;
  margin-bottom: 1.85185vw; }
  .photon-label.error {
    opacity: 1;
    color: #FF5744; }
  @media (min-width: 1300px) {
    .photon-label {
      font-size: 0.83333vw;
      margin-bottom: 0.52083vw;
      margin-top: unset; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-label {
      font-size: 0.92593vw;
      margin-bottom: 0.5787vw;
      margin-top: unset; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-label {
      font-size: 0.83333vw;
      margin-bottom: 0.52083vw;
      margin-top: unset; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .photon-label {
      font-size: 2.46914vw;
      margin-top: 1.85185vw;
      margin-bottom: 1.54321vw; }
      .photon-label else {
        font-size: 16px; }
      .photon-label else {
        margin-top: 12px; }
      .photon-label else {
        margin-bottom: 10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .photon-label {
      font-size: 1.97531vw;
      margin-top: 1.48148vw;
      margin-bottom: 1.23457vw; }
      .photon-label else {
        font-size: 16px; }
      .photon-label else {
        margin-top: 12px; }
      .photon-label else {
        margin-bottom: 10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .photon-label {
      font-size: 1.74292vw;
      margin-top: 1.30719vw;
      margin-bottom: 1.08932vw; }
      .photon-label else {
        font-size: 16px; }
      .photon-label else {
        margin-top: 12px; }
      .photon-label else {
        margin-bottom: 10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .photon-label {
      font-size: 1.55945vw;
      margin-top: 1.16959vw;
      margin-bottom: 0.97466vw; }
      .photon-label else {
        font-size: 16px; }
      .photon-label else {
        margin-top: 12px; }
      .photon-label else {
        margin-bottom: 10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .photon-label {
      font-size: 1.41093vw;
      margin-top: 1.0582vw;
      margin-bottom: 0.88183vw; }
      .photon-label else {
        font-size: 16px; }
      .photon-label else {
        margin-top: 12px; }
      .photon-label else {
        margin-bottom: 10px; } }

.photon-input-tooltip {
  background-color: #bff7f9 !important;
  font-weight: 600 !important; }
  @media (min-width: 1300px) {
    .photon-input-tooltip {
      font-size: 0.83333vw !important;
      padding-left: 0.52083vw !important;
      padding-right: 0.52083vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-input-tooltip {
      font-size: 0.92593vw !important;
      padding-left: 0.5787vw !important;
      padding-right: 0.5787vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-input-tooltip {
      font-size: 0.83333vw !important;
      padding-left: 0.52083vw !important;
      padding-right: 0.52083vw !important; } }

.photon-input {
  width: 100% !important;
  background: #FFFFFF !important;
  font-family: Nunito !important;
  box-sizing: border-box !important;
  font-weight: 600 !important;
  transition: 0.3s all ease-in-out;
  border: 0.18519vw solid #C4C4C4 !important;
  border-radius: 1.11111vw !important;
  height: 10.37037vw !important;
  padding: 2.96296vw !important;
  font-size: 3.7037vw !important;
  width: 100% !important;
  color: #504F4F !important; }
  @media (min-width: 1300px) {
    .photon-input {
      border: 0.05208vw solid #C4C4C4 !important;
      border-radius: 0.3125vw !important;
      height: 2.91667vw !important;
      padding: 0.83333vw !important;
      font-size: 1.04167vw !important;
      width: 100% !important;
      color: #504F4F !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-input {
      border: 0.05787vw solid #C4C4C4 !important;
      border-radius: 0.34722vw !important;
      height: 3.24074vw !important;
      padding: 0.92593vw !important;
      font-size: 1.15741vw !important;
      width: 100% !important;
      color: #504F4F !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-input {
      border: 0.05208vw solid #C4C4C4 !important;
      border-radius: 0.3125vw !important;
      height: 2.91667vw !important;
      padding: 0.83333vw !important;
      font-size: 1.04167vw !important;
      width: 100% !important;
      color: #504F4F !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .photon-input {
      border: 0.15432vw solid #C4C4C4 !important;
      border-radius: 0.92593vw !important;
      height: 8.64198vw !important;
      padding: 2.46914vw !important;
      font-size: 3.08642vw !important;
      width: 100% !important; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .photon-input {
      border: 0.12346vw solid #C4C4C4 !important;
      border-radius: 0.74074vw !important;
      height: 6.91358vw !important;
      padding: 1.97531vw !important;
      font-size: 2.46914vw !important;
      width: 100% !important; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .photon-input {
      border: 0.10893vw solid #C4C4C4 !important;
      border-radius: 0.65359vw !important;
      height: 6.10022vw !important;
      padding: 1.74292vw !important;
      font-size: 2.17865vw !important;
      width: 100% !important; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .photon-input {
      border: 0.09747vw solid #C4C4C4 !important;
      border-radius: 0.5848vw !important;
      height: 5.45809vw !important;
      padding: 1.55945vw !important;
      font-size: 1.94932vw !important;
      width: 100% !important; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .photon-input {
      border: 0.08818vw solid #C4C4C4 !important;
      border-radius: 0.5291vw !important;
      height: 4.93827vw !important;
      padding: 1.41093vw !important;
      font-size: 1.76367vw !important;
      width: 100% !important; } }
  .photon-input.error {
    border: 0.18519vw solid #FF5744 !important; }
    @media (min-width: 1300px) {
      .photon-input.error {
        border: 0.05208vw solid #FF5744 !important; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .photon-input.error {
        border: 0.05787vw solid #FF5744 !important; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .photon-input.error {
        border: 0.05208vw solid #FF5744 !important; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .photon-input.error {
        border: 0.15432vw solid #FF5744 !important; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .photon-input.error {
        border: 0.12346vw solid #FF5744 !important; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .photon-input.error {
        border: 0.10893vw solid #FF5744 !important; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .photon-input.error {
        border: 0.09747vw solid #FF5744 !important; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .photon-input.error {
        border: 0.08818vw solid #FF5744 !important; } }
  .photon-input.disabled {
    opacity: 0.6;
    cursor: not-allowed; }
  .photon-input:focus {
    box-shadow: 0 0 0 1px #00ADE6 !important;
    border: 1px solid transparent !important;
    outline: none !important; }
  .photon-input:focus-visible {
    box-shadow: 0 0 0 1px #00ADE6 !important;
    border: 1px solid transparent !important;
    outline: none !important; }
  .photon-input::-webkit-input-placeholder {
    color: rgba(17, 17, 17, 0.48) !important;
    opacity: 0.7 !important; }
  .photon-input::placeholder {
    color: rgba(17, 17, 17, 0.48) !important;
    opacity: 0.7 !important; }

.photon-phone-input {
  margin-left: 11.83575vw !important;
  width: 87% !important;
  border-radius: 0 1.44928vw 1.44928vw 0 !important;
  z-index: 2 !important; }
  @media (min-width: 1300px) {
    .photon-phone-input {
      margin-left: 3.59375vw !important;
      width: 83.5% !important;
      border-radius: 0 0.3125vw 0.3125vw 0 !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-phone-input {
      margin-left: 3.99306vw !important;
      width: 83.5% !important;
      border-radius: 0 0.34722vw 0.34722vw 0 !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-phone-input {
      margin-left: 3.59375vw !important;
      width: 83.5% !important;
      border-radius: 0 0.3125vw 0.3125vw 0 !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .photon-phone-input {
      margin-left: 9.86312vw !important;
      width: 87% !important;
      border-radius: 0 1.20773vw 1.20773vw 0 !important; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .photon-phone-input {
      margin-left: 7.8905vw !important;
      width: 87% !important;
      border-radius: 0 0.96618vw 0.96618vw 0 !important; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .photon-phone-input {
      margin-left: 6.96221vw !important;
      width: 87% !important;
      border-radius: 0 0.85251vw 0.85251vw 0 !important; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .photon-phone-input {
      margin-left: 6.22934vw !important;
      width: 87% !important;
      border-radius: 0 0.76278vw 0.76278vw 0 !important; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .photon-phone-input {
      margin-left: 5.63607vw !important;
      width: 87% !important;
      border-radius: 0 0.69013vw 0.69013vw 0 !important; } }
  @media (min-width: 1300px) {
    .photon-phone-input:focus, .photon-phone-input:focus-visible {
      border-radius: 0.3125vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-phone-input:focus, .photon-phone-input:focus-visible {
      border-radius: 0.34722vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-phone-input:focus, .photon-phone-input:focus-visible {
      border-radius: 0.3125vw !important; } }

.photon-otp-container .photon-otp-input {
  width: 10.37037vw !important;
  margin-right: 4.44444vw; }
  @media (min-width: 1300px) {
    .photon-otp-container .photon-otp-input {
      width: 2.91667vw !important;
      margin-right: 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-otp-container .photon-otp-input {
      width: 3.24074vw !important;
      margin-right: 1.38889vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-otp-container .photon-otp-input {
      width: 2.91667vw !important;
      margin-right: 1.25vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .photon-otp-container .photon-otp-input {
      width: 8.64198vw !important;
      margin-right: 3.7037vw; }
      .photon-otp-container .photon-otp-input else {
        margin-right: 24px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .photon-otp-container .photon-otp-input {
      width: 6.91358vw !important;
      margin-right: 2.96296vw; }
      .photon-otp-container .photon-otp-input else {
        margin-right: 24px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .photon-otp-container .photon-otp-input {
      width: 6.10022vw !important;
      margin-right: 2.61438vw; }
      .photon-otp-container .photon-otp-input else {
        margin-right: 24px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .photon-otp-container .photon-otp-input {
      width: 5.45809vw !important;
      margin-right: 2.33918vw; }
      .photon-otp-container .photon-otp-input else {
        margin-right: 24px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .photon-otp-container .photon-otp-input {
      width: 4.93827vw !important;
      margin-right: 2.1164vw; }
      .photon-otp-container .photon-otp-input else {
        margin-right: 24px; } }

@media (min-width: 1300px) {
  .photon-otp-container:last-child .photon-otp-input {
    margin-right: 0vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .photon-otp-container:last-child .photon-otp-input {
    margin-right: 0vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .photon-otp-container:last-child .photon-otp-input {
    margin-right: 0vw; } }

.photon-button {
  background: #00ADE6;
  font-family: Nunito;
  font-weight: 600;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 2.96296vw 3.33333vw;
  border-radius: 0.74074vw;
  font-family: 22px;
  color: white;
  transition: 0.3s all ease-in-out; }
  @media (min-width: 1300px) {
    .photon-button {
      padding: 0.83333vw 0.9375vw;
      border-radius: 0.20833vw;
      font-family: 22px; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-button {
      padding: 0.92593vw 1.04167vw;
      border-radius: 0.23148vw;
      font-family: 22px; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-button {
      padding: 0.83333vw 0.9375vw;
      border-radius: 0.20833vw;
      font-family: 22px; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .photon-button {
      padding: 2.46914vw 2.77778vw;
      border-radius: 0.61728vw; }
      .photon-button else {
        border-radius: 4px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .photon-button {
      padding: 1.97531vw 2.22222vw;
      border-radius: 0.49383vw; }
      .photon-button else {
        border-radius: 4px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .photon-button {
      padding: 1.74292vw 1.96078vw;
      border-radius: 0.43573vw; }
      .photon-button else {
        border-radius: 4px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .photon-button {
      padding: 1.55945vw 1.75439vw;
      border-radius: 0.38986vw; }
      .photon-button else {
        border-radius: 4px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .photon-button {
      padding: 1.41093vw 1.5873vw;
      border-radius: 0.35273vw; }
      .photon-button else {
        border-radius: 4px; } }
  .photon-button div:first-child {
    pointer-events: none; }
  .photon-button:hover {
    opacity: 0.8; }
  .photon-button:hover .photon-button-arrowContainer {
    -webkit-transform: scale(-1) translateX(-3px);
            transform: scale(-1) translateX(-3px); }
  .photon-button.disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed; }

.photon-button-arrowContainer {
  width: 1.94444vw;
  height: 2.77778vw;
  margin-left: 1.85185vw;
  transition: 0.3s all ease-in-out;
  background: url(/static/media/arrow.fa095bde.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transform: scale(-1);
          transform: scale(-1); }
  @media (min-width: 1300px) {
    .photon-button-arrowContainer {
      width: 0.54688vw;
      height: 0.78125vw;
      margin-left: 0.67708vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-button-arrowContainer {
      width: 0.60764vw;
      height: 0.86806vw;
      margin-left: 0.75231vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-button-arrowContainer {
      width: 0.54688vw;
      height: 0.78125vw;
      margin-left: 0.67708vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .photon-button-arrowContainer {
      width: 1.62037vw;
      height: 2.31481vw;
      margin-left: 1.54321vw; }
      .photon-button-arrowContainer else {
        width: 10.5px; }
      .photon-button-arrowContainer else {
        height: 15px; }
      .photon-button-arrowContainer else {
        margin-left: 10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .photon-button-arrowContainer {
      width: 1.2963vw;
      height: 1.85185vw;
      margin-left: 1.23457vw; }
      .photon-button-arrowContainer else {
        width: 10.5px; }
      .photon-button-arrowContainer else {
        height: 15px; }
      .photon-button-arrowContainer else {
        margin-left: 10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .photon-button-arrowContainer {
      width: 1.14379vw;
      height: 1.63399vw;
      margin-left: 1.08932vw; }
      .photon-button-arrowContainer else {
        width: 10.5px; }
      .photon-button-arrowContainer else {
        height: 15px; }
      .photon-button-arrowContainer else {
        margin-left: 10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .photon-button-arrowContainer {
      width: 1.02339vw;
      height: 1.46199vw;
      margin-left: 0.97466vw; }
      .photon-button-arrowContainer else {
        width: 10.5px; }
      .photon-button-arrowContainer else {
        height: 15px; }
      .photon-button-arrowContainer else {
        margin-left: 10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .photon-button-arrowContainer {
      width: 0.92593vw;
      height: 1.32275vw;
      margin-left: 0.88183vw; }
      .photon-button-arrowContainer else {
        width: 10.5px; }
      .photon-button-arrowContainer else {
        height: 15px; }
      .photon-button-arrowContainer else {
        margin-left: 10px; } }

.photon-link {
  color: #00ADE6;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  font-family: Nunito;
  cursor: pointer;
  font-size: 3.88889vw;
  font-weight: normal; }
  @media (min-width: 1300px) {
    .photon-link {
      font-size: 0.83333vw;
      font-weight: bold; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-link {
      font-size: 0.92593vw;
      font-weight: bold; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-link {
      font-size: 0.83333vw;
      font-weight: bold; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .photon-link {
      font-size: 3.24074vw; }
      .photon-link else {
        font-size: 21px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .photon-link {
      font-size: 2.59259vw; }
      .photon-link else {
        font-size: 21px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .photon-link {
      font-size: 2.28758vw; }
      .photon-link else {
        font-size: 21px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .photon-link {
      font-size: 2.04678vw; }
      .photon-link else {
        font-size: 21px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .photon-link {
      font-size: 1.85185vw; }
      .photon-link else {
        font-size: 21px; } }

.photon-input-password {
  position: relative;
  letter-spacing: 1.69082vw;
  padding-right: 10.86957vw !important; }
  @media (min-width: 1300px) {
    .photon-input-password {
      letter-spacing: 0.52083vw;
      padding-right: 2.60417vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-input-password {
      letter-spacing: 0.5787vw;
      padding-right: 2.89352vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-input-password {
      letter-spacing: 0.52083vw;
      padding-right: 2.60417vw !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .photon-input-password {
      letter-spacing: 1.40902vw;
      padding-right: 9.05797vw !important; }
      .photon-input-password else {
        letter-spacing: 7px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .photon-input-password {
      letter-spacing: 1.12721vw;
      padding-right: 7.24638vw !important; }
      .photon-input-password else {
        letter-spacing: 7px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .photon-input-password {
      letter-spacing: 0.9946vw;
      padding-right: 6.39386vw !important; }
      .photon-input-password else {
        letter-spacing: 7px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .photon-input-password {
      letter-spacing: 0.88991vw;
      padding-right: 5.72082vw !important; }
      .photon-input-password else {
        letter-spacing: 7px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .photon-input-password {
      letter-spacing: 0.80515vw;
      padding-right: 5.17598vw !important; }
      .photon-input-password else {
        letter-spacing: 7px; } }

.photon-input-password-eye-icon {
  position: absolute;
  opacity: 0.5;
  right: 0;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  top: 1.93237vw;
  letter-spacing: 2.41546vw;
  width: 6.28019vw;
  height: 6.28019vw;
  right: 2.41546vw; }
  .photon-input-password-eye-icon:hover {
    opacity: 0.8; }
  @media (min-width: 1300px) {
    .photon-input-password-eye-icon {
      top: 0.78125vw;
      letter-spacing: 0.52083vw;
      width: 1.45833vw;
      height: 1.45833vw;
      right: 0.52083vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-input-password-eye-icon {
      top: 0.86806vw;
      letter-spacing: 0.5787vw;
      width: 1.62037vw;
      height: 1.62037vw;
      right: 0.5787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-input-password-eye-icon {
      top: 0.78125vw;
      letter-spacing: 0.52083vw;
      width: 1.45833vw;
      height: 1.45833vw;
      right: 0.52083vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .photon-input-password-eye-icon {
      top: 1.61031vw;
      letter-spacing: 2.01288vw;
      width: 5.23349vw;
      height: 5.23349vw;
      right: 2.01288vw; }
      .photon-input-password-eye-icon else {
        top: 8px; }
      .photon-input-password-eye-icon else {
        letter-spacing: 10px; }
      .photon-input-password-eye-icon else {
        width: 26px; }
      .photon-input-password-eye-icon else {
        height: 26px; }
      .photon-input-password-eye-icon else {
        right: 10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .photon-input-password-eye-icon {
      top: 1.28824vw;
      letter-spacing: 1.61031vw;
      width: 4.1868vw;
      height: 4.1868vw;
      right: 1.61031vw; }
      .photon-input-password-eye-icon else {
        top: 8px; }
      .photon-input-password-eye-icon else {
        letter-spacing: 10px; }
      .photon-input-password-eye-icon else {
        width: 26px; }
      .photon-input-password-eye-icon else {
        height: 26px; }
      .photon-input-password-eye-icon else {
        right: 10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .photon-input-password-eye-icon {
      top: 1.13669vw;
      letter-spacing: 1.42086vw;
      width: 3.69423vw;
      height: 3.69423vw;
      right: 1.42086vw; }
      .photon-input-password-eye-icon else {
        top: 8px; }
      .photon-input-password-eye-icon else {
        letter-spacing: 10px; }
      .photon-input-password-eye-icon else {
        width: 26px; }
      .photon-input-password-eye-icon else {
        height: 26px; }
      .photon-input-password-eye-icon else {
        right: 10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .photon-input-password-eye-icon {
      top: 1.01704vw;
      letter-spacing: 1.27129vw;
      width: 3.30536vw;
      height: 3.30536vw;
      right: 1.27129vw; }
      .photon-input-password-eye-icon else {
        top: 8px; }
      .photon-input-password-eye-icon else {
        letter-spacing: 10px; }
      .photon-input-password-eye-icon else {
        width: 26px; }
      .photon-input-password-eye-icon else {
        height: 26px; }
      .photon-input-password-eye-icon else {
        right: 10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .photon-input-password-eye-icon {
      top: 0.92017vw;
      letter-spacing: 1.15022vw;
      width: 2.99057vw;
      height: 2.99057vw;
      right: 1.15022vw; }
      .photon-input-password-eye-icon else {
        top: 8px; }
      .photon-input-password-eye-icon else {
        letter-spacing: 10px; }
      .photon-input-password-eye-icon else {
        width: 26px; }
      .photon-input-password-eye-icon else {
        height: 26px; }
      .photon-input-password-eye-icon else {
        right: 10px; } }

.photon-phone-button {
  font-family: Nunito !important;
  background-color: white !important;
  transition: 0.3s all ease-in-out;
  width: 11.11111vw;
  font-size: 2.96296vw;
  border-right: 1px solid #C4C4C4; }
  @media (min-width: 1300px) {
    .photon-phone-button {
      width: 3.64583vw;
      font-size: 0.9375vw;
      border-right: 1px solid #C4C4C4; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-phone-button {
      width: 4.05093vw;
      font-size: 1.04167vw;
      border-right: 1px solid #C4C4C4; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-phone-button {
      width: 3.64583vw;
      font-size: 0.9375vw;
      border-right: 1px solid #C4C4C4; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .photon-phone-button {
      width: 9.25926vw;
      font-size: 2.46914vw; }
      .photon-phone-button else {
        width: 60px; }
      .photon-phone-button else {
        font-size: 16px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .photon-phone-button {
      width: 7.40741vw;
      font-size: 1.97531vw; }
      .photon-phone-button else {
        width: 60px; }
      .photon-phone-button else {
        font-size: 16px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .photon-phone-button {
      width: 6.53595vw;
      font-size: 1.74292vw; }
      .photon-phone-button else {
        width: 60px; }
      .photon-phone-button else {
        font-size: 16px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .photon-phone-button {
      width: 5.84795vw;
      font-size: 1.55945vw; }
      .photon-phone-button else {
        width: 60px; }
      .photon-phone-button else {
        font-size: 16px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .photon-phone-button {
      width: 5.29101vw;
      font-size: 1.41093vw; }
      .photon-phone-button else {
        width: 60px; }
      .photon-phone-button else {
        font-size: 16px; } }
  .photon-phone-button.error {
    border: 1px solid #FF5744 !important; }
  .photon-phone-button:hover {
    background-color: rgba(0, 173, 229, 0.2) !important; }
  .photon-phone-button .react-tel-input {
    font-family: 'Lato', sans-serif;
    width: 100% !important; }
  .photon-phone-button .selected-flag {
    width: 100% !important; }
    @media (min-width: 1300px) {
      .photon-phone-button .selected-flag {
        padding-left: 0.9375vw !important; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .photon-phone-button .selected-flag {
        padding-left: 1.04167vw !important; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .photon-phone-button .selected-flag {
        padding-left: 0.9375vw !important; } }
    .photon-phone-button .selected-flag:hover {
      background: transparent !important; }
  .photon-phone-button .arrow {
    opacity: 0.4; }
  .photon-phone-button .selected-flag.open {
    background: transparent !important; }
  .photon-phone-button .country-list {
    background-color: #fff !important;
    overflow-y: auto !important; }
  .photon-phone-button .flag-dropdown:focus {
    outline: none; }
  .photon-phone-button .country {
    color: #222 !important;
    -webkit-user-select: none;
            user-select: none; }
    .photon-phone-button .country:hover {
      background-color: #fff !important;
      background-color: rgba(6, 179, 230, 0.3) !important; }
    .photon-phone-button .country:focus {
      outline: none; }
  .photon-phone-button .country.highlight {
    background-color: rgba(6, 179, 230, 0.7) !important;
    color: white !important;
    font-weight: 700;
    border: unset !important; }
    .photon-phone-button .country.highlight .dial-code {
      color: white !important; }
  .photon-phone-button .icon, .photon-phone-button .flag.in, .photon-phone-button .flag.ru, .photon-phone-button .flag.af, .photon-phone-button .flag.au, .photon-phone-button .flag.sg, .photon-phone-button .flag.us, .photon-phone-button .flag.gb, .photon-phone-button .flag.ae {
    background-repeat: no-repeat !important; }
  .photon-phone-button .flag.in {
    background-image: url(/static/media/in.bdfaaf97.svg) !important;
    background-position: 0 0 !important; }
  .photon-phone-button .flag.ru {
    background-image: url(/static/media/ru.e2037a0e.svg) !important;
    background-position: 0 0 !important; }
  .photon-phone-button .flag.af {
    background-image: url(/static/media/af.d8fb0d22.svg) !important;
    background-position: 0 0 !important; }
  .photon-phone-button .flag.au {
    background-image: url(/static/media/au.e875a51e.svg) !important;
    background-position: 0 0 !important; }
  .photon-phone-button .flag.sg {
    background-image: url(/static/media/sg.6ae2dc5b.svg) !important;
    background-position: 0 0 !important; }
  .photon-phone-button .flag.us {
    background-image: url(/static/media/us.50162f60.svg) !important;
    background-position: 0 0 !important; }
  .photon-phone-button .flag.gb {
    background-image: url(/static/media/gb.c39480d5.svg) !important;
    background-position: 0 0 !important; }
  .photon-phone-button .flag.ae {
    background-image: url(/static/media/ae.6d497f1c.svg) !important;
    background-position: 0 0 !important; }

.photon-button-3d-shadow {
  box-shadow: inset 0px 0px 8px #34E4EA;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition: 0.3s all ease-in-out;
  box-shadow: inset 0vw 0vw 1.0306vw #34E4EA;
  border-radius: 13vw;
  background: #0C718E;
  z-index: 98; }
  .photon-button-3d-shadow.disabled {
    box-shadow: none;
    background: #A8A7A7;
    cursor: default; }
    @media (min-width: 1300px) {
      .photon-button-3d-shadow.disabled {
        box-shadow: none;
        background: #A8A7A7; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .photon-button-3d-shadow.disabled {
        box-shadow: none;
        background: #A8A7A7; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .photon-button-3d-shadow.disabled {
        box-shadow: none;
        background: #A8A7A7; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .photon-button-3d-shadow.disabled else {
        box-shadow: none; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .photon-button-3d-shadow.disabled else {
        box-shadow: none; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .photon-button-3d-shadow.disabled else {
        box-shadow: none; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .photon-button-3d-shadow.disabled else {
        box-shadow: none; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .photon-button-3d-shadow.disabled else {
        box-shadow: none; } }
    .photon-button-3d-shadow.disabled:hover .photon-button-3d-inner-container {
      box-shadow: inset 0px 0px 5.56522px #A8A7A7; }
  .photon-button-3d-shadow:hover .photon-button-3d-inner-container {
    box-shadow: 0px 0px 30px rgba(53, 201, 233, 0.5), inset 0px 0px 8px rgba(0, 255, 240, 0.8), 0px 0px 15px rgba(0, 255, 240, 0.8); }
  .photon-button-3d-shadow:active .photon-button-3d-inner-container {
    -webkit-transform: translateY(-2vw);
            transform: translateY(-2vw); }
    @media (min-width: 1300px) {
      .photon-button-3d-shadow:active .photon-button-3d-inner-container {
        -webkit-transform: translateY(-4px);
                transform: translateY(-4px); } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .photon-button-3d-shadow:active .photon-button-3d-inner-container {
        -webkit-transform: translateY(-4px);
                transform: translateY(-4px); } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .photon-button-3d-shadow:active .photon-button-3d-inner-container {
        -webkit-transform: translateY(-4px);
                transform: translateY(-4px); } }
  @media (min-width: 1300px) {
    .photon-button-3d-shadow {
      border-radius: 2.08333vw;
      height: 3.17708vw;
      margin-top: 0.41667vw;
      background: #0C718E; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-button-3d-shadow {
      border-radius: 2.31481vw;
      height: 3.53009vw;
      margin-top: 0.46296vw;
      background: #0C718E; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-button-3d-shadow {
      border-radius: 2.08333vw;
      height: 3.17708vw;
      margin-top: 0.41667vw;
      background: #0C718E; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .photon-button-3d-shadow {
      box-shadow: inset 0vw 0vw 0.85883vw #34E4EA; }
      .photon-button-3d-shadow else {
        border-radius: 13vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .photon-button-3d-shadow {
      box-shadow: inset 0vw 0vw 0.68706vw #34E4EA; }
      .photon-button-3d-shadow else {
        border-radius: 13vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .photon-button-3d-shadow {
      box-shadow: inset 0vw 0vw 0.60623vw #34E4EA; }
      .photon-button-3d-shadow else {
        border-radius: 13vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .photon-button-3d-shadow {
      box-shadow: inset 0vw 0vw 0.54242vw #34E4EA; }
      .photon-button-3d-shadow else {
        border-radius: 13vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .photon-button-3d-shadow {
      box-shadow: inset 0vw 0vw 0.49076vw #34E4EA; }
      .photon-button-3d-shadow else {
        border-radius: 13vw; } }

.photon-button-3d-inner-container {
  background: conic-gradient(from -3.29deg at 100% -13%, #35E4E9 0deg, #00ADE6 360deg);
  box-shadow: 0px 0px 20.8696px rgba(53, 201, 233, 0.5), inset 0px 0px 5.56522px rgba(0, 255, 240, 0.8);
  color: #ffffff;
  display: flex;
  align-items: center;
  transition: 0.3s box-shadow ease-in-out, 0.3s width ease-in-out, 0.1s -webkit-transform ease-in-out;
  transition: 0.1s transform ease-in-out, 0.3s box-shadow ease-in-out, 0.3s width ease-in-out;
  transition: 0.1s transform ease-in-out, 0.3s box-shadow ease-in-out, 0.3s width ease-in-out, 0.1s -webkit-transform ease-in-out;
  text-shadow: 0px 1.391px 0px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border-radius: 13vw;
  padding: 0 6vw;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 12vw;
  background: conic-gradient(from -3.29deg at 100% -13%, #35E4E9 0deg, #00ADE6 360deg);
  text-align: center;
  font-weight: bold;
  -webkit-transform: translateY(-6px);
          transform: translateY(-6px); }
  .photon-button-3d-inner-container.disabled {
    background: #DCDCDC;
    color: #A8A7A7;
    box-shadow: none;
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    text-shadow: none; }
  .photon-button-3d-inner-container.transform {
    font-size: 3.7vw;
    text-transform: uppercase; }
    @media (min-width: 1300px) {
      .photon-button-3d-inner-container.transform {
        font-size: initial;
        text-transform: initial; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .photon-button-3d-inner-container.transform {
        font-size: initial;
        text-transform: initial; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .photon-button-3d-inner-container.transform {
        font-size: initial;
        text-transform: initial; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .photon-button-3d-inner-container.transform else {
        font-size: 3.7vw; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .photon-button-3d-inner-container.transform else {
        font-size: 3.7vw; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .photon-button-3d-inner-container.transform else {
        font-size: 3.7vw; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .photon-button-3d-inner-container.transform else {
        font-size: 3.7vw; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .photon-button-3d-inner-container.transform else {
        font-size: 3.7vw; } }
  @media (min-width: 1300px) {
    .photon-button-3d-inner-container {
      border-radius: 2.08333vw;
      width: initial;
      height: 3.17708vw;
      padding: 0.83333vw 1.25vw;
      font-size: 1.09375vw;
      font-weight: 900;
      background: conic-gradient(from -3.29deg at 100% -13%, #35E4E9 0deg, #00ADE6 360deg);
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-button-3d-inner-container {
      border-radius: 2.31481vw;
      width: initial;
      height: 3.53009vw;
      padding: 0.92593vw 1.38889vw;
      font-size: 1.21528vw;
      font-weight: 900;
      background: conic-gradient(from -3.29deg at 100% -13%, #35E4E9 0deg, #00ADE6 360deg);
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-button-3d-inner-container {
      border-radius: 2.08333vw;
      width: initial;
      height: 3.17708vw;
      padding: 0.83333vw 1.25vw;
      font-size: 1.09375vw;
      font-weight: 900;
      background: conic-gradient(from -3.29deg at 100% -13%, #35E4E9 0deg, #00ADE6 360deg);
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px); } }
  @media (min-width: 450px) and (max-width: 500px) {
    .photon-button-3d-inner-container {
      padding: 0 6vw; }
      .photon-button-3d-inner-container else {
        border-radius: 13vw; }
      .photon-button-3d-inner-container else {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content; }
      .photon-button-3d-inner-container else {
        height: 12vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .photon-button-3d-inner-container {
      padding: 0 6vw; }
      .photon-button-3d-inner-container else {
        border-radius: 13vw; }
      .photon-button-3d-inner-container else {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content; }
      .photon-button-3d-inner-container else {
        height: 12vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .photon-button-3d-inner-container {
      padding: 0 6vw; }
      .photon-button-3d-inner-container else {
        border-radius: 13vw; }
      .photon-button-3d-inner-container else {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content; }
      .photon-button-3d-inner-container else {
        height: 12vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .photon-button-3d-inner-container {
      padding: 0 6vw; }
      .photon-button-3d-inner-container else {
        border-radius: 13vw; }
      .photon-button-3d-inner-container else {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content; }
      .photon-button-3d-inner-container else {
        height: 12vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .photon-button-3d-inner-container {
      padding: 0 6vw; }
      .photon-button-3d-inner-container else {
        border-radius: 13vw; }
      .photon-button-3d-inner-container else {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content; }
      .photon-button-3d-inner-container else {
        height: 12vw; } }

.photon-button-loading,
.photon-button-loading:after {
  border-radius: 50%;
  width: 1.2em;
  height: 1.2em; }

.photon-button-loading {
  font-size: 6px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  margin-right: 2vw;
  margin-left: -4vw; }
  @media (min-width: 1300px) {
    .photon-button-loading {
      margin-right: 0.36458vw;
      margin-left: initial; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-button-loading {
      margin-right: 0.40509vw;
      margin-left: initial; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-button-loading {
      margin-right: 0.36458vw;
      margin-left: initial; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .photon-button-loading else {
      margin-right: 2vw; }
    .photon-button-loading else {
      margin-left: -4vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .photon-button-loading else {
      margin-right: 2vw; }
    .photon-button-loading else {
      margin-left: -4vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .photon-button-loading else {
      margin-right: 2vw; }
    .photon-button-loading else {
      margin-left: -4vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .photon-button-loading else {
      margin-right: 2vw; }
    .photon-button-loading else {
      margin-left: -4vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .photon-button-loading else {
      margin-right: 2vw; }
    .photon-button-loading else {
      margin-left: -4vw; } }

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.mentor-label {
  font-family: Nunito;
  color: #DCDCDC;
  color: rgba(17, 17, 17, 0.48);
  opacity: 0.7;
  font-size: 2.96296vw;
  margin-top: 2.22222vw;
  margin-bottom: 1.85185vw; }
  .mentor-label.error {
    opacity: 1;
    color: #FF5744; }
  @media (min-width: 1300px) {
    .mentor-label {
      font-size: 0.83333vw;
      margin-bottom: 0.52083vw;
      margin-top: unset; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-label {
      font-size: 0.92593vw;
      margin-bottom: 0.5787vw;
      margin-top: unset; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-label {
      font-size: 0.83333vw;
      margin-bottom: 0.52083vw;
      margin-top: unset; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .mentor-label {
      font-size: 2.46914vw;
      margin-top: 1.85185vw;
      margin-bottom: 1.54321vw; }
      .mentor-label else {
        font-size: 16px; }
      .mentor-label else {
        margin-top: 12px; }
      .mentor-label else {
        margin-bottom: 10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .mentor-label {
      font-size: 1.97531vw;
      margin-top: 1.48148vw;
      margin-bottom: 1.23457vw; }
      .mentor-label else {
        font-size: 16px; }
      .mentor-label else {
        margin-top: 12px; }
      .mentor-label else {
        margin-bottom: 10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .mentor-label {
      font-size: 1.74292vw;
      margin-top: 1.30719vw;
      margin-bottom: 1.08932vw; }
      .mentor-label else {
        font-size: 16px; }
      .mentor-label else {
        margin-top: 12px; }
      .mentor-label else {
        margin-bottom: 10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .mentor-label {
      font-size: 1.55945vw;
      margin-top: 1.16959vw;
      margin-bottom: 0.97466vw; }
      .mentor-label else {
        font-size: 16px; }
      .mentor-label else {
        margin-top: 12px; }
      .mentor-label else {
        margin-bottom: 10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .mentor-label {
      font-size: 1.41093vw;
      margin-top: 1.0582vw;
      margin-bottom: 0.88183vw; }
      .mentor-label else {
        font-size: 16px; }
      .mentor-label else {
        margin-top: 12px; }
      .mentor-label else {
        margin-bottom: 10px; } }

.mentor-input-tooltip {
  background-color: #bff7f9 !important;
  font-weight: 600 !important; }
  @media (min-width: 1300px) {
    .mentor-input-tooltip {
      font-size: 0.83333vw !important;
      padding-left: 0.52083vw !important;
      padding-right: 0.52083vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-input-tooltip {
      font-size: 0.92593vw !important;
      padding-left: 0.5787vw !important;
      padding-right: 0.5787vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-input-tooltip {
      font-size: 0.83333vw !important;
      padding-left: 0.52083vw !important;
      padding-right: 0.52083vw !important; } }

.mentor-input {
  width: 100% !important;
  background: #FFFFFF !important;
  font-family: Nunito !important;
  box-sizing: border-box !important;
  font-weight: 600 !important;
  transition: 0.3s all ease-in-out;
  border: 0.18519vw solid #C4C4C4 !important;
  border-radius: 1.11111vw !important;
  height: 10.37037vw !important;
  padding: 2.96296vw !important;
  font-size: 3.7037vw !important;
  width: 100% !important;
  color: #504F4F !important; }
  @media (min-width: 1300px) {
    .mentor-input {
      border: 0.05208vw solid #C4C4C4 !important;
      border-radius: 0.3125vw !important;
      height: 2.91667vw !important;
      padding: 0.83333vw !important;
      font-size: 1.04167vw !important;
      width: 100% !important;
      color: #504F4F !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-input {
      border: 0.05787vw solid #C4C4C4 !important;
      border-radius: 0.34722vw !important;
      height: 3.24074vw !important;
      padding: 0.92593vw !important;
      font-size: 1.15741vw !important;
      width: 100% !important;
      color: #504F4F !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-input {
      border: 0.05208vw solid #C4C4C4 !important;
      border-radius: 0.3125vw !important;
      height: 2.91667vw !important;
      padding: 0.83333vw !important;
      font-size: 1.04167vw !important;
      width: 100% !important;
      color: #504F4F !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .mentor-input {
      border: 0.15432vw solid #C4C4C4 !important;
      border-radius: 0.92593vw !important;
      height: 8.64198vw !important;
      padding: 2.46914vw !important;
      font-size: 3.08642vw !important;
      width: 100% !important; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .mentor-input {
      border: 0.12346vw solid #C4C4C4 !important;
      border-radius: 0.74074vw !important;
      height: 6.91358vw !important;
      padding: 1.97531vw !important;
      font-size: 2.46914vw !important;
      width: 100% !important; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .mentor-input {
      border: 0.10893vw solid #C4C4C4 !important;
      border-radius: 0.65359vw !important;
      height: 6.10022vw !important;
      padding: 1.74292vw !important;
      font-size: 2.17865vw !important;
      width: 100% !important; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .mentor-input {
      border: 0.09747vw solid #C4C4C4 !important;
      border-radius: 0.5848vw !important;
      height: 5.45809vw !important;
      padding: 1.55945vw !important;
      font-size: 1.94932vw !important;
      width: 100% !important; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .mentor-input {
      border: 0.08818vw solid #C4C4C4 !important;
      border-radius: 0.5291vw !important;
      height: 4.93827vw !important;
      padding: 1.41093vw !important;
      font-size: 1.76367vw !important;
      width: 100% !important; } }
  .mentor-input.error {
    border: 0.18519vw solid #FF5744 !important; }
    @media (min-width: 1300px) {
      .mentor-input.error {
        border: 0.05208vw solid #FF5744 !important; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .mentor-input.error {
        border: 0.05787vw solid #FF5744 !important; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .mentor-input.error {
        border: 0.05208vw solid #FF5744 !important; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .mentor-input.error {
        border: 0.15432vw solid #FF5744 !important; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .mentor-input.error {
        border: 0.12346vw solid #FF5744 !important; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .mentor-input.error {
        border: 0.10893vw solid #FF5744 !important; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .mentor-input.error {
        border: 0.09747vw solid #FF5744 !important; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .mentor-input.error {
        border: 0.08818vw solid #FF5744 !important; } }
  .mentor-input.disabled {
    opacity: 0.6;
    cursor: not-allowed; }
  .mentor-input:focus {
    box-shadow: 0 0 0 1px #00ADE6 !important;
    border: 1px solid transparent !important;
    outline: none !important; }
  .mentor-input:focus-visible {
    box-shadow: 0 0 0 1px #00ADE6 !important;
    border: 1px solid transparent !important;
    outline: none !important; }
  .mentor-input::-webkit-input-placeholder {
    color: rgba(17, 17, 17, 0.48) !important;
    opacity: 0.7 !important; }
  .mentor-input::placeholder {
    color: rgba(17, 17, 17, 0.48) !important;
    opacity: 0.7 !important; }

.mentor-phone-input {
  margin-left: 11.83575vw !important;
  width: 87% !important;
  border-radius: 0 1.44928vw 1.44928vw 0 !important;
  z-index: 2 !important; }
  @media (min-width: 1300px) {
    .mentor-phone-input {
      margin-left: 3.59375vw !important;
      width: 83.5% !important;
      border-radius: 0 0.3125vw 0.3125vw 0 !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-phone-input {
      margin-left: 3.99306vw !important;
      width: 83.5% !important;
      border-radius: 0 0.34722vw 0.34722vw 0 !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-phone-input {
      margin-left: 3.59375vw !important;
      width: 83.5% !important;
      border-radius: 0 0.3125vw 0.3125vw 0 !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .mentor-phone-input {
      margin-left: 9.86312vw !important;
      width: 87% !important;
      border-radius: 0 1.20773vw 1.20773vw 0 !important; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .mentor-phone-input {
      margin-left: 7.8905vw !important;
      width: 87% !important;
      border-radius: 0 0.96618vw 0.96618vw 0 !important; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .mentor-phone-input {
      margin-left: 6.96221vw !important;
      width: 87% !important;
      border-radius: 0 0.85251vw 0.85251vw 0 !important; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .mentor-phone-input {
      margin-left: 6.22934vw !important;
      width: 87% !important;
      border-radius: 0 0.76278vw 0.76278vw 0 !important; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .mentor-phone-input {
      margin-left: 5.63607vw !important;
      width: 87% !important;
      border-radius: 0 0.69013vw 0.69013vw 0 !important; } }
  @media (min-width: 1300px) {
    .mentor-phone-input:focus, .mentor-phone-input:focus-visible {
      border-radius: 0.3125vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-phone-input:focus, .mentor-phone-input:focus-visible {
      border-radius: 0.34722vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-phone-input:focus, .mentor-phone-input:focus-visible {
      border-radius: 0.3125vw !important; } }

.mentor-otp-container .mentor-otp-input {
  width: 10.37037vw !important;
  margin-right: 4.44444vw; }
  @media (min-width: 1300px) {
    .mentor-otp-container .mentor-otp-input {
      width: 2.91667vw !important;
      margin-right: 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-otp-container .mentor-otp-input {
      width: 3.24074vw !important;
      margin-right: 1.38889vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-otp-container .mentor-otp-input {
      width: 2.91667vw !important;
      margin-right: 1.25vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .mentor-otp-container .mentor-otp-input {
      width: 8.64198vw !important;
      margin-right: 3.7037vw; }
      .mentor-otp-container .mentor-otp-input else {
        margin-right: 24px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .mentor-otp-container .mentor-otp-input {
      width: 6.91358vw !important;
      margin-right: 2.96296vw; }
      .mentor-otp-container .mentor-otp-input else {
        margin-right: 24px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .mentor-otp-container .mentor-otp-input {
      width: 6.10022vw !important;
      margin-right: 2.61438vw; }
      .mentor-otp-container .mentor-otp-input else {
        margin-right: 24px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .mentor-otp-container .mentor-otp-input {
      width: 5.45809vw !important;
      margin-right: 2.33918vw; }
      .mentor-otp-container .mentor-otp-input else {
        margin-right: 24px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .mentor-otp-container .mentor-otp-input {
      width: 4.93827vw !important;
      margin-right: 2.1164vw; }
      .mentor-otp-container .mentor-otp-input else {
        margin-right: 24px; } }

@media (min-width: 1300px) {
  .mentor-otp-container:last-child .mentor-otp-input {
    margin-right: 0vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .mentor-otp-container:last-child .mentor-otp-input {
    margin-right: 0vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .mentor-otp-container:last-child .mentor-otp-input {
    margin-right: 0vw; } }

.mentor-button {
  background: #00ADE6;
  font-family: Nunito;
  font-weight: 600;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 2.96296vw 3.33333vw;
  border-radius: 0.74074vw;
  font-family: 22px;
  color: white;
  transition: 0.3s all ease-in-out; }
  @media (min-width: 1300px) {
    .mentor-button {
      padding: 0.83333vw 0.9375vw;
      border-radius: 0.20833vw;
      font-family: 22px; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-button {
      padding: 0.92593vw 1.04167vw;
      border-radius: 0.23148vw;
      font-family: 22px; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-button {
      padding: 0.83333vw 0.9375vw;
      border-radius: 0.20833vw;
      font-family: 22px; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .mentor-button {
      padding: 2.46914vw 2.77778vw;
      border-radius: 0.61728vw; }
      .mentor-button else {
        border-radius: 4px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .mentor-button {
      padding: 1.97531vw 2.22222vw;
      border-radius: 0.49383vw; }
      .mentor-button else {
        border-radius: 4px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .mentor-button {
      padding: 1.74292vw 1.96078vw;
      border-radius: 0.43573vw; }
      .mentor-button else {
        border-radius: 4px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .mentor-button {
      padding: 1.55945vw 1.75439vw;
      border-radius: 0.38986vw; }
      .mentor-button else {
        border-radius: 4px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .mentor-button {
      padding: 1.41093vw 1.5873vw;
      border-radius: 0.35273vw; }
      .mentor-button else {
        border-radius: 4px; } }
  .mentor-button div:first-child {
    pointer-events: none; }
  .mentor-button:hover {
    opacity: 0.8; }
  .mentor-button:hover .mentor-button-arrowContainer {
    -webkit-transform: scale(-1) translateX(-3px);
            transform: scale(-1) translateX(-3px); }
  .mentor-button.disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed; }

.mentor-button-arrowContainer {
  width: 1.94444vw;
  height: 2.77778vw;
  margin-left: 1.85185vw;
  transition: 0.3s all ease-in-out;
  background: url(/static/media/arrow.fa095bde.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transform: scale(-1);
          transform: scale(-1); }
  @media (min-width: 1300px) {
    .mentor-button-arrowContainer {
      width: 0.54688vw;
      height: 0.78125vw;
      margin-left: 0.67708vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-button-arrowContainer {
      width: 0.60764vw;
      height: 0.86806vw;
      margin-left: 0.75231vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-button-arrowContainer {
      width: 0.54688vw;
      height: 0.78125vw;
      margin-left: 0.67708vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .mentor-button-arrowContainer {
      width: 1.62037vw;
      height: 2.31481vw;
      margin-left: 1.54321vw; }
      .mentor-button-arrowContainer else {
        width: 10.5px; }
      .mentor-button-arrowContainer else {
        height: 15px; }
      .mentor-button-arrowContainer else {
        margin-left: 10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .mentor-button-arrowContainer {
      width: 1.2963vw;
      height: 1.85185vw;
      margin-left: 1.23457vw; }
      .mentor-button-arrowContainer else {
        width: 10.5px; }
      .mentor-button-arrowContainer else {
        height: 15px; }
      .mentor-button-arrowContainer else {
        margin-left: 10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .mentor-button-arrowContainer {
      width: 1.14379vw;
      height: 1.63399vw;
      margin-left: 1.08932vw; }
      .mentor-button-arrowContainer else {
        width: 10.5px; }
      .mentor-button-arrowContainer else {
        height: 15px; }
      .mentor-button-arrowContainer else {
        margin-left: 10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .mentor-button-arrowContainer {
      width: 1.02339vw;
      height: 1.46199vw;
      margin-left: 0.97466vw; }
      .mentor-button-arrowContainer else {
        width: 10.5px; }
      .mentor-button-arrowContainer else {
        height: 15px; }
      .mentor-button-arrowContainer else {
        margin-left: 10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .mentor-button-arrowContainer {
      width: 0.92593vw;
      height: 1.32275vw;
      margin-left: 0.88183vw; }
      .mentor-button-arrowContainer else {
        width: 10.5px; }
      .mentor-button-arrowContainer else {
        height: 15px; }
      .mentor-button-arrowContainer else {
        margin-left: 10px; } }

.mentor-link {
  color: #00ADE6;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  font-family: Nunito;
  cursor: pointer;
  font-size: 3.88889vw;
  font-weight: normal; }
  @media (min-width: 1300px) {
    .mentor-link {
      font-size: 0.83333vw;
      font-weight: bold; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-link {
      font-size: 0.92593vw;
      font-weight: bold; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-link {
      font-size: 0.83333vw;
      font-weight: bold; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .mentor-link {
      font-size: 3.24074vw; }
      .mentor-link else {
        font-size: 21px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .mentor-link {
      font-size: 2.59259vw; }
      .mentor-link else {
        font-size: 21px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .mentor-link {
      font-size: 2.28758vw; }
      .mentor-link else {
        font-size: 21px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .mentor-link {
      font-size: 2.04678vw; }
      .mentor-link else {
        font-size: 21px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .mentor-link {
      font-size: 1.85185vw; }
      .mentor-link else {
        font-size: 21px; } }

.mentor-input-password {
  position: relative;
  letter-spacing: 1.69082vw;
  padding-right: 10.86957vw !important; }
  @media (min-width: 1300px) {
    .mentor-input-password {
      letter-spacing: 0.52083vw;
      padding-right: 2.60417vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-input-password {
      letter-spacing: 0.5787vw;
      padding-right: 2.89352vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-input-password {
      letter-spacing: 0.52083vw;
      padding-right: 2.60417vw !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .mentor-input-password {
      letter-spacing: 1.40902vw;
      padding-right: 9.05797vw !important; }
      .mentor-input-password else {
        letter-spacing: 7px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .mentor-input-password {
      letter-spacing: 1.12721vw;
      padding-right: 7.24638vw !important; }
      .mentor-input-password else {
        letter-spacing: 7px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .mentor-input-password {
      letter-spacing: 0.9946vw;
      padding-right: 6.39386vw !important; }
      .mentor-input-password else {
        letter-spacing: 7px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .mentor-input-password {
      letter-spacing: 0.88991vw;
      padding-right: 5.72082vw !important; }
      .mentor-input-password else {
        letter-spacing: 7px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .mentor-input-password {
      letter-spacing: 0.80515vw;
      padding-right: 5.17598vw !important; }
      .mentor-input-password else {
        letter-spacing: 7px; } }

.mentor-input-password-eye-icon {
  position: absolute;
  opacity: 0.5;
  right: 0;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  top: 1.93237vw;
  letter-spacing: 2.41546vw;
  width: 6.28019vw;
  height: 6.28019vw;
  right: 2.41546vw; }
  .mentor-input-password-eye-icon:hover {
    opacity: 0.8; }
  @media (min-width: 1300px) {
    .mentor-input-password-eye-icon {
      top: 0.78125vw;
      letter-spacing: 0.52083vw;
      width: 1.45833vw;
      height: 1.45833vw;
      right: 0.52083vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-input-password-eye-icon {
      top: 0.86806vw;
      letter-spacing: 0.5787vw;
      width: 1.62037vw;
      height: 1.62037vw;
      right: 0.5787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-input-password-eye-icon {
      top: 0.78125vw;
      letter-spacing: 0.52083vw;
      width: 1.45833vw;
      height: 1.45833vw;
      right: 0.52083vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .mentor-input-password-eye-icon {
      top: 1.61031vw;
      letter-spacing: 2.01288vw;
      width: 5.23349vw;
      height: 5.23349vw;
      right: 2.01288vw; }
      .mentor-input-password-eye-icon else {
        top: 8px; }
      .mentor-input-password-eye-icon else {
        letter-spacing: 10px; }
      .mentor-input-password-eye-icon else {
        width: 26px; }
      .mentor-input-password-eye-icon else {
        height: 26px; }
      .mentor-input-password-eye-icon else {
        right: 10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .mentor-input-password-eye-icon {
      top: 1.28824vw;
      letter-spacing: 1.61031vw;
      width: 4.1868vw;
      height: 4.1868vw;
      right: 1.61031vw; }
      .mentor-input-password-eye-icon else {
        top: 8px; }
      .mentor-input-password-eye-icon else {
        letter-spacing: 10px; }
      .mentor-input-password-eye-icon else {
        width: 26px; }
      .mentor-input-password-eye-icon else {
        height: 26px; }
      .mentor-input-password-eye-icon else {
        right: 10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .mentor-input-password-eye-icon {
      top: 1.13669vw;
      letter-spacing: 1.42086vw;
      width: 3.69423vw;
      height: 3.69423vw;
      right: 1.42086vw; }
      .mentor-input-password-eye-icon else {
        top: 8px; }
      .mentor-input-password-eye-icon else {
        letter-spacing: 10px; }
      .mentor-input-password-eye-icon else {
        width: 26px; }
      .mentor-input-password-eye-icon else {
        height: 26px; }
      .mentor-input-password-eye-icon else {
        right: 10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .mentor-input-password-eye-icon {
      top: 1.01704vw;
      letter-spacing: 1.27129vw;
      width: 3.30536vw;
      height: 3.30536vw;
      right: 1.27129vw; }
      .mentor-input-password-eye-icon else {
        top: 8px; }
      .mentor-input-password-eye-icon else {
        letter-spacing: 10px; }
      .mentor-input-password-eye-icon else {
        width: 26px; }
      .mentor-input-password-eye-icon else {
        height: 26px; }
      .mentor-input-password-eye-icon else {
        right: 10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .mentor-input-password-eye-icon {
      top: 0.92017vw;
      letter-spacing: 1.15022vw;
      width: 2.99057vw;
      height: 2.99057vw;
      right: 1.15022vw; }
      .mentor-input-password-eye-icon else {
        top: 8px; }
      .mentor-input-password-eye-icon else {
        letter-spacing: 10px; }
      .mentor-input-password-eye-icon else {
        width: 26px; }
      .mentor-input-password-eye-icon else {
        height: 26px; }
      .mentor-input-password-eye-icon else {
        right: 10px; } }

.mentor-phone-button {
  font-family: Nunito !important;
  background-color: white !important;
  transition: 0.3s all ease-in-out;
  width: 11.11111vw;
  font-size: 2.96296vw;
  border-right: 1px solid #C4C4C4; }
  @media (min-width: 1300px) {
    .mentor-phone-button {
      width: 3.64583vw;
      font-size: 0.9375vw;
      border-right: 1px solid #C4C4C4; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-phone-button {
      width: 4.05093vw;
      font-size: 1.04167vw;
      border-right: 1px solid #C4C4C4; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-phone-button {
      width: 3.64583vw;
      font-size: 0.9375vw;
      border-right: 1px solid #C4C4C4; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .mentor-phone-button {
      width: 9.25926vw;
      font-size: 2.46914vw; }
      .mentor-phone-button else {
        width: 60px; }
      .mentor-phone-button else {
        font-size: 16px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .mentor-phone-button {
      width: 7.40741vw;
      font-size: 1.97531vw; }
      .mentor-phone-button else {
        width: 60px; }
      .mentor-phone-button else {
        font-size: 16px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .mentor-phone-button {
      width: 6.53595vw;
      font-size: 1.74292vw; }
      .mentor-phone-button else {
        width: 60px; }
      .mentor-phone-button else {
        font-size: 16px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .mentor-phone-button {
      width: 5.84795vw;
      font-size: 1.55945vw; }
      .mentor-phone-button else {
        width: 60px; }
      .mentor-phone-button else {
        font-size: 16px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .mentor-phone-button {
      width: 5.29101vw;
      font-size: 1.41093vw; }
      .mentor-phone-button else {
        width: 60px; }
      .mentor-phone-button else {
        font-size: 16px; } }
  .mentor-phone-button.error {
    border: 1px solid #FF5744 !important; }
  .mentor-phone-button:hover {
    background-color: rgba(0, 173, 229, 0.2) !important; }
  .mentor-phone-button .react-tel-input {
    font-family: 'Lato', sans-serif;
    width: 100% !important; }
  .mentor-phone-button .selected-flag {
    width: 100% !important; }
    @media (min-width: 1300px) {
      .mentor-phone-button .selected-flag {
        padding-left: 0.9375vw !important; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .mentor-phone-button .selected-flag {
        padding-left: 1.04167vw !important; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .mentor-phone-button .selected-flag {
        padding-left: 0.9375vw !important; } }
    .mentor-phone-button .selected-flag:hover {
      background: transparent !important; }
  .mentor-phone-button .arrow {
    opacity: 0.4; }
  .mentor-phone-button .selected-flag.open {
    background: transparent !important; }
  .mentor-phone-button .country-list {
    background-color: #fff !important;
    overflow-y: auto !important; }
  .mentor-phone-button .flag-dropdown:focus {
    outline: none; }
  .mentor-phone-button .country {
    color: #222 !important;
    -webkit-user-select: none;
            user-select: none; }
    .mentor-phone-button .country:hover {
      background-color: #fff !important;
      background-color: rgba(6, 179, 230, 0.3) !important; }
    .mentor-phone-button .country:focus {
      outline: none; }
  .mentor-phone-button .country.highlight {
    background-color: rgba(6, 179, 230, 0.7) !important;
    color: white !important;
    font-weight: 700;
    border: unset !important; }
    .mentor-phone-button .country.highlight .dial-code {
      color: white !important; }
  .mentor-phone-button .icon, .mentor-phone-button .flag.in, .mentor-phone-button .flag.ru, .mentor-phone-button .flag.af, .mentor-phone-button .flag.au, .mentor-phone-button .flag.sg, .mentor-phone-button .flag.us, .mentor-phone-button .flag.gb, .mentor-phone-button .flag.ae {
    background-repeat: no-repeat !important; }
  .mentor-phone-button .flag.in {
    background-image: url(/static/media/in.bdfaaf97.svg) !important;
    background-position: 0 0 !important; }
  .mentor-phone-button .flag.ru {
    background-image: url(/static/media/ru.e2037a0e.svg) !important;
    background-position: 0 0 !important; }
  .mentor-phone-button .flag.af {
    background-image: url(/static/media/af.d8fb0d22.svg) !important;
    background-position: 0 0 !important; }
  .mentor-phone-button .flag.au {
    background-image: url(/static/media/au.e875a51e.svg) !important;
    background-position: 0 0 !important; }
  .mentor-phone-button .flag.sg {
    background-image: url(/static/media/sg.6ae2dc5b.svg) !important;
    background-position: 0 0 !important; }
  .mentor-phone-button .flag.us {
    background-image: url(/static/media/us.50162f60.svg) !important;
    background-position: 0 0 !important; }
  .mentor-phone-button .flag.gb {
    background-image: url(/static/media/gb.c39480d5.svg) !important;
    background-position: 0 0 !important; }
  .mentor-phone-button .flag.ae {
    background-image: url(/static/media/ae.6d497f1c.svg) !important;
    background-position: 0 0 !important; }

.mentor-button-3d-shadow {
  box-shadow: inset 0px 0px 8px #34E4EA;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition: 0.3s all ease-in-out;
  border-radius: 7.40741vw;
  height: 11.2963vw;
  margin-top: 1.48148vw;
  background: #0C718E; }
  .mentor-button-3d-shadow.disabled {
    box-shadow: none;
    background: #A8A7A7;
    cursor: default; }
    @media (min-width: 1300px) {
      .mentor-button-3d-shadow.disabled {
        box-shadow: none;
        background: #A8A7A7; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .mentor-button-3d-shadow.disabled {
        box-shadow: none;
        background: #A8A7A7; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .mentor-button-3d-shadow.disabled {
        box-shadow: none;
        background: #A8A7A7; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .mentor-button-3d-shadow.disabled else {
        box-shadow: none; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .mentor-button-3d-shadow.disabled else {
        box-shadow: none; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .mentor-button-3d-shadow.disabled else {
        box-shadow: none; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .mentor-button-3d-shadow.disabled else {
        box-shadow: none; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .mentor-button-3d-shadow.disabled else {
        box-shadow: none; } }
    .mentor-button-3d-shadow.disabled:hover .mentor-button-3d-inner-container {
      box-shadow: none; }
  .mentor-button-3d-shadow:hover .mentor-button-3d-inner-container {
    box-shadow: 0px 0px 30px rgba(53, 201, 233, 0.5), inset 0px 0px 8px rgba(0, 255, 240, 0.8), 0px 0px 15px rgba(0, 255, 240, 0.8); }
  .mentor-button-3d-shadow:active .mentor-button-3d-inner-container {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px); }
    @media (min-width: 1300px) {
      .mentor-button-3d-shadow:active .mentor-button-3d-inner-container {
        -webkit-transform: translateY(-4px);
                transform: translateY(-4px); } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .mentor-button-3d-shadow:active .mentor-button-3d-inner-container {
        -webkit-transform: translateY(-4px);
                transform: translateY(-4px); } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .mentor-button-3d-shadow:active .mentor-button-3d-inner-container {
        -webkit-transform: translateY(-4px);
                transform: translateY(-4px); } }
  @media (min-width: 1300px) {
    .mentor-button-3d-shadow {
      border-radius: 2.08333vw;
      height: 3.17708vw;
      margin-top: 0.41667vw;
      background: #0C718E; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-button-3d-shadow {
      border-radius: 2.31481vw;
      height: 3.53009vw;
      margin-top: 0.46296vw;
      background: #0C718E; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-button-3d-shadow {
      border-radius: 2.08333vw;
      height: 3.17708vw;
      margin-top: 0.41667vw;
      background: #0C718E; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .mentor-button-3d-shadow {
      border-radius: 6.17284vw;
      height: 9.41358vw;
      margin-top: 1.23457vw; }
      .mentor-button-3d-shadow else {
        border-radius: 40px; }
      .mentor-button-3d-shadow else {
        height: 61px; }
      .mentor-button-3d-shadow else {
        margin-top: 8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .mentor-button-3d-shadow {
      border-radius: 4.93827vw;
      height: 7.53086vw;
      margin-top: 0.98765vw; }
      .mentor-button-3d-shadow else {
        border-radius: 40px; }
      .mentor-button-3d-shadow else {
        height: 61px; }
      .mentor-button-3d-shadow else {
        margin-top: 8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .mentor-button-3d-shadow {
      border-radius: 4.3573vw;
      height: 6.64488vw;
      margin-top: 0.87146vw; }
      .mentor-button-3d-shadow else {
        border-radius: 40px; }
      .mentor-button-3d-shadow else {
        height: 61px; }
      .mentor-button-3d-shadow else {
        margin-top: 8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .mentor-button-3d-shadow {
      border-radius: 3.89864vw;
      height: 5.94542vw;
      margin-top: 0.77973vw; }
      .mentor-button-3d-shadow else {
        border-radius: 40px; }
      .mentor-button-3d-shadow else {
        height: 61px; }
      .mentor-button-3d-shadow else {
        margin-top: 8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .mentor-button-3d-shadow {
      border-radius: 3.52734vw;
      height: 5.37919vw;
      margin-top: 0.70547vw; }
      .mentor-button-3d-shadow else {
        border-radius: 40px; }
      .mentor-button-3d-shadow else {
        height: 61px; }
      .mentor-button-3d-shadow else {
        margin-top: 8px; } }

.mentor-button-3d-inner-container {
  background: conic-gradient(from -3.29deg at 100% -13%, #35E4E9 0deg, #00ADE6 360deg);
  box-shadow: 0px 0px 30px rgba(53, 201, 233, 0.5), inset 0px 0px 8px rgba(0, 255, 240, 0.8), 0px 0px 8px rgba(0, 255, 240, 0);
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  transition: 0.3s box-shadow ease-in-out, 0.3s width ease-in-out, 0.1s -webkit-transform ease-in-out;
  transition: 0.1s transform ease-in-out, 0.3s box-shadow ease-in-out, 0.3s width ease-in-out;
  transition: 0.1s transform ease-in-out, 0.3s box-shadow ease-in-out, 0.3s width ease-in-out, 0.1s -webkit-transform ease-in-out;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 7.40741vw;
  height: 11.2963vw;
  padding: 2.96296vw 4.44444vw;
  font-size: 3.88889vw;
  background: conic-gradient(from -3.29deg at 100% -13%, #35E4E9 0deg, #00ADE6 360deg);
  -webkit-transform: translateY(-8px);
          transform: translateY(-8px); }
  .mentor-button-3d-inner-container.disabled {
    background: #DCDCDC;
    color: #A8A7A7;
    box-shadow: none;
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    text-shadow: none; }
  @media (min-width: 1300px) {
    .mentor-button-3d-inner-container {
      border-radius: 2.08333vw;
      height: 3.17708vw;
      padding: 0.83333vw 1.25vw;
      font-size: 1.09375vw;
      background: conic-gradient(from -3.29deg at 100% -13%, #35E4E9 0deg, #00ADE6 360deg);
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-button-3d-inner-container {
      border-radius: 2.31481vw;
      height: 3.53009vw;
      padding: 0.92593vw 1.38889vw;
      font-size: 1.21528vw;
      background: conic-gradient(from -3.29deg at 100% -13%, #35E4E9 0deg, #00ADE6 360deg);
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-button-3d-inner-container {
      border-radius: 2.08333vw;
      height: 3.17708vw;
      padding: 0.83333vw 1.25vw;
      font-size: 1.09375vw;
      background: conic-gradient(from -3.29deg at 100% -13%, #35E4E9 0deg, #00ADE6 360deg);
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px); } }
  @media (min-width: 450px) and (max-width: 500px) {
    .mentor-button-3d-inner-container {
      border-radius: 6.17284vw;
      height: 9.41358vw;
      padding: 2.46914vw 3.7037vw;
      font-size: 3.24074vw; }
      .mentor-button-3d-inner-container else {
        border-radius: 40px; }
      .mentor-button-3d-inner-container else {
        height: 61px; }
      .mentor-button-3d-inner-container else {
        font-size: 21px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .mentor-button-3d-inner-container {
      border-radius: 4.93827vw;
      height: 7.53086vw;
      padding: 1.97531vw 2.96296vw;
      font-size: 2.59259vw; }
      .mentor-button-3d-inner-container else {
        border-radius: 40px; }
      .mentor-button-3d-inner-container else {
        height: 61px; }
      .mentor-button-3d-inner-container else {
        font-size: 21px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .mentor-button-3d-inner-container {
      border-radius: 4.3573vw;
      height: 6.64488vw;
      padding: 1.74292vw 2.61438vw;
      font-size: 2.28758vw; }
      .mentor-button-3d-inner-container else {
        border-radius: 40px; }
      .mentor-button-3d-inner-container else {
        height: 61px; }
      .mentor-button-3d-inner-container else {
        font-size: 21px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .mentor-button-3d-inner-container {
      border-radius: 3.89864vw;
      height: 5.94542vw;
      padding: 1.55945vw 2.33918vw;
      font-size: 2.04678vw; }
      .mentor-button-3d-inner-container else {
        border-radius: 40px; }
      .mentor-button-3d-inner-container else {
        height: 61px; }
      .mentor-button-3d-inner-container else {
        font-size: 21px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .mentor-button-3d-inner-container {
      border-radius: 3.52734vw;
      height: 5.37919vw;
      padding: 1.41093vw 2.1164vw;
      font-size: 1.85185vw; }
      .mentor-button-3d-inner-container else {
        border-radius: 40px; }
      .mentor-button-3d-inner-container else {
        height: 61px; }
      .mentor-button-3d-inner-container else {
        font-size: 21px; } }

.mentor-button-loading,
.mentor-button-loading:after {
  border-radius: 50%;
  width: 1.2em;
  height: 1.2em; }

.mentor-button-loading {
  font-size: 6px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  margin-right: 1.94444vw; }
  @media (min-width: 1300px) {
    .mentor-button-loading {
      margin-right: 0.36458vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-button-loading {
      margin-right: 0.40509vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-button-loading {
      margin-right: 0.36458vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .mentor-button-loading {
      margin-right: 1.62037vw; }
      .mentor-button-loading else {
        margin-right: 7px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .mentor-button-loading {
      margin-right: 1.2963vw; }
      .mentor-button-loading else {
        margin-right: 7px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .mentor-button-loading {
      margin-right: 1.14379vw; }
      .mentor-button-loading else {
        margin-right: 7px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .mentor-button-loading {
      margin-right: 1.02339vw; }
      .mentor-button-loading else {
        margin-right: 7px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .mentor-button-loading {
      margin-right: 0.92593vw; }
      .mentor-button-loading else {
        margin-right: 7px; } }

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.route-container {
  display: flex;
  flex-direction: row; }

@media (min-width: 1000px) {
  .route_container_forTeacherApp {
    height: calc(100vh - 5.10417vw); } }

@media (min-width: 900px) and (max-width: 1000px) {
  .route_container_forTeacherApp {
    height: calc(100vh - 5.10417vw); } }

@media (min-width: 1001px) and (max-width: 1600px) {
  .route_container_forTeacherApp {
    height: calc(100vh - 5.10417vw); } }

@media (min-width: 1601px) and (max-width: 2000px) {
  .route_container_forTeacherApp {
    height: calc(100vh - 5.10417vw); } }

.route-bodyContainer {
  flex: 1 1;
  height: calc(100vh);
  overflow: overlay;
  position: relative;
  overflow-x: hidden; }
  .route-bodyContainer.route-page-with-outside-nav {
    overflow-x: hidden;
    width: calc(100vw - 6.25vw);
    height: calc(100vh - 3.38542vw); }
  .route-bodyContainer.session-embed-code-playground {
    height: 100vh; }

@media only screen and (max-width: 768px) {
  .route-bodyContainer {
    height: 100vh; } }

.route-bodyContainerElectron {
  height: calc(100vh - 4.58333vw - 36px); }

.rotateDevice {
  width: 100vw;
  height: 100vh;
  background: #FFF;
  align-items: center;
  justify-content: center;
  display: none;
  overflow: hidden;
  z-index: 99999999999;
  position: fixed;
  background-image: url(/static/media/rotate-ipad-card-bg.bfb3b6e2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-family: Nunito;
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  flex-direction: column; }
  .rotateDevice > .rotateDeviceIcon {
    background-image: url(/static/media/rotate-phone-icon.2279ee09.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 45px;
    height: 45px;
    display: block;
    margin-bottom: 18px; }

@media screen and (orientation: portrait) and (max-device-width: 900px) {
  .__IPad__ .rotateDevice {
    display: flex; } }

.route-bodyPositionUnset {
  position: unset !important; }

.route-bodyContainerFull {
  flex: 1 1;
  max-width: 100vw;
  position: relative;
  height: calc(100vh);
  overflow: overlay;
  overflow-x: hidden; }

.session-embed-full {
  overflow-y: auto !important; }
  @media (min-width: 1000px) {
    .session-embed-full {
      height: calc(100vh - 5.10417vw); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .session-embed-full {
      height: calc(100vh - 5.10417vw); } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .session-embed-full {
      height: calc(100vh - 5.10417vw); } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .session-embed-full {
      height: calc(100vh - 5.10417vw); } }

.route-bodyOverflowOverlay {
  overflow: overlay; }

.route-sideBar {
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to bottom, #c9fcfd, #34e4ea);
  width: 120%;
  height: 100%; }

@media screen and (max-width: 500px) {
  .routesideBar {
    width: 48.30918vw; } }

@media screen and (min-width: 500px) and (max-width: 600px) {
  .route-sideBar {
    width: 33.81643vw; } }

@media screen and (min-width: 600px) and (max-width: 700px) {
  .route-sideBar {
    width: 31.40097vw; } }

@media screen and (min-width: 700px) and (max-width: 800px) {
  .route-sideBar {
    width: 28.98551vw; } }

@media screen and (min-width: 800px) and (max-width: 900px) {
  .route-sideBar {
    width: 26.57005vw; } }

@media screen and (min-width: 900px) and (max-width: 1000px) {
  .route-sideBar {
    width: 24.15459vw; } }

.route-menuItems {
  width: 100%;
  height: 8%;
  font-weight: bold;
  font-size: 2.5vh;
  font-family: Nunito;
  border-bottom: 1px solid #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-transform: capitalize;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.75); }

.route-text {
  margin-left: 3vh; }

.route-activeBackground {
  background-color: #fff; }

.lato, .lato-light, .lato-regular, .lato-hairline {
  font-family: 'Lato', sans-serif; }

.lato-light {
  font-weight: 300; }

.lato-regular {
  font-weight: 400; }

.lato-hairline {
  font-weight: 100; }

.popup-component-popUpContainer {
  position: absolute;
  width: calc(100vw - 5px);
  display: flex;
  top: 0; }

.popup-component-popUpBackground {
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(3, 3, 3, 0.6);
  overflow: auto;
  position: fixed; }

.popup-component-paymentPopUpBackground {
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(3, 3, 3, 0.6);
  overflow-y: auto;
  overflow-x: hidden; }
  @media (min-width: 1300px) {
    .popup-component-paymentPopUpBackground {
      background-color: rgba(3, 3, 3, 0.6);
      overflow: auto;
      position: fixed; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .popup-component-paymentPopUpBackground {
      background-color: rgba(3, 3, 3, 0.6);
      overflow: auto;
      position: fixed; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .popup-component-paymentPopUpBackground {
      background-color: rgba(3, 3, 3, 0.6);
      overflow: auto;
      position: fixed; } }

.popup-component-childrenContainer {
  display: flex; }

.popup-component-leftAlignedChildren {
  justify-content: flex-start !important; }

.popup-component-fadeIn {
  -webkit-animation: fadeInAnimation ease 0.4s;
          animation: fadeInAnimation ease 0.4s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  display: flex;
  justify-content: center;
  visibility: visible; }

.popup-component-fadeOut {
  -webkit-animation: fadeOutAnimation ease 0.4s;
          animation: fadeOutAnimation ease 0.4s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  visibility: hidden; }

.popup-component-modal {
  width: 5.20833vw;
  height: 5.20833vw; }

@-webkit-keyframes fadeInAnimation {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeInAnimation {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeOutAnimation {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOutAnimation {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.Inter, .Inter-regular, .Inter-SemiBold, .Inter-Bold {
  font-family: 'Inter', 'Nunito', sans-serif; }

.Inter-regular {
  font-weight: 400; }

.Inter-SemiBold {
  font-weight: 600; }

.Inter-Bold {
  font-weight: 800; }

:root {
  --accent-color:$tekie-amethyst;
  --accent-color-110: $tekie-amethyst-dark; }

p {
  margin: 0;
  padding: 0; }

.splitScreen {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background-color: #f9f9f9;
  box-sizing: border-box !important; }
  .splitScreen .popup-component-popUpBackground {
    z-index: 9999;
    background-color: transparent !important; }
  @media (min-width: 1300px) {
    .splitScreen ::-webkit-scrollbar {
      width: 0.52083vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .splitScreen ::-webkit-scrollbar {
      width: 0.5787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .splitScreen ::-webkit-scrollbar {
      width: 0.52083vw; } }
  .splitScreen ::-webkit-scrollbar-thumb {
    background: $tekie-amethyst;
    background: var(--accent-color); }
  .splitScreen.notAllowedScreen {
    justify-content: center;
    align-items: center;
    text-align: center; }

.splitScreen-withCRT-overlay {
  width: 100vw;
  height: 100vh;
  display: none;
  position: fixed;
  z-index: 1001;
  top: 0;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.3); }
  .splitScreen-withCRT-overlay.open {
    display: block; }

.splitScreen-sidenav {
  box-sizing: border-box;
  background: #FFFF;
  height: 100vh;
  font-family: 'Inter';
  z-index: 99;
  position: relative;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  transition: 0.3s all ease-in-out;
  display: flex;
  flex-direction: column;
  z-index: 1111;
  margin-left: 0;
  box-shadow: 2px 2px 4px 0px #0000001A;
  width: 84.54106vw;
  padding: 5.7971vw 4.83092vw; }
  @media (min-width: 1300px) {
    .splitScreen-sidenav {
      width: 18.75vw;
      padding: 1.25vw 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .splitScreen-sidenav {
      width: 20.83333vw;
      padding: 1.38889vw 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .splitScreen-sidenav {
      width: 18.75vw;
      padding: 1.25vw 1.04167vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .splitScreen-sidenav {
      width: 70.45089vw;
      padding: 4.83092vw 4.02576vw; }
      .splitScreen-sidenav else {
        width: 350px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .splitScreen-sidenav {
      width: 56.36071vw;
      padding: 3.86473vw 3.22061vw; }
      .splitScreen-sidenav else {
        width: 350px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .splitScreen-sidenav {
      width: 49.73004vw;
      padding: 3.41006vw 2.84172vw; }
      .splitScreen-sidenav else {
        width: 350px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .splitScreen-sidenav {
      width: 44.4953vw;
      padding: 3.05111vw 2.54259vw; }
      .splitScreen-sidenav else {
        width: 350px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .splitScreen-sidenav {
      width: 40.25765vw;
      padding: 2.76052vw 2.30044vw; }
      .splitScreen-sidenav else {
        width: 350px; } }
  .splitScreen-sidenav .sidenav {
    flex: 1 1; }
  .splitScreen-sidenav.hamburger {
    width: 0; }
    @media (min-width: 1300px) {
      .splitScreen-sidenav.hamburger {
        padding: 1.25vw 0vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .splitScreen-sidenav.hamburger {
        padding: 1.38889vw 0vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .splitScreen-sidenav.hamburger {
        padding: 1.25vw 0vw; } }

.childrens-nav-items {
  padding-left: 4.83092vw; }
  @media (min-width: 1300px) {
    .childrens-nav-items {
      padding-left: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .childrens-nav-items {
      padding-left: 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .childrens-nav-items {
      padding-left: 1.04167vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .childrens-nav-items {
      padding-left: 4.02576vw; }
      .childrens-nav-items else {
        padding-left: 20px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .childrens-nav-items {
      padding-left: 3.22061vw; }
      .childrens-nav-items else {
        padding-left: 20px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .childrens-nav-items {
      padding-left: 2.84172vw; }
      .childrens-nav-items else {
        padding-left: 20px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .childrens-nav-items {
      padding-left: 2.54259vw; }
      .childrens-nav-items else {
        padding-left: 20px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .childrens-nav-items {
      padding-left: 2.30044vw; }
      .childrens-nav-items else {
        padding-left: 20px; } }

.splitScreen-sidenav-withCRT {
  position: fixed; }

@media (min-width: 1000px) {
  .splitScreen-main-component-withCRT .menu__icon__container.open {
    left: 14.0625vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .splitScreen-main-component-withCRT .menu__icon__container.open {
    left: 15.625vw; } }

@media (min-width: 1001px) and (max-width: 1600px) {
  .splitScreen-main-component-withCRT .menu__icon__container.open {
    left: 14.0625vw; } }

@media (min-width: 1601px) and (max-width: 2000px) {
  .splitScreen-main-component-withCRT .menu__icon__container.open {
    left: 11.25vw; } }

.splitScreen ::-webkit-scrollbar {
  background-color: #EEEEEE; }

@media screen and (max-width: 700px) {
  .splitScreen-sidenav.hamburger {
    position: fixed;
    top: 0;
    left: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); } }

.hamburger-icon-teacherApp {
  position: absolute;
  top: 1rem;
  left: 1rem; }

.splitScreen-main-component {
  position: relative;
  min-height: 100%;
  height: 100vh;
  width: 100%;
  overflow: scroll;
  transition: 0.3s all ease-in-out; }
  @media (min-width: 1000px) {
    .splitScreen-main-component {
      padding-left: 1.5625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .splitScreen-main-component {
      padding-left: 1.73611vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .splitScreen-main-component {
      padding-left: 1.5625vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .splitScreen-main-component {
      padding-left: 1.25vw; } }
  @media (min-width: 1000px) {
    .splitScreen-main-component.hamburger, .splitScreen-main-component.splitScreen-main-component-withCRT {
      padding-left: 3.75vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .splitScreen-main-component.hamburger, .splitScreen-main-component.splitScreen-main-component-withCRT {
      padding-left: 4.16667vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .splitScreen-main-component.hamburger, .splitScreen-main-component.splitScreen-main-component-withCRT {
      padding-left: 3.75vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .splitScreen-main-component.hamburger, .splitScreen-main-component.splitScreen-main-component-withCRT {
      padding-left: 3vw; } }
  .splitScreen-main-component.splitScreen-main-component-trainingApp {
    padding: unset; }

.profile-container {
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-direction: row;
  font-weight: 540;
  align-items: center;
  transition: 0.2s all ease-in-out;
  text-decoration: none;
  font-size: 3.38164vw;
  padding: 1.93237vw 2.89855vw;
  border-radius: 2.41546vw;
  margin: 2.89855vw auto; }
  @media (min-width: 1300px) {
    .profile-container {
      font-size: 1.14583vw;
      padding: 0.52083vw 0.72917vw;
      border-radius: 0.72917vw;
      margin: 0.625vw auto; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .profile-container {
      font-size: 1.27315vw;
      padding: 0.5787vw 0.81019vw;
      border-radius: 0.81019vw;
      margin: 0.69444vw auto; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .profile-container {
      font-size: 1.14583vw;
      padding: 0.52083vw 0.72917vw;
      border-radius: 0.72917vw;
      margin: 0.625vw auto; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .profile-container {
      font-size: 2.81804vw;
      padding: 1.61031vw 2.41546vw;
      border-radius: 2.01288vw;
      margin: 2.41546vw auto; }
      .profile-container else {
        font-size: 14px; }
      .profile-container else {
        border-radius: 10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .profile-container {
      font-size: 2.25443vw;
      padding: 1.28824vw 1.93237vw;
      border-radius: 1.61031vw;
      margin: 1.93237vw auto; }
      .profile-container else {
        font-size: 14px; }
      .profile-container else {
        border-radius: 10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .profile-container {
      font-size: 1.9892vw;
      padding: 1.13669vw 1.70503vw;
      border-radius: 1.42086vw;
      margin: 1.70503vw auto; }
      .profile-container else {
        font-size: 14px; }
      .profile-container else {
        border-radius: 10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .profile-container {
      font-size: 1.77981vw;
      padding: 1.01704vw 1.52555vw;
      border-radius: 1.27129vw;
      margin: 1.52555vw auto; }
      .profile-container else {
        font-size: 14px; }
      .profile-container else {
        border-radius: 10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .profile-container {
      font-size: 1.61031vw;
      padding: 0.92017vw 1.38026vw;
      border-radius: 1.15022vw;
      margin: 1.38026vw auto; }
      .profile-container else {
        font-size: 14px; }
      .profile-container else {
        border-radius: 10px; } }
  .profile-container svg {
    height: 4.83092vw;
    width: 4.83092vw; }
    @media (min-width: 1300px) {
      .profile-container svg {
        height: 1.5625vw;
        width: 1.5625vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .profile-container svg {
        height: 1.73611vw;
        width: 1.73611vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .profile-container svg {
        height: 1.5625vw;
        width: 1.5625vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .profile-container svg {
        height: 4.02576vw;
        width: 4.02576vw; }
        .profile-container svg else {
          height: 20px; }
        .profile-container svg else {
          width: 20px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .profile-container svg {
        height: 3.22061vw;
        width: 3.22061vw; }
        .profile-container svg else {
          height: 20px; }
        .profile-container svg else {
          width: 20px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .profile-container svg {
        height: 2.84172vw;
        width: 2.84172vw; }
        .profile-container svg else {
          height: 20px; }
        .profile-container svg else {
          width: 20px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .profile-container svg {
        height: 2.54259vw;
        width: 2.54259vw; }
        .profile-container svg else {
          height: 20px; }
        .profile-container svg else {
          width: 20px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .profile-container svg {
        height: 2.30044vw;
        width: 2.30044vw; }
        .profile-container svg else {
          height: 20px; }
        .profile-container svg else {
          width: 20px; } }

.flex-container {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.user-image {
  -webkit-user-select: none;
          user-select: none;
  background-color: $tekie-amethyst;
  background-color: var(--accent-color);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 9.66184vw;
  width: 9.66184vw;
  border-radius: 1.93237vw; }
  .user-image img {
    height: 100%;
    width: 100%;
    border-radius: 1.93237vw; }
    @media (min-width: 1300px) {
      .user-image img {
        height: 100%;
        width: 100%;
        border-radius: 0.41667vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .user-image img {
        height: 100%;
        width: 100%;
        border-radius: 0.46296vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .user-image img {
        height: 100%;
        width: 100%;
        border-radius: 0.41667vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .user-image img {
        border-radius: 1.61031vw; }
        .user-image img else {
          height: 100%; }
        .user-image img else {
          width: 100%; }
        .user-image img else {
          border-radius: 8px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .user-image img {
        border-radius: 1.28824vw; }
        .user-image img else {
          height: 100%; }
        .user-image img else {
          width: 100%; }
        .user-image img else {
          border-radius: 8px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .user-image img {
        border-radius: 1.13669vw; }
        .user-image img else {
          height: 100%; }
        .user-image img else {
          width: 100%; }
        .user-image img else {
          border-radius: 8px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .user-image img {
        border-radius: 1.01704vw; }
        .user-image img else {
          height: 100%; }
        .user-image img else {
          width: 100%; }
        .user-image img else {
          border-radius: 8px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .user-image img {
        border-radius: 0.92017vw; }
        .user-image img else {
          height: 100%; }
        .user-image img else {
          width: 100%; }
        .user-image img else {
          border-radius: 8px; } }
  @media (min-width: 1300px) {
    .user-image {
      height: 2.70833vw;
      width: 2.70833vw;
      border-radius: 0.625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .user-image {
      height: 3.00926vw;
      width: 3.00926vw;
      border-radius: 0.69444vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .user-image {
      height: 2.70833vw;
      width: 2.70833vw;
      border-radius: 0.625vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .user-image {
      height: 8.05153vw;
      width: 8.05153vw;
      border-radius: 1.61031vw; }
      .user-image else {
        height: 40px; }
      .user-image else {
        width: 40px; }
      .user-image else {
        border-radius: 8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .user-image {
      height: 6.44122vw;
      width: 6.44122vw;
      border-radius: 1.28824vw; }
      .user-image else {
        height: 40px; }
      .user-image else {
        width: 40px; }
      .user-image else {
        border-radius: 8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .user-image {
      height: 5.68343vw;
      width: 5.68343vw;
      border-radius: 1.13669vw; }
      .user-image else {
        height: 40px; }
      .user-image else {
        width: 40px; }
      .user-image else {
        border-radius: 8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .user-image {
      height: 5.08518vw;
      width: 5.08518vw;
      border-radius: 1.01704vw; }
      .user-image else {
        height: 40px; }
      .user-image else {
        width: 40px; }
      .user-image else {
        border-radius: 8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .user-image {
      height: 4.60087vw;
      width: 4.60087vw;
      border-radius: 0.92017vw; }
      .user-image else {
        height: 40px; }
      .user-image else {
        width: 40px; }
      .user-image else {
        border-radius: 8px; } }

.user-details {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  letter-spacing: .5px;
  display: block;
  padding: 0vw 2.89855vw 0.96618vw; }
  @media (min-width: 1300px) {
    .user-details {
      padding: 0.3125vw 0.83333vw 0.3125vw;
      width: 7.8125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .user-details {
      padding: 0.34722vw 0.92593vw 0.34722vw;
      width: 8.68056vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .user-details {
      padding: 0.3125vw 0.83333vw 0.3125vw;
      width: 7.8125vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .user-details {
      padding: 0vw 2.41546vw 0.80515vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .user-details {
      padding: 0vw 1.93237vw 0.64412vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .user-details {
      padding: 0vw 1.70503vw 0.56834vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .user-details {
      padding: 0vw 1.52555vw 0.50852vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .user-details {
      padding: 0vw 1.38026vw 0.46009vw; } }

.user-name {
  font-weight: 550;
  position: relative;
  display: block;
  font-size: 3.62319vw;
  margin-bottom: 0.48309vw; }
  @media (min-width: 1300px) {
    .user-name {
      font-size: 0.9375vw;
      margin-bottom: 0.20833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .user-name {
      font-size: 1.04167vw;
      margin-bottom: 0.23148vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .user-name {
      font-size: 0.9375vw;
      margin-bottom: 0.20833vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .user-name {
      font-size: 3.01932vw;
      margin-bottom: 0.40258vw; }
      .user-name else {
        font-size: 15px; }
      .user-name else {
        margin-bottom: 2px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .user-name {
      font-size: 2.41546vw;
      margin-bottom: 0.32206vw; }
      .user-name else {
        font-size: 15px; }
      .user-name else {
        margin-bottom: 2px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .user-name {
      font-size: 2.13129vw;
      margin-bottom: 0.28417vw; }
      .user-name else {
        font-size: 15px; }
      .user-name else {
        margin-bottom: 2px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .user-name {
      font-size: 1.90694vw;
      margin-bottom: 0.25426vw; }
      .user-name else {
        font-size: 15px; }
      .user-name else {
        margin-bottom: 2px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .user-name {
      font-size: 1.72533vw;
      margin-bottom: 0.23004vw; }
      .user-name else {
        font-size: 15px; }
      .user-name else {
        margin-bottom: 2px; } }

.sidebar-role {
  font-size: 1.93237vw;
  font-weight: 500;
  color: #666666;
  letter-spacing: 0.1px; }
  @media (min-width: 1300px) {
    .sidebar-role {
      font-size: 0.72917vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sidebar-role {
      font-size: 0.81019vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sidebar-role {
      font-size: 0.72917vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .sidebar-role {
      font-size: 1.61031vw; }
      .sidebar-role else {
        font-size: 8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .sidebar-role {
      font-size: 1.28824vw; }
      .sidebar-role else {
        font-size: 8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .sidebar-role {
      font-size: 1.13669vw; }
      .sidebar-role else {
        font-size: 8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .sidebar-role {
      font-size: 1.01704vw; }
      .sidebar-role else {
        font-size: 8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .sidebar-role {
      font-size: 0.92017vw; }
      .sidebar-role else {
        font-size: 8px; } }
  .sidebar-role span {
    font-size: 2.657vw; }
    @media (min-width: 1300px) {
      .sidebar-role span {
        font-size: 0.88542vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .sidebar-role span {
        font-size: 0.9838vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .sidebar-role span {
        font-size: 0.88542vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .sidebar-role span {
        font-size: 2.21417vw; }
        .sidebar-role span else {
          font-size: 11px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .sidebar-role span {
        font-size: 1.77134vw; }
        .sidebar-role span else {
          font-size: 11px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .sidebar-role span {
        font-size: 1.56294vw; }
        .sidebar-role span else {
          font-size: 11px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .sidebar-role span {
        font-size: 1.39842vw; }
        .sidebar-role span else {
          font-size: 11px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .sidebar-role span {
        font-size: 1.26524vw; }
        .sidebar-role span else {
          font-size: 11px; } }

.logo-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: default !important;
  width: 100%;
  height: 5.7971vw;
  margin-bottom: 2.89855vw;
  cursor: pointer; }
  @media (min-width: 1300px) {
    .logo-link {
      width: 100%;
      height: 2.34375vw;
      margin-bottom: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .logo-link {
      width: 100%;
      height: 2.60417vw;
      margin-bottom: 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .logo-link {
      width: 100%;
      height: 2.34375vw;
      margin-bottom: 1.04167vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .logo-link {
      height: 4.83092vw;
      margin-bottom: 2.41546vw; }
      .logo-link else {
        width: 100%; }
      .logo-link else {
        height: 24px; }
      .logo-link else {
        margin-bottom: 12px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .logo-link {
      height: 3.86473vw;
      margin-bottom: 1.93237vw; }
      .logo-link else {
        width: 100%; }
      .logo-link else {
        height: 24px; }
      .logo-link else {
        margin-bottom: 12px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .logo-link {
      height: 3.41006vw;
      margin-bottom: 1.70503vw; }
      .logo-link else {
        width: 100%; }
      .logo-link else {
        height: 24px; }
      .logo-link else {
        margin-bottom: 12px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .logo-link {
      height: 3.05111vw;
      margin-bottom: 1.52555vw; }
      .logo-link else {
        width: 100%; }
      .logo-link else {
        height: 24px; }
      .logo-link else {
        margin-bottom: 12px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .logo-link {
      height: 2.76052vw;
      margin-bottom: 1.38026vw; }
      .logo-link else {
        width: 100%; }
      .logo-link else {
        height: 24px; }
      .logo-link else {
        margin-bottom: 12px; } }
  .logo-link img {
    width: auto;
    height: 5.7971vw; }
    @media (min-width: 1300px) {
      .logo-link img {
        width: auto;
        height: 2.34375vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .logo-link img {
        width: auto;
        height: 2.60417vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .logo-link img {
        width: auto;
        height: 2.34375vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .logo-link img {
        height: 4.83092vw; }
        .logo-link img else {
          width: auto; }
        .logo-link img else {
          height: 24px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .logo-link img {
        height: 3.86473vw; }
        .logo-link img else {
          width: auto; }
        .logo-link img else {
          height: 24px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .logo-link img {
        height: 3.41006vw; }
        .logo-link img else {
          width: auto; }
        .logo-link img else {
          height: 24px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .logo-link img {
        height: 3.05111vw; }
        .logo-link img else {
          width: auto; }
        .logo-link img else {
          height: 24px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .logo-link img {
        height: 2.76052vw; }
        .logo-link img else {
          width: auto; }
        .logo-link img else {
          height: 24px; } }

.navitem-link {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Inter';
  position: relative;
  font-weight: 520;
  align-items: center;
  height: 7.97101vw;
  padding: 0vw 2.41546vw;
  margin: 1.93237vw 0vw;
  border-radius: 1.93237vw;
  color: #333333;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  letter-spacing: 0.3px;
  position: relative;
  text-decoration: none; }
  @media (min-width: 1300px) {
    .navitem-link {
      height: 2.70833vw;
      padding: 0vw 0.83333vw;
      margin: 0.57292vw 0vw;
      border-radius: 0.67708vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .navitem-link {
      height: 3.00926vw;
      padding: 0vw 0.92593vw;
      margin: 0.63657vw 0vw;
      border-radius: 0.75231vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .navitem-link {
      height: 2.70833vw;
      padding: 0vw 0.83333vw;
      margin: 0.57292vw 0vw;
      border-radius: 0.67708vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .navitem-link {
      height: 6.64251vw;
      padding: 0vw 2.01288vw;
      margin: 1.61031vw 0vw;
      border-radius: 1.61031vw; }
      .navitem-link else {
        height: 33px; }
      .navitem-link else {
        border-radius: 8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .navitem-link {
      height: 5.31401vw;
      padding: 0vw 1.61031vw;
      margin: 1.28824vw 0vw;
      border-radius: 1.28824vw; }
      .navitem-link else {
        height: 33px; }
      .navitem-link else {
        border-radius: 8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .navitem-link {
      height: 4.68883vw;
      padding: 0vw 1.42086vw;
      margin: 1.13669vw 0vw;
      border-radius: 1.13669vw; }
      .navitem-link else {
        height: 33px; }
      .navitem-link else {
        border-radius: 8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .navitem-link {
      height: 4.19527vw;
      padding: 0vw 1.27129vw;
      margin: 1.01704vw 0vw;
      border-radius: 1.01704vw; }
      .navitem-link else {
        height: 33px; }
      .navitem-link else {
        border-radius: 8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .navitem-link {
      height: 3.79572vw;
      padding: 0vw 1.15022vw;
      margin: 0.92017vw 0vw;
      border-radius: 0.92017vw; }
      .navitem-link else {
        height: 33px; }
      .navitem-link else {
        border-radius: 8px; } }
  .navitem-link:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    background: #F5F5F5;
    text-decoration: none; }
  .navitem-link:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95); }

.navitem-link-image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.96618vw; }
  @media (min-width: 1300px) {
    .navitem-link-image {
      margin-right: 0.67708vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .navitem-link-image {
      margin-right: 0.75231vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .navitem-link-image {
      margin-right: 0.67708vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .navitem-link-image {
      margin-right: 0.80515vw; }
      .navitem-link-image else {
        margin-right: 4px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .navitem-link-image {
      margin-right: 0.64412vw; }
      .navitem-link-image else {
        margin-right: 4px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .navitem-link-image {
      margin-right: 0.56834vw; }
      .navitem-link-image else {
        margin-right: 4px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .navitem-link-image {
      margin-right: 0.50852vw; }
      .navitem-link-image else {
        margin-right: 4px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .navitem-link-image {
      margin-right: 0.46009vw; }
      .navitem-link-image else {
        margin-right: 4px; } }
  .navitem-link-image svg {
    width: 5.7971vw;
    height: 5.7971vw; }
    @media (min-width: 1300px) {
      .navitem-link-image svg {
        width: 1.5625vw;
        height: 1.5625vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .navitem-link-image svg {
        width: 1.73611vw;
        height: 1.73611vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .navitem-link-image svg {
        width: 1.5625vw;
        height: 1.5625vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .navitem-link-image svg {
        width: 4.83092vw;
        height: 4.83092vw; }
        .navitem-link-image svg else {
          width: 24px; }
        .navitem-link-image svg else {
          height: 24px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .navitem-link-image svg {
        width: 3.86473vw;
        height: 3.86473vw; }
        .navitem-link-image svg else {
          width: 24px; }
        .navitem-link-image svg else {
          height: 24px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .navitem-link-image svg {
        width: 3.41006vw;
        height: 3.41006vw; }
        .navitem-link-image svg else {
          width: 24px; }
        .navitem-link-image svg else {
          height: 24px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .navitem-link-image svg {
        width: 3.05111vw;
        height: 3.05111vw; }
        .navitem-link-image svg else {
          width: 24px; }
        .navitem-link-image svg else {
          height: 24px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .navitem-link-image svg {
        width: 2.76052vw;
        height: 2.76052vw; }
        .navitem-link-image svg else {
          width: 24px; }
        .navitem-link-image svg else {
          height: 24px; } }

.navitem-parent-link {
  position: absolute;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  transition: all 0.2s ease-in-out; }
  @media (min-width: 1300px) {
    .navitem-parent-link {
      right: 0.26042vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .navitem-parent-link {
      right: 0.28935vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .navitem-parent-link {
      right: 0.26042vw; } }
  .navitem-parent-link.navitem-parent-link-active {
    -webkit-transform: initial;
            transform: initial; }

.navitem-link-title {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 2.89855vw; }
  @media (min-width: 1300px) {
    .navitem-link-title {
      font-size: 0.9375vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .navitem-link-title {
      font-size: 1.04167vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .navitem-link-title {
      font-size: 0.9375vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .navitem-link-title {
      font-size: 2.41546vw; }
      .navitem-link-title else {
        font-size: 12px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .navitem-link-title {
      font-size: 1.93237vw; }
      .navitem-link-title else {
        font-size: 12px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .navitem-link-title {
      font-size: 1.70503vw; }
      .navitem-link-title else {
        font-size: 12px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .navitem-link-title {
      font-size: 1.52555vw; }
      .navitem-link-title else {
        font-size: 12px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .navitem-link-title {
      font-size: 1.38026vw; }
      .navitem-link-title else {
        font-size: 12px; } }

.navitem-link-active {
  color: $tekie-amethyst;
  color: var(--accent-color);
  background-color: #FAF6FF; }
  .navitem-link-active:hover {
    color: $tekie-amethyst;
    color: var(--accent-color);
    background: #FAF6FF;
    -webkit-transform: none;
            transform: none; }

.divider {
  padding: 6px 0px;
  border-bottom: 1.5px solid #EEEEEE;
  width: 95%;
  margin: auto; }

.logout-btn {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  color: #FF5744;
  border: none;
  background-color: #F6F6F6;
  display: flex;
  align-items: center;
  align-self: stretch;
  background: #FFE4E6;
  font-size: 3.38164vw; }
  @media (min-width: 1000px) {
    .logout-btn {
      font-size: 1.09375vw;
      margin-top: 0;
      padding: 0.3125vw;
      grid-gap: 0.625vw;
      gap: 0.625vw;
      border-radius: 0.625vw;
      width: 95%;
      font-weight: 500; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .logout-btn {
      font-size: 1.21528vw;
      margin-top: 0;
      padding: 0.34722vw;
      grid-gap: 0.69444vw;
      gap: 0.69444vw;
      border-radius: 0.69444vw;
      width: 95%;
      font-weight: 500; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .logout-btn {
      font-size: 1.09375vw;
      margin-top: 0;
      padding: 0.3125vw;
      grid-gap: 0.625vw;
      gap: 0.625vw;
      border-radius: 0.625vw;
      width: 95%;
      font-weight: 500; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .logout-btn {
      font-size: 0.875vw;
      margin-top: 0;
      padding: 0.25vw;
      grid-gap: 0.5vw;
      gap: 0.5vw;
      border-radius: 0.5vw;
      width: 95%;
      font-weight: 500; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .logout-btn {
      font-size: 2.81804vw; }
      .logout-btn else {
        font-size: 14px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .logout-btn {
      font-size: 2.25443vw; }
      .logout-btn else {
        font-size: 14px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .logout-btn {
      font-size: 1.9892vw; }
      .logout-btn else {
        font-size: 14px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .logout-btn {
      font-size: 1.77981vw; }
      .logout-btn else {
        font-size: 14px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .logout-btn {
      font-size: 1.61031vw; }
      .logout-btn else {
        font-size: 14px; } }
  .logout-btn img {
    width: 3.38164vw;
    height: 3.38164vw;
    margin-right: 2.17391vw; }
    @media (min-width: 1300px) {
      .logout-btn img {
        width: 1.25vw;
        height: 1.25vw;
        margin-right: 0.625vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .logout-btn img {
        width: 1.38889vw;
        height: 1.38889vw;
        margin-right: 0.69444vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .logout-btn img {
        width: 1.25vw;
        height: 1.25vw;
        margin-right: 0.625vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .logout-btn img {
        width: 2.81804vw;
        height: 2.81804vw;
        margin-right: 1.81159vw; }
        .logout-btn img else {
          width: 14px; }
        .logout-btn img else {
          height: 14px; }
        .logout-btn img else {
          margin-right: 9px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .logout-btn img {
        width: 2.25443vw;
        height: 2.25443vw;
        margin-right: 1.44928vw; }
        .logout-btn img else {
          width: 14px; }
        .logout-btn img else {
          height: 14px; }
        .logout-btn img else {
          margin-right: 9px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .logout-btn img {
        width: 1.9892vw;
        height: 1.9892vw;
        margin-right: 1.27877vw; }
        .logout-btn img else {
          width: 14px; }
        .logout-btn img else {
          height: 14px; }
        .logout-btn img else {
          margin-right: 9px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .logout-btn img {
        width: 1.77981vw;
        height: 1.77981vw;
        margin-right: 1.14416vw; }
        .logout-btn img else {
          width: 14px; }
        .logout-btn img else {
          height: 14px; }
        .logout-btn img else {
          margin-right: 9px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .logout-btn img {
        width: 1.61031vw;
        height: 1.61031vw;
        margin-right: 1.0352vw; }
        .logout-btn img else {
          width: 14px; }
        .logout-btn img else {
          height: 14px; }
        .logout-btn img else {
          margin-right: 9px; } }

.navitem-link-secondary {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  color: #605959;
  border: none;
  font-size: 3.38164vw; }
  @media (min-width: 1300px) {
    .navitem-link-secondary {
      font-size: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .navitem-link-secondary {
      font-size: 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .navitem-link-secondary {
      font-size: 1.04167vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .navitem-link-secondary {
      font-size: 2.81804vw; }
      .navitem-link-secondary else {
        font-size: 14px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .navitem-link-secondary {
      font-size: 2.25443vw; }
      .navitem-link-secondary else {
        font-size: 14px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .navitem-link-secondary {
      font-size: 1.9892vw; }
      .navitem-link-secondary else {
        font-size: 14px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .navitem-link-secondary {
      font-size: 1.77981vw; }
      .navitem-link-secondary else {
        font-size: 14px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .navitem-link-secondary {
      font-size: 1.61031vw; }
      .navitem-link-secondary else {
        font-size: 14px; } }

.switch-btn {
  position: absolute;
  bottom: 4rem; }

.menu__icon__container {
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition: 0.3s left ease-in-out;
  height: 9.66184vw;
  width: 9.66184vw;
  z-index: 1112; }
  @media (min-width: 1300px) {
    .menu__icon__container {
      top: 1.04167vw;
      border-radius: 0 0.78125vw 0.78125vw 0;
      height: 2.86458vw;
      width: 2.86458vw;
      left: 10.9375vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .menu__icon__container {
      top: 1.15741vw;
      border-radius: 0 0.86806vw 0.86806vw 0;
      height: 3.18287vw;
      width: 3.18287vw;
      left: 12.15278vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .menu__icon__container {
      top: 1.04167vw;
      border-radius: 0 0.78125vw 0.78125vw 0;
      height: 2.86458vw;
      width: 2.86458vw;
      left: 10.9375vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .menu__icon__container {
      height: 8.05153vw;
      width: 8.05153vw; }
      .menu__icon__container else {
        height: 40px; }
      .menu__icon__container else {
        width: 40px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .menu__icon__container {
      height: 6.44122vw;
      width: 6.44122vw; }
      .menu__icon__container else {
        height: 40px; }
      .menu__icon__container else {
        width: 40px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .menu__icon__container {
      height: 5.68343vw;
      width: 5.68343vw; }
      .menu__icon__container else {
        height: 40px; }
      .menu__icon__container else {
        width: 40px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .menu__icon__container {
      height: 5.08518vw;
      width: 5.08518vw; }
      .menu__icon__container else {
        height: 40px; }
      .menu__icon__container else {
        width: 40px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .menu__icon__container {
      height: 4.60087vw;
      width: 4.60087vw; }
      .menu__icon__container else {
        height: 40px; }
      .menu__icon__container else {
        width: 40px; } }
  .menu__icon__container svg {
    width: 5.7971vw;
    height: 5.7971vw; }
    .menu__icon__container svg path {
      fill: #a8a5a5; }
    .menu__icon__container svg:hover path {
      fill: $tekie-amethyst;
      fill: var(--accent-color); }
    @media (min-width: 1300px) {
      .menu__icon__container svg {
        width: 2.08333vw;
        height: 2.08333vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .menu__icon__container svg {
        width: 2.31481vw;
        height: 2.31481vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .menu__icon__container svg {
        width: 2.08333vw;
        height: 2.08333vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .menu__icon__container svg {
        width: 4.83092vw;
        height: 4.83092vw; }
        .menu__icon__container svg else {
          width: 24px; }
        .menu__icon__container svg else {
          height: 24px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .menu__icon__container svg {
        width: 3.86473vw;
        height: 3.86473vw; }
        .menu__icon__container svg else {
          width: 24px; }
        .menu__icon__container svg else {
          height: 24px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .menu__icon__container svg {
        width: 3.41006vw;
        height: 3.41006vw; }
        .menu__icon__container svg else {
          width: 24px; }
        .menu__icon__container svg else {
          height: 24px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .menu__icon__container svg {
        width: 3.05111vw;
        height: 3.05111vw; }
        .menu__icon__container svg else {
          width: 24px; }
        .menu__icon__container svg else {
          height: 24px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .menu__icon__container svg {
        width: 2.76052vw;
        height: 2.76052vw; }
        .menu__icon__container svg else {
          width: 24px; }
        .menu__icon__container svg else {
          height: 24px; } }

.open.menu__icon__container:hover {
  border-radius: 50%;
  background-color: rgba(228, 228, 228, 0.5); }
  .open.menu__icon__container:hover svg path {
    fill: $tekie-amethyst;
    fill: var(--accent-color); }

.teacher-app-hamburger {
  position: relative;
  z-index: 999;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .teacher-app-hamburger span {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    background: $tekie-amethyst;
    background: var(--accent-color);
    z-index: 999; }
    @media (min-width: 1000px) {
      .teacher-app-hamburger span {
        width: 75%;
        height: 0.27344vw;
        margin-bottom: 0.39062vw;
        border-radius: 0.23438vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .teacher-app-hamburger span {
        width: 75%;
        height: 0.30382vw;
        margin-bottom: 0.43403vw;
        border-radius: 0.26042vw; } }
    @media (min-width: 1001px) and (max-width: 1600px) {
      .teacher-app-hamburger span {
        width: 75%;
        height: 0.27344vw;
        margin-bottom: 0.39062vw;
        border-radius: 0.23438vw; } }
    @media (min-width: 1601px) and (max-width: 2000px) {
      .teacher-app-hamburger span {
        width: 75%;
        height: 0.21875vw;
        margin-bottom: 0.3125vw;
        border-radius: 0.1875vw; } }
    .teacher-app-hamburger span:last-of-type {
      margin-bottom: 0px; }
      @media (min-width: 1000px) {
        .teacher-app-hamburger span:last-of-type {
          height: 0.3125vw; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .teacher-app-hamburger span:last-of-type {
          height: 0.34722vw; } }
      @media (min-width: 1001px) and (max-width: 1600px) {
        .teacher-app-hamburger span:last-of-type {
          height: 0.3125vw; } }
      @media (min-width: 1601px) and (max-width: 2000px) {
        .teacher-app-hamburger span:last-of-type {
          height: 0.25vw; } }

@media (min-width: 1300px) {
  .close.menu__icon__container {
    left: 0;
    height: 2.60417vw;
    width: 2.60417vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .close.menu__icon__container {
    left: 0;
    height: 2.89352vw;
    width: 2.89352vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .close.menu__icon__container {
    left: 0;
    height: 2.60417vw;
    width: 2.60417vw; } }

.close.menu__icon__container .teacher-app-hamburger span {
  width: 75%; }

.teacher-app-cn-navbar {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: all;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  z-index: 9999;
  -webkit-filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15)); }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: rgba(230, 247, 253, 0);
      transition: 0.3s all ease-in-out;
      cursor: pointer;
      -webkit-filter: unset;
              filter: unset; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: rgba(230, 247, 253, 0);
      transition: 0.3s all ease-in-out;
      cursor: pointer;
      -webkit-filter: unset;
              filter: unset; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: rgba(230, 247, 253, 0);
      transition: 0.3s all ease-in-out;
      cursor: pointer;
      -webkit-filter: unset;
              filter: unset; } }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar.open {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar.open {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar.open {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); } }
  .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 {
    margin-top: 1.80556vw;
    border-radius: 13.88889vw;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
    @media (min-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 {
        margin-top: 0vw;
        border-radius: 2.60417vw;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 {
        margin-top: 0vw;
        border-radius: 2.89352vw;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 {
        margin-top: 0vw;
        border-radius: 2.60417vw;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); } }
    @media (min-width: 450px) and (max-width: 500px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 {
        margin-top: 1.50463vw;
        border-radius: 11.57407vw; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 else {
          margin-top: 6.5px; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 {
        margin-top: 1.2037vw;
        border-radius: 9.25926vw; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 else {
          margin-top: 6.5px; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 {
        margin-top: 1.06209vw;
        border-radius: 8.16993vw; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 else {
          margin-top: 6.5px; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 {
        margin-top: 0.95029vw;
        border-radius: 7.30994vw; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 else {
          margin-top: 6.5px; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 {
        margin-top: 0.85979vw;
        border-radius: 6.61376vw; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 else {
          margin-top: 6.5px; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
  .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 {
    margin-top: 1.66667vw;
    border-radius: 13.88889vw;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
    @media (min-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 {
        margin-top: 0vw;
        border-radius: 2.60417vw;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 {
        margin-top: 0vw;
        border-radius: 2.89352vw;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 {
        margin-top: 0vw;
        border-radius: 2.60417vw;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); } }
    @media (min-width: 450px) and (max-width: 500px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 {
        margin-top: 1.38889vw;
        border-radius: 11.57407vw; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 else {
          margin-top: 6px; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 {
        margin-top: 1.11111vw;
        border-radius: 9.25926vw; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 else {
          margin-top: 6px; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 {
        margin-top: 0.98039vw;
        border-radius: 8.16993vw; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 else {
          margin-top: 6px; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 {
        margin-top: 0.87719vw;
        border-radius: 7.30994vw; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 else {
          margin-top: 6px; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 {
        margin-top: 0.79365vw;
        border-radius: 6.61376vw; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 else {
          margin-top: 6px; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
  .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 {
    border-radius: 13.88889vw;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
    @media (min-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 {
        border-radius: 2.60417vw;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 {
        border-radius: 2.89352vw;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 {
        border-radius: 2.60417vw;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); } }
    @media (min-width: 450px) and (max-width: 500px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 {
        border-radius: 11.57407vw; }
        .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 {
        border-radius: 9.25926vw; }
        .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 {
        border-radius: 8.16993vw; }
        .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 {
        border-radius: 7.30994vw; }
        .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 {
        border-radius: 6.61376vw; }
        .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
  .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 {
    border-radius: 13.88889vw;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
    @media (min-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 {
        border-radius: 2.60417vw;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 {
        border-radius: 2.89352vw;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 {
        border-radius: 2.60417vw;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); } }
    @media (min-width: 450px) and (max-width: 500px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 {
        border-radius: 11.57407vw; }
        .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 {
        border-radius: 9.25926vw; }
        .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 {
        border-radius: 8.16993vw; }
        .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 {
        border-radius: 7.30994vw; }
        .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 {
        border-radius: 6.61376vw; }
        .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
  .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 {
    margin-top: -0.02778vw;
    border-radius: 13.88889vw;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
    @media (min-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 {
        border-radius: 2.60417vw;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 {
        border-radius: 2.89352vw;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 {
        border-radius: 2.60417vw;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); } }
    @media (min-width: 450px) and (max-width: 500px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 {
        margin-top: -0.02315vw;
        border-radius: 11.57407vw; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 else {
          margin-top: -0.1px; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 {
        margin-top: -0.01852vw;
        border-radius: 9.25926vw; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 else {
          margin-top: -0.1px; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 {
        margin-top: -0.01634vw;
        border-radius: 8.16993vw; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 else {
          margin-top: -0.1px; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 {
        margin-top: -0.01462vw;
        border-radius: 7.30994vw; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 else {
          margin-top: -0.1px; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 {
        margin-top: -0.01323vw;
        border-radius: 6.61376vw; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 else {
          margin-top: -0.1px; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
  .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 {
    margin-top: -0.02778vw;
    border-radius: 13.88889vw;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
    @media (min-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 {
        border-radius: 2.60417vw;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 {
        border-radius: 2.89352vw;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 {
        border-radius: 2.60417vw;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); } }
    @media (min-width: 450px) and (max-width: 500px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 {
        margin-top: -0.02315vw;
        border-radius: 11.57407vw; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 else {
          margin-top: -0.1px; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 {
        margin-top: -0.01852vw;
        border-radius: 9.25926vw; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 else {
          margin-top: -0.1px; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 {
        margin-top: -0.01634vw;
        border-radius: 8.16993vw; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 else {
          margin-top: -0.1px; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 {
        margin-top: -0.01462vw;
        border-radius: 7.30994vw; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 else {
          margin-top: -0.1px; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 {
        margin-top: -0.01323vw;
        border-radius: 6.61376vw; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 else {
          margin-top: -0.1px; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar.open .teacher-app-cn-navbar-line {
      -webkit-transform: translateY(200%);
              transform: translateY(200%); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar.open .teacher-app-cn-navbar-line {
      -webkit-transform: translateY(200%);
              transform: translateY(200%); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar.open .teacher-app-cn-navbar-line {
      -webkit-transform: translateY(200%);
              transform: translateY(200%); } }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar.open .lines-2 {
      opacity: 0; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar.open .lines-2 {
      opacity: 0; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar.open .lines-2 {
      opacity: 0; } }
  .teacher-app-cn-navbar.open .lines-3 {
    opacity: 0; }
    @media (min-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-3 {
        opacity: 1; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .teacher-app-cn-navbar.open .lines-3 {
        opacity: 1; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-3 {
        opacity: 1; } }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar.close:hover .lines-2 {
      transition: 0s ease-in-out;
      opacity: 0; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar.close:hover .lines-2 {
      transition: 0s ease-in-out;
      opacity: 0; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar.close:hover .lines-2 {
      transition: 0s ease-in-out;
      opacity: 0; } }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar.close:hover .lines-1 .teacher-app-cn-navbar-line-1 {
      border-radius: 2.60417vw;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar.close:hover .lines-1 .teacher-app-cn-navbar-line-1 {
      border-radius: 2.89352vw;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar.close:hover .lines-1 .teacher-app-cn-navbar-line-1 {
      border-radius: 2.60417vw;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); } }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar.close:hover .lines-1 .teacher-app-cn-navbar-line-2 {
      border-radius: 2.60417vw;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar.close:hover .lines-1 .teacher-app-cn-navbar-line-2 {
      border-radius: 2.89352vw;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar.close:hover .lines-1 .teacher-app-cn-navbar-line-2 {
      border-radius: 2.60417vw;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); } }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar.close:hover .lines-3 .teacher-app-cn-navbar-line-1 {
      border-radius: 2.60417vw;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar.close:hover .lines-3 .teacher-app-cn-navbar-line-1 {
      border-radius: 2.89352vw;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar.close:hover .lines-3 .teacher-app-cn-navbar-line-1 {
      border-radius: 2.60417vw;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); } }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar.close:hover .lines-3 .teacher-app-cn-navbar-line-2 {
      border-radius: 2.60417vw;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar.close:hover .lines-3 .teacher-app-cn-navbar-line-2 {
      border-radius: 2.89352vw;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar.close:hover .lines-3 .teacher-app-cn-navbar-line-2 {
      border-radius: 2.60417vw;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); } }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar.close:hover .teacher-app-cn-navbar-line {
      -webkit-transform: translateY(200%);
              transform: translateY(200%); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar.close:hover .teacher-app-cn-navbar-line {
      -webkit-transform: translateY(200%);
              transform: translateY(200%); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar.close:hover .teacher-app-cn-navbar-line {
      -webkit-transform: translateY(200%);
              transform: translateY(200%); } }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar.close:hover {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar.close:hover {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar.close:hover {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg); } }

.teacher-app-cn-navbar-line {
  display: flex;
  transition: 0.3s ease-in-out;
  flex-direction: row;
  width: 7.77778vw;
  margin-top: 1.11111vw;
  height: 0.83333vw; }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar-line {
      width: 1.45833vw;
      margin-top: 0.20833vw;
      height: 0.15625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar-line {
      width: 1.62037vw;
      margin-top: 0.23148vw;
      height: 0.17361vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar-line {
      width: 1.45833vw;
      margin-top: 0.20833vw;
      height: 0.15625vw; } }
  .teacher-app-cn-navbar-line:first-child {
    margin-top: 0vw; }
    @media (min-width: 1300px) {
      .teacher-app-cn-navbar-line:first-child {
        margin-top: 0vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .teacher-app-cn-navbar-line:first-child {
        margin-top: 0vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .teacher-app-cn-navbar-line:first-child {
        margin-top: 0vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .teacher-app-cn-navbar-line:first-child {
        margin-top: 0vw; }
        .teacher-app-cn-navbar-line:first-child else {
          margin-top: 0px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .teacher-app-cn-navbar-line:first-child {
        margin-top: 0vw; }
        .teacher-app-cn-navbar-line:first-child else {
          margin-top: 0px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .teacher-app-cn-navbar-line:first-child {
        margin-top: 0vw; }
        .teacher-app-cn-navbar-line:first-child else {
          margin-top: 0px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .teacher-app-cn-navbar-line:first-child {
        margin-top: 0vw; }
        .teacher-app-cn-navbar-line:first-child else {
          margin-top: 0px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .teacher-app-cn-navbar-line:first-child {
        margin-top: 0vw; }
        .teacher-app-cn-navbar-line:first-child else {
          margin-top: 0px; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .teacher-app-cn-navbar-line {
      width: 6.48148vw;
      margin-top: 0.92593vw;
      height: 0.69444vw; }
      .teacher-app-cn-navbar-line else {
        width: 28px; }
      .teacher-app-cn-navbar-line else {
        margin-top: 4px; }
      .teacher-app-cn-navbar-line else {
        height: 3px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .teacher-app-cn-navbar-line {
      width: 5.18519vw;
      margin-top: 0.74074vw;
      height: 0.55556vw; }
      .teacher-app-cn-navbar-line else {
        width: 28px; }
      .teacher-app-cn-navbar-line else {
        margin-top: 4px; }
      .teacher-app-cn-navbar-line else {
        height: 3px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .teacher-app-cn-navbar-line {
      width: 4.57516vw;
      margin-top: 0.65359vw;
      height: 0.4902vw; }
      .teacher-app-cn-navbar-line else {
        width: 28px; }
      .teacher-app-cn-navbar-line else {
        margin-top: 4px; }
      .teacher-app-cn-navbar-line else {
        height: 3px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .teacher-app-cn-navbar-line {
      width: 4.09357vw;
      margin-top: 0.5848vw;
      height: 0.4386vw; }
      .teacher-app-cn-navbar-line else {
        width: 28px; }
      .teacher-app-cn-navbar-line else {
        margin-top: 4px; }
      .teacher-app-cn-navbar-line else {
        height: 3px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .teacher-app-cn-navbar-line {
      width: 3.7037vw;
      margin-top: 0.5291vw;
      height: 0.39683vw; }
      .teacher-app-cn-navbar-line else {
        width: 28px; }
      .teacher-app-cn-navbar-line else {
        margin-top: 4px; }
      .teacher-app-cn-navbar-line else {
        height: 3px; } }

.teacher-app-cn-navbar-line-1 {
  height: 100%;
  width: 50%;
  -webkit-transform-origin: right;
          transform-origin: right;
  transition: 0.3s all ease-in-out;
  background: #fff; }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar-line-1 {
      background: $tekie-amethyst;
      background: var(--accent-color); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar-line-1 {
      background: $tekie-amethyst;
      background: var(--accent-color); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar-line-1 {
      background: $tekie-amethyst;
      background: var(--accent-color); } }

.teacher-app-cn-navbar-line-2 {
  height: 100%;
  width: 50%;
  -webkit-transform-origin: left;
          transform-origin: left;
  transition: 0.3s all ease-in-out;
  background: #fff; }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar-line-2 {
      background: $tekie-amethyst;
      background: var(--accent-color); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar-line-2 {
      background: $tekie-amethyst;
      background: var(--accent-color); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar-line-2 {
      background: $tekie-amethyst;
      background: var(--accent-color); } }

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 #FF9084; }
  70% {
    -webkit-box-shadow: 0 0 0 8px rgba(204, 169, 44, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #FF9084; }
  70% {
    box-shadow: 0 0 0 8px rgba(204, 169, 44, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

.academicYearContainer {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media (min-width: 1000px) {
    .academicYearContainer {
      margin-bottom: 0.9375vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .academicYearContainer {
      margin-bottom: 1.04167vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .academicYearContainer {
      margin-bottom: 0.9375vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .academicYearContainer {
      margin-bottom: 0.75vw; } }
  .academicYearContainer .academicViewing {
    display: flex;
    flex-direction: column; }
    .academicYearContainer .academicViewing p {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      color: #838383; }
      @media (min-width: 1000px) {
        .academicYearContainer .academicViewing p {
          font-size: 0.78125vw;
          line-height: 1.5625vw; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .academicYearContainer .academicViewing p {
          font-size: 0.86806vw;
          line-height: 1.73611vw; } }
      @media (min-width: 1001px) and (max-width: 1600px) {
        .academicYearContainer .academicViewing p {
          font-size: 0.78125vw;
          line-height: 1.5625vw; } }
      @media (min-width: 1601px) and (max-width: 2000px) {
        .academicYearContainer .academicViewing p {
          font-size: 0.625vw;
          line-height: 1.25vw; } }
    .academicYearContainer .academicViewing h4 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      color: #333333; }
      @media (min-width: 1000px) {
        .academicYearContainer .academicViewing h4 {
          font-size: 0.9375vw;
          line-height: 1.5625vw; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .academicYearContainer .academicViewing h4 {
          font-size: 1.04167vw;
          line-height: 1.73611vw; } }
      @media (min-width: 1001px) and (max-width: 1600px) {
        .academicYearContainer .academicViewing h4 {
          font-size: 0.9375vw;
          line-height: 1.5625vw; } }
      @media (min-width: 1601px) and (max-width: 2000px) {
        .academicYearContainer .academicViewing h4 {
          font-size: 0.75vw;
          line-height: 1.25vw; } }
  .academicYearContainer h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #8C61CB;
    cursor: pointer; }
    @media (min-width: 1000px) {
      .academicYearContainer h3 {
        font-size: 0.78125vw;
        line-height: 1.5625vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .academicYearContainer h3 {
        font-size: 0.86806vw;
        line-height: 1.73611vw; } }
    @media (min-width: 1001px) and (max-width: 1600px) {
      .academicYearContainer h3 {
        font-size: 0.78125vw;
        line-height: 1.5625vw; } }
    @media (min-width: 1601px) and (max-width: 2000px) {
      .academicYearContainer h3 {
        font-size: 0.625vw;
        line-height: 1.25vw; } }

.testtest {
  width: 100%;
  outline: none; }

.setting-container {
  cursor: pointer;
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  background: #FAFAFA; }
  @media (min-width: 1000px) {
    .setting-container {
      border-radius: 0.625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .setting-container {
      border-radius: 0.69444vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .setting-container {
      border-radius: 0.625vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .setting-container {
      border-radius: 0.5vw; } }

.ebook-container {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media (min-width: 1000px) {
    .ebook-container {
      border-radius: 0.625vw;
      padding: 0.625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ebook-container {
      border-radius: 0.69444vw;
      padding: 0.69444vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .ebook-container {
      border-radius: 0.625vw;
      padding: 0.625vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .ebook-container {
      border-radius: 0.5vw;
      padding: 0.5vw; } }
  .ebook-container:hover {
    color: $tekie-amethyst;
    color: var(--accent-color);
    background: #FAFAFA;
    -webkit-transform: none;
            transform: none; }

.setting-text {
  display: flex;
  align-items: center;
  color: #333333; }
  @media (min-width: 1000px) {
    .setting-text {
      grid-gap: 0.78125vw;
      gap: 0.78125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .setting-text {
      grid-gap: 0.86806vw;
      gap: 0.86806vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .setting-text {
      grid-gap: 0.78125vw;
      gap: 0.78125vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .setting-text {
      grid-gap: 0.625vw;
      gap: 0.625vw; } }
  .setting-text p {
    color: #333;
    color: var(--tekie-grey-2, #333); }
    @media (min-width: 1000px) {
      .setting-text p {
        font-size: 1.09375vw;
        line-height: 1.5625vw;
        font-weight: 500; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .setting-text p {
        font-size: 1.21528vw;
        line-height: 1.73611vw;
        font-weight: 500; } }
    @media (min-width: 1001px) and (max-width: 1600px) {
      .setting-text p {
        font-size: 1.09375vw;
        line-height: 1.5625vw;
        font-weight: 500; } }
    @media (min-width: 1601px) and (max-width: 2000px) {
      .setting-text p {
        font-size: 0.875vw;
        line-height: 1.25vw;
        font-weight: 500; } }

.ebook-text {
  display: flex;
  align-items: center;
  color: #333333; }
  @media (min-width: 1000px) {
    .ebook-text {
      grid-gap: 0.78125vw;
      gap: 0.78125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ebook-text {
      grid-gap: 0.86806vw;
      gap: 0.86806vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .ebook-text {
      grid-gap: 0.78125vw;
      gap: 0.78125vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .ebook-text {
      grid-gap: 0.625vw;
      gap: 0.625vw; } }
  .ebook-text p {
    color: #8C61CB;
    color: var(--functional-amethyst, #8C61CB);
    font-family: Inter;
    font-style: normal; }
    @media (min-width: 1000px) {
      .ebook-text p {
        line-height: 1.5625vw;
        font-size: 1.09375vw;
        font-weight: 500; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .ebook-text p {
        line-height: 1.73611vw;
        font-size: 1.21528vw;
        font-weight: 500; } }
    @media (min-width: 1001px) and (max-width: 1600px) {
      .ebook-text p {
        line-height: 1.5625vw;
        font-size: 1.09375vw;
        font-weight: 500; } }
    @media (min-width: 1601px) and (max-width: 2000px) {
      .ebook-text p {
        line-height: 1.25vw;
        font-size: 0.875vw;
        font-weight: 500; } }

.profileDropdown {
  z-index: 1;
  left: 110%;
  background: #FFF;
  box-sizing: border-box;
  position: absolute;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2), 0px 2px 10px 0px rgba(0, 0, 0, 0.1); }
  @media (min-width: 1000px) {
    .profileDropdown {
      width: 14.29688vw;
      padding: 0.9375vw;
      grid-gap: 1.25vw;
      gap: 1.25vw;
      border-radius: 0.625vw;
      display: flex;
      flex-direction: column;
      align-items: flex-start; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .profileDropdown {
      width: 15.88542vw;
      padding: 1.04167vw;
      grid-gap: 1.38889vw;
      gap: 1.38889vw;
      border-radius: 0.69444vw;
      display: flex;
      flex-direction: column;
      align-items: flex-start; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .profileDropdown {
      width: 14.29688vw;
      padding: 0.9375vw;
      grid-gap: 1.25vw;
      gap: 1.25vw;
      border-radius: 0.625vw;
      display: flex;
      flex-direction: column;
      align-items: flex-start; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .profileDropdown {
      width: 11.4375vw;
      padding: 0.75vw;
      grid-gap: 1vw;
      gap: 1vw;
      border-radius: 0.5vw;
      display: flex;
      flex-direction: column;
      align-items: flex-start; } }
  @media (min-width: 1300px) {
    .profileDropdown {
      bottom: 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .profileDropdown {
      bottom: 1.38889vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .profileDropdown {
      bottom: 1.25vw; } }

.ebookDropdown {
  z-index: 1;
  left: 103%;
  box-sizing: border-box;
  position: absolute;
  border-radius: 0px 0px 5px 5px;
  background: #FFF;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2), 0px 2px 10px 0px rgba(0, 0, 0, 0.1); }
  @media (min-width: 1000px) {
    .ebookDropdown {
      grid-gap: 0.3125vw;
      gap: 0.3125vw;
      width: 7.89062vw;
      display: flex;
      flex-direction: column;
      align-items: flex-start; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ebookDropdown {
      grid-gap: 0.34722vw;
      gap: 0.34722vw;
      width: 8.76736vw;
      display: flex;
      flex-direction: column;
      align-items: flex-start; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .ebookDropdown {
      grid-gap: 0.3125vw;
      gap: 0.3125vw;
      width: 7.89062vw;
      display: flex;
      flex-direction: column;
      align-items: flex-start; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .ebookDropdown {
      grid-gap: 0.25vw;
      gap: 0.25vw;
      width: 6.3125vw;
      display: flex;
      flex-direction: column;
      align-items: flex-start; } }

.ebookLinkDropdown {
  z-index: 1;
  left: 103%;
  box-sizing: border-box;
  width: -webkit-max-content;
  width: max-content;
  max-height: 300px;
  overflow: auto;
  position: absolute;
  border-radius: 0px 0px 5px 5px;
  background: #FFF;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2), 0px 2px 10px 0px rgba(0, 0, 0, 0.1); }
  @media (min-width: 1000px) {
    .ebookLinkDropdown {
      grid-gap: 0.3125vw;
      gap: 0.3125vw;
      top: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ebookLinkDropdown {
      grid-gap: 0.34722vw;
      gap: 0.34722vw;
      top: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .ebookLinkDropdown {
      grid-gap: 0.3125vw;
      gap: 0.3125vw;
      top: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .ebookLinkDropdown {
      grid-gap: 0.25vw;
      gap: 0.25vw;
      top: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start; } }

.ebook-link {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(140, 97, 203, 0.1); }
  @media (min-width: 1000px) {
    .ebook-link {
      padding: 0.3125vw 1.25vw;
      width: 100%;
      grid-gap: 0.625vw;
      gap: 0.625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ebook-link {
      padding: 0.34722vw 1.38889vw;
      width: 100%;
      grid-gap: 0.69444vw;
      gap: 0.69444vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .ebook-link {
      padding: 0.3125vw 1.25vw;
      width: 100%;
      grid-gap: 0.625vw;
      gap: 0.625vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .ebook-link {
      padding: 0.25vw 1vw;
      width: 100%;
      grid-gap: 0.5vw;
      gap: 0.5vw; } }
  .ebook-link > p {
    color: #8C61CB;
    color: var(--functional-amethyst, #8C61CB);
    font-family: Inter;
    font-style: normal; }
    @media (min-width: 1000px) {
      .ebook-link > p {
        line-height: 1.5625vw;
        font-size: 0.9375vw;
        margin: 0;
        padding: 0;
        font-weight: 500; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .ebook-link > p {
        line-height: 1.73611vw;
        font-size: 1.04167vw;
        margin: 0;
        padding: 0;
        font-weight: 500; } }
    @media (min-width: 1001px) and (max-width: 1600px) {
      .ebook-link > p {
        line-height: 1.5625vw;
        font-size: 0.9375vw;
        margin: 0;
        padding: 0;
        font-weight: 500; } }
    @media (min-width: 1601px) and (max-width: 2000px) {
      .ebook-link > p {
        line-height: 1.25vw;
        font-size: 0.75vw;
        margin: 0;
        padding: 0;
        font-weight: 500; } }

.ebook-link {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(140, 97, 203, 0.1); }
  @media (min-width: 1000px) {
    .ebook-link {
      grid-gap: 0.625vw;
      gap: 0.625vw;
      padding: 0.3125vw 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ebook-link {
      grid-gap: 0.69444vw;
      gap: 0.69444vw;
      padding: 0.34722vw 1.38889vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .ebook-link {
      grid-gap: 0.625vw;
      gap: 0.625vw;
      padding: 0.3125vw 1.25vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .ebook-link {
      grid-gap: 0.5vw;
      gap: 0.5vw;
      padding: 0.25vw 1vw; } }

.grade-text {
  color: #333;
  color: var(--tekie-grey-2, #333); }
  @media (min-width: 1000px) {
    .grade-text {
      padding: 0.3125vw 1.25vw;
      line-height: 1.5625vw;
      font-size: 0.9375vw;
      font-weight: 500; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .grade-text {
      padding: 0.34722vw 1.38889vw;
      line-height: 1.73611vw;
      font-size: 1.04167vw;
      font-weight: 500; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .grade-text {
      padding: 0.3125vw 1.25vw;
      line-height: 1.5625vw;
      font-size: 0.9375vw;
      font-weight: 500; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .grade-text {
      padding: 0.25vw 1vw;
      line-height: 1.25vw;
      font-size: 0.75vw;
      font-weight: 500; } }

.active-current-ebook-link {
  color: #8C61CB;
  color: var(--functional-amethyst, #8C61CB);
  font-style: normal;
  text-decoration: none; }
  @media (min-width: 1000px) {
    .active-current-ebook-link {
      line-height: 1.5625vw;
      font-size: 0.9375vw;
      font-weight: 500; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .active-current-ebook-link {
      line-height: 1.73611vw;
      font-size: 1.04167vw;
      font-weight: 500; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .active-current-ebook-link {
      line-height: 1.5625vw;
      font-size: 0.9375vw;
      font-weight: 500; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .active-current-ebook-link {
      line-height: 1.25vw;
      font-size: 0.75vw;
      font-weight: 500; } }

.inactive-ebook-link {
  text-decoration: none;
  color: #333;
  color: var(--tekie-grey-2, #333);
  font-family: Inter;
  font-style: normal; }
  @media (min-width: 1000px) {
    .inactive-ebook-link {
      padding: 0.3125vw 2.5vw;
      font-size: 0.9375vw;
      line-height: 1.5625vw;
      font-weight: 500; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .inactive-ebook-link {
      padding: 0.34722vw 2.77778vw;
      font-size: 1.04167vw;
      line-height: 1.73611vw;
      font-weight: 500; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .inactive-ebook-link {
      padding: 0.3125vw 2.5vw;
      font-size: 0.9375vw;
      line-height: 1.5625vw;
      font-weight: 500; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .inactive-ebook-link {
      padding: 0.25vw 2vw;
      font-size: 0.75vw;
      line-height: 1.25vw;
      font-weight: 500; } }

.active-ebook-link {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(140, 97, 203, 0.1); }
  @media (min-width: 1000px) {
    .active-ebook-link {
      grid-gap: 0.625vw;
      gap: 0.625vw;
      padding: 0.3125vw 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .active-ebook-link {
      grid-gap: 0.69444vw;
      gap: 0.69444vw;
      padding: 0.34722vw 1.38889vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .active-ebook-link {
      grid-gap: 0.625vw;
      gap: 0.625vw;
      padding: 0.3125vw 1.25vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .active-ebook-link {
      grid-gap: 0.5vw;
      gap: 0.5vw;
      padding: 0.25vw 1vw; } }

.profile {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  color: #333;
  color: var(--tekie-grey-2, #333);
  /* Body/Content 14-medium */ }
  @media (min-width: 1000px) {
    .profile {
      font-size: 1.09375vw;
      line-height: 1.5625vw;
      font-weight: 500; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .profile {
      font-size: 1.21528vw;
      line-height: 1.73611vw;
      font-weight: 500; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .profile {
      font-size: 1.09375vw;
      line-height: 1.5625vw;
      font-weight: 500; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .profile {
      font-size: 0.875vw;
      line-height: 1.25vw;
      font-weight: 500; } }
  @media (min-width: 1000px) {
    .profile img {
      width: 1.875vw;
      height: 1.875vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .profile img {
      width: 2.08333vw;
      height: 2.08333vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .profile img {
      width: 1.875vw;
      height: 1.875vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .profile img {
      width: 1.5vw;
      height: 1.5vw; } }

.ebookContainer {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  color: #333;
  color: var(--tekie-grey-2, #333);
  padding: 0;
  margin: 0;
  /* Body/Content 14-medium */ }
  @media (min-width: 1000px) {
    .ebookContainer {
      font-size: 1.09375vw;
      line-height: 1.5625vw;
      font-weight: 500; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ebookContainer {
      font-size: 1.21528vw;
      line-height: 1.73611vw;
      font-weight: 500; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .ebookContainer {
      font-size: 1.09375vw;
      line-height: 1.5625vw;
      font-weight: 500; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .ebookContainer {
      font-size: 0.875vw;
      line-height: 1.25vw;
      font-weight: 500; } }
  @media (min-width: 1000px) {
    .ebookContainer img {
      width: 1.875vw;
      height: 1.875vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ebookContainer img {
      width: 2.08333vw;
      height: 2.08333vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .ebookContainer img {
      width: 1.875vw;
      height: 1.875vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .ebookContainer img {
      width: 1.5vw;
      height: 1.5vw; } }

:root {
  --accent-color:$tekie-amethyst;
  --accent-color-110: $tekie-amethyst-dark; }

.logoutModal_Inter__2OxCg, .logoutModal_Inter-regular__2oNcQ, .logoutModal_Inter-SemiBold__2A2GA, .logoutModal_Inter-Bold__2YgnN {
  font-family: 'Inter', 'Nunito', sans-serif; }

.logoutModal_Inter-regular__2oNcQ {
  font-weight: 400; }

.logoutModal_Inter-SemiBold__2A2GA {
  font-weight: 600; }

.logoutModal_Inter-Bold__2YgnN {
  font-weight: 800; }

.logoutModal_sessionModalContainer__1ucpj {
  z-index: 9999;
  background-color: rgba(125, 138, 143, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter'; }

.logoutModal_sessionModal__19xvl {
  width: 500px; }

.logoutModal_header__2kuHp {
  padding: 18px 20px;
  padding-bottom: 24px;
  background-color: #FAF6FF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  font-size: 18px; }

.logoutModal_header_headingContainer__uBErx {
  display: flex;
  justify-content: center;
  align-items: center; }

.logoutModal_body__3c__z {
  background-color: #FFFFFF;
  padding: 24px 22px 12px 22px;
  color: #424242;
  font-weight: 500; }

.logoutModal_sessionIcon__7jtSn {
  margin-right: 16px;
  padding: 16px;
  background-color: #FFFFFF;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center; }

.logoutModal_footer__178_o {
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-top: 1px solid #F1F1F1;
  background-color: #FFFFFF; }

.logoutModal_closeModalIcon__1WgQn {
  cursor: pointer;
  margin-right: 0.5rem;
  margin-left: 1rem;
  padding: 0px 5px 2px 5px;
  border-radius: 4px; }
  .logoutModal_closeModalIcon__1WgQn:hover {
    background-color: $tekie-amethyst;
    background-color: var(--accent-color);
    color: #FFFFFF; }
    .logoutModal_closeModalIcon__1WgQn:hover svg > path {
      fill: white !important; }

.logoutModal_completed__JfxAf, .logoutModal_yetToBegin__3m2G3, .logoutModal_unAttended__3-W-r, .logoutModal_inProgress__2tnA4 {
  background-color: #E6F7F4;
  color: #01AA93;
  padding: 8px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .logoutModal_completed__JfxAf img, .logoutModal_yetToBegin__3m2G3 img, .logoutModal_unAttended__3-W-r img, .logoutModal_inProgress__2tnA4 img {
    margin-right: 10px; }

.logoutModal_yetToBegin__3m2G3 {
  background-color: #ebecec;
  color: $tekie-amethyst-dark;
  color: var(--accent-color-110); }

.logoutModal_unAttended__3-W-r {
  background-color: #FBEDEE;
  color: #FF5744; }

.logoutModal_inProgress__2tnA4 {
  background-color: #FFF7E7;
  color: #FAAD14; }

.logoutModal_headingContainer__v1vE- {
  margin: 16px auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px; }

.logoutModal_sessionDetails__1WAwQ {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px; }

.logoutModal_title__36jfl {
  color: #212121;
  font-weight: 600; }

.logoutModal_timeAndDay__191pi {
  color: #757575;
  font-size: 12px; }

.logoutModal_timeAndOtpContainer__1Js17 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px; }

.logoutModal_gradeAndSection__dNDMF, .logoutModal_otp__1rymd {
  background-color: #EEEEEE;
  color: #282828;
  padding: 4px 8px;
  font-weight: 500;
  margin-right: 16px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  gap: 5px; }

.logoutModal_otp__1rymd {
  display: inline-block;
  color: #757575; }

.logoutModal_topicThumbnail__3JfEA {
  border: 1px solid #EEEEEE;
  border-radius: 16px;
  height: 110px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center; }

.logoutModal_reminderBtns__3hT7U {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 20px; }

.logoutModal_moreDetailsHeading__liQap {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 16px; }

.logoutModal_moreDetailsContainer__2OOlv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-row-gap: 24px;
  row-gap: 24px;
  flex-wrap: wrap; }

.logoutModal_itemContainer__2W1mD {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 33.33%; }

.logoutModal_sessionTypePill__1_OQa {
  background-color: #EEEEEE;
  color: #282828;
  font-size: 10px;
  border-radius: 16px;
  padding: 2px 8px;
  margin-right: 8px; }

.logoutModal_itemLabelAndTextContainer__33vXq {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px; }

.logoutModal_itemIcon__uH794 {
  color: #9E9E9E;
  margin-right: 4px; }

.logoutModal_itemLabel__1DzP2 {
  color: #757575;
  font-size: 10px; }

.logoutModal_link__SE3nM {
  color: #8C61CB;
  cursor: pointer;
  font-size: 12px; }

.logoutModal_timeDaySeparator__2zxpt {
  display: inline-block;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  background-color: #757575;
  color: #757575;
  margin: auto 10px;
  margin-bottom: 4px; }

.sessionEmbed_lato__3wTpA, .sessionEmbed_lato-light__3NIfr, .sessionEmbed_lato-regular__2nAjQ, .sessionEmbed_lato-hairline__2S5M- {
  font-family: 'Lato', sans-serif; }

.sessionEmbed_lato-light__3NIfr {
  font-weight: 300; }

.sessionEmbed_lato-regular__2nAjQ {
  font-weight: 400; }

.sessionEmbed_lato-hairline__2S5M- {
  font-weight: 100; }

.sessionEmbed_Inter__v-cxv, .sessionEmbed_Inter-regular__3OGbI, .sessionEmbed_Inter-SemiBold__1Vk65, .sessionEmbed_Inter-Bold__3Ipm1 {
  font-family: 'Inter', 'Nunito', sans-serif; }

.sessionEmbed_Inter-regular__3OGbI {
  font-weight: 400; }

.sessionEmbed_Inter-SemiBold__1Vk65 {
  font-weight: 600; }

.sessionEmbed_Inter-Bold__3Ipm1 {
  font-weight: 800; }

:root {
  --accent-color:$tekie-amethyst;
  --accent-color-110: $tekie-amethyst-dark; }

.sessionEmbed_embedPageContainer___PJHt, .sessionEmbed_modalPopup__f4n58, .sessionEmbed_otpmodalPopup__2rAHO {
  font-family: 'Inter'; }

.sessionEmbed_embedPageContainer___PJHt {
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 0; }
  @media (min-width: 1300px) {
    .sessionEmbed_embedPageContainer___PJHt {
      padding: 1.25vw 1.25vw 1.25vw 0vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_embedPageContainer___PJHt {
      padding: 1.38889vw 1.38889vw 1.38889vw 0vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sessionEmbed_embedPageContainer___PJHt {
      padding: 1.25vw 1.25vw 1.25vw 0vw; } }

.sessionEmbed_iframeContainer__1N_5l {
  background-color: white;
  overflow-y: hidden;
  position: relative; }
  @media (min-width: 1000px) {
    .sessionEmbed_iframeContainer__1N_5l {
      height: calc(100vh - 5.10417vw); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_iframeContainer__1N_5l {
      height: calc(100vh - 5.10417vw); } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .sessionEmbed_iframeContainer__1N_5l {
      height: calc(100vh - 5.10417vw); } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .sessionEmbed_iframeContainer__1N_5l {
      height: calc(100vh - 5.10417vw); } }
  @media (min-width: 1300px) {
    .sessionEmbed_iframeContainer__1N_5l {
      margin-top: 0.78125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_iframeContainer__1N_5l {
      margin-top: 0.86806vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sessionEmbed_iframeContainer__1N_5l {
      margin-top: 0.78125vw; } }

.sessionEmbed_sessionEmbedIframeContainer__xC6JC {
  height: 100%;
  width: 100%;
  border: none;
  background-color: white; }
  @media (min-width: 1300px) {
    .sessionEmbed_sessionEmbedIframeContainer__xC6JC {
      padding: 1.04167vw;
      border-radius: 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_sessionEmbedIframeContainer__xC6JC {
      padding: 1.15741vw;
      border-radius: 0.92593vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sessionEmbed_sessionEmbedIframeContainer__xC6JC {
      padding: 1.04167vw;
      border-radius: 0.83333vw; } }

.sessionEmbed_embedPageHeader__2ZPEB {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9; }

.sessionEmbed_currentTime__2jd-0 {
  font-weight: 700;
  cursor: pointer; }

.sessionEmbed_routerLink__2wnMz, .sessionEmbed_currentTime__2jd-0 {
  text-decoration: none;
  background-color: transparent; }
  .sessionEmbed_routerLink__2wnMz button span, .sessionEmbed_currentTime__2jd-0 button span {
    font-size: 3.86473vw;
    line-height: 4.83092vw;
    font-weight: 500; }
    @media (min-width: 1300px) {
      .sessionEmbed_routerLink__2wnMz button span, .sessionEmbed_currentTime__2jd-0 button span {
        font-size: 0.83333vw;
        line-height: 1.04167vw;
        font-weight: 500; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .sessionEmbed_routerLink__2wnMz button span, .sessionEmbed_currentTime__2jd-0 button span {
        font-size: 0.92593vw;
        line-height: 1.15741vw;
        font-weight: 500; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .sessionEmbed_routerLink__2wnMz button span, .sessionEmbed_currentTime__2jd-0 button span {
        font-size: 0.83333vw;
        line-height: 1.04167vw;
        font-weight: 500; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .sessionEmbed_routerLink__2wnMz button span, .sessionEmbed_currentTime__2jd-0 button span {
        font-size: 3.22061vw;
        line-height: 4.02576vw; }
        .sessionEmbed_routerLink__2wnMz button span else, .sessionEmbed_currentTime__2jd-0 button span else {
          font-size: 16px; }
        .sessionEmbed_routerLink__2wnMz button span else, .sessionEmbed_currentTime__2jd-0 button span else {
          line-height: 20px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .sessionEmbed_routerLink__2wnMz button span, .sessionEmbed_currentTime__2jd-0 button span {
        font-size: 2.57649vw;
        line-height: 3.22061vw; }
        .sessionEmbed_routerLink__2wnMz button span else, .sessionEmbed_currentTime__2jd-0 button span else {
          font-size: 16px; }
        .sessionEmbed_routerLink__2wnMz button span else, .sessionEmbed_currentTime__2jd-0 button span else {
          line-height: 20px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .sessionEmbed_routerLink__2wnMz button span, .sessionEmbed_currentTime__2jd-0 button span {
        font-size: 2.27337vw;
        line-height: 2.84172vw; }
        .sessionEmbed_routerLink__2wnMz button span else, .sessionEmbed_currentTime__2jd-0 button span else {
          font-size: 16px; }
        .sessionEmbed_routerLink__2wnMz button span else, .sessionEmbed_currentTime__2jd-0 button span else {
          line-height: 20px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .sessionEmbed_routerLink__2wnMz button span, .sessionEmbed_currentTime__2jd-0 button span {
        font-size: 2.03407vw;
        line-height: 2.54259vw; }
        .sessionEmbed_routerLink__2wnMz button span else, .sessionEmbed_currentTime__2jd-0 button span else {
          font-size: 16px; }
        .sessionEmbed_routerLink__2wnMz button span else, .sessionEmbed_currentTime__2jd-0 button span else {
          line-height: 20px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .sessionEmbed_routerLink__2wnMz button span, .sessionEmbed_currentTime__2jd-0 button span {
        font-size: 1.84035vw;
        line-height: 2.30044vw; }
        .sessionEmbed_routerLink__2wnMz button span else, .sessionEmbed_currentTime__2jd-0 button span else {
          font-size: 16px; }
        .sessionEmbed_routerLink__2wnMz button span else, .sessionEmbed_currentTime__2jd-0 button span else {
          line-height: 20px; } }
  .sessionEmbed_routerLink__2wnMz svg, .sessionEmbed_currentTime__2jd-0 svg {
    height: 4.34783vw;
    width: 3.86473vw;
    font-size: 4.34783vw; }
    @media (min-width: 1300px) {
      .sessionEmbed_routerLink__2wnMz svg, .sessionEmbed_currentTime__2jd-0 svg {
        height: 0.9375vw;
        width: 0.83333vw;
        font-size: 0.9375vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .sessionEmbed_routerLink__2wnMz svg, .sessionEmbed_currentTime__2jd-0 svg {
        height: 1.04167vw;
        width: 0.92593vw;
        font-size: 1.04167vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .sessionEmbed_routerLink__2wnMz svg, .sessionEmbed_currentTime__2jd-0 svg {
        height: 0.9375vw;
        width: 0.83333vw;
        font-size: 0.9375vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .sessionEmbed_routerLink__2wnMz svg, .sessionEmbed_currentTime__2jd-0 svg {
        height: 3.62319vw;
        width: 3.22061vw;
        font-size: 3.62319vw; }
        .sessionEmbed_routerLink__2wnMz svg else, .sessionEmbed_currentTime__2jd-0 svg else {
          height: 18px; }
        .sessionEmbed_routerLink__2wnMz svg else, .sessionEmbed_currentTime__2jd-0 svg else {
          width: 16px; }
        .sessionEmbed_routerLink__2wnMz svg else, .sessionEmbed_currentTime__2jd-0 svg else {
          font-size: 18px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .sessionEmbed_routerLink__2wnMz svg, .sessionEmbed_currentTime__2jd-0 svg {
        height: 2.89855vw;
        width: 2.57649vw;
        font-size: 2.89855vw; }
        .sessionEmbed_routerLink__2wnMz svg else, .sessionEmbed_currentTime__2jd-0 svg else {
          height: 18px; }
        .sessionEmbed_routerLink__2wnMz svg else, .sessionEmbed_currentTime__2jd-0 svg else {
          width: 16px; }
        .sessionEmbed_routerLink__2wnMz svg else, .sessionEmbed_currentTime__2jd-0 svg else {
          font-size: 18px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .sessionEmbed_routerLink__2wnMz svg, .sessionEmbed_currentTime__2jd-0 svg {
        height: 2.55754vw;
        width: 2.27337vw;
        font-size: 2.55754vw; }
        .sessionEmbed_routerLink__2wnMz svg else, .sessionEmbed_currentTime__2jd-0 svg else {
          height: 18px; }
        .sessionEmbed_routerLink__2wnMz svg else, .sessionEmbed_currentTime__2jd-0 svg else {
          width: 16px; }
        .sessionEmbed_routerLink__2wnMz svg else, .sessionEmbed_currentTime__2jd-0 svg else {
          font-size: 18px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .sessionEmbed_routerLink__2wnMz svg, .sessionEmbed_currentTime__2jd-0 svg {
        height: 2.28833vw;
        width: 2.03407vw;
        font-size: 2.28833vw; }
        .sessionEmbed_routerLink__2wnMz svg else, .sessionEmbed_currentTime__2jd-0 svg else {
          height: 18px; }
        .sessionEmbed_routerLink__2wnMz svg else, .sessionEmbed_currentTime__2jd-0 svg else {
          width: 16px; }
        .sessionEmbed_routerLink__2wnMz svg else, .sessionEmbed_currentTime__2jd-0 svg else {
          font-size: 18px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .sessionEmbed_routerLink__2wnMz svg, .sessionEmbed_currentTime__2jd-0 svg {
        height: 2.07039vw;
        width: 1.84035vw;
        font-size: 2.07039vw; }
        .sessionEmbed_routerLink__2wnMz svg else, .sessionEmbed_currentTime__2jd-0 svg else {
          height: 18px; }
        .sessionEmbed_routerLink__2wnMz svg else, .sessionEmbed_currentTime__2jd-0 svg else {
          width: 16px; }
        .sessionEmbed_routerLink__2wnMz svg else, .sessionEmbed_currentTime__2jd-0 svg else {
          font-size: 18px; } }

.sessionEmbed_routerLink__2wnMz {
  display: flex;
  border-radius: 8px;
  border: 1px solid #D2D2D2;
  color: #858585;
  background-color: #fff;
  align-items: center;
  justify-content: center; }
  @media (min-width: 1300px) {
    .sessionEmbed_routerLink__2wnMz {
      width: 2.08333vw;
      height: 2.08333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_routerLink__2wnMz {
      width: 2.31481vw;
      height: 2.31481vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sessionEmbed_routerLink__2wnMz {
      width: 2.08333vw;
      height: 2.08333vw; } }
  .sessionEmbed_routerLink__2wnMz button {
    grid-gap: 0px !important;
    gap: 0px !important; }

.sessionEmbed_timetableCalendarLoaderBackdrop__CUlz5 {
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: #fff;
  height: 100%;
  width: 100%;
  z-index: 99; }

.sessionEmbed_endClassCtaContainer__a1rnU {
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 1300px) {
    .sessionEmbed_endClassCtaContainer__a1rnU {
      grid-gap: 0.3125vw;
      gap: 0.3125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_endClassCtaContainer__a1rnU {
      grid-gap: 0.34722vw;
      gap: 0.34722vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sessionEmbed_endClassCtaContainer__a1rnU {
      grid-gap: 0.3125vw;
      gap: 0.3125vw; } }

.sessionEmbed_ctaContainer__2RNsc {
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 1300px) {
    .sessionEmbed_ctaContainer__2RNsc {
      grid-gap: 1.25vw;
      gap: 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_ctaContainer__2RNsc {
      grid-gap: 1.38889vw;
      gap: 1.38889vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sessionEmbed_ctaContainer__2RNsc {
      grid-gap: 1.25vw;
      gap: 1.25vw; } }

.sessionEmbed_endClassLink__1OMfG {
  box-sizing: border-box;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #FFCACF;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 1300px) {
    .sessionEmbed_endClassLink__1OMfG {
      grid-gap: 0.41667vw;
      gap: 0.41667vw;
      padding: 0.625vw 0.83333vw;
      border-radius: 0.52083vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_endClassLink__1OMfG {
      grid-gap: 0.46296vw;
      gap: 0.46296vw;
      padding: 0.69444vw 0.92593vw;
      border-radius: 0.5787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sessionEmbed_endClassLink__1OMfG {
      grid-gap: 0.41667vw;
      gap: 0.41667vw;
      padding: 0.625vw 0.83333vw;
      border-radius: 0.52083vw; } }

.sessionEmbed_endClassBtn__1-sfx {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  color: #D34B57;
  margin: 0; }
  @media (min-width: 1300px) {
    .sessionEmbed_endClassBtn__1-sfx {
      font-size: 0.9375vw;
      line-height: 1.14583vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_endClassBtn__1-sfx {
      font-size: 1.04167vw;
      line-height: 1.27315vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sessionEmbed_endClassBtn__1-sfx {
      font-size: 0.9375vw;
      line-height: 1.14583vw; } }

.sessionEmbed_linkContainer__htRs2 {
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: center; }
  @media (min-width: 1300px) {
    .sessionEmbed_linkContainer__htRs2 {
      grid-gap: 1.25vw;
      gap: 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_linkContainer__htRs2 {
      grid-gap: 1.38889vw;
      gap: 1.38889vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sessionEmbed_linkContainer__htRs2 {
      grid-gap: 1.25vw;
      gap: 1.25vw; } }

.sessionEmbed_attendance__33L4R {
  border: 1px solid #D2D2D2;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  cursor: pointer; }
  @media (min-width: 1300px) {
    .sessionEmbed_attendance__33L4R {
      font-size: 0.9375vw;
      border-radius: 0.41667vw;
      line-height: 0.88542vw;
      padding-right: 1.04167vw;
      height: 2.08333vw;
      min-width: 8.07292vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_attendance__33L4R {
      font-size: 1.04167vw;
      border-radius: 0.46296vw;
      line-height: 0.9838vw;
      padding-right: 1.15741vw;
      height: 2.31481vw;
      min-width: 8.96991vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sessionEmbed_attendance__33L4R {
      font-size: 0.9375vw;
      border-radius: 0.41667vw;
      line-height: 0.88542vw;
      padding-right: 1.04167vw;
      height: 2.08333vw;
      min-width: 8.07292vw; } }

.sessionEmbed_joinedText__3KI8V {
  font-weight: 500;
  color: #858585; }
  @media (min-width: 1300px) {
    .sessionEmbed_joinedText__3KI8V {
      font-size: 1.04167vw;
      line-height: 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_joinedText__3KI8V {
      font-size: 1.15741vw;
      line-height: 1.38889vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sessionEmbed_joinedText__3KI8V {
      font-size: 1.04167vw;
      line-height: 1.25vw; } }

.sessionEmbed_modalPopup__f4n58 {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  background: white;
  flex-direction: column;
  align-items: center;
  z-index: 99999999; }
  @media (min-width: 1300px) {
    .sessionEmbed_modalPopup__f4n58 {
      width: 33.33333vw;
      border-radius: 0.625vw;
      height: 35.78125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_modalPopup__f4n58 {
      width: 37.03704vw;
      border-radius: 0.69444vw;
      height: 39.75694vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sessionEmbed_modalPopup__f4n58 {
      width: 33.33333vw;
      border-radius: 0.625vw;
      height: 35.78125vw; } }

.sessionEmbed_modalOverlay__2MWLX {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999999; }

.sessionEmbed_attendanceText__2CuEy {
  display: flex;
  justify-content: space-between;
  background-color: #FAF7FF;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #D4D4D4; }
  @media (min-width: 1300px) {
    .sessionEmbed_attendanceText__2CuEy {
      border-radius: 0.625vw 0.625vw 0 0; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_attendanceText__2CuEy {
      border-radius: 0.69444vw 0.69444vw 0 0; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sessionEmbed_attendanceText__2CuEy {
      border-radius: 0.625vw 0.625vw 0 0; } }
  .sessionEmbed_attendanceText__2CuEy > p {
    font-weight: 600;
    color: #000000;
    margin: 0; }
    @media (min-width: 1300px) {
      .sessionEmbed_attendanceText__2CuEy > p {
        font-size: 1.25vw;
        margin: 0.83333vw 0 0.83333vw 1.04167vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .sessionEmbed_attendanceText__2CuEy > p {
        font-size: 1.38889vw;
        margin: 0.92593vw 0 0.92593vw 1.15741vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .sessionEmbed_attendanceText__2CuEy > p {
        font-size: 1.25vw;
        margin: 0.83333vw 0 0.83333vw 1.04167vw; } }
  @media (min-width: 1300px) {
    .sessionEmbed_attendanceText__2CuEy > div {
      padding-right: 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_attendanceText__2CuEy > div {
      padding-right: 0.92593vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sessionEmbed_attendanceText__2CuEy > div {
      padding-right: 0.83333vw; } }

.sessionEmbed_closeButton__3wNPt {
  width: 95%;
  color: #858585;
  background-color: #fff;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #E2E2E2; }
  @media (min-width: 1300px) {
    .sessionEmbed_closeButton__3wNPt {
      height: 3.02083vw;
      border-radius: 0.625vw;
      margin: 1.04167vw 1.04167vw 0.625vw 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_closeButton__3wNPt {
      height: 3.35648vw;
      border-radius: 0.69444vw;
      margin: 1.15741vw 1.15741vw 0.69444vw 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sessionEmbed_closeButton__3wNPt {
      height: 3.02083vw;
      border-radius: 0.625vw;
      margin: 1.04167vw 1.04167vw 0.625vw 1.04167vw; } }

.sessionEmbed_table__74LJm {
  align-items: center;
  justify-content: center;
  overflow: auto;
  width: 92%;
  border: 1px solid #E2E2E2;
  border-collapse: collapse; }
  @media (min-width: 1300px) {
    .sessionEmbed_table__74LJm {
      height: 26.04167vw;
      border-radius: 0.625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_table__74LJm {
      height: 28.93519vw;
      border-radius: 0.69444vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sessionEmbed_table__74LJm {
      height: 26.04167vw;
      border-radius: 0.625vw; } }

.sessionEmbed_tableHead__ZR38w {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border-color: '#0000000A';
  background: white;
  z-index: 1;
  font-weight: 600;
  border-bottom: 1px solid #E2E2E2;
  color: '##858585';
  background: 'white';
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 1300px) {
    .sessionEmbed_tableHead__ZR38w {
      font-size: 0.625vw;
      height: 2.1875vw;
      line-height: 0.9375vw;
      color: #858585; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_tableHead__ZR38w {
      font-size: 0.69444vw;
      height: 2.43056vw;
      line-height: 1.04167vw;
      color: #858585; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sessionEmbed_tableHead__ZR38w {
      font-size: 0.625vw;
      height: 2.1875vw;
      line-height: 0.9375vw;
      color: #858585; } }

.sessionEmbed_tableData__1ztYJ {
  position: relative;
  display: flex;
  border-bottom: 1px solid #E2E2E2;
  border-collapse: collapse; }

.sessionEmbed_studentDetail__3b8d9 {
  display: flex;
  flex-direction: column; }
  @media (min-width: 1300px) {
    .sessionEmbed_studentDetail__3b8d9 {
      font-size: 0.9375vw;
      grid-gap: 0.3125vw;
      gap: 0.3125vw;
      padding: 0.83333vw 1.45833vw;
      width: 100%; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_studentDetail__3b8d9 {
      font-size: 1.04167vw;
      grid-gap: 0.34722vw;
      gap: 0.34722vw;
      padding: 0.92593vw 1.62037vw;
      width: 100%; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sessionEmbed_studentDetail__3b8d9 {
      font-size: 0.9375vw;
      grid-gap: 0.3125vw;
      gap: 0.3125vw;
      padding: 0.83333vw 1.45833vw;
      width: 100%; } }

.sessionEmbed_attendanceStatusContainer__2TyYA {
  display: flex;
  width: 90%;
  border: 1px solid #E2E2E2; }
  @media (min-width: 1300px) {
    .sessionEmbed_attendanceStatusContainer__2TyYA {
      margin: 0.83333vw 1.25vw;
      border-radius: 0.625vw;
      padding: 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_attendanceStatusContainer__2TyYA {
      margin: 0.92593vw 1.38889vw;
      border-radius: 0.69444vw;
      padding: 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sessionEmbed_attendanceStatusContainer__2TyYA {
      margin: 0.83333vw 1.25vw;
      border-radius: 0.625vw;
      padding: 0.41667vw; } }
  @media (min-width: 1300px) {
    .sessionEmbed_attendanceStatusContainer__2TyYA button {
      height: 2.1875vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_attendanceStatusContainer__2TyYA button {
      height: 2.43056vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sessionEmbed_attendanceStatusContainer__2TyYA button {
      height: 2.1875vw; } }

.sessionEmbed_absentButton__1xthQ {
  width: 50%;
  cursor: pointer;
  background: rgba(211, 75, 87, 0.12);
  border: none;
  color: #D34B57; }
  @media (min-width: 1300px) {
    .sessionEmbed_absentButton__1xthQ {
      border-radius: 0.625vw;
      font-size: 0.83333vw;
      line-height: 0.9375vw;
      padding: 0.41667vw 0; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_absentButton__1xthQ {
      border-radius: 0.69444vw;
      font-size: 0.92593vw;
      line-height: 1.04167vw;
      padding: 0.46296vw 0; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sessionEmbed_absentButton__1xthQ {
      border-radius: 0.625vw;
      font-size: 0.83333vw;
      line-height: 0.9375vw;
      padding: 0.41667vw 0; } }

.sessionEmbed_presentButton__2LT-W {
  width: 50%;
  cursor: pointer;
  background: rgba(1, 170, 147, 0.12);
  border: none;
  font-weight: 600;
  color: #01AA93; }
  @media (min-width: 1300px) {
    .sessionEmbed_presentButton__2LT-W {
      border-radius: 0.625vw;
      font-size: 0.83333vw;
      line-height: 0.9375vw;
      padding: 0.41667vw 0; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_presentButton__2LT-W {
      border-radius: 0.69444vw;
      font-size: 0.92593vw;
      line-height: 1.04167vw;
      padding: 0.46296vw 0; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sessionEmbed_presentButton__2LT-W {
      border-radius: 0.625vw;
      font-size: 0.83333vw;
      line-height: 0.9375vw;
      padding: 0.41667vw 0; } }

.sessionEmbed_groupIcon__uZQDT {
  background: rgba(220, 220, 220, 0.6);
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (min-width: 1300px) {
    .sessionEmbed_groupIcon__uZQDT {
      padding: 0.10417vw;
      border-radius: 0.41667vw;
      margin: 0.41667vw 0.625vw 0.41667vw 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_groupIcon__uZQDT {
      padding: 0.11574vw;
      border-radius: 0.46296vw;
      margin: 0.46296vw 0.69444vw 0.46296vw 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sessionEmbed_groupIcon__uZQDT {
      padding: 0.10417vw;
      border-radius: 0.41667vw;
      margin: 0.41667vw 0.625vw 0.41667vw 0.41667vw; } }

.sessionEmbed_inactiveButton__3hrdq {
  width: 50%;
  cursor: pointer;
  background: white;
  border: none;
  font-weight: 600;
  color: #A8A7A7; }
  @media (min-width: 1300px) {
    .sessionEmbed_inactiveButton__3hrdq {
      font-size: 0.83333vw;
      line-height: 0.9375vw;
      padding: 0.41667vw 0; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_inactiveButton__3hrdq {
      font-size: 0.92593vw;
      line-height: 1.04167vw;
      padding: 0.46296vw 0; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sessionEmbed_inactiveButton__3hrdq {
      font-size: 0.83333vw;
      line-height: 0.9375vw;
      padding: 0.41667vw 0; } }

.sessionEmbed_otpmodalPopup__2rAHO {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  background: white;
  flex-direction: column;
  align-items: center;
  z-index: 99999999; }
  @media (min-width: 1000px) {
    .sessionEmbed_otpmodalPopup__2rAHO {
      border-radius: 0.9375vw;
      width: 32.8125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_otpmodalPopup__2rAHO {
      border-radius: 1.04167vw;
      width: 36.45833vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .sessionEmbed_otpmodalPopup__2rAHO {
      border-radius: 0.9375vw;
      width: 32.8125vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .sessionEmbed_otpmodalPopup__2rAHO {
      border-radius: 0.75vw;
      width: 26.25vw; } }

.sessionEmbed_otpmodalPopupHeading__2jHFT {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FAF7FF; }
  @media (min-width: 1000px) {
    .sessionEmbed_otpmodalPopupHeading__2jHFT {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_otpmodalPopupHeading__2jHFT {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .sessionEmbed_otpmodalPopupHeading__2jHFT {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .sessionEmbed_otpmodalPopupHeading__2jHFT {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px; } }

.sessionEmbed_otpmodalNameContainer__zEvcA {
  display: flex;
  align-items: center; }
  .sessionEmbed_otpmodalNameContainer__zEvcA p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    color: #000000; }
    @media (min-width: 1000px) {
      .sessionEmbed_otpmodalNameContainer__zEvcA p {
        font-size: 1.25vw;
        line-height: 1.48438vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .sessionEmbed_otpmodalNameContainer__zEvcA p {
        font-size: 1.38889vw;
        line-height: 1.64931vw; } }
    @media (min-width: 1001px) and (max-width: 1600px) {
      .sessionEmbed_otpmodalNameContainer__zEvcA p {
        font-size: 1.25vw;
        line-height: 1.48438vw; } }
    @media (min-width: 1601px) and (max-width: 2000px) {
      .sessionEmbed_otpmodalNameContainer__zEvcA p {
        font-size: 1vw;
        line-height: 1.1875vw; } }

.sessionEmbed_otpmodalNameIconContainer__33vkS {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF; }
  @media (min-width: 1000px) {
    .sessionEmbed_otpmodalNameIconContainer__33vkS {
      width: 2.8125vw;
      height: 2.8125vw;
      border-radius: 0.9375vw;
      margin: 0.9375vw 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_otpmodalNameIconContainer__33vkS {
      width: 3.125vw;
      height: 3.125vw;
      border-radius: 1.04167vw;
      margin: 1.04167vw 1.38889vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .sessionEmbed_otpmodalNameIconContainer__33vkS {
      width: 2.8125vw;
      height: 2.8125vw;
      border-radius: 0.9375vw;
      margin: 0.9375vw 1.25vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .sessionEmbed_otpmodalNameIconContainer__33vkS {
      width: 2.25vw;
      height: 2.25vw;
      border-radius: 0.75vw;
      margin: 0.75vw 1vw; } }

.sessionEmbed_otpmodalCloseContainer__1IDcN {
  cursor: pointer; }
  @media (min-width: 1000px) {
    .sessionEmbed_otpmodalCloseContainer__1IDcN {
      margin-right: 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_otpmodalCloseContainer__1IDcN {
      margin-right: 1.38889vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .sessionEmbed_otpmodalCloseContainer__1IDcN {
      margin-right: 1.25vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .sessionEmbed_otpmodalCloseContainer__1IDcN {
      margin-right: 1vw; } }

.sessionEmbed_otpmodalPopupBody__2U2C3 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center; }
  @media (min-width: 1000px) {
    .sessionEmbed_otpmodalPopupBody__2U2C3 {
      padding: 2.5vw 4.375vw 2.5vw 4.375vw;
      grid-gap: 2.5vw;
      gap: 2.5vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_otpmodalPopupBody__2U2C3 {
      padding: 2.77778vw 4.86111vw 2.77778vw 4.86111vw;
      grid-gap: 2.77778vw;
      gap: 2.77778vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .sessionEmbed_otpmodalPopupBody__2U2C3 {
      padding: 2.5vw 4.375vw 2.5vw 4.375vw;
      grid-gap: 2.5vw;
      gap: 2.5vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .sessionEmbed_otpmodalPopupBody__2U2C3 {
      padding: 2vw 3.5vw 2vw 3.5vw;
      grid-gap: 2vw;
      gap: 2vw; } }

.sessionEmbed_otpmodaPopBodyTextContainer__9VWxp {
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 1000px) {
    .sessionEmbed_otpmodaPopBodyTextContainer__9VWxp {
      grid-gap: 1.25vw;
      gap: 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_otpmodaPopBodyTextContainer__9VWxp {
      grid-gap: 1.38889vw;
      gap: 1.38889vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .sessionEmbed_otpmodaPopBodyTextContainer__9VWxp {
      grid-gap: 1.25vw;
      gap: 1.25vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .sessionEmbed_otpmodaPopBodyTextContainer__9VWxp {
      grid-gap: 1vw;
      gap: 1vw; } }
  .sessionEmbed_otpmodaPopBodyTextContainer__9VWxp p {
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    color: #858585; }
    @media (min-width: 1000px) {
      .sessionEmbed_otpmodaPopBodyTextContainer__9VWxp p {
        font-size: 1.09375vw;
        line-height: 140%; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .sessionEmbed_otpmodaPopBodyTextContainer__9VWxp p {
        font-size: 1.21528vw;
        line-height: 140%; } }
    @media (min-width: 1001px) and (max-width: 1600px) {
      .sessionEmbed_otpmodaPopBodyTextContainer__9VWxp p {
        font-size: 1.09375vw;
        line-height: 140%; } }
    @media (min-width: 1601px) and (max-width: 2000px) {
      .sessionEmbed_otpmodaPopBodyTextContainer__9VWxp p {
        font-size: 0.875vw;
        line-height: 140%; } }
  .sessionEmbed_otpmodaPopBodyTextContainer__9VWxp span {
    font-weight: 700; }

.sessionEmbed_otpContainer__gWd3f {
  display: flex;
  align-items: center; }
  @media (min-width: 1000px) {
    .sessionEmbed_otpContainer__gWd3f {
      grid-gap: 0.9375vw;
      gap: 0.9375vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_otpContainer__gWd3f {
      grid-gap: 1.04167vw;
      gap: 1.04167vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .sessionEmbed_otpContainer__gWd3f {
      grid-gap: 0.9375vw;
      gap: 0.9375vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .sessionEmbed_otpContainer__gWd3f {
      grid-gap: 0.75vw;
      gap: 0.75vw; } }

.sessionEmbed_otptext__3x0I- {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(140, 97, 203, 0.12);
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  color: #8C61CB;
  box-sizing: border-box; }
  @media (min-width: 1000px) {
    .sessionEmbed_otptext__3x0I- {
      padding: 0.625vw 1.25vw;
      width: 3.51562vw;
      height: 3.67188vw;
      border-radius: 0.625vw;
      font-size: 2.00195vw;
      line-height: 2.42188vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_otptext__3x0I- {
      padding: 0.69444vw 1.38889vw;
      width: 3.90625vw;
      height: 4.07986vw;
      border-radius: 0.69444vw;
      font-size: 2.22439vw;
      line-height: 2.69097vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .sessionEmbed_otptext__3x0I- {
      padding: 0.625vw 1.25vw;
      width: 3.51562vw;
      height: 3.67188vw;
      border-radius: 0.625vw;
      font-size: 2.00195vw;
      line-height: 2.42188vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .sessionEmbed_otptext__3x0I- {
      padding: 0.5vw 1vw;
      width: 2.8125vw;
      height: 2.9375vw;
      border-radius: 0.5vw;
      font-size: 1.60156vw;
      line-height: 1.9375vw; } }

.sessionEmbed_otpmodalPopupFooter__1RIX1 {
  width: 95%; }
  @media (min-width: 1000px) {
    .sessionEmbed_otpmodalPopupFooter__1RIX1 {
      padding: 0.9375vw 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_otpmodalPopupFooter__1RIX1 {
      padding: 1.04167vw 1.38889vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .sessionEmbed_otpmodalPopupFooter__1RIX1 {
      padding: 0.9375vw 1.25vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .sessionEmbed_otpmodalPopupFooter__1RIX1 {
      padding: 0.75vw 1vw; } }

@media (min-width: 1000px) {
  .sessionEmbed_otpContainerLoader__2a5oe {
    width: 17.96875vw;
    height: 3.67188vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .sessionEmbed_otpContainerLoader__2a5oe {
    width: 19.96528vw;
    height: 4.07986vw; } }

@media (min-width: 1001px) and (max-width: 1600px) {
  .sessionEmbed_otpContainerLoader__2a5oe {
    width: 17.96875vw;
    height: 3.67188vw; } }

@media (min-width: 1601px) and (max-width: 2000px) {
  .sessionEmbed_otpContainerLoader__2a5oe {
    width: 14.375vw;
    height: 2.9375vw; } }

.sessionEmbed_otpButtonContainer__2yh64 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgba(140, 97, 203, 0.12);
  color: #8C61CB;
  cursor: pointer; }
  @media (min-width: 1300px) {
    .sessionEmbed_otpButtonContainer__2yh64 {
      padding: 0.625vw 0.83333vw;
      grid-gap: 0.41667vw;
      gap: 0.41667vw;
      border: 0.05208vw solid rgba(140, 97, 203, 0.24);
      border-radius: 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_otpButtonContainer__2yh64 {
      padding: 0.69444vw 0.92593vw;
      grid-gap: 0.46296vw;
      gap: 0.46296vw;
      border: 0.05787vw solid rgba(140, 97, 203, 0.24);
      border-radius: 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sessionEmbed_otpButtonContainer__2yh64 {
      padding: 0.625vw 0.83333vw;
      grid-gap: 0.41667vw;
      gap: 0.41667vw;
      border: 0.05208vw solid rgba(140, 97, 203, 0.24);
      border-radius: 0.41667vw; } }
  .sessionEmbed_otpButtonContainer__2yh64 h3 {
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700; }
    @media (min-width: 1300px) {
      .sessionEmbed_otpButtonContainer__2yh64 h3 {
        font-size: 0.9375vw;
        line-height: 1.14583vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .sessionEmbed_otpButtonContainer__2yh64 h3 {
        font-size: 1.04167vw;
        line-height: 1.27315vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .sessionEmbed_otpButtonContainer__2yh64 h3 {
        font-size: 0.9375vw;
        line-height: 1.14583vw; } }
  .sessionEmbed_otpButtonContainer__2yh64 span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    color: #8C61CB; }
    @media (min-width: 1300px) {
      .sessionEmbed_otpButtonContainer__2yh64 span {
        font-size: 0.9375vw;
        line-height: 1.14583vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .sessionEmbed_otpButtonContainer__2yh64 span {
        font-size: 1.04167vw;
        line-height: 1.27315vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .sessionEmbed_otpButtonContainer__2yh64 span {
        font-size: 0.9375vw;
        line-height: 1.14583vw; } }

.sessionEmbed_attendanceWarningContainer__Vxr3_ {
  display: flex;
  align-items: center; }
  .sessionEmbed_attendanceWarningContainer__Vxr3_ p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500; }
    @media (min-width: 1000px) {
      .sessionEmbed_attendanceWarningContainer__Vxr3_ p {
        font-size: 0.9375vw;
        line-height: 140%; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .sessionEmbed_attendanceWarningContainer__Vxr3_ p {
        font-size: 1.04167vw;
        line-height: 140%; } }
    @media (min-width: 1001px) and (max-width: 1600px) {
      .sessionEmbed_attendanceWarningContainer__Vxr3_ p {
        font-size: 0.9375vw;
        line-height: 140%; } }
    @media (min-width: 1601px) and (max-width: 2000px) {
      .sessionEmbed_attendanceWarningContainer__Vxr3_ p {
        font-size: 0.75vw;
        line-height: 140%; } }
  @media (min-width: 1000px) {
    .sessionEmbed_attendanceWarningContainer__Vxr3_ {
      padding: 0.9375vw;
      border-radius: 0.9375vw;
      margin: 0 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_attendanceWarningContainer__Vxr3_ {
      padding: 1.04167vw;
      border-radius: 1.04167vw;
      margin: 0 1.38889vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .sessionEmbed_attendanceWarningContainer__Vxr3_ {
      padding: 0.9375vw;
      border-radius: 0.9375vw;
      margin: 0 1.25vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .sessionEmbed_attendanceWarningContainer__Vxr3_ {
      padding: 0.75vw;
      border-radius: 0.75vw;
      margin: 0 1vw; } }

.sessionEmbed_attendanceWarningIconContainer__1wkFL {
  box-sizing: border-box; }
  @media (min-width: 1000px) {
    .sessionEmbed_attendanceWarningIconContainer__1wkFL {
      border-radius: 100%;
      width: 2.5vw;
      height: 2.5vw;
      margin-right: 0.78125vw;
      padding: 0.3125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_attendanceWarningIconContainer__1wkFL {
      border-radius: 100%;
      width: 2.77778vw;
      height: 2.77778vw;
      margin-right: 0.86806vw;
      padding: 0.34722vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .sessionEmbed_attendanceWarningIconContainer__1wkFL {
      border-radius: 100%;
      width: 2.5vw;
      height: 2.5vw;
      margin-right: 0.78125vw;
      padding: 0.3125vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .sessionEmbed_attendanceWarningIconContainer__1wkFL {
      border-radius: 100%;
      width: 2vw;
      height: 2vw;
      margin-right: 0.625vw;
      padding: 0.25vw; } }
  @media (min-width: 1000px) {
    .sessionEmbed_attendanceWarningIconContainer__1wkFL svg {
      width: 1.71875vw;
      height: 1.71875vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_attendanceWarningIconContainer__1wkFL svg {
      width: 1.90972vw;
      height: 1.90972vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .sessionEmbed_attendanceWarningIconContainer__1wkFL svg {
      width: 1.71875vw;
      height: 1.71875vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .sessionEmbed_attendanceWarningIconContainer__1wkFL svg {
      width: 1.375vw;
      height: 1.375vw; } }

.sessionEmbed_trainingIframeContainer__177RA {
  margin: unset; }
  @media (min-width: 1000px) {
    .sessionEmbed_trainingIframeContainer__177RA {
      height: 100vh; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_trainingIframeContainer__177RA {
      height: 100vh; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .sessionEmbed_trainingIframeContainer__177RA {
      height: 100vh; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .sessionEmbed_trainingIframeContainer__177RA {
      height: 100vh; } }

.sessionEmbed_buddyLoginActive__1Qemi {
  display: flex;
  height: 100%;
  position: absolute;
  align-items: center;
  justify-content: center;
  background: rgba(1, 170, 147, 0.1);
  box-sizing: border-box;
  padding: 6px; }
  @media (min-width: 1000px) {
    .sessionEmbed_buddyLoginActive__1Qemi {
      width: 2.34375vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_buddyLoginActive__1Qemi {
      width: 2.60417vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .sessionEmbed_buddyLoginActive__1Qemi {
      width: 2.34375vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .sessionEmbed_buddyLoginActive__1Qemi {
      width: 1.875vw; } }
  .sessionEmbed_buddyLoginActive__1Qemi .sessionEmbed_userIcon__1qUXo {
    color: #01AA93; }

.sessionEmbed_attendanceItem__3NZNx {
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 1000px) {
    .sessionEmbed_attendanceItem__3NZNx {
      margin-left: 1.95312vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_attendanceItem__3NZNx {
      margin-left: 2.17014vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .sessionEmbed_attendanceItem__3NZNx {
      margin-left: 1.95312vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .sessionEmbed_attendanceItem__3NZNx {
      margin-left: 1.5625vw; } }

.sessionEmbed_tooltipContent__1fpIt {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 4px;
  gap: 4px; }

.sessionEmbed_warningIcon__2xGlW {
  color: #D34B57; }

.sessionEmbed_viewTeacherManualContainer__2LaBH {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid rgba(140, 97, 203, 0.24); }
  @media (min-width: 1300px) {
    .sessionEmbed_viewTeacherManualContainer__2LaBH {
      padding: 0.625vw 0.83333vw;
      grid-gap: 0.41667vw;
      gap: 0.41667vw;
      border-radius: 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sessionEmbed_viewTeacherManualContainer__2LaBH {
      padding: 0.69444vw 0.92593vw;
      grid-gap: 0.46296vw;
      gap: 0.46296vw;
      border-radius: 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sessionEmbed_viewTeacherManualContainer__2LaBH {
      padding: 0.625vw 0.83333vw;
      grid-gap: 0.41667vw;
      gap: 0.41667vw;
      border-radius: 0.41667vw; } }
  .sessionEmbed_viewTeacherManualContainer__2LaBH p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    color: #8C61CB;
    margin: 0; }
    @media (min-width: 1300px) {
      .sessionEmbed_viewTeacherManualContainer__2LaBH p {
        font-size: 0.9375vw;
        line-height: 1.14583vw;
        margin-left: 0.41667vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .sessionEmbed_viewTeacherManualContainer__2LaBH p {
        font-size: 1.04167vw;
        line-height: 1.27315vw;
        margin-left: 0.46296vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .sessionEmbed_viewTeacherManualContainer__2LaBH p {
        font-size: 0.9375vw;
        line-height: 1.14583vw;
        margin-left: 0.41667vw; } }

:root {
  --accent-color:$tekie-amethyst;
  --accent-color-110: $tekie-amethyst-dark; }

.Modal_Inter__3lUJ6, .Modal_Inter-regular__1tYF6, .Modal_Inter-SemiBold__5r-7c, .Modal_Inter-Bold__2XLJ- {
  font-family: 'Inter', 'Nunito', sans-serif; }

.Modal_Inter-regular__1tYF6 {
  font-weight: 400; }

.Modal_Inter-SemiBold__5r-7c {
  font-weight: 600; }

.Modal_Inter-Bold__2XLJ- {
  font-weight: 800; }

.Modal_modalContainer__1dK2A {
  z-index: 9999;
  background-color: rgba(125, 138, 143, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter'; }
  .Modal_modalContainer__1dK2A:active, .Modal_modalContainer__1dK2A::selection {
    border: none; }

.Modal_modal__2rR-L {
  width: 500px; }

@media (min-width: 1000px) {
  .Modal_allActionButtonModal__H6PAp {
    width: 29.6875vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .Modal_allActionButtonModal__H6PAp {
    width: 32.98611vw; } }

@media (min-width: 1001px) and (max-width: 1600px) {
  .Modal_allActionButtonModal__H6PAp {
    width: 29.6875vw; } }

@media (min-width: 1601px) and (max-width: 2000px) {
  .Modal_allActionButtonModal__H6PAp {
    width: 23.75vw; } }

.Modal_header__1KBUQ {
  padding: 20px 16px;
  background-color: #FAF6FF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  font-size: 18px; }

.Modal_endSessionHeader__2gXpk {
  font-weight: 500; }

.Modal_headerIcon__2y-zv {
  margin-right: 16px;
  padding: 12px;
  background-color: #FFFFFF;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center; }

.Modal_headerHeadingContainer__2oup6 {
  display: flex;
  justify-content: center;
  align-items: center; }

.Modal_headingTextContainer__1OXnG {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px; }

.Modal_subHeading__8NSdz {
  font-size: 12px;
  font-weight: 400;
  color: #666666; }

.Modal_body__225S9 {
  background-color: #FFFFFF;
  padding: 12px 18px; }

.Modal_secondaryButton__uFbyI {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 16px 7px 12px;
  width: 68px;
  height: 36px;
  background: #F3EFFA;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: $tekie-amethyst;
  color: var(--accent-color);
  outline: none;
  border: none;
  cursor: pointer; }
  .Modal_secondaryButton__uFbyI:hover {
    background: #E2CEFF; }
  .Modal_secondaryButton__uFbyI:active {
    background: #F3EBFF; }

.Modal_primaryButton__1EMiT {
  display: flex;
  border: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 16px 7px 12px;
  width: 124px;
  height: 36px;
  outline: none;
  cursor: pointer;
  /* Tekie-Amethyst */
  background: $tekie-amethyst;
  background: var(--accent-color);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: white; }
  .Modal_primaryButton__1EMiT:hover {
    background: $tekie-amethyst-dark;
    background: var(--accent-color-110); }

.Modal_footer__LvAfY {
  padding: 12px 16px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-top: 1px solid #F1F1F1;
  background-color: #FFFFFF; }

.Modal_embedFooter__3bkyI {
  padding: 0px 25px 25px 25px;
  grid-gap: 20px;
  gap: 20px;
  border-top: unset; }

.Modal_allActionButtonFooter__WVgQf {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  grid-gap: 12px;
  gap: 12px;
  border-top: unset;
  background-color: #FFFFFF;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem; }

.Modal_closeModalIcon__MLF2i {
  padding: 0px 5px 2px 5px;
  border-radius: 4px;
  cursor: pointer; }
  .Modal_closeModalIcon__MLF2i:hover {
    background: $tekie-amethyst-dark;
    background: var(--accent-color-110);
    color: #FFFFFF; }
    .Modal_closeModalIcon__MLF2i:hover svg > path {
      fill: white !important; }

.Modal_allActionButtonHeader__2Kfq4 {
  background-color: #FAF6FF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem; }
  @media (min-width: 1000px) {
    .Modal_allActionButtonHeader__2Kfq4 {
      padding: 1.13281vw 1.5625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .Modal_allActionButtonHeader__2Kfq4 {
      padding: 1.25868vw 1.73611vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .Modal_allActionButtonHeader__2Kfq4 {
      padding: 1.13281vw 1.5625vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .Modal_allActionButtonHeader__2Kfq4 {
      padding: 0.90625vw 1.25vw; } }
  .Modal_allActionButtonHeader__2Kfq4 h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    display: flex;
    align-items: center;
    color: #000000;
    margin: 0; }
    @media (min-width: 1000px) {
      .Modal_allActionButtonHeader__2Kfq4 h2 {
        font-size: 1.25vw;
        line-height: 1.48438vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .Modal_allActionButtonHeader__2Kfq4 h2 {
        font-size: 1.38889vw;
        line-height: 1.64931vw; } }
    @media (min-width: 1001px) and (max-width: 1600px) {
      .Modal_allActionButtonHeader__2Kfq4 h2 {
        font-size: 1.25vw;
        line-height: 1.48438vw; } }
    @media (min-width: 1601px) and (max-width: 2000px) {
      .Modal_allActionButtonHeader__2Kfq4 h2 {
        font-size: 1vw;
        line-height: 1.1875vw; } }

.EndSessionModal_Inter__XYogz, .EndSessionModal_Inter-regular__2DFlg, .EndSessionModal_Inter-SemiBold__2j5kI, .EndSessionModal_Inter-Bold__3x8o0 {
  font-family: 'Inter', 'Nunito', sans-serif; }

.EndSessionModal_Inter-regular__2DFlg {
  font-weight: 400; }

.EndSessionModal_Inter-SemiBold__2j5kI {
  font-weight: 600; }

.EndSessionModal_Inter-Bold__3x8o0 {
  font-weight: 800; }

.EndSessionModal_endSessionModalContainer__1XxIS {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  .EndSessionModal_endSessionModalContainer__1XxIS h3 {
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    color: #403F3F;
    margin: 0; }
    @media (min-width: 1000px) {
      .EndSessionModal_endSessionModalContainer__1XxIS h3 {
        font-size: 1.09375vw;
        line-height: 1.875vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .EndSessionModal_endSessionModalContainer__1XxIS h3 {
        font-size: 1.21528vw;
        line-height: 2.08333vw; } }
    @media (min-width: 1001px) and (max-width: 1600px) {
      .EndSessionModal_endSessionModalContainer__1XxIS h3 {
        font-size: 1.09375vw;
        line-height: 1.875vw; } }
    @media (min-width: 1601px) and (max-width: 2000px) {
      .EndSessionModal_endSessionModalContainer__1XxIS h3 {
        font-size: 0.875vw;
        line-height: 1.5vw; } }
  .EndSessionModal_endSessionModalContainer__1XxIS p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    text-align: center;
    color: #858585;
    margin: 0; }
    @media (min-width: 1000px) {
      .EndSessionModal_endSessionModalContainer__1XxIS p {
        font-size: 0.9375vw;
        line-height: 140%;
        margin-top: 0.625vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .EndSessionModal_endSessionModalContainer__1XxIS p {
        font-size: 1.04167vw;
        line-height: 140%;
        margin-top: 0.69444vw; } }
    @media (min-width: 1001px) and (max-width: 1600px) {
      .EndSessionModal_endSessionModalContainer__1XxIS p {
        font-size: 0.9375vw;
        line-height: 140%;
        margin-top: 0.625vw; } }
    @media (min-width: 1601px) and (max-width: 2000px) {
      .EndSessionModal_endSessionModalContainer__1XxIS p {
        font-size: 0.75vw;
        line-height: 140%;
        margin-top: 0.5vw; } }

.EndSessionModal_endSessionModalContainerSec__29q50 {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .EndSessionModal_endSessionModalContainerSec__29q50 {
      padding: 1.5625vw 1.82292vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .EndSessionModal_endSessionModalContainerSec__29q50 {
      padding: 1.73611vw 2.02546vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .EndSessionModal_endSessionModalContainerSec__29q50 {
      padding: 1.5625vw 1.82292vw; } }
  .EndSessionModal_endSessionModalContainerSec__29q50 h3, .EndSessionModal_endSessionModalContainerSec__29q50 p {
    margin: 0px; }
  .EndSessionModal_endSessionModalContainerSec__29q50 h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    color: #212121; }
    @media (min-width: 1000px) {
      .EndSessionModal_endSessionModalContainerSec__29q50 h3 {
        font-size: 1.32812vw;
        line-height: 1.875vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .EndSessionModal_endSessionModalContainerSec__29q50 h3 {
        font-size: 1.47569vw;
        line-height: 2.08333vw; } }
    @media (min-width: 1001px) and (max-width: 1600px) {
      .EndSessionModal_endSessionModalContainerSec__29q50 h3 {
        font-size: 1.32812vw;
        line-height: 1.875vw; } }
    @media (min-width: 1601px) and (max-width: 2000px) {
      .EndSessionModal_endSessionModalContainerSec__29q50 h3 {
        font-size: 1.0625vw;
        line-height: 1.5vw; } }
  .EndSessionModal_endSessionModalContainerSec__29q50 p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    color: #616161; }
    @media (min-width: 1000px) {
      .EndSessionModal_endSessionModalContainerSec__29q50 p {
        font-size: 1.01562vw;
        line-height: 1.64062vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .EndSessionModal_endSessionModalContainerSec__29q50 p {
        font-size: 1.12847vw;
        line-height: 1.82292vw; } }
    @media (min-width: 1001px) and (max-width: 1600px) {
      .EndSessionModal_endSessionModalContainerSec__29q50 p {
        font-size: 1.01562vw;
        line-height: 1.64062vw; } }
    @media (min-width: 1601px) and (max-width: 2000px) {
      .EndSessionModal_endSessionModalContainerSec__29q50 p {
        font-size: 0.8125vw;
        line-height: 1.3125vw; } }

.classroom-details-modal-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;
  padding: 40px;
  box-sizing: border-box;
  z-index: 9999999;
  background-color: #1e1f1e3d;
  display: flex;
  align-items: center;
  justify-content: center; }
  .classroom-details-modal-container .classroom-details-modal {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 16px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    max-height: 100%;
    min-width: 70%;
    min-height: 60vh; }
    .classroom-details-modal-container .classroom-details-modal.modal-embed {
      height: 90vh;
      width: 100%; }
    .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__hr-line {
      opacity: 0.1; }
      @media (min-width: 1300px) {
        .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__hr-line {
          border-bottom: 2px solid #000000; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__hr-line {
          border-bottom: 2px solid #000000; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__hr-line {
          border-bottom: 2px solid #000000; } }
    .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__header {
      display: flex;
      justify-content: space-between;
      grid-gap: 20px;
      gap: 20px;
      align-items: center;
      color: #8C61CB; }
      @media (min-width: 1300px) {
        .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__header {
          padding-inline: 30px;
          padding-block: 24px; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__header {
          padding-inline: 30px;
          padding-block: 24px; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__header {
          padding-inline: 30px;
          padding-block: 24px; } }
      @media (min-width: 1300px) {
        .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__header .classroom-details-modal__header--title {
          font-size: 1.25vw; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__header .classroom-details-modal__header--title {
          font-size: 1.38889vw; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__header .classroom-details-modal__header--title {
          font-size: 1.25vw; } }
      .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__header .classroom-details-modal__header--close-btn {
        display: flex;
        align-items: center;
        cursor: pointer; }
    .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__footer {
      display: flex;
      justify-content: flex-end;
      font-weight: bold;
      transition: all 0.3s ease;
      background: linear-gradient(269.4deg, #F2FFFD 0%, #FFFFFF 100%);
      margin-top: auto; }
      @media (min-width: 1300px) {
        .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__footer {
          padding-inline: 30px;
          padding-block: 24px;
          -webkit-padding-before: 16px;
                  padding-block-start: 16px; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__footer {
          padding-inline: 30px;
          padding-block: 24px;
          -webkit-padding-before: 16px;
                  padding-block-start: 16px; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__footer {
          padding-inline: 30px;
          padding-block: 24px;
          -webkit-padding-before: 16px;
                  padding-block-start: 16px; } }
      .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__footer[data-disable='true'] {
        background: linear-gradient(269.4deg, #F8F8F8 0%, #FFFFFF 100%); }
      .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__footer button {
        margin-left: auto;
        background-color: #01AA93;
        transition: all 0.3s ease; }
        .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__footer button:disabled {
          background-color: #C4C4C4;
          color: #fff; }
      .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__footer .classroom-details-modal__footer--complete-btn {
        margin-left: auto;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        background-color: #01AA93;
        color: #fff;
        font-size: 500;
        display: flex;
        grid-gap: 10px;
        gap: 10px;
        cursor: pointer;
        font-size: 15px;
        align-items: center; }
        @media (min-width: 1300px) {
          .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__footer .classroom-details-modal__footer--complete-btn {
            border-radius: 0.41667vw;
            padding: 0.72917vw; } }
        @media (min-width: 900px) and (max-width: 1000px) {
          .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__footer .classroom-details-modal__footer--complete-btn {
            border-radius: 0.46296vw;
            padding: 0.81019vw; } }
        @media (min-width: 1000px) and (max-width: 1300px) {
          .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__footer .classroom-details-modal__footer--complete-btn {
            border-radius: 0.41667vw;
            padding: 0.72917vw; } }
      .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__footer[data-align="center"] button {
        margin-inline: auto; }
    .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__body {
      overflow: auto;
      min-height: 100px; }
      @media (min-width: 1300px) {
        .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__body {
          padding-inline: 30px;
          padding-block: 24px; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__body {
          padding-inline: 30px;
          padding-block: 24px; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__body {
          padding-inline: 30px;
          padding-block: 24px; } }
      .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__body.modal-embed {
        height: 100%;
        overflow: unset;
        padding: 0; }
      .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__body::-webkit-scrollbar {
        background-color: transparent; }
      .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__body::-webkit-scrollbar-thumb {
        background-color: #ddd; }
    .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__body--empty {
      display: flex;
      flex: 1 1;
      justify-content: center;
      line-height: 22px;
      font-weight: 400;
      align-items: center;
      flex-direction: column;
      text-align: center;
      color: #6D6D6D; }
      .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__body--empty button {
        background-color: #01AA93;
        margin-top: 10px; }
      @media (min-width: 1300px) {
        .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__body--empty {
          height: 10.41667vw;
          grid-gap: 0.9375vw;
          gap: 0.9375vw;
          padding-inline: 30px;
          padding-block: 70px; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__body--empty {
          height: 11.57407vw;
          grid-gap: 1.04167vw;
          gap: 1.04167vw;
          padding-inline: 30px;
          padding-block: 70px; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .classroom-details-modal-container .classroom-details-modal .classroom-details-modal__body--empty {
          height: 10.41667vw;
          grid-gap: 0.9375vw;
          gap: 0.9375vw;
          padding-inline: 30px;
          padding-block: 70px; } }

.iframeContent_iframeContent__3YONA {
  border: none;
  outline: none;
  min-height: 100%; }

.Tooltip_tooltipWrapper__3CnuW {
  display: inline-block;
  position: relative;
  transition: all 0.2s ease-in-out; }

.Tooltip_tip__3qUiQ {
  width: 20px;
  color: #034459;
  height: var(--tooltip-height);
  position: absolute;
  left: 50%;
  bottom: 100%; }

.Tooltip_tertiaryTip__1HIV3 {
  width: 20px;
  color: #F0FBFF;
  height: var(--tooltip-height);
  position: absolute;
  left: 86%;
  bottom: 100%; }

.Tooltip_tooltipTip__2axIv {
  --tooltip-height: 12px;
  position: absolute;
  border-radius: 4px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  color: white;
  background: black;
  padding: 6px;
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap; }
  .Tooltip_tooltipTip__2axIv::before {
    content: " ";
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 6px;
    margin-left: calc(6px * -1); }
  .Tooltip_tooltipTip__2axIv.Tooltip_secondary__1oC0N {
    white-space: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #034459; }
    @media (min-width: 1300px) {
      .Tooltip_tooltipTip__2axIv.Tooltip_secondary__1oC0N {
        width: 10.41667vw;
        font-size: 0.9375vw;
        line-height: 140%;
        border-radius: 0.41667vw;
        padding-inline: 16px;
        padding-block: 12px; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .Tooltip_tooltipTip__2axIv.Tooltip_secondary__1oC0N {
        width: 11.57407vw;
        font-size: 1.04167vw;
        line-height: 140%;
        border-radius: 0.46296vw;
        padding-inline: 16px;
        padding-block: 12px; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .Tooltip_tooltipTip__2axIv.Tooltip_secondary__1oC0N {
        width: 10.41667vw;
        font-size: 0.9375vw;
        line-height: 140%;
        border-radius: 0.41667vw;
        padding-inline: 16px;
        padding-block: 12px; } }
    .Tooltip_tooltipTip__2axIv.Tooltip_secondary__1oC0N::before {
      display: none; }
  .Tooltip_tooltipTip__2axIv.Tooltip_newFlow__24Qvt::before {
    display: none; }

.Tooltip_tooltipTip__2axIv.Tooltip_top__1dULl {
  bottom: calc(100% + var(--tooltip-height) + 6px); }

.Tooltip_tooltipTip__2axIv.Tooltip_top__1dULl::before {
  top: 100%;
  border-top-color: black; }

.Tooltip_tooltipTip__2axIv.Tooltip_right__c4Xc3 {
  left: calc(100% + 30px);
  top: 50%;
  -webkit-transform: translateX(0) translateY(-50%);
          transform: translateX(0) translateY(-50%); }

.Tooltip_tooltipTip__2axIv.Tooltip_right__c4Xc3::before {
  left: calc(6px * -1);
  top: 50%;
  -webkit-transform: translateX(0) translateY(-50%);
          transform: translateX(0) translateY(-50%);
  border-right-color: black; }

.Tooltip_tooltipTip__2axIv.Tooltip_bottom__3uc8n {
  top: calc(100% + var(--tooltip-height) + 3px); }

.Tooltip_tooltipTip__2axIv.Tooltip_bottom__3uc8n::before {
  bottom: 100%;
  border-bottom-color: black; }

.Tooltip_tooltipTip__2axIv.Tooltip_left__1w6cP {
  left: auto;
  right: calc(100% + 30px);
  top: 50%;
  -webkit-transform: translateX(0) translateY(-50%);
          transform: translateX(0) translateY(-50%); }

.Tooltip_tooltipTip__2axIv.Tooltip_left__1w6cP::before {
  left: auto;
  right: calc(6px * -2);
  top: 50%;
  -webkit-transform: translateX(0) translateY(-50%);
          transform: translateX(0) translateY(-50%);
  border-left-color: black; }

.Tooltip_newFlow__24Qvt .Tooltip_tooltipTip__2axIv {
  border-radius: 12px;
  background-color: #034459; }
  .Tooltip_newFlow__24Qvt .Tooltip_tooltipTip__2axIv::before {
    display: none; }

.updatedProfileModal_modalPopup__1U4FY {
  font-family: 'Inter';
  position: fixed;
  cursor: auto;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  background: white;
  flex-direction: column;
  align-items: center;
  z-index: 99999999; }
  @media (min-width: 1300px) {
    .updatedProfileModal_modalPopup__1U4FY {
      width: 21.92708vw;
      border-radius: 0.625vw;
      height: 20.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .updatedProfileModal_modalPopup__1U4FY {
      width: 24.36343vw;
      border-radius: 0.69444vw;
      height: 23.14815vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .updatedProfileModal_modalPopup__1U4FY {
      width: 21.92708vw;
      border-radius: 0.625vw;
      height: 20.83333vw; } }

.updatedProfileModal_modalOverlay__1zamO {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999999; }

.updatedProfileModal_attendanceText__2PJQy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: -webkit-fill-available;
  background: #FAF6FF;
  background: var(--tekie-light-amethyst, #FAF6FF); }
  @media (min-width: 1300px) {
    .updatedProfileModal_attendanceText__2PJQy {
      padding: 0.625vw 0.83333vw;
      border-radius: 0.83333vw 0.83333vw 0vw 0vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .updatedProfileModal_attendanceText__2PJQy {
      padding: 0.69444vw 0.92593vw;
      border-radius: 0.92593vw 0.92593vw 0vw 0vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .updatedProfileModal_attendanceText__2PJQy {
      padding: 0.625vw 0.83333vw;
      border-radius: 0.83333vw 0.83333vw 0vw 0vw; } }

.updatedProfileModal_profileText__Sgr06 {
  display: flex;
  align-items: center;
  flex-shrink: 0; }
  @media (min-width: 1300px) {
    .updatedProfileModal_profileText__Sgr06 {
      grid-gap: 0.625vw;
      gap: 0.625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .updatedProfileModal_profileText__Sgr06 {
      grid-gap: 0.69444vw;
      gap: 0.69444vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .updatedProfileModal_profileText__Sgr06 {
      grid-gap: 0.625vw;
      gap: 0.625vw; } }
  .updatedProfileModal_profileText__Sgr06 > p {
    color: #000;
    font-family: Inter;
    font-style: normal;
    line-height: normal; }
    @media (min-width: 1300px) {
      .updatedProfileModal_profileText__Sgr06 > p {
        font-size: 0.83333vw;
        font-weight: 600; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .updatedProfileModal_profileText__Sgr06 > p {
        font-size: 0.92593vw;
        font-weight: 600; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .updatedProfileModal_profileText__Sgr06 > p {
        font-size: 0.83333vw;
        font-weight: 600; } }

.updatedProfileModal_usernameContainer__1s9pS {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%; }
  @media (min-width: 1300px) {
    .updatedProfileModal_usernameContainer__1s9pS {
      grid-gap: 0.41667vw;
      gap: 0.41667vw;
      margin-top: 1.14583vw;
      margin-left: 2.34375vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .updatedProfileModal_usernameContainer__1s9pS {
      grid-gap: 0.46296vw;
      gap: 0.46296vw;
      margin-top: 1.27315vw;
      margin-left: 2.60417vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .updatedProfileModal_usernameContainer__1s9pS {
      grid-gap: 0.41667vw;
      gap: 0.41667vw;
      margin-top: 1.14583vw;
      margin-left: 2.34375vw; } }
  .updatedProfileModal_usernameContainer__1s9pS > p {
    color: #666;
    color: var(--tekie-grey-3, #666);
    font-family: Inter;
    font-style: normal;
    line-height: normal; }
    @media (min-width: 1300px) {
      .updatedProfileModal_usernameContainer__1s9pS > p {
        font-size: 0.72917vw;
        font-weight: 400; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .updatedProfileModal_usernameContainer__1s9pS > p {
        font-size: 0.81019vw;
        font-weight: 400; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .updatedProfileModal_usernameContainer__1s9pS > p {
        font-size: 0.72917vw;
        font-weight: 400; } }

.updatedProfileModal_buttonContainer__1qlae {
  display: flex;
  align-items: space-between;
  width: 85%;
  justify-content: space-between; }
  .updatedProfileModal_buttonContainer__1qlae > p {
    color: #666;
    color: var(--tekie-grey-3, #666);
    font-family: Inter;
    font-style: normal;
    line-height: normal;
    align-self: center; }
    @media (min-width: 1300px) {
      .updatedProfileModal_buttonContainer__1qlae > p {
        font-size: 0.72917vw;
        font-weight: 400; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .updatedProfileModal_buttonContainer__1qlae > p {
        font-size: 0.81019vw;
        font-weight: 400; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .updatedProfileModal_buttonContainer__1qlae > p {
        font-size: 0.72917vw;
        font-weight: 400; } }
  .updatedProfileModal_buttonContainer__1qlae > h2 {
    color: #8C61CB;
    color: var(--tekie-amethyst, #8C61CB);
    /* Body/Subtext12-medium */
    font-family: Inter;
    font-style: normal;
    justify-content: center;
    align-items: center; }
    @media (min-width: 1300px) {
      .updatedProfileModal_buttonContainer__1qlae > h2 {
        margin: 0;
        padding: 0.20833vw;
        grid-gap: 0.41667vw;
        gap: 0.41667vw;
        line-height: 0.9375vw;
        font-size: 0.625vw;
        border-bottom: 1px dashed #8C61CB;
        font-weight: 500; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .updatedProfileModal_buttonContainer__1qlae > h2 {
        margin: 0;
        padding: 0.23148vw;
        grid-gap: 0.46296vw;
        gap: 0.46296vw;
        line-height: 1.04167vw;
        font-size: 0.69444vw;
        border-bottom: 1px dashed #8C61CB;
        font-weight: 500; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .updatedProfileModal_buttonContainer__1qlae > h2 {
        margin: 0;
        padding: 0.20833vw;
        grid-gap: 0.41667vw;
        gap: 0.41667vw;
        line-height: 0.9375vw;
        font-size: 0.625vw;
        border-bottom: 1px dashed #8C61CB;
        font-weight: 500; } }

.updatedProfileModal_content__25CU6, .updatedProfileModal_inputClass__LzQxp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background: #FFF;
  background: var(--primary-white, #FFF); }
  @media (min-width: 1300px) {
    .updatedProfileModal_content__25CU6, .updatedProfileModal_inputClass__LzQxp {
      padding: 0.625vw 1.25vw;
      grid-gap: 0.41667vw;
      gap: 0.41667vw;
      border-radius: 0.39062vw;
      border: 0.05208vw solid #E2E2E2;
      width: 75%; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .updatedProfileModal_content__25CU6, .updatedProfileModal_inputClass__LzQxp {
      padding: 0.69444vw 1.38889vw;
      grid-gap: 0.46296vw;
      gap: 0.46296vw;
      border-radius: 0.43403vw;
      border: 0.05787vw solid #E2E2E2;
      width: 75%; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .updatedProfileModal_content__25CU6, .updatedProfileModal_inputClass__LzQxp {
      padding: 0.625vw 1.25vw;
      grid-gap: 0.41667vw;
      gap: 0.41667vw;
      border-radius: 0.39062vw;
      border: 0.05208vw solid #E2E2E2;
      width: 75%; } }
  .updatedProfileModal_content__25CU6 > p, .updatedProfileModal_inputClass__LzQxp > p {
    color: #403F3F;
    color: var(--neutral-emperor-25, #403F3F);
    font-family: Inter;
    font-style: normal; }
    @media (min-width: 1300px) {
      .updatedProfileModal_content__25CU6 > p, .updatedProfileModal_inputClass__LzQxp > p {
        line-height: 140%;
        font-size: 0.83333vw;
        font-weight: 700; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .updatedProfileModal_content__25CU6 > p, .updatedProfileModal_inputClass__LzQxp > p {
        line-height: 140%;
        font-size: 0.92593vw;
        font-weight: 700; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .updatedProfileModal_content__25CU6 > p, .updatedProfileModal_inputClass__LzQxp > p {
        line-height: 140%;
        font-size: 0.83333vw;
        font-weight: 700; } }

.updatedProfileModal_saveChangesButton__2wgYR {
  position: absolute; }
  @media (min-width: 1300px) {
    .updatedProfileModal_saveChangesButton__2wgYR {
      bottom: 0.52083vw;
      right: 0.57292vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .updatedProfileModal_saveChangesButton__2wgYR {
      bottom: 0.5787vw;
      right: 0.63657vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .updatedProfileModal_saveChangesButton__2wgYR {
      bottom: 0.52083vw;
      right: 0.57292vw; } }

.updatedProfileModal_passwordError__3mrY9 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 4px;
  gap: 4px;
  width: -webkit-fill-available; }
  @media (min-width: 1300px) {
    .updatedProfileModal_passwordError__3mrY9 {
      margin-top: 0.65104vw;
      margin-left: 1.30208vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .updatedProfileModal_passwordError__3mrY9 {
      margin-top: 0.72338vw;
      margin-left: 1.44676vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .updatedProfileModal_passwordError__3mrY9 {
      margin-top: 0.65104vw;
      margin-left: 1.30208vw; } }
  .updatedProfileModal_passwordError__3mrY9 > span {
    color: #F02A13;
    font-family: Inter;
    font-style: normal;
    align-self: center; }
    @media (min-width: 1300px) {
      .updatedProfileModal_passwordError__3mrY9 > span {
        font-size: 0.72917vw;
        line-height: 120%;
        letter-spacing: -0.01458vw;
        font-weight: 400; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .updatedProfileModal_passwordError__3mrY9 > span {
        font-size: 0.81019vw;
        line-height: 120%;
        letter-spacing: -0.0162vw;
        font-weight: 400; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .updatedProfileModal_passwordError__3mrY9 > span {
        font-size: 0.72917vw;
        line-height: 120%;
        letter-spacing: -0.01458vw;
        font-weight: 400; } }

/** Padding */
.p-hxxs {
  box-sizing: border-box;
  padding-left: 4px;
  padding-right: 4px; }

.p-vxxs {
  box-sizing: border-box;
  padding-top: 4px;
  padding-bottom: 4px; }

.p-hxs {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .p-hxs {
      padding-left: 0.41667vw;
      padding-right: 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .p-hxs {
      padding-left: 0.46296vw;
      padding-right: 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .p-hxs {
      padding-left: 0.41667vw;
      padding-right: 0.41667vw; } }

.p-vxs {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .p-vxs {
      padding-top: 0.41667vw;
      padding-bottom: 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .p-vxs {
      padding-top: 0.46296vw;
      padding-bottom: 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .p-vxs {
      padding-top: 0.41667vw;
      padding-bottom: 0.41667vw; } }

.p-hs {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .p-hs {
      padding-left: 0.83333vw;
      padding-right: 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .p-hs {
      padding-left: 0.92593vw;
      padding-right: 0.92593vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .p-hs {
      padding-left: 0.83333vw;
      padding-right: 0.83333vw; } }

.p-vs {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .p-vs {
      padding-top: 0.83333vw;
      padding-bottom: 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .p-vs {
      padding-top: 0.92593vw;
      padding-bottom: 0.92593vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .p-vs {
      padding-top: 0.83333vw;
      padding-bottom: 0.83333vw; } }

.p-hm {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .p-hm {
      padding-left: 1.25vw;
      padding-right: 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .p-hm {
      padding-left: 1.38889vw;
      padding-right: 1.38889vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .p-hm {
      padding-left: 1.25vw;
      padding-right: 1.25vw; } }

.p-vm {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .p-vm {
      padding-top: 1.25vw;
      padding-bottom: 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .p-vm {
      padding-top: 1.38889vw;
      padding-bottom: 1.38889vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .p-vm {
      padding-top: 1.25vw;
      padding-bottom: 1.25vw; } }

.p-hxm {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .p-hxm {
      padding-left: 1.66667vw;
      padding-right: 1.66667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .p-hxm {
      padding-left: 1.85185vw;
      padding-right: 1.85185vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .p-hxm {
      padding-left: 1.66667vw;
      padding-right: 1.66667vw; } }

.p-vxm {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .p-vxm {
      padding-top: 1.66667vw;
      padding-bottom: 1.66667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .p-vxm {
      padding-top: 1.85185vw;
      padding-bottom: 1.85185vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .p-vxm {
      padding-top: 1.66667vw;
      padding-bottom: 1.66667vw; } }

.p-hxxm {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .p-hxxm {
      padding-left: 2.08333vw;
      padding-right: 2.08333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .p-hxxm {
      padding-left: 2.31481vw;
      padding-right: 2.31481vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .p-hxxm {
      padding-left: 2.08333vw;
      padding-right: 2.08333vw; } }

.p-vxxm {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .p-vxxm {
      padding-top: 2.08333vw;
      padding-bottom: 2.08333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .p-vxxm {
      padding-top: 2.31481vw;
      padding-bottom: 2.31481vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .p-vxxm {
      padding-top: 2.08333vw;
      padding-bottom: 2.08333vw; } }

.p-hl {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .p-hl {
      padding-left: 2.5vw;
      padding-right: 2.5vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .p-hl {
      padding-left: 2.77778vw;
      padding-right: 2.77778vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .p-hl {
      padding-left: 2.5vw;
      padding-right: 2.5vw; } }

.p-vl {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .p-vl {
      padding-top: 2.5vw;
      padding-bottom: 2.5vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .p-vl {
      padding-top: 2.77778vw;
      padding-bottom: 2.77778vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .p-vl {
      padding-top: 2.5vw;
      padding-bottom: 2.5vw; } }

.p-hxl {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .p-hxl {
      padding-left: 3.33333vw;
      padding-right: 3.33333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .p-hxl {
      padding-left: 3.7037vw;
      padding-right: 3.7037vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .p-hxl {
      padding-left: 3.33333vw;
      padding-right: 3.33333vw; } }

.p-vxl {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .p-vxl {
      padding-top: 3.33333vw;
      padding-bottom: 3.33333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .p-vxl {
      padding-top: 3.7037vw;
      padding-bottom: 3.7037vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .p-vxl {
      padding-top: 3.33333vw;
      padding-bottom: 3.33333vw; } }

.p-hxxl {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .p-hxxl {
      padding-left: 5vw;
      padding-right: 5vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .p-hxxl {
      padding-left: 5.55556vw;
      padding-right: 5.55556vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .p-hxxl {
      padding-left: 5vw;
      padding-right: 5vw; } }

.p-vxxl {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .p-vxxl {
      padding-top: 5vw;
      padding-bottom: 5vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .p-vxxl {
      padding-top: 5.55556vw;
      padding-bottom: 5.55556vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .p-vxxl {
      padding-top: 5vw;
      padding-bottom: 5vw; } }

@media (min-width: 1300px) {
  .p-hxxm {
    padding-left: 2.08333vw;
    padding-right: 2.08333vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .p-hxxm {
    padding-left: 2.31481vw;
    padding-right: 2.31481vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .p-hxxm {
    padding-left: 2.08333vw;
    padding-right: 2.08333vw; } }

@media (min-width: 1300px) {
  .p-vxm {
    padding-top: 2.08333vw;
    padding-bottom: 2.08333vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .p-vxm {
    padding-top: 2.31481vw;
    padding-bottom: 2.31481vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .p-vxm {
    padding-top: 2.08333vw;
    padding-bottom: 2.08333vw; } }

.p-hxxxl {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .p-hxxxl {
      padding-left: 6.66667vw;
      padding-right: 6.66667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .p-hxxxl {
      padding-left: 7.40741vw;
      padding-right: 7.40741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .p-hxxxl {
      padding-left: 6.66667vw;
      padding-right: 6.66667vw; } }

.p-vxxxl {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .p-vxxxl {
      padding-top: 6.66667vw;
      padding-bottom: 6.66667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .p-vxxxl {
      padding-top: 7.40741vw;
      padding-bottom: 7.40741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .p-vxxxl {
      padding-top: 6.66667vw;
      padding-bottom: 6.66667vw; } }

@media (min-width: 1300px) {
  .m-rxl {
    margin-right: 3.33333vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .m-rxl {
    margin-right: 3.7037vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .m-rxl {
    margin-right: 3.33333vw; } }

@media (min-width: 1300px) {
  .m-rxxm {
    margin-right: 2.08333vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .m-rxxm {
    margin-right: 2.31481vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .m-rxxm {
    margin-right: 2.08333vw; } }

@media (min-width: 1300px) {
  .m-tm {
    margin-top: 1.66667vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .m-tm {
    margin-top: 1.85185vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .m-tm {
    margin-top: 1.66667vw; } }

@media (min-width: 1300px) {
  .m-txxs {
    margin-top: 0.20833vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .m-txxs {
    margin-top: 0.23148vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .m-txxs {
    margin-top: 0.20833vw; } }

@media (min-width: 1300px) {
  .m-rs {
    margin-right: 0.83333vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .m-rs {
    margin-right: 0.92593vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .m-rs {
    margin-right: 0.83333vw; } }

.row {
  display: flex; }

.shadow, .ph-card {
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08); }

.bg-white, .ph-card {
  background: #ffffff; }

.t-bdy-strong {
  font-family: Nunito;
  font-weight: 700; }
  @media (min-width: 1300px) {
    .t-bdy-strong {
      font-size: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .t-bdy-strong {
      font-size: 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .t-bdy-strong {
      font-size: 1.04167vw; } }

.t-bdy-regular {
  font-family: Nunito; }
  @media (min-width: 1300px) {
    .t-bdy-regular {
      font-size: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .t-bdy-regular {
      font-size: 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .t-bdy-regular {
      font-size: 1.04167vw; } }

.t-bdy-regular {
  font-family: Nunito; }
  @media (min-width: 1300px) {
    .t-bdy-regular {
      font-size: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .t-bdy-regular {
      font-size: 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .t-bdy-regular {
      font-size: 1.04167vw; } }

.t-head-semi-medium {
  font-family: Nunito;
  font-weight: 600; }
  @media (min-width: 1300px) {
    .t-head-semi-medium {
      font-size: 1.35417vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .t-head-semi-medium {
      font-size: 1.50463vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .t-head-semi-medium {
      font-size: 1.35417vw; } }

.t-head-regular {
  font-family: Nunito; }
  @media (min-width: 1300px) {
    .t-head-regular {
      font-size: 1.35417vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .t-head-regular {
      font-size: 1.50463vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .t-head-regular {
      font-size: 1.35417vw; } }

.t-small-regular {
  color: #403F3F; }

.cl-deep-sky-blue {
  color: #00ADE6; }

.op-50 {
  opacity: 0.5; }

.align-start {
  align-items: flex-start !important; }

.code-showcase-row {
  z-index: 0;
  display: flex; }

.code-showcase-hero-master {
  display: flex;
  padding: 0 0 5.7971vw;
  flex-direction: column-reverse; }
  @media (min-width: 1300px) {
    .code-showcase-hero-master {
      padding: 0 0 2.5vw;
      flex-direction: row; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-hero-master {
      padding: 0 0 2.77778vw;
      flex-direction: row; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-hero-master {
      padding: 0 0 2.5vw;
      flex-direction: row; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-hero-master {
      padding: 0 0 4.83092vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-hero-master {
      padding: 0 0 3.86473vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-hero-master {
      padding: 0 0 3.41006vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-hero-master {
      padding: 0 0 3.05111vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-hero-master {
      padding: 0 0 2.76052vw; } }

.code-showcase-hero-container {
  font-family: 'Nunito';
  flex: 1 1 50%;
  color: #403F3F;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0vw 5.7971vw; }
  @media (min-width: 1300px) {
    .code-showcase-hero-container {
      padding: 0;
      padding-right: 2.5vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-hero-container {
      padding: 0;
      padding-right: 2.5vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-hero-container {
      padding: 0;
      padding-right: 2.5vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-hero-container {
      padding: 0vw 4.83092vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-hero-container {
      padding: 0vw 3.86473vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-hero-container {
      padding: 0vw 3.41006vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-hero-container {
      padding: 0vw 3.05111vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-hero-container {
      padding: 0vw 2.76052vw; } }
  .code-showcase-hero-container .code-showcase-hero-header {
    font-weight: bold;
    font-size: 5.31401vw; }
    @media (min-width: 1300px) {
      .code-showcase-hero-container .code-showcase-hero-header {
        font-size: 1.45833vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .code-showcase-hero-container .code-showcase-hero-header {
        font-size: 1.62037vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .code-showcase-hero-container .code-showcase-hero-header {
        font-size: 1.45833vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .code-showcase-hero-container .code-showcase-hero-header {
        font-size: 4.42834vw; }
        .code-showcase-hero-container .code-showcase-hero-header else {
          font-size: 22px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .code-showcase-hero-container .code-showcase-hero-header {
        font-size: 3.54267vw; }
        .code-showcase-hero-container .code-showcase-hero-header else {
          font-size: 22px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .code-showcase-hero-container .code-showcase-hero-header {
        font-size: 3.12589vw; }
        .code-showcase-hero-container .code-showcase-hero-header else {
          font-size: 22px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .code-showcase-hero-container .code-showcase-hero-header {
        font-size: 2.79685vw; }
        .code-showcase-hero-container .code-showcase-hero-header else {
          font-size: 22px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .code-showcase-hero-container .code-showcase-hero-header {
        font-size: 2.53048vw; }
        .code-showcase-hero-container .code-showcase-hero-header else {
          font-size: 22px; } }
  .code-showcase-hero-container .code-showcase-hero-description {
    font-weight: 400;
    text-align: justify;
    font-size: 3.38164vw;
    padding-top: 0.3rem; }
    @media (min-width: 1300px) {
      .code-showcase-hero-container .code-showcase-hero-description {
        font-size: 1.04167vw;
        padding-top: 0.5rem; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .code-showcase-hero-container .code-showcase-hero-description {
        font-size: 1.15741vw;
        padding-top: 0.5rem; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .code-showcase-hero-container .code-showcase-hero-description {
        font-size: 1.04167vw;
        padding-top: 0.5rem; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .code-showcase-hero-container .code-showcase-hero-description {
        font-size: 2.81804vw; }
        .code-showcase-hero-container .code-showcase-hero-description else {
          font-size: 14px; }
        .code-showcase-hero-container .code-showcase-hero-description else {
          padding-top: 0.3rem; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .code-showcase-hero-container .code-showcase-hero-description {
        font-size: 2.25443vw; }
        .code-showcase-hero-container .code-showcase-hero-description else {
          font-size: 14px; }
        .code-showcase-hero-container .code-showcase-hero-description else {
          padding-top: 0.3rem; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .code-showcase-hero-container .code-showcase-hero-description {
        font-size: 1.9892vw; }
        .code-showcase-hero-container .code-showcase-hero-description else {
          font-size: 14px; }
        .code-showcase-hero-container .code-showcase-hero-description else {
          padding-top: 0.3rem; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .code-showcase-hero-container .code-showcase-hero-description {
        font-size: 1.77981vw; }
        .code-showcase-hero-container .code-showcase-hero-description else {
          font-size: 14px; }
        .code-showcase-hero-container .code-showcase-hero-description else {
          padding-top: 0.3rem; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .code-showcase-hero-container .code-showcase-hero-description {
        font-size: 1.61031vw; }
        .code-showcase-hero-container .code-showcase-hero-description else {
          font-size: 14px; }
        .code-showcase-hero-container .code-showcase-hero-description else {
          padding-top: 0.3rem; } }
  .code-showcase-hero-container .code-showcase-hero-btn {
    font-weight: 400;
    padding-top: 16px; }
    @media (min-width: 1300px) {
      .code-showcase-hero-container .code-showcase-hero-btn {
        font-size: 0.83333vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .code-showcase-hero-container .code-showcase-hero-btn {
        font-size: 0.92593vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .code-showcase-hero-container .code-showcase-hero-btn {
        font-size: 0.83333vw; } }
    @media (min-width: 1300px) {
      .code-showcase-hero-container .code-showcase-hero-btn {
        font-size: 0.72917vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .code-showcase-hero-container .code-showcase-hero-btn {
        font-size: 0.81019vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .code-showcase-hero-container .code-showcase-hero-btn {
        font-size: 0.72917vw; } }
    .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-playground-buttonContainer {
      width: -webkit-max-content;
      width: max-content;
      display: flex;
      align-items: center;
      flex-direction: row;
      pointer-events: auto;
      cursor: pointer;
      text-decoration: none;
      background-image: linear-gradient(to top, #00ade6, #34e4ea);
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      padding: 0px 10px;
      opacity: 0.9;
      border-radius: 12.07729vw;
      height: 8.45411vw; }
      @media (min-width: 1300px) {
        .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-playground-buttonContainer {
          border-radius: 2.60417vw;
          height: 2.29167vw; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-playground-buttonContainer {
          border-radius: 2.89352vw;
          height: 2.5463vw; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-playground-buttonContainer {
          border-radius: 2.60417vw;
          height: 2.29167vw; } }
      @media (min-width: 450px) and (max-width: 500px) {
        .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-playground-buttonContainer {
          border-radius: 10.06441vw;
          height: 7.04509vw; }
          .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-playground-buttonContainer else {
            border-radius: 50px; }
          .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-playground-buttonContainer else {
            height: 35px; } }
      @media (min-width: 500px) and (max-width: 600px) {
        .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-playground-buttonContainer {
          border-radius: 8.05153vw;
          height: 5.63607vw; }
          .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-playground-buttonContainer else {
            border-radius: 50px; }
          .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-playground-buttonContainer else {
            height: 35px; } }
      @media (min-width: 600px) and (max-width: 700px) {
        .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-playground-buttonContainer {
          border-radius: 7.10429vw;
          height: 4.973vw; }
          .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-playground-buttonContainer else {
            border-radius: 50px; }
          .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-playground-buttonContainer else {
            height: 35px; } }
      @media (min-width: 700px) and (max-width: 800px) {
        .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-playground-buttonContainer {
          border-radius: 6.35647vw;
          height: 4.44953vw; }
          .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-playground-buttonContainer else {
            border-radius: 50px; }
          .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-playground-buttonContainer else {
            height: 35px; } }
      @media (min-width: 800px) and (max-width: 899px) {
        .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-playground-buttonContainer {
          border-radius: 5.75109vw;
          height: 4.02576vw; }
          .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-playground-buttonContainer else {
            border-radius: 50px; }
          .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-playground-buttonContainer else {
            height: 35px; } }
      .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-playground-buttonContainer:hover {
        opacity: 1;
        box-shadow: 0px 5px 8px 0 rgba(0, 0, 0, 0.16); }
    .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-buttonText {
      -webkit-user-select: none;
              user-select: none;
      font-weight: bold;
      color: #fff;
      text-decoration: none;
      font-size: 3.38164vw;
      margin-left: 2.41546vw;
      margin-right: 2.41546vw; }
      @media (min-width: 1300px) {
        .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-buttonText {
          font-size: 1.04167vw;
          margin-left: 0.625vw;
          margin-right: 0.625vw; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-buttonText {
          font-size: 1.15741vw;
          margin-left: 0.69444vw;
          margin-right: 0.69444vw; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-buttonText {
          font-size: 1.04167vw;
          margin-left: 0.625vw;
          margin-right: 0.625vw; } }
      @media (min-width: 450px) and (max-width: 500px) {
        .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-buttonText {
          font-size: 2.81804vw;
          margin-left: 2.01288vw;
          margin-right: 2.01288vw; }
          .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-buttonText else {
            font-size: 14px; }
          .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-buttonText else {
            margin-left: 10px; }
          .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-buttonText else {
            margin-right: 10px; } }
      @media (min-width: 500px) and (max-width: 600px) {
        .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-buttonText {
          font-size: 2.25443vw;
          margin-left: 1.61031vw;
          margin-right: 1.61031vw; }
          .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-buttonText else {
            font-size: 14px; }
          .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-buttonText else {
            margin-left: 10px; }
          .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-buttonText else {
            margin-right: 10px; } }
      @media (min-width: 600px) and (max-width: 700px) {
        .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-buttonText {
          font-size: 1.9892vw;
          margin-left: 1.42086vw;
          margin-right: 1.42086vw; }
          .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-buttonText else {
            font-size: 14px; }
          .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-buttonText else {
            margin-left: 10px; }
          .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-buttonText else {
            margin-right: 10px; } }
      @media (min-width: 700px) and (max-width: 800px) {
        .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-buttonText {
          font-size: 1.77981vw;
          margin-left: 1.27129vw;
          margin-right: 1.27129vw; }
          .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-buttonText else {
            font-size: 14px; }
          .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-buttonText else {
            margin-left: 10px; }
          .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-buttonText else {
            margin-right: 10px; } }
      @media (min-width: 800px) and (max-width: 899px) {
        .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-buttonText {
          font-size: 1.61031vw;
          margin-left: 1.15022vw;
          margin-right: 1.15022vw; }
          .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-buttonText else {
            font-size: 14px; }
          .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-buttonText else {
            margin-left: 10px; }
          .code-showcase-hero-container .code-showcase-hero-btn .code-showcase-buttonText else {
            margin-right: 10px; } }

.code-showcase-hero-img-container {
  flex: 1 1 60%;
  display: flex;
  justify-content: flex-start; }
  @media (min-width: 1300px) {
    .code-showcase-hero-img-container {
      flex: 1 1 60%;
      display: flex;
      justify-content: flex-start; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-hero-img-container {
      flex: 1 1 60%;
      display: flex;
      justify-content: flex-start; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-hero-img-container {
      flex: 1 1 60%;
      display: flex;
      justify-content: flex-start; } }

.code-showcase-hero-img {
  width: 100%;
  height: 350px;
  background-image: url(/static/media/theoPoster.9c9eb807.svg);
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 3.86473vw;
  background-position: center; }
  @media (min-width: 1300px) {
    .code-showcase-hero-img {
      margin: 0;
      background-position: top right; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-hero-img {
      margin: 0;
      background-position: top right; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-hero-img {
      margin: 0;
      background-position: top right; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-hero-img {
      margin-bottom: 3.22061vw; }
      .code-showcase-hero-img else {
        margin-bottom: 16px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-hero-img {
      margin-bottom: 2.57649vw; }
      .code-showcase-hero-img else {
        margin-bottom: 16px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-hero-img {
      margin-bottom: 2.27337vw; }
      .code-showcase-hero-img else {
        margin-bottom: 16px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-hero-img {
      margin-bottom: 2.03407vw; }
      .code-showcase-hero-img else {
        margin-bottom: 16px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-hero-img {
      margin-bottom: 1.84035vw; }
      .code-showcase-hero-img else {
        margin-bottom: 16px; } }

@media screen and (min-width: 1440px) {
  .code-showcase-hero-img {
    height: 400px; } }

@media screen and (max-width: 1440px) {
  .code-showcase-hero-img {
    height: 300px; } }

@media screen and (max-width: 1024px) {
  .code-showcase-hero-img {
    height: 250px; } }

@media screen and (max-width: 899px) {
  .code-showcase-hero-img {
    height: 200px; } }

@media screen and (max-width: 768px) {
  .code-showcase-hero-img {
    height: 150px; } }

.code-showcase-master-container {
  padding: 0;
  padding-top: 23.18841vw; }
  @media (min-width: 1300px) {
    .code-showcase-master-container {
      padding: 5vw 7.8125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-master-container {
      padding: 5.55556vw 8.68056vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-master-container {
      padding: 5vw 7.8125vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-master-container {
      padding-top: 19.32367vw; }
      .code-showcase-master-container else {
        padding: 0; }
      .code-showcase-master-container else {
        padding-top: 96px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-master-container {
      padding-top: 15.45894vw; }
      .code-showcase-master-container else {
        padding: 0; }
      .code-showcase-master-container else {
        padding-top: 96px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-master-container {
      padding-top: 13.64024vw; }
      .code-showcase-master-container else {
        padding: 0; }
      .code-showcase-master-container else {
        padding-top: 96px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-master-container {
      padding-top: 12.20442vw; }
      .code-showcase-master-container else {
        padding: 0; }
      .code-showcase-master-container else {
        padding-top: 96px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-master-container {
      padding-top: 11.0421vw; }
      .code-showcase-master-container else {
        padding: 0; }
      .code-showcase-master-container else {
        padding-top: 96px; } }
  .code-showcase-master-container > .code-showcase-row {
    position: relative;
    display: block; }
    @media (min-width: 1300px) {
      .code-showcase-master-container > .code-showcase-row {
        display: flex; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .code-showcase-master-container > .code-showcase-row {
        display: flex; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .code-showcase-master-container > .code-showcase-row {
        display: flex; } }

.code-showcase-mobile-hero-text {
  line-height: 4.58937vw;
  padding: 0.48309vw 3.86473vw 3.86473vw;
  font-weight: bold;
  color: #00ADE6;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 1300px) {
    .code-showcase-mobile-hero-text {
      display: none; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-mobile-hero-text {
      display: none; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-mobile-hero-text {
      display: none; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-mobile-hero-text {
      line-height: 3.82448vw;
      padding: 0.40258vw 3.22061vw 3.22061vw; }
      .code-showcase-mobile-hero-text else {
        line-height: 19px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-mobile-hero-text {
      line-height: 3.05958vw;
      padding: 0.32206vw 2.57649vw 2.57649vw; }
      .code-showcase-mobile-hero-text else {
        line-height: 19px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-mobile-hero-text {
      line-height: 2.69963vw;
      padding: 0.28417vw 2.27337vw 2.27337vw; }
      .code-showcase-mobile-hero-text else {
        line-height: 19px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-mobile-hero-text {
      line-height: 2.41546vw;
      padding: 0.25426vw 2.03407vw 2.03407vw; }
      .code-showcase-mobile-hero-text else {
        line-height: 19px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-mobile-hero-text {
      line-height: 2.18542vw;
      padding: 0.23004vw 1.84035vw 1.84035vw; }
      .code-showcase-mobile-hero-text else {
        line-height: 19px; } }

@media (min-width: 1300px) {
  .code-showcase-m-rxl {
    margin-right: 3.33333vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .code-showcase-m-rxl {
    margin-right: 3.7037vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .code-showcase-m-rxl {
    margin-right: 3.33333vw; } }

.code-showcase-filter-options {
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);
  width: 100%;
  background: rgba(130, 225, 243, 0.23); }
  @media (min-width: 1300px) {
    .code-showcase-filter-options {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      background: #ffffff; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-filter-options {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      background: #ffffff; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-filter-options {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      background: #ffffff; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-filter-options else {
      width: 100%; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-filter-options else {
      width: 100%; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-filter-options else {
      width: 100%; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-filter-options else {
      width: 100%; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-filter-options else {
      width: 100%; } }
  .code-showcase-filter-options > .code-showcase-row {
    justify-content: space-evenly; }

.code-showcase-section-link {
  font-family: Nunito;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  color: #403F3F;
  opacity: 0.5;
  padding: 3.86473vw 5.7971vw;
  font-size: 2.89855vw; }
  @media (min-width: 1300px) {
    .code-showcase-section-link {
      padding: 1.25vw 1.66667vw;
      font-size: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-section-link {
      padding: 1.38889vw 1.85185vw;
      font-size: 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-section-link {
      padding: 1.25vw 1.66667vw;
      font-size: 1.04167vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-section-link {
      padding: 3.22061vw 4.83092vw;
      font-size: 2.41546vw; }
      .code-showcase-section-link else {
        font-size: 12px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-section-link {
      padding: 2.57649vw 3.86473vw;
      font-size: 1.93237vw; }
      .code-showcase-section-link else {
        font-size: 12px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-section-link {
      padding: 2.27337vw 3.41006vw;
      font-size: 1.70503vw; }
      .code-showcase-section-link else {
        font-size: 12px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-section-link {
      padding: 2.03407vw 3.05111vw;
      font-size: 1.52555vw; }
      .code-showcase-section-link else {
        font-size: 12px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-section-link {
      padding: 1.84035vw 2.76052vw;
      font-size: 1.38026vw; }
      .code-showcase-section-link else {
        font-size: 12px; } }
  .code-showcase-section-link:hover, .code-showcase-section-link.code-showcase-option-active {
    opacity: 1;
    color: #00ADE6; }
    .code-showcase-section-link:hover span, .code-showcase-section-link.code-showcase-option-active span {
      padding: 0.96618vw 0vw;
      border-bottom: 2px solid #00ADE6; }
      @media (min-width: 1300px) {
        .code-showcase-section-link:hover span, .code-showcase-section-link.code-showcase-option-active span {
          padding: 0.41667vw 0vw; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .code-showcase-section-link:hover span, .code-showcase-section-link.code-showcase-option-active span {
          padding: 0.41667vw 0vw; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .code-showcase-section-link:hover span, .code-showcase-section-link.code-showcase-option-active span {
          padding: 0.41667vw 0vw; } }
      @media (min-width: 450px) and (max-width: 500px) {
        .code-showcase-section-link:hover span, .code-showcase-section-link.code-showcase-option-active span {
          padding: 0.80515vw 0vw; } }
      @media (min-width: 500px) and (max-width: 600px) {
        .code-showcase-section-link:hover span, .code-showcase-section-link.code-showcase-option-active span {
          padding: 0.64412vw 0vw; } }
      @media (min-width: 600px) and (max-width: 700px) {
        .code-showcase-section-link:hover span, .code-showcase-section-link.code-showcase-option-active span {
          padding: 0.56834vw 0vw; } }
      @media (min-width: 700px) and (max-width: 800px) {
        .code-showcase-section-link:hover span, .code-showcase-section-link.code-showcase-option-active span {
          padding: 0.50852vw 0vw; } }
      @media (min-width: 800px) and (max-width: 899px) {
        .code-showcase-section-link:hover span, .code-showcase-section-link.code-showcase-option-active span {
          padding: 0.46009vw 0vw; } }

.code-showcase-ph-card {
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);
  background: #ffffff; }

.code-showcase-card-container {
  min-width: 100%;
  min-height: 100%; }
  @media (min-width: 1300px) {
    .code-showcase-card-container {
      min-width: 60vw;
      max-width: 65vw;
      min-height: 45vh; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-card-container {
      min-width: 60vw;
      max-width: 65vw;
      min-height: 45vh; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-card-container {
      min-width: 60vw;
      max-width: 65vw;
      min-height: 45vh; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-card-container else {
      min-width: 100%; }
    .code-showcase-card-container else {
      min-height: 100%; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-card-container else {
      min-width: 100%; }
    .code-showcase-card-container else {
      min-height: 100%; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-card-container else {
      min-width: 100%; }
    .code-showcase-card-container else {
      min-height: 100%; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-card-container else {
      min-width: 100%; }
    .code-showcase-card-container else {
      min-height: 100%; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-card-container else {
      min-width: 100%; }
    .code-showcase-card-container else {
      min-height: 100%; } }

.code-showcase-trending-tag-card {
  display: none; }
  @media (min-width: 1300px) {
    .code-showcase-trending-tag-card {
      display: block; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-trending-tag-card {
      display: block; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-trending-tag-card {
      display: block; } }

.code-showcase-divider {
  border-bottom: 1px solid #DCDCDC;
  width: 95%;
  height: 2px;
  margin: 1.25vw auto; }

.code-showcase-block {
  position: relative; }
  .code-showcase-block > .code-showcase-row {
    padding: 5.7971vw 0;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    @media (min-width: 1300px) {
      .code-showcase-block > .code-showcase-row {
        padding: 1.66667vw;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .code-showcase-block > .code-showcase-row {
        padding: 1.85185vw;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .code-showcase-block > .code-showcase-row {
        padding: 1.66667vw;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .code-showcase-block > .code-showcase-row {
        padding: 4.83092vw 0; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .code-showcase-block > .code-showcase-row {
        padding: 3.86473vw 0; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .code-showcase-block > .code-showcase-row {
        padding: 3.41006vw 0; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .code-showcase-block > .code-showcase-row {
        padding: 3.05111vw 0; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .code-showcase-block > .code-showcase-row {
        padding: 2.76052vw 0; } }
  .code-showcase-block .code-showcase-divider {
    margin: 0 auto; }
  .code-showcase-block:nth-last-child(1) > .code-showcase-divider {
    border: none; }

.code-showcase-code-view {
  height: 15rem;
  padding: 16px 0;
  background: #002f3e;
  position: relative;
  overflow: hidden;
  margin: 0.8rem 0.5rem 0rem;
  border-radius: 0.48309vw; }
  @media (min-width: 1300px) {
    .code-showcase-code-view {
      border-radius: 0.10417vw 1.04167vw 1.04167vw 1.04167vw;
      margin: 0.8rem 0rem 0rem; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-code-view {
      border-radius: 0.11574vw 1.15741vw 1.15741vw 1.15741vw;
      margin: 0.8rem 0rem 0rem; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-code-view {
      border-radius: 0.10417vw 1.04167vw 1.04167vw 1.04167vw;
      margin: 0.8rem 0rem 0rem; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-code-view {
      margin: 0.8rem 0.5rem 0rem;
      border-radius: 0.40258vw; }
      .code-showcase-code-view else {
        border-radius: 2px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-code-view {
      margin: 0.8rem 0.5rem 0rem;
      border-radius: 0.32206vw; }
      .code-showcase-code-view else {
        border-radius: 2px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-code-view {
      margin: 0.8rem 0.5rem 0rem;
      border-radius: 0.28417vw; }
      .code-showcase-code-view else {
        border-radius: 2px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-code-view {
      margin: 0.8rem 0.5rem 0rem;
      border-radius: 0.25426vw; }
      .code-showcase-code-view else {
        border-radius: 2px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-code-view {
      margin: 0.8rem 0.5rem 0rem;
      border-radius: 0.23004vw; }
      .code-showcase-code-view else {
        border-radius: 2px; } }

.code-showcase-author-details {
  color: #403F3F;
  font-weight: normal;
  text-align: center; }
  @media (min-width: 1300px) {
    .code-showcase-author-details {
      width: 9vw;
      margin-right: 0.83333vw;
      text-align: left; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-author-details {
      width: 9vw;
      margin-right: 0.92593vw;
      text-align: left; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-author-details {
      width: 9vw;
      margin-right: 0.83333vw;
      text-align: left; } }
  .code-showcase-author-details .code-showcase-author-profile-img-container {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex; }
    @media (min-width: 1300px) {
      .code-showcase-author-details .code-showcase-author-profile-img-container {
        display: block; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .code-showcase-author-details .code-showcase-author-profile-img-container {
        display: block; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .code-showcase-author-details .code-showcase-author-profile-img-container {
        display: block; } }
    .code-showcase-author-details .code-showcase-author-profile-img-container .code-showcase-profile-img {
      display: block;
      box-sizing: border-box;
      border-radius: 100%;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-position: top center !important;
      border: 2px solid #00171F;
      width: 16.90821vw;
      height: 16.90821vw; }
      @media (min-width: 1300px) {
        .code-showcase-author-details .code-showcase-author-profile-img-container .code-showcase-profile-img {
          width: 5.20833vw;
          height: 5.20833vw; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .code-showcase-author-details .code-showcase-author-profile-img-container .code-showcase-profile-img {
          width: 5.78704vw;
          height: 5.78704vw; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .code-showcase-author-details .code-showcase-author-profile-img-container .code-showcase-profile-img {
          width: 5.20833vw;
          height: 5.20833vw; } }
      @media (min-width: 450px) and (max-width: 500px) {
        .code-showcase-author-details .code-showcase-author-profile-img-container .code-showcase-profile-img {
          width: 14.09018vw;
          height: 14.09018vw; }
          .code-showcase-author-details .code-showcase-author-profile-img-container .code-showcase-profile-img else {
            width: 70px; }
          .code-showcase-author-details .code-showcase-author-profile-img-container .code-showcase-profile-img else {
            height: 70px; } }
      @media (min-width: 500px) and (max-width: 600px) {
        .code-showcase-author-details .code-showcase-author-profile-img-container .code-showcase-profile-img {
          width: 11.27214vw;
          height: 11.27214vw; }
          .code-showcase-author-details .code-showcase-author-profile-img-container .code-showcase-profile-img else {
            width: 70px; }
          .code-showcase-author-details .code-showcase-author-profile-img-container .code-showcase-profile-img else {
            height: 70px; } }
      @media (min-width: 600px) and (max-width: 700px) {
        .code-showcase-author-details .code-showcase-author-profile-img-container .code-showcase-profile-img {
          width: 9.94601vw;
          height: 9.94601vw; }
          .code-showcase-author-details .code-showcase-author-profile-img-container .code-showcase-profile-img else {
            width: 70px; }
          .code-showcase-author-details .code-showcase-author-profile-img-container .code-showcase-profile-img else {
            height: 70px; } }
      @media (min-width: 700px) and (max-width: 800px) {
        .code-showcase-author-details .code-showcase-author-profile-img-container .code-showcase-profile-img {
          width: 8.89906vw;
          height: 8.89906vw; }
          .code-showcase-author-details .code-showcase-author-profile-img-container .code-showcase-profile-img else {
            width: 70px; }
          .code-showcase-author-details .code-showcase-author-profile-img-container .code-showcase-profile-img else {
            height: 70px; } }
      @media (min-width: 800px) and (max-width: 899px) {
        .code-showcase-author-details .code-showcase-author-profile-img-container .code-showcase-profile-img {
          width: 8.05153vw;
          height: 8.05153vw; }
          .code-showcase-author-details .code-showcase-author-profile-img-container .code-showcase-profile-img else {
            width: 70px; }
          .code-showcase-author-details .code-showcase-author-profile-img-container .code-showcase-profile-img else {
            height: 70px; } }
  .code-showcase-author-details .code-showcase-author-name {
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    line-height: 32px;
    font-size: 4.34783vw; }
    @media (min-width: 1300px) {
      .code-showcase-author-details .code-showcase-author-name {
        margin-top: 0.52083vw;
        font-size: 1.04167vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .code-showcase-author-details .code-showcase-author-name {
        margin-top: 0.52083vw;
        font-size: 1.15741vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .code-showcase-author-details .code-showcase-author-name {
        margin-top: 0.52083vw;
        font-size: 1.04167vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .code-showcase-author-details .code-showcase-author-name {
        font-size: 3.62319vw; }
        .code-showcase-author-details .code-showcase-author-name else {
          font-size: 18px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .code-showcase-author-details .code-showcase-author-name {
        font-size: 2.89855vw; }
        .code-showcase-author-details .code-showcase-author-name else {
          font-size: 18px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .code-showcase-author-details .code-showcase-author-name {
        font-size: 2.55754vw; }
        .code-showcase-author-details .code-showcase-author-name else {
          font-size: 18px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .code-showcase-author-details .code-showcase-author-name {
        font-size: 2.28833vw; }
        .code-showcase-author-details .code-showcase-author-name else {
          font-size: 18px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .code-showcase-author-details .code-showcase-author-name {
        font-size: 2.07039vw; }
        .code-showcase-author-details .code-showcase-author-name else {
          font-size: 18px; } }
  .code-showcase-author-details .code-showcase-author-grade {
    opacity: 0.6;
    line-height: 4.34783vw;
    font-size: 3.86473vw; }
    @media (min-width: 1300px) {
      .code-showcase-author-details .code-showcase-author-grade {
        line-height: 1.04167vw;
        font-size: 0.9375vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .code-showcase-author-details .code-showcase-author-grade {
        line-height: 1.15741vw;
        font-size: 1.04167vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .code-showcase-author-details .code-showcase-author-grade {
        line-height: 1.04167vw;
        font-size: 0.9375vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .code-showcase-author-details .code-showcase-author-grade {
        line-height: 3.62319vw;
        font-size: 3.22061vw; }
        .code-showcase-author-details .code-showcase-author-grade else {
          line-height: 18px; }
        .code-showcase-author-details .code-showcase-author-grade else {
          font-size: 16px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .code-showcase-author-details .code-showcase-author-grade {
        line-height: 2.89855vw;
        font-size: 2.57649vw; }
        .code-showcase-author-details .code-showcase-author-grade else {
          line-height: 18px; }
        .code-showcase-author-details .code-showcase-author-grade else {
          font-size: 16px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .code-showcase-author-details .code-showcase-author-grade {
        line-height: 2.55754vw;
        font-size: 2.27337vw; }
        .code-showcase-author-details .code-showcase-author-grade else {
          line-height: 18px; }
        .code-showcase-author-details .code-showcase-author-grade else {
          font-size: 16px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .code-showcase-author-details .code-showcase-author-grade {
        line-height: 2.28833vw;
        font-size: 2.03407vw; }
        .code-showcase-author-details .code-showcase-author-grade else {
          line-height: 18px; }
        .code-showcase-author-details .code-showcase-author-grade else {
          font-size: 16px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .code-showcase-author-details .code-showcase-author-grade {
        line-height: 2.07039vw;
        font-size: 1.84035vw; }
        .code-showcase-author-details .code-showcase-author-grade else {
          line-height: 18px; }
        .code-showcase-author-details .code-showcase-author-grade else {
          font-size: 16px; } }

.code-showcase-trending-text {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 2.89855vw;
  margin: 2.5vw; }
  @media (min-width: 1300px) {
    .code-showcase-trending-text {
      font-size: 0.9375vw;
      margin: 1.25vw 1.66667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-trending-text {
      font-size: 1.04167vw;
      margin: 1.25vw 1.66667vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-trending-text {
      font-size: 0.9375vw;
      margin: 1.25vw 1.66667vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-trending-text {
      font-size: 2.41546vw; }
      .code-showcase-trending-text else {
        font-size: 12px; }
      .code-showcase-trending-text else {
        margin: 2.5vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-trending-text {
      font-size: 1.93237vw; }
      .code-showcase-trending-text else {
        font-size: 12px; }
      .code-showcase-trending-text else {
        margin: 2.5vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-trending-text {
      font-size: 1.70503vw; }
      .code-showcase-trending-text else {
        font-size: 12px; }
      .code-showcase-trending-text else {
        margin: 2.5vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-trending-text {
      font-size: 1.52555vw; }
      .code-showcase-trending-text else {
        font-size: 12px; }
      .code-showcase-trending-text else {
        margin: 2.5vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-trending-text {
      font-size: 1.38026vw; }
      .code-showcase-trending-text else {
        font-size: 12px; }
      .code-showcase-trending-text else {
        margin: 2.5vw; } }
  .code-showcase-trending-text > div > span {
    display: none; }
    @media (min-width: 1300px) {
      .code-showcase-trending-text > div > span {
        display: block; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .code-showcase-trending-text > div > span {
        display: block; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .code-showcase-trending-text > div > span {
        display: block; } }

.code-showcase-details {
  width: 100%;
  box-sizing: border-box;
  padding: 0vw 5.7971vw;
  margin-top: 3.86473vw; }
  @media (min-width: 1300px) {
    .code-showcase-details {
      padding: 0vw;
      margin-top: 0; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-details {
      padding: 0vw;
      margin-top: 0; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-details {
      padding: 0vw;
      margin-top: 0; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-details {
      padding: 0vw 4.83092vw;
      margin-top: 3.22061vw; }
      .code-showcase-details else {
        margin-top: 16px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-details {
      padding: 0vw 3.86473vw;
      margin-top: 2.57649vw; }
      .code-showcase-details else {
        margin-top: 16px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-details {
      padding: 0vw 3.41006vw;
      margin-top: 2.27337vw; }
      .code-showcase-details else {
        margin-top: 16px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-details {
      padding: 0vw 3.05111vw;
      margin-top: 2.03407vw; }
      .code-showcase-details else {
        margin-top: 16px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-details {
      padding: 0vw 2.76052vw;
      margin-top: 1.84035vw; }
      .code-showcase-details else {
        margin-top: 16px; } }
  .code-showcase-details .code-showcase-title {
    margin: 0;
    color: #403F3F;
    font-size: 5.31401vw;
    font-weight: bold; }
    @media (min-width: 1300px) {
      .code-showcase-details .code-showcase-title {
        font-size: 1.35417vw;
        font-weight: 600; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .code-showcase-details .code-showcase-title {
        font-size: 1.50463vw;
        font-weight: 600; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .code-showcase-details .code-showcase-title {
        font-size: 1.35417vw;
        font-weight: 600; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .code-showcase-details .code-showcase-title {
        font-size: 4.42834vw; }
        .code-showcase-details .code-showcase-title else {
          font-size: 22px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .code-showcase-details .code-showcase-title {
        font-size: 3.54267vw; }
        .code-showcase-details .code-showcase-title else {
          font-size: 22px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .code-showcase-details .code-showcase-title {
        font-size: 3.12589vw; }
        .code-showcase-details .code-showcase-title else {
          font-size: 22px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .code-showcase-details .code-showcase-title {
        font-size: 2.79685vw; }
        .code-showcase-details .code-showcase-title else {
          font-size: 22px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .code-showcase-details .code-showcase-title {
        font-size: 2.53048vw; }
        .code-showcase-details .code-showcase-title else {
          font-size: 22px; } }
  .code-showcase-details .code-showcase-timestamp {
    color: #A8A7A7;
    font-family: inherit;
    font-weight: normal;
    letter-spacing: 0.03em;
    margin: 0;
    font-size: 3.86473vw; }
    @media (min-width: 1300px) {
      .code-showcase-details .code-showcase-timestamp {
        font-size: 0.9375vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .code-showcase-details .code-showcase-timestamp {
        font-size: 1.04167vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .code-showcase-details .code-showcase-timestamp {
        font-size: 0.9375vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .code-showcase-details .code-showcase-timestamp {
        font-size: 3.22061vw; }
        .code-showcase-details .code-showcase-timestamp else {
          font-size: 16px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .code-showcase-details .code-showcase-timestamp {
        font-size: 2.57649vw; }
        .code-showcase-details .code-showcase-timestamp else {
          font-size: 16px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .code-showcase-details .code-showcase-timestamp {
        font-size: 2.27337vw; }
        .code-showcase-details .code-showcase-timestamp else {
          font-size: 16px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .code-showcase-details .code-showcase-timestamp {
        font-size: 2.03407vw; }
        .code-showcase-details .code-showcase-timestamp else {
          font-size: 16px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .code-showcase-details .code-showcase-timestamp {
        font-size: 1.84035vw; }
        .code-showcase-details .code-showcase-timestamp else {
          font-size: 16px; } }
  .code-showcase-details .code-showcase-description {
    text-align: justify;
    color: #403F3F;
    margin: 0;
    font-size: 3.86473vw; }
    @media (min-width: 1300px) {
      .code-showcase-details .code-showcase-description {
        font-size: 1.04167vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .code-showcase-details .code-showcase-description {
        font-size: 1.15741vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .code-showcase-details .code-showcase-description {
        font-size: 1.04167vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .code-showcase-details .code-showcase-description {
        font-size: 3.22061vw; }
        .code-showcase-details .code-showcase-description else {
          font-size: 16px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .code-showcase-details .code-showcase-description {
        font-size: 2.57649vw; }
        .code-showcase-details .code-showcase-description else {
          font-size: 16px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .code-showcase-details .code-showcase-description {
        font-size: 2.27337vw; }
        .code-showcase-details .code-showcase-description else {
          font-size: 16px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .code-showcase-details .code-showcase-description {
        font-size: 2.03407vw; }
        .code-showcase-details .code-showcase-description else {
          font-size: 16px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .code-showcase-details .code-showcase-description {
        font-size: 1.84035vw; }
        .code-showcase-details .code-showcase-description else {
          font-size: 16px; } }

.code-showcase-details-footer {
  justify-content: space-between;
  align-items: center;
  padding: 3.86473vw; }
  @media (min-width: 1300px) {
    .code-showcase-details-footer {
      padding: 0.83333vw 0 0; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-details-footer {
      padding: 0.92593vw 0 0; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-details-footer {
      padding: 0.83333vw 0 0; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-details-footer {
      padding: 3.22061vw; }
      .code-showcase-details-footer else {
        padding: 16px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-details-footer {
      padding: 2.57649vw; }
      .code-showcase-details-footer else {
        padding: 16px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-details-footer {
      padding: 2.27337vw; }
      .code-showcase-details-footer else {
        padding: 16px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-details-footer {
      padding: 2.03407vw; }
      .code-showcase-details-footer else {
        padding: 16px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-details-footer {
      padding: 1.84035vw; }
      .code-showcase-details-footer else {
        padding: 16px; } }
  .code-showcase-details-footer .code-showcase-reactions {
    -webkit-user-select: none;
            user-select: none;
    min-width: 2.5vw;
    color: #403F3F;
    text-align: center;
    border-radius: 100px;
    cursor: pointer;
    margin: 0 4px;
    font-size: 3.38164vw;
    padding: 0.96618vw 3.86473vw; }
    @media (min-width: 1300px) {
      .code-showcase-details-footer .code-showcase-reactions {
        font-size: 0.9375vw;
        padding: 0.41667vw 1.25vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .code-showcase-details-footer .code-showcase-reactions {
        font-size: 0.9375vw;
        padding: 0.46296vw 1.38889vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .code-showcase-details-footer .code-showcase-reactions {
        font-size: 0.9375vw;
        padding: 0.41667vw 1.25vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .code-showcase-details-footer .code-showcase-reactions {
        font-size: 2.81804vw;
        padding: 0.80515vw 3.22061vw; }
        .code-showcase-details-footer .code-showcase-reactions else {
          font-size: 14px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .code-showcase-details-footer .code-showcase-reactions {
        font-size: 2.25443vw;
        padding: 0.64412vw 2.57649vw; }
        .code-showcase-details-footer .code-showcase-reactions else {
          font-size: 14px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .code-showcase-details-footer .code-showcase-reactions {
        font-size: 1.9892vw;
        padding: 0.56834vw 2.27337vw; }
        .code-showcase-details-footer .code-showcase-reactions else {
          font-size: 14px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .code-showcase-details-footer .code-showcase-reactions {
        font-size: 1.77981vw;
        padding: 0.50852vw 2.03407vw; }
        .code-showcase-details-footer .code-showcase-reactions else {
          font-size: 14px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .code-showcase-details-footer .code-showcase-reactions {
        font-size: 1.61031vw;
        padding: 0.46009vw 1.84035vw; }
        .code-showcase-details-footer .code-showcase-reactions else {
          font-size: 14px; } }
    .code-showcase-details-footer .code-showcase-reactions.hot.active, .code-showcase-details-footer .code-showcase-reactions.hot:hover {
      background: #FFE2E0; }
    .code-showcase-details-footer .code-showcase-reactions.heart.active, .code-showcase-details-footer .code-showcase-reactions.heart:hover {
      background: #FCE3F2; }
    .code-showcase-details-footer .code-showcase-reactions.celebrate.active, .code-showcase-details-footer .code-showcase-reactions.celebrate:hover {
      background: rgba(249, 231, 63, 0.2); }
  .code-showcase-details-footer .code-showcase-share-container {
    cursor: pointer;
    font-size: 4.34783vw; }
    @media (min-width: 1300px) {
      .code-showcase-details-footer .code-showcase-share-container {
        font-size: 1.14583vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .code-showcase-details-footer .code-showcase-share-container {
        font-size: 1.14583vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .code-showcase-details-footer .code-showcase-share-container {
        font-size: 1.14583vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .code-showcase-details-footer .code-showcase-share-container {
        font-size: 3.62319vw; }
        .code-showcase-details-footer .code-showcase-share-container else {
          font-size: 18px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .code-showcase-details-footer .code-showcase-share-container {
        font-size: 2.89855vw; }
        .code-showcase-details-footer .code-showcase-share-container else {
          font-size: 18px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .code-showcase-details-footer .code-showcase-share-container {
        font-size: 2.55754vw; }
        .code-showcase-details-footer .code-showcase-share-container else {
          font-size: 18px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .code-showcase-details-footer .code-showcase-share-container {
        font-size: 2.28833vw; }
        .code-showcase-details-footer .code-showcase-share-container else {
          font-size: 18px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .code-showcase-details-footer .code-showcase-share-container {
        font-size: 2.07039vw; }
        .code-showcase-details-footer .code-showcase-share-container else {
          font-size: 18px; } }

.code-showcase-tags-wrapper {
  margin: 1.93237vw 0vw; }
  @media (min-width: 1300px) {
    .code-showcase-tags-wrapper {
      margin: 0.41667vw 0vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-tags-wrapper {
      margin: 0.46296vw 0vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-tags-wrapper {
      margin: 0.41667vw 0vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-tags-wrapper {
      margin: 1.61031vw 0vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-tags-wrapper {
      margin: 1.28824vw 0vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-tags-wrapper {
      margin: 1.13669vw 0vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-tags-wrapper {
      margin: 1.01704vw 0vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-tags-wrapper {
      margin: 0.92017vw 0vw; } }

.code-showcase-tags {
  cursor: pointer;
  border: 1px solid #00ADE6;
  border-radius: 100px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  color: #00ADE6;
  transition: background-color .3s;
  margin: 1.93237vw 1.93237vw 0.96618vw 0;
  font-size: 3.86473vw;
  padding: 0.48309vw 3.86473vw; }
  @media (min-width: 1300px) {
    .code-showcase-tags {
      margin: 0.20833vw 0.41667vw 0.41667vw 0;
      font-size: 0.9375vw;
      padding: 0.20833vw 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-tags {
      margin: 0.20833vw 0.41667vw 0.41667vw 0;
      font-size: 0.9375vw;
      padding: 0.20833vw 0.83333vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-tags {
      margin: 0.20833vw 0.41667vw 0.41667vw 0;
      font-size: 0.9375vw;
      padding: 0.20833vw 0.83333vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-tags {
      margin: 1.61031vw 1.61031vw 0.80515vw 0;
      font-size: 3.22061vw;
      padding: 0.40258vw 3.22061vw; }
      .code-showcase-tags else {
        font-size: 16px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-tags {
      margin: 1.28824vw 1.28824vw 0.64412vw 0;
      font-size: 2.57649vw;
      padding: 0.32206vw 2.57649vw; }
      .code-showcase-tags else {
        font-size: 16px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-tags {
      margin: 1.13669vw 1.13669vw 0.56834vw 0;
      font-size: 2.27337vw;
      padding: 0.28417vw 2.27337vw; }
      .code-showcase-tags else {
        font-size: 16px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-tags {
      margin: 1.01704vw 1.01704vw 0.50852vw 0;
      font-size: 2.03407vw;
      padding: 0.25426vw 2.03407vw; }
      .code-showcase-tags else {
        font-size: 16px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-tags {
      margin: 0.92017vw 0.92017vw 0.46009vw 0;
      font-size: 1.84035vw;
      padding: 0.23004vw 1.84035vw; }
      .code-showcase-tags else {
        font-size: 16px; } }
  .code-showcase-tags span {
    font-weight: normal;
    color: #403F3F; }
  .code-showcase-tags:hover, .code-showcase-tags.code-showcase-tags-active {
    transition: background-color .3s;
    background-color: #00ADE6;
    color: #fff; }
    .code-showcase-tags:hover span, .code-showcase-tags.code-showcase-tags-active span {
      color: #fff; }

.code-showcase-clearTags {
  cursor: pointer;
  border: 1px solid #FF5744;
  border-radius: 100px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  color: #FF5744;
  transition: background-color .3s;
  margin: 3.86473vw 1.93237vw 3.86473vw 0;
  font-size: 3.86473vw;
  padding: 0.48309vw 3.86473vw; }
  @media (min-width: 1300px) {
    .code-showcase-clearTags {
      margin: 0.41667vw 0.20833vw 0.20833vw;
      font-size: 0.9375vw;
      padding: 0.20833vw 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-clearTags {
      margin: 0.41667vw 0.20833vw 0.20833vw;
      font-size: 0.9375vw;
      padding: 0.20833vw 0.83333vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-clearTags {
      margin: 0.41667vw 0.20833vw 0.20833vw;
      font-size: 0.9375vw;
      padding: 0.20833vw 0.83333vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-clearTags {
      margin: 3.22061vw 1.61031vw 3.22061vw 0;
      font-size: 3.22061vw;
      padding: 0.40258vw 3.22061vw; }
      .code-showcase-clearTags else {
        font-size: 16px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-clearTags {
      margin: 2.57649vw 1.28824vw 2.57649vw 0;
      font-size: 2.57649vw;
      padding: 0.32206vw 2.57649vw; }
      .code-showcase-clearTags else {
        font-size: 16px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-clearTags {
      margin: 2.27337vw 1.13669vw 2.27337vw 0;
      font-size: 2.27337vw;
      padding: 0.28417vw 2.27337vw; }
      .code-showcase-clearTags else {
        font-size: 16px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-clearTags {
      margin: 2.03407vw 1.01704vw 2.03407vw 0;
      font-size: 2.03407vw;
      padding: 0.25426vw 2.03407vw; }
      .code-showcase-clearTags else {
        font-size: 16px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-clearTags {
      margin: 1.84035vw 0.92017vw 1.84035vw 0;
      font-size: 1.84035vw;
      padding: 0.23004vw 1.84035vw; }
      .code-showcase-clearTags else {
        font-size: 16px; } }
  .code-showcase-clearTags:hover {
    transition: background-color .3s;
    background-color: #FF5744;
    color: #ffffff; }

.code-showcase-shareIcon {
  display: block;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url(/static/media/shareCode.1901aaaa.svg);
  width: 3.62319vw;
  margin-right: 1.93237vw; }
  @media (min-width: 1300px) {
    .code-showcase-shareIcon {
      width: 0.88542vw;
      margin-right: 0.52083vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-shareIcon {
      width: 0.9838vw;
      margin-right: 0.52083vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-shareIcon {
      width: 0.88542vw;
      margin-right: 0.52083vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-shareIcon {
      width: 3.01932vw; }
      .code-showcase-shareIcon else {
        width: 15px; }
      .code-showcase-shareIcon else {
        margin-right: 1.93237vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-shareIcon {
      width: 2.41546vw; }
      .code-showcase-shareIcon else {
        width: 15px; }
      .code-showcase-shareIcon else {
        margin-right: 1.93237vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-shareIcon {
      width: 2.13129vw; }
      .code-showcase-shareIcon else {
        width: 15px; }
      .code-showcase-shareIcon else {
        margin-right: 1.93237vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-shareIcon {
      width: 1.90694vw; }
      .code-showcase-shareIcon else {
        width: 15px; }
      .code-showcase-shareIcon else {
        margin-right: 1.93237vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-shareIcon {
      width: 1.72533vw; }
      .code-showcase-shareIcon else {
        width: 15px; }
      .code-showcase-shareIcon else {
        margin-right: 1.93237vw; } }

.code-showcase-trendingIcon {
  margin-right: 10px;
  display: block;
  position: relative;
  width: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url(/static/media/trending.47185730.svg); }

.code-showcase-uhohMsgContainer {
  margin-top: 5.20833vw;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 600;
  color: #00171F;
  opacity: 0.6;
  font-size: 4.34783vw; }
  @media (min-width: 1300px) {
    .code-showcase-uhohMsgContainer {
      font-size: 1.5625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-uhohMsgContainer {
      font-size: 1.5625vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-uhohMsgContainer {
      font-size: 1.5625vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-uhohMsgContainer {
      font-size: 3.62319vw; }
      .code-showcase-uhohMsgContainer else {
        font-size: 18px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-uhohMsgContainer {
      font-size: 2.89855vw; }
      .code-showcase-uhohMsgContainer else {
        font-size: 18px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-uhohMsgContainer {
      font-size: 2.55754vw; }
      .code-showcase-uhohMsgContainer else {
        font-size: 18px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-uhohMsgContainer {
      font-size: 2.28833vw; }
      .code-showcase-uhohMsgContainer else {
        font-size: 18px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-uhohMsgContainer {
      font-size: 2.07039vw; }
      .code-showcase-uhohMsgContainer else {
        font-size: 18px; } }

.code-showcase-emptyMsgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 500;
  color: #00171F;
  opacity: 0.5;
  line-height: 32px;
  font-size: 4.34783vw; }
  @media (min-width: 1300px) {
    .code-showcase-emptyMsgContainer {
      font-size: 1.35417vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-emptyMsgContainer {
      font-size: 1.35417vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-emptyMsgContainer {
      font-size: 1.35417vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-emptyMsgContainer {
      font-size: 3.62319vw; }
      .code-showcase-emptyMsgContainer else {
        font-size: 18px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-emptyMsgContainer {
      font-size: 2.89855vw; }
      .code-showcase-emptyMsgContainer else {
        font-size: 18px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-emptyMsgContainer {
      font-size: 2.55754vw; }
      .code-showcase-emptyMsgContainer else {
        font-size: 18px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-emptyMsgContainer {
      font-size: 2.28833vw; }
      .code-showcase-emptyMsgContainer else {
        font-size: 18px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-emptyMsgContainer {
      font-size: 2.07039vw; }
      .code-showcase-emptyMsgContainer else {
        font-size: 18px; } }

.code-showcase-mainContainer {
  width: 100%;
  padding: 1rem 0; }

.code-showcase-trending-tag-container {
  min-width: 15vw;
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  padding: .5rem; }
  .code-showcase-trending-tag-container .code-showcase-trending-header {
    color: #403F3F;
    font-family: Nunito;
    font-weight: 600; }
    @media (min-width: 1300px) {
      .code-showcase-trending-tag-container .code-showcase-trending-header {
        font-size: 1.04167vw;
        padding: 0.41667vw 0.83333vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .code-showcase-trending-tag-container .code-showcase-trending-header {
        font-size: 1.15741vw;
        padding: 0.46296vw 0.92593vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .code-showcase-trending-tag-container .code-showcase-trending-header {
        font-size: 1.04167vw;
        padding: 0.41667vw 0.83333vw; } }
  .code-showcase-trending-tag-container > .code-showcase-divider {
    margin: 0 auto 8px; }
  .code-showcase-trending-tag-container .code-showcase-tags-body {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: scroll;
    max-height: 50vh;
    padding: 0 4px 8px; }
    @media (min-width: 1300px) {
      .code-showcase-trending-tag-container .code-showcase-tags-body .code-showcase-tags {
        margin: 0.41667vw 0.41667vw 0.41667vw 0; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .code-showcase-trending-tag-container .code-showcase-tags-body .code-showcase-tags {
        margin: 0.41667vw 0.41667vw 0.41667vw 0; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .code-showcase-trending-tag-container .code-showcase-tags-body .code-showcase-tags {
        margin: 0.41667vw 0.41667vw 0.41667vw 0; } }
    .code-showcase-trending-tag-container .code-showcase-tags-body .code-showcase-uhohMsgContainer {
      margin: 0;
      font-size: 1.14583vw; }

.code-showcase-code-view #editor {
  width: inherit !important; }

.code-showcase-code-view #editor1#editor1, div.interpretor[id^='interpretor'], div.interpretor[id^='__interpretor'] {
  background-color: transparent !important; }

.code-showcase-code-view .ace_editor {
  background-color: #002f3e !important;
  padding-top: 1.04167vw !important;
  box-sizing: border-box; }

.code-showcase-code-view ::-webkit-scrollbar {
  max-width: 0.52083vw; }

.code-showcase-code-view .ace_gutter {
  background-color: transparent !important; }

.code-showcase-code-view .ace-dracula .ace_gutter-active-line {
  background-color: transparent !important; }

.code-showcase-code-view .ace-dracula .ace_marker-layer .ace_active-line {
  background-color: transparent !important; }

.code-showcase-code-view .ace_scrollbar-v::-webkit-scrollbar {
  max-width: 0.10417vw; }

.code-showcase-code-view .ace_scrollbar-v::-webkit-scrollbar-thumb {
  background-color: rgba(52, 228, 234, 0.5);
  border-radius: 0; }

.code-showcase-playButton {
  position: absolute;
  background-image: url(/static/media/playButton.32a941f2.png);
  background-size: 117%;
  background-position: 47% 42%;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  width: 12.07729vw;
  height: 12.07729vw;
  bottom: 1.93237vw;
  right: 1.93237vw; }
  .code-showcase-playButton:hover {
    opacity: 0.5; }
  @media (min-width: 1300px) {
    .code-showcase-playButton {
      width: 3.64583vw;
      height: 3.64583vw;
      bottom: 0.83333vw;
      right: 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-playButton {
      width: 4.05093vw;
      height: 4.05093vw;
      bottom: 0.92593vw;
      right: 0.92593vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-playButton {
      width: 3.64583vw;
      height: 3.64583vw;
      bottom: 0.83333vw;
      right: 0.83333vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-playButton {
      width: 10.06441vw;
      height: 10.06441vw;
      bottom: 1.61031vw;
      right: 1.61031vw; }
      .code-showcase-playButton else {
        width: 50px; }
      .code-showcase-playButton else {
        height: 50px; }
      .code-showcase-playButton else {
        bottom: 8px; }
      .code-showcase-playButton else {
        right: 8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-playButton {
      width: 8.05153vw;
      height: 8.05153vw;
      bottom: 1.28824vw;
      right: 1.28824vw; }
      .code-showcase-playButton else {
        width: 50px; }
      .code-showcase-playButton else {
        height: 50px; }
      .code-showcase-playButton else {
        bottom: 8px; }
      .code-showcase-playButton else {
        right: 8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-playButton {
      width: 7.10429vw;
      height: 7.10429vw;
      bottom: 1.13669vw;
      right: 1.13669vw; }
      .code-showcase-playButton else {
        width: 50px; }
      .code-showcase-playButton else {
        height: 50px; }
      .code-showcase-playButton else {
        bottom: 8px; }
      .code-showcase-playButton else {
        right: 8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-playButton {
      width: 6.35647vw;
      height: 6.35647vw;
      bottom: 1.01704vw;
      right: 1.01704vw; }
      .code-showcase-playButton else {
        width: 50px; }
      .code-showcase-playButton else {
        height: 50px; }
      .code-showcase-playButton else {
        bottom: 8px; }
      .code-showcase-playButton else {
        right: 8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-playButton {
      width: 5.75109vw;
      height: 5.75109vw;
      bottom: 0.92017vw;
      right: 0.92017vw; }
      .code-showcase-playButton else {
        width: 50px; }
      .code-showcase-playButton else {
        height: 50px; }
      .code-showcase-playButton else {
        bottom: 8px; }
      .code-showcase-playButton else {
        right: 8px; } }

.code-showcase-fullScreenIcon {
  background-image: url(/static/media/fullscreen.cc86496c.svg);
  position: absolute;
  background-size: 100%;
  z-index: 999;
  margin: 8px 12px;
  background-position: 47% 42%;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  width: 5.31401vw;
  height: 5.31401vw;
  top: 1.93237vw;
  right: 1.93237vw; }
  .code-showcase-fullScreenIcon:hover {
    opacity: 0.5; }
  @media (min-width: 1300px) {
    .code-showcase-fullScreenIcon {
      width: 1.45833vw;
      height: 1.45833vw;
      top: 0.83333vw;
      right: 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-fullScreenIcon {
      width: 1.62037vw;
      height: 1.62037vw;
      top: 0.92593vw;
      right: 0.92593vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-fullScreenIcon {
      width: 1.45833vw;
      height: 1.45833vw;
      top: 0.83333vw;
      right: 0.83333vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-fullScreenIcon {
      width: 4.42834vw;
      height: 4.42834vw;
      top: 1.61031vw;
      right: 1.61031vw; }
      .code-showcase-fullScreenIcon else {
        width: 22px; }
      .code-showcase-fullScreenIcon else {
        height: 22px; }
      .code-showcase-fullScreenIcon else {
        top: 8px; }
      .code-showcase-fullScreenIcon else {
        right: 8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-fullScreenIcon {
      width: 3.54267vw;
      height: 3.54267vw;
      top: 1.28824vw;
      right: 1.28824vw; }
      .code-showcase-fullScreenIcon else {
        width: 22px; }
      .code-showcase-fullScreenIcon else {
        height: 22px; }
      .code-showcase-fullScreenIcon else {
        top: 8px; }
      .code-showcase-fullScreenIcon else {
        right: 8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-fullScreenIcon {
      width: 3.12589vw;
      height: 3.12589vw;
      top: 1.13669vw;
      right: 1.13669vw; }
      .code-showcase-fullScreenIcon else {
        width: 22px; }
      .code-showcase-fullScreenIcon else {
        height: 22px; }
      .code-showcase-fullScreenIcon else {
        top: 8px; }
      .code-showcase-fullScreenIcon else {
        right: 8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-fullScreenIcon {
      width: 2.79685vw;
      height: 2.79685vw;
      top: 1.01704vw;
      right: 1.01704vw; }
      .code-showcase-fullScreenIcon else {
        width: 22px; }
      .code-showcase-fullScreenIcon else {
        height: 22px; }
      .code-showcase-fullScreenIcon else {
        top: 8px; }
      .code-showcase-fullScreenIcon else {
        right: 8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-fullScreenIcon {
      width: 2.53048vw;
      height: 2.53048vw;
      top: 0.92017vw;
      right: 0.92017vw; }
      .code-showcase-fullScreenIcon else {
        width: 22px; }
      .code-showcase-fullScreenIcon else {
        height: 22px; }
      .code-showcase-fullScreenIcon else {
        top: 8px; }
      .code-showcase-fullScreenIcon else {
        right: 8px; } }

#editor {
  width: inherit !important;
  height: 100% !important; }

#editor1#editor1, div.interpretor[id^='interpretor'], div.interpretor[id^='__interpretor'] {
  background-color: transparent !important; }

.ace_hidden-cursors {
  opacity: 0; }

#__editor__full__screen {
  display: flex;
  flex-direction: column; }

#__editor__full__screen .ace_editor {
  background-color: #002f3e !important;
  box-sizing: border-box;
  padding-top: 4.83092vw !important; }
  @media (min-width: 1300px) {
    #__editor__full__screen .ace_editor {
      padding-top: 1.04167vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    #__editor__full__screen .ace_editor {
      padding-top: 1.15741vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    #__editor__full__screen .ace_editor {
      padding-top: 1.04167vw !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    #__editor__full__screen .ace_editor {
      padding-top: 4.02576vw !important; } }
  @media (min-width: 500px) and (max-width: 600px) {
    #__editor__full__screen .ace_editor {
      padding-top: 3.22061vw !important; } }
  @media (min-width: 600px) and (max-width: 700px) {
    #__editor__full__screen .ace_editor {
      padding-top: 2.84172vw !important; } }
  @media (min-width: 700px) and (max-width: 800px) {
    #__editor__full__screen .ace_editor {
      padding-top: 2.54259vw !important; } }
  @media (min-width: 800px) and (max-width: 899px) {
    #__editor__full__screen .ace_editor {
      padding-top: 2.30044vw !important; } }

#__editor__full__screen .ace_gutter {
  background-color: transparent !important;
  width: 10.86957vw !important; }
  @media (min-width: 1300px) {
    #__editor__full__screen .ace_gutter {
      width: 4.42708vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    #__editor__full__screen .ace_gutter {
      width: 4.91898vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    #__editor__full__screen .ace_gutter {
      width: 4.42708vw !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    #__editor__full__screen .ace_gutter {
      width: 9.05797vw !important; } }
  @media (min-width: 500px) and (max-width: 600px) {
    #__editor__full__screen .ace_gutter {
      width: 7.24638vw !important; } }
  @media (min-width: 600px) and (max-width: 700px) {
    #__editor__full__screen .ace_gutter {
      width: 6.39386vw !important; } }
  @media (min-width: 700px) and (max-width: 800px) {
    #__editor__full__screen .ace_gutter {
      width: 5.72082vw !important; } }
  @media (min-width: 800px) and (max-width: 899px) {
    #__editor__full__screen .ace_gutter {
      width: 5.17598vw !important; } }

.ace-dracula .ace_gutter-active-line {
  background-color: transparent !important; }

.ace-dracula .ace_marker-layer .ace_active-line {
  background-color: transparent !important; }

.ace_scrollbar-v::-webkit-scrollbar {
  max-width: 0.52083vw; }

.ace_scrollbar-v::-webkit-scrollbar-thumb {
  background-color: rgba(52, 228, 234, 0.5);
  border-radius: 0; }

.slideText {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 4.34783vw;
  margin-right: 8px;
  text-align: center;
  color: #FFFFFF; }
  @media (min-width: 1300px) {
    .slideText {
      font-size: 0.9375vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .slideText {
      font-size: 1.04167vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .slideText {
      font-size: 0.9375vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .slideText {
      font-size: 3.62319vw; }
      .slideText else {
        font-size: 18px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .slideText {
      font-size: 2.89855vw; }
      .slideText else {
        font-size: 18px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .slideText {
      font-size: 2.55754vw; }
      .slideText else {
        font-size: 18px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .slideText {
      font-size: 2.28833vw; }
      .slideText else {
        font-size: 18px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .slideText {
      font-size: 2.07039vw; }
      .slideText else {
        font-size: 18px; } }

.editor-toggleSwitch {
  position: relative;
  display: inline-block;
  width: 29px;
  height: 16px; }

.editor-toggleSwitch input {
  opacity: 0;
  width: 0;
  height: 0; }

.editor-toggleSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid #FFF;
  transition: .4s; }

.editor-toggleSlider:before {
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  background: #00ADE6;
  left: -1px;
  bottom: -1.5px;
  border: 2px solid white;
  transition: .4s; }

.editor-toggleSlider input:checked + .editor-toggleSlider:before {
  background: #65DA7A; }

.editor-toggleSlider input:checked + .editor-toggleSlider:before {
  -webkit-transform: translateX(12px);
  transform: translateX(12px); }

/* Rounded sliders */
.editor-toggleSlider.editorToggleRound {
  border-radius: 34px; }

.editor-toggleSlider.editorToggleRound:before {
  border-radius: 50%; }

.xterm-cursor-link {
  background: #052732; }

.output--clear-btn {
  cursor: pointer;
  background: none;
  box-sizing: border-box;
  border: none;
  color: #fff;
  text-transform: capitalize;
  font-family: inherit;
  font-weight: bold;
  border-bottom: 1px dashed #ffffff; }
  @media (min-width: 1300px) {
    .output--clear-btn {
      font-size: 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .output--clear-btn {
      font-size: 0.92593vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .output--clear-btn {
      font-size: 0.83333vw; } }
  .output--clear-btn > {
    margin: 0; }

.playButton {
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(54.2deg, #00ade6 36.49%, #34e4ea 91.95%);
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  right: 20px;
  bottom: 56px;
  width: 14.49275vw;
  height: 14.49275vw; }
  @media (min-width: 1300px) {
    .playButton {
      width: 3.75vw;
      height: 3.75vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .playButton {
      width: 4.16667vw;
      height: 4.16667vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .playButton {
      width: 3.75vw;
      height: 3.75vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .playButton {
      width: 12.07729vw;
      height: 12.07729vw; }
      .playButton else {
        width: 60px; }
      .playButton else {
        height: 60px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .playButton {
      width: 9.66184vw;
      height: 9.66184vw; }
      .playButton else {
        width: 60px; }
      .playButton else {
        height: 60px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .playButton {
      width: 8.52515vw;
      height: 8.52515vw; }
      .playButton else {
        width: 60px; }
      .playButton else {
        height: 60px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .playButton {
      width: 7.62777vw;
      height: 7.62777vw; }
      .playButton else {
        width: 60px; }
      .playButton else {
        height: 60px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .playButton {
      width: 6.90131vw;
      height: 6.90131vw; }
      .playButton else {
        width: 60px; }
      .playButton else {
        height: 60px; } }
  .playButton g {
    stroke: transparent !important; }
  .playButton:hover {
    opacity: 0.5; }

.toggle {
  --switch-on-clr: #00ade6;
  --switch-off-clr: #888888;
  --switch-padding: 7px;
  --text-on-clr: #a8a7a7;
  --text-off-clr: #fff;
  display: flex;
  justify-self: end;
  align-items: center; }
  @media (min-width: 1300px) {
    .toggle {
      grid-gap: 0.625vw;
      gap: 0.625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .toggle {
      grid-gap: 0.69444vw;
      gap: 0.69444vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .toggle {
      grid-gap: 0.625vw;
      gap: 0.625vw; } }
  .toggle .switch {
    box-sizing: border-box;
    background-color: #00ADE6;
    display: flex;
    align-items: center;
    border-radius: 50px;
    background-color: var(--switch-off-clr);
    transition: background-color 0.6s ease;
    cursor: pointer;
    position: relative;
    width: 32px;
    height: 16px; }
    .toggle .switch .handle {
      position: absolute;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      left: 2px;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
      background-color: white;
      border-radius: 50%;
      transition: -webkit-transform 0.3s ease;
      transition: transform 0.3s ease;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease;
      width: 13px;
      height: 13px; }
  .toggle p {
    letter-spacing: -0.02em;
    text-transform: capitalize;
    font-weight: bold;
    color: var(--text-on-clr); }
    @media (min-width: 1300px) {
      .toggle p {
        font-size: 1.04167vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .toggle p {
        font-size: 1.15741vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .toggle p {
        font-size: 1.04167vw; } }
  .toggle[data-isOn="true"] .handle {
    -webkit-transform: translate(15px, -50%);
            transform: translate(15px, -50%); }
  .toggle[data-isOn="true"] p {
    color: var(--tex-off-clr); }

.codeplayground-fullHeight {
  height: calc(100vh - 8.02083vw) !important; }

.CodeShowcaseSkeleton_cardLayout__3gOpH {
  box-sizing: border-box;
  padding: 1.04167vw 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column; }
  @media (min-width: 1300px) {
    .CodeShowcaseSkeleton_cardLayout__3gOpH {
      flex-direction: row; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .CodeShowcaseSkeleton_cardLayout__3gOpH {
      flex-direction: row; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .CodeShowcaseSkeleton_cardLayout__3gOpH {
      flex-direction: row; } }

.CodeShowcaseSkeleton_card__3kkHI {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff; }

.CodeShowcaseSkeleton_codeAuthorDetails__25m9o {
  box-sizing: border-box;
  padding: 0px 32px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center; }
  @media (min-width: 1300px) {
    .CodeShowcaseSkeleton_codeAuthorDetails__25m9o {
      width: 10vw;
      justify-content: flex-start;
      align-items: flex-start; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .CodeShowcaseSkeleton_codeAuthorDetails__25m9o {
      width: 10vw;
      justify-content: flex-start;
      align-items: flex-start; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .CodeShowcaseSkeleton_codeAuthorDetails__25m9o {
      width: 10vw;
      justify-content: flex-start;
      align-items: flex-start; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .CodeShowcaseSkeleton_codeAuthorDetails__25m9o else {
      width: 100%; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .CodeShowcaseSkeleton_codeAuthorDetails__25m9o else {
      width: 100%; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .CodeShowcaseSkeleton_codeAuthorDetails__25m9o else {
      width: 100%; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .CodeShowcaseSkeleton_codeAuthorDetails__25m9o else {
      width: 100%; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .CodeShowcaseSkeleton_codeAuthorDetails__25m9o else {
      width: 100%; } }
  .CodeShowcaseSkeleton_codeAuthorDetails__25m9o > svg {
    margin-top: 8px; }

.CodeShowcaseSkeleton_codeDetailsContainer__16S5N {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 15px 0px;
  padding-right: 26px; }
  .CodeShowcaseSkeleton_codeDetailsContainer__16S5N .CodeShowcaseSkeleton_codeDetails__1OpOe {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 0vw 6.28019vw; }
    @media (min-width: 1300px) {
      .CodeShowcaseSkeleton_codeDetailsContainer__16S5N .CodeShowcaseSkeleton_codeDetails__1OpOe {
        padding: 0vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .CodeShowcaseSkeleton_codeDetailsContainer__16S5N .CodeShowcaseSkeleton_codeDetails__1OpOe {
        padding: 0vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .CodeShowcaseSkeleton_codeDetailsContainer__16S5N .CodeShowcaseSkeleton_codeDetails__1OpOe {
        padding: 0vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .CodeShowcaseSkeleton_codeDetailsContainer__16S5N .CodeShowcaseSkeleton_codeDetails__1OpOe {
        padding: 0vw 5.23349vw; }
        .CodeShowcaseSkeleton_codeDetailsContainer__16S5N .CodeShowcaseSkeleton_codeDetails__1OpOe else {
          width: 100%; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .CodeShowcaseSkeleton_codeDetailsContainer__16S5N .CodeShowcaseSkeleton_codeDetails__1OpOe {
        padding: 0vw 4.1868vw; }
        .CodeShowcaseSkeleton_codeDetailsContainer__16S5N .CodeShowcaseSkeleton_codeDetails__1OpOe else {
          width: 100%; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .CodeShowcaseSkeleton_codeDetailsContainer__16S5N .CodeShowcaseSkeleton_codeDetails__1OpOe {
        padding: 0vw 3.69423vw; }
        .CodeShowcaseSkeleton_codeDetailsContainer__16S5N .CodeShowcaseSkeleton_codeDetails__1OpOe else {
          width: 100%; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .CodeShowcaseSkeleton_codeDetailsContainer__16S5N .CodeShowcaseSkeleton_codeDetails__1OpOe {
        padding: 0vw 3.30536vw; }
        .CodeShowcaseSkeleton_codeDetailsContainer__16S5N .CodeShowcaseSkeleton_codeDetails__1OpOe else {
          width: 100%; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .CodeShowcaseSkeleton_codeDetailsContainer__16S5N .CodeShowcaseSkeleton_codeDetails__1OpOe {
        padding: 0vw 2.99057vw; }
        .CodeShowcaseSkeleton_codeDetailsContainer__16S5N .CodeShowcaseSkeleton_codeDetails__1OpOe else {
          width: 100%; } }

.CodeShowcaseSkeleton_reactionContainer__3qjrH {
  padding: 0vw 6.28019vw; }
  @media (min-width: 1300px) {
    .CodeShowcaseSkeleton_reactionContainer__3qjrH {
      padding: 0vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .CodeShowcaseSkeleton_reactionContainer__3qjrH {
      padding: 0vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .CodeShowcaseSkeleton_reactionContainer__3qjrH {
      padding: 0vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .CodeShowcaseSkeleton_reactionContainer__3qjrH {
      padding: 0vw 5.23349vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .CodeShowcaseSkeleton_reactionContainer__3qjrH {
      padding: 0vw 4.1868vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .CodeShowcaseSkeleton_reactionContainer__3qjrH {
      padding: 0vw 3.69423vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .CodeShowcaseSkeleton_reactionContainer__3qjrH {
      padding: 0vw 3.30536vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .CodeShowcaseSkeleton_reactionContainer__3qjrH {
      padding: 0vw 2.99057vw; } }

.CodeShowcaseSkeleton_profileCard__3BJX_ {
  align-self: center;
  border-radius: 100%;
  width: 16.90821vw;
  height: 16.90821vw; }
  @media (min-width: 1300px) {
    .CodeShowcaseSkeleton_profileCard__3BJX_ {
      width: 6.25vw;
      height: 6.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .CodeShowcaseSkeleton_profileCard__3BJX_ {
      width: 6.94444vw;
      height: 6.94444vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .CodeShowcaseSkeleton_profileCard__3BJX_ {
      width: 6.25vw;
      height: 6.25vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .CodeShowcaseSkeleton_profileCard__3BJX_ {
      width: 14.09018vw;
      height: 14.09018vw; }
      .CodeShowcaseSkeleton_profileCard__3BJX_ else {
        width: 70px; }
      .CodeShowcaseSkeleton_profileCard__3BJX_ else {
        height: 70px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .CodeShowcaseSkeleton_profileCard__3BJX_ {
      width: 11.27214vw;
      height: 11.27214vw; }
      .CodeShowcaseSkeleton_profileCard__3BJX_ else {
        width: 70px; }
      .CodeShowcaseSkeleton_profileCard__3BJX_ else {
        height: 70px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .CodeShowcaseSkeleton_profileCard__3BJX_ {
      width: 9.94601vw;
      height: 9.94601vw; }
      .CodeShowcaseSkeleton_profileCard__3BJX_ else {
        width: 70px; }
      .CodeShowcaseSkeleton_profileCard__3BJX_ else {
        height: 70px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .CodeShowcaseSkeleton_profileCard__3BJX_ {
      width: 8.89906vw;
      height: 8.89906vw; }
      .CodeShowcaseSkeleton_profileCard__3BJX_ else {
        width: 70px; }
      .CodeShowcaseSkeleton_profileCard__3BJX_ else {
        height: 70px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .CodeShowcaseSkeleton_profileCard__3BJX_ {
      width: 8.05153vw;
      height: 8.05153vw; }
      .CodeShowcaseSkeleton_profileCard__3BJX_ else {
        width: 70px; }
      .CodeShowcaseSkeleton_profileCard__3BJX_ else {
        height: 70px; } }

.CodeShowcaseSkeleton_profileSubCard__jeSLi {
  align-items: center;
  width: 19.32367vw;
  height: 3.62319vw; }
  @media (min-width: 1300px) {
    .CodeShowcaseSkeleton_profileSubCard__jeSLi {
      width: 100%;
      height: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .CodeShowcaseSkeleton_profileSubCard__jeSLi {
      width: 100%;
      height: 1.04167vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .CodeShowcaseSkeleton_profileSubCard__jeSLi {
      width: 100%;
      height: 1.04167vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .CodeShowcaseSkeleton_profileSubCard__jeSLi else {
      width: 19.32367vw; }
    .CodeShowcaseSkeleton_profileSubCard__jeSLi else {
      height: 3.62319vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .CodeShowcaseSkeleton_profileSubCard__jeSLi else {
      width: 19.32367vw; }
    .CodeShowcaseSkeleton_profileSubCard__jeSLi else {
      height: 3.62319vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .CodeShowcaseSkeleton_profileSubCard__jeSLi else {
      width: 19.32367vw; }
    .CodeShowcaseSkeleton_profileSubCard__jeSLi else {
      height: 3.62319vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .CodeShowcaseSkeleton_profileSubCard__jeSLi else {
      width: 19.32367vw; }
    .CodeShowcaseSkeleton_profileSubCard__jeSLi else {
      height: 3.62319vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .CodeShowcaseSkeleton_profileSubCard__jeSLi else {
      width: 19.32367vw; }
    .CodeShowcaseSkeleton_profileSubCard__jeSLi else {
      height: 3.62319vw; } }

.CodeShowcaseSkeleton_SecondarySubCard__1Xjwl {
  align-items: center;
  width: 14.49275vw;
  height: 2.41546vw; }
  @media (min-width: 1300px) {
    .CodeShowcaseSkeleton_SecondarySubCard__1Xjwl {
      width: 80%;
      height: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .CodeShowcaseSkeleton_SecondarySubCard__1Xjwl {
      width: 80%;
      height: 1.04167vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .CodeShowcaseSkeleton_SecondarySubCard__1Xjwl {
      width: 80%;
      height: 1.04167vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .CodeShowcaseSkeleton_SecondarySubCard__1Xjwl else {
      width: 14.49275vw; }
    .CodeShowcaseSkeleton_SecondarySubCard__1Xjwl else {
      height: 2.41546vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .CodeShowcaseSkeleton_SecondarySubCard__1Xjwl else {
      width: 14.49275vw; }
    .CodeShowcaseSkeleton_SecondarySubCard__1Xjwl else {
      height: 2.41546vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .CodeShowcaseSkeleton_SecondarySubCard__1Xjwl else {
      width: 14.49275vw; }
    .CodeShowcaseSkeleton_SecondarySubCard__1Xjwl else {
      height: 2.41546vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .CodeShowcaseSkeleton_SecondarySubCard__1Xjwl else {
      width: 14.49275vw; }
    .CodeShowcaseSkeleton_SecondarySubCard__1Xjwl else {
      height: 2.41546vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .CodeShowcaseSkeleton_SecondarySubCard__1Xjwl else {
      width: 14.49275vw; }
    .CodeShowcaseSkeleton_SecondarySubCard__1Xjwl else {
      height: 2.41546vw; } }

.CodeShowcaseSkeleton_reactionCard__Nzn4h {
  margin: 10px 10px 10px 0px;
  border-radius: 50px;
  width: 12.07729vw;
  height: 4.83092vw; }
  @media (min-width: 1300px) {
    .CodeShowcaseSkeleton_reactionCard__Nzn4h {
      width: 4.6875vw;
      height: 1.82292vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .CodeShowcaseSkeleton_reactionCard__Nzn4h {
      width: 4.6875vw;
      height: 1.82292vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .CodeShowcaseSkeleton_reactionCard__Nzn4h {
      width: 4.6875vw;
      height: 1.82292vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .CodeShowcaseSkeleton_reactionCard__Nzn4h else {
      width: 12.07729vw; }
    .CodeShowcaseSkeleton_reactionCard__Nzn4h else {
      height: 4.83092vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .CodeShowcaseSkeleton_reactionCard__Nzn4h else {
      width: 12.07729vw; }
    .CodeShowcaseSkeleton_reactionCard__Nzn4h else {
      height: 4.83092vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .CodeShowcaseSkeleton_reactionCard__Nzn4h else {
      width: 12.07729vw; }
    .CodeShowcaseSkeleton_reactionCard__Nzn4h else {
      height: 4.83092vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .CodeShowcaseSkeleton_reactionCard__Nzn4h else {
      width: 12.07729vw; }
    .CodeShowcaseSkeleton_reactionCard__Nzn4h else {
      height: 4.83092vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .CodeShowcaseSkeleton_reactionCard__Nzn4h else {
      width: 12.07729vw; }
    .CodeShowcaseSkeleton_reactionCard__Nzn4h else {
      height: 4.83092vw; } }

.CodeShowcaseSkeleton_topCard__2XScj {
  width: 40%;
  height: 5.20833vw;
  margin-top: 2.60417vw; }
  @media (min-width: 1300px) {
    .CodeShowcaseSkeleton_topCard__2XScj {
      width: 30%;
      height: 2.08333vw;
      margin-top: 0; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .CodeShowcaseSkeleton_topCard__2XScj {
      width: 30%;
      height: 2.08333vw;
      margin-top: 0; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .CodeShowcaseSkeleton_topCard__2XScj {
      width: 30%;
      height: 2.08333vw;
      margin-top: 0; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .CodeShowcaseSkeleton_topCard__2XScj else {
      width: 40%; }
    .CodeShowcaseSkeleton_topCard__2XScj else {
      height: 5.20833vw; }
    .CodeShowcaseSkeleton_topCard__2XScj else {
      margin-top: 2.60417vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .CodeShowcaseSkeleton_topCard__2XScj else {
      width: 40%; }
    .CodeShowcaseSkeleton_topCard__2XScj else {
      height: 5.20833vw; }
    .CodeShowcaseSkeleton_topCard__2XScj else {
      margin-top: 2.60417vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .CodeShowcaseSkeleton_topCard__2XScj else {
      width: 40%; }
    .CodeShowcaseSkeleton_topCard__2XScj else {
      height: 5.20833vw; }
    .CodeShowcaseSkeleton_topCard__2XScj else {
      margin-top: 2.60417vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .CodeShowcaseSkeleton_topCard__2XScj else {
      width: 40%; }
    .CodeShowcaseSkeleton_topCard__2XScj else {
      height: 5.20833vw; }
    .CodeShowcaseSkeleton_topCard__2XScj else {
      margin-top: 2.60417vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .CodeShowcaseSkeleton_topCard__2XScj else {
      width: 40%; }
    .CodeShowcaseSkeleton_topCard__2XScj else {
      height: 5.20833vw; }
    .CodeShowcaseSkeleton_topCard__2XScj else {
      margin-top: 2.60417vw; } }

.CodeShowcaseSkeleton_subHeadingCard__o_o5u {
  width: 50%;
  margin-top: 2.60417vw;
  height: 2.60417vw; }
  @media (min-width: 1300px) {
    .CodeShowcaseSkeleton_subHeadingCard__o_o5u {
      height: 1.04167vw;
      margin-top: 0.78125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .CodeShowcaseSkeleton_subHeadingCard__o_o5u {
      height: 1.04167vw;
      margin-top: 0.78125vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .CodeShowcaseSkeleton_subHeadingCard__o_o5u {
      height: 1.04167vw;
      margin-top: 0.78125vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .CodeShowcaseSkeleton_subHeadingCard__o_o5u else {
      margin-top: 2.60417vw; }
    .CodeShowcaseSkeleton_subHeadingCard__o_o5u else {
      height: 2.60417vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .CodeShowcaseSkeleton_subHeadingCard__o_o5u else {
      margin-top: 2.60417vw; }
    .CodeShowcaseSkeleton_subHeadingCard__o_o5u else {
      height: 2.60417vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .CodeShowcaseSkeleton_subHeadingCard__o_o5u else {
      margin-top: 2.60417vw; }
    .CodeShowcaseSkeleton_subHeadingCard__o_o5u else {
      height: 2.60417vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .CodeShowcaseSkeleton_subHeadingCard__o_o5u else {
      margin-top: 2.60417vw; }
    .CodeShowcaseSkeleton_subHeadingCard__o_o5u else {
      height: 2.60417vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .CodeShowcaseSkeleton_subHeadingCard__o_o5u else {
      margin-top: 2.60417vw; }
    .CodeShowcaseSkeleton_subHeadingCard__o_o5u else {
      height: 2.60417vw; } }

.CodeShowcaseSkeleton_middleCard__3fznz {
  width: 100%;
  margin: 0;
  height: 15.625vw; }
  @media (min-width: 1300px) {
    .CodeShowcaseSkeleton_middleCard__3fznz {
      margin: 0.26042vw 0;
      height: 3.125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .CodeShowcaseSkeleton_middleCard__3fznz {
      margin: 0.26042vw 0;
      height: 3.125vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .CodeShowcaseSkeleton_middleCard__3fznz {
      margin: 0.26042vw 0;
      height: 3.125vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .CodeShowcaseSkeleton_middleCard__3fznz else {
      margin: 0; }
    .CodeShowcaseSkeleton_middleCard__3fznz else {
      height: 15.625vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .CodeShowcaseSkeleton_middleCard__3fznz else {
      margin: 0; }
    .CodeShowcaseSkeleton_middleCard__3fznz else {
      height: 15.625vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .CodeShowcaseSkeleton_middleCard__3fznz else {
      margin: 0; }
    .CodeShowcaseSkeleton_middleCard__3fznz else {
      height: 15.625vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .CodeShowcaseSkeleton_middleCard__3fznz else {
      margin: 0; }
    .CodeShowcaseSkeleton_middleCard__3fznz else {
      height: 15.625vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .CodeShowcaseSkeleton_middleCard__3fznz else {
      margin: 0; }
    .CodeShowcaseSkeleton_middleCard__3fznz else {
      height: 15.625vw; } }

.CodeShowcaseSkeleton_lastCard__3ihMp {
  box-sizing: border-box;
  width: 100%;
  height: 52.08333vw;
  margin-top: 2.60417vw;
  border-radius: 0vw;
  padding: 0vw 0.52083vw; }
  @media (min-width: 1300px) {
    .CodeShowcaseSkeleton_lastCard__3ihMp {
      width: 100%;
      margin-top: 1.04167vw;
      height: 15.625vw;
      border-radius: 0vw 1.04167vw 1.04167vw 1.04167vw;
      padding: 0vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .CodeShowcaseSkeleton_lastCard__3ihMp {
      width: 100%;
      margin-top: 1.04167vw;
      height: 15.625vw;
      border-radius: 0vw 1.15741vw 1.15741vw 1.15741vw;
      padding: 0vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .CodeShowcaseSkeleton_lastCard__3ihMp {
      width: 100%;
      margin-top: 1.04167vw;
      height: 15.625vw;
      border-radius: 0vw 1.04167vw 1.04167vw 1.04167vw;
      padding: 0vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .CodeShowcaseSkeleton_lastCard__3ihMp {
      border-radius: 0vw;
      padding: 0vw 0.52083vw; }
      .CodeShowcaseSkeleton_lastCard__3ihMp else {
        width: 100%; }
      .CodeShowcaseSkeleton_lastCard__3ihMp else {
        height: 52.08333vw; }
      .CodeShowcaseSkeleton_lastCard__3ihMp else {
        margin-top: 2.60417vw; }
      .CodeShowcaseSkeleton_lastCard__3ihMp else {
        border-radius: 0px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .CodeShowcaseSkeleton_lastCard__3ihMp {
      border-radius: 0vw;
      padding: 0vw 0.52083vw; }
      .CodeShowcaseSkeleton_lastCard__3ihMp else {
        width: 100%; }
      .CodeShowcaseSkeleton_lastCard__3ihMp else {
        height: 52.08333vw; }
      .CodeShowcaseSkeleton_lastCard__3ihMp else {
        margin-top: 2.60417vw; }
      .CodeShowcaseSkeleton_lastCard__3ihMp else {
        border-radius: 0px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .CodeShowcaseSkeleton_lastCard__3ihMp {
      border-radius: 0vw;
      padding: 0vw 0.52083vw; }
      .CodeShowcaseSkeleton_lastCard__3ihMp else {
        width: 100%; }
      .CodeShowcaseSkeleton_lastCard__3ihMp else {
        height: 52.08333vw; }
      .CodeShowcaseSkeleton_lastCard__3ihMp else {
        margin-top: 2.60417vw; }
      .CodeShowcaseSkeleton_lastCard__3ihMp else {
        border-radius: 0px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .CodeShowcaseSkeleton_lastCard__3ihMp {
      border-radius: 0vw;
      padding: 0vw 0.52083vw; }
      .CodeShowcaseSkeleton_lastCard__3ihMp else {
        width: 100%; }
      .CodeShowcaseSkeleton_lastCard__3ihMp else {
        height: 52.08333vw; }
      .CodeShowcaseSkeleton_lastCard__3ihMp else {
        margin-top: 2.60417vw; }
      .CodeShowcaseSkeleton_lastCard__3ihMp else {
        border-radius: 0px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .CodeShowcaseSkeleton_lastCard__3ihMp {
      border-radius: 0vw;
      padding: 0vw 0.52083vw; }
      .CodeShowcaseSkeleton_lastCard__3ihMp else {
        width: 100%; }
      .CodeShowcaseSkeleton_lastCard__3ihMp else {
        height: 52.08333vw; }
      .CodeShowcaseSkeleton_lastCard__3ihMp else {
        margin-top: 2.60417vw; }
      .CodeShowcaseSkeleton_lastCard__3ihMp else {
        border-radius: 0px; } }

.ShareOverlay_container__3iskN {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.3); }

.ShareOverlay_closeBtn__1Xm_C {
  cursor: pointer;
  position: absolute;
  font-weight: bolder;
  top: 0;
  right: 0;
  margin: 15px; }

.ShareOverlay_modalContainer__25EVo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  left: 41.09375vw;
  position: absolute;
  object-fit: contain;
  background-size: contain;
  -webkit-transform: scale(1);
          transform: scale(1);
  top: 35%;
  width: 20.83333vw;
  border-radius: 0.96618vw;
  padding: 1.93237vw 0.96618vw 0.96618vw; }
  @media (min-width: 1300px) {
    .ShareOverlay_modalContainer__25EVo {
      top: 22%;
      width: 18.75vw;
      border-radius: 0.9375vw;
      padding: 1.25vw 0.83333vw 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ShareOverlay_modalContainer__25EVo {
      top: 22%;
      width: 18.75vw;
      border-radius: 1.04167vw;
      padding: 1.38889vw 0.92593vw 0.92593vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .ShareOverlay_modalContainer__25EVo {
      top: 22%;
      width: 18.75vw;
      border-radius: 0.9375vw;
      padding: 1.25vw 0.83333vw 0.83333vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .ShareOverlay_modalContainer__25EVo {
      border-radius: 0.80515vw;
      padding: 1.61031vw 0.80515vw 0.80515vw; }
      .ShareOverlay_modalContainer__25EVo else {
        top: 35%; }
      .ShareOverlay_modalContainer__25EVo else {
        width: 20.83333vw; }
      .ShareOverlay_modalContainer__25EVo else {
        border-radius: 4px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .ShareOverlay_modalContainer__25EVo {
      border-radius: 0.64412vw;
      padding: 1.28824vw 0.64412vw 0.64412vw; }
      .ShareOverlay_modalContainer__25EVo else {
        top: 35%; }
      .ShareOverlay_modalContainer__25EVo else {
        width: 20.83333vw; }
      .ShareOverlay_modalContainer__25EVo else {
        border-radius: 4px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .ShareOverlay_modalContainer__25EVo {
      border-radius: 0.56834vw;
      padding: 1.13669vw 0.56834vw 0.56834vw; }
      .ShareOverlay_modalContainer__25EVo else {
        top: 35%; }
      .ShareOverlay_modalContainer__25EVo else {
        width: 20.83333vw; }
      .ShareOverlay_modalContainer__25EVo else {
        border-radius: 4px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .ShareOverlay_modalContainer__25EVo {
      border-radius: 0.50852vw;
      padding: 1.01704vw 0.50852vw 0.50852vw; }
      .ShareOverlay_modalContainer__25EVo else {
        top: 35%; }
      .ShareOverlay_modalContainer__25EVo else {
        width: 20.83333vw; }
      .ShareOverlay_modalContainer__25EVo else {
        border-radius: 4px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .ShareOverlay_modalContainer__25EVo {
      border-radius: 0.46009vw;
      padding: 0.92017vw 0.46009vw 0.46009vw; }
      .ShareOverlay_modalContainer__25EVo else {
        top: 35%; }
      .ShareOverlay_modalContainer__25EVo else {
        width: 20.83333vw; }
      .ShareOverlay_modalContainer__25EVo else {
        border-radius: 4px; } }

.ShareOverlay_ShareOverlayClose__TUsAh {
  position: absolute;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);
  width: 4.83092vw;
  height: 4.83092vw;
  top: -1.81159vw;
  right: -1.20773vw;
  transition: 0.3s all ease-in-out; }
  @media (min-width: 1300px) {
    .ShareOverlay_ShareOverlayClose__TUsAh {
      width: 2.60417vw;
      height: 2.60417vw;
      top: -1.30208vw;
      right: -1.30208vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ShareOverlay_ShareOverlayClose__TUsAh {
      width: 2.89352vw;
      height: 2.89352vw;
      top: -1.44676vw;
      right: -1.44676vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .ShareOverlay_ShareOverlayClose__TUsAh {
      width: 2.60417vw;
      height: 2.60417vw;
      top: -1.30208vw;
      right: -1.30208vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .ShareOverlay_ShareOverlayClose__TUsAh {
      width: 4.02576vw;
      height: 4.02576vw;
      top: -1.50966vw;
      right: -1.00644vw; }
      .ShareOverlay_ShareOverlayClose__TUsAh else {
        width: 20px; }
      .ShareOverlay_ShareOverlayClose__TUsAh else {
        height: 20px; }
      .ShareOverlay_ShareOverlayClose__TUsAh else {
        top: -7.5px; }
      .ShareOverlay_ShareOverlayClose__TUsAh else {
        right: -5px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .ShareOverlay_ShareOverlayClose__TUsAh {
      width: 3.22061vw;
      height: 3.22061vw;
      top: -1.20773vw;
      right: -0.80515vw; }
      .ShareOverlay_ShareOverlayClose__TUsAh else {
        width: 20px; }
      .ShareOverlay_ShareOverlayClose__TUsAh else {
        height: 20px; }
      .ShareOverlay_ShareOverlayClose__TUsAh else {
        top: -7.5px; }
      .ShareOverlay_ShareOverlayClose__TUsAh else {
        right: -5px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .ShareOverlay_ShareOverlayClose__TUsAh {
      width: 2.84172vw;
      height: 2.84172vw;
      top: -1.06564vw;
      right: -0.71043vw; }
      .ShareOverlay_ShareOverlayClose__TUsAh else {
        width: 20px; }
      .ShareOverlay_ShareOverlayClose__TUsAh else {
        height: 20px; }
      .ShareOverlay_ShareOverlayClose__TUsAh else {
        top: -7.5px; }
      .ShareOverlay_ShareOverlayClose__TUsAh else {
        right: -5px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .ShareOverlay_ShareOverlayClose__TUsAh {
      width: 2.54259vw;
      height: 2.54259vw;
      top: -0.95347vw;
      right: -0.63565vw; }
      .ShareOverlay_ShareOverlayClose__TUsAh else {
        width: 20px; }
      .ShareOverlay_ShareOverlayClose__TUsAh else {
        height: 20px; }
      .ShareOverlay_ShareOverlayClose__TUsAh else {
        top: -7.5px; }
      .ShareOverlay_ShareOverlayClose__TUsAh else {
        right: -5px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .ShareOverlay_ShareOverlayClose__TUsAh {
      width: 2.30044vw;
      height: 2.30044vw;
      top: -0.86266vw;
      right: -0.57511vw; }
      .ShareOverlay_ShareOverlayClose__TUsAh else {
        width: 20px; }
      .ShareOverlay_ShareOverlayClose__TUsAh else {
        height: 20px; }
      .ShareOverlay_ShareOverlayClose__TUsAh else {
        top: -7.5px; }
      .ShareOverlay_ShareOverlayClose__TUsAh else {
        right: -5px; } }
  .ShareOverlay_ShareOverlayClose__TUsAh:hover {
    background: #ccf2ff;
    box-shadow: 0px 6px 6px rgba(51, 51, 51, 0.2), 0px 5px 20px rgba(51, 51, 51, 0.08); }

.ShareOverlay_shareOverlayCloseIcon__3gaRQ {
  position: relative;
  width: 1.93237vw;
  height: 1.93237vw;
  top: -1.93237vw; }
  @media (min-width: 1300px) {
    .ShareOverlay_shareOverlayCloseIcon__3gaRQ {
      width: 1.04167vw;
      height: 1.04167vw;
      top: -0.05208vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ShareOverlay_shareOverlayCloseIcon__3gaRQ {
      width: 1.15741vw;
      height: 1.15741vw;
      top: -0.05787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .ShareOverlay_shareOverlayCloseIcon__3gaRQ {
      width: 1.04167vw;
      height: 1.04167vw;
      top: -0.05208vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .ShareOverlay_shareOverlayCloseIcon__3gaRQ {
      width: 1.61031vw;
      height: 1.61031vw;
      top: -1.61031vw; }
      .ShareOverlay_shareOverlayCloseIcon__3gaRQ else {
        width: 8px; }
      .ShareOverlay_shareOverlayCloseIcon__3gaRQ else {
        height: 8px; }
      .ShareOverlay_shareOverlayCloseIcon__3gaRQ else {
        top: -8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .ShareOverlay_shareOverlayCloseIcon__3gaRQ {
      width: 1.28824vw;
      height: 1.28824vw;
      top: -1.28824vw; }
      .ShareOverlay_shareOverlayCloseIcon__3gaRQ else {
        width: 8px; }
      .ShareOverlay_shareOverlayCloseIcon__3gaRQ else {
        height: 8px; }
      .ShareOverlay_shareOverlayCloseIcon__3gaRQ else {
        top: -8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .ShareOverlay_shareOverlayCloseIcon__3gaRQ {
      width: 1.13669vw;
      height: 1.13669vw;
      top: -1.13669vw; }
      .ShareOverlay_shareOverlayCloseIcon__3gaRQ else {
        width: 8px; }
      .ShareOverlay_shareOverlayCloseIcon__3gaRQ else {
        height: 8px; }
      .ShareOverlay_shareOverlayCloseIcon__3gaRQ else {
        top: -8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .ShareOverlay_shareOverlayCloseIcon__3gaRQ {
      width: 1.01704vw;
      height: 1.01704vw;
      top: -1.01704vw; }
      .ShareOverlay_shareOverlayCloseIcon__3gaRQ else {
        width: 8px; }
      .ShareOverlay_shareOverlayCloseIcon__3gaRQ else {
        height: 8px; }
      .ShareOverlay_shareOverlayCloseIcon__3gaRQ else {
        top: -8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .ShareOverlay_shareOverlayCloseIcon__3gaRQ {
      width: 0.92017vw;
      height: 0.92017vw;
      top: -0.92017vw; }
      .ShareOverlay_shareOverlayCloseIcon__3gaRQ else {
        width: 8px; }
      .ShareOverlay_shareOverlayCloseIcon__3gaRQ else {
        height: 8px; }
      .ShareOverlay_shareOverlayCloseIcon__3gaRQ else {
        top: -8px; } }
  .ShareOverlay_shareOverlayCloseIcon__3gaRQ path {
    fill: #00ADE5;
    opacity: 1; }

@media screen and (max-width: 800px) {
  .ShareOverlay_modalContainer__25EVo {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  .ShareOverlay_closeBtn__1Xm_C {
    font-size: 8px;
    margin: 5px 8px; }
  .ShareOverlay_shareButtonContainer__Y6LXA {
    height: 100% !important; }
  .ShareOverlay_ShareOverlayClose__TUsAh {
    width: 20px;
    height: 20px;
    top: -6px !important;
    right: -6px !important; }
  .ShareOverlay_shareOverlayCloseIcon__3gaRQ {
    width: 7px; } }

@media screen and (max-width: 500px) {
  .ShareOverlay_modalContainer__25EVo {
    -webkit-transform: scale(3);
            transform: scale(3); }
  .ShareOverlay_closeBtn__1Xm_C {
    font-size: 8px;
    margin: 3px 5px; }
  .ShareOverlay_shareButtonContainer__Y6LXA {
    height: 14px !important; }
  .ShareOverlay_ShareOverlayClose__TUsAh {
    width: 14px;
    height: 14px;
    top: -4px !important;
    right: -4px !important; }
  .ShareOverlay_shareOverlayCloseIcon__3gaRQ {
    width: 5px;
    top: -9px !important;
    right: 0px !important; } }

.ShareOverlay_modalContainer__25EVo:focus {
  outline: none; }

.ShareOverlay_pqStory__1N-2S {
  font-family: Nunito;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #00ADE6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
  margin-bottom: 0.96618vw;
  font-size: 1.93237vw; }
  @media (min-width: 1300px) {
    .ShareOverlay_pqStory__1N-2S {
      font-size: 1.25vw;
      margin-bottom: 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ShareOverlay_pqStory__1N-2S {
      font-size: 1.25vw;
      margin-bottom: 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .ShareOverlay_pqStory__1N-2S {
      font-size: 1.25vw;
      margin-bottom: 0.41667vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .ShareOverlay_pqStory__1N-2S {
      margin-bottom: 0.80515vw;
      font-size: 1.61031vw; }
      .ShareOverlay_pqStory__1N-2S else {
        margin-bottom: 4px; }
      .ShareOverlay_pqStory__1N-2S else {
        font-size: 8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .ShareOverlay_pqStory__1N-2S {
      margin-bottom: 0.64412vw;
      font-size: 1.28824vw; }
      .ShareOverlay_pqStory__1N-2S else {
        margin-bottom: 4px; }
      .ShareOverlay_pqStory__1N-2S else {
        font-size: 8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .ShareOverlay_pqStory__1N-2S {
      margin-bottom: 0.56834vw;
      font-size: 1.13669vw; }
      .ShareOverlay_pqStory__1N-2S else {
        margin-bottom: 4px; }
      .ShareOverlay_pqStory__1N-2S else {
        font-size: 8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .ShareOverlay_pqStory__1N-2S {
      margin-bottom: 0.50852vw;
      font-size: 1.01704vw; }
      .ShareOverlay_pqStory__1N-2S else {
        margin-bottom: 4px; }
      .ShareOverlay_pqStory__1N-2S else {
        font-size: 8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .ShareOverlay_pqStory__1N-2S {
      margin-bottom: 0.46009vw;
      font-size: 0.92017vw; }
      .ShareOverlay_pqStory__1N-2S else {
        margin-bottom: 4px; }
      .ShareOverlay_pqStory__1N-2S else {
        font-size: 8px; } }

.ShareOverlay_shareButtonContainer__Y6LXA {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 100px;
  color: #00ADE6;
  width: 100%;
  height: 3.62319vw;
  border: 0.04831vw solid #00ADE6; }
  @media (min-width: 1300px) {
    .ShareOverlay_shareButtonContainer__Y6LXA {
      width: 16.66667vw;
      height: 3.38542vw;
      border: solid 0.10417vw #00ADE6; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ShareOverlay_shareButtonContainer__Y6LXA {
      width: 16.66667vw;
      height: 3.38542vw;
      border: solid 0.11574vw #00ADE6; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .ShareOverlay_shareButtonContainer__Y6LXA {
      width: 16.66667vw;
      height: 3.38542vw;
      border: solid 0.10417vw #00ADE6; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .ShareOverlay_shareButtonContainer__Y6LXA {
      height: 3.01932vw;
      border: 0.04026vw solid #00ADE6; }
      .ShareOverlay_shareButtonContainer__Y6LXA else {
        width: 100%; }
      .ShareOverlay_shareButtonContainer__Y6LXA else {
        height: 15px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .ShareOverlay_shareButtonContainer__Y6LXA {
      height: 2.41546vw;
      border: 0.03221vw solid #00ADE6; }
      .ShareOverlay_shareButtonContainer__Y6LXA else {
        width: 100%; }
      .ShareOverlay_shareButtonContainer__Y6LXA else {
        height: 15px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .ShareOverlay_shareButtonContainer__Y6LXA {
      height: 2.13129vw;
      border: 0.02842vw solid #00ADE6; }
      .ShareOverlay_shareButtonContainer__Y6LXA else {
        width: 100%; }
      .ShareOverlay_shareButtonContainer__Y6LXA else {
        height: 15px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .ShareOverlay_shareButtonContainer__Y6LXA {
      height: 1.90694vw;
      border: 0.02543vw solid #00ADE6; }
      .ShareOverlay_shareButtonContainer__Y6LXA else {
        width: 100%; }
      .ShareOverlay_shareButtonContainer__Y6LXA else {
        height: 15px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .ShareOverlay_shareButtonContainer__Y6LXA {
      height: 1.72533vw;
      border: 0.023vw solid #00ADE6; }
      .ShareOverlay_shareButtonContainer__Y6LXA else {
        width: 100%; }
      .ShareOverlay_shareButtonContainer__Y6LXA else {
        height: 15px; } }
  .ShareOverlay_shareButtonContainer__Y6LXA:hover {
    color: #ffffff !important;
    background: #00ADE6; }

.ShareOverlay_shareButtonIcon__2YmAp {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-left: 1.04167vw; }
  .ShareOverlay_shareButtonIcon__2YmAp > svg {
    width: 2.41546vw;
    height: 2.41546vw; }
    @media (min-width: 1300px) {
      .ShareOverlay_shareButtonIcon__2YmAp > svg {
        width: 2.08333vw;
        height: 2.08333vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .ShareOverlay_shareButtonIcon__2YmAp > svg {
        width: 2.08333vw;
        height: 2.08333vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .ShareOverlay_shareButtonIcon__2YmAp > svg {
        width: 2.08333vw;
        height: 2.08333vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .ShareOverlay_shareButtonIcon__2YmAp > svg {
        width: 2.01288vw;
        height: 2.01288vw; }
        .ShareOverlay_shareButtonIcon__2YmAp > svg else {
          width: 10px; }
        .ShareOverlay_shareButtonIcon__2YmAp > svg else {
          height: 10px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .ShareOverlay_shareButtonIcon__2YmAp > svg {
        width: 1.61031vw;
        height: 1.61031vw; }
        .ShareOverlay_shareButtonIcon__2YmAp > svg else {
          width: 10px; }
        .ShareOverlay_shareButtonIcon__2YmAp > svg else {
          height: 10px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .ShareOverlay_shareButtonIcon__2YmAp > svg {
        width: 1.42086vw;
        height: 1.42086vw; }
        .ShareOverlay_shareButtonIcon__2YmAp > svg else {
          width: 10px; }
        .ShareOverlay_shareButtonIcon__2YmAp > svg else {
          height: 10px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .ShareOverlay_shareButtonIcon__2YmAp > svg {
        width: 1.27129vw;
        height: 1.27129vw; }
        .ShareOverlay_shareButtonIcon__2YmAp > svg else {
          width: 10px; }
        .ShareOverlay_shareButtonIcon__2YmAp > svg else {
          height: 10px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .ShareOverlay_shareButtonIcon__2YmAp > svg {
        width: 1.15022vw;
        height: 1.15022vw; }
        .ShareOverlay_shareButtonIcon__2YmAp > svg else {
          width: 10px; }
        .ShareOverlay_shareButtonIcon__2YmAp > svg else {
          height: 10px; } }

.ShareOverlay_shareButtonName__QiwMc {
  object-fit: contain;
  font-family: Nunito;
  font-size: 1.14583vw;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-left: 1.04167vw; }

.ShareOverlay_react_share_shareButton__2UodY {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 90% !important;
  height: 3.38542vw !important;
  margin: 0.96618vw 0; }
  @media (min-width: 1300px) {
    .ShareOverlay_react_share_shareButton__2UodY {
      width: 16.66667vw !important;
      height: 3.38542vw !important;
      margin: 0.41667vw 0; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ShareOverlay_react_share_shareButton__2UodY {
      width: 16.66667vw !important;
      height: 3.38542vw !important;
      margin: 0.46296vw 0; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .ShareOverlay_react_share_shareButton__2UodY {
      width: 16.66667vw !important;
      height: 3.38542vw !important;
      margin: 0.41667vw 0; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .ShareOverlay_react_share_shareButton__2UodY {
      width: 90% !important;
      height: 3.38542vw !important;
      margin: 0.80515vw 0; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .ShareOverlay_react_share_shareButton__2UodY {
      width: 90% !important;
      height: 3.38542vw !important;
      margin: 0.64412vw 0; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .ShareOverlay_react_share_shareButton__2UodY {
      width: 90% !important;
      height: 3.38542vw !important;
      margin: 0.56834vw 0; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .ShareOverlay_react_share_shareButton__2UodY {
      width: 90% !important;
      height: 3.38542vw !important;
      margin: 0.50852vw 0; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .ShareOverlay_react_share_shareButton__2UodY {
      width: 90% !important;
      height: 3.38542vw !important;
      margin: 0.46009vw 0; } }

.lato, .lato-light, .code-showcase-header-login, .code-showcase-header-navLinkDesktop, .code-showcase-header-bookFreeSession, .code-showcase-header-mobile-login-btn, .code-showcase-header-overlayNavItem, .lato-regular, .lato-hairline {
  font-family: 'Lato', sans-serif; }

.lato-light, .code-showcase-header-login, .code-showcase-header-navLinkDesktop, .code-showcase-header-bookFreeSession, .code-showcase-header-mobile-login-btn, .code-showcase-header-overlayNavItem {
  font-weight: 300; }

.lato-regular {
  font-weight: 400; }

.lato-hairline {
  font-weight: 100; }

.code-showcase-header-container {
  padding-bottom: 3.38164vw;
  padding-top: 3.38164vw;
  left: 0;
  width: 100vw;
  top: 0;
  z-index: 9999;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08); }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-header-container {
      padding-bottom: 2.81804vw;
      padding-top: 2.81804vw; }
      .code-showcase-header-container else {
        padding-bottom: 14px; }
      .code-showcase-header-container else {
        padding-top: 14px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-header-container {
      padding-bottom: 2.25443vw;
      padding-top: 2.25443vw; }
      .code-showcase-header-container else {
        padding-bottom: 14px; }
      .code-showcase-header-container else {
        padding-top: 14px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-header-container {
      padding-bottom: 1.9892vw;
      padding-top: 1.9892vw; }
      .code-showcase-header-container else {
        padding-bottom: 14px; }
      .code-showcase-header-container else {
        padding-top: 14px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-header-container {
      padding-bottom: 1.77981vw;
      padding-top: 1.77981vw; }
      .code-showcase-header-container else {
        padding-bottom: 14px; }
      .code-showcase-header-container else {
        padding-top: 14px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-header-container {
      padding-bottom: 1.61031vw;
      padding-top: 1.61031vw; }
      .code-showcase-header-container else {
        padding-bottom: 14px; }
      .code-showcase-header-container else {
        padding-top: 14px; } }
  @media (min-width: 1300px) {
    .code-showcase-header-container {
      padding-bottom: 0.52083vw;
      padding-top: 0.72917vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-header-container {
      padding-bottom: 0.5787vw;
      padding-top: 0.81019vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-header-container {
      padding-bottom: 0.52083vw;
      padding-top: 0.72917vw; } }

.code-showcase-header-noPaddingContainer {
  padding-top: 0vw; }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-header-noPaddingContainer {
      padding-top: 0vw; }
      .code-showcase-header-noPaddingContainer else {
        padding-top: 0px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-header-noPaddingContainer {
      padding-top: 0vw; }
      .code-showcase-header-noPaddingContainer else {
        padding-top: 0px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-header-noPaddingContainer {
      padding-top: 0vw; }
      .code-showcase-header-noPaddingContainer else {
        padding-top: 0px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-header-noPaddingContainer {
      padding-top: 0vw; }
      .code-showcase-header-noPaddingContainer else {
        padding-top: 0px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-header-noPaddingContainer {
      padding-top: 0vw; }
      .code-showcase-header-noPaddingContainer else {
        padding-top: 0px; } }
  @media (min-width: 1300px) {
    .code-showcase-header-noPaddingContainer {
      padding-top: 0vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-header-noPaddingContainer {
      padding-top: 0vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-header-noPaddingContainer {
      padding-top: 0vw; } }

.code-showcase-header-bannerSpecialContainer {
  background-image: url(/static/media/usBanner.ddf6ed3d.webp);
  background-size: cover;
  position: relative;
  background-position: top center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 12.07729vw;
  margin-bottom: 2.41546vw;
  background-size: cover; }
  @media (min-width: 1300px) {
    .code-showcase-header-bannerSpecialContainer {
      width: 100%;
      height: 2.60417vw !important;
      margin-bottom: 1.25vw;
      background-size: cover; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-header-bannerSpecialContainer {
      width: 100%;
      height: 2.89352vw !important;
      margin-bottom: 1.38889vw;
      background-size: cover; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-header-bannerSpecialContainer {
      width: 100%;
      height: 2.60417vw !important;
      margin-bottom: 1.25vw;
      background-size: cover; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-header-bannerSpecialContainer {
      height: 10.06441vw;
      margin-bottom: 2.01288vw; }
      .code-showcase-header-bannerSpecialContainer else {
        width: 100%; }
      .code-showcase-header-bannerSpecialContainer else {
        height: 50px; }
      .code-showcase-header-bannerSpecialContainer else {
        margin-bottom: 10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-header-bannerSpecialContainer {
      height: 8.05153vw;
      margin-bottom: 1.61031vw; }
      .code-showcase-header-bannerSpecialContainer else {
        width: 100%; }
      .code-showcase-header-bannerSpecialContainer else {
        height: 50px; }
      .code-showcase-header-bannerSpecialContainer else {
        margin-bottom: 10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-header-bannerSpecialContainer {
      height: 7.10429vw;
      margin-bottom: 1.42086vw; }
      .code-showcase-header-bannerSpecialContainer else {
        width: 100%; }
      .code-showcase-header-bannerSpecialContainer else {
        height: 50px; }
      .code-showcase-header-bannerSpecialContainer else {
        margin-bottom: 10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-header-bannerSpecialContainer {
      height: 6.35647vw;
      margin-bottom: 1.27129vw; }
      .code-showcase-header-bannerSpecialContainer else {
        width: 100%; }
      .code-showcase-header-bannerSpecialContainer else {
        height: 50px; }
      .code-showcase-header-bannerSpecialContainer else {
        margin-bottom: 10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-header-bannerSpecialContainer {
      height: 5.75109vw;
      margin-bottom: 1.15022vw; }
      .code-showcase-header-bannerSpecialContainer else {
        width: 100%; }
      .code-showcase-header-bannerSpecialContainer else {
        height: 50px; }
      .code-showcase-header-bannerSpecialContainer else {
        margin-bottom: 10px; } }

.code-showcase-header-limitedText {
  position: absolute;
  font-family: Lato;
  color: white;
  font-size: 2.89855vw;
  right: 4.83092vw;
  top: 7.24638vw;
  opacity: 0.7; }
  @media (min-width: 1300px) {
    .code-showcase-header-limitedText {
      font-size: 0.88542vw;
      right: 1.92708vw;
      top: 1.30208vw;
      opacity: 1; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-header-limitedText {
      font-size: 0.9838vw;
      right: 2.1412vw;
      top: 1.44676vw;
      opacity: 1; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-header-limitedText {
      font-size: 0.88542vw;
      right: 1.92708vw;
      top: 1.30208vw;
      opacity: 1; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-header-limitedText {
      font-size: 2.41546vw;
      right: 4.02576vw;
      top: 6.03865vw; }
      .code-showcase-header-limitedText else {
        font-size: 12px; }
      .code-showcase-header-limitedText else {
        right: 20px; }
      .code-showcase-header-limitedText else {
        top: 30px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-header-limitedText {
      font-size: 1.93237vw;
      right: 3.22061vw;
      top: 4.83092vw; }
      .code-showcase-header-limitedText else {
        font-size: 12px; }
      .code-showcase-header-limitedText else {
        right: 20px; }
      .code-showcase-header-limitedText else {
        top: 30px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-header-limitedText {
      font-size: 1.70503vw;
      right: 2.84172vw;
      top: 4.26257vw; }
      .code-showcase-header-limitedText else {
        font-size: 12px; }
      .code-showcase-header-limitedText else {
        right: 20px; }
      .code-showcase-header-limitedText else {
        top: 30px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-header-limitedText {
      font-size: 1.52555vw;
      right: 2.54259vw;
      top: 3.81388vw; }
      .code-showcase-header-limitedText else {
        font-size: 12px; }
      .code-showcase-header-limitedText else {
        right: 20px; }
      .code-showcase-header-limitedText else {
        top: 30px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-header-limitedText {
      font-size: 1.38026vw;
      right: 2.30044vw;
      top: 3.45066vw; }
      .code-showcase-header-limitedText else {
        font-size: 12px; }
      .code-showcase-header-limitedText else {
        right: 20px; }
      .code-showcase-header-limitedText else {
        top: 30px; } }

.code-showcase-header-bannerText {
  font-family: Lato;
  color: #fff;
  text-align: center;
  position: relative;
  font-size: 3.86473vw;
  top: -1.20773vw;
  font-weight: 400; }
  @media (min-width: 1300px) {
    .code-showcase-header-bannerText {
      font-size: 1.51042vw;
      top: unset;
      font-weight: 300; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-header-bannerText {
      font-size: 1.67824vw;
      top: unset;
      font-weight: 300; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-header-bannerText {
      font-size: 1.51042vw;
      top: unset;
      font-weight: 300; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-header-bannerText {
      font-size: 3.22061vw;
      top: -1.00644vw; }
      .code-showcase-header-bannerText else {
        font-size: 16px; }
      .code-showcase-header-bannerText else {
        top: -5px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-header-bannerText {
      font-size: 2.57649vw;
      top: -0.80515vw; }
      .code-showcase-header-bannerText else {
        font-size: 16px; }
      .code-showcase-header-bannerText else {
        top: -5px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-header-bannerText {
      font-size: 2.27337vw;
      top: -0.71043vw; }
      .code-showcase-header-bannerText else {
        font-size: 16px; }
      .code-showcase-header-bannerText else {
        top: -5px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-header-bannerText {
      font-size: 2.03407vw;
      top: -0.63565vw; }
      .code-showcase-header-bannerText else {
        font-size: 16px; }
      .code-showcase-header-bannerText else {
        top: -5px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-header-bannerText {
      font-size: 1.84035vw;
      top: -0.57511vw; }
      .code-showcase-header-bannerText else {
        font-size: 16px; }
      .code-showcase-header-bannerText else {
        top: -5px; } }

.code-showcase-header-gradientText {
  background-image: linear-gradient(180deg, #C6F8FF, #03BEE7);
  background-size: 100%;
  font-weight: 900;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; }

.code-showcase-header-lottieAbsoluteContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3.62319vw; }
  @media (min-width: 1300px) {
    .code-showcase-header-lottieAbsoluteContainer {
      width: 100%;
      height: 2.08333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-header-lottieAbsoluteContainer {
      width: 100%;
      height: 2.31481vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-header-lottieAbsoluteContainer {
      width: 100%;
      height: 2.08333vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-header-lottieAbsoluteContainer {
      height: 3.01932vw; }
      .code-showcase-header-lottieAbsoluteContainer else {
        width: 100%; }
      .code-showcase-header-lottieAbsoluteContainer else {
        height: 15px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-header-lottieAbsoluteContainer {
      height: 2.41546vw; }
      .code-showcase-header-lottieAbsoluteContainer else {
        width: 100%; }
      .code-showcase-header-lottieAbsoluteContainer else {
        height: 15px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-header-lottieAbsoluteContainer {
      height: 2.13129vw; }
      .code-showcase-header-lottieAbsoluteContainer else {
        width: 100%; }
      .code-showcase-header-lottieAbsoluteContainer else {
        height: 15px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-header-lottieAbsoluteContainer {
      height: 1.90694vw; }
      .code-showcase-header-lottieAbsoluteContainer else {
        width: 100%; }
      .code-showcase-header-lottieAbsoluteContainer else {
        height: 15px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-header-lottieAbsoluteContainer {
      height: 1.72533vw; }
      .code-showcase-header-lottieAbsoluteContainer else {
        width: 100%; }
      .code-showcase-header-lottieAbsoluteContainer else {
        height: 15px; } }

.code-showcase-header-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0vw 4.83092vw;
  box-sizing: border-box;
  align-items: center; }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-header-row {
      padding: 0vw 4.02576vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-header-row {
      padding: 0vw 3.22061vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-header-row {
      padding: 0vw 2.84172vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-header-row {
      padding: 0vw 2.54259vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-header-row {
      padding: 0vw 2.30044vw; } }
  @media (min-width: 1300px) {
    .code-showcase-header-row {
      padding: 0vw 2.34375vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-header-row {
      padding: 0vw 2.60417vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-header-row {
      padding: 0vw 2.34375vw; } }

.code-showcase-header-tekieLogo {
  width: 21.25604vw;
  height: 11.5942vw;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center; }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-header-tekieLogo {
      width: 17.71337vw;
      height: 9.66184vw; }
      .code-showcase-header-tekieLogo else {
        width: 88px; }
      .code-showcase-header-tekieLogo else {
        height: 48px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-header-tekieLogo {
      width: 14.17069vw;
      height: 7.72947vw; }
      .code-showcase-header-tekieLogo else {
        width: 88px; }
      .code-showcase-header-tekieLogo else {
        height: 48px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-header-tekieLogo {
      width: 12.50355vw;
      height: 6.82012vw; }
      .code-showcase-header-tekieLogo else {
        width: 88px; }
      .code-showcase-header-tekieLogo else {
        height: 48px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-header-tekieLogo {
      width: 11.18739vw;
      height: 6.10221vw; }
      .code-showcase-header-tekieLogo else {
        width: 88px; }
      .code-showcase-header-tekieLogo else {
        height: 48px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-header-tekieLogo {
      width: 10.12192vw;
      height: 5.52105vw; }
      .code-showcase-header-tekieLogo else {
        width: 88px; }
      .code-showcase-header-tekieLogo else {
        height: 48px; } }
  @media (min-width: 1300px) {
    .code-showcase-header-tekieLogo {
      width: 5.625vw;
      height: 1.97917vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-header-tekieLogo {
      width: 6.25vw;
      height: 2.19907vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-header-tekieLogo {
      width: 5.625vw;
      height: 1.97917vw; } }

.code-showcase-header-login {
  display: flex;
  padding: 2.41546vw;
  letter-spacing: 0.18116vw;
  font-size: 3.86473vw;
  display: none;
  border-radius: 100px;
  background-color: transparent;
  justify-content: center;
  text-transform: unset;
  align-items: center;
  text-align: left;
  font-weight: 500;
  border: 1.5px solid #00ADE5;
  color: #00ADE5;
  opacity: 0.8;
  cursor: pointer;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-header-login {
      padding: 2.01288vw;
      letter-spacing: 0.15097vw;
      font-size: 3.22061vw; }
      .code-showcase-header-login else {
        padding: 10px; }
      .code-showcase-header-login else {
        letter-spacing: 0.75px; }
      .code-showcase-header-login else {
        font-size: 16px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-header-login {
      padding: 1.61031vw;
      letter-spacing: 0.12077vw;
      font-size: 2.57649vw; }
      .code-showcase-header-login else {
        padding: 10px; }
      .code-showcase-header-login else {
        letter-spacing: 0.75px; }
      .code-showcase-header-login else {
        font-size: 16px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-header-login {
      padding: 1.42086vw;
      letter-spacing: 0.10656vw;
      font-size: 2.27337vw; }
      .code-showcase-header-login else {
        padding: 10px; }
      .code-showcase-header-login else {
        letter-spacing: 0.75px; }
      .code-showcase-header-login else {
        font-size: 16px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-header-login {
      padding: 1.27129vw;
      letter-spacing: 0.09535vw;
      font-size: 2.03407vw; }
      .code-showcase-header-login else {
        padding: 10px; }
      .code-showcase-header-login else {
        letter-spacing: 0.75px; }
      .code-showcase-header-login else {
        font-size: 16px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-header-login {
      padding: 1.15022vw;
      letter-spacing: 0.08627vw;
      font-size: 1.84035vw; }
      .code-showcase-header-login else {
        padding: 10px; }
      .code-showcase-header-login else {
        letter-spacing: 0.75px; }
      .code-showcase-header-login else {
        font-size: 16px; } }
  @media (min-width: 1300px) {
    .code-showcase-header-login {
      padding: 0.72917vw 1.45833vw;
      font-size: 1.25vw;
      letter-spacing: 0.02604vw;
      display: flex; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-header-login {
      padding: 0.81019vw 1.62037vw;
      font-size: 1.38889vw;
      letter-spacing: 0.02894vw;
      display: flex; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-header-login {
      padding: 0.72917vw 1.45833vw;
      font-size: 1.25vw;
      letter-spacing: 0.02604vw;
      display: flex; } }
  .code-showcase-header-login:hover {
    opacity: 1;
    box-shadow: 0px 5px 8px 0 rgba(0, 0, 0, 0.16); }

.code-showcase-header-hamburgerContainer {
  width: 6.42512vw;
  height: 4.29952vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-header-hamburgerContainer {
      width: 5.35427vw;
      height: 3.58293vw; }
      .code-showcase-header-hamburgerContainer else {
        width: 26.6px; }
      .code-showcase-header-hamburgerContainer else {
        height: 17.8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-header-hamburgerContainer {
      width: 4.28341vw;
      height: 2.86634vw; }
      .code-showcase-header-hamburgerContainer else {
        width: 26.6px; }
      .code-showcase-header-hamburgerContainer else {
        height: 17.8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-header-hamburgerContainer {
      width: 3.77948vw;
      height: 2.52913vw; }
      .code-showcase-header-hamburgerContainer else {
        width: 26.6px; }
      .code-showcase-header-hamburgerContainer else {
        height: 17.8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-header-hamburgerContainer {
      width: 3.38164vw;
      height: 2.2629vw; }
      .code-showcase-header-hamburgerContainer else {
        width: 26.6px; }
      .code-showcase-header-hamburgerContainer else {
        height: 17.8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-header-hamburgerContainer {
      width: 3.05958vw;
      height: 2.04739vw; }
      .code-showcase-header-hamburgerContainer else {
        width: 26.6px; }
      .code-showcase-header-hamburgerContainer else {
        height: 17.8px; } }
  .code-showcase-header-hamburgerContainer > div {
    width: 100%;
    background-color: #21d0e9;
    min-height: 0.77295vw; }
    @media (min-width: 450px) and (max-width: 500px) {
      .code-showcase-header-hamburgerContainer > div {
        min-height: 0.64412vw; }
        .code-showcase-header-hamburgerContainer > div else {
          min-height: 3.2px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .code-showcase-header-hamburgerContainer > div {
        min-height: 0.5153vw; }
        .code-showcase-header-hamburgerContainer > div else {
          min-height: 3.2px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .code-showcase-header-hamburgerContainer > div {
        min-height: 0.45467vw; }
        .code-showcase-header-hamburgerContainer > div else {
          min-height: 3.2px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .code-showcase-header-hamburgerContainer > div {
        min-height: 0.40681vw; }
        .code-showcase-header-hamburgerContainer > div else {
          min-height: 3.2px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .code-showcase-header-hamburgerContainer > div {
        min-height: 0.36807vw; }
        .code-showcase-header-hamburgerContainer > div else {
          min-height: 3.2px; } }
  @media (min-width: 1300px) {
    .code-showcase-header-hamburgerContainer {
      display: none; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-header-hamburgerContainer {
      display: none; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-header-hamburgerContainer {
      display: none; } }

.code-showcase-header-navLinkDesktop {
  font-weight: 400;
  opacity: 0.8;
  color: #fff;
  cursor: pointer;
  display: none; }
  .code-showcase-header-navLinkDesktop:hover {
    opacity: 1; }
  @media (min-width: 1300px) {
    .code-showcase-header-navLinkDesktop {
      font-size: 1.25vw;
      letter-spacing: 0.02604vw;
      margin-right: 1.77083vw;
      padding: 0.67708vw 0vw;
      display: block; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-header-navLinkDesktop {
      font-size: 1.38889vw;
      letter-spacing: 0.02894vw;
      margin-right: 1.96759vw;
      padding: 0.75231vw 0vw;
      display: block; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-header-navLinkDesktop {
      font-size: 1.25vw;
      letter-spacing: 0.02604vw;
      margin-right: 1.77083vw;
      padding: 0.67708vw 0vw;
      display: block; } }

.code-showcase-header-bookFreeSession {
  display: flex;
  align-items: center;
  font-weight: 600;
  opacity: 0.9;
  padding: 3.38164vw;
  letter-spacing: 0.07246vw;
  font-size: 3.86473vw;
  margin-right: 0vw;
  justify-content: center;
  border-radius: 100px;
  background: linear-gradient(#35e4e9 0%, #00ade6 100%);
  text-align: left;
  color: white;
  cursor: pointer;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-header-bookFreeSession {
      padding: 2.81804vw;
      letter-spacing: 0.06039vw;
      font-size: 3.22061vw;
      margin-right: 0vw; }
      .code-showcase-header-bookFreeSession else {
        padding: 14px; }
      .code-showcase-header-bookFreeSession else {
        letter-spacing: 0.3px; }
      .code-showcase-header-bookFreeSession else {
        font-size: 16px; }
      .code-showcase-header-bookFreeSession else {
        margin-right: 0px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-header-bookFreeSession {
      padding: 2.25443vw;
      letter-spacing: 0.04831vw;
      font-size: 2.57649vw;
      margin-right: 0vw; }
      .code-showcase-header-bookFreeSession else {
        padding: 14px; }
      .code-showcase-header-bookFreeSession else {
        letter-spacing: 0.3px; }
      .code-showcase-header-bookFreeSession else {
        font-size: 16px; }
      .code-showcase-header-bookFreeSession else {
        margin-right: 0px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-header-bookFreeSession {
      padding: 1.9892vw;
      letter-spacing: 0.04263vw;
      font-size: 2.27337vw;
      margin-right: 0vw; }
      .code-showcase-header-bookFreeSession else {
        padding: 14px; }
      .code-showcase-header-bookFreeSession else {
        letter-spacing: 0.3px; }
      .code-showcase-header-bookFreeSession else {
        font-size: 16px; }
      .code-showcase-header-bookFreeSession else {
        margin-right: 0px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-header-bookFreeSession {
      padding: 1.77981vw;
      letter-spacing: 0.03814vw;
      font-size: 2.03407vw;
      margin-right: 0vw; }
      .code-showcase-header-bookFreeSession else {
        padding: 14px; }
      .code-showcase-header-bookFreeSession else {
        letter-spacing: 0.3px; }
      .code-showcase-header-bookFreeSession else {
        font-size: 16px; }
      .code-showcase-header-bookFreeSession else {
        margin-right: 0px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-header-bookFreeSession {
      padding: 1.61031vw;
      letter-spacing: 0.03451vw;
      font-size: 1.84035vw;
      margin-right: 0vw; }
      .code-showcase-header-bookFreeSession else {
        padding: 14px; }
      .code-showcase-header-bookFreeSession else {
        letter-spacing: 0.3px; }
      .code-showcase-header-bookFreeSession else {
        font-size: 16px; }
      .code-showcase-header-bookFreeSession else {
        margin-right: 0px; } }
  @media (min-width: 1300px) {
    .code-showcase-header-bookFreeSession {
      padding: 0.83333vw 1.25vw;
      font-size: 1.25vw;
      letter-spacing: 0.02604vw;
      margin-right: 1.45833vw;
      justify-content: space-between;
      position: relative; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-header-bookFreeSession {
      padding: 0.92593vw 1.38889vw;
      font-size: 1.38889vw;
      letter-spacing: 0.02894vw;
      margin-right: 1.62037vw;
      justify-content: space-between;
      position: relative; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-header-bookFreeSession {
      padding: 0.83333vw 1.25vw;
      font-size: 1.25vw;
      letter-spacing: 0.02604vw;
      margin-right: 1.45833vw;
      justify-content: space-between;
      position: relative; } }
  .code-showcase-header-bookFreeSession:hover {
    opacity: 1;
    box-shadow: 0px 5px 8px 0 rgba(0, 0, 0, 0.16); }
  .code-showcase-header-bookFreeSession::before {
    content: 'Get in Touch'; }

.code-showcase-header-mobile-login-btn {
  font-weight: 600;
  opacity: 0.9;
  padding: 2.41546vw 2.89855vw;
  border-radius: 0.48309vw;
  letter-spacing: 0vw;
  font-size: 3.86473vw;
  margin-right: 0vw;
  background-color: #022f3f;
  justify-content: center;
  align-items: center;
  text-align: left;
  color: #2abac1;
  cursor: pointer;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-header-mobile-login-btn {
      padding: 2.01288vw 2.41546vw;
      border-radius: 0.40258vw;
      letter-spacing: 0vw;
      font-size: 3.22061vw;
      margin-right: 0vw; }
      .code-showcase-header-mobile-login-btn else {
        border-radius: 2px; }
      .code-showcase-header-mobile-login-btn else {
        letter-spacing: 0px; }
      .code-showcase-header-mobile-login-btn else {
        font-size: 16px; }
      .code-showcase-header-mobile-login-btn else {
        margin-right: 0px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-header-mobile-login-btn {
      padding: 1.61031vw 1.93237vw;
      border-radius: 0.32206vw;
      letter-spacing: 0vw;
      font-size: 2.57649vw;
      margin-right: 0vw; }
      .code-showcase-header-mobile-login-btn else {
        border-radius: 2px; }
      .code-showcase-header-mobile-login-btn else {
        letter-spacing: 0px; }
      .code-showcase-header-mobile-login-btn else {
        font-size: 16px; }
      .code-showcase-header-mobile-login-btn else {
        margin-right: 0px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-header-mobile-login-btn {
      padding: 1.42086vw 1.70503vw;
      border-radius: 0.28417vw;
      letter-spacing: 0vw;
      font-size: 2.27337vw;
      margin-right: 0vw; }
      .code-showcase-header-mobile-login-btn else {
        border-radius: 2px; }
      .code-showcase-header-mobile-login-btn else {
        letter-spacing: 0px; }
      .code-showcase-header-mobile-login-btn else {
        font-size: 16px; }
      .code-showcase-header-mobile-login-btn else {
        margin-right: 0px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-header-mobile-login-btn {
      padding: 1.27129vw 1.52555vw;
      border-radius: 0.25426vw;
      letter-spacing: 0vw;
      font-size: 2.03407vw;
      margin-right: 0vw; }
      .code-showcase-header-mobile-login-btn else {
        border-radius: 2px; }
      .code-showcase-header-mobile-login-btn else {
        letter-spacing: 0px; }
      .code-showcase-header-mobile-login-btn else {
        font-size: 16px; }
      .code-showcase-header-mobile-login-btn else {
        margin-right: 0px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-header-mobile-login-btn {
      padding: 1.15022vw 1.38026vw;
      border-radius: 0.23004vw;
      letter-spacing: 0vw;
      font-size: 1.84035vw;
      margin-right: 0vw; }
      .code-showcase-header-mobile-login-btn else {
        border-radius: 2px; }
      .code-showcase-header-mobile-login-btn else {
        letter-spacing: 0px; }
      .code-showcase-header-mobile-login-btn else {
        font-size: 16px; }
      .code-showcase-header-mobile-login-btn else {
        margin-right: 0px; } }
  @media (min-width: 1300px) {
    .code-showcase-header-mobile-login-btn {
      padding: 0.67708vw 0.9375vw;
      border-radius: 0.26042vw;
      font-size: 1.25vw;
      letter-spacing: 0.02604vw;
      margin-right: 3.54167vw;
      padding-top: 0.78125vw;
      position: relative; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-header-mobile-login-btn {
      padding: 0.75231vw 1.04167vw;
      border-radius: 0.28935vw;
      font-size: 1.38889vw;
      letter-spacing: 0.02894vw;
      margin-right: 3.93519vw;
      padding-top: 0.86806vw;
      position: relative; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-header-mobile-login-btn {
      padding: 0.67708vw 0.9375vw;
      border-radius: 0.26042vw;
      font-size: 1.25vw;
      letter-spacing: 0.02604vw;
      margin-right: 3.54167vw;
      padding-top: 0.78125vw;
      position: relative; } }
  .code-showcase-header-mobile-login-btn:hover {
    opacity: 1;
    box-shadow: 2px 10px 15px 0 rgba(0, 0, 0, 0.16); }

.code-showcase-calender-icon {
  display: block;
  margin-left: 8px;
  background-image: url(/static/media/calendarOutline.ed436b31.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 4.34783vw;
  height: 4.34783vw; }
  @media (min-width: 1300px) {
    .code-showcase-calender-icon {
      width: 1.35417vw;
      height: 1.35417vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-calender-icon {
      width: 1.50463vw;
      height: 1.50463vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-calender-icon {
      width: 1.35417vw;
      height: 1.35417vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-calender-icon {
      width: 3.62319vw;
      height: 3.62319vw; }
      .code-showcase-calender-icon else {
        width: 18px; }
      .code-showcase-calender-icon else {
        height: 18px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-calender-icon {
      width: 2.89855vw;
      height: 2.89855vw; }
      .code-showcase-calender-icon else {
        width: 18px; }
      .code-showcase-calender-icon else {
        height: 18px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-calender-icon {
      width: 2.55754vw;
      height: 2.55754vw; }
      .code-showcase-calender-icon else {
        width: 18px; }
      .code-showcase-calender-icon else {
        height: 18px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-calender-icon {
      width: 2.28833vw;
      height: 2.28833vw; }
      .code-showcase-calender-icon else {
        width: 18px; }
      .code-showcase-calender-icon else {
        height: 18px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-calender-icon {
      width: 2.07039vw;
      height: 2.07039vw; }
      .code-showcase-calender-icon else {
        width: 18px; }
      .code-showcase-calender-icon else {
        height: 18px; } }

.code-showcase-header-buyNowButton {
  margin-left: 0; }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-header-buyNowButton else {
      margin-left: 0; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-header-buyNowButton else {
      margin-left: 0; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-header-buyNowButton else {
      margin-left: 0; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-header-buyNowButton else {
      margin-left: 0; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-header-buyNowButton else {
      margin-left: 0; } }
  @media (min-width: 1300px) {
    .code-showcase-header-buyNowButton {
      margin-left: 3.54167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-header-buyNowButton {
      margin-left: 3.93519vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-header-buyNowButton {
      margin-left: 3.54167vw; } }

.code-showcase-header-headerButton {
  font-weight: 600;
  opacity: 0.8;
  transition: all .2s;
  padding: 2.41546vw;
  border-radius: 0.48309vw;
  letter-spacing: 0vw;
  font-size: 3.86473vw;
  margin-right: 3.38164vw;
  display: none;
  font-weight: bold;
  justify-content: center;
  color: #C4C4C4;
  align-items: center;
  text-align: left;
  cursor: pointer; }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-header-headerButton {
      padding: 2.01288vw;
      border-radius: 0.40258vw;
      letter-spacing: 0vw;
      font-size: 3.22061vw;
      margin-right: 2.81804vw; }
      .code-showcase-header-headerButton else {
        padding: 10px; }
      .code-showcase-header-headerButton else {
        border-radius: 2px; }
      .code-showcase-header-headerButton else {
        letter-spacing: 0px; }
      .code-showcase-header-headerButton else {
        font-size: 16px; }
      .code-showcase-header-headerButton else {
        margin-right: 14px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-header-headerButton {
      padding: 1.61031vw;
      border-radius: 0.32206vw;
      letter-spacing: 0vw;
      font-size: 2.57649vw;
      margin-right: 2.25443vw; }
      .code-showcase-header-headerButton else {
        padding: 10px; }
      .code-showcase-header-headerButton else {
        border-radius: 2px; }
      .code-showcase-header-headerButton else {
        letter-spacing: 0px; }
      .code-showcase-header-headerButton else {
        font-size: 16px; }
      .code-showcase-header-headerButton else {
        margin-right: 14px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-header-headerButton {
      padding: 1.42086vw;
      border-radius: 0.28417vw;
      letter-spacing: 0vw;
      font-size: 2.27337vw;
      margin-right: 1.9892vw; }
      .code-showcase-header-headerButton else {
        padding: 10px; }
      .code-showcase-header-headerButton else {
        border-radius: 2px; }
      .code-showcase-header-headerButton else {
        letter-spacing: 0px; }
      .code-showcase-header-headerButton else {
        font-size: 16px; }
      .code-showcase-header-headerButton else {
        margin-right: 14px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-header-headerButton {
      padding: 1.27129vw;
      border-radius: 0.25426vw;
      letter-spacing: 0vw;
      font-size: 2.03407vw;
      margin-right: 1.77981vw; }
      .code-showcase-header-headerButton else {
        padding: 10px; }
      .code-showcase-header-headerButton else {
        border-radius: 2px; }
      .code-showcase-header-headerButton else {
        letter-spacing: 0px; }
      .code-showcase-header-headerButton else {
        font-size: 16px; }
      .code-showcase-header-headerButton else {
        margin-right: 14px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-header-headerButton {
      padding: 1.15022vw;
      border-radius: 0.23004vw;
      letter-spacing: 0vw;
      font-size: 1.84035vw;
      margin-right: 1.61031vw; }
      .code-showcase-header-headerButton else {
        padding: 10px; }
      .code-showcase-header-headerButton else {
        border-radius: 2px; }
      .code-showcase-header-headerButton else {
        letter-spacing: 0px; }
      .code-showcase-header-headerButton else {
        font-size: 16px; }
      .code-showcase-header-headerButton else {
        margin-right: 14px; } }
  @media (min-width: 1300px) {
    .code-showcase-header-headerButton {
      padding: 0.67708vw 0.9375vw;
      border-radius: 0.26042vw;
      font-size: 1.30208vw;
      letter-spacing: 0.02604vw;
      margin-right: 3.54167vw;
      margin-top: 0.26042vw;
      display: flex; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-header-headerButton {
      padding: 0.75231vw 1.04167vw;
      border-radius: 0.28935vw;
      font-size: 1.44676vw;
      letter-spacing: 0.02894vw;
      margin-right: 3.93519vw;
      margin-top: 0.28935vw;
      display: flex; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-header-headerButton {
      padding: 0.67708vw 0.9375vw;
      border-radius: 0.26042vw;
      font-size: 1.30208vw;
      letter-spacing: 0.02604vw;
      margin-right: 3.54167vw;
      margin-top: 0.26042vw;
      display: flex; } }
  .code-showcase-header-headerButton:hover, .code-showcase-header-headerButton .code-showcase-header-active {
    color: #00ADE5; }

.code-showcase-header-active {
  color: #00ADE5; }

.code-showcase-header-text {
  font-style: normal;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  font-family: Nunito;
  font-weight: bold;
  line-height: 41px;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  color: #00ADE5;
  align-items: center;
  opacity: 0.8;
  font-size: 3.86473vw;
  display: none; }
  @media (min-width: 1300px) {
    .code-showcase-header-text {
      font-size: 1.45833vw;
      display: flex; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-header-text {
      font-size: 1.45833vw;
      display: flex; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-header-text {
      font-size: 1.45833vw;
      display: flex; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-header-text {
      font-size: 3.22061vw; }
      .code-showcase-header-text else {
        font-size: 16px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-header-text {
      font-size: 2.57649vw; }
      .code-showcase-header-text else {
        font-size: 16px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-header-text {
      font-size: 2.27337vw; }
      .code-showcase-header-text else {
        font-size: 16px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-header-text {
      font-size: 2.03407vw; }
      .code-showcase-header-text else {
        font-size: 16px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-header-text {
      font-size: 1.84035vw; }
      .code-showcase-header-text else {
        font-size: 16px; } }

.code-showcase-header-overlayNavItem {
  padding: 6.76329vw 4.83092vw;
  min-width: 100%;
  letter-spacing: 0.18116vw;
  font-size: 4.34783vw;
  flex: 1 1;
  cursor: pointer;
  box-sizing: border-box;
  border-bottom: solid 1px #2abac1;
  color: #ffffff; }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-header-overlayNavItem {
      padding: 5.63607vw 4.02576vw;
      letter-spacing: 0.15097vw;
      font-size: 3.62319vw; }
      .code-showcase-header-overlayNavItem else {
        min-width: 100%; }
      .code-showcase-header-overlayNavItem else {
        letter-spacing: 0.75px; }
      .code-showcase-header-overlayNavItem else {
        font-size: 18px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-header-overlayNavItem {
      padding: 4.50886vw 3.22061vw;
      letter-spacing: 0.12077vw;
      font-size: 2.89855vw; }
      .code-showcase-header-overlayNavItem else {
        min-width: 100%; }
      .code-showcase-header-overlayNavItem else {
        letter-spacing: 0.75px; }
      .code-showcase-header-overlayNavItem else {
        font-size: 18px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-header-overlayNavItem {
      padding: 3.9784vw 2.84172vw;
      letter-spacing: 0.10656vw;
      font-size: 2.55754vw; }
      .code-showcase-header-overlayNavItem else {
        min-width: 100%; }
      .code-showcase-header-overlayNavItem else {
        letter-spacing: 0.75px; }
      .code-showcase-header-overlayNavItem else {
        font-size: 18px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-header-overlayNavItem {
      padding: 3.55962vw 2.54259vw;
      letter-spacing: 0.09535vw;
      font-size: 2.28833vw; }
      .code-showcase-header-overlayNavItem else {
        min-width: 100%; }
      .code-showcase-header-overlayNavItem else {
        letter-spacing: 0.75px; }
      .code-showcase-header-overlayNavItem else {
        font-size: 18px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-header-overlayNavItem {
      padding: 3.22061vw 2.30044vw;
      letter-spacing: 0.08627vw;
      font-size: 2.07039vw; }
      .code-showcase-header-overlayNavItem else {
        min-width: 100%; }
      .code-showcase-header-overlayNavItem else {
        letter-spacing: 0.75px; }
      .code-showcase-header-overlayNavItem else {
        font-size: 18px; } }

.code-showcase-header-bookFreeSessionMobile {
  font-size: 4.34783vw;
  margin-right: 4.83092vw;
  margin-left: 4.83092vw;
  letter-spacing: 0.16908vw;
  margin-top: 3.86473vw;
  margin-bottom: 0vw;
  font-weight: 600; }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-header-bookFreeSessionMobile {
      font-size: 3.62319vw;
      margin-right: 4.02576vw;
      margin-left: 4.02576vw;
      letter-spacing: 0.1409vw;
      margin-top: 3.22061vw;
      margin-bottom: 0vw; }
      .code-showcase-header-bookFreeSessionMobile else {
        font-size: 18px; }
      .code-showcase-header-bookFreeSessionMobile else {
        margin-right: 20px; }
      .code-showcase-header-bookFreeSessionMobile else {
        margin-left: 20px; }
      .code-showcase-header-bookFreeSessionMobile else {
        letter-spacing: 0.7px; }
      .code-showcase-header-bookFreeSessionMobile else {
        margin-top: 16px; }
      .code-showcase-header-bookFreeSessionMobile else {
        margin-bottom: 0px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-header-bookFreeSessionMobile {
      font-size: 2.89855vw;
      margin-right: 3.22061vw;
      margin-left: 3.22061vw;
      letter-spacing: 0.11272vw;
      margin-top: 2.57649vw;
      margin-bottom: 0vw; }
      .code-showcase-header-bookFreeSessionMobile else {
        font-size: 18px; }
      .code-showcase-header-bookFreeSessionMobile else {
        margin-right: 20px; }
      .code-showcase-header-bookFreeSessionMobile else {
        margin-left: 20px; }
      .code-showcase-header-bookFreeSessionMobile else {
        letter-spacing: 0.7px; }
      .code-showcase-header-bookFreeSessionMobile else {
        margin-top: 16px; }
      .code-showcase-header-bookFreeSessionMobile else {
        margin-bottom: 0px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-header-bookFreeSessionMobile {
      font-size: 2.55754vw;
      margin-right: 2.84172vw;
      margin-left: 2.84172vw;
      letter-spacing: 0.09946vw;
      margin-top: 2.27337vw;
      margin-bottom: 0vw; }
      .code-showcase-header-bookFreeSessionMobile else {
        font-size: 18px; }
      .code-showcase-header-bookFreeSessionMobile else {
        margin-right: 20px; }
      .code-showcase-header-bookFreeSessionMobile else {
        margin-left: 20px; }
      .code-showcase-header-bookFreeSessionMobile else {
        letter-spacing: 0.7px; }
      .code-showcase-header-bookFreeSessionMobile else {
        margin-top: 16px; }
      .code-showcase-header-bookFreeSessionMobile else {
        margin-bottom: 0px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-header-bookFreeSessionMobile {
      font-size: 2.28833vw;
      margin-right: 2.54259vw;
      margin-left: 2.54259vw;
      letter-spacing: 0.08899vw;
      margin-top: 2.03407vw;
      margin-bottom: 0vw; }
      .code-showcase-header-bookFreeSessionMobile else {
        font-size: 18px; }
      .code-showcase-header-bookFreeSessionMobile else {
        margin-right: 20px; }
      .code-showcase-header-bookFreeSessionMobile else {
        margin-left: 20px; }
      .code-showcase-header-bookFreeSessionMobile else {
        letter-spacing: 0.7px; }
      .code-showcase-header-bookFreeSessionMobile else {
        margin-top: 16px; }
      .code-showcase-header-bookFreeSessionMobile else {
        margin-bottom: 0px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-header-bookFreeSessionMobile {
      font-size: 2.07039vw;
      margin-right: 2.30044vw;
      margin-left: 2.30044vw;
      letter-spacing: 0.08052vw;
      margin-top: 1.84035vw;
      margin-bottom: 0vw; }
      .code-showcase-header-bookFreeSessionMobile else {
        font-size: 18px; }
      .code-showcase-header-bookFreeSessionMobile else {
        margin-right: 20px; }
      .code-showcase-header-bookFreeSessionMobile else {
        margin-left: 20px; }
      .code-showcase-header-bookFreeSessionMobile else {
        letter-spacing: 0.7px; }
      .code-showcase-header-bookFreeSessionMobile else {
        margin-top: 16px; }
      .code-showcase-header-bookFreeSessionMobile else {
        margin-bottom: 0px; } }

.code-showcase-header-contentNone {
  padding: 2.41546vw 4.83092vw;
  height: 7.24638vw; }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-header-contentNone {
      padding: 2.01288vw 4.02576vw;
      height: 6.03865vw; }
      .code-showcase-header-contentNone else {
        height: 30px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-header-contentNone {
      padding: 1.61031vw 3.22061vw;
      height: 4.83092vw; }
      .code-showcase-header-contentNone else {
        height: 30px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-header-contentNone {
      padding: 1.42086vw 2.84172vw;
      height: 4.26257vw; }
      .code-showcase-header-contentNone else {
        height: 30px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-header-contentNone {
      padding: 1.27129vw 2.54259vw;
      height: 3.81388vw; }
      .code-showcase-header-contentNone else {
        height: 30px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-header-contentNone {
      padding: 1.15022vw 2.30044vw;
      height: 3.45066vw; }
      .code-showcase-header-contentNone else {
        height: 30px; } }
  .code-showcase-header-contentNone:before {
    content: '' !important; }

.code-showcase-header-overlayNavbar {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: row; }
  @media (min-width: 1300px) {
    .code-showcase-header-overlayNavbar {
      display: none; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-header-overlayNavbar {
      display: none; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-header-overlayNavbar {
      display: none; } }

.code-showcase-header-overlayNavbarHide {
  left: -100vw;
  background-color: transparent; }

.code-showcase-header-overlayNavbarWrapper {
  height: 100%;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(179deg, #00171f 55%, #002835 100%);
  width: 56.03865vw;
  padding-top: 4.83092vw; }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-header-overlayNavbarWrapper {
      width: 46.69887vw;
      padding-top: 4.02576vw; }
      .code-showcase-header-overlayNavbarWrapper else {
        width: 232px; }
      .code-showcase-header-overlayNavbarWrapper else {
        padding-top: 20px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-header-overlayNavbarWrapper {
      width: 37.3591vw;
      padding-top: 3.22061vw; }
      .code-showcase-header-overlayNavbarWrapper else {
        width: 232px; }
      .code-showcase-header-overlayNavbarWrapper else {
        padding-top: 20px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-header-overlayNavbarWrapper {
      width: 32.96391vw;
      padding-top: 2.84172vw; }
      .code-showcase-header-overlayNavbarWrapper else {
        width: 232px; }
      .code-showcase-header-overlayNavbarWrapper else {
        padding-top: 20px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-header-overlayNavbarWrapper {
      width: 29.49402vw;
      padding-top: 2.54259vw; }
      .code-showcase-header-overlayNavbarWrapper else {
        width: 232px; }
      .code-showcase-header-overlayNavbarWrapper else {
        padding-top: 20px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-header-overlayNavbarWrapper {
      width: 26.68507vw;
      padding-top: 2.30044vw; }
      .code-showcase-header-overlayNavbarWrapper else {
        width: 232px; }
      .code-showcase-header-overlayNavbarWrapper else {
        padding-top: 20px; } }

.code-showcase-header-overlayNavbarWrapperHide {
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0); }

.code-showcase-header-overlayNavbarClose {
  height: 100%;
  flex: 1 1; }

.code-showcase-header-profile-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 0px 0px; }
  .code-showcase-header-profile-container .code-showcase-header-profile-img {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top center !important;
    background-color: #ffffff;
    border: 3px solid #47A9B0; }
  .code-showcase-header-profile-container .code-showcase-header-profile-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #ffffff;
    padding-top: 8px; }

/* dropdown menu */
.code-showcase-header-menu {
  position: relative;
  cursor: pointer; }

.code-showcase-header-drop {
  box-shadow: 0 4px 5px #ccc;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  position: absolute;
  box-sizing: border-box;
  color: black;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: rotateX(90deg);
          transform: rotateX(90deg);
  transition: all 0.5s ease-in-out;
  background: #FFFFFF;
  border-radius: 4px; }
  @media (min-width: 1300px) {
    .code-showcase-header-drop {
      top: 4.42708vw;
      width: 13.125vw;
      right: -0.72917vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-header-drop {
      top: 4.91898vw;
      width: 14.58333vw;
      right: -0.81019vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-header-drop {
      top: 4.42708vw;
      width: 13.125vw;
      right: -0.72917vw; } }

.code-showcase-header-drop a {
  transition: 0.2s all ease-in-out;
  text-decoration: none;
  position: relative;
  display: block;
  color: inherit; }

.code-showcase-header-drop::before {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border: 20px solid transparent;
  border-bottom: 26px solid #E6F7FD;
  left: 25px;
  top: -45px;
  z-index: 1; }

.code-showcase-header-drop span {
  font-size: 3.86473vw;
  display: none;
  box-sizing: border-box;
  display: block;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  color: #504F4F;
  transition: all 0.5s ease-in-out; }
  @media (min-width: 450px) and (max-width: 500px) {
    .code-showcase-header-drop span {
      font-size: 3.22061vw; }
      .code-showcase-header-drop span else {
        font-size: 16px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .code-showcase-header-drop span {
      font-size: 2.57649vw; }
      .code-showcase-header-drop span else {
        font-size: 16px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .code-showcase-header-drop span {
      font-size: 2.27337vw; }
      .code-showcase-header-drop span else {
        font-size: 16px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .code-showcase-header-drop span {
      font-size: 2.03407vw; }
      .code-showcase-header-drop span else {
        font-size: 16px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .code-showcase-header-drop span {
      font-size: 1.84035vw; }
      .code-showcase-header-drop span else {
        font-size: 16px; } }
  @media (min-width: 1300px) {
    .code-showcase-header-drop span {
      font-size: 1.19792vw;
      padding: 0.78125vw;
      line-height: 1.45833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .code-showcase-header-drop span {
      font-size: 1.33102vw;
      padding: 0.86806vw;
      line-height: 1.62037vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .code-showcase-header-drop span {
      font-size: 1.19792vw;
      padding: 0.78125vw;
      line-height: 1.45833vw; } }
  .code-showcase-header-drop span:hover {
    background-color: #E6F7FD;
    color: #00ADE6; }
    .code-showcase-header-drop span:hover > a {
      -webkit-transform: translateX(0.52083vw);
              transform: translateX(0.52083vw); }

.code-showcase-header-droShow {
  z-index: 1;
  opacity: 1;
  visibility: visible;
  -webkit-transform: rotate(0);
          transform: rotate(0); }

.code-showcase-header-drop span.activeNav {
  background-color: #E6F7FD;
  color: #00ADE6 !important; }

.element_input__3FY9r {
  width: 10.41667vw;
  border: none;
  border-bottom: 1.5px solid #FFFFFF;
  font-family: Nunito;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #00ADE6;
  text-align: center;
  background-color: inherit;
  font-size: 3.64583vw; }
  @media (min-width: 1300px) {
    .element_input__3FY9r {
      font-size: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .element_input__3FY9r {
      font-size: 1.04167vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .element_input__3FY9r {
      font-size: 1.04167vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .element_input__3FY9r else {
      font-size: 3.64583vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .element_input__3FY9r else {
      font-size: 3.64583vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .element_input__3FY9r else {
      font-size: 3.64583vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .element_input__3FY9r else {
      font-size: 3.64583vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .element_input__3FY9r else {
      font-size: 3.64583vw; } }

.element_input__3FY9r:focus {
  border: none;
  outline: none;
  border-bottom: 1px solid #44e7f7; }

.element_dropArea__2IXGl {
  width: 1.97917vw;
  height: 1.45833vw;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #1ac9e8;
  display: inline-block; }

/** Padding */
.FibBlock_p-hxxs__1mJsf {
  box-sizing: border-box;
  padding-left: 4px;
  padding-right: 4px; }

.FibBlock_p-vxxs__1YvC6 {
  box-sizing: border-box;
  padding-top: 4px;
  padding-bottom: 4px; }

.FibBlock_p-hxs__vTP1w {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .FibBlock_p-hxs__vTP1w {
      padding-left: 0.41667vw;
      padding-right: 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_p-hxs__vTP1w {
      padding-left: 0.46296vw;
      padding-right: 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_p-hxs__vTP1w {
      padding-left: 0.41667vw;
      padding-right: 0.41667vw; } }

.FibBlock_p-vxs__EB8Oa {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .FibBlock_p-vxs__EB8Oa {
      padding-top: 0.41667vw;
      padding-bottom: 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_p-vxs__EB8Oa {
      padding-top: 0.46296vw;
      padding-bottom: 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_p-vxs__EB8Oa {
      padding-top: 0.41667vw;
      padding-bottom: 0.41667vw; } }

.FibBlock_p-hs__2VhPz {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .FibBlock_p-hs__2VhPz {
      padding-left: 0.83333vw;
      padding-right: 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_p-hs__2VhPz {
      padding-left: 0.92593vw;
      padding-right: 0.92593vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_p-hs__2VhPz {
      padding-left: 0.83333vw;
      padding-right: 0.83333vw; } }

.FibBlock_p-vs__vtnP0 {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .FibBlock_p-vs__vtnP0 {
      padding-top: 0.83333vw;
      padding-bottom: 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_p-vs__vtnP0 {
      padding-top: 0.92593vw;
      padding-bottom: 0.92593vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_p-vs__vtnP0 {
      padding-top: 0.83333vw;
      padding-bottom: 0.83333vw; } }

.FibBlock_p-hm__37630 {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .FibBlock_p-hm__37630 {
      padding-left: 1.25vw;
      padding-right: 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_p-hm__37630 {
      padding-left: 1.38889vw;
      padding-right: 1.38889vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_p-hm__37630 {
      padding-left: 1.25vw;
      padding-right: 1.25vw; } }

.FibBlock_p-vm__2KCyp {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .FibBlock_p-vm__2KCyp {
      padding-top: 1.25vw;
      padding-bottom: 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_p-vm__2KCyp {
      padding-top: 1.38889vw;
      padding-bottom: 1.38889vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_p-vm__2KCyp {
      padding-top: 1.25vw;
      padding-bottom: 1.25vw; } }

.FibBlock_p-hxm__1hX3S {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .FibBlock_p-hxm__1hX3S {
      padding-left: 1.66667vw;
      padding-right: 1.66667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_p-hxm__1hX3S {
      padding-left: 1.85185vw;
      padding-right: 1.85185vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_p-hxm__1hX3S {
      padding-left: 1.66667vw;
      padding-right: 1.66667vw; } }

.FibBlock_p-vxm__2oHRd {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .FibBlock_p-vxm__2oHRd {
      padding-top: 1.66667vw;
      padding-bottom: 1.66667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_p-vxm__2oHRd {
      padding-top: 1.85185vw;
      padding-bottom: 1.85185vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_p-vxm__2oHRd {
      padding-top: 1.66667vw;
      padding-bottom: 1.66667vw; } }

.FibBlock_p-hxxm__8ntEl {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .FibBlock_p-hxxm__8ntEl {
      padding-left: 2.08333vw;
      padding-right: 2.08333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_p-hxxm__8ntEl {
      padding-left: 2.31481vw;
      padding-right: 2.31481vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_p-hxxm__8ntEl {
      padding-left: 2.08333vw;
      padding-right: 2.08333vw; } }

.FibBlock_p-vxxm__3qC9q {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .FibBlock_p-vxxm__3qC9q {
      padding-top: 2.08333vw;
      padding-bottom: 2.08333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_p-vxxm__3qC9q {
      padding-top: 2.31481vw;
      padding-bottom: 2.31481vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_p-vxxm__3qC9q {
      padding-top: 2.08333vw;
      padding-bottom: 2.08333vw; } }

.FibBlock_p-hl__7GF4b {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .FibBlock_p-hl__7GF4b {
      padding-left: 2.5vw;
      padding-right: 2.5vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_p-hl__7GF4b {
      padding-left: 2.77778vw;
      padding-right: 2.77778vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_p-hl__7GF4b {
      padding-left: 2.5vw;
      padding-right: 2.5vw; } }

.FibBlock_p-vl__25Nrw {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .FibBlock_p-vl__25Nrw {
      padding-top: 2.5vw;
      padding-bottom: 2.5vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_p-vl__25Nrw {
      padding-top: 2.77778vw;
      padding-bottom: 2.77778vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_p-vl__25Nrw {
      padding-top: 2.5vw;
      padding-bottom: 2.5vw; } }

.FibBlock_p-hxl__3lGkr {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .FibBlock_p-hxl__3lGkr {
      padding-left: 3.33333vw;
      padding-right: 3.33333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_p-hxl__3lGkr {
      padding-left: 3.7037vw;
      padding-right: 3.7037vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_p-hxl__3lGkr {
      padding-left: 3.33333vw;
      padding-right: 3.33333vw; } }

.FibBlock_p-vxl__3YBXb {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .FibBlock_p-vxl__3YBXb {
      padding-top: 3.33333vw;
      padding-bottom: 3.33333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_p-vxl__3YBXb {
      padding-top: 3.7037vw;
      padding-bottom: 3.7037vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_p-vxl__3YBXb {
      padding-top: 3.33333vw;
      padding-bottom: 3.33333vw; } }

.FibBlock_p-hxxl__VCMci {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .FibBlock_p-hxxl__VCMci {
      padding-left: 5vw;
      padding-right: 5vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_p-hxxl__VCMci {
      padding-left: 5.55556vw;
      padding-right: 5.55556vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_p-hxxl__VCMci {
      padding-left: 5vw;
      padding-right: 5vw; } }

.FibBlock_p-vxxl__3Rsrt {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .FibBlock_p-vxxl__3Rsrt {
      padding-top: 5vw;
      padding-bottom: 5vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_p-vxxl__3Rsrt {
      padding-top: 5.55556vw;
      padding-bottom: 5.55556vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_p-vxxl__3Rsrt {
      padding-top: 5vw;
      padding-bottom: 5vw; } }

@media (min-width: 1300px) {
  .FibBlock_p-hxxm__8ntEl {
    padding-left: 2.08333vw;
    padding-right: 2.08333vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .FibBlock_p-hxxm__8ntEl {
    padding-left: 2.31481vw;
    padding-right: 2.31481vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .FibBlock_p-hxxm__8ntEl {
    padding-left: 2.08333vw;
    padding-right: 2.08333vw; } }

@media (min-width: 1300px) {
  .FibBlock_p-vxm__2oHRd {
    padding-top: 2.08333vw;
    padding-bottom: 2.08333vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .FibBlock_p-vxm__2oHRd {
    padding-top: 2.31481vw;
    padding-bottom: 2.31481vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .FibBlock_p-vxm__2oHRd {
    padding-top: 2.08333vw;
    padding-bottom: 2.08333vw; } }

.FibBlock_p-hxxxl__37dxC {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .FibBlock_p-hxxxl__37dxC {
      padding-left: 6.66667vw;
      padding-right: 6.66667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_p-hxxxl__37dxC {
      padding-left: 7.40741vw;
      padding-right: 7.40741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_p-hxxxl__37dxC {
      padding-left: 6.66667vw;
      padding-right: 6.66667vw; } }

.FibBlock_p-vxxxl__10tCD {
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .FibBlock_p-vxxxl__10tCD {
      padding-top: 6.66667vw;
      padding-bottom: 6.66667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_p-vxxxl__10tCD {
      padding-top: 7.40741vw;
      padding-bottom: 7.40741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_p-vxxxl__10tCD {
      padding-top: 6.66667vw;
      padding-bottom: 6.66667vw; } }

@media (min-width: 1300px) {
  .FibBlock_m-rxl__3Cv-K {
    margin-right: 3.33333vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .FibBlock_m-rxl__3Cv-K {
    margin-right: 3.7037vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .FibBlock_m-rxl__3Cv-K {
    margin-right: 3.33333vw; } }

@media (min-width: 1300px) {
  .FibBlock_m-rxxm__27P5n {
    margin-right: 2.08333vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .FibBlock_m-rxxm__27P5n {
    margin-right: 2.31481vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .FibBlock_m-rxxm__27P5n {
    margin-right: 2.08333vw; } }

@media (min-width: 1300px) {
  .FibBlock_m-tm__2KaKo {
    margin-top: 1.66667vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .FibBlock_m-tm__2KaKo {
    margin-top: 1.85185vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .FibBlock_m-tm__2KaKo {
    margin-top: 1.66667vw; } }

@media (min-width: 1300px) {
  .FibBlock_m-txxs__1QMXV {
    margin-top: 0.20833vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .FibBlock_m-txxs__1QMXV {
    margin-top: 0.23148vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .FibBlock_m-txxs__1QMXV {
    margin-top: 0.20833vw; } }

@media (min-width: 1300px) {
  .FibBlock_m-rs__1f-U6 {
    margin-right: 0.83333vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .FibBlock_m-rs__1f-U6 {
    margin-right: 0.92593vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .FibBlock_m-rs__1f-U6 {
    margin-right: 0.83333vw; } }

.FibBlock_row__zvIlS {
  display: flex; }

.FibBlock_shadow__3YUZM, .FibBlock_ph-card__2COIU {
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08); }

.FibBlock_bg-white__2k9Wc, .FibBlock_ph-card__2COIU {
  background: #ffffff; }

.FibBlock_t-bdy-strong__10o01 {
  font-family: Nunito;
  font-weight: 700; }
  @media (min-width: 1300px) {
    .FibBlock_t-bdy-strong__10o01 {
      font-size: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_t-bdy-strong__10o01 {
      font-size: 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_t-bdy-strong__10o01 {
      font-size: 1.04167vw; } }

.FibBlock_t-bdy-regular__1F70A {
  font-family: Nunito; }
  @media (min-width: 1300px) {
    .FibBlock_t-bdy-regular__1F70A {
      font-size: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_t-bdy-regular__1F70A {
      font-size: 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_t-bdy-regular__1F70A {
      font-size: 1.04167vw; } }

.FibBlock_t-bdy-regular__1F70A {
  font-family: Nunito; }
  @media (min-width: 1300px) {
    .FibBlock_t-bdy-regular__1F70A {
      font-size: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_t-bdy-regular__1F70A {
      font-size: 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_t-bdy-regular__1F70A {
      font-size: 1.04167vw; } }

.FibBlock_t-head-semi-medium__2Objh {
  font-family: Nunito;
  font-weight: 600; }
  @media (min-width: 1300px) {
    .FibBlock_t-head-semi-medium__2Objh {
      font-size: 1.35417vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_t-head-semi-medium__2Objh {
      font-size: 1.50463vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_t-head-semi-medium__2Objh {
      font-size: 1.35417vw; } }

.FibBlock_t-head-regular__3GHZU {
  font-family: Nunito; }
  @media (min-width: 1300px) {
    .FibBlock_t-head-regular__3GHZU {
      font-size: 1.35417vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_t-head-regular__3GHZU {
      font-size: 1.50463vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_t-head-regular__3GHZU {
      font-size: 1.35417vw; } }

.FibBlock_t-small-regular__1RM0D {
  color: #403F3F; }

.FibBlock_cl-deep-sky-blue__24r9j {
  color: #00ADE6; }

.FibBlock_op-50__16aaX {
  opacity: 0.5; }

.FibBlock_align-start__2AGrc {
  align-items: flex-start !important; }

.FibBlock_fibBlockContainer__3u3bG {
  display: block;
  width: 100%;
  width: 46.04167vw; }

.FibBlock_terminalContainer__1o2me {
  min-width: 38.54167vw;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  object-fit: contain;
  margin-top: 0.97917vw;
  font-size: 2.5vw; }
  @media (min-width: 1300px) {
    .FibBlock_terminalContainer__1o2me {
      font-size: 1.04167vw;
      padding-left: 1.04167vw;
      padding-top: 1.04167vw;
      padding-right: 0;
      padding-bottom: 0; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_terminalContainer__1o2me {
      font-size: 1.04167vw;
      padding-left: 1.15741vw;
      padding-top: 1.15741vw;
      padding-right: 0;
      padding-bottom: 0; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_terminalContainer__1o2me {
      font-size: 1.04167vw;
      padding-left: 1.04167vw;
      padding-top: 1.04167vw;
      padding-right: 0;
      padding-bottom: 0; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .FibBlock_terminalContainer__1o2me else {
      font-size: 2.5vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .FibBlock_terminalContainer__1o2me else {
      font-size: 2.5vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .FibBlock_terminalContainer__1o2me else {
      font-size: 2.5vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .FibBlock_terminalContainer__1o2me else {
      font-size: 2.5vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .FibBlock_terminalContainer__1o2me else {
      font-size: 2.5vw; } }

.FibBlock_terminalContainerAuto__3iQ34 {
  height: auto !important; }

.FibBlock_questionStatement__3q54_ {
  min-height: 4.0625vw;
  width: 100%;
  object-fit: contain;
  font-family: Nunito;
  font-size: 1.25vw;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #504f4f; }

.FibBlock_blocksContainer__3ZoWD {
  width: 100%;
  padding-top: 0.88542vw !important;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 2.77778vw; }
  @media (min-width: 1300px) {
    .FibBlock_blocksContainer__3ZoWD {
      margin-top: unset; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_blocksContainer__3ZoWD {
      margin-top: unset; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_blocksContainer__3ZoWD {
      margin-top: unset; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .FibBlock_blocksContainer__3ZoWD {
      margin-top: 2.31481vw; }
      .FibBlock_blocksContainer__3ZoWD else {
        margin-top: 10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .FibBlock_blocksContainer__3ZoWD {
      margin-top: 1.85185vw; }
      .FibBlock_blocksContainer__3ZoWD else {
        margin-top: 10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .FibBlock_blocksContainer__3ZoWD {
      margin-top: 1.63399vw; }
      .FibBlock_blocksContainer__3ZoWD else {
        margin-top: 10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .FibBlock_blocksContainer__3ZoWD {
      margin-top: 1.46199vw; }
      .FibBlock_blocksContainer__3ZoWD else {
        margin-top: 10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .FibBlock_blocksContainer__3ZoWD {
      margin-top: 1.32275vw; }
      .FibBlock_blocksContainer__3ZoWD else {
        margin-top: 10px; } }

.FibBlock_block__1cM2j {
  justify-content: center;
  margin-top: 1.04167vw !important;
  margin-left: 14px;
  margin-right: 14px;
  box-shadow: 0 3px 2px 0 #d4d5d6;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  background-color: #1cc7e7;
  font-family: Monaco;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  align-items: center;
  display: flex;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-left: 0.52083vw;
  margin-top: 0.52083vw;
  border-radius: 1.61458vw;
  font-size: 2.08333vw;
  height: 3.20833vw; }
  @media (min-width: 1300px) {
    .FibBlock_block__1cM2j {
      font-size: 1.09375vw;
      height: 3.20833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_block__1cM2j {
      font-size: 1.09375vw;
      height: 3.20833vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_block__1cM2j {
      font-size: 1.09375vw;
      height: 3.20833vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .FibBlock_block__1cM2j else {
      font-size: 2.08333vw; }
    .FibBlock_block__1cM2j else {
      height: 3.20833vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .FibBlock_block__1cM2j else {
      font-size: 2.08333vw; }
    .FibBlock_block__1cM2j else {
      height: 3.20833vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .FibBlock_block__1cM2j else {
      font-size: 2.08333vw; }
    .FibBlock_block__1cM2j else {
      height: 3.20833vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .FibBlock_block__1cM2j else {
      font-size: 2.08333vw; }
    .FibBlock_block__1cM2j else {
      height: 3.20833vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .FibBlock_block__1cM2j else {
      font-size: 2.08333vw; }
    .FibBlock_block__1cM2j else {
      height: 3.20833vw; } }

.FibBlock_emptyBlock__3rWdR {
  height: 3.20833vw;
  min-width: 3.95313vw;
  border-radius: 1.61458vw; }

.FibBlock_blockText__1z6QJ {
  height: 1.35417vw;
  font-family: Monaco;
  font-size: 1.09375vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.05;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff; }

.FibBlock_fibBlockText__338PH {
  padding: 0 1.04167vw; }

.FibBlock_dropArea__2NFqP {
  justify-content: center;
  display: inline-flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 8px;
  margin-left: 5px;
  border-radius: 50px;
  border-style: solid;
  border-width: 2px;
  border-color: #00ade6;
  min-width: 6.76329vw;
  min-height: 5.31401vw; }
  @media (min-width: 1300px) {
    .FibBlock_dropArea__2NFqP {
      min-width: 1.97917vw;
      min-height: 1.45833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_dropArea__2NFqP {
      min-width: 1.97917vw;
      min-height: 1.45833vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_dropArea__2NFqP {
      min-width: 1.97917vw;
      min-height: 1.45833vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .FibBlock_dropArea__2NFqP {
      min-width: 5.63607vw;
      min-height: 4.42834vw; }
      .FibBlock_dropArea__2NFqP else {
        min-width: 28px; }
      .FibBlock_dropArea__2NFqP else {
        min-height: 22px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .FibBlock_dropArea__2NFqP {
      min-width: 4.50886vw;
      min-height: 3.54267vw; }
      .FibBlock_dropArea__2NFqP else {
        min-width: 28px; }
      .FibBlock_dropArea__2NFqP else {
        min-height: 22px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .FibBlock_dropArea__2NFqP {
      min-width: 3.9784vw;
      min-height: 3.12589vw; }
      .FibBlock_dropArea__2NFqP else {
        min-width: 28px; }
      .FibBlock_dropArea__2NFqP else {
        min-height: 22px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .FibBlock_dropArea__2NFqP {
      min-width: 3.55962vw;
      min-height: 2.79685vw; }
      .FibBlock_dropArea__2NFqP else {
        min-width: 28px; }
      .FibBlock_dropArea__2NFqP else {
        min-height: 22px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .FibBlock_dropArea__2NFqP {
      min-width: 3.22061vw;
      min-height: 2.53048vw; }
      .FibBlock_dropArea__2NFqP else {
        min-width: 28px; }
      .FibBlock_dropArea__2NFqP else {
        min-height: 22px; } }

.FibBlock_updatedAnswersContainer__16bCH {
  background: #005773;
  mix-blend-mode: normal;
  border-radius: 30px;
  display: flex;
  justify-content: flex-start;
  margin-top: 32px;
  background-position: top;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(/static/media/quizWrapperBg.6201d2d0.svg);
  border-radius: 0vw;
  flex-direction: column; }
  @media (min-width: 1300px) {
    .FibBlock_updatedAnswersContainer__16bCH {
      border-radius: 1.82292vw;
      flex-direction: row; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_updatedAnswersContainer__16bCH {
      border-radius: 2.02546vw;
      flex-direction: row; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_updatedAnswersContainer__16bCH {
      border-radius: 1.82292vw;
      flex-direction: row; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .FibBlock_updatedAnswersContainer__16bCH {
      border-radius: 0vw; }
      .FibBlock_updatedAnswersContainer__16bCH else {
        border-radius: 0px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .FibBlock_updatedAnswersContainer__16bCH {
      border-radius: 0vw; }
      .FibBlock_updatedAnswersContainer__16bCH else {
        border-radius: 0px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .FibBlock_updatedAnswersContainer__16bCH {
      border-radius: 0vw; }
      .FibBlock_updatedAnswersContainer__16bCH else {
        border-radius: 0px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .FibBlock_updatedAnswersContainer__16bCH {
      border-radius: 0vw; }
      .FibBlock_updatedAnswersContainer__16bCH else {
        border-radius: 0px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .FibBlock_updatedAnswersContainer__16bCH {
      border-radius: 0vw; }
      .FibBlock_updatedAnswersContainer__16bCH else {
        border-radius: 0px; } }
  .FibBlock_updatedAnswersContainer__16bCH .FibBlock_yourAnswerContainer__3uuol {
    background-image: url("https://tekie-prod-bucket.uolo.co/python/correctAnsB.svg");
    background-position: top;
    background-repeat: no-repeat;
    background-color: #013d50 !important; }
    @media (min-width: 1300px) {
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_yourAnswerContainer__3uuol {
        background-image: none;
        background-color: unset !important; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_yourAnswerContainer__3uuol {
        background-image: none;
        background-color: unset !important; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_yourAnswerContainer__3uuol {
        background-image: none;
        background-color: unset !important; } }
  .FibBlock_updatedAnswersContainer__16bCH .FibBlock_actualAnswerContainer__33atw {
    background-image: url("https://tekie-prod-bucket.uolo.co/python/yourAnsB.svg");
    background-position: top;
    background-repeat: no-repeat;
    background-color: #005772 !important; }
    @media (min-width: 1300px) {
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_actualAnswerContainer__33atw {
        background-image: none;
        background-color: unset !important; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_actualAnswerContainer__33atw {
        background-image: none;
        background-color: unset !important; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_actualAnswerContainer__33atw {
        background-image: none;
        background-color: unset !important; } }
  .FibBlock_updatedAnswersContainer__16bCH .FibBlock_yourAnswerContainer__3uuol,
  .FibBlock_updatedAnswersContainer__16bCH .FibBlock_actualAnswerContainer__33atw {
    padding: 32px 28px;
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-weight: 600;
    color: #ffffff;
    font-size: 4.34783vw; }
    @media (min-width: 1300px) {
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_yourAnswerContainer__3uuol,
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_actualAnswerContainer__33atw {
        font-size: 0.9375vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_yourAnswerContainer__3uuol,
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_actualAnswerContainer__33atw {
        font-size: 1.04167vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_yourAnswerContainer__3uuol,
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_actualAnswerContainer__33atw {
        font-size: 0.9375vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_yourAnswerContainer__3uuol,
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_actualAnswerContainer__33atw {
        font-size: 3.62319vw; }
        .FibBlock_updatedAnswersContainer__16bCH .FibBlock_yourAnswerContainer__3uuol else,
        .FibBlock_updatedAnswersContainer__16bCH .FibBlock_actualAnswerContainer__33atw else {
          font-size: 18px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_yourAnswerContainer__3uuol,
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_actualAnswerContainer__33atw {
        font-size: 2.89855vw; }
        .FibBlock_updatedAnswersContainer__16bCH .FibBlock_yourAnswerContainer__3uuol else,
        .FibBlock_updatedAnswersContainer__16bCH .FibBlock_actualAnswerContainer__33atw else {
          font-size: 18px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_yourAnswerContainer__3uuol,
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_actualAnswerContainer__33atw {
        font-size: 2.55754vw; }
        .FibBlock_updatedAnswersContainer__16bCH .FibBlock_yourAnswerContainer__3uuol else,
        .FibBlock_updatedAnswersContainer__16bCH .FibBlock_actualAnswerContainer__33atw else {
          font-size: 18px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_yourAnswerContainer__3uuol,
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_actualAnswerContainer__33atw {
        font-size: 2.28833vw; }
        .FibBlock_updatedAnswersContainer__16bCH .FibBlock_yourAnswerContainer__3uuol else,
        .FibBlock_updatedAnswersContainer__16bCH .FibBlock_actualAnswerContainer__33atw else {
          font-size: 18px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_yourAnswerContainer__3uuol,
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_actualAnswerContainer__33atw {
        font-size: 2.07039vw; }
        .FibBlock_updatedAnswersContainer__16bCH .FibBlock_yourAnswerContainer__3uuol else,
        .FibBlock_updatedAnswersContainer__16bCH .FibBlock_actualAnswerContainer__33atw else {
          font-size: 18px; } }
    .FibBlock_updatedAnswersContainer__16bCH .FibBlock_yourAnswerContainer__3uuol > span,
    .FibBlock_updatedAnswersContainer__16bCH .FibBlock_actualAnswerContainer__33atw > span {
      margin-bottom: 16px; }
  .FibBlock_updatedAnswersContainer__16bCH .FibBlock_actualAnswerContainer__33atw {
    color: #fff;
    background-color: rgba(0, 137, 157, 0.3);
    border-left: none; }
    @media (min-width: 1300px) {
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_actualAnswerContainer__33atw {
        border-left: 2px solid #ddd; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_actualAnswerContainer__33atw {
        border-left: 2px solid #ddd; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_actualAnswerContainer__33atw {
        border-left: 2px solid #ddd; } }
  .FibBlock_updatedAnswersContainer__16bCH .FibBlock_tagsContainer__1wRUt {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 8px; }
  .FibBlock_updatedAnswersContainer__16bCH .FibBlock_answerTags__3z-6w {
    background: #65da7a;
    border-radius: 14px;
    padding: 12px 18px;
    font-weight: bold;
    font-size: 3.86473vw; }
    @media (min-width: 1300px) {
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_answerTags__3z-6w {
        font-size: 0.83333vw;
        margin-top: 0.3125vw;
        margin-right: 0.3125vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_answerTags__3z-6w {
        font-size: 0.92593vw;
        margin-top: 0.34722vw;
        margin-right: 0.34722vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_answerTags__3z-6w {
        font-size: 0.83333vw;
        margin-top: 0.3125vw;
        margin-right: 0.3125vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_answerTags__3z-6w {
        font-size: 3.22061vw; }
        .FibBlock_updatedAnswersContainer__16bCH .FibBlock_answerTags__3z-6w else {
          font-size: 16px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_answerTags__3z-6w {
        font-size: 2.57649vw; }
        .FibBlock_updatedAnswersContainer__16bCH .FibBlock_answerTags__3z-6w else {
          font-size: 16px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_answerTags__3z-6w {
        font-size: 2.27337vw; }
        .FibBlock_updatedAnswersContainer__16bCH .FibBlock_answerTags__3z-6w else {
          font-size: 16px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_answerTags__3z-6w {
        font-size: 2.03407vw; }
        .FibBlock_updatedAnswersContainer__16bCH .FibBlock_answerTags__3z-6w else {
          font-size: 16px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .FibBlock_updatedAnswersContainer__16bCH .FibBlock_answerTags__3z-6w {
        font-size: 1.84035vw; }
        .FibBlock_updatedAnswersContainer__16bCH .FibBlock_answerTags__3z-6w else {
          font-size: 16px; } }
  .FibBlock_updatedAnswersContainer__16bCH .FibBlock_incorrectTagColor__ijWZR {
    background: #ff5744 !important; }

/* mobile */
.FibBlock_mbFibBlockContainer__6sFy_ {
  width: 100%;
  padding: 10px;
  box-sizing: border-box; }

.FibBlock_mbQuestionStatement__214D8 {
  min-height: 100%;
  width: 100%;
  object-fit: contain;
  font-family: Nunito;
  font-size: 16;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #504f4f; }

.FibBlock_mbTerminalContainer__1_pLD {
  min-width: 38.54167vw;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  object-fit: contain;
  margin-top: 25px; }
  .FibBlock_mbTerminalContainer__1_pLD pre {
    padding: 11px 12px !important;
    background: #005773 !important;
    color: #ffffff !important; }
    .FibBlock_mbTerminalContainer__1_pLD pre code {
      font-size: 17px;
      font-family: Nunito; }
      .FibBlock_mbTerminalContainer__1_pLD pre code div {
        min-width: 56px;
        height: 36px;
        box-sizing: border-box;
        border-radius: 24px; }

.FibBlock_mbBlocksContainer__3ectS {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 28px;
  flex-wrap: wrap;
  justify-content: flex-start; }

@media (max-width: 768px) {
  code {
    font-size: 14px; } }

.FibBlock_mbOptionBtn__10hE9 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #00ADE6;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  padding: 10px 16px;
  min-width: 58px;
  min-height: 40px;
  border: none;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 2px; }

.FibBlock_mbBlock__1TFVK {
  justify-content: center;
  margin-top: 6px !important;
  margin-left: 14px;
  margin-right: 14px;
  box-shadow: 0 3px 2px 0 #d4d5d6;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  background-color: #1cc7e7;
  font-family: Monaco;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  align-items: center;
  display: flex;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-left: 0.52083vw;
  margin-bottom: 0.83333vw;
  min-width: 3.95313vw;
  border-radius: 1.61458vw; }
  .FibBlock_mbBlock__1TFVK > div > div > div {
    height: 5.5vh !important; }

.FibBlock_emptyBlock__3rWdR {
  height: 3.20833vw;
  min-width: 5.98958vw;
  background-color: #e9e9ea;
  border-radius: 1.61458vw;
  align-items: center;
  justify-content: center;
  margin-left: 0.52083vw;
  margin-top: 0.52083vw;
  display: none; }
  @media (min-width: 1300px) {
    .FibBlock_emptyBlock__3rWdR {
      display: flex; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .FibBlock_emptyBlock__3rWdR {
      display: flex; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .FibBlock_emptyBlock__3rWdR {
      display: flex; } }

.FibBlock_fromReportsPageStyle__1QMN8 {
  padding: 0;
  margin: 0; }
  .FibBlock_fromReportsPageStyle__1QMN8 pre {
    margin-top: 0 !important; }

.current-page {
  font-size: 1.5rem;
  vertical-align: middle; }

.pagination-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-align: center;
  overflow-x: scroll;
  padding: 7.72947vw 3.86473vw; }
  @media (min-width: 1300px) {
    .pagination-holder {
      padding: 1.66667vw 0; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .pagination-holder {
      padding: 1.85185vw 0; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .pagination-holder {
      padding: 1.66667vw 0; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .pagination-holder {
      padding: 6.44122vw 3.22061vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .pagination-holder {
      padding: 5.15298vw 2.57649vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .pagination-holder {
      padding: 4.54675vw 2.27337vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .pagination-holder {
      padding: 4.06814vw 2.03407vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .pagination-holder {
      padding: 3.6807vw 1.84035vw; } }

ul.pagination {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  text-align: center;
  margin: 0;
  padding: 0; }

ul.pagination li {
  list-style-type: none; }

ul.pagination li a.page-item {
  width: 42px;
  min-width: 42px;
  height: 42px;
  border: none;
  text-decoration: none;
  border-radius: 100px;
  font-size: 15px;
  transition: all .3s;
  display: flex;
  align-items: center;
  color: black;
  justify-content: center;
  cursor: pointer;
  background-color: #CBF7FC;
  font-weight: 600;
  margin-right: 11px;
  line-height: 30px; }
  ul.pagination li a.page-item:hover {
    color: #ffffff !important;
    background: linear-gradient(#35e4e9 0%, #00ade6 100%) !important; }

ul.pagination li a.page-item-skip-btn {
  font-size: 22px;
  color: black;
  text-decoration: none;
  background-color: transparent;
  color: #00ADE6; }

ul.pagination li a.page-item.active {
  color: #ffffff !important;
  text-decoration: none;
  background: linear-gradient(#35e4e9 0%, #00ade6 100%) !important; }

.pagination-arrow {
  background-repeat: no-repeat;
  cursor: pointer;
  object-fit: contain;
  background-size: contain;
  background-position: center;
  background-image: url(/static/media/quizLeftArrow.2b595c67.svg);
  display: block;
  width: 9.66184vw;
  height: 7.24638vw; }
  @media (min-width: 1300px) {
    .pagination-arrow {
      width: 1.04167vw;
      height: 2.02604vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .pagination-arrow {
      width: 1.04167vw;
      height: 2.02604vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .pagination-arrow {
      width: 1.04167vw;
      height: 2.02604vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .pagination-arrow {
      width: 8.05153vw;
      height: 6.03865vw; }
      .pagination-arrow else {
        width: 40px; }
      .pagination-arrow else {
        height: 30px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .pagination-arrow {
      width: 6.44122vw;
      height: 4.83092vw; }
      .pagination-arrow else {
        width: 40px; }
      .pagination-arrow else {
        height: 30px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .pagination-arrow {
      width: 5.68343vw;
      height: 4.26257vw; }
      .pagination-arrow else {
        width: 40px; }
      .pagination-arrow else {
        height: 30px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .pagination-arrow {
      width: 5.08518vw;
      height: 3.81388vw; }
      .pagination-arrow else {
        width: 40px; }
      .pagination-arrow else {
        height: 30px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .pagination-arrow {
      width: 4.60087vw;
      height: 3.45066vw; }
      .pagination-arrow else {
        width: 40px; }
      .pagination-arrow else {
        height: 30px; } }
  .pagination-arrow:hover {
    color: #aaacae;
    opacity: 0.7; }

.pagination-leftArrow {
  background-image: url(/static/media/quizLeftArrow.2b595c67.svg);
  margin-right: 1.93237vw; }
  @media (min-width: 1300px) {
    .pagination-leftArrow {
      margin-right: 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .pagination-leftArrow {
      margin-right: 1.38889vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .pagination-leftArrow {
      margin-right: 1.25vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .pagination-leftArrow {
      margin-right: 1.61031vw; }
      .pagination-leftArrow else {
        margin-right: 8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .pagination-leftArrow {
      margin-right: 1.28824vw; }
      .pagination-leftArrow else {
        margin-right: 8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .pagination-leftArrow {
      margin-right: 1.13669vw; }
      .pagination-leftArrow else {
        margin-right: 8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .pagination-leftArrow {
      margin-right: 1.01704vw; }
      .pagination-leftArrow else {
        margin-right: 8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .pagination-leftArrow {
      margin-right: 0.92017vw; }
      .pagination-leftArrow else {
        margin-right: 8px; } }

.pagination-rightArrow {
  background-image: url(/static/media/quizRightArrow.d48151a5.svg);
  margin-left: 0.96618vw; }
  @media (min-width: 1300px) {
    .pagination-rightArrow {
      margin-left: 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .pagination-rightArrow {
      margin-left: 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .pagination-rightArrow {
      margin-left: 0.41667vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .pagination-rightArrow {
      margin-left: 0.80515vw; }
      .pagination-rightArrow else {
        margin-left: 4px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .pagination-rightArrow {
      margin-left: 0.64412vw; }
      .pagination-rightArrow else {
        margin-left: 4px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .pagination-rightArrow {
      margin-left: 0.56834vw; }
      .pagination-rightArrow else {
        margin-left: 4px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .pagination-rightArrow {
      margin-left: 0.50852vw; }
      .pagination-rightArrow else {
        margin-left: 4px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .pagination-rightArrow {
      margin-left: 0.46009vw; }
      .pagination-rightArrow else {
        margin-left: 4px; } }

.mediaDesktopOnly {
  width: 100%;
  display: none; }
  @media (min-width: 1300px) {
    .mediaDesktopOnly {
      display: block; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mediaDesktopOnly {
      display: block; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mediaDesktopOnly {
      display: block; } }

.mediaMobileOnly {
  display: block; }
  @media (min-width: 1300px) {
    .mediaMobileOnly {
      display: none; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mediaMobileOnly {
      display: none; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mediaMobileOnly {
      display: none; } }

.dropdown-phone-number-light {
  font-family: Nunito !important; }
  .dropdown-phone-number-light .react-tel-input {
    font-family: 'Lato', sans-serif;
    width: 100% !important; }
  .dropdown-phone-number-light .selected-flag {
    width: 100% !important; }
    @media (min-width: 1300px) {
      .dropdown-phone-number-light .selected-flag {
        padding-left: 0.9375vw !important; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .dropdown-phone-number-light .selected-flag {
        padding-left: 1.04167vw !important; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .dropdown-phone-number-light .selected-flag {
        padding-left: 0.9375vw !important; } }
    .dropdown-phone-number-light .selected-flag:hover {
      background: transparent !important; }
  .dropdown-phone-number-light .arrow {
    opacity: 0.4; }
  .dropdown-phone-number-light .selected-flag.open {
    background: transparent !important; }
  .dropdown-phone-number-light .country-list {
    background-color: #fff !important;
    overflow-y: auto !important; }
  .dropdown-phone-number-light .flag-dropdown:focus {
    outline: none; }
  .dropdown-phone-number-light .country {
    color: #222 !important;
    -webkit-user-select: none;
            user-select: none; }
    .dropdown-phone-number-light .country:hover {
      background-color: #fff !important;
      background-color: rgba(6, 179, 230, 0.3) !important; }
    .dropdown-phone-number-light .country:focus {
      outline: none; }
  .dropdown-phone-number-light .country.highlight {
    background-color: rgba(6, 179, 230, 0.7) !important;
    color: white !important;
    font-weight: 700;
    border: unset !important; }
    .dropdown-phone-number-light .country.highlight .dial-code {
      color: white !important; }
  .dropdown-phone-number-light .icon, .dropdown-phone-number-light .flag.in, .dropdown-phone-number-light .flag.ru, .dropdown-phone-number-light .flag.af, .dropdown-phone-number-light .flag.au, .dropdown-phone-number-light .flag.sg, .dropdown-phone-number-light .flag.us, .dropdown-phone-number-light .flag.gb, .dropdown-phone-number-light .flag.ae {
    background-repeat: no-repeat !important; }
  .dropdown-phone-number-light .flag.in {
    background-image: url(/static/media/in.bdfaaf97.svg) !important;
    background-position: 0 0 !important; }
  .dropdown-phone-number-light .flag.ru {
    background-image: url(/static/media/ru.e2037a0e.svg) !important;
    background-position: 0 0 !important; }
  .dropdown-phone-number-light .flag.af {
    background-image: url(/static/media/af.d8fb0d22.svg) !important;
    background-position: 0 0 !important; }
  .dropdown-phone-number-light .flag.au {
    background-image: url(/static/media/au.e875a51e.svg) !important;
    background-position: 0 0 !important; }
  .dropdown-phone-number-light .flag.sg {
    background-image: url(/static/media/sg.6ae2dc5b.svg) !important;
    background-position: 0 0 !important; }
  .dropdown-phone-number-light .flag.us {
    background-image: url(/static/media/us.50162f60.svg) !important;
    background-position: 0 0 !important; }
  .dropdown-phone-number-light .flag.gb {
    background-image: url(/static/media/gb.c39480d5.svg) !important;
    background-position: 0 0 !important; }
  .dropdown-phone-number-light .flag.ae {
    background-image: url(/static/media/ae.6d497f1c.svg) !important;
    background-position: 0 0 !important; }

.lato, .lato-light, .lato-regular, .lato-hairline {
  font-family: 'Lato', sans-serif; }

.lato-light {
  font-weight: 300; }

.lato-regular {
  font-weight: 400; }

.lato-hairline {
  font-weight: 100; }

.signup-page-container {
  width: 100vw;
  height: 100vh; }
  @media (min-width: 1300px) {
    .signup-page-container {
      width: 100vw;
      height: 100vh; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-container {
      width: 100vw;
      height: 100vh; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-container {
      width: 100vw;
      height: 100vh; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-container else {
      width: 100vw; }
    .signup-page-container else {
      height: 100vh; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-container else {
      width: 100vw; }
    .signup-page-container else {
      height: 100vh; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-container else {
      width: 100vw; }
    .signup-page-container else {
      height: 100vh; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-container else {
      width: 100vw; }
    .signup-page-container else {
      height: 100vh; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-container else {
      width: 100vw; }
    .signup-page-container else {
      height: 100vh; } }

.signup-page-triangleBG {
  position: fixed;
  z-index: -1;
  background: url(/static/media/triangleBG.397c2b5f.svg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh; }
  @media (min-width: 1300px) {
    .signup-page-triangleBG {
      width: 100vw;
      height: 100vh; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-triangleBG {
      width: 100vw;
      height: 100vh; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-triangleBG {
      width: 100vw;
      height: 100vh; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-triangleBG else {
      width: 100vw; }
    .signup-page-triangleBG else {
      height: 100vh; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-triangleBG else {
      width: 100vw; }
    .signup-page-triangleBG else {
      height: 100vh; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-triangleBG else {
      width: 100vw; }
    .signup-page-triangleBG else {
      height: 100vh; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-triangleBG else {
      width: 100vw; }
    .signup-page-triangleBG else {
      height: 100vh; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-triangleBG else {
      width: 100vw; }
    .signup-page-triangleBG else {
      height: 100vh; } }

.signup-page-thumbsupEmoji {
  display: inline-block;
  position: relative;
  width: 6.03865vw;
  height: 6.03865vw;
  top: 0.72464vw;
  background: url(/static/media/thumbsUp.cc884848.png);
  background-size: contain; }
  @media (min-width: 1300px) {
    .signup-page-thumbsupEmoji {
      width: 1.5625vw;
      height: 1.5625vw;
      top: 0.15625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-thumbsupEmoji {
      width: 1.73611vw;
      height: 1.73611vw;
      top: 0.17361vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-thumbsupEmoji {
      width: 1.5625vw;
      height: 1.5625vw;
      top: 0.15625vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-thumbsupEmoji {
      width: 5.03221vw;
      height: 5.03221vw;
      top: 0.60386vw; }
      .signup-page-thumbsupEmoji else {
        width: 25px; }
      .signup-page-thumbsupEmoji else {
        height: 25px; }
      .signup-page-thumbsupEmoji else {
        top: 3px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-thumbsupEmoji {
      width: 4.02576vw;
      height: 4.02576vw;
      top: 0.48309vw; }
      .signup-page-thumbsupEmoji else {
        width: 25px; }
      .signup-page-thumbsupEmoji else {
        height: 25px; }
      .signup-page-thumbsupEmoji else {
        top: 3px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-thumbsupEmoji {
      width: 3.55215vw;
      height: 3.55215vw;
      top: 0.42626vw; }
      .signup-page-thumbsupEmoji else {
        width: 25px; }
      .signup-page-thumbsupEmoji else {
        height: 25px; }
      .signup-page-thumbsupEmoji else {
        top: 3px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-thumbsupEmoji {
      width: 3.17824vw;
      height: 3.17824vw;
      top: 0.38139vw; }
      .signup-page-thumbsupEmoji else {
        width: 25px; }
      .signup-page-thumbsupEmoji else {
        height: 25px; }
      .signup-page-thumbsupEmoji else {
        top: 3px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-thumbsupEmoji {
      width: 2.87555vw;
      height: 2.87555vw;
      top: 0.34507vw; }
      .signup-page-thumbsupEmoji else {
        width: 25px; }
      .signup-page-thumbsupEmoji else {
        height: 25px; }
      .signup-page-thumbsupEmoji else {
        top: 3px; } }

.signup-page-laptopText {
  font-family: Nunito;
  font-weight: 400;
  font-style: italic;
  text-decoration: underline;
  color: #504F4F;
  opacity: 0.75;
  font-size: 3.62319vw;
  margin-top: 2.89855vw; }
  @media (min-width: 1300px) {
    .signup-page-laptopText {
      font-size: 0.9375vw;
      margin-top: 0.625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-laptopText {
      font-size: 1.04167vw;
      margin-top: 0.69444vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-laptopText {
      font-size: 0.9375vw;
      margin-top: 0.625vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-laptopText {
      font-size: 3.01932vw;
      margin-top: 2.41546vw; }
      .signup-page-laptopText else {
        font-size: 15px; }
      .signup-page-laptopText else {
        margin-top: 12px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-laptopText {
      font-size: 2.41546vw;
      margin-top: 1.93237vw; }
      .signup-page-laptopText else {
        font-size: 15px; }
      .signup-page-laptopText else {
        margin-top: 12px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-laptopText {
      font-size: 2.13129vw;
      margin-top: 1.70503vw; }
      .signup-page-laptopText else {
        font-size: 15px; }
      .signup-page-laptopText else {
        margin-top: 12px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-laptopText {
      font-size: 1.90694vw;
      margin-top: 1.52555vw; }
      .signup-page-laptopText else {
        font-size: 15px; }
      .signup-page-laptopText else {
        margin-top: 12px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-laptopText {
      font-size: 1.72533vw;
      margin-top: 1.38026vw; }
      .signup-page-laptopText else {
        font-size: 15px; }
      .signup-page-laptopText else {
        margin-top: 12px; } }

.signup-page-parentImageContainer {
  position: relative;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13.52657vw;
  height: 13.52657vw;
  top: 0vw;
  left: 0vw;
  background: #fff; }
  @media (min-width: 1300px) {
    .signup-page-parentImageContainer {
      width: 5.26042vw;
      height: 5.26042vw;
      top: 0vw;
      left: 0vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-parentImageContainer {
      width: 5.84491vw;
      height: 5.84491vw;
      top: 0vw;
      left: 0vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-parentImageContainer {
      width: 5.26042vw;
      height: 5.26042vw;
      top: 0vw;
      left: 0vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-parentImageContainer {
      width: 11.27214vw;
      height: 11.27214vw;
      top: 0vw;
      left: 0vw; }
      .signup-page-parentImageContainer else {
        width: 56px; }
      .signup-page-parentImageContainer else {
        height: 56px; }
      .signup-page-parentImageContainer else {
        top: 0px; }
      .signup-page-parentImageContainer else {
        left: 0px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-parentImageContainer {
      width: 9.01771vw;
      height: 9.01771vw;
      top: 0vw;
      left: 0vw; }
      .signup-page-parentImageContainer else {
        width: 56px; }
      .signup-page-parentImageContainer else {
        height: 56px; }
      .signup-page-parentImageContainer else {
        top: 0px; }
      .signup-page-parentImageContainer else {
        left: 0px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-parentImageContainer {
      width: 7.95681vw;
      height: 7.95681vw;
      top: 0vw;
      left: 0vw; }
      .signup-page-parentImageContainer else {
        width: 56px; }
      .signup-page-parentImageContainer else {
        height: 56px; }
      .signup-page-parentImageContainer else {
        top: 0px; }
      .signup-page-parentImageContainer else {
        left: 0px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-parentImageContainer {
      width: 7.11925vw;
      height: 7.11925vw;
      top: 0vw;
      left: 0vw; }
      .signup-page-parentImageContainer else {
        width: 56px; }
      .signup-page-parentImageContainer else {
        height: 56px; }
      .signup-page-parentImageContainer else {
        top: 0px; }
      .signup-page-parentImageContainer else {
        left: 0px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-parentImageContainer {
      width: 6.44122vw;
      height: 6.44122vw;
      top: 0vw;
      left: 0vw; }
      .signup-page-parentImageContainer else {
        width: 56px; }
      .signup-page-parentImageContainer else {
        height: 56px; }
      .signup-page-parentImageContainer else {
        top: 0px; }
      .signup-page-parentImageContainer else {
        left: 0px; } }

.signup-page-parentImage {
  position: relative;
  min-width: 13.04348vw;
  min-height: 13.04348vw;
  border: 0.48309vw solid #33E3EA;
  top: 0.48309vw;
  background: url(/static/media/sudipto.8a74b82c.jpg);
  background-size: contain;
  border-radius: 50%; }
  @media (min-width: 1300px) {
    .signup-page-parentImage {
      min-width: 5.10417vw;
      min-height: 5.10417vw;
      border: 0.15625vw solid #33E3EA;
      top: unset; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-parentImage {
      min-width: 5.6713vw;
      min-height: 5.6713vw;
      border: 0.17361vw solid #33E3EA;
      top: unset; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-parentImage {
      min-width: 5.10417vw;
      min-height: 5.10417vw;
      border: 0.15625vw solid #33E3EA;
      top: unset; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-parentImage {
      min-width: 10.86957vw;
      min-height: 10.86957vw;
      border: 0.40258vw solid #33E3EA;
      top: 0.40258vw; }
      .signup-page-parentImage else {
        min-width: 54px; }
      .signup-page-parentImage else {
        min-height: 54px; }
      .signup-page-parentImage else {
        top: 2px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-parentImage {
      min-width: 8.69565vw;
      min-height: 8.69565vw;
      border: 0.32206vw solid #33E3EA;
      top: 0.32206vw; }
      .signup-page-parentImage else {
        min-width: 54px; }
      .signup-page-parentImage else {
        min-height: 54px; }
      .signup-page-parentImage else {
        top: 2px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-parentImage {
      min-width: 7.67263vw;
      min-height: 7.67263vw;
      border: 0.28417vw solid #33E3EA;
      top: 0.28417vw; }
      .signup-page-parentImage else {
        min-width: 54px; }
      .signup-page-parentImage else {
        min-height: 54px; }
      .signup-page-parentImage else {
        top: 2px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-parentImage {
      min-width: 6.86499vw;
      min-height: 6.86499vw;
      border: 0.25426vw solid #33E3EA;
      top: 0.25426vw; }
      .signup-page-parentImage else {
        min-width: 54px; }
      .signup-page-parentImage else {
        min-height: 54px; }
      .signup-page-parentImage else {
        top: 2px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-parentImage {
      min-width: 6.21118vw;
      min-height: 6.21118vw;
      border: 0.23004vw solid #33E3EA;
      top: 0.23004vw; }
      .signup-page-parentImage else {
        min-width: 54px; }
      .signup-page-parentImage else {
        min-height: 54px; }
      .signup-page-parentImage else {
        top: 2px; } }

.signup-page-error {
  font-family: Nunito;
  font-weight: 500;
  position: relative;
  width: 100%;
  color: #DB524E;
  height: 2.41546vw;
  top: 0.96618vw;
  left: 0.96618vw;
  font-size: 2.89855vw; }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-error {
      height: 2.01288vw;
      top: 0.80515vw;
      left: 0.80515vw;
      font-size: 2.41546vw; }
      .signup-page-error else {
        height: 10px; }
      .signup-page-error else {
        top: 4px; }
      .signup-page-error else {
        left: 4px; }
      .signup-page-error else {
        font-size: 12px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-error {
      height: 1.61031vw;
      top: 0.64412vw;
      left: 0.64412vw;
      font-size: 1.93237vw; }
      .signup-page-error else {
        height: 10px; }
      .signup-page-error else {
        top: 4px; }
      .signup-page-error else {
        left: 4px; }
      .signup-page-error else {
        font-size: 12px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-error {
      height: 1.42086vw;
      top: 0.56834vw;
      left: 0.56834vw;
      font-size: 1.70503vw; }
      .signup-page-error else {
        height: 10px; }
      .signup-page-error else {
        top: 4px; }
      .signup-page-error else {
        left: 4px; }
      .signup-page-error else {
        font-size: 12px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-error {
      height: 1.27129vw;
      top: 0.50852vw;
      left: 0.50852vw;
      font-size: 1.52555vw; }
      .signup-page-error else {
        height: 10px; }
      .signup-page-error else {
        top: 4px; }
      .signup-page-error else {
        left: 4px; }
      .signup-page-error else {
        font-size: 12px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-error {
      height: 1.15022vw;
      top: 0.46009vw;
      left: 0.46009vw;
      font-size: 1.38026vw; }
      .signup-page-error else {
        height: 10px; }
      .signup-page-error else {
        top: 4px; }
      .signup-page-error else {
        left: 4px; }
      .signup-page-error else {
        font-size: 12px; } }
  @media (min-width: 1300px) {
    .signup-page-error {
      height: 0.36458vw;
      top: 0.26042vw;
      left: 0vw;
      font-size: 0.88542vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-error {
      height: 0.40509vw;
      top: 0.28935vw;
      left: 0vw;
      font-size: 0.9838vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-error {
      height: 0.36458vw;
      top: 0.26042vw;
      left: 0vw;
      font-size: 0.88542vw; } }

.signup-page-headerRow {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  position: relative;
  z-index: 99;
  align-items: center;
  padding: 4.83092vw 0vw 0 0vw;
  justify-content: center; }
  @media (min-width: 1300px) {
    .signup-page-headerRow {
      padding: 1.5625vw 3.125vw 0 1.5625vw;
      justify-content: space-between; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-headerRow {
      padding: 1.73611vw 3.47222vw 0 1.73611vw;
      justify-content: space-between; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-headerRow {
      padding: 1.5625vw 3.125vw 0 1.5625vw;
      justify-content: space-between; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-headerRow {
      padding: 4.02576vw 0vw 0 0vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-headerRow {
      padding: 3.22061vw 0vw 0 0vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-headerRow {
      padding: 2.84172vw 0vw 0 0vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-headerRow {
      padding: 2.54259vw 0vw 0 0vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-headerRow {
      padding: 2.30044vw 0vw 0 0vw; } }

.signup-page-banner-row {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  position: relative;
  z-index: 99;
  background: #E6F7FD;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1.93237vw 0vw; }
  @media (min-width: 1300px) {
    .signup-page-banner-row {
      padding: 0.625vw 0vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-banner-row {
      padding: 0.69444vw 0vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-banner-row {
      padding: 0.625vw 0vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-banner-row {
      padding: 1.61031vw 0vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-banner-row {
      padding: 1.28824vw 0vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-banner-row {
      padding: 1.13669vw 0vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-banner-row {
      padding: 1.01704vw 0vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-banner-row {
      padding: 0.92017vw 0vw; } }

.signup-page-desktopOnly {
  display: none; }
  @media (min-width: 1300px) {
    .signup-page-desktopOnly {
      display: block; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-desktopOnly {
      display: block; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-desktopOnly {
      display: block; } }

.signup-page-mobileOnly {
  display: block; }
  @media (min-width: 1300px) {
    .signup-page-mobileOnly {
      display: none; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-mobileOnly {
      display: none; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-mobileOnly {
      display: none; } }

.signup-page-GSVLogo {
  width: 12.75362vw;
  height: 8.69565vw;
  left: 0vw;
  position: relative;
  background-image: url(/static/media/GSVLogo.5eb33002.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center; }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-GSVLogo {
      width: 10.62802vw;
      height: 7.24638vw;
      left: 0vw; }
      .signup-page-GSVLogo else {
        width: 52.8px; }
      .signup-page-GSVLogo else {
        height: 36px; }
      .signup-page-GSVLogo else {
        left: 0px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-GSVLogo {
      width: 8.50242vw;
      height: 5.7971vw;
      left: 0vw; }
      .signup-page-GSVLogo else {
        width: 52.8px; }
      .signup-page-GSVLogo else {
        height: 36px; }
      .signup-page-GSVLogo else {
        left: 0px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-GSVLogo {
      width: 7.50213vw;
      height: 5.11509vw;
      left: 0vw; }
      .signup-page-GSVLogo else {
        width: 52.8px; }
      .signup-page-GSVLogo else {
        height: 36px; }
      .signup-page-GSVLogo else {
        left: 0px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-GSVLogo {
      width: 6.71243vw;
      height: 4.57666vw;
      left: 0vw; }
      .signup-page-GSVLogo else {
        width: 52.8px; }
      .signup-page-GSVLogo else {
        height: 36px; }
      .signup-page-GSVLogo else {
        left: 0px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-GSVLogo {
      width: 6.07315vw;
      height: 4.14079vw;
      left: 0vw; }
      .signup-page-GSVLogo else {
        width: 52.8px; }
      .signup-page-GSVLogo else {
        height: 36px; }
      .signup-page-GSVLogo else {
        left: 0px; } }
  @media (min-width: 1300px) {
    .signup-page-GSVLogo {
      width: 4.16667vw;
      height: 2.70833vw;
      left: 0vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-GSVLogo {
      width: 4.62963vw;
      height: 3.00926vw;
      left: 0vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-GSVLogo {
      width: 4.16667vw;
      height: 2.70833vw;
      left: 0vw; } }

.signup-page-GSV-link {
  font-family: Nunito;
  font-weight: bold;
  color: #504F4F;
  background: -webkit-linear-gradient(125deg, #35E4E9, #00ADE6);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  border-bottom: 1.6px solid #35E4E9;
  margin-right: 8px;
  transition: all .5;
  font-size: 3.86473vw; }
  @media (min-width: 1300px) {
    .signup-page-GSV-link {
      font-size: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-GSV-link {
      font-size: 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-GSV-link {
      font-size: 1.04167vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-GSV-link {
      font-size: 3.22061vw; }
      .signup-page-GSV-link else {
        font-size: 16px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-GSV-link {
      font-size: 2.57649vw; }
      .signup-page-GSV-link else {
        font-size: 16px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-GSV-link {
      font-size: 2.27337vw; }
      .signup-page-GSV-link else {
        font-size: 16px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-GSV-link {
      font-size: 2.03407vw; }
      .signup-page-GSV-link else {
        font-size: 16px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-GSV-link {
      font-size: 1.84035vw; }
      .signup-page-GSV-link else {
        font-size: 16px; } }
  .signup-page-GSV-link:hover {
    opacity: 0.8;
    transition: all .5; }

.signup-page-headerText {
  font-family: Nunito;
  font-weight: bold;
  color: #504F4F;
  font-size: 3.38164vw;
  padding: 1.44928vw 0vw 0vw; }
  @media (min-width: 1300px) {
    .signup-page-headerText {
      font-size: 1.04167vw;
      padding: 0; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-headerText {
      font-size: 1.15741vw;
      padding: 0; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-headerText {
      font-size: 1.04167vw;
      padding: 0; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-headerText {
      font-size: 2.81804vw;
      padding: 1.20773vw 0vw 0vw; }
      .signup-page-headerText else {
        font-size: 14px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-headerText {
      font-size: 2.25443vw;
      padding: 0.96618vw 0vw 0vw; }
      .signup-page-headerText else {
        font-size: 14px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-headerText {
      font-size: 1.9892vw;
      padding: 0.85251vw 0vw 0vw; }
      .signup-page-headerText else {
        font-size: 14px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-headerText {
      font-size: 1.77981vw;
      padding: 0.76278vw 0vw 0vw; }
      .signup-page-headerText else {
        font-size: 14px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-headerText {
      font-size: 1.61031vw;
      padding: 0.69013vw 0vw 0vw; }
      .signup-page-headerText else {
        font-size: 14px; } }

.signup-page-tekieLogo {
  width: 15.65217vw;
  height: 5.7971vw;
  left: 0vw;
  position: relative;
  background-image: url(/static/media/tekieLogo.029ba7a9.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center; }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-tekieLogo {
      width: 13.04348vw;
      height: 4.83092vw;
      left: 0vw; }
      .signup-page-tekieLogo else {
        width: 64.8px; }
      .signup-page-tekieLogo else {
        height: 24px; }
      .signup-page-tekieLogo else {
        left: 0px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-tekieLogo {
      width: 10.43478vw;
      height: 3.86473vw;
      left: 0vw; }
      .signup-page-tekieLogo else {
        width: 64.8px; }
      .signup-page-tekieLogo else {
        height: 24px; }
      .signup-page-tekieLogo else {
        left: 0px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-tekieLogo {
      width: 9.20716vw;
      height: 3.41006vw;
      left: 0vw; }
      .signup-page-tekieLogo else {
        width: 64.8px; }
      .signup-page-tekieLogo else {
        height: 24px; }
      .signup-page-tekieLogo else {
        left: 0px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-tekieLogo {
      width: 8.23799vw;
      height: 3.05111vw;
      left: 0vw; }
      .signup-page-tekieLogo else {
        width: 64.8px; }
      .signup-page-tekieLogo else {
        height: 24px; }
      .signup-page-tekieLogo else {
        left: 0px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-tekieLogo {
      width: 7.45342vw;
      height: 2.76052vw;
      left: 0vw; }
      .signup-page-tekieLogo else {
        width: 64.8px; }
      .signup-page-tekieLogo else {
        height: 24px; }
      .signup-page-tekieLogo else {
        left: 0px; } }
  @media (min-width: 1300px) {
    .signup-page-tekieLogo {
      width: 6.51042vw;
      height: 2.29167vw;
      left: 0vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-tekieLogo {
      width: 7.2338vw;
      height: 2.5463vw;
      left: 0vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-tekieLogo {
      width: 6.51042vw;
      height: 2.29167vw;
      left: 0vw; } }

.signup-page-alreadyAccount {
  font-family: Nunito;
  font-weight: 600;
  color: #504F4F;
  font-size: 3.86473vw;
  margin-top: 3.62319vw;
  opacity: 0.8; }
  @media (min-width: 1300px) {
    .signup-page-alreadyAccount {
      font-size: 1.04167vw;
      margin-top: unset;
      opacity: 1; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-alreadyAccount {
      font-size: 1.15741vw;
      margin-top: unset;
      opacity: 1; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-alreadyAccount {
      font-size: 1.04167vw;
      margin-top: unset;
      opacity: 1; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-alreadyAccount {
      font-size: 3.22061vw;
      margin-top: 3.01932vw; }
      .signup-page-alreadyAccount else {
        font-size: 16px; }
      .signup-page-alreadyAccount else {
        margin-top: 15px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-alreadyAccount {
      font-size: 2.57649vw;
      margin-top: 2.41546vw; }
      .signup-page-alreadyAccount else {
        font-size: 16px; }
      .signup-page-alreadyAccount else {
        margin-top: 15px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-alreadyAccount {
      font-size: 2.27337vw;
      margin-top: 2.13129vw; }
      .signup-page-alreadyAccount else {
        font-size: 16px; }
      .signup-page-alreadyAccount else {
        margin-top: 15px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-alreadyAccount {
      font-size: 2.03407vw;
      margin-top: 1.90694vw; }
      .signup-page-alreadyAccount else {
        font-size: 16px; }
      .signup-page-alreadyAccount else {
        margin-top: 15px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-alreadyAccount {
      font-size: 1.84035vw;
      margin-top: 1.72533vw; }
      .signup-page-alreadyAccount else {
        font-size: 16px; }
      .signup-page-alreadyAccount else {
        margin-top: 15px; } }

.signup-page-body {
  width: 100vw;
  min-height: 100vh;
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center; }

.signup-page-headingBookSession {
  color: #00ADE5;
  font-family: Lato;
  font-weight: bold;
  opacity: 0.8;
  text-transform: capitalize;
  text-align: center;
  font-size: 5.31401vw;
  margin-top: 4.34783vw;
  line-height: 6.40097vw; }
  @media (min-width: 1300px) {
    .signup-page-headingBookSession {
      font-size: 1.82292vw;
      margin-top: -2.29167vw;
      line-height: 2.1875vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-headingBookSession {
      font-size: 2.02546vw;
      margin-top: -2.5463vw;
      line-height: 2.43056vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-headingBookSession {
      font-size: 1.82292vw;
      margin-top: -2.29167vw;
      line-height: 2.1875vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-headingBookSession {
      font-size: 4.42834vw;
      margin-top: 3.62319vw;
      line-height: 5.33414vw; }
      .signup-page-headingBookSession else {
        font-size: 22px; }
      .signup-page-headingBookSession else {
        margin-top: 18px; }
      .signup-page-headingBookSession else {
        line-height: 26.5px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-headingBookSession {
      font-size: 3.54267vw;
      margin-top: 2.89855vw;
      line-height: 4.26731vw; }
      .signup-page-headingBookSession else {
        font-size: 22px; }
      .signup-page-headingBookSession else {
        margin-top: 18px; }
      .signup-page-headingBookSession else {
        line-height: 26.5px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-headingBookSession {
      font-size: 3.12589vw;
      margin-top: 2.55754vw;
      line-height: 3.76527vw; }
      .signup-page-headingBookSession else {
        font-size: 22px; }
      .signup-page-headingBookSession else {
        margin-top: 18px; }
      .signup-page-headingBookSession else {
        line-height: 26.5px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-headingBookSession {
      font-size: 2.79685vw;
      margin-top: 2.28833vw;
      line-height: 3.36893vw; }
      .signup-page-headingBookSession else {
        font-size: 22px; }
      .signup-page-headingBookSession else {
        margin-top: 18px; }
      .signup-page-headingBookSession else {
        line-height: 26.5px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-headingBookSession {
      font-size: 2.53048vw;
      margin-top: 2.07039vw;
      line-height: 3.04808vw; }
      .signup-page-headingBookSession else {
        font-size: 22px; }
      .signup-page-headingBookSession else {
        margin-top: 18px; }
      .signup-page-headingBookSession else {
        line-height: 26.5px; } }

.signup-page-signupBody, .signup-page-signupBodySchool {
  align-self: center;
  display: flex;
  padding: 0 2.41546vw;
  background: transparent;
  flex-direction: column-reverse; }
  @media (min-width: 1300px) {
    .signup-page-signupBody, .signup-page-signupBodySchool {
      min-width: 65.10417vw;
      border-radius: 1.30208vw;
      box-shadow: 0vw 1.04167vw 1.5625vw rgba(0, 23, 31, 0.15);
      margin-top: 1.5625vw;
      margin-bottom: 1.5625vw;
      padding: unset;
      background: #fff;
      flex-direction: row; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-signupBody, .signup-page-signupBodySchool {
      min-width: 72.33796vw;
      border-radius: 1.44676vw;
      box-shadow: 0vw 1.15741vw 1.73611vw rgba(0, 23, 31, 0.15);
      margin-top: 1.73611vw;
      margin-bottom: 1.73611vw;
      padding: unset;
      background: #fff;
      flex-direction: row; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-signupBody, .signup-page-signupBodySchool {
      min-width: 65.10417vw;
      border-radius: 1.30208vw;
      box-shadow: 0vw 1.04167vw 1.5625vw rgba(0, 23, 31, 0.15);
      margin-top: 1.5625vw;
      margin-bottom: 1.5625vw;
      padding: unset;
      background: #fff;
      flex-direction: row; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-signupBody, .signup-page-signupBodySchool {
      padding: 0 2.01288vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-signupBody, .signup-page-signupBodySchool {
      padding: 0 1.61031vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-signupBody, .signup-page-signupBodySchool {
      padding: 0 1.42086vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-signupBody, .signup-page-signupBodySchool {
      padding: 0 1.27129vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-signupBody, .signup-page-signupBodySchool {
      padding: 0 1.15022vw; } }

.signup-page-signupBodySchool {
  margin-top: 8.45411vw; }
  @media (min-width: 1300px) {
    .signup-page-signupBodySchool {
      margin-top: 1.66667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-signupBodySchool {
      margin-top: 1.85185vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-signupBodySchool {
      margin-top: 1.66667vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-signupBodySchool {
      margin-top: 7.04509vw; }
      .signup-page-signupBodySchool else {
        margin-top: 35px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-signupBodySchool {
      margin-top: 5.63607vw; }
      .signup-page-signupBodySchool else {
        margin-top: 35px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-signupBodySchool {
      margin-top: 4.973vw; }
      .signup-page-signupBodySchool else {
        margin-top: 35px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-signupBodySchool {
      margin-top: 4.44953vw; }
      .signup-page-signupBodySchool else {
        margin-top: 35px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-signupBodySchool {
      margin-top: 4.02576vw; }
      .signup-page-signupBodySchool else {
        margin-top: 35px; } }

.signup-page-showcaseContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0vw 4.83092vw 7.24638vw rgba(0, 23, 31, 0.15);
  margin-top: 8.45411vw;
  border-radius: 2.89855vw;
  padding-top: 3.1401vw;
  padding-left: 4.83092vw;
  background: #fff; }
  @media (min-width: 1300px) {
    .signup-page-showcaseContainer {
      width: 34.94792vw;
      height: 100%;
      padding-top: 1.5625vw;
      padding-left: unset;
      box-shadow: unset;
      margin-top: unset;
      border-radius: unset;
      background: unset; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-showcaseContainer {
      width: 38.83102vw;
      height: 100%;
      padding-top: 1.73611vw;
      padding-left: unset;
      box-shadow: unset;
      margin-top: unset;
      border-radius: unset;
      background: unset; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-showcaseContainer {
      width: 34.94792vw;
      height: 100%;
      padding-top: 1.5625vw;
      padding-left: unset;
      box-shadow: unset;
      margin-top: unset;
      border-radius: unset;
      background: unset; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-showcaseContainer {
      box-shadow: 0vw 4.02576vw 6.03865vw rgba(0, 23, 31, 0.15);
      margin-top: 7.04509vw;
      border-radius: 2.41546vw;
      padding-top: 2.61675vw;
      padding-left: 4.02576vw; }
      .signup-page-showcaseContainer else {
        margin-top: 35px; }
      .signup-page-showcaseContainer else {
        border-radius: 12px; }
      .signup-page-showcaseContainer else {
        padding-top: 13px; }
      .signup-page-showcaseContainer else {
        padding-left: 20px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-showcaseContainer {
      box-shadow: 0vw 3.22061vw 4.83092vw rgba(0, 23, 31, 0.15);
      margin-top: 5.63607vw;
      border-radius: 1.93237vw;
      padding-top: 2.0934vw;
      padding-left: 3.22061vw; }
      .signup-page-showcaseContainer else {
        margin-top: 35px; }
      .signup-page-showcaseContainer else {
        border-radius: 12px; }
      .signup-page-showcaseContainer else {
        padding-top: 13px; }
      .signup-page-showcaseContainer else {
        padding-left: 20px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-showcaseContainer {
      box-shadow: 0vw 2.84172vw 4.26257vw rgba(0, 23, 31, 0.15);
      margin-top: 4.973vw;
      border-radius: 1.70503vw;
      padding-top: 1.84712vw;
      padding-left: 2.84172vw; }
      .signup-page-showcaseContainer else {
        margin-top: 35px; }
      .signup-page-showcaseContainer else {
        border-radius: 12px; }
      .signup-page-showcaseContainer else {
        padding-top: 13px; }
      .signup-page-showcaseContainer else {
        padding-left: 20px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-showcaseContainer {
      box-shadow: 0vw 2.54259vw 3.81388vw rgba(0, 23, 31, 0.15);
      margin-top: 4.44953vw;
      border-radius: 1.52555vw;
      padding-top: 1.65268vw;
      padding-left: 2.54259vw; }
      .signup-page-showcaseContainer else {
        margin-top: 35px; }
      .signup-page-showcaseContainer else {
        border-radius: 12px; }
      .signup-page-showcaseContainer else {
        padding-top: 13px; }
      .signup-page-showcaseContainer else {
        padding-left: 20px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-showcaseContainer {
      box-shadow: 0vw 2.30044vw 3.45066vw rgba(0, 23, 31, 0.15);
      margin-top: 4.02576vw;
      border-radius: 1.38026vw;
      padding-top: 1.49528vw;
      padding-left: 2.30044vw; }
      .signup-page-showcaseContainer else {
        margin-top: 35px; }
      .signup-page-showcaseContainer else {
        border-radius: 12px; }
      .signup-page-showcaseContainer else {
        padding-top: 13px; }
      .signup-page-showcaseContainer else {
        padding-left: 20px; } }

.signup-page-indicatorRow {
  display: flex;
  flex-direction: row; }

.signup-page-indicator {
  border-radius: 50%;
  margin-top: 0vw;
  width: 3.38164vw;
  height: 3.38164vw;
  margin-left: 2.89855vw;
  margin-bottom: 4.83092vw;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  box-shadow: 0 0 0 0px #70F0FF; }
  @media (min-width: 1300px) {
    .signup-page-indicator {
      margin-top: 1.5625vw;
      width: 0.9375vw;
      height: 0.9375vw;
      margin-left: 0.78125vw;
      margin-bottom: 0vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-indicator {
      margin-top: 1.73611vw;
      width: 1.04167vw;
      height: 1.04167vw;
      margin-left: 0.86806vw;
      margin-bottom: 0vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-indicator {
      margin-top: 1.5625vw;
      width: 0.9375vw;
      height: 0.9375vw;
      margin-left: 0.78125vw;
      margin-bottom: 0vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-indicator {
      margin-top: 0vw;
      width: 2.81804vw;
      height: 2.81804vw;
      margin-left: 2.41546vw;
      margin-bottom: 4.02576vw; }
      .signup-page-indicator else {
        margin-top: 0px; }
      .signup-page-indicator else {
        width: 14px; }
      .signup-page-indicator else {
        height: 14px; }
      .signup-page-indicator else {
        margin-left: 12px; }
      .signup-page-indicator else {
        margin-bottom: 20px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-indicator {
      margin-top: 0vw;
      width: 2.25443vw;
      height: 2.25443vw;
      margin-left: 1.93237vw;
      margin-bottom: 3.22061vw; }
      .signup-page-indicator else {
        margin-top: 0px; }
      .signup-page-indicator else {
        width: 14px; }
      .signup-page-indicator else {
        height: 14px; }
      .signup-page-indicator else {
        margin-left: 12px; }
      .signup-page-indicator else {
        margin-bottom: 20px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-indicator {
      margin-top: 0vw;
      width: 1.9892vw;
      height: 1.9892vw;
      margin-left: 1.70503vw;
      margin-bottom: 2.84172vw; }
      .signup-page-indicator else {
        margin-top: 0px; }
      .signup-page-indicator else {
        width: 14px; }
      .signup-page-indicator else {
        height: 14px; }
      .signup-page-indicator else {
        margin-left: 12px; }
      .signup-page-indicator else {
        margin-bottom: 20px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-indicator {
      margin-top: 0vw;
      width: 1.77981vw;
      height: 1.77981vw;
      margin-left: 1.52555vw;
      margin-bottom: 2.54259vw; }
      .signup-page-indicator else {
        margin-top: 0px; }
      .signup-page-indicator else {
        width: 14px; }
      .signup-page-indicator else {
        height: 14px; }
      .signup-page-indicator else {
        margin-left: 12px; }
      .signup-page-indicator else {
        margin-bottom: 20px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-indicator {
      margin-top: 0vw;
      width: 1.61031vw;
      height: 1.61031vw;
      margin-left: 1.38026vw;
      margin-bottom: 2.30044vw; }
      .signup-page-indicator else {
        margin-top: 0px; }
      .signup-page-indicator else {
        width: 14px; }
      .signup-page-indicator else {
        height: 14px; }
      .signup-page-indicator else {
        margin-left: 12px; }
      .signup-page-indicator else {
        margin-bottom: 20px; } }
  .signup-page-indicator:hover {
    box-shadow: 0 0 0 2px #70F0FF; }
  .signup-page-indicator:active, .signup-page-indicator:focus {
    box-shadow: 0 0 0 0px #70F0FF; }

.signup-page-slideTitle {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  color: #00ADE5;
  opacity: 0.8;
  font-size: 4.34783vw;
  line-height: 6.03865vw; }
  @media (min-width: 1300px) {
    .signup-page-slideTitle {
      font-size: 1.30208vw;
      line-height: 1.77083vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-slideTitle {
      font-size: 1.44676vw;
      line-height: 1.96759vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-slideTitle {
      font-size: 1.30208vw;
      line-height: 1.77083vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-slideTitle {
      font-size: 3.62319vw;
      line-height: 5.03221vw; }
      .signup-page-slideTitle else {
        font-size: 18px; }
      .signup-page-slideTitle else {
        line-height: 25px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-slideTitle {
      font-size: 2.89855vw;
      line-height: 4.02576vw; }
      .signup-page-slideTitle else {
        font-size: 18px; }
      .signup-page-slideTitle else {
        line-height: 25px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-slideTitle {
      font-size: 2.55754vw;
      line-height: 3.55215vw; }
      .signup-page-slideTitle else {
        font-size: 18px; }
      .signup-page-slideTitle else {
        line-height: 25px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-slideTitle {
      font-size: 2.28833vw;
      line-height: 3.17824vw; }
      .signup-page-slideTitle else {
        font-size: 18px; }
      .signup-page-slideTitle else {
        line-height: 25px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-slideTitle {
      font-size: 2.07039vw;
      line-height: 2.87555vw; }
      .signup-page-slideTitle else {
        font-size: 18px; }
      .signup-page-slideTitle else {
        line-height: 25px; } }

.signup-page-dropTestimonial {
  position: relative;
  width: 13.52657vw;
  height: 22.22222vw;
  margin-top: 3.62319vw; }
  @media (min-width: 1300px) {
    .signup-page-dropTestimonial {
      width: 5.26042vw;
      height: 7.86458vw;
      margin-top: 3.90625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-dropTestimonial {
      width: 5.84491vw;
      height: 8.73843vw;
      margin-top: 4.34028vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-dropTestimonial {
      width: 5.26042vw;
      height: 7.86458vw;
      margin-top: 3.90625vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-dropTestimonial {
      width: 11.27214vw;
      height: 18.51852vw;
      margin-top: 3.01932vw; }
      .signup-page-dropTestimonial else {
        width: 56px; }
      .signup-page-dropTestimonial else {
        height: 92px; }
      .signup-page-dropTestimonial else {
        margin-top: 15px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-dropTestimonial {
      width: 9.01771vw;
      height: 14.81481vw;
      margin-top: 2.41546vw; }
      .signup-page-dropTestimonial else {
        width: 56px; }
      .signup-page-dropTestimonial else {
        height: 92px; }
      .signup-page-dropTestimonial else {
        margin-top: 15px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-dropTestimonial {
      width: 7.95681vw;
      height: 13.0719vw;
      margin-top: 2.13129vw; }
      .signup-page-dropTestimonial else {
        width: 56px; }
      .signup-page-dropTestimonial else {
        height: 92px; }
      .signup-page-dropTestimonial else {
        margin-top: 15px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-dropTestimonial {
      width: 7.11925vw;
      height: 11.69591vw;
      margin-top: 1.90694vw; }
      .signup-page-dropTestimonial else {
        width: 56px; }
      .signup-page-dropTestimonial else {
        height: 92px; }
      .signup-page-dropTestimonial else {
        margin-top: 15px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-dropTestimonial {
      width: 6.44122vw;
      height: 10.58201vw;
      margin-top: 1.72533vw; }
      .signup-page-dropTestimonial else {
        width: 56px; }
      .signup-page-dropTestimonial else {
        height: 92px; }
      .signup-page-dropTestimonial else {
        margin-top: 15px; } }

.signup-page-testimonialText {
  font-family: Nunito;
  font-style: italic;
  font-weight: normal;
  line-height: 159.4%;
  color: #504F4F;
  opacity: 0.75;
  font-size: 3.38164vw;
  margin-top: 2.89855vw;
  margin-right: 4.83092vw; }
  @media (min-width: 1300px) {
    .signup-page-testimonialText {
      font-size: 0.88542vw;
      margin-top: 0.78125vw;
      margin-right: unset; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-testimonialText {
      font-size: 0.9838vw;
      margin-top: 0.86806vw;
      margin-right: unset; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-testimonialText {
      font-size: 0.88542vw;
      margin-top: 0.78125vw;
      margin-right: unset; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-testimonialText {
      font-size: 2.81804vw;
      margin-top: 2.41546vw;
      margin-right: 4.02576vw; }
      .signup-page-testimonialText else {
        font-size: 14px; }
      .signup-page-testimonialText else {
        margin-top: 12px; }
      .signup-page-testimonialText else {
        margin-right: 20px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-testimonialText {
      font-size: 2.25443vw;
      margin-top: 1.93237vw;
      margin-right: 3.22061vw; }
      .signup-page-testimonialText else {
        font-size: 14px; }
      .signup-page-testimonialText else {
        margin-top: 12px; }
      .signup-page-testimonialText else {
        margin-right: 20px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-testimonialText {
      font-size: 1.9892vw;
      margin-top: 1.70503vw;
      margin-right: 2.84172vw; }
      .signup-page-testimonialText else {
        font-size: 14px; }
      .signup-page-testimonialText else {
        margin-top: 12px; }
      .signup-page-testimonialText else {
        margin-right: 20px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-testimonialText {
      font-size: 1.77981vw;
      margin-top: 1.52555vw;
      margin-right: 2.54259vw; }
      .signup-page-testimonialText else {
        font-size: 14px; }
      .signup-page-testimonialText else {
        margin-top: 12px; }
      .signup-page-testimonialText else {
        margin-right: 20px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-testimonialText {
      font-size: 1.61031vw;
      margin-top: 1.38026vw;
      margin-right: 2.30044vw; }
      .signup-page-testimonialText else {
        font-size: 14px; }
      .signup-page-testimonialText else {
        margin-top: 12px; }
      .signup-page-testimonialText else {
        margin-right: 20px; } }

.signup-page-testimonialAuthor {
  font-family: Nunito;
  font-weight: 700;
  color: #504F4F;
  opacity: 0.75;
  line-height: 159.4%;
  font-size: 3.38164vw;
  margin-top: 3.38164vw; }
  @media (min-width: 1300px) {
    .signup-page-testimonialAuthor {
      font-size: 0.88542vw;
      margin-top: 0.52083vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-testimonialAuthor {
      font-size: 0.9838vw;
      margin-top: 0.5787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-testimonialAuthor {
      font-size: 0.88542vw;
      margin-top: 0.52083vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-testimonialAuthor {
      font-size: 2.81804vw;
      margin-top: 2.81804vw; }
      .signup-page-testimonialAuthor else {
        font-size: 14px; }
      .signup-page-testimonialAuthor else {
        margin-top: 14px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-testimonialAuthor {
      font-size: 2.25443vw;
      margin-top: 2.25443vw; }
      .signup-page-testimonialAuthor else {
        font-size: 14px; }
      .signup-page-testimonialAuthor else {
        margin-top: 14px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-testimonialAuthor {
      font-size: 1.9892vw;
      margin-top: 1.9892vw; }
      .signup-page-testimonialAuthor else {
        font-size: 14px; }
      .signup-page-testimonialAuthor else {
        margin-top: 14px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-testimonialAuthor {
      font-size: 1.77981vw;
      margin-top: 1.77981vw; }
      .signup-page-testimonialAuthor else {
        font-size: 14px; }
      .signup-page-testimonialAuthor else {
        margin-top: 14px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-testimonialAuthor {
      font-size: 1.61031vw;
      margin-top: 1.61031vw; }
      .signup-page-testimonialAuthor else {
        font-size: 14px; }
      .signup-page-testimonialAuthor else {
        margin-top: 14px; } }

.signup-page-testimonialAuthorLocation {
  font-family: Nunito;
  font-weight: 600;
  color: #504F4F;
  opacity: 0.75;
  line-height: 159.4%;
  font-size: 3.38164vw; }
  @media (min-width: 1300px) {
    .signup-page-testimonialAuthorLocation {
      font-size: 0.88542vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-testimonialAuthorLocation {
      font-size: 0.9838vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-testimonialAuthorLocation {
      font-size: 0.88542vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-testimonialAuthorLocation {
      font-size: 2.81804vw; }
      .signup-page-testimonialAuthorLocation else {
        font-size: 14px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-testimonialAuthorLocation {
      font-size: 2.25443vw; }
      .signup-page-testimonialAuthorLocation else {
        font-size: 14px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-testimonialAuthorLocation {
      font-size: 1.9892vw; }
      .signup-page-testimonialAuthorLocation else {
        font-size: 14px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-testimonialAuthorLocation {
      font-size: 1.77981vw; }
      .signup-page-testimonialAuthorLocation else {
        font-size: 14px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-testimonialAuthorLocation {
      font-size: 1.61031vw; }
      .signup-page-testimonialAuthorLocation else {
        font-size: 14px; } }

.signup-page-slideContainer {
  position: relative;
  pointer-events: none;
  width: 100%;
  height: 84.78261vw; }
  @media (min-width: 1300px) {
    .signup-page-slideContainer {
      width: 28.33333vw;
      height: 29.47917vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-slideContainer {
      width: 31.48148vw;
      height: 32.75463vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-slideContainer {
      width: 28.33333vw;
      height: 29.47917vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-slideContainer {
      height: 70.65217vw; }
      .signup-page-slideContainer else {
        width: 100%; }
      .signup-page-slideContainer else {
        height: 351px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-slideContainer {
      height: 56.52174vw; }
      .signup-page-slideContainer else {
        width: 100%; }
      .signup-page-slideContainer else {
        height: 351px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-slideContainer {
      height: 49.87212vw; }
      .signup-page-slideContainer else {
        width: 100%; }
      .signup-page-slideContainer else {
        height: 351px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-slideContainer {
      height: 44.62243vw; }
      .signup-page-slideContainer else {
        width: 100%; }
      .signup-page-slideContainer else {
        height: 351px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-slideContainer {
      height: 40.37267vw; }
      .signup-page-slideContainer else {
        width: 100%; }
      .signup-page-slideContainer else {
        height: 351px; } }

.signup-page-slideWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; }

.signup-page-teamBanner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 41.0628vw;
  margin-top: 7.24638vw;
  background: white; }
  @media (min-width: 1300px) {
    .signup-page-teamBanner {
      min-height: 7.55208vw;
      margin-top: auto;
      background: white; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-teamBanner {
      min-height: 8.3912vw;
      margin-top: auto;
      background: white; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-teamBanner {
      min-height: 7.55208vw;
      margin-top: auto;
      background: white; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-teamBanner {
      min-height: 34.219vw;
      margin-top: 6.03865vw; }
      .signup-page-teamBanner else {
        min-height: 170px; }
      .signup-page-teamBanner else {
        margin-top: 30px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-teamBanner {
      min-height: 27.3752vw;
      margin-top: 4.83092vw; }
      .signup-page-teamBanner else {
        min-height: 170px; }
      .signup-page-teamBanner else {
        margin-top: 30px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-teamBanner {
      min-height: 24.15459vw;
      margin-top: 4.26257vw; }
      .signup-page-teamBanner else {
        min-height: 170px; }
      .signup-page-teamBanner else {
        margin-top: 30px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-teamBanner {
      min-height: 21.612vw;
      margin-top: 3.81388vw; }
      .signup-page-teamBanner else {
        min-height: 170px; }
      .signup-page-teamBanner else {
        margin-top: 30px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-teamBanner {
      min-height: 19.55372vw;
      margin-top: 3.45066vw; }
      .signup-page-teamBanner else {
        min-height: 170px; }
      .signup-page-teamBanner else {
        margin-top: 30px; } }

.signup-page-teamRowWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media (min-width: 1300px) {
    .signup-page-teamRowWrapper {
      flex-direction: row; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-teamRowWrapper {
      flex-direction: row; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-teamRowWrapper {
      flex-direction: row; } }

.signup-page-icon, .signup-page-textIcon, .signup-page-googleIcon, .signup-page-intelIcon, .signup-page-microsoftIcon, .signup-page-amazonIcon {
  margin-right: 8.69565vw; }
  @media (min-width: 1300px) {
    .signup-page-icon, .signup-page-textIcon, .signup-page-googleIcon, .signup-page-intelIcon, .signup-page-microsoftIcon, .signup-page-amazonIcon {
      margin-right: 6.97917vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-icon, .signup-page-textIcon, .signup-page-googleIcon, .signup-page-intelIcon, .signup-page-microsoftIcon, .signup-page-amazonIcon {
      margin-right: 7.75463vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-icon, .signup-page-textIcon, .signup-page-googleIcon, .signup-page-intelIcon, .signup-page-microsoftIcon, .signup-page-amazonIcon {
      margin-right: 6.97917vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-icon, .signup-page-textIcon, .signup-page-googleIcon, .signup-page-intelIcon, .signup-page-microsoftIcon, .signup-page-amazonIcon {
      margin-right: 7.24638vw; }
      .signup-page-icon else, .signup-page-textIcon else, .signup-page-googleIcon else, .signup-page-intelIcon else, .signup-page-microsoftIcon else, .signup-page-amazonIcon else {
        margin-right: 36px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-icon, .signup-page-textIcon, .signup-page-googleIcon, .signup-page-intelIcon, .signup-page-microsoftIcon, .signup-page-amazonIcon {
      margin-right: 5.7971vw; }
      .signup-page-icon else, .signup-page-textIcon else, .signup-page-googleIcon else, .signup-page-intelIcon else, .signup-page-microsoftIcon else, .signup-page-amazonIcon else {
        margin-right: 36px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-icon, .signup-page-textIcon, .signup-page-googleIcon, .signup-page-intelIcon, .signup-page-microsoftIcon, .signup-page-amazonIcon {
      margin-right: 5.11509vw; }
      .signup-page-icon else, .signup-page-textIcon else, .signup-page-googleIcon else, .signup-page-intelIcon else, .signup-page-microsoftIcon else, .signup-page-amazonIcon else {
        margin-right: 36px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-icon, .signup-page-textIcon, .signup-page-googleIcon, .signup-page-intelIcon, .signup-page-microsoftIcon, .signup-page-amazonIcon {
      margin-right: 4.57666vw; }
      .signup-page-icon else, .signup-page-textIcon else, .signup-page-googleIcon else, .signup-page-intelIcon else, .signup-page-microsoftIcon else, .signup-page-amazonIcon else {
        margin-right: 36px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-icon, .signup-page-textIcon, .signup-page-googleIcon, .signup-page-intelIcon, .signup-page-microsoftIcon, .signup-page-amazonIcon {
      margin-right: 4.14079vw; }
      .signup-page-icon else, .signup-page-textIcon else, .signup-page-googleIcon else, .signup-page-intelIcon else, .signup-page-microsoftIcon else, .signup-page-amazonIcon else {
        margin-right: 36px; } }

.signup-page-textIcon {
  font-family: Lato;
  font-weight: bold;
  color: #737373;
  opacity: 0.8;
  font-size: 3.62319vw;
  line-height: 4.34783vw; }
  @media (min-width: 1300px) {
    .signup-page-textIcon {
      font-size: 1.30208vw;
      line-height: 1.5625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-textIcon {
      font-size: 1.44676vw;
      line-height: 1.73611vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-textIcon {
      font-size: 1.30208vw;
      line-height: 1.5625vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-textIcon {
      font-size: 3.01932vw;
      line-height: 3.62319vw; }
      .signup-page-textIcon else {
        font-size: 15px; }
      .signup-page-textIcon else {
        line-height: 18px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-textIcon {
      font-size: 2.41546vw;
      line-height: 2.89855vw; }
      .signup-page-textIcon else {
        font-size: 15px; }
      .signup-page-textIcon else {
        line-height: 18px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-textIcon {
      font-size: 2.13129vw;
      line-height: 2.55754vw; }
      .signup-page-textIcon else {
        font-size: 15px; }
      .signup-page-textIcon else {
        line-height: 18px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-textIcon {
      font-size: 1.90694vw;
      line-height: 2.28833vw; }
      .signup-page-textIcon else {
        font-size: 15px; }
      .signup-page-textIcon else {
        line-height: 18px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-textIcon {
      font-size: 1.72533vw;
      line-height: 2.07039vw; }
      .signup-page-textIcon else {
        font-size: 15px; }
      .signup-page-textIcon else {
        line-height: 18px; } }

.signup-page-googleIcon {
  width: 5.07246vw;
  height: 5.07246vw; }
  @media (min-width: 1300px) {
    .signup-page-googleIcon {
      width: 1.5625vw;
      height: 1.5625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-googleIcon {
      width: 1.73611vw;
      height: 1.73611vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-googleIcon {
      width: 1.5625vw;
      height: 1.5625vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-googleIcon {
      width: 4.22705vw;
      height: 4.22705vw; }
      .signup-page-googleIcon else {
        width: 21px; }
      .signup-page-googleIcon else {
        height: 21px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-googleIcon {
      width: 3.38164vw;
      height: 3.38164vw; }
      .signup-page-googleIcon else {
        width: 21px; }
      .signup-page-googleIcon else {
        height: 21px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-googleIcon {
      width: 2.9838vw;
      height: 2.9838vw; }
      .signup-page-googleIcon else {
        width: 21px; }
      .signup-page-googleIcon else {
        height: 21px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-googleIcon {
      width: 2.66972vw;
      height: 2.66972vw; }
      .signup-page-googleIcon else {
        width: 21px; }
      .signup-page-googleIcon else {
        height: 21px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-googleIcon {
      width: 2.41546vw;
      height: 2.41546vw; }
      .signup-page-googleIcon else {
        width: 21px; }
      .signup-page-googleIcon else {
        height: 21px; } }

.signup-page-intelIcon {
  width: 10.38647vw;
  height: 4.83092vw; }
  @media (min-width: 1300px) {
    .signup-page-intelIcon {
      width: 3.33333vw;
      height: 1.5625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-intelIcon {
      width: 3.7037vw;
      height: 1.73611vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-intelIcon {
      width: 3.33333vw;
      height: 1.5625vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-intelIcon {
      width: 8.65539vw;
      height: 4.02576vw; }
      .signup-page-intelIcon else {
        width: 43px; }
      .signup-page-intelIcon else {
        height: 20px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-intelIcon {
      width: 6.92432vw;
      height: 3.22061vw; }
      .signup-page-intelIcon else {
        width: 43px; }
      .signup-page-intelIcon else {
        height: 20px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-intelIcon {
      width: 6.10969vw;
      height: 2.84172vw; }
      .signup-page-intelIcon else {
        width: 43px; }
      .signup-page-intelIcon else {
        height: 20px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-intelIcon {
      width: 5.46656vw;
      height: 2.54259vw; }
      .signup-page-intelIcon else {
        width: 43px; }
      .signup-page-intelIcon else {
        height: 20px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-intelIcon {
      width: 4.94594vw;
      height: 2.30044vw; }
      .signup-page-intelIcon else {
        width: 43px; }
      .signup-page-intelIcon else {
        height: 20px; } }

.signup-page-microsoftIcon {
  width: 19.32367vw;
  height: 4.10628vw; }
  @media (min-width: 1300px) {
    .signup-page-microsoftIcon {
      width: 6.09375vw;
      height: 1.30208vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-microsoftIcon {
      width: 6.77083vw;
      height: 1.44676vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-microsoftIcon {
      width: 6.09375vw;
      height: 1.30208vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-microsoftIcon {
      width: 16.10306vw;
      height: 3.4219vw; }
      .signup-page-microsoftIcon else {
        width: 80px; }
      .signup-page-microsoftIcon else {
        height: 17px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-microsoftIcon {
      width: 12.88245vw;
      height: 2.73752vw; }
      .signup-page-microsoftIcon else {
        width: 80px; }
      .signup-page-microsoftIcon else {
        height: 17px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-microsoftIcon {
      width: 11.36687vw;
      height: 2.41546vw; }
      .signup-page-microsoftIcon else {
        width: 80px; }
      .signup-page-microsoftIcon else {
        height: 17px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-microsoftIcon {
      width: 10.17035vw;
      height: 2.1612vw; }
      .signup-page-microsoftIcon else {
        width: 80px; }
      .signup-page-microsoftIcon else {
        height: 17px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-microsoftIcon {
      width: 9.20175vw;
      height: 1.95537vw; }
      .signup-page-microsoftIcon else {
        width: 80px; }
      .signup-page-microsoftIcon else {
        height: 17px; } }

.signup-page-amazonIcon {
  width: 13.76812vw;
  height: 4.10628vw; }
  @media (min-width: 1300px) {
    .signup-page-amazonIcon {
      width: 4.32292vw;
      height: 1.30208vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-amazonIcon {
      width: 4.80324vw;
      height: 1.44676vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-amazonIcon {
      width: 4.32292vw;
      height: 1.30208vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-amazonIcon {
      width: 11.47343vw;
      height: 3.4219vw; }
      .signup-page-amazonIcon else {
        width: 57px; }
      .signup-page-amazonIcon else {
        height: 17px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-amazonIcon {
      width: 9.17874vw;
      height: 2.73752vw; }
      .signup-page-amazonIcon else {
        width: 57px; }
      .signup-page-amazonIcon else {
        height: 17px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-amazonIcon {
      width: 8.09889vw;
      height: 2.41546vw; }
      .signup-page-amazonIcon else {
        width: 57px; }
      .signup-page-amazonIcon else {
        height: 17px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-amazonIcon {
      width: 7.24638vw;
      height: 2.1612vw; }
      .signup-page-amazonIcon else {
        width: 57px; }
      .signup-page-amazonIcon else {
        height: 17px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-amazonIcon {
      width: 6.55625vw;
      height: 1.95537vw; }
      .signup-page-amazonIcon else {
        width: 57px; }
      .signup-page-amazonIcon else {
        height: 17px; } }

.signup-page-teamTitle {
  font-family: Lato;
  text-transform: uppercase;
  color: #504F4F;
  opacity: 0.5;
  line-height: 4.10628vw;
  font-size: 3.38164vw;
  margin-top: 3.62319vw;
  letter-spacing: 0.2657vw; }
  @media (min-width: 1300px) {
    .signup-page-teamTitle {
      line-height: 1.25vw;
      font-size: 1.04167vw;
      margin-top: 1.5625vw;
      letter-spacing: 0.05729vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-teamTitle {
      line-height: 1.38889vw;
      font-size: 1.15741vw;
      margin-top: 1.73611vw;
      letter-spacing: 0.06366vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-teamTitle {
      line-height: 1.25vw;
      font-size: 1.04167vw;
      margin-top: 1.5625vw;
      letter-spacing: 0.05729vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-teamTitle {
      line-height: 3.4219vw;
      font-size: 2.81804vw;
      margin-top: 3.01932vw;
      letter-spacing: 0.22142vw; }
      .signup-page-teamTitle else {
        line-height: 17px; }
      .signup-page-teamTitle else {
        font-size: 14px; }
      .signup-page-teamTitle else {
        margin-top: 15px; }
      .signup-page-teamTitle else {
        letter-spacing: 1.1px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-teamTitle {
      line-height: 2.73752vw;
      font-size: 2.25443vw;
      margin-top: 2.41546vw;
      letter-spacing: 0.17713vw; }
      .signup-page-teamTitle else {
        line-height: 17px; }
      .signup-page-teamTitle else {
        font-size: 14px; }
      .signup-page-teamTitle else {
        margin-top: 15px; }
      .signup-page-teamTitle else {
        letter-spacing: 1.1px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-teamTitle {
      line-height: 2.41546vw;
      font-size: 1.9892vw;
      margin-top: 2.13129vw;
      letter-spacing: 0.15629vw; }
      .signup-page-teamTitle else {
        line-height: 17px; }
      .signup-page-teamTitle else {
        font-size: 14px; }
      .signup-page-teamTitle else {
        margin-top: 15px; }
      .signup-page-teamTitle else {
        letter-spacing: 1.1px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-teamTitle {
      line-height: 2.1612vw;
      font-size: 1.77981vw;
      margin-top: 1.90694vw;
      letter-spacing: 0.13984vw; }
      .signup-page-teamTitle else {
        line-height: 17px; }
      .signup-page-teamTitle else {
        font-size: 14px; }
      .signup-page-teamTitle else {
        margin-top: 15px; }
      .signup-page-teamTitle else {
        letter-spacing: 1.1px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-teamTitle {
      line-height: 1.95537vw;
      font-size: 1.61031vw;
      margin-top: 1.72533vw;
      letter-spacing: 0.12652vw; }
      .signup-page-teamTitle else {
        line-height: 17px; }
      .signup-page-teamTitle else {
        font-size: 14px; }
      .signup-page-teamTitle else {
        margin-top: 15px; }
      .signup-page-teamTitle else {
        letter-spacing: 1.1px; } }

.signup-page-teamRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 6.52174vw; }
  @media (min-width: 1300px) {
    .signup-page-teamRow {
      margin-top: 1.5625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-teamRow {
      margin-top: 1.73611vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-teamRow {
      margin-top: 1.5625vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-teamRow {
      margin-top: 5.43478vw; }
      .signup-page-teamRow else {
        margin-top: 27px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-teamRow {
      margin-top: 4.34783vw; }
      .signup-page-teamRow else {
        margin-top: 27px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-teamRow {
      margin-top: 3.83632vw; }
      .signup-page-teamRow else {
        margin-top: 27px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-teamRow {
      margin-top: 3.43249vw; }
      .signup-page-teamRow else {
        margin-top: 27px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-teamRow {
      margin-top: 3.10559vw; }
      .signup-page-teamRow else {
        margin-top: 27px; } }

.signup-page-noMarginRightMobile {
  margin-right: 0; }
  @media (min-width: 1300px) {
    .signup-page-noMarginRightMobile {
      margin-right: 6.97917vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-noMarginRightMobile {
      margin-right: 7.75463vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-noMarginRightMobile {
      margin-right: 6.97917vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-noMarginRightMobile else {
      margin-right: 0; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-noMarginRightMobile else {
      margin-right: 0; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-noMarginRightMobile else {
      margin-right: 0; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-noMarginRightMobile else {
      margin-right: 0; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-noMarginRightMobile else {
      margin-right: 0; } }

.signup-page-signupLine {
  opacity: 0.5;
  background-color: #6AD1F0;
  display: none; }
  @media (min-width: 1300px) {
    .signup-page-signupLine {
      width: 0.0625vw;
      height: 32.5vw;
      margin-top: 1.5625vw;
      margin-right: 3.125vw;
      display: block; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-signupLine {
      width: 0.06944vw;
      height: 36.11111vw;
      margin-top: 1.73611vw;
      margin-right: 3.47222vw;
      display: block; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-signupLine {
      width: 0.0625vw;
      height: 32.5vw;
      margin-top: 1.5625vw;
      margin-right: 3.125vw;
      display: block; } }

.signup-page-signupFormBody {
  box-sizing: border-box;
  margin-top: -3.86473vw;
  border-radius: 2.89855vw;
  padding: 3.38164vw 4.83092vw;
  box-shadow: 0vw 4.83092vw 7.24638vw rgba(0, 23, 31, 0.15);
  background: #fff; }
  @media (min-width: 1300px) {
    .signup-page-signupFormBody {
      margin-top: 1.5625vw;
      padding: unset;
      border-radius: 0vw;
      box-shadow: unset;
      background: transparent; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-signupFormBody {
      margin-top: 1.73611vw;
      padding: unset;
      border-radius: 0vw;
      box-shadow: unset;
      background: transparent; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-signupFormBody {
      margin-top: 1.5625vw;
      padding: unset;
      border-radius: 0vw;
      box-shadow: unset;
      background: transparent; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-signupFormBody {
      margin-top: -3.22061vw;
      border-radius: 2.41546vw;
      padding: 2.81804vw 4.02576vw;
      box-shadow: 0vw 4.02576vw 6.03865vw rgba(0, 23, 31, 0.15); }
      .signup-page-signupFormBody else {
        margin-top: -16px; }
      .signup-page-signupFormBody else {
        border-radius: 12px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-signupFormBody {
      margin-top: -2.57649vw;
      border-radius: 1.93237vw;
      padding: 2.25443vw 3.22061vw;
      box-shadow: 0vw 3.22061vw 4.83092vw rgba(0, 23, 31, 0.15); }
      .signup-page-signupFormBody else {
        margin-top: -16px; }
      .signup-page-signupFormBody else {
        border-radius: 12px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-signupFormBody {
      margin-top: -2.27337vw;
      border-radius: 1.70503vw;
      padding: 1.9892vw 2.84172vw;
      box-shadow: 0vw 2.84172vw 4.26257vw rgba(0, 23, 31, 0.15); }
      .signup-page-signupFormBody else {
        margin-top: -16px; }
      .signup-page-signupFormBody else {
        border-radius: 12px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-signupFormBody {
      margin-top: -2.03407vw;
      border-radius: 1.52555vw;
      padding: 1.77981vw 2.54259vw;
      box-shadow: 0vw 2.54259vw 3.81388vw rgba(0, 23, 31, 0.15); }
      .signup-page-signupFormBody else {
        margin-top: -16px; }
      .signup-page-signupFormBody else {
        border-radius: 12px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-signupFormBody {
      margin-top: -1.84035vw;
      border-radius: 1.38026vw;
      padding: 1.61031vw 2.30044vw;
      box-shadow: 0vw 2.30044vw 3.45066vw rgba(0, 23, 31, 0.15); }
      .signup-page-signupFormBody else {
        margin-top: -16px; }
      .signup-page-signupFormBody else {
        border-radius: 12px; } }

.signup-page-input, .signup-page-inputSelectable, .signup-page-phoneInput {
  background-color: #fff !important;
  color: #111111 !important;
  border: none !important;
  outline: none !important;
  font-family: Nunito !important;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);
  font-size: 3.38164vw !important;
  width: 78.50242vw !important;
  height: 10.14493vw !important;
  border-radius: 0.96618vw !important;
  margin-top: 4.10628vw;
  padding-left: 2.657vw !important; }
  .signup-page-input.signup-page-inputError, .signup-page-inputError.signup-page-inputSelectable, .signup-page-inputError.signup-page-phoneInput {
    background-color: #F9E3E3 !important; }
  @media (min-width: 1300px) {
    .signup-page-input, .signup-page-inputSelectable, .signup-page-phoneInput {
      font-size: 0.88542vw !important;
      width: 23.95833vw !important;
      height: 2.91667vw !important;
      border-radius: 0.20833vw !important;
      margin-top: 1.5625vw;
      padding-left: 0.83333vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-input, .signup-page-inputSelectable, .signup-page-phoneInput {
      font-size: 0.9838vw !important;
      width: 26.62037vw !important;
      height: 3.24074vw !important;
      border-radius: 0.23148vw !important;
      margin-top: 1.73611vw;
      padding-left: 0.92593vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-input, .signup-page-inputSelectable, .signup-page-phoneInput {
      font-size: 0.88542vw !important;
      width: 23.95833vw !important;
      height: 2.91667vw !important;
      border-radius: 0.20833vw !important;
      margin-top: 1.5625vw;
      padding-left: 0.83333vw !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-input, .signup-page-inputSelectable, .signup-page-phoneInput {
      font-size: 2.81804vw !important;
      width: 65.41868vw !important;
      height: 8.45411vw !important;
      border-radius: 0.80515vw !important;
      margin-top: 3.4219vw;
      padding-left: 2.21417vw !important; }
      .signup-page-input else, .signup-page-inputSelectable else, .signup-page-phoneInput else {
        margin-top: 17px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-input, .signup-page-inputSelectable, .signup-page-phoneInput {
      font-size: 2.25443vw !important;
      width: 52.33494vw !important;
      height: 6.76329vw !important;
      border-radius: 0.64412vw !important;
      margin-top: 2.73752vw;
      padding-left: 1.77134vw !important; }
      .signup-page-input else, .signup-page-inputSelectable else, .signup-page-phoneInput else {
        margin-top: 17px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-input, .signup-page-inputSelectable, .signup-page-phoneInput {
      font-size: 1.9892vw !important;
      width: 46.17789vw !important;
      height: 5.9676vw !important;
      border-radius: 0.56834vw !important;
      margin-top: 2.41546vw;
      padding-left: 1.56294vw !important; }
      .signup-page-input else, .signup-page-inputSelectable else, .signup-page-phoneInput else {
        margin-top: 17px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-input, .signup-page-inputSelectable, .signup-page-phoneInput {
      font-size: 1.77981vw !important;
      width: 41.31706vw !important;
      height: 5.33944vw !important;
      border-radius: 0.50852vw !important;
      margin-top: 2.1612vw;
      padding-left: 1.39842vw !important; }
      .signup-page-input else, .signup-page-inputSelectable else, .signup-page-phoneInput else {
        margin-top: 17px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-input, .signup-page-inputSelectable, .signup-page-phoneInput {
      font-size: 1.61031vw !important;
      width: 37.3821vw !important;
      height: 4.83092vw !important;
      border-radius: 0.46009vw !important;
      margin-top: 1.95537vw;
      padding-left: 1.26524vw !important; }
      .signup-page-input else, .signup-page-inputSelectable else, .signup-page-phoneInput else {
        margin-top: 17px; } }
  .signup-page-input:focus, .signup-page-inputSelectable:focus, .signup-page-phoneInput:focus {
    box-shadow: 0 0 0 2px rgba(0, 173, 229, 0.6), 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08); }
  .signup-page-input::-webkit-input-placeholder, .signup-page-inputSelectable::-webkit-input-placeholder, .signup-page-phoneInput::-webkit-input-placeholder {
    color: rgba(17, 17, 17, 0.48) !important;
    opacity: 0.7 !important; }
  .signup-page-input::placeholder, .signup-page-inputSelectable::placeholder, .signup-page-phoneInput::placeholder {
    color: rgba(17, 17, 17, 0.48) !important;
    opacity: 0.7 !important; }

.signup-page-inputSelectable {
  padding-left: 0vw !important;
  width: 81.64251vw !important; }
  @media (min-width: 1300px) {
    .signup-page-inputSelectable {
      padding-left: 0vw !important;
      width: 25vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-inputSelectable {
      padding-left: 0vw !important;
      width: 27.77778vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-inputSelectable {
      padding-left: 0vw !important;
      width: 25vw !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-inputSelectable {
      padding-left: 0vw !important;
      width: 68.03543vw !important; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-inputSelectable {
      padding-left: 0vw !important;
      width: 54.42834vw !important; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-inputSelectable {
      padding-left: 0vw !important;
      width: 48.02501vw !important; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-inputSelectable {
      padding-left: 0vw !important;
      width: 42.96974vw !important; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-inputSelectable {
      padding-left: 0vw !important;
      width: 38.87739vw !important; } }
  .signup-page-inputSelectable > div {
    border-width: 0px;
    min-height: 10.14493vw !important; }
    @media (min-width: 1300px) {
      .signup-page-inputSelectable > div {
        min-height: 2.86458vw !important; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .signup-page-inputSelectable > div {
        min-height: 3.18287vw !important; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .signup-page-inputSelectable > div {
        min-height: 2.86458vw !important; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .signup-page-inputSelectable > div {
        min-height: 8.45411vw !important; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .signup-page-inputSelectable > div {
        min-height: 6.76329vw !important; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .signup-page-inputSelectable > div {
        min-height: 5.9676vw !important; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .signup-page-inputSelectable > div {
        min-height: 5.33944vw !important; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .signup-page-inputSelectable > div {
        min-height: 4.83092vw !important; } }

.signup-page-dropdownButtonClass {
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);
  background-color: transparent !important;
  transition: 0.3s all ease-in-out;
  border: unset !important;
  width: 10.86957vw;
  font-size: 3.38164vw; }
  @media (min-width: 1300px) {
    .signup-page-dropdownButtonClass {
      border: unset !important;
      width: 3.64583vw;
      font-size: 0.9375vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-dropdownButtonClass {
      border: unset !important;
      width: 4.05093vw;
      font-size: 1.04167vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-dropdownButtonClass {
      border: unset !important;
      width: 3.64583vw;
      font-size: 0.9375vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-dropdownButtonClass {
      border: unset !important;
      width: 9.05797vw;
      font-size: 2.81804vw; }
      .signup-page-dropdownButtonClass else {
        width: 45px; }
      .signup-page-dropdownButtonClass else {
        font-size: 14px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-dropdownButtonClass {
      border: unset !important;
      width: 7.24638vw;
      font-size: 2.25443vw; }
      .signup-page-dropdownButtonClass else {
        width: 45px; }
      .signup-page-dropdownButtonClass else {
        font-size: 14px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-dropdownButtonClass {
      border: unset !important;
      width: 6.39386vw;
      font-size: 1.9892vw; }
      .signup-page-dropdownButtonClass else {
        width: 45px; }
      .signup-page-dropdownButtonClass else {
        font-size: 14px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-dropdownButtonClass {
      border: unset !important;
      width: 5.72082vw;
      font-size: 1.77981vw; }
      .signup-page-dropdownButtonClass else {
        width: 45px; }
      .signup-page-dropdownButtonClass else {
        font-size: 14px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-dropdownButtonClass {
      border: unset !important;
      width: 5.17598vw;
      font-size: 1.61031vw; }
      .signup-page-dropdownButtonClass else {
        width: 45px; }
      .signup-page-dropdownButtonClass else {
        font-size: 14px; } }
  .signup-page-dropdownButtonClass:hover {
    background-color: rgba(0, 173, 229, 0.2) !important; }

.signup-page-signupBodyWrapper {
  display: flex;
  flex: 1 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse; }
  @media (min-width: 1300px) {
    .signup-page-signupBodyWrapper {
      flex-direction: row; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-signupBodyWrapper {
      flex-direction: row; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-signupBodyWrapper {
      flex-direction: row; } }

.signup-page-inputColumn {
  align-self: center; }
  @media (min-width: 1300px) {
    .signup-page-inputColumn {
      align-self: unset; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-inputColumn {
      align-self: unset; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-inputColumn {
      align-self: unset; } }

.signup-page-instructionsContainer {
  display: flex;
  margin-top: 4.83092vw;
  flex-direction: row;
  align-self: center; }
  @media (min-width: 1300px) {
    .signup-page-instructionsContainer {
      margin-top: 5.88542vw;
      flex-direction: column;
      align-self: flex-start; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-instructionsContainer {
      margin-top: 6.53935vw;
      flex-direction: column;
      align-self: flex-start; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-instructionsContainer {
      margin-top: 5.88542vw;
      flex-direction: column;
      align-self: flex-start; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-instructionsContainer {
      margin-top: 4.02576vw; }
      .signup-page-instructionsContainer else {
        margin-top: 20px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-instructionsContainer {
      margin-top: 3.22061vw; }
      .signup-page-instructionsContainer else {
        margin-top: 20px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-instructionsContainer {
      margin-top: 2.84172vw; }
      .signup-page-instructionsContainer else {
        margin-top: 20px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-instructionsContainer {
      margin-top: 2.54259vw; }
      .signup-page-instructionsContainer else {
        margin-top: 20px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-instructionsContainer {
      margin-top: 2.30044vw; }
      .signup-page-instructionsContainer else {
        margin-top: 20px; } }

.signup-page-instructionContainer {
  display: flex;
  flex-direction: row;
  align-items: center; }
  @media (min-width: 1300px) {
    .signup-page-instructionContainer {
      margin-left: -1.40625vw;
      align-items: unset; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-instructionContainer {
      margin-left: -1.5625vw;
      align-items: unset; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-instructionContainer {
      margin-left: -1.40625vw;
      align-items: unset; } }

.signup-page-instructionNumber {
  border-radius: 50%;
  background: linear-gradient(180deg, rgba(52, 228, 234, 0.6) 0%, rgba(0, 173, 230, 0.6) 100%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  color: #FFFFFF;
  min-width: 7.97101vw;
  height: 7.97101vw;
  font-size: 2.89855vw;
  margin-right: unset;
  margin-left: 7.24638vw; }
  @media (min-width: 1300px) {
    .signup-page-instructionNumber {
      margin-right: 0.78125vw;
      font-size: 1.04167vw;
      min-width: 2.8125vw;
      height: 2.8125vw;
      margin-left: unset; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-instructionNumber {
      margin-right: 0.86806vw;
      font-size: 1.15741vw;
      min-width: 3.125vw;
      height: 3.125vw;
      margin-left: unset; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-instructionNumber {
      margin-right: 0.78125vw;
      font-size: 1.04167vw;
      min-width: 2.8125vw;
      height: 2.8125vw;
      margin-left: unset; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-instructionNumber {
      min-width: 6.64251vw;
      height: 6.64251vw;
      font-size: 2.41546vw;
      margin-left: 6.03865vw; }
      .signup-page-instructionNumber else {
        min-width: 33px; }
      .signup-page-instructionNumber else {
        height: 33px; }
      .signup-page-instructionNumber else {
        font-size: 12px; }
      .signup-page-instructionNumber else {
        margin-right: unset; }
      .signup-page-instructionNumber else {
        margin-left: 30px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-instructionNumber {
      min-width: 5.31401vw;
      height: 5.31401vw;
      font-size: 1.93237vw;
      margin-left: 4.83092vw; }
      .signup-page-instructionNumber else {
        min-width: 33px; }
      .signup-page-instructionNumber else {
        height: 33px; }
      .signup-page-instructionNumber else {
        font-size: 12px; }
      .signup-page-instructionNumber else {
        margin-right: unset; }
      .signup-page-instructionNumber else {
        margin-left: 30px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-instructionNumber {
      min-width: 4.68883vw;
      height: 4.68883vw;
      font-size: 1.70503vw;
      margin-left: 4.26257vw; }
      .signup-page-instructionNumber else {
        min-width: 33px; }
      .signup-page-instructionNumber else {
        height: 33px; }
      .signup-page-instructionNumber else {
        font-size: 12px; }
      .signup-page-instructionNumber else {
        margin-right: unset; }
      .signup-page-instructionNumber else {
        margin-left: 30px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-instructionNumber {
      min-width: 4.19527vw;
      height: 4.19527vw;
      font-size: 1.52555vw;
      margin-left: 3.81388vw; }
      .signup-page-instructionNumber else {
        min-width: 33px; }
      .signup-page-instructionNumber else {
        height: 33px; }
      .signup-page-instructionNumber else {
        font-size: 12px; }
      .signup-page-instructionNumber else {
        margin-right: unset; }
      .signup-page-instructionNumber else {
        margin-left: 30px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-instructionNumber {
      min-width: 3.79572vw;
      height: 3.79572vw;
      font-size: 1.38026vw;
      margin-left: 3.45066vw; }
      .signup-page-instructionNumber else {
        min-width: 33px; }
      .signup-page-instructionNumber else {
        height: 33px; }
      .signup-page-instructionNumber else {
        font-size: 12px; }
      .signup-page-instructionNumber else {
        margin-right: unset; }
      .signup-page-instructionNumber else {
        margin-left: 30px; } }

.signup-page-instructionTitle, .signup-page-instructionDescription {
  font-family: Nunito;
  font-weight: 600;
  color: #504F4F;
  line-height: 159.4%;
  margin-left: 2.89855vw;
  font-size: 3.38164vw; }
  @media (min-width: 1300px) {
    .signup-page-instructionTitle, .signup-page-instructionDescription {
      margin-top: 0.26042vw;
      font-size: 1.04167vw;
      margin-left: unset; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-instructionTitle, .signup-page-instructionDescription {
      margin-top: 0.28935vw;
      font-size: 1.15741vw;
      margin-left: unset; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-instructionTitle, .signup-page-instructionDescription {
      margin-top: 0.26042vw;
      font-size: 1.04167vw;
      margin-left: unset; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-instructionTitle, .signup-page-instructionDescription {
      margin-left: 2.41546vw;
      font-size: 2.81804vw; }
      .signup-page-instructionTitle else, .signup-page-instructionDescription else {
        margin-left: 12px; }
      .signup-page-instructionTitle else, .signup-page-instructionDescription else {
        font-size: 14px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-instructionTitle, .signup-page-instructionDescription {
      margin-left: 1.93237vw;
      font-size: 2.25443vw; }
      .signup-page-instructionTitle else, .signup-page-instructionDescription else {
        margin-left: 12px; }
      .signup-page-instructionTitle else, .signup-page-instructionDescription else {
        font-size: 14px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-instructionTitle, .signup-page-instructionDescription {
      margin-left: 1.70503vw;
      font-size: 1.9892vw; }
      .signup-page-instructionTitle else, .signup-page-instructionDescription else {
        margin-left: 12px; }
      .signup-page-instructionTitle else, .signup-page-instructionDescription else {
        font-size: 14px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-instructionTitle, .signup-page-instructionDescription {
      margin-left: 1.52555vw;
      font-size: 1.77981vw; }
      .signup-page-instructionTitle else, .signup-page-instructionDescription else {
        margin-left: 12px; }
      .signup-page-instructionTitle else, .signup-page-instructionDescription else {
        font-size: 14px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-instructionTitle, .signup-page-instructionDescription {
      margin-left: 1.38026vw;
      font-size: 1.61031vw; }
      .signup-page-instructionTitle else, .signup-page-instructionDescription else {
        margin-left: 12px; }
      .signup-page-instructionTitle else, .signup-page-instructionDescription else {
        font-size: 14px; } }

@media (min-width: 1300px) {
  .signup-page-instructionContainerSecond {
    margin-top: 4.6875vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .signup-page-instructionContainerSecond {
    margin-top: 5.20833vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .signup-page-instructionContainerSecond {
    margin-top: 4.6875vw; } }

.signup-page-greyInstructionNumber {
  background: rgba(226, 226, 226, 0.6); }

.signup-page-instructionDescription {
  font-weight: 400;
  display: none; }
  @media (min-width: 1300px) {
    .signup-page-instructionDescription {
      width: 12.1875vw;
      display: block; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-instructionDescription {
      width: 13.54167vw;
      display: block; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-instructionDescription {
      width: 12.1875vw;
      display: block; } }

.signup-page-phoneInput {
  width: 69.08213vw !important;
  margin-left: 12.56039vw !important; }
  @media (min-width: 1300px) {
    .signup-page-phoneInput {
      width: 20.46875vw !important;
      margin-left: 4.42708vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-phoneInput {
      width: 22.74306vw !important;
      margin-left: 4.91898vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-phoneInput {
      width: 20.46875vw !important;
      margin-left: 4.42708vw !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-phoneInput {
      width: 57.56844vw !important;
      margin-left: 10.46699vw !important; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-phoneInput {
      width: 46.05475vw !important;
      margin-left: 8.37359vw !important; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-phoneInput {
      width: 40.63654vw !important;
      margin-left: 7.38846vw !important; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-phoneInput {
      width: 36.35901vw !important;
      margin-left: 6.61073vw !important; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-phoneInput {
      width: 32.89625vw !important;
      margin-left: 5.98114vw !important; } }

.signup-page-phoneInputContainer {
  margin-top: 4.10628vw !important; }
  @media (min-width: 1300px) {
    .signup-page-phoneInputContainer {
      margin-top: 1.5625vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-phoneInputContainer {
      margin-top: 1.73611vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-phoneInputContainer {
      margin-top: 1.5625vw !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-phoneInputContainer {
      margin-top: 3.4219vw !important; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-phoneInputContainer {
      margin-top: 2.73752vw !important; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-phoneInputContainer {
      margin-top: 2.41546vw !important; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-phoneInputContainer {
      margin-top: 2.1612vw !important; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-phoneInputContainer {
      margin-top: 1.95537vw !important; } }

.signup-page-gradesContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  width: 81.15942vw;
  border-radius: 1.20773vw;
  align-items: center; }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-gradesContainer {
      width: 67.63285vw;
      border-radius: 1.00644vw; }
      .signup-page-gradesContainer else {
        width: 336px; }
      .signup-page-gradesContainer else {
        border-radius: 5px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-gradesContainer {
      width: 54.10628vw;
      border-radius: 0.80515vw; }
      .signup-page-gradesContainer else {
        width: 336px; }
      .signup-page-gradesContainer else {
        border-radius: 5px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-gradesContainer {
      width: 47.74084vw;
      border-radius: 0.71043vw; }
      .signup-page-gradesContainer else {
        width: 336px; }
      .signup-page-gradesContainer else {
        border-radius: 5px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-gradesContainer {
      width: 42.71548vw;
      border-radius: 0.63565vw; }
      .signup-page-gradesContainer else {
        width: 336px; }
      .signup-page-gradesContainer else {
        border-radius: 5px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-gradesContainer {
      width: 38.64734vw;
      border-radius: 0.57511vw; }
      .signup-page-gradesContainer else {
        width: 336px; }
      .signup-page-gradesContainer else {
        border-radius: 5px; } }
  @media (min-width: 1300px) {
    .signup-page-gradesContainer {
      width: 24.73958vw;
      border-radius: 0.20833vw;
      align-self: flex-start; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-gradesContainer {
      width: 27.48843vw;
      border-radius: 0.23148vw;
      align-self: flex-start; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-gradesContainer {
      width: 24.73958vw;
      border-radius: 0.20833vw;
      align-self: flex-start; } }

.signup-page-privacytext {
  font-family: Nunito;
  font-weight: 400;
  color: #504F4F;
  width: 82.1256vw;
  margin-top: 3.62319vw;
  font-size: 3.38164vw;
  line-height: 5.07246vw; }
  @media (min-width: 1300px) {
    .signup-page-privacytext {
      width: 25vw;
      margin-top: 1.5625vw;
      font-size: 1.04167vw;
      line-height: 1.71875vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-privacytext {
      width: 27.77778vw;
      margin-top: 1.73611vw;
      font-size: 1.15741vw;
      line-height: 1.90972vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-privacytext {
      width: 25vw;
      margin-top: 1.5625vw;
      font-size: 1.04167vw;
      line-height: 1.71875vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-privacytext {
      width: 68.438vw;
      margin-top: 3.01932vw;
      font-size: 2.81804vw;
      line-height: 4.22705vw; }
      .signup-page-privacytext else {
        width: 340px; }
      .signup-page-privacytext else {
        margin-top: 15px; }
      .signup-page-privacytext else {
        font-size: 14px; }
      .signup-page-privacytext else {
        line-height: 21px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-privacytext {
      width: 54.7504vw;
      margin-top: 2.41546vw;
      font-size: 2.25443vw;
      line-height: 3.38164vw; }
      .signup-page-privacytext else {
        width: 340px; }
      .signup-page-privacytext else {
        margin-top: 15px; }
      .signup-page-privacytext else {
        font-size: 14px; }
      .signup-page-privacytext else {
        line-height: 21px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-privacytext {
      width: 48.30918vw;
      margin-top: 2.13129vw;
      font-size: 1.9892vw;
      line-height: 2.9838vw; }
      .signup-page-privacytext else {
        width: 340px; }
      .signup-page-privacytext else {
        margin-top: 15px; }
      .signup-page-privacytext else {
        font-size: 14px; }
      .signup-page-privacytext else {
        line-height: 21px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-privacytext {
      width: 43.224vw;
      margin-top: 1.90694vw;
      font-size: 1.77981vw;
      line-height: 2.66972vw; }
      .signup-page-privacytext else {
        width: 340px; }
      .signup-page-privacytext else {
        margin-top: 15px; }
      .signup-page-privacytext else {
        font-size: 14px; }
      .signup-page-privacytext else {
        line-height: 21px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-privacytext {
      width: 39.10743vw;
      margin-top: 1.72533vw;
      font-size: 1.61031vw;
      line-height: 2.41546vw; }
      .signup-page-privacytext else {
        width: 340px; }
      .signup-page-privacytext else {
        margin-top: 15px; }
      .signup-page-privacytext else {
        font-size: 14px; }
      .signup-page-privacytext else {
        line-height: 21px; } }

.signup-page-hasLaptopOrDesktop {
  font-family: Nunito;
  font-weight: 400;
  color: #504F4F;
  margin-top: 1.93237vw;
  font-size: 3.38164vw;
  line-height: 5.07246vw; }
  @media (min-width: 1300px) {
    .signup-page-hasLaptopOrDesktop {
      margin-top: 0.52083vw;
      font-size: 1.04167vw;
      line-height: 1.71875vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-hasLaptopOrDesktop {
      margin-top: 0.5787vw;
      font-size: 1.15741vw;
      line-height: 1.90972vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-hasLaptopOrDesktop {
      margin-top: 0.52083vw;
      font-size: 1.04167vw;
      line-height: 1.71875vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-hasLaptopOrDesktop {
      margin-top: 1.61031vw;
      font-size: 2.81804vw;
      line-height: 4.22705vw; }
      .signup-page-hasLaptopOrDesktop else {
        margin-top: 8px; }
      .signup-page-hasLaptopOrDesktop else {
        font-size: 14px; }
      .signup-page-hasLaptopOrDesktop else {
        line-height: 21px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-hasLaptopOrDesktop {
      margin-top: 1.28824vw;
      font-size: 2.25443vw;
      line-height: 3.38164vw; }
      .signup-page-hasLaptopOrDesktop else {
        margin-top: 8px; }
      .signup-page-hasLaptopOrDesktop else {
        font-size: 14px; }
      .signup-page-hasLaptopOrDesktop else {
        line-height: 21px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-hasLaptopOrDesktop {
      margin-top: 1.13669vw;
      font-size: 1.9892vw;
      line-height: 2.9838vw; }
      .signup-page-hasLaptopOrDesktop else {
        margin-top: 8px; }
      .signup-page-hasLaptopOrDesktop else {
        font-size: 14px; }
      .signup-page-hasLaptopOrDesktop else {
        line-height: 21px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-hasLaptopOrDesktop {
      margin-top: 1.01704vw;
      font-size: 1.77981vw;
      line-height: 2.66972vw; }
      .signup-page-hasLaptopOrDesktop else {
        margin-top: 8px; }
      .signup-page-hasLaptopOrDesktop else {
        font-size: 14px; }
      .signup-page-hasLaptopOrDesktop else {
        line-height: 21px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-hasLaptopOrDesktop {
      margin-top: 0.92017vw;
      font-size: 1.61031vw;
      line-height: 2.41546vw; }
      .signup-page-hasLaptopOrDesktop else {
        margin-top: 8px; }
      .signup-page-hasLaptopOrDesktop else {
        font-size: 14px; }
      .signup-page-hasLaptopOrDesktop else {
        line-height: 21px; } }
  .signup-page-hasLaptopOrDesktop div:nth-child(2) {
    margin-left: 2.41546vw; }
    @media (min-width: 1300px) {
      .signup-page-hasLaptopOrDesktop div:nth-child(2) {
        margin-left: 0.52083vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .signup-page-hasLaptopOrDesktop div:nth-child(2) {
        margin-left: 0.5787vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .signup-page-hasLaptopOrDesktop div:nth-child(2) {
        margin-left: 0.52083vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .signup-page-hasLaptopOrDesktop div:nth-child(2) {
        margin-left: 2.01288vw; }
        .signup-page-hasLaptopOrDesktop div:nth-child(2) else {
          margin-left: 10px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .signup-page-hasLaptopOrDesktop div:nth-child(2) {
        margin-left: 1.61031vw; }
        .signup-page-hasLaptopOrDesktop div:nth-child(2) else {
          margin-left: 10px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .signup-page-hasLaptopOrDesktop div:nth-child(2) {
        margin-left: 1.42086vw; }
        .signup-page-hasLaptopOrDesktop div:nth-child(2) else {
          margin-left: 10px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .signup-page-hasLaptopOrDesktop div:nth-child(2) {
        margin-left: 1.27129vw; }
        .signup-page-hasLaptopOrDesktop div:nth-child(2) else {
          margin-left: 10px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .signup-page-hasLaptopOrDesktop div:nth-child(2) {
        margin-left: 1.15022vw; }
        .signup-page-hasLaptopOrDesktop div:nth-child(2) else {
          margin-left: 10px; } }

.signup-page-grade {
  font-family: Nunito;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  color: rgba(173, 173, 173, 0.8);
  height: 100%;
  cursor: pointer;
  font-size: 3.86473vw;
  letter-spacing: -0.04348vw;
  border-radius: 0.96618vw;
  min-width: 22.82609vw;
  min-height: 12.3913vw;
  max-height: 12.3913vw;
  margin-right: 2.41546vw;
  margin-top: 2.41546vw;
  transition: 0.3s all ease-in-out;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);
  border: 1px solid rgba(92, 92, 92, 0.18); }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-grade {
      font-size: 3.22061vw;
      letter-spacing: -0.03623vw;
      border-radius: 0.80515vw;
      min-width: 19.02174vw;
      min-height: 10.32609vw;
      max-height: 10.32609vw;
      margin-right: 2.01288vw;
      margin-top: 2.01288vw; }
      .signup-page-grade else {
        font-size: 16px; }
      .signup-page-grade else {
        letter-spacing: -0.18px; }
      .signup-page-grade else {
        border-radius: 4px; }
      .signup-page-grade else {
        min-width: 94.5px; }
      .signup-page-grade else {
        min-height: 51.3px; }
      .signup-page-grade else {
        max-height: 51.3px; }
      .signup-page-grade else {
        margin-right: 10px; }
      .signup-page-grade else {
        margin-top: 10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-grade {
      font-size: 2.57649vw;
      letter-spacing: -0.02899vw;
      border-radius: 0.64412vw;
      min-width: 15.21739vw;
      min-height: 8.26087vw;
      max-height: 8.26087vw;
      margin-right: 1.61031vw;
      margin-top: 1.61031vw; }
      .signup-page-grade else {
        font-size: 16px; }
      .signup-page-grade else {
        letter-spacing: -0.18px; }
      .signup-page-grade else {
        border-radius: 4px; }
      .signup-page-grade else {
        min-width: 94.5px; }
      .signup-page-grade else {
        min-height: 51.3px; }
      .signup-page-grade else {
        max-height: 51.3px; }
      .signup-page-grade else {
        margin-right: 10px; }
      .signup-page-grade else {
        margin-top: 10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-grade {
      font-size: 2.27337vw;
      letter-spacing: -0.02558vw;
      border-radius: 0.56834vw;
      min-width: 13.42711vw;
      min-height: 7.289vw;
      max-height: 7.289vw;
      margin-right: 1.42086vw;
      margin-top: 1.42086vw; }
      .signup-page-grade else {
        font-size: 16px; }
      .signup-page-grade else {
        letter-spacing: -0.18px; }
      .signup-page-grade else {
        border-radius: 4px; }
      .signup-page-grade else {
        min-width: 94.5px; }
      .signup-page-grade else {
        min-height: 51.3px; }
      .signup-page-grade else {
        max-height: 51.3px; }
      .signup-page-grade else {
        margin-right: 10px; }
      .signup-page-grade else {
        margin-top: 10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-grade {
      font-size: 2.03407vw;
      letter-spacing: -0.02288vw;
      border-radius: 0.50852vw;
      min-width: 12.01373vw;
      min-height: 6.52174vw;
      max-height: 6.52174vw;
      margin-right: 1.27129vw;
      margin-top: 1.27129vw; }
      .signup-page-grade else {
        font-size: 16px; }
      .signup-page-grade else {
        letter-spacing: -0.18px; }
      .signup-page-grade else {
        border-radius: 4px; }
      .signup-page-grade else {
        min-width: 94.5px; }
      .signup-page-grade else {
        min-height: 51.3px; }
      .signup-page-grade else {
        max-height: 51.3px; }
      .signup-page-grade else {
        margin-right: 10px; }
      .signup-page-grade else {
        margin-top: 10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-grade {
      font-size: 1.84035vw;
      letter-spacing: -0.0207vw;
      border-radius: 0.46009vw;
      min-width: 10.86957vw;
      min-height: 5.90062vw;
      max-height: 5.90062vw;
      margin-right: 1.15022vw;
      margin-top: 1.15022vw; }
      .signup-page-grade else {
        font-size: 16px; }
      .signup-page-grade else {
        letter-spacing: -0.18px; }
      .signup-page-grade else {
        border-radius: 4px; }
      .signup-page-grade else {
        min-width: 94.5px; }
      .signup-page-grade else {
        min-height: 51.3px; }
      .signup-page-grade else {
        max-height: 51.3px; }
      .signup-page-grade else {
        margin-right: 10px; }
      .signup-page-grade else {
        margin-top: 10px; } }
  @media (min-width: 1300px) {
    .signup-page-grade {
      border-radius: 0.20833vw;
      font-size: 0.98958vw;
      min-width: 5.46875vw;
      min-height: 2.96875vw;
      max-height: 2.96875vw;
      margin-right: 0.52083vw;
      margin-top: 0.52083vw;
      letter-spacing: -0.0125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-grade {
      border-radius: 0.23148vw;
      font-size: 1.09954vw;
      min-width: 6.07639vw;
      min-height: 3.29861vw;
      max-height: 3.29861vw;
      margin-right: 0.5787vw;
      margin-top: 0.5787vw;
      letter-spacing: -0.01389vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-grade {
      border-radius: 0.20833vw;
      font-size: 0.98958vw;
      min-width: 5.46875vw;
      min-height: 2.96875vw;
      max-height: 2.96875vw;
      margin-right: 0.52083vw;
      margin-top: 0.52083vw;
      letter-spacing: -0.0125vw; } }
  .signup-page-grade:hover {
    border: 1px solid #06B3E6;
    color: rgba(6, 179, 230, 0.9); }

.signup-page-gradeRadioStreet {
  font-family: Nunito;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  color: rgba(173, 173, 173, 0.8);
  height: 100%;
  cursor: pointer;
  font-size: 3.86473vw;
  letter-spacing: -0.04348vw;
  border-radius: 0.96618vw;
  min-width: 21.73913vw;
  min-height: 12.3913vw;
  max-height: 12.3913vw;
  margin-right: 2.41546vw;
  margin-top: 2.41546vw;
  transition: 0.3s all ease-in-out;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);
  border: 1px solid rgba(92, 92, 92, 0.18); }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-gradeRadioStreet {
      font-size: 3.22061vw;
      letter-spacing: -0.03623vw;
      border-radius: 0.80515vw;
      min-width: 18.11594vw;
      min-height: 10.32609vw;
      max-height: 10.32609vw;
      margin-right: 2.01288vw;
      margin-top: 2.01288vw; }
      .signup-page-gradeRadioStreet else {
        font-size: 16px; }
      .signup-page-gradeRadioStreet else {
        letter-spacing: -0.18px; }
      .signup-page-gradeRadioStreet else {
        border-radius: 4px; }
      .signup-page-gradeRadioStreet else {
        min-width: 90px; }
      .signup-page-gradeRadioStreet else {
        min-height: 51.3px; }
      .signup-page-gradeRadioStreet else {
        max-height: 51.3px; }
      .signup-page-gradeRadioStreet else {
        margin-right: 10px; }
      .signup-page-gradeRadioStreet else {
        margin-top: 10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-gradeRadioStreet {
      font-size: 2.57649vw;
      letter-spacing: -0.02899vw;
      border-radius: 0.64412vw;
      min-width: 14.49275vw;
      min-height: 8.26087vw;
      max-height: 8.26087vw;
      margin-right: 1.61031vw;
      margin-top: 1.61031vw; }
      .signup-page-gradeRadioStreet else {
        font-size: 16px; }
      .signup-page-gradeRadioStreet else {
        letter-spacing: -0.18px; }
      .signup-page-gradeRadioStreet else {
        border-radius: 4px; }
      .signup-page-gradeRadioStreet else {
        min-width: 90px; }
      .signup-page-gradeRadioStreet else {
        min-height: 51.3px; }
      .signup-page-gradeRadioStreet else {
        max-height: 51.3px; }
      .signup-page-gradeRadioStreet else {
        margin-right: 10px; }
      .signup-page-gradeRadioStreet else {
        margin-top: 10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-gradeRadioStreet {
      font-size: 2.27337vw;
      letter-spacing: -0.02558vw;
      border-radius: 0.56834vw;
      min-width: 12.78772vw;
      min-height: 7.289vw;
      max-height: 7.289vw;
      margin-right: 1.42086vw;
      margin-top: 1.42086vw; }
      .signup-page-gradeRadioStreet else {
        font-size: 16px; }
      .signup-page-gradeRadioStreet else {
        letter-spacing: -0.18px; }
      .signup-page-gradeRadioStreet else {
        border-radius: 4px; }
      .signup-page-gradeRadioStreet else {
        min-width: 90px; }
      .signup-page-gradeRadioStreet else {
        min-height: 51.3px; }
      .signup-page-gradeRadioStreet else {
        max-height: 51.3px; }
      .signup-page-gradeRadioStreet else {
        margin-right: 10px; }
      .signup-page-gradeRadioStreet else {
        margin-top: 10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-gradeRadioStreet {
      font-size: 2.03407vw;
      letter-spacing: -0.02288vw;
      border-radius: 0.50852vw;
      min-width: 11.44165vw;
      min-height: 6.52174vw;
      max-height: 6.52174vw;
      margin-right: 1.27129vw;
      margin-top: 1.27129vw; }
      .signup-page-gradeRadioStreet else {
        font-size: 16px; }
      .signup-page-gradeRadioStreet else {
        letter-spacing: -0.18px; }
      .signup-page-gradeRadioStreet else {
        border-radius: 4px; }
      .signup-page-gradeRadioStreet else {
        min-width: 90px; }
      .signup-page-gradeRadioStreet else {
        min-height: 51.3px; }
      .signup-page-gradeRadioStreet else {
        max-height: 51.3px; }
      .signup-page-gradeRadioStreet else {
        margin-right: 10px; }
      .signup-page-gradeRadioStreet else {
        margin-top: 10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-gradeRadioStreet {
      font-size: 1.84035vw;
      letter-spacing: -0.0207vw;
      border-radius: 0.46009vw;
      min-width: 10.35197vw;
      min-height: 5.90062vw;
      max-height: 5.90062vw;
      margin-right: 1.15022vw;
      margin-top: 1.15022vw; }
      .signup-page-gradeRadioStreet else {
        font-size: 16px; }
      .signup-page-gradeRadioStreet else {
        letter-spacing: -0.18px; }
      .signup-page-gradeRadioStreet else {
        border-radius: 4px; }
      .signup-page-gradeRadioStreet else {
        min-width: 90px; }
      .signup-page-gradeRadioStreet else {
        min-height: 51.3px; }
      .signup-page-gradeRadioStreet else {
        max-height: 51.3px; }
      .signup-page-gradeRadioStreet else {
        margin-right: 10px; }
      .signup-page-gradeRadioStreet else {
        margin-top: 10px; } }
  @media (min-width: 1300px) {
    .signup-page-gradeRadioStreet {
      border-radius: 0.20833vw;
      font-size: 0.98958vw;
      min-width: 7.29167vw;
      min-height: 2.96875vw;
      max-height: 2.96875vw;
      margin-right: 0.52083vw;
      margin-top: 0.52083vw;
      letter-spacing: -0.0125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-gradeRadioStreet {
      border-radius: 0.23148vw;
      font-size: 1.09954vw;
      min-width: 8.10185vw;
      min-height: 3.29861vw;
      max-height: 3.29861vw;
      margin-right: 0.5787vw;
      margin-top: 0.5787vw;
      letter-spacing: -0.01389vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-gradeRadioStreet {
      border-radius: 0.20833vw;
      font-size: 0.98958vw;
      min-width: 7.29167vw;
      min-height: 2.96875vw;
      max-height: 2.96875vw;
      margin-right: 0.52083vw;
      margin-top: 0.52083vw;
      letter-spacing: -0.0125vw; } }
  .signup-page-gradeRadioStreet:hover {
    border: 1px solid #06B3E6;
    color: rgba(6, 179, 230, 0.9); }

.signup-page-gradesWrapperSignupForm {
  font-family: Nunito;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  color: rgba(173, 173, 173, 0.8);
  height: 100%;
  cursor: pointer;
  font-size: 3.86473vw;
  letter-spacing: -0.04348vw;
  border-radius: 0.96618vw;
  min-width: 22.82609vw;
  min-height: 12.3913vw;
  max-height: 12.3913vw;
  margin-right: 2.41546vw;
  margin-top: 2.41546vw;
  transition: 0.3s all ease-in-out;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);
  border: 1px solid rgba(92, 92, 92, 0.18); }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-gradesWrapperSignupForm {
      font-size: 3.22061vw;
      letter-spacing: -0.03623vw;
      border-radius: 0.80515vw;
      min-width: 19.02174vw;
      min-height: 10.32609vw;
      max-height: 10.32609vw;
      margin-right: 2.01288vw;
      margin-top: 2.01288vw; }
      .signup-page-gradesWrapperSignupForm else {
        font-size: 16px; }
      .signup-page-gradesWrapperSignupForm else {
        letter-spacing: -0.18px; }
      .signup-page-gradesWrapperSignupForm else {
        border-radius: 4px; }
      .signup-page-gradesWrapperSignupForm else {
        min-width: 94.5px; }
      .signup-page-gradesWrapperSignupForm else {
        min-height: 51.3px; }
      .signup-page-gradesWrapperSignupForm else {
        max-height: 51.3px; }
      .signup-page-gradesWrapperSignupForm else {
        margin-right: 10px; }
      .signup-page-gradesWrapperSignupForm else {
        margin-top: 10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-gradesWrapperSignupForm {
      font-size: 2.57649vw;
      letter-spacing: -0.02899vw;
      border-radius: 0.64412vw;
      min-width: 15.21739vw;
      min-height: 8.26087vw;
      max-height: 8.26087vw;
      margin-right: 1.61031vw;
      margin-top: 1.61031vw; }
      .signup-page-gradesWrapperSignupForm else {
        font-size: 16px; }
      .signup-page-gradesWrapperSignupForm else {
        letter-spacing: -0.18px; }
      .signup-page-gradesWrapperSignupForm else {
        border-radius: 4px; }
      .signup-page-gradesWrapperSignupForm else {
        min-width: 94.5px; }
      .signup-page-gradesWrapperSignupForm else {
        min-height: 51.3px; }
      .signup-page-gradesWrapperSignupForm else {
        max-height: 51.3px; }
      .signup-page-gradesWrapperSignupForm else {
        margin-right: 10px; }
      .signup-page-gradesWrapperSignupForm else {
        margin-top: 10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-gradesWrapperSignupForm {
      font-size: 2.27337vw;
      letter-spacing: -0.02558vw;
      border-radius: 0.56834vw;
      min-width: 13.42711vw;
      min-height: 7.289vw;
      max-height: 7.289vw;
      margin-right: 1.42086vw;
      margin-top: 1.42086vw; }
      .signup-page-gradesWrapperSignupForm else {
        font-size: 16px; }
      .signup-page-gradesWrapperSignupForm else {
        letter-spacing: -0.18px; }
      .signup-page-gradesWrapperSignupForm else {
        border-radius: 4px; }
      .signup-page-gradesWrapperSignupForm else {
        min-width: 94.5px; }
      .signup-page-gradesWrapperSignupForm else {
        min-height: 51.3px; }
      .signup-page-gradesWrapperSignupForm else {
        max-height: 51.3px; }
      .signup-page-gradesWrapperSignupForm else {
        margin-right: 10px; }
      .signup-page-gradesWrapperSignupForm else {
        margin-top: 10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-gradesWrapperSignupForm {
      font-size: 2.03407vw;
      letter-spacing: -0.02288vw;
      border-radius: 0.50852vw;
      min-width: 12.01373vw;
      min-height: 6.52174vw;
      max-height: 6.52174vw;
      margin-right: 1.27129vw;
      margin-top: 1.27129vw; }
      .signup-page-gradesWrapperSignupForm else {
        font-size: 16px; }
      .signup-page-gradesWrapperSignupForm else {
        letter-spacing: -0.18px; }
      .signup-page-gradesWrapperSignupForm else {
        border-radius: 4px; }
      .signup-page-gradesWrapperSignupForm else {
        min-width: 94.5px; }
      .signup-page-gradesWrapperSignupForm else {
        min-height: 51.3px; }
      .signup-page-gradesWrapperSignupForm else {
        max-height: 51.3px; }
      .signup-page-gradesWrapperSignupForm else {
        margin-right: 10px; }
      .signup-page-gradesWrapperSignupForm else {
        margin-top: 10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-gradesWrapperSignupForm {
      font-size: 1.84035vw;
      letter-spacing: -0.0207vw;
      border-radius: 0.46009vw;
      min-width: 10.86957vw;
      min-height: 5.90062vw;
      max-height: 5.90062vw;
      margin-right: 1.15022vw;
      margin-top: 1.15022vw; }
      .signup-page-gradesWrapperSignupForm else {
        font-size: 16px; }
      .signup-page-gradesWrapperSignupForm else {
        letter-spacing: -0.18px; }
      .signup-page-gradesWrapperSignupForm else {
        border-radius: 4px; }
      .signup-page-gradesWrapperSignupForm else {
        min-width: 94.5px; }
      .signup-page-gradesWrapperSignupForm else {
        min-height: 51.3px; }
      .signup-page-gradesWrapperSignupForm else {
        max-height: 51.3px; }
      .signup-page-gradesWrapperSignupForm else {
        margin-right: 10px; }
      .signup-page-gradesWrapperSignupForm else {
        margin-top: 10px; } }
  @media (min-width: 1300px) {
    .signup-page-gradesWrapperSignupForm {
      border-radius: 0.20833vw;
      font-size: 0.98958vw;
      min-width: 7.29167vw;
      min-height: 2.96875vw;
      max-height: 2.96875vw;
      margin-right: 0.52083vw;
      margin-top: 0.52083vw;
      letter-spacing: -0.0125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-gradesWrapperSignupForm {
      border-radius: 0.23148vw;
      font-size: 1.09954vw;
      min-width: 8.10185vw;
      min-height: 3.29861vw;
      max-height: 3.29861vw;
      margin-right: 0.5787vw;
      margin-top: 0.5787vw;
      letter-spacing: -0.01389vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-gradesWrapperSignupForm {
      border-radius: 0.20833vw;
      font-size: 0.98958vw;
      min-width: 7.29167vw;
      min-height: 2.96875vw;
      max-height: 2.96875vw;
      margin-right: 0.52083vw;
      margin-top: 0.52083vw;
      letter-spacing: -0.0125vw; } }
  .signup-page-gradesWrapperSignupForm:hover {
    border: 1px solid #06B3E6;
    color: rgba(6, 179, 230, 0.9); }

.signup-page-gradeSep {
  background-color: #229296;
  opacity: 0.4;
  height: 6.15942vw;
  width: 0vw; }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-gradeSep {
      height: 5.13285vw;
      width: 0vw; }
      .signup-page-gradeSep else {
        height: 25.5px; }
      .signup-page-gradeSep else {
        width: 0px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-gradeSep {
      height: 4.10628vw;
      width: 0vw; }
      .signup-page-gradeSep else {
        height: 25.5px; }
      .signup-page-gradeSep else {
        width: 0px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-gradeSep {
      height: 3.62319vw;
      width: 0vw; }
      .signup-page-gradeSep else {
        height: 25.5px; }
      .signup-page-gradeSep else {
        width: 0px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-gradeSep {
      height: 3.2418vw;
      width: 0vw; }
      .signup-page-gradeSep else {
        height: 25.5px; }
      .signup-page-gradeSep else {
        width: 0px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-gradeSep {
      height: 2.93306vw;
      width: 0vw; }
      .signup-page-gradeSep else {
        height: 25.5px; }
      .signup-page-gradeSep else {
        width: 0px; } }
  @media (min-width: 1300px) {
    .signup-page-gradeSep {
      height: 1.86458vw;
      width: 0.05208vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-gradeSep {
      height: 2.07176vw;
      width: 0.05787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-gradeSep {
      height: 1.86458vw;
      width: 0.05208vw; } }

.signup-page-gradeActive {
  color: white;
  background: rgba(6, 179, 230, 0.2);
  border: 1px solid #06B3E6;
  color: rgba(6, 179, 230, 0.9);
  font-weight: bold; }

.signup-page-label {
  width: 100%;
  color: rgba(17, 17, 17, 0.48);
  opacity: 0.7;
  margin-bottom: 1.93237vw;
  font-size: 3.38164vw;
  line-height: 5.7971vw;
  margin-top: 1.93237vw; }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-label {
      margin-bottom: 1.61031vw;
      font-size: 2.81804vw;
      line-height: 4.83092vw;
      margin-top: 1.61031vw; }
      .signup-page-label else {
        margin-bottom: 8px; }
      .signup-page-label else {
        font-size: 14px; }
      .signup-page-label else {
        line-height: 24px; }
      .signup-page-label else {
        margin-top: 8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-label {
      margin-bottom: 1.28824vw;
      font-size: 2.25443vw;
      line-height: 3.86473vw;
      margin-top: 1.28824vw; }
      .signup-page-label else {
        margin-bottom: 8px; }
      .signup-page-label else {
        font-size: 14px; }
      .signup-page-label else {
        line-height: 24px; }
      .signup-page-label else {
        margin-top: 8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-label {
      margin-bottom: 1.13669vw;
      font-size: 1.9892vw;
      line-height: 3.41006vw;
      margin-top: 1.13669vw; }
      .signup-page-label else {
        margin-bottom: 8px; }
      .signup-page-label else {
        font-size: 14px; }
      .signup-page-label else {
        line-height: 24px; }
      .signup-page-label else {
        margin-top: 8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-label {
      margin-bottom: 1.01704vw;
      font-size: 1.77981vw;
      line-height: 3.05111vw;
      margin-top: 1.01704vw; }
      .signup-page-label else {
        margin-bottom: 8px; }
      .signup-page-label else {
        font-size: 14px; }
      .signup-page-label else {
        line-height: 24px; }
      .signup-page-label else {
        margin-top: 8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-label {
      margin-bottom: 0.92017vw;
      font-size: 1.61031vw;
      line-height: 2.76052vw;
      margin-top: 0.92017vw; }
      .signup-page-label else {
        margin-bottom: 8px; }
      .signup-page-label else {
        font-size: 14px; }
      .signup-page-label else {
        line-height: 24px; }
      .signup-page-label else {
        margin-top: 8px; } }
  @media (min-width: 1300px) {
    .signup-page-label {
      font-size: 1.09375vw;
      line-height: 1.25vw;
      margin-bottom: 0.78125vw;
      margin-top: 1.5625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-label {
      font-size: 1.21528vw;
      line-height: 1.38889vw;
      margin-bottom: 0.86806vw;
      margin-top: 1.73611vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-label {
      font-size: 1.09375vw;
      line-height: 1.25vw;
      margin-bottom: 0.78125vw;
      margin-top: 1.5625vw; } }

.signup-page-signupButton {
  align-self: center;
  color: white;
  font-family: Nunito;
  font-weight: 600;
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  padding-left: 4.34783vw;
  padding-top: 2.41546vw;
  padding-bottom: 2.41546vw;
  padding-right: 4.34783vw;
  border-radius: 1.44928vw;
  margin-top: 2.657vw;
  margin-bottom: 4.83092vw;
  font-size: 3.38164vw;
  background: linear-gradient(180deg, #34E4EA 0%, #00ADE6 100%); }
  @media (min-width: 1300px) {
    .signup-page-signupButton {
      padding-left: 1.5625vw;
      padding-top: 0.78125vw;
      padding-bottom: 0.78125vw;
      padding-right: 1.04167vw;
      border-radius: 0.72917vw;
      margin-top: 1.5625vw;
      margin-bottom: 1.5625vw;
      font-size: 1.30208vw;
      background: linear-gradient(180deg, #34E4EA 0%, #00ADE6 100%); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-signupButton {
      padding-left: 1.73611vw;
      padding-top: 0.86806vw;
      padding-bottom: 0.86806vw;
      padding-right: 1.15741vw;
      border-radius: 0.81019vw;
      margin-top: 1.73611vw;
      margin-bottom: 1.73611vw;
      font-size: 1.44676vw;
      background: linear-gradient(180deg, #34E4EA 0%, #00ADE6 100%); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-signupButton {
      padding-left: 1.5625vw;
      padding-top: 0.78125vw;
      padding-bottom: 0.78125vw;
      padding-right: 1.04167vw;
      border-radius: 0.72917vw;
      margin-top: 1.5625vw;
      margin-bottom: 1.5625vw;
      font-size: 1.30208vw;
      background: linear-gradient(180deg, #34E4EA 0%, #00ADE6 100%); } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-signupButton {
      padding-left: 3.62319vw;
      padding-top: 2.01288vw;
      padding-bottom: 2.01288vw;
      padding-right: 3.62319vw;
      border-radius: 1.20773vw;
      margin-top: 2.21417vw;
      margin-bottom: 4.02576vw;
      font-size: 2.81804vw; }
      .signup-page-signupButton else {
        padding-left: 18px; }
      .signup-page-signupButton else {
        padding-top: 10px; }
      .signup-page-signupButton else {
        padding-bottom: 10px; }
      .signup-page-signupButton else {
        padding-right: 18px; }
      .signup-page-signupButton else {
        border-radius: 6px; }
      .signup-page-signupButton else {
        margin-top: 11px; }
      .signup-page-signupButton else {
        margin-bottom: 20px; }
      .signup-page-signupButton else {
        font-size: 14px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-signupButton {
      padding-left: 2.89855vw;
      padding-top: 1.61031vw;
      padding-bottom: 1.61031vw;
      padding-right: 2.89855vw;
      border-radius: 0.96618vw;
      margin-top: 1.77134vw;
      margin-bottom: 3.22061vw;
      font-size: 2.25443vw; }
      .signup-page-signupButton else {
        padding-left: 18px; }
      .signup-page-signupButton else {
        padding-top: 10px; }
      .signup-page-signupButton else {
        padding-bottom: 10px; }
      .signup-page-signupButton else {
        padding-right: 18px; }
      .signup-page-signupButton else {
        border-radius: 6px; }
      .signup-page-signupButton else {
        margin-top: 11px; }
      .signup-page-signupButton else {
        margin-bottom: 20px; }
      .signup-page-signupButton else {
        font-size: 14px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-signupButton {
      padding-left: 2.55754vw;
      padding-top: 1.42086vw;
      padding-bottom: 1.42086vw;
      padding-right: 2.55754vw;
      border-radius: 0.85251vw;
      margin-top: 1.56294vw;
      margin-bottom: 2.84172vw;
      font-size: 1.9892vw; }
      .signup-page-signupButton else {
        padding-left: 18px; }
      .signup-page-signupButton else {
        padding-top: 10px; }
      .signup-page-signupButton else {
        padding-bottom: 10px; }
      .signup-page-signupButton else {
        padding-right: 18px; }
      .signup-page-signupButton else {
        border-radius: 6px; }
      .signup-page-signupButton else {
        margin-top: 11px; }
      .signup-page-signupButton else {
        margin-bottom: 20px; }
      .signup-page-signupButton else {
        font-size: 14px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-signupButton {
      padding-left: 2.28833vw;
      padding-top: 1.27129vw;
      padding-bottom: 1.27129vw;
      padding-right: 2.28833vw;
      border-radius: 0.76278vw;
      margin-top: 1.39842vw;
      margin-bottom: 2.54259vw;
      font-size: 1.77981vw; }
      .signup-page-signupButton else {
        padding-left: 18px; }
      .signup-page-signupButton else {
        padding-top: 10px; }
      .signup-page-signupButton else {
        padding-bottom: 10px; }
      .signup-page-signupButton else {
        padding-right: 18px; }
      .signup-page-signupButton else {
        border-radius: 6px; }
      .signup-page-signupButton else {
        margin-top: 11px; }
      .signup-page-signupButton else {
        margin-bottom: 20px; }
      .signup-page-signupButton else {
        font-size: 14px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-signupButton {
      padding-left: 2.07039vw;
      padding-top: 1.15022vw;
      padding-bottom: 1.15022vw;
      padding-right: 2.07039vw;
      border-radius: 0.69013vw;
      margin-top: 1.26524vw;
      margin-bottom: 2.30044vw;
      font-size: 1.61031vw; }
      .signup-page-signupButton else {
        padding-left: 18px; }
      .signup-page-signupButton else {
        padding-top: 10px; }
      .signup-page-signupButton else {
        padding-bottom: 10px; }
      .signup-page-signupButton else {
        padding-right: 18px; }
      .signup-page-signupButton else {
        border-radius: 6px; }
      .signup-page-signupButton else {
        margin-top: 11px; }
      .signup-page-signupButton else {
        margin-bottom: 20px; }
      .signup-page-signupButton else {
        font-size: 14px; } }
  .signup-page-signupButton.disabled {
    cursor: context-menu;
    opacity: 0.7; }

.signup-page-calendarIcon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.52174vw;
  height: 6.52174vw;
  margin-right: 2.41546vw;
  top: -0.24155vw; }
  @media (min-width: 1300px) {
    .signup-page-calendarIcon {
      width: 1.5625vw;
      height: 1.5625vw;
      margin-right: 0.83333vw;
      top: -0.05208vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-calendarIcon {
      width: 1.73611vw;
      height: 1.73611vw;
      margin-right: 0.92593vw;
      top: -0.05787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-calendarIcon {
      width: 1.5625vw;
      height: 1.5625vw;
      margin-right: 0.83333vw;
      top: -0.05208vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-calendarIcon {
      width: 5.43478vw;
      height: 5.43478vw;
      margin-right: 2.01288vw;
      top: -0.20129vw; }
      .signup-page-calendarIcon else {
        width: 27px; }
      .signup-page-calendarIcon else {
        height: 27px; }
      .signup-page-calendarIcon else {
        margin-right: 10px; }
      .signup-page-calendarIcon else {
        top: -1px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-calendarIcon {
      width: 4.34783vw;
      height: 4.34783vw;
      margin-right: 1.61031vw;
      top: -0.16103vw; }
      .signup-page-calendarIcon else {
        width: 27px; }
      .signup-page-calendarIcon else {
        height: 27px; }
      .signup-page-calendarIcon else {
        margin-right: 10px; }
      .signup-page-calendarIcon else {
        top: -1px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-calendarIcon {
      width: 3.83632vw;
      height: 3.83632vw;
      margin-right: 1.42086vw;
      top: -0.14209vw; }
      .signup-page-calendarIcon else {
        width: 27px; }
      .signup-page-calendarIcon else {
        height: 27px; }
      .signup-page-calendarIcon else {
        margin-right: 10px; }
      .signup-page-calendarIcon else {
        top: -1px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-calendarIcon {
      width: 3.43249vw;
      height: 3.43249vw;
      margin-right: 1.27129vw;
      top: -0.12713vw; }
      .signup-page-calendarIcon else {
        width: 27px; }
      .signup-page-calendarIcon else {
        height: 27px; }
      .signup-page-calendarIcon else {
        margin-right: 10px; }
      .signup-page-calendarIcon else {
        top: -1px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-calendarIcon {
      width: 3.10559vw;
      height: 3.10559vw;
      margin-right: 1.15022vw;
      top: -0.11502vw; }
      .signup-page-calendarIcon else {
        width: 27px; }
      .signup-page-calendarIcon else {
        height: 27px; }
      .signup-page-calendarIcon else {
        margin-right: 10px; }
      .signup-page-calendarIcon else {
        top: -1px; } }

.signup-page-loadingIcon {
  position: relative;
  width: 3.38164vw;
  height: 3.38164vw;
  left: 1.20773vw; }
  @media (min-width: 1300px) {
    .signup-page-loadingIcon {
      width: 1.14583vw;
      height: 1.14583vw;
      left: 0.26042vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-loadingIcon {
      width: 1.27315vw;
      height: 1.27315vw;
      left: 0.28935vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-loadingIcon {
      width: 1.14583vw;
      height: 1.14583vw;
      left: 0.26042vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-loadingIcon {
      width: 2.81804vw;
      height: 2.81804vw;
      left: 1.00644vw; }
      .signup-page-loadingIcon else {
        width: 14px; }
      .signup-page-loadingIcon else {
        height: 14px; }
      .signup-page-loadingIcon else {
        left: 5px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-loadingIcon {
      width: 2.25443vw;
      height: 2.25443vw;
      left: 0.80515vw; }
      .signup-page-loadingIcon else {
        width: 14px; }
      .signup-page-loadingIcon else {
        height: 14px; }
      .signup-page-loadingIcon else {
        left: 5px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-loadingIcon {
      width: 1.9892vw;
      height: 1.9892vw;
      left: 0.71043vw; }
      .signup-page-loadingIcon else {
        width: 14px; }
      .signup-page-loadingIcon else {
        height: 14px; }
      .signup-page-loadingIcon else {
        left: 5px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-loadingIcon {
      width: 1.77981vw;
      height: 1.77981vw;
      left: 0.63565vw; }
      .signup-page-loadingIcon else {
        width: 14px; }
      .signup-page-loadingIcon else {
        height: 14px; }
      .signup-page-loadingIcon else {
        left: 5px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-loadingIcon {
      width: 1.61031vw;
      height: 1.61031vw;
      left: 0.57511vw; }
      .signup-page-loadingIcon else {
        width: 14px; }
      .signup-page-loadingIcon else {
        height: 14px; }
      .signup-page-loadingIcon else {
        left: 5px; } }

.signup-page-tekieFeatureContainer {
  width: 100%;
  margin-top: 7.24638vw; }
  @media (min-width: 1300px) {
    .signup-page-tekieFeatureContainer {
      margin-top: 3.80208vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-tekieFeatureContainer {
      margin-top: 4.22454vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-tekieFeatureContainer {
      margin-top: 3.80208vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-tekieFeatureContainer {
      margin-top: 6.03865vw; }
      .signup-page-tekieFeatureContainer else {
        margin-top: 30px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-tekieFeatureContainer {
      margin-top: 4.83092vw; }
      .signup-page-tekieFeatureContainer else {
        margin-top: 30px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-tekieFeatureContainer {
      margin-top: 4.26257vw; }
      .signup-page-tekieFeatureContainer else {
        margin-top: 30px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-tekieFeatureContainer {
      margin-top: 3.81388vw; }
      .signup-page-tekieFeatureContainer else {
        margin-top: 30px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-tekieFeatureContainer {
      margin-top: 3.45066vw; }
      .signup-page-tekieFeatureContainer else {
        margin-top: 30px; } }

.signup-page-TFrow {
  display: flex;
  flex-direction: row; }

.signup-page-inc42Icon {
  background-image: url(/static/media/inc42.dd6c0ce3.png);
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  min-width: 17.8744vw;
  min-height: 8.21256vw;
  margin-top: 0vw;
  margin-right: 0vw; }
  .signup-page-inc42Icon:hover {
    opacity: 0.8;
    -webkit-transform: scale(0.95);
            transform: scale(0.95); }
  @media (min-width: 1300px) {
    .signup-page-inc42Icon {
      min-width: 5.72917vw;
      min-height: 1.61458vw;
      margin-top: 0.41667vw;
      margin-right: 0.78125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-inc42Icon {
      min-width: 6.36574vw;
      min-height: 1.79398vw;
      margin-top: 0.46296vw;
      margin-right: 0.86806vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-inc42Icon {
      min-width: 5.72917vw;
      min-height: 1.61458vw;
      margin-top: 0.41667vw;
      margin-right: 0.78125vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-inc42Icon {
      min-width: 14.89533vw;
      min-height: 6.8438vw;
      margin-top: 0vw;
      margin-right: 0vw; }
      .signup-page-inc42Icon else {
        min-width: 74px; }
      .signup-page-inc42Icon else {
        min-height: 34px; }
      .signup-page-inc42Icon else {
        margin-top: 0px; }
      .signup-page-inc42Icon else {
        margin-right: 0px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-inc42Icon {
      min-width: 11.91626vw;
      min-height: 5.47504vw;
      margin-top: 0vw;
      margin-right: 0vw; }
      .signup-page-inc42Icon else {
        min-width: 74px; }
      .signup-page-inc42Icon else {
        min-height: 34px; }
      .signup-page-inc42Icon else {
        margin-top: 0px; }
      .signup-page-inc42Icon else {
        margin-right: 0px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-inc42Icon {
      min-width: 10.51435vw;
      min-height: 4.83092vw;
      margin-top: 0vw;
      margin-right: 0vw; }
      .signup-page-inc42Icon else {
        min-width: 74px; }
      .signup-page-inc42Icon else {
        min-height: 34px; }
      .signup-page-inc42Icon else {
        margin-top: 0px; }
      .signup-page-inc42Icon else {
        margin-right: 0px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-inc42Icon {
      min-width: 9.40758vw;
      min-height: 4.3224vw;
      margin-top: 0vw;
      margin-right: 0vw; }
      .signup-page-inc42Icon else {
        min-width: 74px; }
      .signup-page-inc42Icon else {
        min-height: 34px; }
      .signup-page-inc42Icon else {
        margin-top: 0px; }
      .signup-page-inc42Icon else {
        margin-right: 0px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-inc42Icon {
      min-width: 8.51162vw;
      min-height: 3.91074vw;
      margin-top: 0vw;
      margin-right: 0vw; }
      .signup-page-inc42Icon else {
        min-width: 74px; }
      .signup-page-inc42Icon else {
        min-height: 34px; }
      .signup-page-inc42Icon else {
        margin-top: 0px; }
      .signup-page-inc42Icon else {
        margin-right: 0px; } }

.signup-page-yourStoryIcon {
  background-image: url(/static/media/MediumGSVLogo.27a5606c.png);
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  min-width: 17.14976vw;
  min-height: 15.70048vw;
  margin-top: unset;
  margin-left: 4.83092vw;
  margin-right: 4.83092vw; }
  .signup-page-yourStoryIcon:hover {
    opacity: 0.8;
    -webkit-transform: scale(0.95);
            transform: scale(0.95); }
  @media (min-width: 1300px) {
    .signup-page-yourStoryIcon {
      min-width: 5.72917vw;
      min-height: 4.6875vw;
      margin-top: 1.97917vw;
      margin-left: 0.625vw;
      margin-right: unset; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-yourStoryIcon {
      min-width: 6.36574vw;
      min-height: 5.20833vw;
      margin-top: 2.19907vw;
      margin-left: 0.69444vw;
      margin-right: unset; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-yourStoryIcon {
      min-width: 5.72917vw;
      min-height: 4.6875vw;
      margin-top: 1.97917vw;
      margin-left: 0.625vw;
      margin-right: unset; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-yourStoryIcon {
      min-width: 14.29147vw;
      min-height: 13.08374vw;
      margin-left: 4.02576vw;
      margin-right: 4.02576vw; }
      .signup-page-yourStoryIcon else {
        min-width: 71px; }
      .signup-page-yourStoryIcon else {
        min-height: 65px; }
      .signup-page-yourStoryIcon else {
        margin-top: unset; }
      .signup-page-yourStoryIcon else {
        margin-left: 20px; }
      .signup-page-yourStoryIcon else {
        margin-right: 20px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-yourStoryIcon {
      min-width: 11.43317vw;
      min-height: 10.46699vw;
      margin-left: 3.22061vw;
      margin-right: 3.22061vw; }
      .signup-page-yourStoryIcon else {
        min-width: 71px; }
      .signup-page-yourStoryIcon else {
        min-height: 65px; }
      .signup-page-yourStoryIcon else {
        margin-top: unset; }
      .signup-page-yourStoryIcon else {
        margin-left: 20px; }
      .signup-page-yourStoryIcon else {
        margin-right: 20px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-yourStoryIcon {
      min-width: 10.08809vw;
      min-height: 9.23558vw;
      margin-left: 2.84172vw;
      margin-right: 2.84172vw; }
      .signup-page-yourStoryIcon else {
        min-width: 71px; }
      .signup-page-yourStoryIcon else {
        min-height: 65px; }
      .signup-page-yourStoryIcon else {
        margin-top: unset; }
      .signup-page-yourStoryIcon else {
        margin-left: 20px; }
      .signup-page-yourStoryIcon else {
        margin-right: 20px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-yourStoryIcon {
      min-width: 9.02619vw;
      min-height: 8.26341vw;
      margin-left: 2.54259vw;
      margin-right: 2.54259vw; }
      .signup-page-yourStoryIcon else {
        min-width: 71px; }
      .signup-page-yourStoryIcon else {
        min-height: 65px; }
      .signup-page-yourStoryIcon else {
        margin-top: unset; }
      .signup-page-yourStoryIcon else {
        margin-left: 20px; }
      .signup-page-yourStoryIcon else {
        margin-right: 20px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-yourStoryIcon {
      min-width: 8.16655vw;
      min-height: 7.47642vw;
      margin-left: 2.30044vw;
      margin-right: 2.30044vw; }
      .signup-page-yourStoryIcon else {
        min-width: 71px; }
      .signup-page-yourStoryIcon else {
        min-height: 65px; }
      .signup-page-yourStoryIcon else {
        margin-top: unset; }
      .signup-page-yourStoryIcon else {
        margin-left: 20px; }
      .signup-page-yourStoryIcon else {
        margin-right: 20px; } }

.signup-page-yourStoryContainer {
  margin-top: 12.07729vw; }
  @media (min-width: 1300px) {
    .signup-page-yourStoryContainer {
      margin-top: 5.26042vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-yourStoryContainer {
      margin-top: 5.84491vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-yourStoryContainer {
      margin-top: 5.26042vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-yourStoryContainer {
      margin-top: 10.06441vw; }
      .signup-page-yourStoryContainer else {
        margin-top: 50px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-yourStoryContainer {
      margin-top: 8.05153vw; }
      .signup-page-yourStoryContainer else {
        margin-top: 50px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-yourStoryContainer {
      margin-top: 7.10429vw; }
      .signup-page-yourStoryContainer else {
        margin-top: 50px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-yourStoryContainer {
      margin-top: 6.35647vw; }
      .signup-page-yourStoryContainer else {
        margin-top: 50px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-yourStoryContainer {
      margin-top: 5.75109vw; }
      .signup-page-yourStoryContainer else {
        margin-top: 50px; } }

.signup-page-featureText {
  font-family: Nunito;
  font-weight: normal;
  line-height: 159.4%;
  color: #504F4F;
  opacity: 0.75;
  font-size: 2.89855vw;
  margin-right: 4.83092vw;
  margin-left: 4.83092vw; }
  @media (min-width: 1300px) {
    .signup-page-featureText {
      font-size: 0.83333vw;
      margin-right: unset;
      margin-left: unset; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-featureText {
      font-size: 0.92593vw;
      margin-right: unset;
      margin-left: unset; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-featureText {
      font-size: 0.83333vw;
      margin-right: unset;
      margin-left: unset; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-featureText {
      font-size: 2.41546vw;
      margin-right: 4.02576vw;
      margin-left: 4.02576vw; }
      .signup-page-featureText else {
        font-size: 12px; }
      .signup-page-featureText else {
        margin-right: 20px; }
      .signup-page-featureText else {
        margin-left: 20px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-featureText {
      font-size: 1.93237vw;
      margin-right: 3.22061vw;
      margin-left: 3.22061vw; }
      .signup-page-featureText else {
        font-size: 12px; }
      .signup-page-featureText else {
        margin-right: 20px; }
      .signup-page-featureText else {
        margin-left: 20px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-featureText {
      font-size: 1.70503vw;
      margin-right: 2.84172vw;
      margin-left: 2.84172vw; }
      .signup-page-featureText else {
        font-size: 12px; }
      .signup-page-featureText else {
        margin-right: 20px; }
      .signup-page-featureText else {
        margin-left: 20px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-featureText {
      font-size: 1.52555vw;
      margin-right: 2.54259vw;
      margin-left: 2.54259vw; }
      .signup-page-featureText else {
        font-size: 12px; }
      .signup-page-featureText else {
        margin-right: 20px; }
      .signup-page-featureText else {
        margin-left: 20px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-featureText {
      font-size: 1.38026vw;
      margin-right: 2.30044vw;
      margin-left: 2.30044vw; }
      .signup-page-featureText else {
        font-size: 12px; }
      .signup-page-featureText else {
        margin-right: 20px; }
      .signup-page-featureText else {
        margin-left: 20px; } }
  .signup-page-featureText > span {
    font-weight: bold; }

.signup-page-gciText {
  font-family: Nunito;
  font-weight: normal;
  line-height: 159.4%;
  color: #504F4F;
  opacity: 0.75;
  font-style: italic;
  font-size: 2.89855vw;
  margin-top: 4.83092vw;
  margin-right: 4.83092vw;
  margin-left: 0vw; }
  @media (min-width: 1300px) {
    .signup-page-gciText {
      font-size: 0.83333vw;
      margin-top: 3.64583vw;
      margin-right: unset;
      margin-left: unset; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-gciText {
      font-size: 0.92593vw;
      margin-top: 4.05093vw;
      margin-right: unset;
      margin-left: unset; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-gciText {
      font-size: 0.83333vw;
      margin-top: 3.64583vw;
      margin-right: unset;
      margin-left: unset; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-gciText {
      font-size: 2.41546vw;
      margin-top: 4.02576vw;
      margin-right: 4.02576vw;
      margin-left: 0vw; }
      .signup-page-gciText else {
        font-size: 12px; }
      .signup-page-gciText else {
        margin-top: 20px; }
      .signup-page-gciText else {
        margin-right: 20px; }
      .signup-page-gciText else {
        margin-left: 0px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-gciText {
      font-size: 1.93237vw;
      margin-top: 3.22061vw;
      margin-right: 3.22061vw;
      margin-left: 0vw; }
      .signup-page-gciText else {
        font-size: 12px; }
      .signup-page-gciText else {
        margin-top: 20px; }
      .signup-page-gciText else {
        margin-right: 20px; }
      .signup-page-gciText else {
        margin-left: 0px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-gciText {
      font-size: 1.70503vw;
      margin-top: 2.84172vw;
      margin-right: 2.84172vw;
      margin-left: 0vw; }
      .signup-page-gciText else {
        font-size: 12px; }
      .signup-page-gciText else {
        margin-top: 20px; }
      .signup-page-gciText else {
        margin-right: 20px; }
      .signup-page-gciText else {
        margin-left: 0px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-gciText {
      font-size: 1.52555vw;
      margin-top: 2.54259vw;
      margin-right: 2.54259vw;
      margin-left: 0vw; }
      .signup-page-gciText else {
        font-size: 12px; }
      .signup-page-gciText else {
        margin-top: 20px; }
      .signup-page-gciText else {
        margin-right: 20px; }
      .signup-page-gciText else {
        margin-left: 0px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-gciText {
      font-size: 1.38026vw;
      margin-top: 2.30044vw;
      margin-right: 2.30044vw;
      margin-left: 0vw; }
      .signup-page-gciText else {
        font-size: 12px; }
      .signup-page-gciText else {
        margin-top: 20px; }
      .signup-page-gciText else {
        margin-right: 20px; }
      .signup-page-gciText else {
        margin-left: 0px; } }

.signup-page-gciThumb {
  background-image: url(/static/media/sanatanCodeInThumbLight.e70cae48.png);
  background-size: contain;
  background-repeat: no-repeat;
  align-self: center;
  pointer-events: auto;
  cursor: pointer;
  min-width: 59.42029vw;
  min-height: 34.78261vw;
  margin-top: 9.66184vw; }
  @media (min-width: 1300px) {
    .signup-page-gciThumb {
      min-width: 23.02083vw;
      min-height: 12.5vw;
      margin-top: 4.16667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-gciThumb {
      min-width: 25.5787vw;
      min-height: 13.88889vw;
      margin-top: 4.62963vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-gciThumb {
      min-width: 23.02083vw;
      min-height: 12.5vw;
      margin-top: 4.16667vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-gciThumb {
      min-width: 49.51691vw;
      min-height: 28.98551vw;
      margin-top: 8.05153vw; }
      .signup-page-gciThumb else {
        min-width: 246px; }
      .signup-page-gciThumb else {
        min-height: 144px; }
      .signup-page-gciThumb else {
        margin-top: 40px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-gciThumb {
      min-width: 39.61353vw;
      min-height: 23.18841vw;
      margin-top: 6.44122vw; }
      .signup-page-gciThumb else {
        min-width: 246px; }
      .signup-page-gciThumb else {
        min-height: 144px; }
      .signup-page-gciThumb else {
        margin-top: 40px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-gciThumb {
      min-width: 34.95311vw;
      min-height: 20.46036vw;
      margin-top: 5.68343vw; }
      .signup-page-gciThumb else {
        min-width: 246px; }
      .signup-page-gciThumb else {
        min-height: 144px; }
      .signup-page-gciThumb else {
        margin-top: 40px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-gciThumb {
      min-width: 31.27384vw;
      min-height: 18.30664vw;
      margin-top: 5.08518vw; }
      .signup-page-gciThumb else {
        min-width: 246px; }
      .signup-page-gciThumb else {
        min-height: 144px; }
      .signup-page-gciThumb else {
        margin-top: 40px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-gciThumb {
      min-width: 28.29538vw;
      min-height: 16.56315vw;
      margin-top: 4.60087vw; }
      .signup-page-gciThumb else {
        min-width: 246px; }
      .signup-page-gciThumb else {
        min-height: 144px; }
      .signup-page-gciThumb else {
        margin-top: 40px; } }

.signup-page-footer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 7.24638vw; }
  @media (min-width: 1300px) {
    .signup-page-footer {
      padding-top: 1.5625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-footer {
      padding-top: 1.73611vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-footer {
      padding-top: 1.5625vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-footer {
      padding-top: 6.03865vw; }
      .signup-page-footer else {
        padding-top: 30px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-footer {
      padding-top: 4.83092vw; }
      .signup-page-footer else {
        padding-top: 30px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-footer {
      padding-top: 4.26257vw; }
      .signup-page-footer else {
        padding-top: 30px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-footer {
      padding-top: 3.81388vw; }
      .signup-page-footer else {
        padding-top: 30px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-footer {
      padding-top: 3.45066vw; }
      .signup-page-footer else {
        padding-top: 30px; } }

.signup-page-socialMediaIcon {
  width: 9.66184vw;
  height: 9.66184vw;
  margin-right: 2.41546vw; }
  @media (min-width: 1300px) {
    .signup-page-socialMediaIcon {
      width: 2.86458vw;
      height: 2.86458vw;
      margin-right: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-socialMediaIcon {
      width: 3.18287vw;
      height: 3.18287vw;
      margin-right: 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-socialMediaIcon {
      width: 2.86458vw;
      height: 2.86458vw;
      margin-right: 1.04167vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-socialMediaIcon {
      width: 8.05153vw;
      height: 8.05153vw;
      margin-right: 2.01288vw; }
      .signup-page-socialMediaIcon else {
        width: 40px; }
      .signup-page-socialMediaIcon else {
        height: 40px; }
      .signup-page-socialMediaIcon else {
        margin-right: 10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-socialMediaIcon {
      width: 6.44122vw;
      height: 6.44122vw;
      margin-right: 1.61031vw; }
      .signup-page-socialMediaIcon else {
        width: 40px; }
      .signup-page-socialMediaIcon else {
        height: 40px; }
      .signup-page-socialMediaIcon else {
        margin-right: 10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-socialMediaIcon {
      width: 5.68343vw;
      height: 5.68343vw;
      margin-right: 1.42086vw; }
      .signup-page-socialMediaIcon else {
        width: 40px; }
      .signup-page-socialMediaIcon else {
        height: 40px; }
      .signup-page-socialMediaIcon else {
        margin-right: 10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-socialMediaIcon {
      width: 5.08518vw;
      height: 5.08518vw;
      margin-right: 1.27129vw; }
      .signup-page-socialMediaIcon else {
        width: 40px; }
      .signup-page-socialMediaIcon else {
        height: 40px; }
      .signup-page-socialMediaIcon else {
        margin-right: 10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-socialMediaIcon {
      width: 4.60087vw;
      height: 4.60087vw;
      margin-right: 1.15022vw; }
      .signup-page-socialMediaIcon else {
        width: 40px; }
      .signup-page-socialMediaIcon else {
        height: 40px; }
      .signup-page-socialMediaIcon else {
        margin-right: 10px; } }

.signup-page-socialMediaRow {
  display: flex;
  flex-direction: row;
  justify-content: center; }

.signup-page-footerText {
  font-family: Nunito;
  color: #504F4F;
  text-align: center;
  font-size: 3.38164vw;
  margin-top: 4.83092vw;
  margin-bottom: 7.24638vw; }
  @media (min-width: 1300px) {
    .signup-page-footerText {
      font-size: 1.04167vw;
      margin-top: 1.5625vw;
      margin-bottom: 2.5vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-footerText {
      font-size: 1.15741vw;
      margin-top: 1.73611vw;
      margin-bottom: 2.77778vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-footerText {
      font-size: 1.04167vw;
      margin-top: 1.5625vw;
      margin-bottom: 2.5vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-footerText {
      font-size: 2.81804vw;
      margin-top: 4.02576vw;
      margin-bottom: 6.03865vw; }
      .signup-page-footerText else {
        font-size: 14px; }
      .signup-page-footerText else {
        margin-top: 20px; }
      .signup-page-footerText else {
        margin-bottom: 30px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-footerText {
      font-size: 2.25443vw;
      margin-top: 3.22061vw;
      margin-bottom: 4.83092vw; }
      .signup-page-footerText else {
        font-size: 14px; }
      .signup-page-footerText else {
        margin-top: 20px; }
      .signup-page-footerText else {
        margin-bottom: 30px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-footerText {
      font-size: 1.9892vw;
      margin-top: 2.84172vw;
      margin-bottom: 4.26257vw; }
      .signup-page-footerText else {
        font-size: 14px; }
      .signup-page-footerText else {
        margin-top: 20px; }
      .signup-page-footerText else {
        margin-bottom: 30px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-footerText {
      font-size: 1.77981vw;
      margin-top: 2.54259vw;
      margin-bottom: 3.81388vw; }
      .signup-page-footerText else {
        font-size: 14px; }
      .signup-page-footerText else {
        margin-top: 20px; }
      .signup-page-footerText else {
        margin-bottom: 30px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-footerText {
      font-size: 1.61031vw;
      margin-top: 2.30044vw;
      margin-bottom: 3.45066vw; }
      .signup-page-footerText else {
        font-size: 14px; }
      .signup-page-footerText else {
        margin-top: 20px; }
      .signup-page-footerText else {
        margin-bottom: 30px; } }

.signup-page-displayOnlySmall {
  display: block;
  display: block; }
  @media (min-width: 1300px) {
    .signup-page-displayOnlySmall {
      display: none; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-displayOnlySmall {
      display: none; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-displayOnlySmall {
      display: none; } }

.signup-page-displayOnlyBig {
  display: none;
  display: none; }
  @media (min-width: 1300px) {
    .signup-page-displayOnlyBig {
      display: block; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-displayOnlyBig {
      display: block; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-displayOnlyBig {
      display: block; } }

.signup-page-hasSubmitted {
  flex: 1 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: flex-start; }

.signup-page-hasSubmittedContainer {
  background: #FFFFFF;
  background: url(/static/media/triangleBG.397c2b5f.svg);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0vw 0.48309vw 4.83092vw rgba(54, 54, 54, 0.15);
  width: 90%;
  border-radius: 2.89855vw;
  height: 33.81643vw;
  margin-top: 16.90821vw; }
  @media (min-width: 1300px) {
    .signup-page-hasSubmittedContainer {
      box-shadow: 0vw 0.10417vw 1.04167vw rgba(54, 54, 54, 0.15);
      width: 37.96875vw;
      border-radius: 0.625vw;
      margin-top: 7.8125vw;
      height: 9.63542vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-hasSubmittedContainer {
      box-shadow: 0vw 0.11574vw 1.15741vw rgba(54, 54, 54, 0.15);
      width: 42.1875vw;
      border-radius: 0.69444vw;
      margin-top: 8.68056vw;
      height: 10.70602vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-hasSubmittedContainer {
      box-shadow: 0vw 0.10417vw 1.04167vw rgba(54, 54, 54, 0.15);
      width: 37.96875vw;
      border-radius: 0.625vw;
      margin-top: 7.8125vw;
      height: 9.63542vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-hasSubmittedContainer {
      box-shadow: 0vw 0.40258vw 4.02576vw rgba(54, 54, 54, 0.15);
      border-radius: 2.41546vw;
      height: 28.18035vw;
      margin-top: 14.09018vw; }
      .signup-page-hasSubmittedContainer else {
        width: 90%; }
      .signup-page-hasSubmittedContainer else {
        border-radius: 12px; }
      .signup-page-hasSubmittedContainer else {
        height: 140px; }
      .signup-page-hasSubmittedContainer else {
        margin-top: 70px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-hasSubmittedContainer {
      box-shadow: 0vw 0.32206vw 3.22061vw rgba(54, 54, 54, 0.15);
      border-radius: 1.93237vw;
      height: 22.54428vw;
      margin-top: 11.27214vw; }
      .signup-page-hasSubmittedContainer else {
        width: 90%; }
      .signup-page-hasSubmittedContainer else {
        border-radius: 12px; }
      .signup-page-hasSubmittedContainer else {
        height: 140px; }
      .signup-page-hasSubmittedContainer else {
        margin-top: 70px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-hasSubmittedContainer {
      box-shadow: 0vw 0.28417vw 2.84172vw rgba(54, 54, 54, 0.15);
      border-radius: 1.70503vw;
      height: 19.89201vw;
      margin-top: 9.94601vw; }
      .signup-page-hasSubmittedContainer else {
        width: 90%; }
      .signup-page-hasSubmittedContainer else {
        border-radius: 12px; }
      .signup-page-hasSubmittedContainer else {
        height: 140px; }
      .signup-page-hasSubmittedContainer else {
        margin-top: 70px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-hasSubmittedContainer {
      box-shadow: 0vw 0.25426vw 2.54259vw rgba(54, 54, 54, 0.15);
      border-radius: 1.52555vw;
      height: 17.79812vw;
      margin-top: 8.89906vw; }
      .signup-page-hasSubmittedContainer else {
        width: 90%; }
      .signup-page-hasSubmittedContainer else {
        border-radius: 12px; }
      .signup-page-hasSubmittedContainer else {
        height: 140px; }
      .signup-page-hasSubmittedContainer else {
        margin-top: 70px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-hasSubmittedContainer {
      box-shadow: 0vw 0.23004vw 2.30044vw rgba(54, 54, 54, 0.15);
      border-radius: 1.38026vw;
      height: 16.10306vw;
      margin-top: 8.05153vw; }
      .signup-page-hasSubmittedContainer else {
        width: 90%; }
      .signup-page-hasSubmittedContainer else {
        border-radius: 12px; }
      .signup-page-hasSubmittedContainer else {
        height: 140px; }
      .signup-page-hasSubmittedContainer else {
        margin-top: 70px; } }

.signup-page-coolDrop {
  width: 13.73792vw;
  height: 15.70048vw;
  margin-top: -7.85024vw; }
  @media (min-width: 1300px) {
    .signup-page-coolDrop {
      width: 4.73958vw;
      height: 5.41667vw;
      margin-top: -2.70833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-coolDrop {
      width: 5.2662vw;
      height: 6.01852vw;
      margin-top: -3.00926vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-coolDrop {
      width: 4.73958vw;
      height: 5.41667vw;
      margin-top: -2.70833vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-coolDrop {
      width: 11.44827vw;
      height: 13.08374vw;
      margin-top: -6.54187vw; }
      .signup-page-coolDrop else {
        width: 56.875px; }
      .signup-page-coolDrop else {
        height: 65px; }
      .signup-page-coolDrop else {
        margin-top: -32.5px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-coolDrop {
      width: 9.15862vw;
      height: 10.46699vw;
      margin-top: -5.23349vw; }
      .signup-page-coolDrop else {
        width: 56.875px; }
      .signup-page-coolDrop else {
        height: 65px; }
      .signup-page-coolDrop else {
        margin-top: -32.5px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-coolDrop {
      width: 8.08113vw;
      height: 9.23558vw;
      margin-top: -4.61779vw; }
      .signup-page-coolDrop else {
        width: 56.875px; }
      .signup-page-coolDrop else {
        height: 65px; }
      .signup-page-coolDrop else {
        margin-top: -32.5px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-coolDrop {
      width: 7.23049vw;
      height: 8.26341vw;
      margin-top: -4.13171vw; }
      .signup-page-coolDrop else {
        width: 56.875px; }
      .signup-page-coolDrop else {
        height: 65px; }
      .signup-page-coolDrop else {
        margin-top: -32.5px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-coolDrop {
      width: 6.54187vw;
      height: 7.47642vw;
      margin-top: -3.73821vw; }
      .signup-page-coolDrop else {
        width: 56.875px; }
      .signup-page-coolDrop else {
        height: 65px; }
      .signup-page-coolDrop else {
        margin-top: -32.5px; } }

.signup-page-dropTitle {
  font-family: Nunito;
  font-weight: bold;
  color: #00ADE5;
  font-size: 5.31401vw;
  margin-top: 1.93237vw; }
  @media (min-width: 1300px) {
    .signup-page-dropTitle {
      font-size: 1.35417vw;
      margin-top: 0.98958vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-dropTitle {
      font-size: 1.50463vw;
      margin-top: 1.09954vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-dropTitle {
      font-size: 1.35417vw;
      margin-top: 0.98958vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-dropTitle {
      font-size: 4.42834vw;
      margin-top: 1.61031vw; }
      .signup-page-dropTitle else {
        font-size: 22px; }
      .signup-page-dropTitle else {
        margin-top: 8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-dropTitle {
      font-size: 3.54267vw;
      margin-top: 1.28824vw; }
      .signup-page-dropTitle else {
        font-size: 22px; }
      .signup-page-dropTitle else {
        margin-top: 8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-dropTitle {
      font-size: 3.12589vw;
      margin-top: 1.13669vw; }
      .signup-page-dropTitle else {
        font-size: 22px; }
      .signup-page-dropTitle else {
        margin-top: 8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-dropTitle {
      font-size: 2.79685vw;
      margin-top: 1.01704vw; }
      .signup-page-dropTitle else {
        font-size: 22px; }
      .signup-page-dropTitle else {
        margin-top: 8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-dropTitle {
      font-size: 2.53048vw;
      margin-top: 0.92017vw; }
      .signup-page-dropTitle else {
        font-size: 22px; }
      .signup-page-dropTitle else {
        margin-top: 8px; } }

.signup-page-dropText {
  font-family: Nunito;
  font-weight: 600;
  color: #504F4F;
  font-size: 3.86473vw;
  margin-top: 1.93237vw;
  margin-bottom: 6.03865vw; }
  @media (min-width: 1300px) {
    .signup-page-dropText {
      font-size: 1.04167vw;
      margin-top: 0.625vw;
      margin-bottom: 2.08333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-dropText {
      font-size: 1.15741vw;
      margin-top: 0.69444vw;
      margin-bottom: 2.31481vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-dropText {
      font-size: 1.04167vw;
      margin-top: 0.625vw;
      margin-bottom: 2.08333vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-dropText {
      font-size: 3.22061vw;
      margin-top: 1.61031vw;
      margin-bottom: 5.03221vw; }
      .signup-page-dropText else {
        font-size: 16px; }
      .signup-page-dropText else {
        margin-top: 8px; }
      .signup-page-dropText else {
        margin-bottom: 25px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-dropText {
      font-size: 2.57649vw;
      margin-top: 1.28824vw;
      margin-bottom: 4.02576vw; }
      .signup-page-dropText else {
        font-size: 16px; }
      .signup-page-dropText else {
        margin-top: 8px; }
      .signup-page-dropText else {
        margin-bottom: 25px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-dropText {
      font-size: 2.27337vw;
      margin-top: 1.13669vw;
      margin-bottom: 3.55215vw; }
      .signup-page-dropText else {
        font-size: 16px; }
      .signup-page-dropText else {
        margin-top: 8px; }
      .signup-page-dropText else {
        margin-bottom: 25px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-dropText {
      font-size: 2.03407vw;
      margin-top: 1.01704vw;
      margin-bottom: 3.17824vw; }
      .signup-page-dropText else {
        font-size: 16px; }
      .signup-page-dropText else {
        margin-top: 8px; }
      .signup-page-dropText else {
        margin-bottom: 25px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-dropText {
      font-size: 1.84035vw;
      margin-top: 0.92017vw;
      margin-bottom: 2.87555vw; }
      .signup-page-dropText else {
        font-size: 16px; }
      .signup-page-dropText else {
        margin-top: 8px; }
      .signup-page-dropText else {
        margin-bottom: 25px; } }

.signup-page-modal-container {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 0;
  transition: 0.3s all ease-in-out;
  pointer-events: none; }
  .signup-page-modal-container.show {
    opacity: 1;
    pointer-events: auto; }

.signup-page-modal-wrapper {
  width: 95%;
  height: 74.87923vw;
  border-radius: 5.7971vw;
  background-color: #FFFFFF;
  background-image: url(/static/media/signupBG.f8e2b5fd.png);
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 1300px) {
    .signup-page-modal-wrapper {
      width: 34.01042vw;
      height: 20.625vw;
      border-radius: 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-modal-wrapper {
      width: 37.78935vw;
      height: 22.91667vw;
      border-radius: 1.38889vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-modal-wrapper {
      width: 34.01042vw;
      height: 20.625vw;
      border-radius: 1.25vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-modal-wrapper {
      height: 62.39936vw;
      border-radius: 4.83092vw; }
      .signup-page-modal-wrapper else {
        width: 95%; }
      .signup-page-modal-wrapper else {
        height: 310px; }
      .signup-page-modal-wrapper else {
        border-radius: 24px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-modal-wrapper {
      height: 49.91948vw;
      border-radius: 3.86473vw; }
      .signup-page-modal-wrapper else {
        width: 95%; }
      .signup-page-modal-wrapper else {
        height: 310px; }
      .signup-page-modal-wrapper else {
        border-radius: 24px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-modal-wrapper {
      height: 44.0466vw;
      border-radius: 3.41006vw; }
      .signup-page-modal-wrapper else {
        width: 95%; }
      .signup-page-modal-wrapper else {
        height: 310px; }
      .signup-page-modal-wrapper else {
        border-radius: 24px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-modal-wrapper {
      height: 39.41012vw;
      border-radius: 3.05111vw; }
      .signup-page-modal-wrapper else {
        width: 95%; }
      .signup-page-modal-wrapper else {
        height: 310px; }
      .signup-page-modal-wrapper else {
        border-radius: 24px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-modal-wrapper {
      height: 35.65677vw;
      border-radius: 2.76052vw; }
      .signup-page-modal-wrapper else {
        width: 95%; }
      .signup-page-modal-wrapper else {
        height: 310px; }
      .signup-page-modal-wrapper else {
        border-radius: 24px; } }

.signup-page-close-btn {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-filter: drop-shadow(-2px 2px 4px #02628262);
          filter: drop-shadow(-2px 2px 4px #02628262);
  background: #00ADE6;
  border-radius: 50%;
  position: absolute;
  font-family: Nunito;
  font-weight: bold; }
  @media (min-width: 1300px) {
    .signup-page-close-btn {
      width: 2.70833vw;
      height: 2.70833vw;
      right: -1.04167vw;
      top: -1.04167vw;
      font-size: 2.08333vw;
      line-height: 2.5vw;
      letter-spacing: -2%; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-close-btn {
      width: 3.00926vw;
      height: 3.00926vw;
      right: -1.15741vw;
      top: -1.15741vw;
      font-size: 2.31481vw;
      line-height: 2.77778vw;
      letter-spacing: -2%; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-close-btn {
      width: 2.70833vw;
      height: 2.70833vw;
      right: -1.04167vw;
      top: -1.04167vw;
      font-size: 2.08333vw;
      line-height: 2.5vw;
      letter-spacing: -2%; } }

.signup-page-radiostreet-checkmark {
  width: 19.32367vw;
  height: 19.32367vw;
  margin-top: 6.76329vw; }
  @media (min-width: 1300px) {
    .signup-page-radiostreet-checkmark {
      width: 4.16667vw;
      height: 4.16667vw;
      margin-top: 2.91667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-radiostreet-checkmark {
      width: 4.62963vw;
      height: 4.62963vw;
      margin-top: 3.24074vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-radiostreet-checkmark {
      width: 4.16667vw;
      height: 4.16667vw;
      margin-top: 2.91667vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-radiostreet-checkmark {
      width: 16.10306vw;
      height: 16.10306vw;
      margin-top: 5.63607vw; }
      .signup-page-radiostreet-checkmark else {
        width: 80px; }
      .signup-page-radiostreet-checkmark else {
        height: 80px; }
      .signup-page-radiostreet-checkmark else {
        margin-top: 28px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-radiostreet-checkmark {
      width: 12.88245vw;
      height: 12.88245vw;
      margin-top: 4.50886vw; }
      .signup-page-radiostreet-checkmark else {
        width: 80px; }
      .signup-page-radiostreet-checkmark else {
        height: 80px; }
      .signup-page-radiostreet-checkmark else {
        margin-top: 28px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-radiostreet-checkmark {
      width: 11.36687vw;
      height: 11.36687vw;
      margin-top: 3.9784vw; }
      .signup-page-radiostreet-checkmark else {
        width: 80px; }
      .signup-page-radiostreet-checkmark else {
        height: 80px; }
      .signup-page-radiostreet-checkmark else {
        margin-top: 28px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-radiostreet-checkmark {
      width: 10.17035vw;
      height: 10.17035vw;
      margin-top: 3.55962vw; }
      .signup-page-radiostreet-checkmark else {
        width: 80px; }
      .signup-page-radiostreet-checkmark else {
        height: 80px; }
      .signup-page-radiostreet-checkmark else {
        margin-top: 28px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-radiostreet-checkmark {
      width: 9.20175vw;
      height: 9.20175vw;
      margin-top: 3.22061vw; }
      .signup-page-radiostreet-checkmark else {
        width: 80px; }
      .signup-page-radiostreet-checkmark else {
        height: 80px; }
      .signup-page-radiostreet-checkmark else {
        margin-top: 28px; } }

.signup-page-congrats {
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #504F4F;
  font-weight: bold;
  font-size: 5.7971vw;
  margin-top: 4.83092vw; }
  @media (min-width: 1300px) {
    .signup-page-congrats {
      font-size: 1.5625vw;
      margin-top: 2.08333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-congrats {
      font-size: 1.73611vw;
      margin-top: 2.31481vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-congrats {
      font-size: 1.5625vw;
      margin-top: 2.08333vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-congrats {
      font-size: 4.83092vw;
      margin-top: 4.02576vw; }
      .signup-page-congrats else {
        font-size: 24px; }
      .signup-page-congrats else {
        margin-top: 20px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-congrats {
      font-size: 3.86473vw;
      margin-top: 3.22061vw; }
      .signup-page-congrats else {
        font-size: 24px; }
      .signup-page-congrats else {
        margin-top: 20px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-congrats {
      font-size: 3.41006vw;
      margin-top: 2.84172vw; }
      .signup-page-congrats else {
        font-size: 24px; }
      .signup-page-congrats else {
        margin-top: 20px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-congrats {
      font-size: 3.05111vw;
      margin-top: 2.54259vw; }
      .signup-page-congrats else {
        font-size: 24px; }
      .signup-page-congrats else {
        margin-top: 20px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-congrats {
      font-size: 2.76052vw;
      margin-top: 2.30044vw; }
      .signup-page-congrats else {
        font-size: 24px; }
      .signup-page-congrats else {
        margin-top: 20px; } }

.signup-page-spy-text {
  color: #504F4F;
  opacity: 0.7;
  line-height: 140%;
  text-align: center;
  font-size: 3.86473vw;
  margin-top: 2.41546vw;
  margin-bottom: 4.83092vw; }
  @media (min-width: 1300px) {
    .signup-page-spy-text {
      font-size: 1.04167vw;
      margin-top: 0.625vw;
      margin-bottom: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-spy-text {
      font-size: 1.15741vw;
      margin-top: 0.69444vw;
      margin-bottom: 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-spy-text {
      font-size: 1.04167vw;
      margin-top: 0.625vw;
      margin-bottom: 1.04167vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-spy-text {
      font-size: 3.22061vw;
      margin-top: 2.01288vw;
      margin-bottom: 4.02576vw; }
      .signup-page-spy-text else {
        font-size: 16px; }
      .signup-page-spy-text else {
        margin-top: 10px; }
      .signup-page-spy-text else {
        margin-bottom: 20px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-spy-text {
      font-size: 2.57649vw;
      margin-top: 1.61031vw;
      margin-bottom: 3.22061vw; }
      .signup-page-spy-text else {
        font-size: 16px; }
      .signup-page-spy-text else {
        margin-top: 10px; }
      .signup-page-spy-text else {
        margin-bottom: 20px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-spy-text {
      font-size: 2.27337vw;
      margin-top: 1.42086vw;
      margin-bottom: 2.84172vw; }
      .signup-page-spy-text else {
        font-size: 16px; }
      .signup-page-spy-text else {
        margin-top: 10px; }
      .signup-page-spy-text else {
        margin-bottom: 20px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-spy-text {
      font-size: 2.03407vw;
      margin-top: 1.27129vw;
      margin-bottom: 2.54259vw; }
      .signup-page-spy-text else {
        font-size: 16px; }
      .signup-page-spy-text else {
        margin-top: 10px; }
      .signup-page-spy-text else {
        margin-bottom: 20px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-spy-text {
      font-size: 1.84035vw;
      margin-top: 1.15022vw;
      margin-bottom: 2.30044vw; }
      .signup-page-spy-text else {
        font-size: 16px; }
      .signup-page-spy-text else {
        margin-top: 10px; }
      .signup-page-spy-text else {
        margin-bottom: 20px; } }

.signup-page-checkbox-container, .signup-page-checkbox-row .signup-page-checkbox-container-last {
  padding: 0.48309vw;
  border-radius: 0.96618vw !important;
  margin-right: 1.93237vw;
  overflow: hidden; }
  @media (min-width: 1300px) {
    .signup-page-checkbox-container, .signup-page-checkbox-row .signup-page-checkbox-container-last {
      padding: 0.20833vw;
      border-radius: 0.20833vw !important;
      margin-right: 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-checkbox-container, .signup-page-checkbox-row .signup-page-checkbox-container-last {
      padding: 0.23148vw;
      border-radius: 0.23148vw !important;
      margin-right: 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-checkbox-container, .signup-page-checkbox-row .signup-page-checkbox-container-last {
      padding: 0.20833vw;
      border-radius: 0.20833vw !important;
      margin-right: 0.41667vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-checkbox-container, .signup-page-checkbox-row .signup-page-checkbox-container-last {
      padding: 0.40258vw;
      border-radius: 0.80515vw !important;
      margin-right: 1.61031vw; }
      .signup-page-checkbox-container else, .signup-page-checkbox-row .signup-page-checkbox-container-last else {
        padding: 2px; }
      .signup-page-checkbox-container else, .signup-page-checkbox-row .signup-page-checkbox-container-last else {
        margin-right: 8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-checkbox-container, .signup-page-checkbox-row .signup-page-checkbox-container-last {
      padding: 0.32206vw;
      border-radius: 0.64412vw !important;
      margin-right: 1.28824vw; }
      .signup-page-checkbox-container else, .signup-page-checkbox-row .signup-page-checkbox-container-last else {
        padding: 2px; }
      .signup-page-checkbox-container else, .signup-page-checkbox-row .signup-page-checkbox-container-last else {
        margin-right: 8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-checkbox-container, .signup-page-checkbox-row .signup-page-checkbox-container-last {
      padding: 0.28417vw;
      border-radius: 0.56834vw !important;
      margin-right: 1.13669vw; }
      .signup-page-checkbox-container else, .signup-page-checkbox-row .signup-page-checkbox-container-last else {
        padding: 2px; }
      .signup-page-checkbox-container else, .signup-page-checkbox-row .signup-page-checkbox-container-last else {
        margin-right: 8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-checkbox-container, .signup-page-checkbox-row .signup-page-checkbox-container-last {
      padding: 0.25426vw;
      border-radius: 0.50852vw !important;
      margin-right: 1.01704vw; }
      .signup-page-checkbox-container else, .signup-page-checkbox-row .signup-page-checkbox-container-last else {
        padding: 2px; }
      .signup-page-checkbox-container else, .signup-page-checkbox-row .signup-page-checkbox-container-last else {
        margin-right: 8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-checkbox-container, .signup-page-checkbox-row .signup-page-checkbox-container-last {
      padding: 0.23004vw;
      border-radius: 0.46009vw !important;
      margin-right: 0.92017vw; }
      .signup-page-checkbox-container else, .signup-page-checkbox-row .signup-page-checkbox-container-last else {
        padding: 2px; }
      .signup-page-checkbox-container else, .signup-page-checkbox-row .signup-page-checkbox-container-last else {
        margin-right: 8px; } }
  .signup-page-checkbox-container span, .signup-page-checkbox-row .signup-page-checkbox-container-last span {
    border: 2px solid #00ADE6 !important;
    transition: 0.3s all ease-in-out !important; }
  .signup-page-checkbox-container div, .signup-page-checkbox-row .signup-page-checkbox-container-last div {
    transition: 0.3s all ease-in-out !important;
    -webkit-transform: scale(0.7);
            transform: scale(0.7); }
    @media (min-width: 1300px) {
      .signup-page-checkbox-container div, .signup-page-checkbox-row .signup-page-checkbox-container-last div {
        -webkit-transform: scale(1);
                transform: scale(1); } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .signup-page-checkbox-container div, .signup-page-checkbox-row .signup-page-checkbox-container-last div {
        -webkit-transform: scale(1);
                transform: scale(1); } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .signup-page-checkbox-container div, .signup-page-checkbox-row .signup-page-checkbox-container-last div {
        -webkit-transform: scale(1);
                transform: scale(1); } }
  .signup-page-checkbox-container input:checked ~ span, .signup-page-checkbox-row .signup-page-checkbox-container-last input:checked ~ span {
    background-color: #00ADE6 !important; }
    @media (min-width: 1300px) {
      .signup-page-checkbox-container input:checked ~ span, .signup-page-checkbox-row .signup-page-checkbox-container-last input:checked ~ span {
        border-radius: 0.20833vw !important; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .signup-page-checkbox-container input:checked ~ span, .signup-page-checkbox-row .signup-page-checkbox-container-last input:checked ~ span {
        border-radius: 0.23148vw !important; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .signup-page-checkbox-container input:checked ~ span, .signup-page-checkbox-row .signup-page-checkbox-container-last input:checked ~ span {
        border-radius: 0.20833vw !important; } }
  .signup-page-checkbox-container input:checked ~ div, .signup-page-checkbox-row .signup-page-checkbox-container-last input:checked ~ div {
    background-color: #00ADE6 !important; }

.signup-page-checkbox-row {
  display: flex;
  align-items: center;
  margin-top: 3.62319vw; }
  @media (min-width: 1300px) {
    .signup-page-checkbox-row {
      margin-top: 0.52083vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-checkbox-row {
      margin-top: 0.5787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-checkbox-row {
      margin-top: 0.52083vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-checkbox-row {
      margin-top: 3.01932vw; }
      .signup-page-checkbox-row else {
        margin-top: 15px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-checkbox-row {
      margin-top: 2.41546vw; }
      .signup-page-checkbox-row else {
        margin-top: 15px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-checkbox-row {
      margin-top: 2.13129vw; }
      .signup-page-checkbox-row else {
        margin-top: 15px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-checkbox-row {
      margin-top: 1.90694vw; }
      .signup-page-checkbox-row else {
        margin-top: 15px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-checkbox-row {
      margin-top: 1.72533vw; }
      .signup-page-checkbox-row else {
        margin-top: 15px; } }
  .signup-page-checkbox-row span {
    display: block;
    opacity: 0.75;
    color: #504F4F;
    font-size: 3.86473vw; }
    @media (min-width: 1300px) {
      .signup-page-checkbox-row span {
        font-size: 0.83333vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .signup-page-checkbox-row span {
        font-size: 0.92593vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .signup-page-checkbox-row span {
        font-size: 0.83333vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .signup-page-checkbox-row span {
        font-size: 3.22061vw; }
        .signup-page-checkbox-row span else {
          font-size: 16px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .signup-page-checkbox-row span {
        font-size: 2.57649vw; }
        .signup-page-checkbox-row span else {
          font-size: 16px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .signup-page-checkbox-row span {
        font-size: 2.27337vw; }
        .signup-page-checkbox-row span else {
          font-size: 16px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .signup-page-checkbox-row span {
        font-size: 2.03407vw; }
        .signup-page-checkbox-row span else {
          font-size: 16px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .signup-page-checkbox-row span {
        font-size: 1.84035vw; }
        .signup-page-checkbox-row span else {
          font-size: 16px; } }
  .signup-page-checkbox-row .signup-page-checkbox-container-last {
    margin-left: 3.62319vw; }
    @media (min-width: 1300px) {
      .signup-page-checkbox-row .signup-page-checkbox-container-last {
        margin-left: 1.5625vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .signup-page-checkbox-row .signup-page-checkbox-container-last {
        margin-left: 1.73611vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .signup-page-checkbox-row .signup-page-checkbox-container-last {
        margin-left: 1.5625vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .signup-page-checkbox-row .signup-page-checkbox-container-last {
        margin-left: 3.01932vw; }
        .signup-page-checkbox-row .signup-page-checkbox-container-last else {
          margin-left: 15px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .signup-page-checkbox-row .signup-page-checkbox-container-last {
        margin-left: 2.41546vw; }
        .signup-page-checkbox-row .signup-page-checkbox-container-last else {
          margin-left: 15px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .signup-page-checkbox-row .signup-page-checkbox-container-last {
        margin-left: 2.13129vw; }
        .signup-page-checkbox-row .signup-page-checkbox-container-last else {
          margin-left: 15px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .signup-page-checkbox-row .signup-page-checkbox-container-last {
        margin-left: 1.90694vw; }
        .signup-page-checkbox-row .signup-page-checkbox-container-last else {
          margin-left: 15px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .signup-page-checkbox-row .signup-page-checkbox-container-last {
        margin-left: 1.72533vw; }
        .signup-page-checkbox-row .signup-page-checkbox-container-last else {
          margin-left: 15px; } }

.checkBox_lato__axPin, .checkBox_lato-light__3GBX1, .checkBox_lato-regular__29QCA, .checkBox_lato-hairline__3N0Ij {
  font-family: 'Lato', sans-serif; }

.checkBox_lato-light__3GBX1 {
  font-weight: 300; }

.checkBox_lato-regular__29QCA {
  font-weight: 400; }

.checkBox_lato-hairline__3N0Ij {
  font-weight: 100; }

.checkBox_checkBoxContainer__3k4Go {
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  border: solid 0.05208vw rgba(0, 173, 230, 0.65);
  border-radius: 0.10417vw;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.38164vw;
  height: 3.38164vw; }
  @media (min-width: 450px) and (max-width: 500px) {
    .checkBox_checkBoxContainer__3k4Go {
      width: 2.81804vw;
      height: 2.81804vw; }
      .checkBox_checkBoxContainer__3k4Go else {
        width: 14px; }
      .checkBox_checkBoxContainer__3k4Go else {
        height: 14px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .checkBox_checkBoxContainer__3k4Go {
      width: 2.25443vw;
      height: 2.25443vw; }
      .checkBox_checkBoxContainer__3k4Go else {
        width: 14px; }
      .checkBox_checkBoxContainer__3k4Go else {
        height: 14px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .checkBox_checkBoxContainer__3k4Go {
      width: 1.9892vw;
      height: 1.9892vw; }
      .checkBox_checkBoxContainer__3k4Go else {
        width: 14px; }
      .checkBox_checkBoxContainer__3k4Go else {
        height: 14px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .checkBox_checkBoxContainer__3k4Go {
      width: 1.77981vw;
      height: 1.77981vw; }
      .checkBox_checkBoxContainer__3k4Go else {
        width: 14px; }
      .checkBox_checkBoxContainer__3k4Go else {
        height: 14px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .checkBox_checkBoxContainer__3k4Go {
      width: 1.61031vw;
      height: 1.61031vw; }
      .checkBox_checkBoxContainer__3k4Go else {
        width: 14px; }
      .checkBox_checkBoxContainer__3k4Go else {
        height: 14px; } }
  @media (min-width: 1300px) {
    .checkBox_checkBoxContainer__3k4Go {
      width: 0.9375vw;
      height: 0.9375vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .checkBox_checkBoxContainer__3k4Go {
      width: 1.04167vw;
      height: 1.04167vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .checkBox_checkBoxContainer__3k4Go {
      width: 0.9375vw;
      height: 0.9375vw; } }

.checkBox_checkBox__1jXL8 {
  position: absolute;
  opacity: 0;
  margin: 0px;
  cursor: pointer;
  width: 4.34783vw;
  height: 4.34783vw; }
  @media (min-width: 450px) and (max-width: 500px) {
    .checkBox_checkBox__1jXL8 {
      width: 3.62319vw;
      height: 3.62319vw; }
      .checkBox_checkBox__1jXL8 else {
        width: 18px; }
      .checkBox_checkBox__1jXL8 else {
        height: 18px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .checkBox_checkBox__1jXL8 {
      width: 2.89855vw;
      height: 2.89855vw; }
      .checkBox_checkBox__1jXL8 else {
        width: 18px; }
      .checkBox_checkBox__1jXL8 else {
        height: 18px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .checkBox_checkBox__1jXL8 {
      width: 2.55754vw;
      height: 2.55754vw; }
      .checkBox_checkBox__1jXL8 else {
        width: 18px; }
      .checkBox_checkBox__1jXL8 else {
        height: 18px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .checkBox_checkBox__1jXL8 {
      width: 2.28833vw;
      height: 2.28833vw; }
      .checkBox_checkBox__1jXL8 else {
        width: 18px; }
      .checkBox_checkBox__1jXL8 else {
        height: 18px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .checkBox_checkBox__1jXL8 {
      width: 2.07039vw;
      height: 2.07039vw; }
      .checkBox_checkBox__1jXL8 else {
        width: 18px; }
      .checkBox_checkBox__1jXL8 else {
        height: 18px; } }
  @media (min-width: 1300px) {
    .checkBox_checkBox__1jXL8 {
      width: 0.9375vw;
      height: 0.9375vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .checkBox_checkBox__1jXL8 {
      width: 1.04167vw;
      height: 1.04167vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .checkBox_checkBox__1jXL8 {
      width: 0.9375vw;
      height: 0.9375vw; } }

.checkBox_checkBoxCustom__x4fIu {
  position: absolute;
  opacity: 0;
  width: 3.55845vw;
  height: 2.63309vw; }
  @media (min-width: 450px) and (max-width: 500px) {
    .checkBox_checkBoxCustom__x4fIu {
      width: 2.96538vw;
      height: 2.19424vw; }
      .checkBox_checkBoxCustom__x4fIu else {
        width: 14.732px; }
      .checkBox_checkBoxCustom__x4fIu else {
        height: 10.901px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .checkBox_checkBoxCustom__x4fIu {
      width: 2.3723vw;
      height: 1.75539vw; }
      .checkBox_checkBoxCustom__x4fIu else {
        width: 14.732px; }
      .checkBox_checkBoxCustom__x4fIu else {
        height: 10.901px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .checkBox_checkBoxCustom__x4fIu {
      width: 2.09321vw;
      height: 1.54888vw; }
      .checkBox_checkBoxCustom__x4fIu else {
        width: 14.732px; }
      .checkBox_checkBoxCustom__x4fIu else {
        height: 10.901px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .checkBox_checkBoxCustom__x4fIu {
      width: 1.87287vw;
      height: 1.38584vw; }
      .checkBox_checkBoxCustom__x4fIu else {
        width: 14.732px; }
      .checkBox_checkBoxCustom__x4fIu else {
        height: 10.901px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .checkBox_checkBoxCustom__x4fIu {
      width: 1.6945vw;
      height: 1.25385vw; }
      .checkBox_checkBoxCustom__x4fIu else {
        width: 14.732px; }
      .checkBox_checkBoxCustom__x4fIu else {
        height: 10.901px; } }
  @media (min-width: 1300px) {
    .checkBox_checkBoxCustom__x4fIu {
      width: 0.76729vw;
      height: 0.56776vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .checkBox_checkBoxCustom__x4fIu {
      width: 0.85255vw;
      height: 0.63084vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .checkBox_checkBoxCustom__x4fIu {
      width: 0.76729vw;
      height: 0.56776vw; } }

.checkBox_emptySpan__q1gLd {
  position: absolute;
  width: 100%;
  height: 100%; }

.checkBox_checkBoxContainer__3k4Go .checkBox_checkBox__1jXL8:checked ~ .checkBox_addBackground__3V2XX {
  background-color: #31e1e9;
  opacity: 1; }

.checkBox_checkBoxContainer__3k4Go .checkBox_checkBox__1jXL8:focus ~ .checkBox_addBackground__3V2XX {
  border-radius: 0.10417vw; }

.checkBox_checkBoxContainer__3k4Go svg {
  position: absolute;
  left: 0; }

.lato, .lato-light, .lato-regular, .lato-hairline {
  font-family: 'Lato', sans-serif; }

.lato-light {
  font-weight: 300; }

.lato-regular {
  font-weight: 400; }

.lato-hairline {
  font-weight: 100; }

.signup-modal-container {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 99;
  top: 20px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 1300px) {
    .signup-modal-container {
      padding: unset; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-modal-container {
      padding: unset; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-modal-container {
      padding: unset; } }

.signup-modal-popup {
  position: relative;
  background: #fff;
  box-shadow: 0px 20px 30px rgba(0, 23, 31, 0.15);
  box-sizing: border-box;
  width: 91.78744vw;
  border-radius: 2.89855vw;
  padding: 5.7971vw 3.62319vw 0vw; }
  @media (min-width: 1300px) {
    .signup-modal-popup {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      border-radius: 0.625vw;
      padding: 0vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-modal-popup {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      border-radius: 0.69444vw;
      padding: 0vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-modal-popup {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      border-radius: 0.625vw;
      padding: 0vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-modal-popup {
      width: 76.48953vw;
      border-radius: 2.41546vw;
      padding: 4.83092vw 3.01932vw 0vw; }
      .signup-modal-popup else {
        width: 380px; }
      .signup-modal-popup else {
        border-radius: 12px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-modal-popup {
      width: 61.19163vw;
      border-radius: 1.93237vw;
      padding: 3.86473vw 2.41546vw 0vw; }
      .signup-modal-popup else {
        width: 380px; }
      .signup-modal-popup else {
        border-radius: 12px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-modal-popup {
      width: 53.99261vw;
      border-radius: 1.70503vw;
      padding: 3.41006vw 2.13129vw 0vw; }
      .signup-modal-popup else {
        width: 380px; }
      .signup-modal-popup else {
        border-radius: 12px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-modal-popup {
      width: 48.30918vw;
      border-radius: 1.52555vw;
      padding: 3.05111vw 1.90694vw 0vw; }
      .signup-modal-popup else {
        width: 380px; }
      .signup-modal-popup else {
        border-radius: 12px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-modal-popup {
      width: 43.7083vw;
      border-radius: 1.38026vw;
      padding: 2.76052vw 1.72533vw 0vw; }
      .signup-modal-popup else {
        width: 380px; }
      .signup-modal-popup else {
        border-radius: 12px; } }

.signup-modal-close {
  position: absolute;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);
  width: 7.24638vw;
  height: 7.24638vw;
  top: -3.62319vw;
  right: -2.41546vw;
  transition: 0.3s all ease-in-out; }
  @media (min-width: 1300px) {
    .signup-modal-close {
      width: 2.60417vw;
      height: 2.60417vw;
      top: -1.30208vw;
      right: -1.30208vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-modal-close {
      width: 2.89352vw;
      height: 2.89352vw;
      top: -1.44676vw;
      right: -1.44676vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-modal-close {
      width: 2.60417vw;
      height: 2.60417vw;
      top: -1.30208vw;
      right: -1.30208vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-modal-close {
      width: 6.03865vw;
      height: 6.03865vw;
      top: -3.01932vw;
      right: -2.01288vw; }
      .signup-modal-close else {
        width: 30px; }
      .signup-modal-close else {
        height: 30px; }
      .signup-modal-close else {
        top: -15px; }
      .signup-modal-close else {
        right: -10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-modal-close {
      width: 4.83092vw;
      height: 4.83092vw;
      top: -2.41546vw;
      right: -1.61031vw; }
      .signup-modal-close else {
        width: 30px; }
      .signup-modal-close else {
        height: 30px; }
      .signup-modal-close else {
        top: -15px; }
      .signup-modal-close else {
        right: -10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-modal-close {
      width: 4.26257vw;
      height: 4.26257vw;
      top: -2.13129vw;
      right: -1.42086vw; }
      .signup-modal-close else {
        width: 30px; }
      .signup-modal-close else {
        height: 30px; }
      .signup-modal-close else {
        top: -15px; }
      .signup-modal-close else {
        right: -10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-modal-close {
      width: 3.81388vw;
      height: 3.81388vw;
      top: -1.90694vw;
      right: -1.27129vw; }
      .signup-modal-close else {
        width: 30px; }
      .signup-modal-close else {
        height: 30px; }
      .signup-modal-close else {
        top: -15px; }
      .signup-modal-close else {
        right: -10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-modal-close {
      width: 3.45066vw;
      height: 3.45066vw;
      top: -1.72533vw;
      right: -1.15022vw; }
      .signup-modal-close else {
        width: 30px; }
      .signup-modal-close else {
        height: 30px; }
      .signup-modal-close else {
        top: -15px; }
      .signup-modal-close else {
        right: -10px; } }
  .signup-modal-close:hover {
    background: #ccf2ff;
    box-shadow: 0px 6px 6px rgba(51, 51, 51, 0.2), 0px 5px 20px rgba(51, 51, 51, 0.08); }

.signup-modal-closeIcon {
  position: relative;
  width: 2.89855vw;
  height: 2.89855vw;
  top: -1.44928vw; }
  @media (min-width: 1300px) {
    .signup-modal-closeIcon {
      width: 1.04167vw;
      height: 1.04167vw;
      top: -0.05208vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-modal-closeIcon {
      width: 1.15741vw;
      height: 1.15741vw;
      top: -0.05787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-modal-closeIcon {
      width: 1.04167vw;
      height: 1.04167vw;
      top: -0.05208vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-modal-closeIcon {
      width: 2.41546vw;
      height: 2.41546vw;
      top: -1.20773vw; }
      .signup-modal-closeIcon else {
        width: 12px; }
      .signup-modal-closeIcon else {
        height: 12px; }
      .signup-modal-closeIcon else {
        top: -6px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-modal-closeIcon {
      width: 1.93237vw;
      height: 1.93237vw;
      top: -0.96618vw; }
      .signup-modal-closeIcon else {
        width: 12px; }
      .signup-modal-closeIcon else {
        height: 12px; }
      .signup-modal-closeIcon else {
        top: -6px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-modal-closeIcon {
      width: 1.70503vw;
      height: 1.70503vw;
      top: -0.85251vw; }
      .signup-modal-closeIcon else {
        width: 12px; }
      .signup-modal-closeIcon else {
        height: 12px; }
      .signup-modal-closeIcon else {
        top: -6px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-modal-closeIcon {
      width: 1.52555vw;
      height: 1.52555vw;
      top: -0.76278vw; }
      .signup-modal-closeIcon else {
        width: 12px; }
      .signup-modal-closeIcon else {
        height: 12px; }
      .signup-modal-closeIcon else {
        top: -6px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-modal-closeIcon {
      width: 1.38026vw;
      height: 1.38026vw;
      top: -0.69013vw; }
      .signup-modal-closeIcon else {
        width: 12px; }
      .signup-modal-closeIcon else {
        height: 12px; }
      .signup-modal-closeIcon else {
        top: -6px; } }
  .signup-modal-closeIcon path {
    fill: #00ADE5;
    opacity: 1; }

.signup-modal-body {
  align-self: center;
  display: flex;
  padding: 0 2.41546vw;
  background: transparent;
  flex-direction: column-reverse; }
  @media (min-width: 1300px) {
    .signup-modal-body {
      min-width: 65.10417vw;
      border-radius: 1.30208vw;
      padding: 0vw 1.66667vw 0vw 0vw;
      background: #fff;
      flex-direction: row; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-modal-body {
      min-width: 72.33796vw;
      border-radius: 1.44676vw;
      padding: 0vw 1.85185vw 0vw 0vw;
      background: #fff;
      flex-direction: row; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-modal-body {
      min-width: 65.10417vw;
      border-radius: 1.30208vw;
      padding: 0vw 1.66667vw 0vw 0vw;
      background: #fff;
      flex-direction: row; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-modal-body {
      padding: 0 2.01288vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-modal-body {
      padding: 0 1.61031vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-modal-body {
      padding: 0 1.42086vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-modal-body {
      padding: 0 1.27129vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-modal-body {
      padding: 0 1.15022vw; } }

.lato, .lato-light, .lato-regular, .lato-hairline {
  font-family: 'Lato', sans-serif; }

.lato-light {
  font-weight: 300; }

.lato-regular {
  font-weight: 400; }

.lato-hairline {
  font-weight: 100; }

.signup-page-login-container {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.85);
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 1300px) {
    .signup-page-login-container {
      padding: unset; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-login-container {
      padding: unset; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-login-container {
      padding: unset; } }

.signup-page-login-popup {
  position: relative;
  background: #fff;
  box-shadow: 0px 20px 30px rgba(0, 23, 31, 0.15);
  box-sizing: border-box;
  width: 91.78744vw;
  border-radius: 2.89855vw;
  padding: 5.7971vw 3.62319vw; }
  @media (min-width: 1300px) {
    .signup-page-login-popup {
      width: 27.91667vw;
      border-radius: 0.625vw;
      padding: 1.5625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-login-popup {
      width: 31.01852vw;
      border-radius: 0.69444vw;
      padding: 1.73611vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-login-popup {
      width: 27.91667vw;
      border-radius: 0.625vw;
      padding: 1.5625vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-login-popup {
      width: 76.48953vw;
      border-radius: 2.41546vw;
      padding: 4.83092vw 3.01932vw; }
      .signup-page-login-popup else {
        width: 380px; }
      .signup-page-login-popup else {
        border-radius: 12px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-login-popup {
      width: 61.19163vw;
      border-radius: 1.93237vw;
      padding: 3.86473vw 2.41546vw; }
      .signup-page-login-popup else {
        width: 380px; }
      .signup-page-login-popup else {
        border-radius: 12px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-login-popup {
      width: 53.99261vw;
      border-radius: 1.70503vw;
      padding: 3.41006vw 2.13129vw; }
      .signup-page-login-popup else {
        width: 380px; }
      .signup-page-login-popup else {
        border-radius: 12px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-login-popup {
      width: 48.30918vw;
      border-radius: 1.52555vw;
      padding: 3.05111vw 1.90694vw; }
      .signup-page-login-popup else {
        width: 380px; }
      .signup-page-login-popup else {
        border-radius: 12px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-login-popup {
      width: 43.7083vw;
      border-radius: 1.38026vw;
      padding: 2.76052vw 1.72533vw; }
      .signup-page-login-popup else {
        width: 380px; }
      .signup-page-login-popup else {
        border-radius: 12px; } }

.signup-page-login-signinText {
  font-family: Lato;
  font-weight: bold;
  color: #00ADE5;
  opacity: 0.8;
  font-size: 4.34783vw;
  line-height: 5.07246vw; }
  @media (min-width: 1300px) {
    .signup-page-login-signinText {
      font-size: 1.82292vw;
      line-height: 2.1875vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-login-signinText {
      font-size: 2.02546vw;
      line-height: 2.43056vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-login-signinText {
      font-size: 1.82292vw;
      line-height: 2.1875vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-login-signinText {
      font-size: 3.62319vw;
      line-height: 4.22705vw; }
      .signup-page-login-signinText else {
        font-size: 18px; }
      .signup-page-login-signinText else {
        line-height: 21px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-login-signinText {
      font-size: 2.89855vw;
      line-height: 3.38164vw; }
      .signup-page-login-signinText else {
        font-size: 18px; }
      .signup-page-login-signinText else {
        line-height: 21px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-login-signinText {
      font-size: 2.55754vw;
      line-height: 2.9838vw; }
      .signup-page-login-signinText else {
        font-size: 18px; }
      .signup-page-login-signinText else {
        line-height: 21px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-login-signinText {
      font-size: 2.28833vw;
      line-height: 2.66972vw; }
      .signup-page-login-signinText else {
        font-size: 18px; }
      .signup-page-login-signinText else {
        line-height: 21px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-login-signinText {
      font-size: 2.07039vw;
      line-height: 2.41546vw; }
      .signup-page-login-signinText else {
        font-size: 18px; }
      .signup-page-login-signinText else {
        line-height: 21px; } }

.signup-page-login-helpText {
  font-family: Nunito;
  font-weight: 600;
  color: #504F4F;
  font-size: 3.38164vw;
  line-height: 5.3913vw;
  margin-top: 1.44928vw; }
  @media (min-width: 1300px) {
    .signup-page-login-helpText {
      font-size: 1.04167vw;
      line-height: 159.4%;
      margin-top: 0.78125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-login-helpText {
      font-size: 1.15741vw;
      line-height: 159.4%;
      margin-top: 0.86806vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-login-helpText {
      font-size: 1.04167vw;
      line-height: 159.4%;
      margin-top: 0.78125vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-login-helpText {
      font-size: 2.81804vw;
      line-height: 4.49275vw;
      margin-top: 1.20773vw; }
      .signup-page-login-helpText else {
        font-size: 14px; }
      .signup-page-login-helpText else {
        line-height: 22.32px; }
      .signup-page-login-helpText else {
        margin-top: 6px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-login-helpText {
      font-size: 2.25443vw;
      line-height: 3.5942vw;
      margin-top: 0.96618vw; }
      .signup-page-login-helpText else {
        font-size: 14px; }
      .signup-page-login-helpText else {
        line-height: 22.32px; }
      .signup-page-login-helpText else {
        margin-top: 6px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-login-helpText {
      font-size: 1.9892vw;
      line-height: 3.17136vw;
      margin-top: 0.85251vw; }
      .signup-page-login-helpText else {
        font-size: 14px; }
      .signup-page-login-helpText else {
        line-height: 22.32px; }
      .signup-page-login-helpText else {
        margin-top: 6px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-login-helpText {
      font-size: 1.77981vw;
      line-height: 2.83753vw;
      margin-top: 0.76278vw; }
      .signup-page-login-helpText else {
        font-size: 14px; }
      .signup-page-login-helpText else {
        line-height: 22.32px; }
      .signup-page-login-helpText else {
        margin-top: 6px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-login-helpText {
      font-size: 1.61031vw;
      line-height: 2.56729vw;
      margin-top: 0.69013vw; }
      .signup-page-login-helpText else {
        font-size: 14px; }
      .signup-page-login-helpText else {
        line-height: 22.32px; }
      .signup-page-login-helpText else {
        margin-top: 6px; } }

.signup-page-login-input, .signup-page-login-otpInput, .signup-page-login-phoneInput {
  background-color: #fff;
  color: #111111 !important;
  border: none !important;
  outline: none !important;
  font-family: Nunito !important;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);
  font-size: 3.38164vw !important;
  width: 78.50242vw !important;
  height: 10.14493vw !important;
  border-radius: 0.96618vw !important;
  margin-top: 4.10628vw !important;
  padding-left: 2.657vw !important; }
  .signup-page-login-input.signup-page-login-inputError, .signup-page-login-inputError.signup-page-login-otpInput, .signup-page-login-inputError.signup-page-login-phoneInput {
    background-color: #F9E3E3 !important; }
  @media (min-width: 1300px) {
    .signup-page-login-input, .signup-page-login-otpInput, .signup-page-login-phoneInput {
      font-size: 0.88542vw !important;
      width: 23.95833vw !important;
      height: 2.91667vw !important;
      border-radius: 0.20833vw !important;
      margin-top: 1.5625vw !important;
      padding-left: 0.83333vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-login-input, .signup-page-login-otpInput, .signup-page-login-phoneInput {
      font-size: 0.9838vw !important;
      width: 26.62037vw !important;
      height: 3.24074vw !important;
      border-radius: 0.23148vw !important;
      margin-top: 1.73611vw !important;
      padding-left: 0.92593vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-login-input, .signup-page-login-otpInput, .signup-page-login-phoneInput {
      font-size: 0.88542vw !important;
      width: 23.95833vw !important;
      height: 2.91667vw !important;
      border-radius: 0.20833vw !important;
      margin-top: 1.5625vw !important;
      padding-left: 0.83333vw !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-login-input, .signup-page-login-otpInput, .signup-page-login-phoneInput {
      font-size: 2.81804vw !important;
      width: 65.41868vw !important;
      height: 8.45411vw !important;
      border-radius: 0.80515vw !important;
      margin-top: 3.4219vw !important;
      padding-left: 2.21417vw !important; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-login-input, .signup-page-login-otpInput, .signup-page-login-phoneInput {
      font-size: 2.25443vw !important;
      width: 52.33494vw !important;
      height: 6.76329vw !important;
      border-radius: 0.64412vw !important;
      margin-top: 2.73752vw !important;
      padding-left: 1.77134vw !important; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-login-input, .signup-page-login-otpInput, .signup-page-login-phoneInput {
      font-size: 1.9892vw !important;
      width: 46.17789vw !important;
      height: 5.9676vw !important;
      border-radius: 0.56834vw !important;
      margin-top: 2.41546vw !important;
      padding-left: 1.56294vw !important; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-login-input, .signup-page-login-otpInput, .signup-page-login-phoneInput {
      font-size: 1.77981vw !important;
      width: 41.31706vw !important;
      height: 5.33944vw !important;
      border-radius: 0.50852vw !important;
      margin-top: 2.1612vw !important;
      padding-left: 1.39842vw !important; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-login-input, .signup-page-login-otpInput, .signup-page-login-phoneInput {
      font-size: 1.61031vw !important;
      width: 37.3821vw !important;
      height: 4.83092vw !important;
      border-radius: 0.46009vw !important;
      margin-top: 1.95537vw !important;
      padding-left: 1.26524vw !important; } }
  .signup-page-login-input:focus, .signup-page-login-otpInput:focus, .signup-page-login-phoneInput:focus {
    box-shadow: 0 0 0 2px rgba(0, 173, 229, 0.6), 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08); }
  .signup-page-login-input::-webkit-input-placeholder, .signup-page-login-otpInput::-webkit-input-placeholder, .signup-page-login-phoneInput::-webkit-input-placeholder {
    color: rgba(17, 17, 17, 0.48) !important;
    opacity: 0.7 !important; }
  .signup-page-login-input::placeholder, .signup-page-login-otpInput::placeholder, .signup-page-login-phoneInput::placeholder {
    color: rgba(17, 17, 17, 0.48) !important;
    opacity: 0.7 !important; }

.signup-page-login-otpInput {
  width: 8.21256vw !important;
  margin-top: 0vw !important;
  padding-left: 0vw !important;
  font-size: 3.86473vw !important; }
  @media (min-width: 1300px) {
    .signup-page-login-otpInput {
      width: 2.91667vw !important;
      margin-top: 0.52083vw !important;
      padding-left: 0vw !important;
      font-size: 1.14583vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-login-otpInput {
      width: 3.24074vw !important;
      margin-top: 0.5787vw !important;
      padding-left: 0vw !important;
      font-size: 1.27315vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-login-otpInput {
      width: 2.91667vw !important;
      margin-top: 0.52083vw !important;
      padding-left: 0vw !important;
      font-size: 1.14583vw !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-login-otpInput {
      width: 6.8438vw !important;
      margin-top: 0vw !important;
      padding-left: 0vw !important;
      font-size: 3.22061vw !important; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-login-otpInput {
      width: 5.47504vw !important;
      margin-top: 0vw !important;
      padding-left: 0vw !important;
      font-size: 2.57649vw !important; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-login-otpInput {
      width: 4.83092vw !important;
      margin-top: 0vw !important;
      padding-left: 0vw !important;
      font-size: 2.27337vw !important; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-login-otpInput {
      width: 4.3224vw !important;
      margin-top: 0vw !important;
      padding-left: 0vw !important;
      font-size: 2.03407vw !important; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-login-otpInput {
      width: 3.91074vw !important;
      margin-top: 0vw !important;
      padding-left: 0vw !important;
      font-size: 1.84035vw !important; } }

.signup-page-login-phoneInputContainer {
  display: flex;
  flex-direction: space-between;
  margin-top: 4.10628vw !important;
  align-self: flex-start; }
  @media (min-width: 1300px) {
    .signup-page-login-phoneInputContainer {
      margin-top: 1.5625vw !important;
      align-self: center; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-login-phoneInputContainer {
      margin-top: 1.73611vw !important;
      align-self: center; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-login-phoneInputContainer {
      margin-top: 1.5625vw !important;
      align-self: center; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-login-phoneInputContainer {
      margin-top: 3.4219vw !important; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-login-phoneInputContainer {
      margin-top: 2.73752vw !important; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-login-phoneInputContainer {
      margin-top: 2.41546vw !important; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-login-phoneInputContainer {
      margin-top: 2.1612vw !important; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-login-phoneInputContainer {
      margin-top: 1.95537vw !important; } }

.signup-page-login-otpContainer {
  display: flex;
  justify-content: space-between;
  width: 60.38647vw !important; }
  @media (min-width: 1300px) {
    .signup-page-login-otpContainer {
      width: 18.4375vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-login-otpContainer {
      width: 20.48611vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-login-otpContainer {
      width: 18.4375vw !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-login-otpContainer {
      width: 50.32206vw !important; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-login-otpContainer {
      width: 40.25765vw !important; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-login-otpContainer {
      width: 35.52145vw !important; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-login-otpContainer {
      width: 31.78235vw !important; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-login-otpContainer {
      width: 28.75546vw !important; } }

.signup-page-login-dropdownButtonClass {
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);
  background-color: transparent !important;
  transition: 0.3s all ease-in-out;
  border: unset !important;
  width: 10.86957vw;
  font-size: 3.38164vw; }
  @media (min-width: 1300px) {
    .signup-page-login-dropdownButtonClass {
      border: unset !important;
      width: 3.64583vw;
      font-size: 0.9375vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-login-dropdownButtonClass {
      border: unset !important;
      width: 4.05093vw;
      font-size: 1.04167vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-login-dropdownButtonClass {
      border: unset !important;
      width: 3.64583vw;
      font-size: 0.9375vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-login-dropdownButtonClass {
      border: unset !important;
      width: 9.05797vw;
      font-size: 2.81804vw; }
      .signup-page-login-dropdownButtonClass else {
        width: 45px; }
      .signup-page-login-dropdownButtonClass else {
        font-size: 14px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-login-dropdownButtonClass {
      border: unset !important;
      width: 7.24638vw;
      font-size: 2.25443vw; }
      .signup-page-login-dropdownButtonClass else {
        width: 45px; }
      .signup-page-login-dropdownButtonClass else {
        font-size: 14px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-login-dropdownButtonClass {
      border: unset !important;
      width: 6.39386vw;
      font-size: 1.9892vw; }
      .signup-page-login-dropdownButtonClass else {
        width: 45px; }
      .signup-page-login-dropdownButtonClass else {
        font-size: 14px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-login-dropdownButtonClass {
      border: unset !important;
      width: 5.72082vw;
      font-size: 1.77981vw; }
      .signup-page-login-dropdownButtonClass else {
        width: 45px; }
      .signup-page-login-dropdownButtonClass else {
        font-size: 14px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-login-dropdownButtonClass {
      border: unset !important;
      width: 5.17598vw;
      font-size: 1.61031vw; }
      .signup-page-login-dropdownButtonClass else {
        width: 45px; }
      .signup-page-login-dropdownButtonClass else {
        font-size: 14px; } }
  .signup-page-login-dropdownButtonClass:hover {
    background-color: rgba(0, 173, 229, 0.2) !important; }

.signup-page-login-phoneInput {
  width: 69.08213vw !important;
  margin-left: 12.56039vw !important; }
  @media (min-width: 1300px) {
    .signup-page-login-phoneInput {
      width: 19.27083vw !important;
      margin-left: 4.42708vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-login-phoneInput {
      width: 21.41204vw !important;
      margin-left: 4.91898vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-login-phoneInput {
      width: 19.27083vw !important;
      margin-left: 4.42708vw !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-login-phoneInput {
      width: 57.56844vw !important;
      margin-left: 10.46699vw !important; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-login-phoneInput {
      width: 46.05475vw !important;
      margin-left: 8.37359vw !important; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-login-phoneInput {
      width: 40.63654vw !important;
      margin-left: 7.38846vw !important; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-login-phoneInput {
      width: 36.35901vw !important;
      margin-left: 6.61073vw !important; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-login-phoneInput {
      width: 32.89625vw !important;
      margin-left: 5.98114vw !important; } }

.signup-page-login-notOnTekie {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  color: #504F4F;
  font-size: 3.38164vw;
  line-height: 22.32%;
  margin-top: 6.03865vw;
  align-self: flex-start; }
  @media (min-width: 1300px) {
    .signup-page-login-notOnTekie {
      font-size: 1.04167vw;
      line-height: 159.4%;
      margin-top: 2.08333vw;
      margin-left: unset;
      align-self: flex-start; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-login-notOnTekie {
      font-size: 1.15741vw;
      line-height: 159.4%;
      margin-top: 2.31481vw;
      margin-left: unset;
      align-self: flex-start; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-login-notOnTekie {
      font-size: 1.04167vw;
      line-height: 159.4%;
      margin-top: 2.08333vw;
      margin-left: unset;
      align-self: flex-start; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-login-notOnTekie {
      font-size: 2.81804vw;
      margin-top: 5.03221vw; }
      .signup-page-login-notOnTekie else {
        font-size: 14px; }
      .signup-page-login-notOnTekie else {
        line-height: 22.32%; }
      .signup-page-login-notOnTekie else {
        margin-top: 25px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-login-notOnTekie {
      font-size: 2.25443vw;
      margin-top: 4.02576vw; }
      .signup-page-login-notOnTekie else {
        font-size: 14px; }
      .signup-page-login-notOnTekie else {
        line-height: 22.32%; }
      .signup-page-login-notOnTekie else {
        margin-top: 25px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-login-notOnTekie {
      font-size: 1.9892vw;
      margin-top: 3.55215vw; }
      .signup-page-login-notOnTekie else {
        font-size: 14px; }
      .signup-page-login-notOnTekie else {
        line-height: 22.32%; }
      .signup-page-login-notOnTekie else {
        margin-top: 25px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-login-notOnTekie {
      font-size: 1.77981vw;
      margin-top: 3.17824vw; }
      .signup-page-login-notOnTekie else {
        font-size: 14px; }
      .signup-page-login-notOnTekie else {
        line-height: 22.32%; }
      .signup-page-login-notOnTekie else {
        margin-top: 25px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-login-notOnTekie {
      font-size: 1.61031vw;
      margin-top: 2.87555vw; }
      .signup-page-login-notOnTekie else {
        font-size: 14px; }
      .signup-page-login-notOnTekie else {
        line-height: 22.32%; }
      .signup-page-login-notOnTekie else {
        margin-top: 25px; } }

.signup-page-login-signupButton {
  align-self: center;
  color: white;
  font-family: Nunito;
  font-weight: 600;
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  width: auto;
  padding-left: 6.03865vw;
  padding-top: 2.41546vw;
  padding-bottom: 2.41546vw;
  padding-right: 6.03865vw;
  border-radius: 1.44928vw;
  margin-top: 5.7971vw;
  font-size: 3.38164vw;
  background: linear-gradient(180deg, #34E4EA 0%, #00ADE6 100%);
  align-self: flex-start; }
  @media (min-width: 1300px) {
    .signup-page-login-signupButton {
      padding-left: 1.5625vw;
      padding-top: 0.78125vw;
      padding-bottom: 0.78125vw;
      padding-right: 1.5625vw;
      border-radius: 0.72917vw;
      margin-top: 2.08333vw;
      font-size: 1.30208vw;
      margin-bottom: 0.78125vw;
      background: linear-gradient(180deg, #34E4EA 0%, #00ADE6 100%);
      align-self: unset; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-login-signupButton {
      padding-left: 1.73611vw;
      padding-top: 0.86806vw;
      padding-bottom: 0.86806vw;
      padding-right: 1.73611vw;
      border-radius: 0.81019vw;
      margin-top: 2.31481vw;
      font-size: 1.44676vw;
      margin-bottom: 0.86806vw;
      background: linear-gradient(180deg, #34E4EA 0%, #00ADE6 100%);
      align-self: unset; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-login-signupButton {
      padding-left: 1.5625vw;
      padding-top: 0.78125vw;
      padding-bottom: 0.78125vw;
      padding-right: 1.5625vw;
      border-radius: 0.72917vw;
      margin-top: 2.08333vw;
      font-size: 1.30208vw;
      margin-bottom: 0.78125vw;
      background: linear-gradient(180deg, #34E4EA 0%, #00ADE6 100%);
      align-self: unset; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-login-signupButton {
      padding-left: 5.03221vw;
      padding-top: 2.01288vw;
      padding-bottom: 2.01288vw;
      padding-right: 5.03221vw;
      border-radius: 1.20773vw;
      margin-top: 4.83092vw;
      font-size: 2.81804vw; }
      .signup-page-login-signupButton else {
        padding-left: 25px; }
      .signup-page-login-signupButton else {
        padding-top: 10px; }
      .signup-page-login-signupButton else {
        padding-bottom: 10px; }
      .signup-page-login-signupButton else {
        padding-right: 25px; }
      .signup-page-login-signupButton else {
        border-radius: 6px; }
      .signup-page-login-signupButton else {
        margin-top: 24px; }
      .signup-page-login-signupButton else {
        font-size: 14px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-login-signupButton {
      padding-left: 4.02576vw;
      padding-top: 1.61031vw;
      padding-bottom: 1.61031vw;
      padding-right: 4.02576vw;
      border-radius: 0.96618vw;
      margin-top: 3.86473vw;
      font-size: 2.25443vw; }
      .signup-page-login-signupButton else {
        padding-left: 25px; }
      .signup-page-login-signupButton else {
        padding-top: 10px; }
      .signup-page-login-signupButton else {
        padding-bottom: 10px; }
      .signup-page-login-signupButton else {
        padding-right: 25px; }
      .signup-page-login-signupButton else {
        border-radius: 6px; }
      .signup-page-login-signupButton else {
        margin-top: 24px; }
      .signup-page-login-signupButton else {
        font-size: 14px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-login-signupButton {
      padding-left: 3.55215vw;
      padding-top: 1.42086vw;
      padding-bottom: 1.42086vw;
      padding-right: 3.55215vw;
      border-radius: 0.85251vw;
      margin-top: 3.41006vw;
      font-size: 1.9892vw; }
      .signup-page-login-signupButton else {
        padding-left: 25px; }
      .signup-page-login-signupButton else {
        padding-top: 10px; }
      .signup-page-login-signupButton else {
        padding-bottom: 10px; }
      .signup-page-login-signupButton else {
        padding-right: 25px; }
      .signup-page-login-signupButton else {
        border-radius: 6px; }
      .signup-page-login-signupButton else {
        margin-top: 24px; }
      .signup-page-login-signupButton else {
        font-size: 14px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-login-signupButton {
      padding-left: 3.17824vw;
      padding-top: 1.27129vw;
      padding-bottom: 1.27129vw;
      padding-right: 3.17824vw;
      border-radius: 0.76278vw;
      margin-top: 3.05111vw;
      font-size: 1.77981vw; }
      .signup-page-login-signupButton else {
        padding-left: 25px; }
      .signup-page-login-signupButton else {
        padding-top: 10px; }
      .signup-page-login-signupButton else {
        padding-bottom: 10px; }
      .signup-page-login-signupButton else {
        padding-right: 25px; }
      .signup-page-login-signupButton else {
        border-radius: 6px; }
      .signup-page-login-signupButton else {
        margin-top: 24px; }
      .signup-page-login-signupButton else {
        font-size: 14px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-login-signupButton {
      padding-left: 2.87555vw;
      padding-top: 1.15022vw;
      padding-bottom: 1.15022vw;
      padding-right: 2.87555vw;
      border-radius: 0.69013vw;
      margin-top: 2.76052vw;
      font-size: 1.61031vw; }
      .signup-page-login-signupButton else {
        padding-left: 25px; }
      .signup-page-login-signupButton else {
        padding-top: 10px; }
      .signup-page-login-signupButton else {
        padding-bottom: 10px; }
      .signup-page-login-signupButton else {
        padding-right: 25px; }
      .signup-page-login-signupButton else {
        border-radius: 6px; }
      .signup-page-login-signupButton else {
        margin-top: 24px; }
      .signup-page-login-signupButton else {
        font-size: 14px; } }

.signup-page-login-loadingIconContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.62319vw;
  height: 3.62319vw;
  margin-right: 1.20773vw;
  top: -0.24155vw; }
  @media (min-width: 1300px) {
    .signup-page-login-loadingIconContainer {
      width: 1.5625vw;
      height: 1.5625vw;
      margin-right: 0vw;
      top: -0.05208vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-login-loadingIconContainer {
      width: 1.73611vw;
      height: 1.73611vw;
      margin-right: 0vw;
      top: -0.05787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-login-loadingIconContainer {
      width: 1.5625vw;
      height: 1.5625vw;
      margin-right: 0vw;
      top: -0.05208vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-login-loadingIconContainer {
      width: 3.01932vw;
      height: 3.01932vw;
      margin-right: 1.00644vw;
      top: -0.20129vw; }
      .signup-page-login-loadingIconContainer else {
        width: 15px; }
      .signup-page-login-loadingIconContainer else {
        height: 15px; }
      .signup-page-login-loadingIconContainer else {
        margin-right: 5px; }
      .signup-page-login-loadingIconContainer else {
        top: -1px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-login-loadingIconContainer {
      width: 2.41546vw;
      height: 2.41546vw;
      margin-right: 0.80515vw;
      top: -0.16103vw; }
      .signup-page-login-loadingIconContainer else {
        width: 15px; }
      .signup-page-login-loadingIconContainer else {
        height: 15px; }
      .signup-page-login-loadingIconContainer else {
        margin-right: 5px; }
      .signup-page-login-loadingIconContainer else {
        top: -1px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-login-loadingIconContainer {
      width: 2.13129vw;
      height: 2.13129vw;
      margin-right: 0.71043vw;
      top: -0.14209vw; }
      .signup-page-login-loadingIconContainer else {
        width: 15px; }
      .signup-page-login-loadingIconContainer else {
        height: 15px; }
      .signup-page-login-loadingIconContainer else {
        margin-right: 5px; }
      .signup-page-login-loadingIconContainer else {
        top: -1px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-login-loadingIconContainer {
      width: 1.90694vw;
      height: 1.90694vw;
      margin-right: 0.63565vw;
      top: -0.12713vw; }
      .signup-page-login-loadingIconContainer else {
        width: 15px; }
      .signup-page-login-loadingIconContainer else {
        height: 15px; }
      .signup-page-login-loadingIconContainer else {
        margin-right: 5px; }
      .signup-page-login-loadingIconContainer else {
        top: -1px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-login-loadingIconContainer {
      width: 1.72533vw;
      height: 1.72533vw;
      margin-right: 0.57511vw;
      top: -0.11502vw; }
      .signup-page-login-loadingIconContainer else {
        width: 15px; }
      .signup-page-login-loadingIconContainer else {
        height: 15px; }
      .signup-page-login-loadingIconContainer else {
        margin-right: 5px; }
      .signup-page-login-loadingIconContainer else {
        top: -1px; } }

.signup-page-login-error {
  font-family: Nunito;
  font-weight: 500;
  position: relative;
  width: 100%;
  color: #DB524E;
  height: 2.41546vw;
  top: 0.96618vw;
  left: 0.96618vw;
  font-size: 2.89855vw;
  align-self: flex-start; }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-login-error {
      height: 2.01288vw;
      top: 0.80515vw;
      left: 0.80515vw;
      font-size: 2.41546vw; }
      .signup-page-login-error else {
        height: 10px; }
      .signup-page-login-error else {
        top: 4px; }
      .signup-page-login-error else {
        left: 4px; }
      .signup-page-login-error else {
        font-size: 12px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-login-error {
      height: 1.61031vw;
      top: 0.64412vw;
      left: 0.64412vw;
      font-size: 1.93237vw; }
      .signup-page-login-error else {
        height: 10px; }
      .signup-page-login-error else {
        top: 4px; }
      .signup-page-login-error else {
        left: 4px; }
      .signup-page-login-error else {
        font-size: 12px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-login-error {
      height: 1.42086vw;
      top: 0.56834vw;
      left: 0.56834vw;
      font-size: 1.70503vw; }
      .signup-page-login-error else {
        height: 10px; }
      .signup-page-login-error else {
        top: 4px; }
      .signup-page-login-error else {
        left: 4px; }
      .signup-page-login-error else {
        font-size: 12px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-login-error {
      height: 1.27129vw;
      top: 0.50852vw;
      left: 0.50852vw;
      font-size: 1.52555vw; }
      .signup-page-login-error else {
        height: 10px; }
      .signup-page-login-error else {
        top: 4px; }
      .signup-page-login-error else {
        left: 4px; }
      .signup-page-login-error else {
        font-size: 12px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-login-error {
      height: 1.15022vw;
      top: 0.46009vw;
      left: 0.46009vw;
      font-size: 1.38026vw; }
      .signup-page-login-error else {
        height: 10px; }
      .signup-page-login-error else {
        top: 4px; }
      .signup-page-login-error else {
        left: 4px; }
      .signup-page-login-error else {
        font-size: 12px; } }
  @media (min-width: 1300px) {
    .signup-page-login-error {
      height: 0.36458vw;
      top: 0.26042vw;
      left: 0.52083vw;
      font-size: 0.88542vw;
      align-self: center; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-login-error {
      height: 0.40509vw;
      top: 0.28935vw;
      left: 0.5787vw;
      font-size: 0.9838vw;
      align-self: center; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-login-error {
      height: 0.36458vw;
      top: 0.26042vw;
      left: 0.52083vw;
      font-size: 0.88542vw;
      align-self: center; } }
  .signup-page-login-error.signup-page-login-otpError {
    left: 0vw;
    top: 1.20773vw; }
    @media (min-width: 1300px) {
      .signup-page-login-error.signup-page-login-otpError {
        left: 3.125vw;
        top: 0.52083vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .signup-page-login-error.signup-page-login-otpError {
        left: 3.47222vw;
        top: 0.5787vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .signup-page-login-error.signup-page-login-otpError {
        left: 3.125vw;
        top: 0.52083vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .signup-page-login-error.signup-page-login-otpError {
        left: 0vw;
        top: 1.00644vw; }
        .signup-page-login-error.signup-page-login-otpError else {
          left: 0px; }
        .signup-page-login-error.signup-page-login-otpError else {
          top: 5px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .signup-page-login-error.signup-page-login-otpError {
        left: 0vw;
        top: 0.80515vw; }
        .signup-page-login-error.signup-page-login-otpError else {
          left: 0px; }
        .signup-page-login-error.signup-page-login-otpError else {
          top: 5px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .signup-page-login-error.signup-page-login-otpError {
        left: 0vw;
        top: 0.71043vw; }
        .signup-page-login-error.signup-page-login-otpError else {
          left: 0px; }
        .signup-page-login-error.signup-page-login-otpError else {
          top: 5px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .signup-page-login-error.signup-page-login-otpError {
        left: 0vw;
        top: 0.63565vw; }
        .signup-page-login-error.signup-page-login-otpError else {
          left: 0px; }
        .signup-page-login-error.signup-page-login-otpError else {
          top: 5px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .signup-page-login-error.signup-page-login-otpError {
        left: 0vw;
        top: 0.57511vw; }
        .signup-page-login-error.signup-page-login-otpError else {
          left: 0px; }
        .signup-page-login-error.signup-page-login-otpError else {
          top: 5px; } }

.signup-page-login-loadingIcon {
  position: relative;
  min-width: 2.89855vw;
  min-height: 2.89855vw; }
  @media (min-width: 1300px) {
    .signup-page-login-loadingIcon {
      min-width: 0.78125vw;
      min-height: 0.78125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-login-loadingIcon {
      min-width: 0.86806vw;
      min-height: 0.86806vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-login-loadingIcon {
      min-width: 0.78125vw;
      min-height: 0.78125vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-login-loadingIcon {
      min-width: 2.41546vw;
      min-height: 2.41546vw; }
      .signup-page-login-loadingIcon else {
        min-width: 12px; }
      .signup-page-login-loadingIcon else {
        min-height: 12px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-login-loadingIcon {
      min-width: 1.93237vw;
      min-height: 1.93237vw; }
      .signup-page-login-loadingIcon else {
        min-width: 12px; }
      .signup-page-login-loadingIcon else {
        min-height: 12px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-login-loadingIcon {
      min-width: 1.70503vw;
      min-height: 1.70503vw; }
      .signup-page-login-loadingIcon else {
        min-width: 12px; }
      .signup-page-login-loadingIcon else {
        min-height: 12px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-login-loadingIcon {
      min-width: 1.52555vw;
      min-height: 1.52555vw; }
      .signup-page-login-loadingIcon else {
        min-width: 12px; }
      .signup-page-login-loadingIcon else {
        min-height: 12px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-login-loadingIcon {
      min-width: 1.38026vw;
      min-height: 1.38026vw; }
      .signup-page-login-loadingIcon else {
        min-width: 12px; }
      .signup-page-login-loadingIcon else {
        min-height: 12px; } }

.signup-page-login-close {
  position: absolute;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);
  width: 7.24638vw;
  height: 7.24638vw;
  top: -3.62319vw;
  right: -2.41546vw;
  transition: 0.3s all ease-in-out; }
  @media (min-width: 1300px) {
    .signup-page-login-close {
      width: 2.60417vw;
      height: 2.60417vw;
      top: -1.30208vw;
      right: -1.30208vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-login-close {
      width: 2.89352vw;
      height: 2.89352vw;
      top: -1.44676vw;
      right: -1.44676vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-login-close {
      width: 2.60417vw;
      height: 2.60417vw;
      top: -1.30208vw;
      right: -1.30208vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-login-close {
      width: 6.03865vw;
      height: 6.03865vw;
      top: -3.01932vw;
      right: -2.01288vw; }
      .signup-page-login-close else {
        width: 30px; }
      .signup-page-login-close else {
        height: 30px; }
      .signup-page-login-close else {
        top: -15px; }
      .signup-page-login-close else {
        right: -10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-login-close {
      width: 4.83092vw;
      height: 4.83092vw;
      top: -2.41546vw;
      right: -1.61031vw; }
      .signup-page-login-close else {
        width: 30px; }
      .signup-page-login-close else {
        height: 30px; }
      .signup-page-login-close else {
        top: -15px; }
      .signup-page-login-close else {
        right: -10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-login-close {
      width: 4.26257vw;
      height: 4.26257vw;
      top: -2.13129vw;
      right: -1.42086vw; }
      .signup-page-login-close else {
        width: 30px; }
      .signup-page-login-close else {
        height: 30px; }
      .signup-page-login-close else {
        top: -15px; }
      .signup-page-login-close else {
        right: -10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-login-close {
      width: 3.81388vw;
      height: 3.81388vw;
      top: -1.90694vw;
      right: -1.27129vw; }
      .signup-page-login-close else {
        width: 30px; }
      .signup-page-login-close else {
        height: 30px; }
      .signup-page-login-close else {
        top: -15px; }
      .signup-page-login-close else {
        right: -10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-login-close {
      width: 3.45066vw;
      height: 3.45066vw;
      top: -1.72533vw;
      right: -1.15022vw; }
      .signup-page-login-close else {
        width: 30px; }
      .signup-page-login-close else {
        height: 30px; }
      .signup-page-login-close else {
        top: -15px; }
      .signup-page-login-close else {
        right: -10px; } }
  .signup-page-login-close:hover {
    background: #ccf2ff;
    box-shadow: 0px 6px 6px rgba(51, 51, 51, 0.2), 0px 5px 20px rgba(51, 51, 51, 0.08); }

.signup-page-login-closeIcon {
  position: relative;
  width: 2.89855vw;
  height: 2.89855vw;
  top: -1.44928vw; }
  @media (min-width: 1300px) {
    .signup-page-login-closeIcon {
      width: 1.04167vw;
      height: 1.04167vw;
      top: -0.05208vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .signup-page-login-closeIcon {
      width: 1.15741vw;
      height: 1.15741vw;
      top: -0.05787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .signup-page-login-closeIcon {
      width: 1.04167vw;
      height: 1.04167vw;
      top: -0.05208vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .signup-page-login-closeIcon {
      width: 2.41546vw;
      height: 2.41546vw;
      top: -1.20773vw; }
      .signup-page-login-closeIcon else {
        width: 12px; }
      .signup-page-login-closeIcon else {
        height: 12px; }
      .signup-page-login-closeIcon else {
        top: -6px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .signup-page-login-closeIcon {
      width: 1.93237vw;
      height: 1.93237vw;
      top: -0.96618vw; }
      .signup-page-login-closeIcon else {
        width: 12px; }
      .signup-page-login-closeIcon else {
        height: 12px; }
      .signup-page-login-closeIcon else {
        top: -6px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .signup-page-login-closeIcon {
      width: 1.70503vw;
      height: 1.70503vw;
      top: -0.85251vw; }
      .signup-page-login-closeIcon else {
        width: 12px; }
      .signup-page-login-closeIcon else {
        height: 12px; }
      .signup-page-login-closeIcon else {
        top: -6px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .signup-page-login-closeIcon {
      width: 1.52555vw;
      height: 1.52555vw;
      top: -0.76278vw; }
      .signup-page-login-closeIcon else {
        width: 12px; }
      .signup-page-login-closeIcon else {
        height: 12px; }
      .signup-page-login-closeIcon else {
        top: -6px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .signup-page-login-closeIcon {
      width: 1.38026vw;
      height: 1.38026vw;
      top: -0.69013vw; }
      .signup-page-login-closeIcon else {
        width: 12px; }
      .signup-page-login-closeIcon else {
        height: 12px; }
      .signup-page-login-closeIcon else {
        top: -6px; } }
  .signup-page-login-closeIcon path {
    fill: #00ADE5;
    opacity: 1; }

.userWaitingListBgOverlay {
  content: "";
  display: none;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99991; }

.userWaitListModal .country-list li {
  text-align: left; }

.opened .userWaitingListBgOverlay {
  display: block; }

.opened .modal-dialog-main,
.opened .successMessageModal,
.opened .failureMessageModal {
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
  transition: all 0.2s ease-in-out;
  z-index: 99992; }
  .opened .modal-dialog-main p,
  .opened .successMessageModal p,
  .opened .failureMessageModal p {
    margin: 0; }

.modal-dialog-main, .successMessageModal, .failureMessageModal {
  border-radius: 5px;
  text-align: center;
  position: fixed;
  z-index: 11;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
          transform: translate(-50%, -50%) scale(0);
  transition: all 0.2s ease-in-out;
  min-width: 91.78744vw;
  max-width: 91.78744vw;
  border-radius: 3.86473vw; }
  @media (min-width: 1300px) {
    .modal-dialog-main, .successMessageModal, .failureMessageModal {
      min-width: 28.90625vw;
      max-width: 28.90625vw;
      border-radius: 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .modal-dialog-main, .successMessageModal, .failureMessageModal {
      min-width: 32.11806vw;
      max-width: 32.11806vw;
      border-radius: 0.92593vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .modal-dialog-main, .successMessageModal, .failureMessageModal {
      min-width: 28.90625vw;
      max-width: 28.90625vw;
      border-radius: 0.83333vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .modal-dialog-main, .successMessageModal, .failureMessageModal {
      min-width: 76.48953vw;
      max-width: 76.48953vw;
      border-radius: 3.22061vw; }
      .modal-dialog-main else, .successMessageModal else, .failureMessageModal else {
        min-width: 380px; }
      .modal-dialog-main else, .successMessageModal else, .failureMessageModal else {
        max-width: 380px; }
      .modal-dialog-main else, .successMessageModal else, .failureMessageModal else {
        border-radius: 16px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .modal-dialog-main, .successMessageModal, .failureMessageModal {
      min-width: 61.19163vw;
      max-width: 61.19163vw;
      border-radius: 2.57649vw; }
      .modal-dialog-main else, .successMessageModal else, .failureMessageModal else {
        min-width: 380px; }
      .modal-dialog-main else, .successMessageModal else, .failureMessageModal else {
        max-width: 380px; }
      .modal-dialog-main else, .successMessageModal else, .failureMessageModal else {
        border-radius: 16px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .modal-dialog-main, .successMessageModal, .failureMessageModal {
      min-width: 53.99261vw;
      max-width: 53.99261vw;
      border-radius: 2.27337vw; }
      .modal-dialog-main else, .successMessageModal else, .failureMessageModal else {
        min-width: 380px; }
      .modal-dialog-main else, .successMessageModal else, .failureMessageModal else {
        max-width: 380px; }
      .modal-dialog-main else, .successMessageModal else, .failureMessageModal else {
        border-radius: 16px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .modal-dialog-main, .successMessageModal, .failureMessageModal {
      min-width: 48.30918vw;
      max-width: 48.30918vw;
      border-radius: 2.03407vw; }
      .modal-dialog-main else, .successMessageModal else, .failureMessageModal else {
        min-width: 380px; }
      .modal-dialog-main else, .successMessageModal else, .failureMessageModal else {
        max-width: 380px; }
      .modal-dialog-main else, .successMessageModal else, .failureMessageModal else {
        border-radius: 16px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .modal-dialog-main, .successMessageModal, .failureMessageModal {
      min-width: 43.7083vw;
      max-width: 43.7083vw;
      border-radius: 1.84035vw; }
      .modal-dialog-main else, .successMessageModal else, .failureMessageModal else {
        min-width: 380px; }
      .modal-dialog-main else, .successMessageModal else, .failureMessageModal else {
        max-width: 380px; }
      .modal-dialog-main else, .successMessageModal else, .failureMessageModal else {
        border-radius: 16px; } }

.imageHolder {
  position: relative;
  background-image: url(/static/media/waitListMain.52a1ebc1.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  width: 91.78744vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 3.86473vw 3.86473vw 0vw 0vw; }
  @media (min-width: 1300px) {
    .imageHolder {
      width: 28.90625vw;
      height: 7.74271vw;
      border-radius: 0.83333vw 0.83333vw 0vw 0vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .imageHolder {
      width: 32.11806vw;
      height: 8.60301vw;
      border-radius: 0.92593vw 0.92593vw 0vw 0vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .imageHolder {
      width: 28.90625vw;
      height: 7.74271vw;
      border-radius: 0.83333vw 0.83333vw 0vw 0vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .imageHolder {
      width: 76.48953vw;
      border-radius: 3.22061vw 3.22061vw 0vw 0vw; }
      .imageHolder else {
        width: 380px; }
      .imageHolder else {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .imageHolder {
      width: 61.19163vw;
      border-radius: 2.57649vw 2.57649vw 0vw 0vw; }
      .imageHolder else {
        width: 380px; }
      .imageHolder else {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .imageHolder {
      width: 53.99261vw;
      border-radius: 2.27337vw 2.27337vw 0vw 0vw; }
      .imageHolder else {
        width: 380px; }
      .imageHolder else {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .imageHolder {
      width: 48.30918vw;
      border-radius: 2.03407vw 2.03407vw 0vw 0vw; }
      .imageHolder else {
        width: 380px; }
      .imageHolder else {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .imageHolder {
      width: 43.7083vw;
      border-radius: 1.84035vw 1.84035vw 0vw 0vw; }
      .imageHolder else {
        width: 380px; }
      .imageHolder else {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content; } }
  .imageHolder.successModal, .imageHolder.failureModal {
    background-image: url(/static/media/waitListThankyou.b9c1cc63.png);
    justify-content: center;
    height: 24.31401vw; }
    @media (min-width: 1300px) {
      .imageHolder.successModal, .imageHolder.failureModal {
        height: 7.74271vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .imageHolder.successModal, .imageHolder.failureModal {
        height: 8.60301vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .imageHolder.successModal, .imageHolder.failureModal {
        height: 7.74271vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .imageHolder.successModal, .imageHolder.failureModal {
        height: 20.26167vw; }
        .imageHolder.successModal else, .imageHolder.failureModal else {
          height: 100.66px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .imageHolder.successModal, .imageHolder.failureModal {
        height: 16.20934vw; }
        .imageHolder.successModal else, .imageHolder.failureModal else {
          height: 100.66px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .imageHolder.successModal, .imageHolder.failureModal {
        height: 14.30236vw; }
        .imageHolder.successModal else, .imageHolder.failureModal else {
          height: 100.66px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .imageHolder.successModal, .imageHolder.failureModal {
        height: 12.79685vw; }
        .imageHolder.successModal else, .imageHolder.failureModal else {
          height: 100.66px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .imageHolder.successModal, .imageHolder.failureModal {
        height: 11.5781vw; }
        .imageHolder.successModal else, .imageHolder.failureModal else {
          height: 100.66px; } }

.iconHolder {
  position: absolute;
  width: 39.85507vw;
  height: 48.30918vw;
  left: 84.54106vw; }
  @media (min-width: 1300px) {
    .iconHolder {
      width: 8.59375vw;
      height: 10.41667vw;
      left: 18.22917vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .iconHolder {
      width: 9.54861vw;
      height: 11.57407vw;
      left: 20.25463vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .iconHolder {
      width: 8.59375vw;
      height: 10.41667vw;
      left: 18.22917vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .iconHolder {
      width: 33.21256vw;
      height: 40.25765vw;
      left: 70.45089vw; }
      .iconHolder else {
        width: 165px; }
      .iconHolder else {
        height: 200px; }
      .iconHolder else {
        left: 350px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .iconHolder {
      width: 26.57005vw;
      height: 32.20612vw;
      left: 56.36071vw; }
      .iconHolder else {
        width: 165px; }
      .iconHolder else {
        height: 200px; }
      .iconHolder else {
        left: 350px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .iconHolder {
      width: 23.44416vw;
      height: 28.41716vw;
      left: 49.73004vw; }
      .iconHolder else {
        width: 165px; }
      .iconHolder else {
        height: 200px; }
      .iconHolder else {
        left: 350px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .iconHolder {
      width: 20.97635vw;
      height: 25.42588vw;
      left: 44.4953vw; }
      .iconHolder else {
        width: 165px; }
      .iconHolder else {
        height: 200px; }
      .iconHolder else {
        left: 350px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .iconHolder {
      width: 18.97861vw;
      height: 23.00437vw;
      left: 40.25765vw; }
      .iconHolder else {
        width: 165px; }
      .iconHolder else {
        height: 200px; }
      .iconHolder else {
        left: 350px; } }

.heading-text {
  font-family: Gilroy;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 4.34783vw;
  margin-bottom: 1.93237vw; }
  @media (min-width: 1300px) {
    .heading-text {
      font-size: 1.45833vw;
      margin-bottom: 0.52083vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .heading-text {
      font-size: 1.62037vw;
      margin-bottom: 0.5787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .heading-text {
      font-size: 1.45833vw;
      margin-bottom: 0.52083vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .heading-text {
      font-size: 3.62319vw;
      margin-bottom: 1.61031vw; }
      .heading-text else {
        font-size: 18px; }
      .heading-text else {
        margin-bottom: 8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .heading-text {
      font-size: 2.89855vw;
      margin-bottom: 1.28824vw; }
      .heading-text else {
        font-size: 18px; }
      .heading-text else {
        margin-bottom: 8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .heading-text {
      font-size: 2.55754vw;
      margin-bottom: 1.13669vw; }
      .heading-text else {
        font-size: 18px; }
      .heading-text else {
        margin-bottom: 8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .heading-text {
      font-size: 2.28833vw;
      margin-bottom: 1.01704vw; }
      .heading-text else {
        font-size: 18px; }
      .heading-text else {
        margin-bottom: 8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .heading-text {
      font-size: 2.07039vw;
      margin-bottom: 0.92017vw; }
      .heading-text else {
        font-size: 18px; }
      .heading-text else {
        margin-bottom: 8px; } }

.special-text {
  font-family: Gilroy;
  color: #1FBF75;
  font-weight: 600;
  font-size: 4.34783vw; }
  @media (min-width: 1300px) {
    .special-text {
      font-size: 1.45833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .special-text {
      font-size: 1.62037vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .special-text {
      font-size: 1.45833vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .special-text {
      font-size: 3.62319vw; }
      .special-text else {
        font-size: 18px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .special-text {
      font-size: 2.89855vw; }
      .special-text else {
        font-size: 18px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .special-text {
      font-size: 2.55754vw; }
      .special-text else {
        font-size: 18px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .special-text {
      font-size: 2.28833vw; }
      .special-text else {
        font-size: 18px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .special-text {
      font-size: 2.07039vw; }
      .special-text else {
        font-size: 18px; } }

.heading-sub-text {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  color: #FFFFFF;
  width: 60.38647vw;
  font-size: 2.89855vw;
  line-height: 4.10628vw; }
  @media (min-width: 1300px) {
    .heading-sub-text {
      width: 18.80208vw;
      font-size: 0.83333vw;
      line-height: 1.09375vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .heading-sub-text {
      width: 20.8912vw;
      font-size: 0.92593vw;
      line-height: 1.21528vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .heading-sub-text {
      width: 18.80208vw;
      font-size: 0.83333vw;
      line-height: 1.09375vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .heading-sub-text {
      width: 50.32206vw;
      font-size: 2.41546vw;
      line-height: 3.4219vw; }
      .heading-sub-text else {
        width: 250px; }
      .heading-sub-text else {
        font-size: 12px; }
      .heading-sub-text else {
        line-height: 17px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .heading-sub-text {
      width: 40.25765vw;
      font-size: 1.93237vw;
      line-height: 2.73752vw; }
      .heading-sub-text else {
        width: 250px; }
      .heading-sub-text else {
        font-size: 12px; }
      .heading-sub-text else {
        line-height: 17px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .heading-sub-text {
      width: 35.52145vw;
      font-size: 1.70503vw;
      line-height: 2.41546vw; }
      .heading-sub-text else {
        width: 250px; }
      .heading-sub-text else {
        font-size: 12px; }
      .heading-sub-text else {
        line-height: 17px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .heading-sub-text {
      width: 31.78235vw;
      font-size: 1.52555vw;
      line-height: 2.1612vw; }
      .heading-sub-text else {
        width: 250px; }
      .heading-sub-text else {
        font-size: 12px; }
      .heading-sub-text else {
        line-height: 17px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .heading-sub-text {
      width: 28.75546vw;
      font-size: 1.38026vw;
      line-height: 1.95537vw; }
      .heading-sub-text else {
        width: 250px; }
      .heading-sub-text else {
        font-size: 12px; }
      .heading-sub-text else {
        line-height: 17px; } }

.logic-holder {
  display: flex;
  flex-direction: column; }

.logic-heading-text {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  text-align: center;
  color: #282828;
  width: 72.46377vw;
  font-size: 3.62319vw;
  line-height: 4.83092vw;
  margin-top: 4.34783vw;
  margin-bottom: 1.93237vw; }
  @media (min-width: 1300px) {
    .logic-heading-text {
      width: 21.04167vw;
      font-size: 1.04167vw;
      line-height: 1.45833vw;
      margin-top: 1.25vw;
      margin-bottom: 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .logic-heading-text {
      width: 23.37963vw;
      font-size: 1.15741vw;
      line-height: 1.62037vw;
      margin-top: 1.38889vw;
      margin-bottom: 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .logic-heading-text {
      width: 21.04167vw;
      font-size: 1.04167vw;
      line-height: 1.45833vw;
      margin-top: 1.25vw;
      margin-bottom: 0.41667vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .logic-heading-text {
      width: 60.38647vw;
      font-size: 3.01932vw;
      line-height: 4.02576vw;
      margin-top: 3.62319vw;
      margin-bottom: 1.61031vw; }
      .logic-heading-text else {
        width: 300px; }
      .logic-heading-text else {
        font-size: 15px; }
      .logic-heading-text else {
        line-height: 20px; }
      .logic-heading-text else {
        margin-top: 18px; }
      .logic-heading-text else {
        margin-bottom: 8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .logic-heading-text {
      width: 48.30918vw;
      font-size: 2.41546vw;
      line-height: 3.22061vw;
      margin-top: 2.89855vw;
      margin-bottom: 1.28824vw; }
      .logic-heading-text else {
        width: 300px; }
      .logic-heading-text else {
        font-size: 15px; }
      .logic-heading-text else {
        line-height: 20px; }
      .logic-heading-text else {
        margin-top: 18px; }
      .logic-heading-text else {
        margin-bottom: 8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .logic-heading-text {
      width: 42.62575vw;
      font-size: 2.13129vw;
      line-height: 2.84172vw;
      margin-top: 2.55754vw;
      margin-bottom: 1.13669vw; }
      .logic-heading-text else {
        width: 300px; }
      .logic-heading-text else {
        font-size: 15px; }
      .logic-heading-text else {
        line-height: 20px; }
      .logic-heading-text else {
        margin-top: 18px; }
      .logic-heading-text else {
        margin-bottom: 8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .logic-heading-text {
      width: 38.13883vw;
      font-size: 1.90694vw;
      line-height: 2.54259vw;
      margin-top: 2.28833vw;
      margin-bottom: 1.01704vw; }
      .logic-heading-text else {
        width: 300px; }
      .logic-heading-text else {
        font-size: 15px; }
      .logic-heading-text else {
        line-height: 20px; }
      .logic-heading-text else {
        margin-top: 18px; }
      .logic-heading-text else {
        margin-bottom: 8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .logic-heading-text {
      width: 34.50656vw;
      font-size: 1.72533vw;
      line-height: 2.30044vw;
      margin-top: 2.07039vw;
      margin-bottom: 0.92017vw; }
      .logic-heading-text else {
        width: 300px; }
      .logic-heading-text else {
        font-size: 15px; }
      .logic-heading-text else {
        line-height: 20px; }
      .logic-heading-text else {
        margin-top: 18px; }
      .logic-heading-text else {
        margin-bottom: 8px; } }

.text-Holder {
  display: flex;
  text-align: left;
  flex-direction: column;
  padding: 4.34783vw 3.62319vw; }
  @media (min-width: 1300px) {
    .text-Holder {
      padding: 1.45833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .text-Holder {
      padding: 1.62037vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .text-Holder {
      padding: 1.45833vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .text-Holder {
      padding: 3.62319vw 3.01932vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .text-Holder {
      padding: 2.89855vw 2.41546vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .text-Holder {
      padding: 2.55754vw 2.13129vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .text-Holder {
      padding: 2.28833vw 1.90694vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .text-Holder {
      padding: 2.07039vw 1.72533vw; } }

.input-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.detail-input {
  outline: none !important;
  width: 115.94203vw;
  height: 13.52657vw;
  margin: 2.41546vw 0vw;
  border: 0.24155vw solid #C4C4C4;
  border-radius: 1.44928vw; }
  @media (min-width: 1300px) {
    .detail-input {
      width: 25vw;
      height: 2.91667vw;
      margin: 0.9375vw 0vw;
      border: 0.05208vw solid #C4C4C4;
      border-radius: 0.3125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .detail-input {
      width: 27.77778vw;
      height: 3.24074vw;
      margin: 1.04167vw 0vw;
      border: 0.05787vw solid #C4C4C4;
      border-radius: 0.34722vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .detail-input {
      width: 25vw;
      height: 2.91667vw;
      margin: 0.9375vw 0vw;
      border: 0.05208vw solid #C4C4C4;
      border-radius: 0.3125vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .detail-input {
      width: 96.61836vw;
      height: 11.27214vw;
      margin: 2.01288vw 0vw;
      border: 0.20129vw solid #C4C4C4;
      border-radius: 1.20773vw; }
      .detail-input else {
        width: 480px; }
      .detail-input else {
        height: 56px; }
      .detail-input else {
        border-radius: 6px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .detail-input {
      width: 77.29469vw;
      height: 9.01771vw;
      margin: 1.61031vw 0vw;
      border: 0.16103vw solid #C4C4C4;
      border-radius: 0.96618vw; }
      .detail-input else {
        width: 480px; }
      .detail-input else {
        height: 56px; }
      .detail-input else {
        border-radius: 6px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .detail-input {
      width: 68.20119vw;
      height: 7.95681vw;
      margin: 1.42086vw 0vw;
      border: 0.14209vw solid #C4C4C4;
      border-radius: 0.85251vw; }
      .detail-input else {
        width: 480px; }
      .detail-input else {
        height: 56px; }
      .detail-input else {
        border-radius: 6px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .detail-input {
      width: 61.02212vw;
      height: 7.11925vw;
      margin: 1.27129vw 0vw;
      border: 0.12713vw solid #C4C4C4;
      border-radius: 0.76278vw; }
      .detail-input else {
        width: 480px; }
      .detail-input else {
        height: 56px; }
      .detail-input else {
        border-radius: 6px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .detail-input {
      width: 55.21049vw;
      height: 6.44122vw;
      margin: 1.15022vw 0vw;
      border: 0.11502vw solid #C4C4C4;
      border-radius: 0.69013vw; }
      .detail-input else {
        width: 480px; }
      .detail-input else {
        height: 56px; }
      .detail-input else {
        border-radius: 6px; } }

.notify-button {
  background: linear-gradient(280.79deg, #4099F7 2.79%, #00ADE6 98.42%);
  border: white;
  font-family: 'Gilroy';
  color: #FFFFFF;
  border-radius: 7.1635vw;
  width: 84.54106vw;
  height: 14.25121vw;
  padding: 3.74396vw 5.7971vw 3.74396vw 6.76329vw;
  margin: 5.7971vw 0vw;
  font-size: 4.83092vw; }
  @media (min-width: 1300px) {
    .notify-button {
      border-radius: 1.54463vw;
      width: 23.33333vw;
      height: 3.07292vw;
      padding: 0.80729vw 1.25vw 0.80729vw 1.45833vw;
      margin: 1.25vw 0vw;
      font-size: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .notify-button {
      border-radius: 1.71626vw;
      width: 25.92593vw;
      height: 3.41435vw;
      padding: 0.89699vw 1.38889vw 0.89699vw 1.62037vw;
      margin: 1.38889vw 0vw;
      font-size: 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .notify-button {
      border-radius: 1.54463vw;
      width: 23.33333vw;
      height: 3.07292vw;
      padding: 0.80729vw 1.25vw 0.80729vw 1.45833vw;
      margin: 1.25vw 0vw;
      font-size: 1.04167vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .notify-button {
      border-radius: 5.96959vw;
      width: 70.45089vw;
      height: 11.87601vw;
      padding: 3.11997vw 4.83092vw 3.11997vw 5.63607vw;
      margin: 4.83092vw 0vw;
      font-size: 4.02576vw; }
      .notify-button else {
        border-radius: 29.6569px; }
      .notify-button else {
        width: 350px; }
      .notify-button else {
        height: 59px; }
      .notify-button else {
        font-size: 20px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .notify-button {
      border-radius: 4.77567vw;
      width: 56.36071vw;
      height: 9.50081vw;
      padding: 2.49597vw 3.86473vw 2.49597vw 4.50886vw;
      margin: 3.86473vw 0vw;
      font-size: 3.22061vw; }
      .notify-button else {
        border-radius: 29.6569px; }
      .notify-button else {
        width: 350px; }
      .notify-button else {
        height: 59px; }
      .notify-button else {
        font-size: 20px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .notify-button {
      border-radius: 4.21382vw;
      width: 49.73004vw;
      height: 8.38306vw;
      padding: 2.20233vw 3.41006vw 2.20233vw 3.9784vw;
      margin: 3.41006vw 0vw;
      font-size: 2.84172vw; }
      .notify-button else {
        border-radius: 29.6569px; }
      .notify-button else {
        width: 350px; }
      .notify-button else {
        height: 59px; }
      .notify-button else {
        font-size: 20px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .notify-button {
      border-radius: 3.77026vw;
      width: 44.4953vw;
      height: 7.50064vw;
      padding: 1.97051vw 3.05111vw 1.97051vw 3.55962vw;
      margin: 3.05111vw 0vw;
      font-size: 2.54259vw; }
      .notify-button else {
        border-radius: 29.6569px; }
      .notify-button else {
        width: 350px; }
      .notify-button else {
        height: 59px; }
      .notify-button else {
        font-size: 20px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .notify-button {
      border-radius: 3.41119vw;
      width: 40.25765vw;
      height: 6.78629vw;
      padding: 1.78284vw 2.76052vw 1.78284vw 3.22061vw;
      margin: 2.76052vw 0vw;
      font-size: 2.30044vw; }
      .notify-button else {
        border-radius: 29.6569px; }
      .notify-button else {
        width: 350px; }
      .notify-button else {
        height: 59px; }
      .notify-button else {
        font-size: 20px; } }

.button-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.modal-div {
  display: flex;
  justify-content: center;
  align-items: center; }

.parent-email {
  position: relative; }

.parent-email input {
  position: relative;
  width: 84.54106vw;
  height: 10.86957vw;
  margin: 2.41546vw 0vw;
  border: 0.24155vw solid #C4C4C4;
  border-radius: 1.44928vw; }
  @media (min-width: 1300px) {
    .parent-email input {
      width: 25vw;
      height: 2.91667vw;
      margin: 0.9375vw 0vw;
      border: 0.05208vw solid #C4C4C4;
      border-radius: 0.3125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .parent-email input {
      width: 27.77778vw;
      height: 3.24074vw;
      margin: 1.04167vw 0vw;
      border: 0.05787vw solid #C4C4C4;
      border-radius: 0.34722vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .parent-email input {
      width: 25vw;
      height: 2.91667vw;
      margin: 0.9375vw 0vw;
      border: 0.05208vw solid #C4C4C4;
      border-radius: 0.3125vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .parent-email input {
      width: 70.45089vw;
      height: 9.05797vw;
      margin: 2.01288vw 0vw;
      border: 0.20129vw solid #C4C4C4;
      border-radius: 1.20773vw; }
      .parent-email input else {
        width: 350px; }
      .parent-email input else {
        height: 45px; }
      .parent-email input else {
        border-radius: 6px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .parent-email input {
      width: 56.36071vw;
      height: 7.24638vw;
      margin: 1.61031vw 0vw;
      border: 0.16103vw solid #C4C4C4;
      border-radius: 0.96618vw; }
      .parent-email input else {
        width: 350px; }
      .parent-email input else {
        height: 45px; }
      .parent-email input else {
        border-radius: 6px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .parent-email input {
      width: 49.73004vw;
      height: 6.39386vw;
      margin: 1.42086vw 0vw;
      border: 0.14209vw solid #C4C4C4;
      border-radius: 0.85251vw; }
      .parent-email input else {
        width: 350px; }
      .parent-email input else {
        height: 45px; }
      .parent-email input else {
        border-radius: 6px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .parent-email input {
      width: 44.4953vw;
      height: 5.72082vw;
      margin: 1.27129vw 0vw;
      border: 0.12713vw solid #C4C4C4;
      border-radius: 0.76278vw; }
      .parent-email input else {
        width: 350px; }
      .parent-email input else {
        height: 45px; }
      .parent-email input else {
        border-radius: 6px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .parent-email input {
      width: 40.25765vw;
      height: 5.17598vw;
      margin: 1.15022vw 0vw;
      border: 0.11502vw solid #C4C4C4;
      border-radius: 0.69013vw; }
      .parent-email input else {
        width: 350px; }
      .parent-email input else {
        height: 45px; }
      .parent-email input else {
        border-radius: 6px; } }

.parent-email label {
  position: absolute;
  color: #ccc;
  left: 2.41546vw;
  top: 5.31401vw;
  z-index: 1; }
  @media (min-width: 1300px) {
    .parent-email label {
      left: 0.78125vw;
      top: 1.82292vw;
      z-index: 1; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .parent-email label {
      left: 0.86806vw;
      top: 2.02546vw;
      z-index: 1; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .parent-email label {
      left: 0.78125vw;
      top: 1.82292vw;
      z-index: 1; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .parent-email label {
      left: 2.01288vw;
      top: 4.42834vw; }
      .parent-email label else {
        left: 10px; }
      .parent-email label else {
        top: 22px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .parent-email label {
      left: 1.61031vw;
      top: 3.54267vw; }
      .parent-email label else {
        left: 10px; }
      .parent-email label else {
        top: 22px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .parent-email label {
      left: 1.42086vw;
      top: 3.12589vw; }
      .parent-email label else {
        left: 10px; }
      .parent-email label else {
        top: 22px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .parent-email label {
      left: 1.27129vw;
      top: 2.79685vw; }
      .parent-email label else {
        left: 10px; }
      .parent-email label else {
        top: 22px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .parent-email label {
      left: 1.15022vw;
      top: 2.53048vw; }
      .parent-email label else {
        left: 10px; }
      .parent-email label else {
        top: 22px; } }

.parent-name {
  position: relative; }

.parent-name input {
  position: relative;
  width: 84.54106vw;
  height: 10.86957vw;
  margin: 2.41546vw 0vw;
  border: 0.24155vw solid #C4C4C4;
  border-radius: 1.44928vw; }
  @media (min-width: 1300px) {
    .parent-name input {
      width: 25vw;
      height: 2.91667vw;
      margin: 0.9375vw 0vw;
      border: 0.05208vw solid #C4C4C4;
      border-radius: 0.3125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .parent-name input {
      width: 27.77778vw;
      height: 3.24074vw;
      margin: 1.04167vw 0vw;
      border: 0.05787vw solid #C4C4C4;
      border-radius: 0.34722vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .parent-name input {
      width: 25vw;
      height: 2.91667vw;
      margin: 0.9375vw 0vw;
      border: 0.05208vw solid #C4C4C4;
      border-radius: 0.3125vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .parent-name input {
      width: 70.45089vw;
      height: 9.05797vw;
      margin: 2.01288vw 0vw;
      border: 0.20129vw solid #C4C4C4;
      border-radius: 1.20773vw; }
      .parent-name input else {
        width: 350px; }
      .parent-name input else {
        height: 45px; }
      .parent-name input else {
        border-radius: 6px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .parent-name input {
      width: 56.36071vw;
      height: 7.24638vw;
      margin: 1.61031vw 0vw;
      border: 0.16103vw solid #C4C4C4;
      border-radius: 0.96618vw; }
      .parent-name input else {
        width: 350px; }
      .parent-name input else {
        height: 45px; }
      .parent-name input else {
        border-radius: 6px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .parent-name input {
      width: 49.73004vw;
      height: 6.39386vw;
      margin: 1.42086vw 0vw;
      border: 0.14209vw solid #C4C4C4;
      border-radius: 0.85251vw; }
      .parent-name input else {
        width: 350px; }
      .parent-name input else {
        height: 45px; }
      .parent-name input else {
        border-radius: 6px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .parent-name input {
      width: 44.4953vw;
      height: 5.72082vw;
      margin: 1.27129vw 0vw;
      border: 0.12713vw solid #C4C4C4;
      border-radius: 0.76278vw; }
      .parent-name input else {
        width: 350px; }
      .parent-name input else {
        height: 45px; }
      .parent-name input else {
        border-radius: 6px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .parent-name input {
      width: 40.25765vw;
      height: 5.17598vw;
      margin: 1.15022vw 0vw;
      border: 0.11502vw solid #C4C4C4;
      border-radius: 0.69013vw; }
      .parent-name input else {
        width: 350px; }
      .parent-name input else {
        height: 45px; }
      .parent-name input else {
        border-radius: 6px; } }

.parent-name label {
  position: absolute;
  color: #ccc;
  left: 2.41546vw;
  top: 5.31401vw;
  z-index: 1; }
  @media (min-width: 1300px) {
    .parent-name label {
      left: 0.78125vw;
      top: 1.82292vw;
      z-index: 1; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .parent-name label {
      left: 0.86806vw;
      top: 2.02546vw;
      z-index: 1; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .parent-name label {
      left: 0.78125vw;
      top: 1.82292vw;
      z-index: 1; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .parent-name label {
      left: 2.01288vw;
      top: 4.42834vw; }
      .parent-name label else {
        left: 10px; }
      .parent-name label else {
        top: 22px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .parent-name label {
      left: 1.61031vw;
      top: 3.54267vw; }
      .parent-name label else {
        left: 10px; }
      .parent-name label else {
        top: 22px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .parent-name label {
      left: 1.42086vw;
      top: 3.12589vw; }
      .parent-name label else {
        left: 10px; }
      .parent-name label else {
        top: 22px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .parent-name label {
      left: 1.27129vw;
      top: 2.79685vw; }
      .parent-name label else {
        left: 10px; }
      .parent-name label else {
        top: 22px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .parent-name label {
      left: 1.15022vw;
      top: 2.53048vw; }
      .parent-name label else {
        left: 10px; }
      .parent-name label else {
        top: 22px; } }

.parent-email input::-webkit-input-placeholder:after {
  content: "*";
  color: red; }

input[required]:valid + label {
  display: none; }

.waitingListform-group {
  position: relative;
  margin: 2.41546vw auto; }
  @media (min-width: 1300px) {
    .waitingListform-group {
      margin: 1.04167vw auto; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .waitingListform-group {
      margin: 1.15741vw auto; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .waitingListform-group {
      margin: 1.04167vw auto; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .waitingListform-group {
      margin: 2.01288vw auto; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .waitingListform-group {
      margin: 1.61031vw auto; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .waitingListform-group {
      margin: 1.42086vw auto; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .waitingListform-group {
      margin: 1.27129vw auto; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .waitingListform-group {
      margin: 1.15022vw auto; } }
  .waitingListform-group .palceholder {
    position: absolute;
    top: 7px;
    left: 8px;
    color: #B1B1B1;
    display: none; }
  .waitingListform-group label {
    font-weight: normal;
    position: absolute;
    cursor: text;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    color: #504F4F;
    top: 2.41546vw;
    left: 3.62319vw;
    font-size: 3.86473vw;
    line-height: 4.83092vw; }
    .waitingListform-group label .parentEmailSpan {
      opacity: 0.4; }
    @media (min-width: 1300px) {
      .waitingListform-group label {
        top: 0.9375vw;
        left: 0.78125vw;
        font-size: 0.9375vw;
        line-height: 1.14583vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .waitingListform-group label {
        top: 1.04167vw;
        left: 0.86806vw;
        font-size: 1.04167vw;
        line-height: 1.27315vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .waitingListform-group label {
        top: 0.9375vw;
        left: 0.78125vw;
        font-size: 0.9375vw;
        line-height: 1.14583vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .waitingListform-group label {
        top: 2.01288vw;
        left: 3.01932vw;
        font-size: 3.22061vw;
        line-height: 4.02576vw; }
        .waitingListform-group label else {
          top: 10px; }
        .waitingListform-group label else {
          left: 15px; }
        .waitingListform-group label else {
          font-size: 16px; }
        .waitingListform-group label else {
          line-height: 20px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .waitingListform-group label {
        top: 1.61031vw;
        left: 2.41546vw;
        font-size: 2.57649vw;
        line-height: 3.22061vw; }
        .waitingListform-group label else {
          top: 10px; }
        .waitingListform-group label else {
          left: 15px; }
        .waitingListform-group label else {
          font-size: 16px; }
        .waitingListform-group label else {
          line-height: 20px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .waitingListform-group label {
        top: 1.42086vw;
        left: 2.13129vw;
        font-size: 2.27337vw;
        line-height: 2.84172vw; }
        .waitingListform-group label else {
          top: 10px; }
        .waitingListform-group label else {
          left: 15px; }
        .waitingListform-group label else {
          font-size: 16px; }
        .waitingListform-group label else {
          line-height: 20px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .waitingListform-group label {
        top: 1.27129vw;
        left: 1.90694vw;
        font-size: 2.03407vw;
        line-height: 2.54259vw; }
        .waitingListform-group label else {
          top: 10px; }
        .waitingListform-group label else {
          left: 15px; }
        .waitingListform-group label else {
          font-size: 16px; }
        .waitingListform-group label else {
          line-height: 20px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .waitingListform-group label {
        top: 1.15022vw;
        left: 1.72533vw;
        font-size: 1.84035vw;
        line-height: 2.30044vw; }
        .waitingListform-group label else {
          top: 10px; }
        .waitingListform-group label else {
          left: 15px; }
        .waitingListform-group label else {
          font-size: 16px; }
        .waitingListform-group label else {
          line-height: 20px; } }
  .waitingListform-group .palceholder-star {
    color: red; }
  .waitingListform-group input::-webkit-input-placeholder {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    color: #504F4F;
    opacity: 0.4;
    font-size: 3.86473vw;
    line-height: 4.83092vw; }
  .waitingListform-group input::placeholder {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    color: #504F4F;
    opacity: 0.4;
    font-size: 3.86473vw;
    line-height: 4.83092vw; }
    @media (min-width: 1300px) {
      .waitingListform-group input::-webkit-input-placeholder {
        font-size: 0.9375vw;
        line-height: 1.14583vw; }
      .waitingListform-group input::placeholder {
        font-size: 0.9375vw;
        line-height: 1.14583vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .waitingListform-group input::-webkit-input-placeholder {
        font-size: 1.04167vw;
        line-height: 1.27315vw; }
      .waitingListform-group input::placeholder {
        font-size: 1.04167vw;
        line-height: 1.27315vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .waitingListform-group input::-webkit-input-placeholder {
        font-size: 0.9375vw;
        line-height: 1.14583vw; }
      .waitingListform-group input::placeholder {
        font-size: 0.9375vw;
        line-height: 1.14583vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .waitingListform-group input::-webkit-input-placeholder {
        font-size: 3.22061vw;
        line-height: 4.02576vw; }
      .waitingListform-group input::placeholder {
        font-size: 3.22061vw;
        line-height: 4.02576vw; }
        .waitingListform-group input::-webkit-input-placeholder else {
          font-size: 16px; }
        .waitingListform-group input::placeholder else {
          font-size: 16px; }
        .waitingListform-group input::-webkit-input-placeholder else {
          line-height: 20px; }
        .waitingListform-group input::placeholder else {
          line-height: 20px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .waitingListform-group input::-webkit-input-placeholder {
        font-size: 2.57649vw;
        line-height: 3.22061vw; }
      .waitingListform-group input::placeholder {
        font-size: 2.57649vw;
        line-height: 3.22061vw; }
        .waitingListform-group input::-webkit-input-placeholder else {
          font-size: 16px; }
        .waitingListform-group input::placeholder else {
          font-size: 16px; }
        .waitingListform-group input::-webkit-input-placeholder else {
          line-height: 20px; }
        .waitingListform-group input::placeholder else {
          line-height: 20px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .waitingListform-group input::-webkit-input-placeholder {
        font-size: 2.27337vw;
        line-height: 2.84172vw; }
      .waitingListform-group input::placeholder {
        font-size: 2.27337vw;
        line-height: 2.84172vw; }
        .waitingListform-group input::-webkit-input-placeholder else {
          font-size: 16px; }
        .waitingListform-group input::placeholder else {
          font-size: 16px; }
        .waitingListform-group input::-webkit-input-placeholder else {
          line-height: 20px; }
        .waitingListform-group input::placeholder else {
          line-height: 20px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .waitingListform-group input::-webkit-input-placeholder {
        font-size: 2.03407vw;
        line-height: 2.54259vw; }
      .waitingListform-group input::placeholder {
        font-size: 2.03407vw;
        line-height: 2.54259vw; }
        .waitingListform-group input::-webkit-input-placeholder else {
          font-size: 16px; }
        .waitingListform-group input::placeholder else {
          font-size: 16px; }
        .waitingListform-group input::-webkit-input-placeholder else {
          line-height: 20px; }
        .waitingListform-group input::placeholder else {
          line-height: 20px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .waitingListform-group input::-webkit-input-placeholder {
        font-size: 1.84035vw;
        line-height: 2.30044vw; }
      .waitingListform-group input::placeholder {
        font-size: 1.84035vw;
        line-height: 2.30044vw; }
        .waitingListform-group input::-webkit-input-placeholder else {
          font-size: 16px; }
        .waitingListform-group input::placeholder else {
          font-size: 16px; }
        .waitingListform-group input::-webkit-input-placeholder else {
          line-height: 20px; }
        .waitingListform-group input::placeholder else {
          line-height: 20px; } }
  .waitingListform-group input {
    font-size: 3.86473vw;
    line-height: 4.83092vw; }
    @media (min-width: 1300px) {
      .waitingListform-group input {
        font-size: 0.9375vw;
        line-height: 1.14583vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .waitingListform-group input {
        font-size: 1.04167vw;
        line-height: 1.27315vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .waitingListform-group input {
        font-size: 0.9375vw;
        line-height: 1.14583vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .waitingListform-group input {
        font-size: 3.22061vw;
        line-height: 4.02576vw; }
        .waitingListform-group input else {
          font-size: 16px; }
        .waitingListform-group input else {
          line-height: 20px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .waitingListform-group input {
        font-size: 2.57649vw;
        line-height: 3.22061vw; }
        .waitingListform-group input else {
          font-size: 16px; }
        .waitingListform-group input else {
          line-height: 20px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .waitingListform-group input {
        font-size: 2.27337vw;
        line-height: 2.84172vw; }
        .waitingListform-group input else {
          font-size: 16px; }
        .waitingListform-group input else {
          line-height: 20px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .waitingListform-group input {
        font-size: 2.03407vw;
        line-height: 2.54259vw; }
        .waitingListform-group input else {
          font-size: 16px; }
        .waitingListform-group input else {
          line-height: 20px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .waitingListform-group input {
        font-size: 1.84035vw;
        line-height: 2.30044vw; }
        .waitingListform-group input else {
          font-size: 16px; }
        .waitingListform-group input else {
          line-height: 20px; } }

.form-input-modal {
  width: 100% !important;
  border: 1px solid #C4C4C4;
  height: 10vw;
  border-radius: 1.38889vw;
  font-size: 4.44444vw;
  letter-spacing: 0.03439vw;
  padding-left: 4.16667vw;
  padding-right: 4.16667vw;
  box-sizing: border-box;
  font-family: 'Nunito', 'Gilroy';
  background: white;
  color: #333333; }
  @media (min-width: 1300px) {
    .form-input-modal {
      height: 2.86458vw;
      border-radius: 0.41667vw;
      font-size: 1.04167vw;
      letter-spacing: 0.00973vw;
      padding-left: 0.78125vw;
      padding-right: 0.78125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .form-input-modal {
      height: 3.18287vw;
      border-radius: 0.46296vw;
      font-size: 1.15741vw;
      letter-spacing: 0.01081vw;
      padding-left: 0.86806vw;
      padding-right: 0.86806vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .form-input-modal {
      height: 2.86458vw;
      border-radius: 0.41667vw;
      font-size: 1.04167vw;
      letter-spacing: 0.00973vw;
      padding-left: 0.78125vw;
      padding-right: 0.78125vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .form-input-modal {
      height: 8.33333vw;
      border-radius: 1.15741vw;
      font-size: 3.7037vw;
      letter-spacing: 0.02866vw;
      padding-left: 3.47222vw;
      padding-right: 3.47222vw; }
      .form-input-modal else {
        height: 36px; }
      .form-input-modal else {
        border-radius: 5px; }
      .form-input-modal else {
        font-size: 16px; }
      .form-input-modal else {
        letter-spacing: 0.12381px; }
      .form-input-modal else {
        padding-left: 15px; }
      .form-input-modal else {
        padding-right: 15px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .form-input-modal {
      height: 6.66667vw;
      border-radius: 0.92593vw;
      font-size: 2.96296vw;
      letter-spacing: 0.02293vw;
      padding-left: 2.77778vw;
      padding-right: 2.77778vw; }
      .form-input-modal else {
        height: 36px; }
      .form-input-modal else {
        border-radius: 5px; }
      .form-input-modal else {
        font-size: 16px; }
      .form-input-modal else {
        letter-spacing: 0.12381px; }
      .form-input-modal else {
        padding-left: 15px; }
      .form-input-modal else {
        padding-right: 15px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .form-input-modal {
      height: 5.88235vw;
      border-radius: 0.81699vw;
      font-size: 2.61438vw;
      letter-spacing: 0.02023vw;
      padding-left: 2.45098vw;
      padding-right: 2.45098vw; }
      .form-input-modal else {
        height: 36px; }
      .form-input-modal else {
        border-radius: 5px; }
      .form-input-modal else {
        font-size: 16px; }
      .form-input-modal else {
        letter-spacing: 0.12381px; }
      .form-input-modal else {
        padding-left: 15px; }
      .form-input-modal else {
        padding-right: 15px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .form-input-modal {
      height: 5.26316vw;
      border-radius: 0.73099vw;
      font-size: 2.33918vw;
      letter-spacing: 0.0181vw;
      padding-left: 2.19298vw;
      padding-right: 2.19298vw; }
      .form-input-modal else {
        height: 36px; }
      .form-input-modal else {
        border-radius: 5px; }
      .form-input-modal else {
        font-size: 16px; }
      .form-input-modal else {
        letter-spacing: 0.12381px; }
      .form-input-modal else {
        padding-left: 15px; }
      .form-input-modal else {
        padding-right: 15px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .form-input-modal {
      height: 4.7619vw;
      border-radius: 0.66138vw;
      font-size: 2.1164vw;
      letter-spacing: 0.01638vw;
      padding-left: 1.98413vw;
      padding-right: 1.98413vw; }
      .form-input-modal else {
        height: 36px; }
      .form-input-modal else {
        border-radius: 5px; }
      .form-input-modal else {
        font-size: 16px; }
      .form-input-modal else {
        letter-spacing: 0.12381px; }
      .form-input-modal else {
        padding-left: 15px; }
      .form-input-modal else {
        padding-right: 15px; } }
  .form-input-modal:focus, .form-input-modal:active {
    border: 1px solid #4099F7;
    outline: none; }
  .form-input-modal.error {
    background: #FDECEA;
    border: 1.8681px solid #EF4A3C; }

.form-input-modal.form-input-phone {
  border: 1px solid #C4C4C4;
  height: 10vw;
  border-radius: 1.38889vw;
  letter-spacing: 0.03439vw;
  padding-left: 13.88889vw !important;
  box-sizing: border-box;
  font-family: 'Nunito', 'Gilroy';
  background: white;
  color: #333333; }
  @media (min-width: 1300px) {
    .form-input-modal.form-input-phone {
      height: 2.86458vw;
      border-radius: 0.41667vw;
      letter-spacing: 0.00973vw;
      padding-left: 4.16667vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .form-input-modal.form-input-phone {
      height: 3.18287vw;
      border-radius: 0.46296vw;
      letter-spacing: 0.01081vw;
      padding-left: 4.62963vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .form-input-modal.form-input-phone {
      height: 2.86458vw;
      border-radius: 0.41667vw;
      letter-spacing: 0.00973vw;
      padding-left: 4.16667vw !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .form-input-modal.form-input-phone {
      height: 8.33333vw;
      border-radius: 1.15741vw;
      letter-spacing: 0.02866vw;
      padding-left: 11.57407vw !important; }
      .form-input-modal.form-input-phone else {
        height: 36px; }
      .form-input-modal.form-input-phone else {
        border-radius: 5px; }
      .form-input-modal.form-input-phone else {
        letter-spacing: 0.12381px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .form-input-modal.form-input-phone {
      height: 6.66667vw;
      border-radius: 0.92593vw;
      letter-spacing: 0.02293vw;
      padding-left: 9.25926vw !important; }
      .form-input-modal.form-input-phone else {
        height: 36px; }
      .form-input-modal.form-input-phone else {
        border-radius: 5px; }
      .form-input-modal.form-input-phone else {
        letter-spacing: 0.12381px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .form-input-modal.form-input-phone {
      height: 5.88235vw;
      border-radius: 0.81699vw;
      letter-spacing: 0.02023vw;
      padding-left: 8.16993vw !important; }
      .form-input-modal.form-input-phone else {
        height: 36px; }
      .form-input-modal.form-input-phone else {
        border-radius: 5px; }
      .form-input-modal.form-input-phone else {
        letter-spacing: 0.12381px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .form-input-modal.form-input-phone {
      height: 5.26316vw;
      border-radius: 0.73099vw;
      letter-spacing: 0.0181vw;
      padding-left: 7.30994vw !important; }
      .form-input-modal.form-input-phone else {
        height: 36px; }
      .form-input-modal.form-input-phone else {
        border-radius: 5px; }
      .form-input-modal.form-input-phone else {
        letter-spacing: 0.12381px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .form-input-modal.form-input-phone {
      height: 4.7619vw;
      border-radius: 0.66138vw;
      letter-spacing: 0.01638vw;
      padding-left: 6.61376vw !important; }
      .form-input-modal.form-input-phone else {
        height: 36px; }
      .form-input-modal.form-input-phone else {
        border-radius: 5px; }
      .form-input-modal.form-input-phone else {
        letter-spacing: 0.12381px; } }
  .form-input-modal.form-input-phone:focus, .form-input-modal.form-input-phone:active {
    border: 1px solid #4099F7;
    outline: none; }
  .form-input-modal.form-input-phone.error {
    background: #FDECEA;
    border: 1.8681px solid #EF4A3C; }

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fefefe;
  border-radius: 0vw 0vw 4.44444vw 4.44444vw; }
  @media (min-width: 1300px) {
    .modal-body {
      border-radius: 0vw 0vw 0.83333vw 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .modal-body {
      border-radius: 0vw 0vw 0.92593vw 0.92593vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .modal-body {
      border-radius: 0vw 0vw 0.83333vw 0.83333vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .modal-body {
      border-radius: 0vw 0vw 3.7037vw 3.7037vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .modal-body {
      border-radius: 0vw 0vw 2.96296vw 2.96296vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .modal-body {
      border-radius: 0vw 0vw 2.61438vw 2.61438vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .modal-body {
      border-radius: 0vw 0vw 2.33918vw 2.33918vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .modal-body {
      border-radius: 0vw 0vw 2.1164vw 2.1164vw; } }

.waitingListFormContainer {
  width: 85%;
  margin: 0 auto; }
  .waitingListFormContainer .iti__country-list {
    overflow-x: hidden; }
  .waitingListFormContainer .btn-sd-blue-container {
    padding: 2.77778vw 5vw 2.77778vw 6.11111vw;
    border-radius: 5.55556vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
    font-family: Gilroy;
    background: linear-gradient(280.79deg, #4099F7 2.79%, #00ADE6 98.42%);
    font-weight: bold;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #fff;
    box-sizing: border-box; }
    @media (min-width: 1300px) {
      .waitingListFormContainer .btn-sd-blue-container {
        padding: 0.9375vw 1.45833vw 0.9375vw 1.875vw;
        border-radius: 1.5625vw;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .waitingListFormContainer .btn-sd-blue-container {
        padding: 1.04167vw 1.62037vw 1.04167vw 2.08333vw;
        border-radius: 1.73611vw;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .waitingListFormContainer .btn-sd-blue-container {
        padding: 0.9375vw 1.45833vw 0.9375vw 1.875vw;
        border-radius: 1.5625vw;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .waitingListFormContainer .btn-sd-blue-container {
        padding: 2.31481vw 4.16667vw 2.31481vw 5.09259vw;
        border-radius: 4.62963vw; }
        .waitingListFormContainer .btn-sd-blue-container else {
          border-radius: 20px; }
        .waitingListFormContainer .btn-sd-blue-container else {
          height: -webkit-fit-content;
          height: -moz-fit-content;
          height: fit-content; }
        .waitingListFormContainer .btn-sd-blue-container else {
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .waitingListFormContainer .btn-sd-blue-container {
        padding: 1.85185vw 3.33333vw 1.85185vw 4.07407vw;
        border-radius: 3.7037vw; }
        .waitingListFormContainer .btn-sd-blue-container else {
          border-radius: 20px; }
        .waitingListFormContainer .btn-sd-blue-container else {
          height: -webkit-fit-content;
          height: -moz-fit-content;
          height: fit-content; }
        .waitingListFormContainer .btn-sd-blue-container else {
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .waitingListFormContainer .btn-sd-blue-container {
        padding: 1.63399vw 2.94118vw 1.63399vw 3.59477vw;
        border-radius: 3.26797vw; }
        .waitingListFormContainer .btn-sd-blue-container else {
          border-radius: 20px; }
        .waitingListFormContainer .btn-sd-blue-container else {
          height: -webkit-fit-content;
          height: -moz-fit-content;
          height: fit-content; }
        .waitingListFormContainer .btn-sd-blue-container else {
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .waitingListFormContainer .btn-sd-blue-container {
        padding: 1.46199vw 2.63158vw 1.46199vw 3.21637vw;
        border-radius: 2.92398vw; }
        .waitingListFormContainer .btn-sd-blue-container else {
          border-radius: 20px; }
        .waitingListFormContainer .btn-sd-blue-container else {
          height: -webkit-fit-content;
          height: -moz-fit-content;
          height: fit-content; }
        .waitingListFormContainer .btn-sd-blue-container else {
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .waitingListFormContainer .btn-sd-blue-container {
        padding: 1.32275vw 2.38095vw 1.32275vw 2.91005vw;
        border-radius: 2.6455vw; }
        .waitingListFormContainer .btn-sd-blue-container else {
          border-radius: 20px; }
        .waitingListFormContainer .btn-sd-blue-container else {
          height: -webkit-fit-content;
          height: -moz-fit-content;
          height: fit-content; }
        .waitingListFormContainer .btn-sd-blue-container else {
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content; } }
    .waitingListFormContainer .btn-sd-blue-container span {
      font-size: 4.44444vw; }
      @media (min-width: 1300px) {
        .waitingListFormContainer .btn-sd-blue-container span {
          font-size: 1.14583vw; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .waitingListFormContainer .btn-sd-blue-container span {
          font-size: 1.27315vw; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .waitingListFormContainer .btn-sd-blue-container span {
          font-size: 1.14583vw; } }
      @media (min-width: 450px) and (max-width: 500px) {
        .waitingListFormContainer .btn-sd-blue-container span {
          font-size: 3.7037vw; }
          .waitingListFormContainer .btn-sd-blue-container span else {
            font-size: 16px; } }
      @media (min-width: 500px) and (max-width: 600px) {
        .waitingListFormContainer .btn-sd-blue-container span {
          font-size: 2.96296vw; }
          .waitingListFormContainer .btn-sd-blue-container span else {
            font-size: 16px; } }
      @media (min-width: 600px) and (max-width: 700px) {
        .waitingListFormContainer .btn-sd-blue-container span {
          font-size: 2.61438vw; }
          .waitingListFormContainer .btn-sd-blue-container span else {
            font-size: 16px; } }
      @media (min-width: 700px) and (max-width: 800px) {
        .waitingListFormContainer .btn-sd-blue-container span {
          font-size: 2.33918vw; }
          .waitingListFormContainer .btn-sd-blue-container span else {
            font-size: 16px; } }
      @media (min-width: 800px) and (max-width: 899px) {
        .waitingListFormContainer .btn-sd-blue-container span {
          font-size: 2.1164vw; }
          .waitingListFormContainer .btn-sd-blue-container span else {
            font-size: 16px; } }
  .waitingListFormContainer .loader,
  .waitingListFormContainer .loader:after {
    border-radius: 50%;
    width: 1em;
    height: 1em; }
  .waitingListFormContainer .loader {
    margin-right: 10px;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.4em solid rgba(255, 255, 255, 0.2);
    border-right: 0.4em solid rgba(255, 255, 255, 0.2);
    border-bottom: 0.4em solid rgba(255, 255, 255, 0.2);
    border-left: 0.4em solid #ffffff;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear; }
    .waitingListFormContainer .loader.hide {
      display: none; }

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.waitingListButton {
  margin: 4.16667vw auto; }
  @media (min-width: 1300px) {
    .waitingListButton {
      margin: 1.04167vw auto; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .waitingListButton {
      margin: 1.15741vw auto; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .waitingListButton {
      margin: 1.04167vw auto; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .waitingListButton {
      margin: 3.47222vw auto; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .waitingListButton {
      margin: 2.77778vw auto; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .waitingListButton {
      margin: 2.45098vw auto; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .waitingListButton {
      margin: 2.19298vw auto; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .waitingListButton {
      margin: 1.98413vw auto; } }
  .waitingListButton .btn-sd-blue-container {
    width: 100%; }
    @media (min-width: 1300px) {
      .waitingListButton .btn-sd-blue-container {
        width: 92%;
        margin: 0 auto; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .waitingListButton .btn-sd-blue-container {
        width: 92%;
        margin: 0 auto; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .waitingListButton .btn-sd-blue-container {
        width: 92%;
        margin: 0 auto; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .waitingListButton .btn-sd-blue-container else {
        width: 100%; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .waitingListButton .btn-sd-blue-container else {
        width: 100%; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .waitingListButton .btn-sd-blue-container else {
        width: 100%; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .waitingListButton .btn-sd-blue-container else {
        width: 100%; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .waitingListButton .btn-sd-blue-container else {
        width: 100%; } }
  .waitingListButton .btn-arrow {
    display: none; }

.successMessageModal, .failureMessageModal, .modal-dialog-main {
  display: none; }
  .successMessageModal.show, .failureMessageModal.show, .modal-dialog-main.show {
    display: block !important; }

.notification-error {
  font-family: 'Nunito', 'Gilroy'; }

.successMessageModal .heading-text, .failureMessageModal .heading-text {
  margin: 0 !important; }

.successMessageModal .logic-heading-text, .failureMessageModal .logic-heading-text {
  margin-bottom: 0 !important;
  width: 77.29469vw; }
  @media (min-width: 1300px) {
    .successMessageModal .logic-heading-text, .failureMessageModal .logic-heading-text {
      width: 21.875vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .successMessageModal .logic-heading-text, .failureMessageModal .logic-heading-text {
      width: 24.30556vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .successMessageModal .logic-heading-text, .failureMessageModal .logic-heading-text {
      width: 21.875vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .successMessageModal .logic-heading-text, .failureMessageModal .logic-heading-text {
      width: 64.41224vw; }
      .successMessageModal .logic-heading-text else, .failureMessageModal .logic-heading-text else {
        width: 320px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .successMessageModal .logic-heading-text, .failureMessageModal .logic-heading-text {
      width: 51.52979vw; }
      .successMessageModal .logic-heading-text else, .failureMessageModal .logic-heading-text else {
        width: 320px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .successMessageModal .logic-heading-text, .failureMessageModal .logic-heading-text {
      width: 45.46746vw; }
      .successMessageModal .logic-heading-text else, .failureMessageModal .logic-heading-text else {
        width: 320px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .successMessageModal .logic-heading-text, .failureMessageModal .logic-heading-text {
      width: 40.68141vw; }
      .successMessageModal .logic-heading-text else, .failureMessageModal .logic-heading-text else {
        width: 320px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .successMessageModal .logic-heading-text, .failureMessageModal .logic-heading-text {
      width: 36.80699vw; }
      .successMessageModal .logic-heading-text else, .failureMessageModal .logic-heading-text else {
        width: 320px; } }

.successMessageModal .waitingListButton, .failureMessageModal .waitingListButton {
  margin-bottom: 6.03865vw; }
  @media (min-width: 1300px) {
    .successMessageModal .waitingListButton, .failureMessageModal .waitingListButton {
      margin-bottom: 1.30208vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .successMessageModal .waitingListButton, .failureMessageModal .waitingListButton {
      margin-bottom: 1.44676vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .successMessageModal .waitingListButton, .failureMessageModal .waitingListButton {
      margin-bottom: 1.30208vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .successMessageModal .waitingListButton, .failureMessageModal .waitingListButton {
      margin-bottom: 5.03221vw; }
      .successMessageModal .waitingListButton else, .failureMessageModal .waitingListButton else {
        margin-bottom: 25px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .successMessageModal .waitingListButton, .failureMessageModal .waitingListButton {
      margin-bottom: 4.02576vw; }
      .successMessageModal .waitingListButton else, .failureMessageModal .waitingListButton else {
        margin-bottom: 25px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .successMessageModal .waitingListButton, .failureMessageModal .waitingListButton {
      margin-bottom: 3.55215vw; }
      .successMessageModal .waitingListButton else, .failureMessageModal .waitingListButton else {
        margin-bottom: 25px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .successMessageModal .waitingListButton, .failureMessageModal .waitingListButton {
      margin-bottom: 3.17824vw; }
      .successMessageModal .waitingListButton else, .failureMessageModal .waitingListButton else {
        margin-bottom: 25px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .successMessageModal .waitingListButton, .failureMessageModal .waitingListButton {
      margin-bottom: 2.87555vw; }
      .successMessageModal .waitingListButton else, .failureMessageModal .waitingListButton else {
        margin-bottom: 25px; } }

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-ExtraBold.7aa3fc92.woff2) format('woff2'),
        url(/static/media/Gilroy-ExtraBold.871422df.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url(/static/media/Gilroy-Light.0cf83503.woff2) format('woff2'),
        url(/static/media/Gilroy-Light.da979280.woff) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


.ind-code-showcase-row {
  display: flex; }

.ind-code-showcase-container {
  padding: 0;
  padding-top: 5.7971vw;
  padding-bottom: 5.7971vw; }
  @media (min-width: 1300px) {
    .ind-code-showcase-container {
      padding: 3.33333vw 6.66667vw;
      padding-top: 3.33333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ind-code-showcase-container {
      padding: 3.7037vw 7.40741vw;
      padding-top: 3.7037vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .ind-code-showcase-container {
      padding: 3.33333vw 6.66667vw;
      padding-top: 3.33333vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .ind-code-showcase-container {
      padding-top: 4.83092vw;
      padding-bottom: 4.83092vw; }
      .ind-code-showcase-container else {
        padding: 0; }
      .ind-code-showcase-container else {
        padding-top: 24px; }
      .ind-code-showcase-container else {
        padding-bottom: 24px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .ind-code-showcase-container {
      padding-top: 3.86473vw;
      padding-bottom: 3.86473vw; }
      .ind-code-showcase-container else {
        padding: 0; }
      .ind-code-showcase-container else {
        padding-top: 24px; }
      .ind-code-showcase-container else {
        padding-bottom: 24px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .ind-code-showcase-container {
      padding-top: 3.41006vw;
      padding-bottom: 3.41006vw; }
      .ind-code-showcase-container else {
        padding: 0; }
      .ind-code-showcase-container else {
        padding-top: 24px; }
      .ind-code-showcase-container else {
        padding-bottom: 24px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .ind-code-showcase-container {
      padding-top: 3.05111vw;
      padding-bottom: 3.05111vw; }
      .ind-code-showcase-container else {
        padding: 0; }
      .ind-code-showcase-container else {
        padding-top: 24px; }
      .ind-code-showcase-container else {
        padding-bottom: 24px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .ind-code-showcase-container {
      padding-top: 2.76052vw;
      padding-bottom: 2.76052vw; }
      .ind-code-showcase-container else {
        padding: 0; }
      .ind-code-showcase-container else {
        padding-top: 24px; }
      .ind-code-showcase-container else {
        padding-bottom: 24px; } }
  .ind-code-showcase-container > .ind-code-showcase-row {
    position: relative;
    display: block; }
    @media (min-width: 1300px) {
      .ind-code-showcase-container > .ind-code-showcase-row {
        display: flex; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .ind-code-showcase-container > .ind-code-showcase-row {
        display: flex; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .ind-code-showcase-container > .ind-code-showcase-row {
        display: flex; } }

.ind-code-showcase-mobile-hero-t {
  line-height: 4.58937vw;
  padding: 0.48309vw 3.86473vw 3.86473vw;
  font-weight: bold;
  color: #00ADE6;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 1300px) {
    .ind-code-showcase-mobile-hero-t {
      display: none; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ind-code-showcase-mobile-hero-t {
      display: none; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .ind-code-showcase-mobile-hero-t {
      display: none; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .ind-code-showcase-mobile-hero-t {
      line-height: 3.82448vw;
      padding: 0.40258vw 3.22061vw 3.22061vw; }
      .ind-code-showcase-mobile-hero-t else {
        line-height: 19px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .ind-code-showcase-mobile-hero-t {
      line-height: 3.05958vw;
      padding: 0.32206vw 2.57649vw 2.57649vw; }
      .ind-code-showcase-mobile-hero-t else {
        line-height: 19px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .ind-code-showcase-mobile-hero-t {
      line-height: 2.69963vw;
      padding: 0.28417vw 2.27337vw 2.27337vw; }
      .ind-code-showcase-mobile-hero-t else {
        line-height: 19px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .ind-code-showcase-mobile-hero-t {
      line-height: 2.41546vw;
      padding: 0.25426vw 2.03407vw 2.03407vw; }
      .ind-code-showcase-mobile-hero-t else {
        line-height: 19px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .ind-code-showcase-mobile-hero-t {
      line-height: 2.18542vw;
      padding: 0.23004vw 1.84035vw 1.84035vw; }
      .ind-code-showcase-mobile-hero-t else {
        line-height: 19px; } }

.ind-code-showcase-leftArrow {
  background-repeat: no-repeat;
  width: 1.18229vw;
  cursor: pointer;
  padding: 0px 24px 0 0;
  margin: 16px;
  height: 2.02604vw;
  object-fit: contain;
  background-size: contain;
  background-image: url(/static/media/quizLeftArrow.2b595c67.svg);
  display: none; }
  @media (min-width: 1300px) {
    .ind-code-showcase-leftArrow {
      display: block; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ind-code-showcase-leftArrow {
      display: block; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .ind-code-showcase-leftArrow {
      display: block; } }
  .ind-code-showcase-leftArrow:hover {
    color: #aaacae;
    opacity: 0.7; }

@media (min-width: 1300px) {
  .ind-code-showcase-m-rxl {
    margin-right: 3.33333vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .ind-code-showcase-m-rxl {
    margin-right: 3.7037vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .ind-code-showcase-m-rxl {
    margin-right: 3.33333vw; } }

.ind-code-showcase-ph-card {
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);
  background: #ffffff; }

.ind-code-showcase-card-container {
  min-width: 100%;
  min-height: 100%; }
  @media (min-width: 1300px) {
    .ind-code-showcase-card-container {
      min-width: 75vw;
      max-width: 80vw;
      min-height: 60vh; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ind-code-showcase-card-container {
      min-width: 75vw;
      max-width: 80vw;
      min-height: 60vh; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .ind-code-showcase-card-container {
      min-width: 75vw;
      max-width: 80vw;
      min-height: 60vh; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .ind-code-showcase-card-container else {
      min-width: 100%; }
    .ind-code-showcase-card-container else {
      min-height: 100%; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .ind-code-showcase-card-container else {
      min-width: 100%; }
    .ind-code-showcase-card-container else {
      min-height: 100%; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .ind-code-showcase-card-container else {
      min-width: 100%; }
    .ind-code-showcase-card-container else {
      min-height: 100%; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .ind-code-showcase-card-container else {
      min-width: 100%; }
    .ind-code-showcase-card-container else {
      min-height: 100%; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .ind-code-showcase-card-container else {
      min-width: 100%; }
    .ind-code-showcase-card-container else {
      min-height: 100%; } }

.ind-code-showcase-code-block {
  position: relative; }
  .ind-code-showcase-code-block > .ind-code-showcase-row {
    padding: 5.7971vw 0;
    padding-bottom: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    @media (min-width: 1300px) {
      .ind-code-showcase-code-block > .ind-code-showcase-row {
        padding: 1.66667vw;
        padding-bottom: 0.83333vw;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .ind-code-showcase-code-block > .ind-code-showcase-row {
        padding: 1.85185vw;
        padding-bottom: 0.92593vw;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .ind-code-showcase-code-block > .ind-code-showcase-row {
        padding: 1.66667vw;
        padding-bottom: 0.83333vw;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .ind-code-showcase-code-block > .ind-code-showcase-row {
        padding: 4.83092vw 0; }
        .ind-code-showcase-code-block > .ind-code-showcase-row else {
          padding-bottom: 0; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .ind-code-showcase-code-block > .ind-code-showcase-row {
        padding: 3.86473vw 0; }
        .ind-code-showcase-code-block > .ind-code-showcase-row else {
          padding-bottom: 0; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .ind-code-showcase-code-block > .ind-code-showcase-row {
        padding: 3.41006vw 0; }
        .ind-code-showcase-code-block > .ind-code-showcase-row else {
          padding-bottom: 0; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .ind-code-showcase-code-block > .ind-code-showcase-row {
        padding: 3.05111vw 0; }
        .ind-code-showcase-code-block > .ind-code-showcase-row else {
          padding-bottom: 0; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .ind-code-showcase-code-block > .ind-code-showcase-row {
        padding: 2.76052vw 0; }
        .ind-code-showcase-code-block > .ind-code-showcase-row else {
          padding-bottom: 0; } }

.ind-code-showcase-author-details {
  color: #403F3F;
  font-weight: normal;
  text-align: center; }
  @media (min-width: 1300px) {
    .ind-code-showcase-author-details {
      width: 8vw;
      text-align: left; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ind-code-showcase-author-details {
      width: 8vw;
      text-align: left; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .ind-code-showcase-author-details {
      width: 8vw;
      text-align: left; } }
  .ind-code-showcase-author-details .ind-code-showcase-author-profile-img-container {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex; }
    @media (min-width: 1300px) {
      .ind-code-showcase-author-details .ind-code-showcase-author-profile-img-container {
        display: block; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .ind-code-showcase-author-details .ind-code-showcase-author-profile-img-container {
        display: block; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .ind-code-showcase-author-details .ind-code-showcase-author-profile-img-container {
        display: block; } }
    .ind-code-showcase-author-details .ind-code-showcase-author-profile-img-container .ind-code-showcase-profile-img {
      display: block;
      box-sizing: border-box;
      border-radius: 100%;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-position: top center !important;
      border: 2px solid #00171F;
      width: 16.90821vw;
      height: 16.90821vw; }
      @media (min-width: 1300px) {
        .ind-code-showcase-author-details .ind-code-showcase-author-profile-img-container .ind-code-showcase-profile-img {
          width: 5.20833vw;
          height: 5.20833vw; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .ind-code-showcase-author-details .ind-code-showcase-author-profile-img-container .ind-code-showcase-profile-img {
          width: 5.78704vw;
          height: 5.78704vw; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .ind-code-showcase-author-details .ind-code-showcase-author-profile-img-container .ind-code-showcase-profile-img {
          width: 5.20833vw;
          height: 5.20833vw; } }
      @media (min-width: 450px) and (max-width: 500px) {
        .ind-code-showcase-author-details .ind-code-showcase-author-profile-img-container .ind-code-showcase-profile-img {
          width: 14.09018vw;
          height: 14.09018vw; }
          .ind-code-showcase-author-details .ind-code-showcase-author-profile-img-container .ind-code-showcase-profile-img else {
            width: 70px; }
          .ind-code-showcase-author-details .ind-code-showcase-author-profile-img-container .ind-code-showcase-profile-img else {
            height: 70px; } }
      @media (min-width: 500px) and (max-width: 600px) {
        .ind-code-showcase-author-details .ind-code-showcase-author-profile-img-container .ind-code-showcase-profile-img {
          width: 11.27214vw;
          height: 11.27214vw; }
          .ind-code-showcase-author-details .ind-code-showcase-author-profile-img-container .ind-code-showcase-profile-img else {
            width: 70px; }
          .ind-code-showcase-author-details .ind-code-showcase-author-profile-img-container .ind-code-showcase-profile-img else {
            height: 70px; } }
      @media (min-width: 600px) and (max-width: 700px) {
        .ind-code-showcase-author-details .ind-code-showcase-author-profile-img-container .ind-code-showcase-profile-img {
          width: 9.94601vw;
          height: 9.94601vw; }
          .ind-code-showcase-author-details .ind-code-showcase-author-profile-img-container .ind-code-showcase-profile-img else {
            width: 70px; }
          .ind-code-showcase-author-details .ind-code-showcase-author-profile-img-container .ind-code-showcase-profile-img else {
            height: 70px; } }
      @media (min-width: 700px) and (max-width: 800px) {
        .ind-code-showcase-author-details .ind-code-showcase-author-profile-img-container .ind-code-showcase-profile-img {
          width: 8.89906vw;
          height: 8.89906vw; }
          .ind-code-showcase-author-details .ind-code-showcase-author-profile-img-container .ind-code-showcase-profile-img else {
            width: 70px; }
          .ind-code-showcase-author-details .ind-code-showcase-author-profile-img-container .ind-code-showcase-profile-img else {
            height: 70px; } }
      @media (min-width: 800px) and (max-width: 899px) {
        .ind-code-showcase-author-details .ind-code-showcase-author-profile-img-container .ind-code-showcase-profile-img {
          width: 8.05153vw;
          height: 8.05153vw; }
          .ind-code-showcase-author-details .ind-code-showcase-author-profile-img-container .ind-code-showcase-profile-img else {
            width: 70px; }
          .ind-code-showcase-author-details .ind-code-showcase-author-profile-img-container .ind-code-showcase-profile-img else {
            height: 70px; } }
  .ind-code-showcase-author-details .ind-code-showcase-author-name {
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    line-height: 32px;
    font-size: 4.34783vw; }
    @media (min-width: 1300px) {
      .ind-code-showcase-author-details .ind-code-showcase-author-name {
        margin-top: 0.52083vw;
        font-size: 1.04167vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .ind-code-showcase-author-details .ind-code-showcase-author-name {
        margin-top: 0.52083vw;
        font-size: 1.15741vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .ind-code-showcase-author-details .ind-code-showcase-author-name {
        margin-top: 0.52083vw;
        font-size: 1.04167vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .ind-code-showcase-author-details .ind-code-showcase-author-name {
        font-size: 3.62319vw; }
        .ind-code-showcase-author-details .ind-code-showcase-author-name else {
          font-size: 18px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .ind-code-showcase-author-details .ind-code-showcase-author-name {
        font-size: 2.89855vw; }
        .ind-code-showcase-author-details .ind-code-showcase-author-name else {
          font-size: 18px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .ind-code-showcase-author-details .ind-code-showcase-author-name {
        font-size: 2.55754vw; }
        .ind-code-showcase-author-details .ind-code-showcase-author-name else {
          font-size: 18px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .ind-code-showcase-author-details .ind-code-showcase-author-name {
        font-size: 2.28833vw; }
        .ind-code-showcase-author-details .ind-code-showcase-author-name else {
          font-size: 18px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .ind-code-showcase-author-details .ind-code-showcase-author-name {
        font-size: 2.07039vw; }
        .ind-code-showcase-author-details .ind-code-showcase-author-name else {
          font-size: 18px; } }
  .ind-code-showcase-author-details .ind-code-showcase-author-grade {
    opacity: 0.6;
    color: #403F3F;
    line-height: 4.34783vw;
    font-size: 3.86473vw; }
    @media (min-width: 1300px) {
      .ind-code-showcase-author-details .ind-code-showcase-author-grade {
        line-height: 1.04167vw;
        font-size: 0.9375vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .ind-code-showcase-author-details .ind-code-showcase-author-grade {
        line-height: 1.15741vw;
        font-size: 1.04167vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .ind-code-showcase-author-details .ind-code-showcase-author-grade {
        line-height: 1.04167vw;
        font-size: 0.9375vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .ind-code-showcase-author-details .ind-code-showcase-author-grade {
        line-height: 3.62319vw;
        font-size: 3.22061vw; }
        .ind-code-showcase-author-details .ind-code-showcase-author-grade else {
          line-height: 18px; }
        .ind-code-showcase-author-details .ind-code-showcase-author-grade else {
          font-size: 16px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .ind-code-showcase-author-details .ind-code-showcase-author-grade {
        line-height: 2.89855vw;
        font-size: 2.57649vw; }
        .ind-code-showcase-author-details .ind-code-showcase-author-grade else {
          line-height: 18px; }
        .ind-code-showcase-author-details .ind-code-showcase-author-grade else {
          font-size: 16px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .ind-code-showcase-author-details .ind-code-showcase-author-grade {
        line-height: 2.55754vw;
        font-size: 2.27337vw; }
        .ind-code-showcase-author-details .ind-code-showcase-author-grade else {
          line-height: 18px; }
        .ind-code-showcase-author-details .ind-code-showcase-author-grade else {
          font-size: 16px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .ind-code-showcase-author-details .ind-code-showcase-author-grade {
        line-height: 2.28833vw;
        font-size: 2.03407vw; }
        .ind-code-showcase-author-details .ind-code-showcase-author-grade else {
          line-height: 18px; }
        .ind-code-showcase-author-details .ind-code-showcase-author-grade else {
          font-size: 16px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .ind-code-showcase-author-details .ind-code-showcase-author-grade {
        line-height: 2.07039vw;
        font-size: 1.84035vw; }
        .ind-code-showcase-author-details .ind-code-showcase-author-grade else {
          line-height: 18px; }
        .ind-code-showcase-author-details .ind-code-showcase-author-grade else {
          font-size: 16px; } }

.ind-code-showcase-details-container {
  width: 100%; }
  .ind-code-showcase-details-container .ind-code-showcase-details {
    width: 100%;
    box-sizing: border-box;
    padding: 0vw 5.7971vw;
    margin-top: 3.86473vw; }
    @media (min-width: 1300px) {
      .ind-code-showcase-details-container .ind-code-showcase-details {
        padding: 0vw;
        margin-top: 0; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .ind-code-showcase-details-container .ind-code-showcase-details {
        padding: 0vw;
        margin-top: 0; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .ind-code-showcase-details-container .ind-code-showcase-details {
        padding: 0vw;
        margin-top: 0; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .ind-code-showcase-details-container .ind-code-showcase-details {
        padding: 0vw 4.83092vw;
        margin-top: 3.22061vw; }
        .ind-code-showcase-details-container .ind-code-showcase-details else {
          margin-top: 16px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .ind-code-showcase-details-container .ind-code-showcase-details {
        padding: 0vw 3.86473vw;
        margin-top: 2.57649vw; }
        .ind-code-showcase-details-container .ind-code-showcase-details else {
          margin-top: 16px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .ind-code-showcase-details-container .ind-code-showcase-details {
        padding: 0vw 3.41006vw;
        margin-top: 2.27337vw; }
        .ind-code-showcase-details-container .ind-code-showcase-details else {
          margin-top: 16px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .ind-code-showcase-details-container .ind-code-showcase-details {
        padding: 0vw 3.05111vw;
        margin-top: 2.03407vw; }
        .ind-code-showcase-details-container .ind-code-showcase-details else {
          margin-top: 16px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .ind-code-showcase-details-container .ind-code-showcase-details {
        padding: 0vw 2.76052vw;
        margin-top: 1.84035vw; }
        .ind-code-showcase-details-container .ind-code-showcase-details else {
          margin-top: 16px; } }
    .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-file-name {
      margin: 0;
      color: #403F3F;
      font-size: 5.31401vw;
      font-weight: bold; }
      @media (min-width: 1300px) {
        .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-file-name {
          font-size: 1.35417vw;
          font-weight: 600; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-file-name {
          font-size: 1.50463vw;
          font-weight: 600; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-file-name {
          font-size: 1.35417vw;
          font-weight: 600; } }
      @media (min-width: 450px) and (max-width: 500px) {
        .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-file-name {
          font-size: 4.42834vw; }
          .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-file-name else {
            font-size: 22px; } }
      @media (min-width: 500px) and (max-width: 600px) {
        .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-file-name {
          font-size: 3.54267vw; }
          .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-file-name else {
            font-size: 22px; } }
      @media (min-width: 600px) and (max-width: 700px) {
        .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-file-name {
          font-size: 3.12589vw; }
          .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-file-name else {
            font-size: 22px; } }
      @media (min-width: 700px) and (max-width: 800px) {
        .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-file-name {
          font-size: 2.79685vw; }
          .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-file-name else {
            font-size: 22px; } }
      @media (min-width: 800px) and (max-width: 899px) {
        .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-file-name {
          font-size: 2.53048vw; }
          .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-file-name else {
            font-size: 22px; } }
    .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-timestamp {
      color: #A8A7A7;
      font-size: 16px;
      margin: 0;
      font-weight: normal;
      letter-spacing: 0.03em; }
    .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-description {
      margin: 0;
      text-align: justify;
      color: #403F3F;
      padding: 0;
      font-size: 3.86473vw; }
      @media (min-width: 1300px) {
        .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-description {
          padding: 0 0 0.20833vw;
          line-height: 1.66667vw;
          font-size: 1.04167vw; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-description {
          padding: 0 0 0.23148vw;
          line-height: 1.85185vw;
          font-size: 1.15741vw; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-description {
          padding: 0 0 0.20833vw;
          line-height: 1.66667vw;
          font-size: 1.04167vw; } }
      @media (min-width: 450px) and (max-width: 500px) {
        .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-description {
          font-size: 3.22061vw; }
          .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-description else {
            padding: 0; }
          .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-description else {
            font-size: 16px; } }
      @media (min-width: 500px) and (max-width: 600px) {
        .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-description {
          font-size: 2.57649vw; }
          .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-description else {
            padding: 0; }
          .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-description else {
            font-size: 16px; } }
      @media (min-width: 600px) and (max-width: 700px) {
        .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-description {
          font-size: 2.27337vw; }
          .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-description else {
            padding: 0; }
          .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-description else {
            font-size: 16px; } }
      @media (min-width: 700px) and (max-width: 800px) {
        .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-description {
          font-size: 2.03407vw; }
          .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-description else {
            padding: 0; }
          .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-description else {
            font-size: 16px; } }
      @media (min-width: 800px) and (max-width: 899px) {
        .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-description {
          font-size: 1.84035vw; }
          .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-description else {
            padding: 0; }
          .ind-code-showcase-details-container .ind-code-showcase-details .ind-code-showcase-description else {
            font-size: 16px; } }

.ind-code-showcase-tags-container {
  padding: 0; }
  @media (min-width: 1300px) {
    .ind-code-showcase-tags-container {
      padding: 0.20833vw 0; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ind-code-showcase-tags-container {
      padding: 0.23148vw 0; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .ind-code-showcase-tags-container {
      padding: 0.20833vw 0; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .ind-code-showcase-tags-container else {
      padding: 0; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .ind-code-showcase-tags-container else {
      padding: 0; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .ind-code-showcase-tags-container else {
      padding: 0; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .ind-code-showcase-tags-container else {
      padding: 0; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .ind-code-showcase-tags-container else {
      padding: 0; } }
  .ind-code-showcase-tags-container .ind-code-showcase-tags {
    cursor: pointer;
    border: 1px solid #00ADE6;
    border-radius: 100px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: #00ADE6;
    transition: background-color .3s;
    margin: 1.93237vw 1.93237vw 1.93237vw 0;
    font-size: 3.86473vw;
    padding: 0.48309vw 3.86473vw; }
    @media (min-width: 1300px) {
      .ind-code-showcase-tags-container .ind-code-showcase-tags {
        margin: 0.41667vw 0.41667vw 0.10417vw 0;
        font-size: 0.9375vw;
        padding: 0.20833vw 0.83333vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .ind-code-showcase-tags-container .ind-code-showcase-tags {
        margin: 0.41667vw 0.41667vw 0.10417vw 0;
        font-size: 0.9375vw;
        padding: 0.20833vw 0.83333vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .ind-code-showcase-tags-container .ind-code-showcase-tags {
        margin: 0.41667vw 0.41667vw 0.10417vw 0;
        font-size: 0.9375vw;
        padding: 0.20833vw 0.83333vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .ind-code-showcase-tags-container .ind-code-showcase-tags {
        margin: 1.61031vw 1.61031vw 1.61031vw 0;
        font-size: 3.22061vw;
        padding: 0.40258vw 3.22061vw; }
        .ind-code-showcase-tags-container .ind-code-showcase-tags else {
          font-size: 16px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .ind-code-showcase-tags-container .ind-code-showcase-tags {
        margin: 1.28824vw 1.28824vw 1.28824vw 0;
        font-size: 2.57649vw;
        padding: 0.32206vw 2.57649vw; }
        .ind-code-showcase-tags-container .ind-code-showcase-tags else {
          font-size: 16px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .ind-code-showcase-tags-container .ind-code-showcase-tags {
        margin: 1.13669vw 1.13669vw 1.13669vw 0;
        font-size: 2.27337vw;
        padding: 0.28417vw 2.27337vw; }
        .ind-code-showcase-tags-container .ind-code-showcase-tags else {
          font-size: 16px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .ind-code-showcase-tags-container .ind-code-showcase-tags {
        margin: 1.01704vw 1.01704vw 1.01704vw 0;
        font-size: 2.03407vw;
        padding: 0.25426vw 2.03407vw; }
        .ind-code-showcase-tags-container .ind-code-showcase-tags else {
          font-size: 16px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .ind-code-showcase-tags-container .ind-code-showcase-tags {
        margin: 0.92017vw 0.92017vw 0.92017vw 0;
        font-size: 1.84035vw;
        padding: 0.23004vw 1.84035vw; }
        .ind-code-showcase-tags-container .ind-code-showcase-tags else {
          font-size: 16px; } }
    .ind-code-showcase-tags-container .ind-code-showcase-tags span {
      font-weight: normal;
      color: #A8A7A7; }
    .ind-code-showcase-tags-container .ind-code-showcase-tags:hover, .ind-code-showcase-tags-container .ind-code-showcase-tags.active {
      transition: background-color .3s;
      background-color: #00ADE6;
      color: #fff; }
      .ind-code-showcase-tags-container .ind-code-showcase-tags:hover span, .ind-code-showcase-tags-container .ind-code-showcase-tags.active span {
        color: #fff; }

.ind-code-showcase-editorq-titleClass {
  width: 100%;
  background-color: #64d5cb;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000102;
  font-weight: 600;
  font-size: 4.34783vw;
  height: 8.45411vw; }
  @media (min-width: 1300px) {
    .ind-code-showcase-editorq-titleClass {
      font-size: 1.14583vw;
      height: 2.34375vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ind-code-showcase-editorq-titleClass {
      font-size: 1.27315vw;
      height: 2.60417vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .ind-code-showcase-editorq-titleClass {
      font-size: 1.14583vw;
      height: 2.34375vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .ind-code-showcase-editorq-titleClass {
      font-size: 3.62319vw;
      height: 7.04509vw; }
      .ind-code-showcase-editorq-titleClass else {
        font-size: 18px; }
      .ind-code-showcase-editorq-titleClass else {
        height: 35px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .ind-code-showcase-editorq-titleClass {
      font-size: 2.89855vw;
      height: 5.63607vw; }
      .ind-code-showcase-editorq-titleClass else {
        font-size: 18px; }
      .ind-code-showcase-editorq-titleClass else {
        height: 35px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .ind-code-showcase-editorq-titleClass {
      font-size: 2.55754vw;
      height: 4.973vw; }
      .ind-code-showcase-editorq-titleClass else {
        font-size: 18px; }
      .ind-code-showcase-editorq-titleClass else {
        height: 35px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .ind-code-showcase-editorq-titleClass {
      font-size: 2.28833vw;
      height: 4.44953vw; }
      .ind-code-showcase-editorq-titleClass else {
        font-size: 18px; }
      .ind-code-showcase-editorq-titleClass else {
        height: 35px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .ind-code-showcase-editorq-titleClass {
      font-size: 2.07039vw;
      height: 4.02576vw; }
      .ind-code-showcase-editorq-titleClass else {
        font-size: 18px; }
      .ind-code-showcase-editorq-titleClass else {
        height: 35px; } }

.ind-code-showcase-editorq-OutputClass {
  background-color: #5fc5e5; }

#ind-code-showcase-editor-view {
  height: 55vh;
  display: block;
  margin: 3.86473vw 0; }
  @media (min-width: 1300px) {
    #ind-code-showcase-editor-view {
      margin: 0; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    #ind-code-showcase-editor-view {
      margin: 0; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    #ind-code-showcase-editor-view {
      margin: 0; } }
  @media (min-width: 450px) and (max-width: 500px) {
    #ind-code-showcase-editor-view {
      margin: 3.22061vw 0; } }
  @media (min-width: 500px) and (max-width: 600px) {
    #ind-code-showcase-editor-view {
      margin: 2.57649vw 0; } }
  @media (min-width: 600px) and (max-width: 700px) {
    #ind-code-showcase-editor-view {
      margin: 2.27337vw 0; } }
  @media (min-width: 700px) and (max-width: 800px) {
    #ind-code-showcase-editor-view {
      margin: 2.03407vw 0; } }
  @media (min-width: 800px) and (max-width: 899px) {
    #ind-code-showcase-editor-view {
      margin: 1.84035vw 0; } }

.ind-code-showcase-editor-CustomLoadingIcon {
  background-color: #23d1e9 !important;
  margin: 30px;
  display: block;
  width: 20px;
  height: 20px;
  bottom: -20px;
  right: 5px;
  left: inherit;
  position: absolute; }

.ind-code-showcase-editorRunButton {
  position: absolute;
  background-image: url(/static/media/playButton.32a941f2.png);
  background-size: 117%;
  background-position: 47% 42%;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  bottom: 40px;
  width: 14.49275vw;
  height: 14.49275vw;
  right: 2.41546vw; }
  @media (min-width: 1300px) {
    .ind-code-showcase-editorRunButton {
      width: 3.64583vw;
      height: 3.64583vw;
      right: 1.5625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ind-code-showcase-editorRunButton {
      width: 4.05093vw;
      height: 4.05093vw;
      right: 1.73611vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .ind-code-showcase-editorRunButton {
      width: 3.64583vw;
      height: 3.64583vw;
      right: 1.5625vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .ind-code-showcase-editorRunButton {
      width: 12.07729vw;
      height: 12.07729vw;
      right: 2.01288vw; }
      .ind-code-showcase-editorRunButton else {
        width: 60px; }
      .ind-code-showcase-editorRunButton else {
        height: 60px; }
      .ind-code-showcase-editorRunButton else {
        right: 10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .ind-code-showcase-editorRunButton {
      width: 9.66184vw;
      height: 9.66184vw;
      right: 1.61031vw; }
      .ind-code-showcase-editorRunButton else {
        width: 60px; }
      .ind-code-showcase-editorRunButton else {
        height: 60px; }
      .ind-code-showcase-editorRunButton else {
        right: 10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .ind-code-showcase-editorRunButton {
      width: 8.52515vw;
      height: 8.52515vw;
      right: 1.42086vw; }
      .ind-code-showcase-editorRunButton else {
        width: 60px; }
      .ind-code-showcase-editorRunButton else {
        height: 60px; }
      .ind-code-showcase-editorRunButton else {
        right: 10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .ind-code-showcase-editorRunButton {
      width: 7.62777vw;
      height: 7.62777vw;
      right: 1.27129vw; }
      .ind-code-showcase-editorRunButton else {
        width: 60px; }
      .ind-code-showcase-editorRunButton else {
        height: 60px; }
      .ind-code-showcase-editorRunButton else {
        right: 10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .ind-code-showcase-editorRunButton {
      width: 6.90131vw;
      height: 6.90131vw;
      right: 1.15022vw; }
      .ind-code-showcase-editorRunButton else {
        width: 60px; }
      .ind-code-showcase-editorRunButton else {
        height: 60px; }
      .ind-code-showcase-editorRunButton else {
        right: 10px; } }
  .ind-code-showcase-editorRunButton:hover {
    opacity: 0.5; }

.ind-code-showcase-editorOutput {
  width: 100%;
  border-radius: 1.09375vw;
  background-color: #014257;
  box-sizing: border-box;
  padding: 5px 1.04167vw 5px 1.5625vw;
  border-radius: 0.26042vw;
  font-family: Monaco;
  font-size: 1.09375vw;
  overflow: scroll;
  line-height: 1.5;
  color: #ffffff;
  margin-top: 0.26042vw; }
  .ind-code-showcase-editorOutput::-webkit-scrollbar {
    max-width: 0.52083vw;
    height: 0.36458vw; }
  .ind-code-showcase-editorOutput::-webkit-scrollbar-thumb {
    background-color: rgba(52, 228, 234, 0.5);
    border-radius: 0; }
  .ind-code-showcase-editorOutput::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0); }

.ind-code-showcase-details-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.86473vw 1.93237vw;
  padding-top: 0; }
  @media (min-width: 1300px) {
    .ind-code-showcase-details-footer {
      padding: 0.72917vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ind-code-showcase-details-footer {
      padding: 0.81019vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .ind-code-showcase-details-footer {
      padding: 0.72917vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .ind-code-showcase-details-footer {
      padding: 3.22061vw 1.61031vw; }
      .ind-code-showcase-details-footer else {
        padding-top: 0; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .ind-code-showcase-details-footer {
      padding: 2.57649vw 1.28824vw; }
      .ind-code-showcase-details-footer else {
        padding-top: 0; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .ind-code-showcase-details-footer {
      padding: 2.27337vw 1.13669vw; }
      .ind-code-showcase-details-footer else {
        padding-top: 0; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .ind-code-showcase-details-footer {
      padding: 2.03407vw 1.01704vw; }
      .ind-code-showcase-details-footer else {
        padding-top: 0; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .ind-code-showcase-details-footer {
      padding: 1.84035vw 0.92017vw; }
      .ind-code-showcase-details-footer else {
        padding-top: 0; } }
  .ind-code-showcase-details-footer .ind-code-showcase-reactions {
    -webkit-user-select: none;
            user-select: none;
    min-width: 2.5vw;
    text-align: center;
    color: #403F3F;
    border-radius: 100px;
    cursor: pointer;
    margin: 0 4px;
    font-size: 3.38164vw;
    padding: 0.96618vw 3.86473vw; }
    @media (min-width: 1300px) {
      .ind-code-showcase-details-footer .ind-code-showcase-reactions {
        font-size: 0.9375vw;
        padding: 0.41667vw 1.25vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .ind-code-showcase-details-footer .ind-code-showcase-reactions {
        font-size: 0.9375vw;
        padding: 0.46296vw 1.38889vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .ind-code-showcase-details-footer .ind-code-showcase-reactions {
        font-size: 0.9375vw;
        padding: 0.41667vw 1.25vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .ind-code-showcase-details-footer .ind-code-showcase-reactions {
        font-size: 2.81804vw;
        padding: 0.80515vw 3.22061vw; }
        .ind-code-showcase-details-footer .ind-code-showcase-reactions else {
          font-size: 14px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .ind-code-showcase-details-footer .ind-code-showcase-reactions {
        font-size: 2.25443vw;
        padding: 0.64412vw 2.57649vw; }
        .ind-code-showcase-details-footer .ind-code-showcase-reactions else {
          font-size: 14px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .ind-code-showcase-details-footer .ind-code-showcase-reactions {
        font-size: 1.9892vw;
        padding: 0.56834vw 2.27337vw; }
        .ind-code-showcase-details-footer .ind-code-showcase-reactions else {
          font-size: 14px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .ind-code-showcase-details-footer .ind-code-showcase-reactions {
        font-size: 1.77981vw;
        padding: 0.50852vw 2.03407vw; }
        .ind-code-showcase-details-footer .ind-code-showcase-reactions else {
          font-size: 14px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .ind-code-showcase-details-footer .ind-code-showcase-reactions {
        font-size: 1.61031vw;
        padding: 0.46009vw 1.84035vw; }
        .ind-code-showcase-details-footer .ind-code-showcase-reactions else {
          font-size: 14px; } }
    .ind-code-showcase-details-footer .ind-code-showcase-reactions.hot.active, .ind-code-showcase-details-footer .ind-code-showcase-reactions.hot:hover {
      background: #FFE2E0; }
    .ind-code-showcase-details-footer .ind-code-showcase-reactions.heart.active, .ind-code-showcase-details-footer .ind-code-showcase-reactions.heart:hover {
      background: #FCE3F2; }
    .ind-code-showcase-details-footer .ind-code-showcase-reactions.celebrate.active, .ind-code-showcase-details-footer .ind-code-showcase-reactions.celebrate:hover {
      background: rgba(249, 231, 63, 0.2); }
  .ind-code-showcase-details-footer .ind-code-showcase-share-container {
    cursor: pointer;
    font-size: 4.34783vw; }
    @media (min-width: 1300px) {
      .ind-code-showcase-details-footer .ind-code-showcase-share-container {
        font-size: 1.14583vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .ind-code-showcase-details-footer .ind-code-showcase-share-container {
        font-size: 1.14583vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .ind-code-showcase-details-footer .ind-code-showcase-share-container {
        font-size: 1.14583vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .ind-code-showcase-details-footer .ind-code-showcase-share-container {
        font-size: 3.62319vw; }
        .ind-code-showcase-details-footer .ind-code-showcase-share-container else {
          font-size: 18px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .ind-code-showcase-details-footer .ind-code-showcase-share-container {
        font-size: 2.89855vw; }
        .ind-code-showcase-details-footer .ind-code-showcase-share-container else {
          font-size: 18px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .ind-code-showcase-details-footer .ind-code-showcase-share-container {
        font-size: 2.55754vw; }
        .ind-code-showcase-details-footer .ind-code-showcase-share-container else {
          font-size: 18px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .ind-code-showcase-details-footer .ind-code-showcase-share-container {
        font-size: 2.28833vw; }
        .ind-code-showcase-details-footer .ind-code-showcase-share-container else {
          font-size: 18px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .ind-code-showcase-details-footer .ind-code-showcase-share-container {
        font-size: 2.07039vw; }
        .ind-code-showcase-details-footer .ind-code-showcase-share-container else {
          font-size: 18px; } }
    .ind-code-showcase-details-footer .ind-code-showcase-share-container .ind-code-showcase-shareIcon {
      display: block;
      position: relative;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      background-image: url(/static/media/shareCode.1901aaaa.svg);
      width: 3.62319vw;
      margin-right: 1.93237vw; }
      @media (min-width: 1300px) {
        .ind-code-showcase-details-footer .ind-code-showcase-share-container .ind-code-showcase-shareIcon {
          width: 0.88542vw;
          margin-right: 0.52083vw; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .ind-code-showcase-details-footer .ind-code-showcase-share-container .ind-code-showcase-shareIcon {
          width: 0.9838vw;
          margin-right: 0.52083vw; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .ind-code-showcase-details-footer .ind-code-showcase-share-container .ind-code-showcase-shareIcon {
          width: 0.88542vw;
          margin-right: 0.52083vw; } }
      @media (min-width: 450px) and (max-width: 500px) {
        .ind-code-showcase-details-footer .ind-code-showcase-share-container .ind-code-showcase-shareIcon {
          width: 3.01932vw; }
          .ind-code-showcase-details-footer .ind-code-showcase-share-container .ind-code-showcase-shareIcon else {
            width: 15px; }
          .ind-code-showcase-details-footer .ind-code-showcase-share-container .ind-code-showcase-shareIcon else {
            margin-right: 1.93237vw; } }
      @media (min-width: 500px) and (max-width: 600px) {
        .ind-code-showcase-details-footer .ind-code-showcase-share-container .ind-code-showcase-shareIcon {
          width: 2.41546vw; }
          .ind-code-showcase-details-footer .ind-code-showcase-share-container .ind-code-showcase-shareIcon else {
            width: 15px; }
          .ind-code-showcase-details-footer .ind-code-showcase-share-container .ind-code-showcase-shareIcon else {
            margin-right: 1.93237vw; } }
      @media (min-width: 600px) and (max-width: 700px) {
        .ind-code-showcase-details-footer .ind-code-showcase-share-container .ind-code-showcase-shareIcon {
          width: 2.13129vw; }
          .ind-code-showcase-details-footer .ind-code-showcase-share-container .ind-code-showcase-shareIcon else {
            width: 15px; }
          .ind-code-showcase-details-footer .ind-code-showcase-share-container .ind-code-showcase-shareIcon else {
            margin-right: 1.93237vw; } }
      @media (min-width: 700px) and (max-width: 800px) {
        .ind-code-showcase-details-footer .ind-code-showcase-share-container .ind-code-showcase-shareIcon {
          width: 1.90694vw; }
          .ind-code-showcase-details-footer .ind-code-showcase-share-container .ind-code-showcase-shareIcon else {
            width: 15px; }
          .ind-code-showcase-details-footer .ind-code-showcase-share-container .ind-code-showcase-shareIcon else {
            margin-right: 1.93237vw; } }
      @media (min-width: 800px) and (max-width: 899px) {
        .ind-code-showcase-details-footer .ind-code-showcase-share-container .ind-code-showcase-shareIcon {
          width: 1.72533vw; }
          .ind-code-showcase-details-footer .ind-code-showcase-share-container .ind-code-showcase-shareIcon else {
            width: 15px; }
          .ind-code-showcase-details-footer .ind-code-showcase-share-container .ind-code-showcase-shareIcon else {
            margin-right: 1.93237vw; } }

.ind-code-showcase-trending-text {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 2.89855vw;
  margin: 2.5vw; }
  @media (min-width: 1300px) {
    .ind-code-showcase-trending-text {
      font-size: 0.9375vw;
      margin: 1.25vw 1.66667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ind-code-showcase-trending-text {
      font-size: 1.04167vw;
      margin: 1.25vw 1.66667vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .ind-code-showcase-trending-text {
      font-size: 0.9375vw;
      margin: 1.25vw 1.66667vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .ind-code-showcase-trending-text {
      font-size: 2.41546vw; }
      .ind-code-showcase-trending-text else {
        font-size: 12px; }
      .ind-code-showcase-trending-text else {
        margin: 2.5vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .ind-code-showcase-trending-text {
      font-size: 1.93237vw; }
      .ind-code-showcase-trending-text else {
        font-size: 12px; }
      .ind-code-showcase-trending-text else {
        margin: 2.5vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .ind-code-showcase-trending-text {
      font-size: 1.70503vw; }
      .ind-code-showcase-trending-text else {
        font-size: 12px; }
      .ind-code-showcase-trending-text else {
        margin: 2.5vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .ind-code-showcase-trending-text {
      font-size: 1.52555vw; }
      .ind-code-showcase-trending-text else {
        font-size: 12px; }
      .ind-code-showcase-trending-text else {
        margin: 2.5vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .ind-code-showcase-trending-text {
      font-size: 1.38026vw; }
      .ind-code-showcase-trending-text else {
        font-size: 12px; }
      .ind-code-showcase-trending-text else {
        margin: 2.5vw; } }
  .ind-code-showcase-trending-text > div > span {
    display: none; }
    @media (min-width: 1300px) {
      .ind-code-showcase-trending-text > div > span {
        display: block; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .ind-code-showcase-trending-text > div > span {
        display: block; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .ind-code-showcase-trending-text > div > span {
        display: block; } }

.ind-code-showcase-trendingIcon {
  margin-right: 10px;
  display: block;
  position: relative;
  width: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url(/static/media/trending.47185730.svg); }

.ind-code-showcase-empty-container {
  width: 100%;
  padding: 1rem 0; }
  .ind-code-showcase-empty-container .ind-code-showcase-uhohMsgContainer {
    margin-top: 5.20833vw;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: 600;
    color: #00171F;
    opacity: 0.6;
    font-size: 4.34783vw; }
    @media (min-width: 1300px) {
      .ind-code-showcase-empty-container .ind-code-showcase-uhohMsgContainer {
        font-size: 1.5625vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .ind-code-showcase-empty-container .ind-code-showcase-uhohMsgContainer {
        font-size: 1.5625vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .ind-code-showcase-empty-container .ind-code-showcase-uhohMsgContainer {
        font-size: 1.5625vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .ind-code-showcase-empty-container .ind-code-showcase-uhohMsgContainer {
        font-size: 3.62319vw; }
        .ind-code-showcase-empty-container .ind-code-showcase-uhohMsgContainer else {
          font-size: 18px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .ind-code-showcase-empty-container .ind-code-showcase-uhohMsgContainer {
        font-size: 2.89855vw; }
        .ind-code-showcase-empty-container .ind-code-showcase-uhohMsgContainer else {
          font-size: 18px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .ind-code-showcase-empty-container .ind-code-showcase-uhohMsgContainer {
        font-size: 2.55754vw; }
        .ind-code-showcase-empty-container .ind-code-showcase-uhohMsgContainer else {
          font-size: 18px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .ind-code-showcase-empty-container .ind-code-showcase-uhohMsgContainer {
        font-size: 2.28833vw; }
        .ind-code-showcase-empty-container .ind-code-showcase-uhohMsgContainer else {
          font-size: 18px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .ind-code-showcase-empty-container .ind-code-showcase-uhohMsgContainer {
        font-size: 2.07039vw; }
        .ind-code-showcase-empty-container .ind-code-showcase-uhohMsgContainer else {
          font-size: 18px; } }
  .ind-code-showcase-empty-container .ind-code-showcase-emptyMsgContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: 500;
    color: #00171F;
    opacity: 0.5;
    line-height: 32px;
    font-size: 4.34783vw; }
    @media (min-width: 1300px) {
      .ind-code-showcase-empty-container .ind-code-showcase-emptyMsgContainer {
        font-size: 1.35417vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .ind-code-showcase-empty-container .ind-code-showcase-emptyMsgContainer {
        font-size: 1.35417vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .ind-code-showcase-empty-container .ind-code-showcase-emptyMsgContainer {
        font-size: 1.35417vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .ind-code-showcase-empty-container .ind-code-showcase-emptyMsgContainer {
        font-size: 3.62319vw; }
        .ind-code-showcase-empty-container .ind-code-showcase-emptyMsgContainer else {
          font-size: 18px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .ind-code-showcase-empty-container .ind-code-showcase-emptyMsgContainer {
        font-size: 2.89855vw; }
        .ind-code-showcase-empty-container .ind-code-showcase-emptyMsgContainer else {
          font-size: 18px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .ind-code-showcase-empty-container .ind-code-showcase-emptyMsgContainer {
        font-size: 2.55754vw; }
        .ind-code-showcase-empty-container .ind-code-showcase-emptyMsgContainer else {
          font-size: 18px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .ind-code-showcase-empty-container .ind-code-showcase-emptyMsgContainer {
        font-size: 2.28833vw; }
        .ind-code-showcase-empty-container .ind-code-showcase-emptyMsgContainer else {
          font-size: 18px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .ind-code-showcase-empty-container .ind-code-showcase-emptyMsgContainer {
        font-size: 2.07039vw; }
        .ind-code-showcase-empty-container .ind-code-showcase-emptyMsgContainer else {
          font-size: 18px; } }

#ind-code-showcase-editor-view #editor {
  height: 100% !important; }

.lato, .lato-light, .cert-showcase-header-login-btn, .lato-regular, .lato-hairline {
  font-family: 'Lato', sans-serif; }

.lato-light, .cert-showcase-header-login-btn {
  font-weight: 300; }

.lato-regular {
  font-weight: 400; }

.lato-hairline {
  font-weight: 100; }

.cert-showcase-header-container {
  padding: 8px 16px;
  justify-content: space-between;
  left: 0;
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 9999;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: row;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08); }

.cert-showcase-header-tekieLogo {
  width: 21.25604vw;
  height: 11.5942vw;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center; }
  @media (min-width: 450px) and (max-width: 500px) {
    .cert-showcase-header-tekieLogo {
      width: 17.71337vw;
      height: 9.66184vw; }
      .cert-showcase-header-tekieLogo else {
        width: 88px; }
      .cert-showcase-header-tekieLogo else {
        height: 48px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cert-showcase-header-tekieLogo {
      width: 14.17069vw;
      height: 7.72947vw; }
      .cert-showcase-header-tekieLogo else {
        width: 88px; }
      .cert-showcase-header-tekieLogo else {
        height: 48px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cert-showcase-header-tekieLogo {
      width: 12.50355vw;
      height: 6.82012vw; }
      .cert-showcase-header-tekieLogo else {
        width: 88px; }
      .cert-showcase-header-tekieLogo else {
        height: 48px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cert-showcase-header-tekieLogo {
      width: 11.18739vw;
      height: 6.10221vw; }
      .cert-showcase-header-tekieLogo else {
        width: 88px; }
      .cert-showcase-header-tekieLogo else {
        height: 48px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cert-showcase-header-tekieLogo {
      width: 10.12192vw;
      height: 5.52105vw; }
      .cert-showcase-header-tekieLogo else {
        width: 88px; }
      .cert-showcase-header-tekieLogo else {
        height: 48px; } }
  @media (min-width: 1300px) {
    .cert-showcase-header-tekieLogo {
      width: 5.625vw;
      height: 1.97917vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cert-showcase-header-tekieLogo {
      width: 6.25vw;
      height: 2.19907vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cert-showcase-header-tekieLogo {
      width: 5.625vw;
      height: 1.97917vw; } }

.cert-showcase-exploreTekieBtn {
  display: none;
  transition: all .2s;
  font-family: Lato;
  text-decoration: none;
  font-weight: bold;
  color: #FFFFFF;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: linear-gradient(270deg, #35E4E9 -8.62%, #00ADE6 109.2%);
  border-radius: 6px; }
  @media (min-width: 1300px) {
    .cert-showcase-exploreTekieBtn {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      font-size: 1.14583vw;
      min-width: 13.02083vw;
      padding: 0.625vw 0;
      margin-right: 0.625vw;
      letter-spacing: 0.02604vw;
      position: relative;
      display: flex; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cert-showcase-exploreTekieBtn {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      font-size: 1.27315vw;
      min-width: 14.46759vw;
      padding: 0.69444vw 0;
      margin-right: 0.69444vw;
      letter-spacing: 0.02894vw;
      position: relative;
      display: flex; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cert-showcase-exploreTekieBtn {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      font-size: 1.14583vw;
      min-width: 13.02083vw;
      padding: 0.625vw 0;
      margin-right: 0.625vw;
      letter-spacing: 0.02604vw;
      position: relative;
      display: flex; } }
  .cert-showcase-exploreTekieBtn:hover, .cert-showcase-exploreTekieBtn .code-showcase-header-active {
    background: linear-gradient(170deg, #35E4E9 -8.62%, #00ADE6 109.2%); }

.cert-showcase-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 15.45894vw 3.86473vw 5.7971vw; }
  @media (min-width: 1300px) {
    .cert-showcase-container {
      padding: 5vw 2.5vw 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cert-showcase-container {
      padding: 5.55556vw 2.77778vw 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cert-showcase-container {
      padding: 5vw 2.5vw 0.41667vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cert-showcase-container {
      padding: 12.88245vw 3.22061vw 4.83092vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cert-showcase-container {
      padding: 10.30596vw 2.57649vw 3.86473vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cert-showcase-container {
      padding: 9.09349vw 2.27337vw 3.41006vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cert-showcase-container {
      padding: 8.13628vw 2.03407vw 3.05111vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cert-showcase-container {
      padding: 7.3614vw 1.84035vw 2.76052vw; } }
  .cert-showcase-container > .cert-showcase-innerContainer {
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 0;
    display: block; }
    @media (min-width: 1300px) {
      .cert-showcase-container > .cert-showcase-innerContainer:nth-child(1) {
        width: 30%; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cert-showcase-container > .cert-showcase-innerContainer:nth-child(1) {
        width: 30%; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cert-showcase-container > .cert-showcase-innerContainer:nth-child(1) {
        width: 30%; } }
    .cert-showcase-container > .cert-showcase-innerContainer:nth-child(2) {
      -webkit-filter: drop-shadow(2px 4px 12px rgba(0, 0, 0, 0.12));
              filter: drop-shadow(2px 4px 12px rgba(0, 0, 0, 0.12));
      border-radius: 4px; }
      @media (min-width: 1300px) {
        .cert-showcase-container > .cert-showcase-innerContainer:nth-child(2) {
          width: 60%;
          padding: 2.5vw; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .cert-showcase-container > .cert-showcase-innerContainer:nth-child(2) {
          width: 60%;
          padding: 2.77778vw; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .cert-showcase-container > .cert-showcase-innerContainer:nth-child(2) {
          width: 60%;
          padding: 2.5vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .cert-showcase-container > .cert-showcase-innerContainer else {
        width: 100%; }
      .cert-showcase-container > .cert-showcase-innerContainer else {
        padding: 0; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .cert-showcase-container > .cert-showcase-innerContainer else {
        width: 100%; }
      .cert-showcase-container > .cert-showcase-innerContainer else {
        padding: 0; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .cert-showcase-container > .cert-showcase-innerContainer else {
        width: 100%; }
      .cert-showcase-container > .cert-showcase-innerContainer else {
        padding: 0; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .cert-showcase-container > .cert-showcase-innerContainer else {
        width: 100%; }
      .cert-showcase-container > .cert-showcase-innerContainer else {
        padding: 0; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .cert-showcase-container > .cert-showcase-innerContainer else {
        width: 100%; }
      .cert-showcase-container > .cert-showcase-innerContainer else {
        padding: 0; } }

.cert-showcase-details-container {
  align-self: flex-start;
  justify-content: flex-start;
  font-family: Nunito;
  height: 100%;
  width: 100%;
  padding: 5.7971vw 0;
  margin: 0; }
  @media (min-width: 1300px) {
    .cert-showcase-details-container {
      padding: 0 1.25vw 0 0.83333vw;
      margin: 2.5vw 0; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cert-showcase-details-container {
      padding: 0 1.38889vw 0 0.92593vw;
      margin: 2.77778vw 0; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cert-showcase-details-container {
      padding: 0 1.25vw 0 0.83333vw;
      margin: 2.5vw 0; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cert-showcase-details-container {
      padding: 4.83092vw 0; }
      .cert-showcase-details-container else {
        margin: 0; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cert-showcase-details-container {
      padding: 3.86473vw 0; }
      .cert-showcase-details-container else {
        margin: 0; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cert-showcase-details-container {
      padding: 3.41006vw 0; }
      .cert-showcase-details-container else {
        margin: 0; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cert-showcase-details-container {
      padding: 3.05111vw 0; }
      .cert-showcase-details-container else {
        margin: 0; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cert-showcase-details-container {
      padding: 2.76052vw 0; }
      .cert-showcase-details-container else {
        margin: 0; } }
  .cert-showcase-details-container .cert-showcase-verified {
    font-family: Nunito;
    font-weight: bold;
    letter-spacing: -0.02em;
    color: #01AA93;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 5.31401vw;
    margin-bottom: 2.89855vw; }
    @media (min-width: 1300px) {
      .cert-showcase-details-container .cert-showcase-verified {
        font-size: 1.35417vw;
        margin-bottom: 1.66667vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cert-showcase-details-container .cert-showcase-verified {
        font-size: 1.50463vw;
        margin-bottom: 1.85185vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cert-showcase-details-container .cert-showcase-verified {
        font-size: 1.35417vw;
        margin-bottom: 1.66667vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .cert-showcase-details-container .cert-showcase-verified {
        font-size: 4.42834vw;
        margin-bottom: 2.41546vw; }
        .cert-showcase-details-container .cert-showcase-verified else {
          font-size: 22px; }
        .cert-showcase-details-container .cert-showcase-verified else {
          margin-bottom: 12px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .cert-showcase-details-container .cert-showcase-verified {
        font-size: 3.54267vw;
        margin-bottom: 1.93237vw; }
        .cert-showcase-details-container .cert-showcase-verified else {
          font-size: 22px; }
        .cert-showcase-details-container .cert-showcase-verified else {
          margin-bottom: 12px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .cert-showcase-details-container .cert-showcase-verified {
        font-size: 3.12589vw;
        margin-bottom: 1.70503vw; }
        .cert-showcase-details-container .cert-showcase-verified else {
          font-size: 22px; }
        .cert-showcase-details-container .cert-showcase-verified else {
          margin-bottom: 12px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .cert-showcase-details-container .cert-showcase-verified {
        font-size: 2.79685vw;
        margin-bottom: 1.52555vw; }
        .cert-showcase-details-container .cert-showcase-verified else {
          font-size: 22px; }
        .cert-showcase-details-container .cert-showcase-verified else {
          margin-bottom: 12px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .cert-showcase-details-container .cert-showcase-verified {
        font-size: 2.53048vw;
        margin-bottom: 1.38026vw; }
        .cert-showcase-details-container .cert-showcase-verified else {
          font-size: 22px; }
        .cert-showcase-details-container .cert-showcase-verified else {
          margin-bottom: 12px; } }
  .cert-showcase-details-container .cert-showcase-text {
    letter-spacing: 0.5px;
    font-size: 3.86473vw;
    margin-bottom: 1.44928vw;
    color: #A8A7A7;
    font-weight: bold; }
    @media (min-width: 1300px) {
      .cert-showcase-details-container .cert-showcase-text {
        font-size: 1.04167vw;
        margin-bottom: 1.5625vw;
        color: #504F4F;
        font-weight: normal; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cert-showcase-details-container .cert-showcase-text {
        font-size: 1.15741vw;
        margin-bottom: 1.73611vw;
        color: #504F4F;
        font-weight: normal; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cert-showcase-details-container .cert-showcase-text {
        font-size: 1.04167vw;
        margin-bottom: 1.5625vw;
        color: #504F4F;
        font-weight: normal; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .cert-showcase-details-container .cert-showcase-text {
        font-size: 3.22061vw;
        margin-bottom: 1.20773vw; }
        .cert-showcase-details-container .cert-showcase-text else {
          font-size: 16px; }
        .cert-showcase-details-container .cert-showcase-text else {
          margin-bottom: 6px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .cert-showcase-details-container .cert-showcase-text {
        font-size: 2.57649vw;
        margin-bottom: 0.96618vw; }
        .cert-showcase-details-container .cert-showcase-text else {
          font-size: 16px; }
        .cert-showcase-details-container .cert-showcase-text else {
          margin-bottom: 6px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .cert-showcase-details-container .cert-showcase-text {
        font-size: 2.27337vw;
        margin-bottom: 0.85251vw; }
        .cert-showcase-details-container .cert-showcase-text else {
          font-size: 16px; }
        .cert-showcase-details-container .cert-showcase-text else {
          margin-bottom: 6px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .cert-showcase-details-container .cert-showcase-text {
        font-size: 2.03407vw;
        margin-bottom: 0.76278vw; }
        .cert-showcase-details-container .cert-showcase-text else {
          font-size: 16px; }
        .cert-showcase-details-container .cert-showcase-text else {
          margin-bottom: 6px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .cert-showcase-details-container .cert-showcase-text {
        font-size: 1.84035vw;
        margin-bottom: 0.69013vw; }
        .cert-showcase-details-container .cert-showcase-text else {
          font-size: 16px; }
        .cert-showcase-details-container .cert-showcase-text else {
          margin-bottom: 6px; } }
    .cert-showcase-details-container .cert-showcase-text span {
      text-transform: capitalize;
      color: #504F4F;
      font-weight: 700; }
  .cert-showcase-details-container .cert-showcase-courseSnapshot-header {
    font-family: Nunito;
    letter-spacing: -0.02em;
    color: #504F4F;
    font-size: 5.31401vw;
    margin-bottom: 4.83092vw;
    font-weight: normal; }
    @media (min-width: 1300px) {
      .cert-showcase-details-container .cert-showcase-courseSnapshot-header {
        font-size: 1.35417vw;
        margin-bottom: 1.45833vw;
        font-weight: 600; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cert-showcase-details-container .cert-showcase-courseSnapshot-header {
        font-size: 1.50463vw;
        margin-bottom: 1.62037vw;
        font-weight: 600; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cert-showcase-details-container .cert-showcase-courseSnapshot-header {
        font-size: 1.35417vw;
        margin-bottom: 1.45833vw;
        font-weight: 600; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .cert-showcase-details-container .cert-showcase-courseSnapshot-header {
        font-size: 4.42834vw;
        margin-bottom: 4.02576vw; }
        .cert-showcase-details-container .cert-showcase-courseSnapshot-header else {
          font-size: 22px; }
        .cert-showcase-details-container .cert-showcase-courseSnapshot-header else {
          margin-bottom: 20px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .cert-showcase-details-container .cert-showcase-courseSnapshot-header {
        font-size: 3.54267vw;
        margin-bottom: 3.22061vw; }
        .cert-showcase-details-container .cert-showcase-courseSnapshot-header else {
          font-size: 22px; }
        .cert-showcase-details-container .cert-showcase-courseSnapshot-header else {
          margin-bottom: 20px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .cert-showcase-details-container .cert-showcase-courseSnapshot-header {
        font-size: 3.12589vw;
        margin-bottom: 2.84172vw; }
        .cert-showcase-details-container .cert-showcase-courseSnapshot-header else {
          font-size: 22px; }
        .cert-showcase-details-container .cert-showcase-courseSnapshot-header else {
          margin-bottom: 20px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .cert-showcase-details-container .cert-showcase-courseSnapshot-header {
        font-size: 2.79685vw;
        margin-bottom: 2.54259vw; }
        .cert-showcase-details-container .cert-showcase-courseSnapshot-header else {
          font-size: 22px; }
        .cert-showcase-details-container .cert-showcase-courseSnapshot-header else {
          margin-bottom: 20px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .cert-showcase-details-container .cert-showcase-courseSnapshot-header {
        font-size: 2.53048vw;
        margin-bottom: 2.30044vw; }
        .cert-showcase-details-container .cert-showcase-courseSnapshot-header else {
          font-size: 22px; }
        .cert-showcase-details-container .cert-showcase-courseSnapshot-header else {
          margin-bottom: 20px; } }
  .cert-showcase-details-container .cert-showcase-courseSnapshot-row {
    display: flex;
    flex-direction: row; }
    @media (min-width: 1300px) {
      .cert-showcase-details-container .cert-showcase-courseSnapshot-row {
        flex-direction: column; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cert-showcase-details-container .cert-showcase-courseSnapshot-row {
        flex-direction: column; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cert-showcase-details-container .cert-showcase-courseSnapshot-row {
        flex-direction: column; } }
  .cert-showcase-details-container .cert-showcase-courseSnapshot-column {
    display: flex;
    font-size: 3.86473vw;
    margin-bottom: 4.83092vw;
    flex-direction: column;
    align-items: flex-start;
    color: #504F4F;
    font-family: Nunito; }
    @media (min-width: 1300px) {
      .cert-showcase-details-container .cert-showcase-courseSnapshot-column {
        font-size: 1.04167vw;
        margin-bottom: 1.45833vw;
        flex-direction: row;
        align-items: center; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cert-showcase-details-container .cert-showcase-courseSnapshot-column {
        font-size: 1.15741vw;
        margin-bottom: 1.62037vw;
        flex-direction: row;
        align-items: center; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cert-showcase-details-container .cert-showcase-courseSnapshot-column {
        font-size: 1.04167vw;
        margin-bottom: 1.45833vw;
        flex-direction: row;
        align-items: center; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .cert-showcase-details-container .cert-showcase-courseSnapshot-column {
        font-size: 3.22061vw;
        margin-bottom: 4.02576vw; }
        .cert-showcase-details-container .cert-showcase-courseSnapshot-column else {
          font-size: 16px; }
        .cert-showcase-details-container .cert-showcase-courseSnapshot-column else {
          margin-bottom: 20px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .cert-showcase-details-container .cert-showcase-courseSnapshot-column {
        font-size: 2.57649vw;
        margin-bottom: 3.22061vw; }
        .cert-showcase-details-container .cert-showcase-courseSnapshot-column else {
          font-size: 16px; }
        .cert-showcase-details-container .cert-showcase-courseSnapshot-column else {
          margin-bottom: 20px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .cert-showcase-details-container .cert-showcase-courseSnapshot-column {
        font-size: 2.27337vw;
        margin-bottom: 2.84172vw; }
        .cert-showcase-details-container .cert-showcase-courseSnapshot-column else {
          font-size: 16px; }
        .cert-showcase-details-container .cert-showcase-courseSnapshot-column else {
          margin-bottom: 20px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .cert-showcase-details-container .cert-showcase-courseSnapshot-column {
        font-size: 2.03407vw;
        margin-bottom: 2.54259vw; }
        .cert-showcase-details-container .cert-showcase-courseSnapshot-column else {
          font-size: 16px; }
        .cert-showcase-details-container .cert-showcase-courseSnapshot-column else {
          margin-bottom: 20px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .cert-showcase-details-container .cert-showcase-courseSnapshot-column {
        font-size: 1.84035vw;
        margin-bottom: 2.30044vw; }
        .cert-showcase-details-container .cert-showcase-courseSnapshot-column else {
          font-size: 16px; }
        .cert-showcase-details-container .cert-showcase-courseSnapshot-column else {
          margin-bottom: 20px; } }
    .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-title {
      width: 43.47826vw;
      padding-bottom: 2.89855vw;
      font-weight: bold;
      color: #A8A7A7; }
      @media (min-width: 1300px) {
        .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-title {
          width: 7.8125vw;
          padding-bottom: 0;
          font-weight: normal;
          color: #504F4F; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-title {
          width: 8.68056vw;
          padding-bottom: 0;
          font-weight: normal;
          color: #504F4F; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-title {
          width: 7.8125vw;
          padding-bottom: 0;
          font-weight: normal;
          color: #504F4F; } }
      @media (min-width: 450px) and (max-width: 500px) {
        .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-title {
          width: 36.23188vw;
          padding-bottom: 2.41546vw; }
          .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-title else {
            width: 180px; }
          .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-title else {
            padding-bottom: 12px; } }
      @media (min-width: 500px) and (max-width: 600px) {
        .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-title {
          width: 28.98551vw;
          padding-bottom: 1.93237vw; }
          .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-title else {
            width: 180px; }
          .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-title else {
            padding-bottom: 12px; } }
      @media (min-width: 600px) and (max-width: 700px) {
        .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-title {
          width: 25.57545vw;
          padding-bottom: 1.70503vw; }
          .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-title else {
            width: 180px; }
          .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-title else {
            padding-bottom: 12px; } }
      @media (min-width: 700px) and (max-width: 800px) {
        .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-title {
          width: 22.8833vw;
          padding-bottom: 1.52555vw; }
          .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-title else {
            width: 180px; }
          .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-title else {
            padding-bottom: 12px; } }
      @media (min-width: 800px) and (max-width: 899px) {
        .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-title {
          width: 20.70393vw;
          padding-bottom: 1.38026vw; }
          .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-title else {
            width: 180px; }
          .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-title else {
            padding-bottom: 12px; } }
    .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-description {
      font-weight: bold;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-transform: capitalize;
      font-size: 4.34783vw; }
      @media (min-width: 1300px) {
        .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-description {
          font-size: 1.04167vw; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-description {
          font-size: 1.15741vw; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-description {
          font-size: 1.04167vw; } }
      @media (min-width: 450px) and (max-width: 500px) {
        .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-description {
          font-size: 3.62319vw; }
          .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-description else {
            font-size: 18px; } }
      @media (min-width: 500px) and (max-width: 600px) {
        .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-description {
          font-size: 2.89855vw; }
          .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-description else {
            font-size: 18px; } }
      @media (min-width: 600px) and (max-width: 700px) {
        .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-description {
          font-size: 2.55754vw; }
          .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-description else {
            font-size: 18px; } }
      @media (min-width: 700px) and (max-width: 800px) {
        .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-description {
          font-size: 2.28833vw; }
          .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-description else {
            font-size: 18px; } }
      @media (min-width: 800px) and (max-width: 899px) {
        .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-description {
          font-size: 2.07039vw; }
          .cert-showcase-details-container .cert-showcase-courseSnapshot-column .cert-showcase-courseSnapshot-description else {
            font-size: 18px; } }
  .cert-showcase-details-container .cert-showcase-divider {
    border-bottom: 1.5px solid #DCDCDC;
    display: block;
    margin: 5.7971vw 0; }
    @media (min-width: 1300px) {
      .cert-showcase-details-container .cert-showcase-divider {
        margin: 1.25vw 0; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cert-showcase-details-container .cert-showcase-divider {
        margin: 1.38889vw 0; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cert-showcase-details-container .cert-showcase-divider {
        margin: 1.25vw 0; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .cert-showcase-details-container .cert-showcase-divider {
        margin: 4.83092vw 0; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .cert-showcase-details-container .cert-showcase-divider {
        margin: 3.86473vw 0; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .cert-showcase-details-container .cert-showcase-divider {
        margin: 3.41006vw 0; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .cert-showcase-details-container .cert-showcase-divider {
        margin: 3.05111vw 0; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .cert-showcase-details-container .cert-showcase-divider {
        margin: 2.76052vw 0; } }

.cert-showcase-courseDetails-header {
  font-family: Nunito;
  letter-spacing: -0.02em;
  color: #504F4F;
  font-size: 4.83092vw;
  margin-bottom: 4.83092vw;
  font-weight: normal; }
  @media (min-width: 1300px) {
    .cert-showcase-courseDetails-header {
      font-size: 1.35417vw;
      margin-bottom: 1.14583vw;
      font-weight: 600; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cert-showcase-courseDetails-header {
      font-size: 1.50463vw;
      margin-bottom: 1.27315vw;
      font-weight: 600; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cert-showcase-courseDetails-header {
      font-size: 1.35417vw;
      margin-bottom: 1.14583vw;
      font-weight: 600; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cert-showcase-courseDetails-header {
      font-size: 4.02576vw;
      margin-bottom: 4.02576vw; }
      .cert-showcase-courseDetails-header else {
        font-size: 20px; }
      .cert-showcase-courseDetails-header else {
        margin-bottom: 20px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cert-showcase-courseDetails-header {
      font-size: 3.22061vw;
      margin-bottom: 3.22061vw; }
      .cert-showcase-courseDetails-header else {
        font-size: 20px; }
      .cert-showcase-courseDetails-header else {
        margin-bottom: 20px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cert-showcase-courseDetails-header {
      font-size: 2.84172vw;
      margin-bottom: 2.84172vw; }
      .cert-showcase-courseDetails-header else {
        font-size: 20px; }
      .cert-showcase-courseDetails-header else {
        margin-bottom: 20px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cert-showcase-courseDetails-header {
      font-size: 2.54259vw;
      margin-bottom: 2.54259vw; }
      .cert-showcase-courseDetails-header else {
        font-size: 20px; }
      .cert-showcase-courseDetails-header else {
        margin-bottom: 20px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cert-showcase-courseDetails-header {
      font-size: 2.30044vw;
      margin-bottom: 2.30044vw; }
      .cert-showcase-courseDetails-header else {
        font-size: 20px; }
      .cert-showcase-courseDetails-header else {
        margin-bottom: 20px; } }

.cert-showcase-moreTag {
  background: #DCDCDC;
  border-radius: 100%;
  margin: 0px 6px;
  padding: 6px;
  cursor: pointer;
  display: grid;
  place-items: center;
  font-size: 3.38164vw;
  width: 6.76329vw;
  height: 6.76329vw; }
  @media (min-width: 1300px) {
    .cert-showcase-moreTag {
      font-size: 0.83333vw;
      width: 1.45833vw;
      height: 1.45833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cert-showcase-moreTag {
      font-size: 0.92593vw;
      width: 1.62037vw;
      height: 1.62037vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cert-showcase-moreTag {
      font-size: 0.83333vw;
      width: 1.45833vw;
      height: 1.45833vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cert-showcase-moreTag {
      font-size: 2.81804vw;
      width: 5.63607vw;
      height: 5.63607vw; }
      .cert-showcase-moreTag else {
        font-size: 14px; }
      .cert-showcase-moreTag else {
        width: 28px; }
      .cert-showcase-moreTag else {
        height: 28px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cert-showcase-moreTag {
      font-size: 2.25443vw;
      width: 4.50886vw;
      height: 4.50886vw; }
      .cert-showcase-moreTag else {
        font-size: 14px; }
      .cert-showcase-moreTag else {
        width: 28px; }
      .cert-showcase-moreTag else {
        height: 28px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cert-showcase-moreTag {
      font-size: 1.9892vw;
      width: 3.9784vw;
      height: 3.9784vw; }
      .cert-showcase-moreTag else {
        font-size: 14px; }
      .cert-showcase-moreTag else {
        width: 28px; }
      .cert-showcase-moreTag else {
        height: 28px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cert-showcase-moreTag {
      font-size: 1.77981vw;
      width: 3.55962vw;
      height: 3.55962vw; }
      .cert-showcase-moreTag else {
        font-size: 14px; }
      .cert-showcase-moreTag else {
        width: 28px; }
      .cert-showcase-moreTag else {
        height: 28px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cert-showcase-moreTag {
      font-size: 1.61031vw;
      width: 3.22061vw;
      height: 3.22061vw; }
      .cert-showcase-moreTag else {
        font-size: 14px; }
      .cert-showcase-moreTag else {
        width: 28px; }
      .cert-showcase-moreTag else {
        height: 28px; } }

.cert-showcase-courseDetails-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  padding: 4.34783vw;
  flex-wrap: wrap; }
  @media (min-width: 1300px) {
    .cert-showcase-courseDetails-container {
      padding: 0.9375vw;
      flex-wrap: nowrap; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cert-showcase-courseDetails-container {
      padding: 1.04167vw;
      flex-wrap: nowrap; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cert-showcase-courseDetails-container {
      padding: 0.9375vw;
      flex-wrap: nowrap; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cert-showcase-courseDetails-container {
      padding: 3.62319vw; }
      .cert-showcase-courseDetails-container else {
        padding: 18px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cert-showcase-courseDetails-container {
      padding: 2.89855vw; }
      .cert-showcase-courseDetails-container else {
        padding: 18px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cert-showcase-courseDetails-container {
      padding: 2.55754vw; }
      .cert-showcase-courseDetails-container else {
        padding: 18px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cert-showcase-courseDetails-container {
      padding: 2.28833vw; }
      .cert-showcase-courseDetails-container else {
        padding: 18px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cert-showcase-courseDetails-container {
      padding: 2.07039vw; }
      .cert-showcase-courseDetails-container else {
        padding: 18px; } }
  .cert-showcase-courseDetails-container > .cert-showcase-courseImage {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
    width: 19.80676vw;
    height: 19.80676vw; }
    @media (min-width: 1300px) {
      .cert-showcase-courseDetails-container > .cert-showcase-courseImage {
        width: 6.77083vw;
        height: 6.25vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cert-showcase-courseDetails-container > .cert-showcase-courseImage {
        width: 7.52315vw;
        height: 6.94444vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cert-showcase-courseDetails-container > .cert-showcase-courseImage {
        width: 6.77083vw;
        height: 6.25vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .cert-showcase-courseDetails-container > .cert-showcase-courseImage {
        width: 16.50564vw;
        height: 16.50564vw; }
        .cert-showcase-courseDetails-container > .cert-showcase-courseImage else {
          width: 82px; }
        .cert-showcase-courseDetails-container > .cert-showcase-courseImage else {
          height: 82px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .cert-showcase-courseDetails-container > .cert-showcase-courseImage {
        width: 13.20451vw;
        height: 13.20451vw; }
        .cert-showcase-courseDetails-container > .cert-showcase-courseImage else {
          width: 82px; }
        .cert-showcase-courseDetails-container > .cert-showcase-courseImage else {
          height: 82px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .cert-showcase-courseDetails-container > .cert-showcase-courseImage {
        width: 11.65104vw;
        height: 11.65104vw; }
        .cert-showcase-courseDetails-container > .cert-showcase-courseImage else {
          width: 82px; }
        .cert-showcase-courseDetails-container > .cert-showcase-courseImage else {
          height: 82px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .cert-showcase-courseDetails-container > .cert-showcase-courseImage {
        width: 10.42461vw;
        height: 10.42461vw; }
        .cert-showcase-courseDetails-container > .cert-showcase-courseImage else {
          width: 82px; }
        .cert-showcase-courseDetails-container > .cert-showcase-courseImage else {
          height: 82px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .cert-showcase-courseDetails-container > .cert-showcase-courseImage {
        width: 9.43179vw;
        height: 9.43179vw; }
        .cert-showcase-courseDetails-container > .cert-showcase-courseImage else {
          width: 82px; }
        .cert-showcase-courseDetails-container > .cert-showcase-courseImage else {
          height: 82px; } }
  .cert-showcase-courseDetails-container .cert-showcase-courseDetails {
    padding-left: 2.89855vw;
    padding-top: 0.96618vw;
    text-transform: capitalize;
    z-index: 1;
    font-family: Nunito; }
    @media (min-width: 1300px) {
      .cert-showcase-courseDetails-container .cert-showcase-courseDetails {
        padding-left: 0.9375vw;
        padding-top: 0.3125vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cert-showcase-courseDetails-container .cert-showcase-courseDetails {
        padding-left: 1.04167vw;
        padding-top: 0.34722vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cert-showcase-courseDetails-container .cert-showcase-courseDetails {
        padding-left: 0.9375vw;
        padding-top: 0.3125vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .cert-showcase-courseDetails-container .cert-showcase-courseDetails {
        padding-left: 2.41546vw;
        padding-top: 0.80515vw; }
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails else {
          padding-left: 12px; }
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails else {
          padding-top: 4px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .cert-showcase-courseDetails-container .cert-showcase-courseDetails {
        padding-left: 1.93237vw;
        padding-top: 0.64412vw; }
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails else {
          padding-left: 12px; }
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails else {
          padding-top: 4px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .cert-showcase-courseDetails-container .cert-showcase-courseDetails {
        padding-left: 1.70503vw;
        padding-top: 0.56834vw; }
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails else {
          padding-left: 12px; }
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails else {
          padding-top: 4px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .cert-showcase-courseDetails-container .cert-showcase-courseDetails {
        padding-left: 1.52555vw;
        padding-top: 0.50852vw; }
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails else {
          padding-left: 12px; }
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails else {
          padding-top: 4px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .cert-showcase-courseDetails-container .cert-showcase-courseDetails {
        padding-left: 1.38026vw;
        padding-top: 0.46009vw; }
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails else {
          padding-left: 12px; }
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails else {
          padding-top: 4px; } }
    .cert-showcase-courseDetails-container .cert-showcase-courseDetails > span {
      display: block; }
    .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-subHead {
      letter-spacing: 0.03em;
      font-size: 2.89855vw;
      margin-bottom: 0.96618vw;
      color: #00ADE6;
      font-weight: normal; }
      @media (min-width: 1300px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-subHead {
          font-size: 0.78125vw;
          margin-bottom: 0.10417vw;
          color: rgba(53, 228, 233, 0.7);
          font-weight: bold; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-subHead {
          font-size: 0.86806vw;
          margin-bottom: 0.11574vw;
          color: rgba(53, 228, 233, 0.7);
          font-weight: bold; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-subHead {
          font-size: 0.78125vw;
          margin-bottom: 0.10417vw;
          color: rgba(53, 228, 233, 0.7);
          font-weight: bold; } }
      @media (min-width: 450px) and (max-width: 500px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-subHead {
          font-size: 2.41546vw;
          margin-bottom: 0.80515vw; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-subHead else {
            font-size: 12px; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-subHead else {
            margin-bottom: 4px; } }
      @media (min-width: 500px) and (max-width: 600px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-subHead {
          font-size: 1.93237vw;
          margin-bottom: 0.64412vw; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-subHead else {
            font-size: 12px; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-subHead else {
            margin-bottom: 4px; } }
      @media (min-width: 600px) and (max-width: 700px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-subHead {
          font-size: 1.70503vw;
          margin-bottom: 0.56834vw; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-subHead else {
            font-size: 12px; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-subHead else {
            margin-bottom: 4px; } }
      @media (min-width: 700px) and (max-width: 800px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-subHead {
          font-size: 1.52555vw;
          margin-bottom: 0.50852vw; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-subHead else {
            font-size: 12px; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-subHead else {
            margin-bottom: 4px; } }
      @media (min-width: 800px) and (max-width: 899px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-subHead {
          font-size: 1.38026vw;
          margin-bottom: 0.46009vw; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-subHead else {
            font-size: 12px; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-subHead else {
            margin-bottom: 4px; } }
    .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-details-header {
      letter-spacing: 0.03em;
      font-weight: bold;
      color: #504F4F;
      font-size: 4.34783vw;
      margin-bottom: 1.44928vw; }
      @media (min-width: 1300px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-details-header {
          font-size: 1.04167vw;
          margin-bottom: 0.41667vw; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-details-header {
          font-size: 1.15741vw;
          margin-bottom: 0.46296vw; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-details-header {
          font-size: 1.04167vw;
          margin-bottom: 0.41667vw; } }
      @media (min-width: 450px) and (max-width: 500px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-details-header {
          font-size: 3.62319vw;
          margin-bottom: 1.20773vw; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-details-header else {
            font-size: 18px; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-details-header else {
            margin-bottom: 6px; } }
      @media (min-width: 500px) and (max-width: 600px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-details-header {
          font-size: 2.89855vw;
          margin-bottom: 0.96618vw; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-details-header else {
            font-size: 18px; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-details-header else {
            margin-bottom: 6px; } }
      @media (min-width: 600px) and (max-width: 700px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-details-header {
          font-size: 2.55754vw;
          margin-bottom: 0.85251vw; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-details-header else {
            font-size: 18px; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-details-header else {
            margin-bottom: 6px; } }
      @media (min-width: 700px) and (max-width: 800px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-details-header {
          font-size: 2.28833vw;
          margin-bottom: 0.76278vw; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-details-header else {
            font-size: 18px; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-details-header else {
            margin-bottom: 6px; } }
      @media (min-width: 800px) and (max-width: 899px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-details-header {
          font-size: 2.07039vw;
          margin-bottom: 0.69013vw; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-details-header else {
            font-size: 18px; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-details-header else {
            margin-bottom: 6px; } }
    .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-grade-details {
      letter-spacing: 0.03em;
      font-size: 3.38164vw;
      margin-bottom: 2.89855vw;
      font-weight: bold;
      color: #A8A7A7; }
      @media (min-width: 1300px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-grade-details {
          font-size: 0.83333vw;
          margin-bottom: 0.625vw;
          font-weight: normal;
          color: #504F4F; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-grade-details {
          font-size: 0.92593vw;
          margin-bottom: 0.69444vw;
          font-weight: normal;
          color: #504F4F; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-grade-details {
          font-size: 0.83333vw;
          margin-bottom: 0.625vw;
          font-weight: normal;
          color: #504F4F; } }
      @media (min-width: 450px) and (max-width: 500px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-grade-details {
          font-size: 2.81804vw;
          margin-bottom: 2.41546vw; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-grade-details else {
            font-size: 14px; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-grade-details else {
            margin-bottom: 12px; } }
      @media (min-width: 500px) and (max-width: 600px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-grade-details {
          font-size: 2.25443vw;
          margin-bottom: 1.93237vw; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-grade-details else {
            font-size: 14px; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-grade-details else {
            margin-bottom: 12px; } }
      @media (min-width: 600px) and (max-width: 700px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-grade-details {
          font-size: 1.9892vw;
          margin-bottom: 1.70503vw; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-grade-details else {
            font-size: 14px; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-grade-details else {
            margin-bottom: 12px; } }
      @media (min-width: 700px) and (max-width: 800px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-grade-details {
          font-size: 1.77981vw;
          margin-bottom: 1.52555vw; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-grade-details else {
            font-size: 14px; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-grade-details else {
            margin-bottom: 12px; } }
      @media (min-width: 800px) and (max-width: 899px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-grade-details {
          font-size: 1.61031vw;
          margin-bottom: 1.38026vw; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-grade-details else {
            font-size: 14px; }
          .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-grade-details else {
            margin-bottom: 12px; } }
    .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-details-viewBtn {
      font-family: Nunito;
      font-weight: bold;
      color: #00ADE6;
      cursor: pointer;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      letter-spacing: 0.03em;
      font-weight: bold;
      display: none; }
      @media (min-width: 1300px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-details-viewBtn {
          font-size: 0.72917vw;
          display: flex; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-details-viewBtn {
          font-size: 0.81019vw;
          display: flex; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .cert-showcase-courseDetails-container .cert-showcase-courseDetails .cert-showcase-details-viewBtn {
          font-size: 0.72917vw;
          display: flex; } }

.cert-showcase-details-viewBtn-mobileOnly {
  font-family: Nunito;
  font-weight: bold;
  color: #00ADE6;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.03em;
  font-weight: bold;
  background: #F0F0F0;
  border-radius: 24px;
  flex: 1 1 100%;
  font-size: 3.86473vw;
  margin: 4.34783vw 0vw 1.44928vw;
  padding: 2.89855vw 1.93237vw; }
  @media (min-width: 1300px) {
    .cert-showcase-details-viewBtn-mobileOnly {
      display: none; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cert-showcase-details-viewBtn-mobileOnly {
      display: none; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cert-showcase-details-viewBtn-mobileOnly {
      display: none; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cert-showcase-details-viewBtn-mobileOnly {
      font-size: 3.22061vw;
      margin: 3.62319vw 0vw 1.20773vw;
      padding: 2.41546vw 1.61031vw; }
      .cert-showcase-details-viewBtn-mobileOnly else {
        font-size: 16px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cert-showcase-details-viewBtn-mobileOnly {
      font-size: 2.57649vw;
      margin: 2.89855vw 0vw 0.96618vw;
      padding: 1.93237vw 1.28824vw; }
      .cert-showcase-details-viewBtn-mobileOnly else {
        font-size: 16px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cert-showcase-details-viewBtn-mobileOnly {
      font-size: 2.27337vw;
      margin: 2.55754vw 0vw 0.85251vw;
      padding: 1.70503vw 1.13669vw; }
      .cert-showcase-details-viewBtn-mobileOnly else {
        font-size: 16px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cert-showcase-details-viewBtn-mobileOnly {
      font-size: 2.03407vw;
      margin: 2.28833vw 0vw 0.76278vw;
      padding: 1.52555vw 1.01704vw; }
      .cert-showcase-details-viewBtn-mobileOnly else {
        font-size: 16px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cert-showcase-details-viewBtn-mobileOnly {
      font-size: 1.84035vw;
      margin: 2.07039vw 0vw 0.69013vw;
      padding: 1.38026vw 0.92017vw; }
      .cert-showcase-details-viewBtn-mobileOnly else {
        font-size: 16px; } }

.cert-showcase-courseDetails-bgBlur {
  background: #65DA7A;
  -webkit-filter: blur(130px);
          filter: blur(130px);
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 110px;
  height: 100%;
  display: none; }
  @media (min-width: 1300px) {
    .cert-showcase-courseDetails-bgBlur {
      display: block; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cert-showcase-courseDetails-bgBlur {
      display: block; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cert-showcase-courseDetails-bgBlur {
      display: block; } }

.cert-showcase-exploreTekieBtn-mobile {
  font-size: 4.34783vw;
  min-width: 60.38647vw;
  padding: 2.89855vw 4.34783vw;
  margin: 2.89855vw auto;
  letter-spacing: 0.12077vw;
  display: flex;
  width: 90%;
  z-index: 99;
  box-sizing: border-box;
  position: fixed;
  bottom: 10px;
  transition: all .2s;
  font-family: Nunito;
  text-decoration: none;
  font-weight: bold;
  color: #FFFFFF;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background: linear-gradient(270deg, #35E4E9 -8.62%, #00ADE6 109.2%);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  border-radius: 4px; }
  @media (min-width: 1300px) {
    .cert-showcase-exploreTekieBtn-mobile {
      display: none; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cert-showcase-exploreTekieBtn-mobile {
      display: none; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cert-showcase-exploreTekieBtn-mobile {
      display: none; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cert-showcase-exploreTekieBtn-mobile {
      font-size: 3.62319vw;
      min-width: 50.32206vw;
      padding: 2.41546vw 3.62319vw;
      margin: 2.41546vw auto;
      letter-spacing: 0.10064vw; }
      .cert-showcase-exploreTekieBtn-mobile else {
        font-size: 18px; }
      .cert-showcase-exploreTekieBtn-mobile else {
        min-width: 250px; }
      .cert-showcase-exploreTekieBtn-mobile else {
        letter-spacing: 0.5px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cert-showcase-exploreTekieBtn-mobile {
      font-size: 2.89855vw;
      min-width: 40.25765vw;
      padding: 1.93237vw 2.89855vw;
      margin: 1.93237vw auto;
      letter-spacing: 0.08052vw; }
      .cert-showcase-exploreTekieBtn-mobile else {
        font-size: 18px; }
      .cert-showcase-exploreTekieBtn-mobile else {
        min-width: 250px; }
      .cert-showcase-exploreTekieBtn-mobile else {
        letter-spacing: 0.5px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cert-showcase-exploreTekieBtn-mobile {
      font-size: 2.55754vw;
      min-width: 35.52145vw;
      padding: 1.70503vw 2.55754vw;
      margin: 1.70503vw auto;
      letter-spacing: 0.07104vw; }
      .cert-showcase-exploreTekieBtn-mobile else {
        font-size: 18px; }
      .cert-showcase-exploreTekieBtn-mobile else {
        min-width: 250px; }
      .cert-showcase-exploreTekieBtn-mobile else {
        letter-spacing: 0.5px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cert-showcase-exploreTekieBtn-mobile {
      font-size: 2.28833vw;
      min-width: 31.78235vw;
      padding: 1.52555vw 2.28833vw;
      margin: 1.52555vw auto;
      letter-spacing: 0.06356vw; }
      .cert-showcase-exploreTekieBtn-mobile else {
        font-size: 18px; }
      .cert-showcase-exploreTekieBtn-mobile else {
        min-width: 250px; }
      .cert-showcase-exploreTekieBtn-mobile else {
        letter-spacing: 0.5px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cert-showcase-exploreTekieBtn-mobile {
      font-size: 2.07039vw;
      min-width: 28.75546vw;
      padding: 1.38026vw 2.07039vw;
      margin: 1.38026vw auto;
      letter-spacing: 0.05751vw; }
      .cert-showcase-exploreTekieBtn-mobile else {
        font-size: 18px; }
      .cert-showcase-exploreTekieBtn-mobile else {
        min-width: 250px; }
      .cert-showcase-exploreTekieBtn-mobile else {
        letter-spacing: 0.5px; } }

.cert-showcase-externalIcon {
  background-image: url(/static/media/externalLinkIcon.3f34276b.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 4.44444vw;
  height: 4.44444vw;
  margin-left: 1.48148vw; }
  @media (min-width: 1300px) {
    .cert-showcase-externalIcon {
      width: 1.14583vw;
      height: 1.14583vw;
      margin-left: 0.3125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cert-showcase-externalIcon {
      width: 1.27315vw;
      height: 1.27315vw;
      margin-left: 0.34722vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cert-showcase-externalIcon {
      width: 1.14583vw;
      height: 1.14583vw;
      margin-left: 0.3125vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cert-showcase-externalIcon {
      width: 3.7037vw;
      height: 3.7037vw;
      margin-left: 1.23457vw; }
      .cert-showcase-externalIcon else {
        width: 24px; }
      .cert-showcase-externalIcon else {
        height: 24px; }
      .cert-showcase-externalIcon else {
        margin-left: 8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cert-showcase-externalIcon {
      width: 2.96296vw;
      height: 2.96296vw;
      margin-left: 0.98765vw; }
      .cert-showcase-externalIcon else {
        width: 24px; }
      .cert-showcase-externalIcon else {
        height: 24px; }
      .cert-showcase-externalIcon else {
        margin-left: 8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cert-showcase-externalIcon {
      width: 2.61438vw;
      height: 2.61438vw;
      margin-left: 0.87146vw; }
      .cert-showcase-externalIcon else {
        width: 24px; }
      .cert-showcase-externalIcon else {
        height: 24px; }
      .cert-showcase-externalIcon else {
        margin-left: 8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cert-showcase-externalIcon {
      width: 2.33918vw;
      height: 2.33918vw;
      margin-left: 0.77973vw; }
      .cert-showcase-externalIcon else {
        width: 24px; }
      .cert-showcase-externalIcon else {
        height: 24px; }
      .cert-showcase-externalIcon else {
        margin-left: 8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cert-showcase-externalIcon {
      width: 2.1164vw;
      height: 2.1164vw;
      margin-left: 0.70547vw; }
      .cert-showcase-externalIcon else {
        width: 24px; }
      .cert-showcase-externalIcon else {
        height: 24px; }
      .cert-showcase-externalIcon else {
        margin-left: 8px; } }

.cert-showcase-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  transition: all .3s;
  margin-top: 2.77778vw;
  width: 100%; }
  @media (min-width: 1300px) {
    .cert-showcase-buttons-container {
      margin-top: 0.78125vw;
      width: unset;
      position: static; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cert-showcase-buttons-container {
      margin-top: 0.86806vw;
      width: unset;
      position: static; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cert-showcase-buttons-container {
      margin-top: 0.78125vw;
      width: unset;
      position: static; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cert-showcase-buttons-container {
      margin-top: 2.31481vw; }
      .cert-showcase-buttons-container else {
        margin-top: 15px; }
      .cert-showcase-buttons-container else {
        width: 100%; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cert-showcase-buttons-container {
      margin-top: 1.85185vw; }
      .cert-showcase-buttons-container else {
        margin-top: 15px; }
      .cert-showcase-buttons-container else {
        width: 100%; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cert-showcase-buttons-container {
      margin-top: 1.63399vw; }
      .cert-showcase-buttons-container else {
        margin-top: 15px; }
      .cert-showcase-buttons-container else {
        width: 100%; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cert-showcase-buttons-container {
      margin-top: 1.46199vw; }
      .cert-showcase-buttons-container else {
        margin-top: 15px; }
      .cert-showcase-buttons-container else {
        width: 100%; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cert-showcase-buttons-container {
      margin-top: 1.32275vw; }
      .cert-showcase-buttons-container else {
        margin-top: 15px; }
      .cert-showcase-buttons-container else {
        width: 100%; } }
  .cert-showcase-buttons-container .cert-showcase-download-button {
    font-family: Nunito;
    font-weight: bold;
    letter-spacing: 0.03em;
    cursor: pointer;
    color: #FFFFFF;
    display: grid;
    place-items: center;
    text-decoration: none;
    background: conic-gradient(from -3.29deg at 100% -13%, #35E4E9 0deg, #00ADE6 360deg);
    border-radius: 40px;
    transition: all .3s;
    font-size: 2.89855vw;
    padding: 1.93237vw 2.89855vw;
    margin: 1.93237vw 0vw; }
    @media (min-width: 1300px) {
      .cert-showcase-buttons-container .cert-showcase-download-button {
        font-size: 0.9375vw;
        padding: 0.625vw 1.35417vw;
        margin: 0.625vw 0vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cert-showcase-buttons-container .cert-showcase-download-button {
        font-size: 1.04167vw;
        padding: 0.69444vw 1.50463vw;
        margin: 0.69444vw 0vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cert-showcase-buttons-container .cert-showcase-download-button {
        font-size: 0.9375vw;
        padding: 0.625vw 1.35417vw;
        margin: 0.625vw 0vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .cert-showcase-buttons-container .cert-showcase-download-button {
        font-size: 2.41546vw;
        padding: 1.61031vw 2.41546vw;
        margin: 1.61031vw 0vw; }
        .cert-showcase-buttons-container .cert-showcase-download-button else {
          font-size: 12px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .cert-showcase-buttons-container .cert-showcase-download-button {
        font-size: 1.93237vw;
        padding: 1.28824vw 1.93237vw;
        margin: 1.28824vw 0vw; }
        .cert-showcase-buttons-container .cert-showcase-download-button else {
          font-size: 12px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .cert-showcase-buttons-container .cert-showcase-download-button {
        font-size: 1.70503vw;
        padding: 1.13669vw 1.70503vw;
        margin: 1.13669vw 0vw; }
        .cert-showcase-buttons-container .cert-showcase-download-button else {
          font-size: 12px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .cert-showcase-buttons-container .cert-showcase-download-button {
        font-size: 1.52555vw;
        padding: 1.01704vw 1.52555vw;
        margin: 1.01704vw 0vw; }
        .cert-showcase-buttons-container .cert-showcase-download-button else {
          font-size: 12px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .cert-showcase-buttons-container .cert-showcase-download-button {
        font-size: 1.38026vw;
        padding: 0.92017vw 1.38026vw;
        margin: 0.92017vw 0vw; }
        .cert-showcase-buttons-container .cert-showcase-download-button else {
          font-size: 12px; } }
  .cert-showcase-buttons-container .cert-showcase-share-button {
    font-family: Nunito;
    font-weight: bold;
    cursor: pointer;
    letter-spacing: 0.03em;
    color: #00ADE6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 2.89855vw;
    padding: 1.93237vw 2.89855vw;
    margin: 1.93237vw 0vw;
    transition: all .3s;
    opacity: 0.8; }
    @media (min-width: 1300px) {
      .cert-showcase-buttons-container .cert-showcase-share-button {
        font-size: 0.9375vw;
        padding: 0.625vw 1.35417vw;
        margin: 0.625vw 0vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cert-showcase-buttons-container .cert-showcase-share-button {
        font-size: 1.04167vw;
        padding: 0.69444vw 1.50463vw;
        margin: 0.69444vw 0vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cert-showcase-buttons-container .cert-showcase-share-button {
        font-size: 0.9375vw;
        padding: 0.625vw 1.35417vw;
        margin: 0.625vw 0vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .cert-showcase-buttons-container .cert-showcase-share-button {
        font-size: 2.41546vw;
        padding: 1.61031vw 2.41546vw;
        margin: 1.61031vw 0vw; }
        .cert-showcase-buttons-container .cert-showcase-share-button else {
          font-size: 12px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .cert-showcase-buttons-container .cert-showcase-share-button {
        font-size: 1.93237vw;
        padding: 1.28824vw 1.93237vw;
        margin: 1.28824vw 0vw; }
        .cert-showcase-buttons-container .cert-showcase-share-button else {
          font-size: 12px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .cert-showcase-buttons-container .cert-showcase-share-button {
        font-size: 1.70503vw;
        padding: 1.13669vw 1.70503vw;
        margin: 1.13669vw 0vw; }
        .cert-showcase-buttons-container .cert-showcase-share-button else {
          font-size: 12px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .cert-showcase-buttons-container .cert-showcase-share-button {
        font-size: 1.52555vw;
        padding: 1.01704vw 1.52555vw;
        margin: 1.01704vw 0vw; }
        .cert-showcase-buttons-container .cert-showcase-share-button else {
          font-size: 12px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .cert-showcase-buttons-container .cert-showcase-share-button {
        font-size: 1.38026vw;
        padding: 0.92017vw 1.38026vw;
        margin: 0.92017vw 0vw; }
        .cert-showcase-buttons-container .cert-showcase-share-button else {
          font-size: 12px; } }
    .cert-showcase-buttons-container .cert-showcase-share-button:hover {
      opacity: 1; }
    .cert-showcase-buttons-container .cert-showcase-share-button > .cert-showcase-shareIcon {
      background-image: url(/static/media/shareIcon.52d5759c.svg);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 4.07407vw;
      height: 4.07407vw;
      margin-left: 0.74074vw; }
      @media (min-width: 1300px) {
        .cert-showcase-buttons-container .cert-showcase-share-button > .cert-showcase-shareIcon {
          width: 1.45833vw;
          height: 1.45833vw;
          margin-left: 0.3125vw; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .cert-showcase-buttons-container .cert-showcase-share-button > .cert-showcase-shareIcon {
          width: 1.62037vw;
          height: 1.62037vw;
          margin-left: 0.34722vw; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .cert-showcase-buttons-container .cert-showcase-share-button > .cert-showcase-shareIcon {
          width: 1.45833vw;
          height: 1.45833vw;
          margin-left: 0.3125vw; } }
      @media (min-width: 450px) and (max-width: 500px) {
        .cert-showcase-buttons-container .cert-showcase-share-button > .cert-showcase-shareIcon {
          width: 3.39506vw;
          height: 3.39506vw;
          margin-left: 0.61728vw; }
          .cert-showcase-buttons-container .cert-showcase-share-button > .cert-showcase-shareIcon else {
            width: 22px; }
          .cert-showcase-buttons-container .cert-showcase-share-button > .cert-showcase-shareIcon else {
            height: 22px; }
          .cert-showcase-buttons-container .cert-showcase-share-button > .cert-showcase-shareIcon else {
            margin-left: 4px; } }
      @media (min-width: 500px) and (max-width: 600px) {
        .cert-showcase-buttons-container .cert-showcase-share-button > .cert-showcase-shareIcon {
          width: 2.71605vw;
          height: 2.71605vw;
          margin-left: 0.49383vw; }
          .cert-showcase-buttons-container .cert-showcase-share-button > .cert-showcase-shareIcon else {
            width: 22px; }
          .cert-showcase-buttons-container .cert-showcase-share-button > .cert-showcase-shareIcon else {
            height: 22px; }
          .cert-showcase-buttons-container .cert-showcase-share-button > .cert-showcase-shareIcon else {
            margin-left: 4px; } }
      @media (min-width: 600px) and (max-width: 700px) {
        .cert-showcase-buttons-container .cert-showcase-share-button > .cert-showcase-shareIcon {
          width: 2.39651vw;
          height: 2.39651vw;
          margin-left: 0.43573vw; }
          .cert-showcase-buttons-container .cert-showcase-share-button > .cert-showcase-shareIcon else {
            width: 22px; }
          .cert-showcase-buttons-container .cert-showcase-share-button > .cert-showcase-shareIcon else {
            height: 22px; }
          .cert-showcase-buttons-container .cert-showcase-share-button > .cert-showcase-shareIcon else {
            margin-left: 4px; } }
      @media (min-width: 700px) and (max-width: 800px) {
        .cert-showcase-buttons-container .cert-showcase-share-button > .cert-showcase-shareIcon {
          width: 2.14425vw;
          height: 2.14425vw;
          margin-left: 0.38986vw; }
          .cert-showcase-buttons-container .cert-showcase-share-button > .cert-showcase-shareIcon else {
            width: 22px; }
          .cert-showcase-buttons-container .cert-showcase-share-button > .cert-showcase-shareIcon else {
            height: 22px; }
          .cert-showcase-buttons-container .cert-showcase-share-button > .cert-showcase-shareIcon else {
            margin-left: 4px; } }
      @media (min-width: 800px) and (max-width: 899px) {
        .cert-showcase-buttons-container .cert-showcase-share-button > .cert-showcase-shareIcon {
          width: 1.94004vw;
          height: 1.94004vw;
          margin-left: 0.35273vw; }
          .cert-showcase-buttons-container .cert-showcase-share-button > .cert-showcase-shareIcon else {
            width: 22px; }
          .cert-showcase-buttons-container .cert-showcase-share-button > .cert-showcase-shareIcon else {
            height: 22px; }
          .cert-showcase-buttons-container .cert-showcase-share-button > .cert-showcase-shareIcon else {
            margin-left: 4px; } }

.cert-showcase-loader {
  border: 6px solid #FFF;
  border-radius: 50%;
  border-top: 2px solid #00ADE6;
  width: 32px;
  height: 32px;
  display: flex;
  margin-top: 32px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite; }

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.cert-showcase-checkmark-icon {
  background-color: #01AA93;
  -webkit-mask-image: url(/static/media/checkmark-outline.6013ad3b.svg);
  mask-image: url(/static/media/checkmark-outline.6013ad3b.svg);
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-position: center;
          mask-position: center;
  width: 7.24638vw;
  height: 7.24638vw;
  margin-right: 0.96618vw; }
  @media (min-width: 1300px) {
    .cert-showcase-checkmark-icon {
      width: 2.1875vw;
      height: 2.1875vw;
      margin-right: 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cert-showcase-checkmark-icon {
      width: 2.43056vw;
      height: 2.43056vw;
      margin-right: 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cert-showcase-checkmark-icon {
      width: 2.1875vw;
      height: 2.1875vw;
      margin-right: 0.41667vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cert-showcase-checkmark-icon {
      width: 6.03865vw;
      height: 6.03865vw;
      margin-right: 0.80515vw; }
      .cert-showcase-checkmark-icon else {
        width: 30px; }
      .cert-showcase-checkmark-icon else {
        height: 30px; }
      .cert-showcase-checkmark-icon else {
        margin-right: 4px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cert-showcase-checkmark-icon {
      width: 4.83092vw;
      height: 4.83092vw;
      margin-right: 0.64412vw; }
      .cert-showcase-checkmark-icon else {
        width: 30px; }
      .cert-showcase-checkmark-icon else {
        height: 30px; }
      .cert-showcase-checkmark-icon else {
        margin-right: 4px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cert-showcase-checkmark-icon {
      width: 4.26257vw;
      height: 4.26257vw;
      margin-right: 0.56834vw; }
      .cert-showcase-checkmark-icon else {
        width: 30px; }
      .cert-showcase-checkmark-icon else {
        height: 30px; }
      .cert-showcase-checkmark-icon else {
        margin-right: 4px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cert-showcase-checkmark-icon {
      width: 3.81388vw;
      height: 3.81388vw;
      margin-right: 0.50852vw; }
      .cert-showcase-checkmark-icon else {
        width: 30px; }
      .cert-showcase-checkmark-icon else {
        height: 30px; }
      .cert-showcase-checkmark-icon else {
        margin-right: 4px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cert-showcase-checkmark-icon {
      width: 3.45066vw;
      height: 3.45066vw;
      margin-right: 0.46009vw; }
      .cert-showcase-checkmark-icon else {
        width: 30px; }
      .cert-showcase-checkmark-icon else {
        height: 30px; }
      .cert-showcase-checkmark-icon else {
        margin-right: 4px; } }

.cert-showcase-desktopOnly {
  display: none; }
  @media (min-width: 1300px) {
    .cert-showcase-desktopOnly {
      display: block; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cert-showcase-desktopOnly {
      display: block; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cert-showcase-desktopOnly {
      display: block; } }

.cert-showcase-mobileOnly {
  display: block; }
  @media (min-width: 1300px) {
    .cert-showcase-mobileOnly {
      display: none; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cert-showcase-mobileOnly {
      display: none; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cert-showcase-mobileOnly {
      display: none; } }

.cert-showcase-footer-container {
  background-color: rgba(220, 220, 220, 0.3);
  padding: 18px 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  margin-top: 2.89855vw;
  flex-direction: column;
  justify-content: center; }
  @media (min-width: 1300px) {
    .cert-showcase-footer-container {
      margin-top: 0vw;
      flex-direction: row;
      justify-content: space-between; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cert-showcase-footer-container {
      margin-top: 0vw;
      flex-direction: row;
      justify-content: space-between; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cert-showcase-footer-container {
      margin-top: 0vw;
      flex-direction: row;
      justify-content: space-between; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cert-showcase-footer-container {
      margin-top: 2.41546vw; }
      .cert-showcase-footer-container else {
        margin-top: 12px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cert-showcase-footer-container {
      margin-top: 1.93237vw; }
      .cert-showcase-footer-container else {
        margin-top: 12px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cert-showcase-footer-container {
      margin-top: 1.70503vw; }
      .cert-showcase-footer-container else {
        margin-top: 12px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cert-showcase-footer-container {
      margin-top: 1.52555vw; }
      .cert-showcase-footer-container else {
        margin-top: 12px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cert-showcase-footer-container {
      margin-top: 1.38026vw; }
      .cert-showcase-footer-container else {
        margin-top: 12px; } }
  .cert-showcase-footer-container .cert-showcase-footer-leftContainer, .cert-showcase-footer-container .cert-showcase-footer-rightContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    font-size: 3.38164vw;
    flex-wrap: wrap; }
    @media (min-width: 450px) and (max-width: 500px) {
      .cert-showcase-footer-container .cert-showcase-footer-leftContainer, .cert-showcase-footer-container .cert-showcase-footer-rightContainer {
        font-size: 2.81804vw; }
        .cert-showcase-footer-container .cert-showcase-footer-leftContainer else, .cert-showcase-footer-container .cert-showcase-footer-rightContainer else {
          font-size: 14px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .cert-showcase-footer-container .cert-showcase-footer-leftContainer, .cert-showcase-footer-container .cert-showcase-footer-rightContainer {
        font-size: 2.25443vw; }
        .cert-showcase-footer-container .cert-showcase-footer-leftContainer else, .cert-showcase-footer-container .cert-showcase-footer-rightContainer else {
          font-size: 14px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .cert-showcase-footer-container .cert-showcase-footer-leftContainer, .cert-showcase-footer-container .cert-showcase-footer-rightContainer {
        font-size: 1.9892vw; }
        .cert-showcase-footer-container .cert-showcase-footer-leftContainer else, .cert-showcase-footer-container .cert-showcase-footer-rightContainer else {
          font-size: 14px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .cert-showcase-footer-container .cert-showcase-footer-leftContainer, .cert-showcase-footer-container .cert-showcase-footer-rightContainer {
        font-size: 1.77981vw; }
        .cert-showcase-footer-container .cert-showcase-footer-leftContainer else, .cert-showcase-footer-container .cert-showcase-footer-rightContainer else {
          font-size: 14px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .cert-showcase-footer-container .cert-showcase-footer-leftContainer, .cert-showcase-footer-container .cert-showcase-footer-rightContainer {
        font-size: 1.61031vw; }
        .cert-showcase-footer-container .cert-showcase-footer-leftContainer else, .cert-showcase-footer-container .cert-showcase-footer-rightContainer else {
          font-size: 14px; } }
    @media (min-width: 1300px) {
      .cert-showcase-footer-container .cert-showcase-footer-leftContainer, .cert-showcase-footer-container .cert-showcase-footer-rightContainer {
        font-size: 0.83333vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cert-showcase-footer-container .cert-showcase-footer-leftContainer, .cert-showcase-footer-container .cert-showcase-footer-rightContainer {
        font-size: 0.92593vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cert-showcase-footer-container .cert-showcase-footer-leftContainer, .cert-showcase-footer-container .cert-showcase-footer-rightContainer {
        font-size: 0.83333vw; } }
    .cert-showcase-footer-container .cert-showcase-footer-leftContainer .cert-showcase-footer-TekieLogo, .cert-showcase-footer-container .cert-showcase-footer-rightContainer .cert-showcase-footer-TekieLogo {
      width: 6.76329vw;
      height: 6.76329vw;
      margin: 0vw 1.44928vw;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center; }
      @media (min-width: 450px) and (max-width: 500px) {
        .cert-showcase-footer-container .cert-showcase-footer-leftContainer .cert-showcase-footer-TekieLogo, .cert-showcase-footer-container .cert-showcase-footer-rightContainer .cert-showcase-footer-TekieLogo {
          width: 5.63607vw;
          height: 5.63607vw;
          margin: 0vw 1.20773vw; }
          .cert-showcase-footer-container .cert-showcase-footer-leftContainer .cert-showcase-footer-TekieLogo else, .cert-showcase-footer-container .cert-showcase-footer-rightContainer .cert-showcase-footer-TekieLogo else {
            width: 28px; }
          .cert-showcase-footer-container .cert-showcase-footer-leftContainer .cert-showcase-footer-TekieLogo else, .cert-showcase-footer-container .cert-showcase-footer-rightContainer .cert-showcase-footer-TekieLogo else {
            height: 28px; } }
      @media (min-width: 500px) and (max-width: 600px) {
        .cert-showcase-footer-container .cert-showcase-footer-leftContainer .cert-showcase-footer-TekieLogo, .cert-showcase-footer-container .cert-showcase-footer-rightContainer .cert-showcase-footer-TekieLogo {
          width: 4.50886vw;
          height: 4.50886vw;
          margin: 0vw 0.96618vw; }
          .cert-showcase-footer-container .cert-showcase-footer-leftContainer .cert-showcase-footer-TekieLogo else, .cert-showcase-footer-container .cert-showcase-footer-rightContainer .cert-showcase-footer-TekieLogo else {
            width: 28px; }
          .cert-showcase-footer-container .cert-showcase-footer-leftContainer .cert-showcase-footer-TekieLogo else, .cert-showcase-footer-container .cert-showcase-footer-rightContainer .cert-showcase-footer-TekieLogo else {
            height: 28px; } }
      @media (min-width: 600px) and (max-width: 700px) {
        .cert-showcase-footer-container .cert-showcase-footer-leftContainer .cert-showcase-footer-TekieLogo, .cert-showcase-footer-container .cert-showcase-footer-rightContainer .cert-showcase-footer-TekieLogo {
          width: 3.9784vw;
          height: 3.9784vw;
          margin: 0vw 0.85251vw; }
          .cert-showcase-footer-container .cert-showcase-footer-leftContainer .cert-showcase-footer-TekieLogo else, .cert-showcase-footer-container .cert-showcase-footer-rightContainer .cert-showcase-footer-TekieLogo else {
            width: 28px; }
          .cert-showcase-footer-container .cert-showcase-footer-leftContainer .cert-showcase-footer-TekieLogo else, .cert-showcase-footer-container .cert-showcase-footer-rightContainer .cert-showcase-footer-TekieLogo else {
            height: 28px; } }
      @media (min-width: 700px) and (max-width: 800px) {
        .cert-showcase-footer-container .cert-showcase-footer-leftContainer .cert-showcase-footer-TekieLogo, .cert-showcase-footer-container .cert-showcase-footer-rightContainer .cert-showcase-footer-TekieLogo {
          width: 3.55962vw;
          height: 3.55962vw;
          margin: 0vw 0.76278vw; }
          .cert-showcase-footer-container .cert-showcase-footer-leftContainer .cert-showcase-footer-TekieLogo else, .cert-showcase-footer-container .cert-showcase-footer-rightContainer .cert-showcase-footer-TekieLogo else {
            width: 28px; }
          .cert-showcase-footer-container .cert-showcase-footer-leftContainer .cert-showcase-footer-TekieLogo else, .cert-showcase-footer-container .cert-showcase-footer-rightContainer .cert-showcase-footer-TekieLogo else {
            height: 28px; } }
      @media (min-width: 800px) and (max-width: 899px) {
        .cert-showcase-footer-container .cert-showcase-footer-leftContainer .cert-showcase-footer-TekieLogo, .cert-showcase-footer-container .cert-showcase-footer-rightContainer .cert-showcase-footer-TekieLogo {
          width: 3.22061vw;
          height: 3.22061vw;
          margin: 0vw 0.69013vw; }
          .cert-showcase-footer-container .cert-showcase-footer-leftContainer .cert-showcase-footer-TekieLogo else, .cert-showcase-footer-container .cert-showcase-footer-rightContainer .cert-showcase-footer-TekieLogo else {
            width: 28px; }
          .cert-showcase-footer-container .cert-showcase-footer-leftContainer .cert-showcase-footer-TekieLogo else, .cert-showcase-footer-container .cert-showcase-footer-rightContainer .cert-showcase-footer-TekieLogo else {
            height: 28px; } }
      @media (min-width: 1300px) {
        .cert-showcase-footer-container .cert-showcase-footer-leftContainer .cert-showcase-footer-TekieLogo, .cert-showcase-footer-container .cert-showcase-footer-rightContainer .cert-showcase-footer-TekieLogo {
          width: 2.08333vw;
          height: 2.08333vw;
          margin: 0vw 0.625vw; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .cert-showcase-footer-container .cert-showcase-footer-leftContainer .cert-showcase-footer-TekieLogo, .cert-showcase-footer-container .cert-showcase-footer-rightContainer .cert-showcase-footer-TekieLogo {
          width: 2.31481vw;
          height: 2.31481vw;
          margin: 0vw 0.69444vw; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .cert-showcase-footer-container .cert-showcase-footer-leftContainer .cert-showcase-footer-TekieLogo, .cert-showcase-footer-container .cert-showcase-footer-rightContainer .cert-showcase-footer-TekieLogo {
          width: 2.08333vw;
          height: 2.08333vw;
          margin: 0vw 0.625vw; } }
    .cert-showcase-footer-container .cert-showcase-footer-leftContainer span, .cert-showcase-footer-container .cert-showcase-footer-rightContainer span {
      font-family: Nunito;
      font-weight: normal;
      color: #504F4F;
      opacity: 0.7;
      padding-top: 1.93237vw; }
      @media (min-width: 1300px) {
        .cert-showcase-footer-container .cert-showcase-footer-leftContainer span, .cert-showcase-footer-container .cert-showcase-footer-rightContainer span {
          padding: 0; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .cert-showcase-footer-container .cert-showcase-footer-leftContainer span, .cert-showcase-footer-container .cert-showcase-footer-rightContainer span {
          padding: 0; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .cert-showcase-footer-container .cert-showcase-footer-leftContainer span, .cert-showcase-footer-container .cert-showcase-footer-rightContainer span {
          padding: 0; } }
      @media (min-width: 450px) and (max-width: 500px) {
        .cert-showcase-footer-container .cert-showcase-footer-leftContainer span, .cert-showcase-footer-container .cert-showcase-footer-rightContainer span {
          padding-top: 1.61031vw; }
          .cert-showcase-footer-container .cert-showcase-footer-leftContainer span else, .cert-showcase-footer-container .cert-showcase-footer-rightContainer span else {
            padding-top: 8px; } }
      @media (min-width: 500px) and (max-width: 600px) {
        .cert-showcase-footer-container .cert-showcase-footer-leftContainer span, .cert-showcase-footer-container .cert-showcase-footer-rightContainer span {
          padding-top: 1.28824vw; }
          .cert-showcase-footer-container .cert-showcase-footer-leftContainer span else, .cert-showcase-footer-container .cert-showcase-footer-rightContainer span else {
            padding-top: 8px; } }
      @media (min-width: 600px) and (max-width: 700px) {
        .cert-showcase-footer-container .cert-showcase-footer-leftContainer span, .cert-showcase-footer-container .cert-showcase-footer-rightContainer span {
          padding-top: 1.13669vw; }
          .cert-showcase-footer-container .cert-showcase-footer-leftContainer span else, .cert-showcase-footer-container .cert-showcase-footer-rightContainer span else {
            padding-top: 8px; } }
      @media (min-width: 700px) and (max-width: 800px) {
        .cert-showcase-footer-container .cert-showcase-footer-leftContainer span, .cert-showcase-footer-container .cert-showcase-footer-rightContainer span {
          padding-top: 1.01704vw; }
          .cert-showcase-footer-container .cert-showcase-footer-leftContainer span else, .cert-showcase-footer-container .cert-showcase-footer-rightContainer span else {
            padding-top: 8px; } }
      @media (min-width: 800px) and (max-width: 899px) {
        .cert-showcase-footer-container .cert-showcase-footer-leftContainer span, .cert-showcase-footer-container .cert-showcase-footer-rightContainer span {
          padding-top: 0.92017vw; }
          .cert-showcase-footer-container .cert-showcase-footer-leftContainer span else, .cert-showcase-footer-container .cert-showcase-footer-rightContainer span else {
            padding-top: 8px; } }
  .cert-showcase-footer-container .cert-showcase-footer-privacyAndTerms {
    box-sizing: border-box;
    text-decoration: none;
    padding: 0px 12px;
    display: block; }
    .cert-showcase-footer-container .cert-showcase-footer-privacyAndTerms:hover {
      text-decoration: underline; }
    .cert-showcase-footer-container .cert-showcase-footer-privacyAndTerms:nth-child(1) {
      border-right: 1px solid rgba(80, 79, 79, 0.5); }

.cert-showcase-Arrowicon {
  width: 16px;
  height: 16px;
  margin: 0px 4px;
  background-color: #FFFFFF;
  -webkit-mask-image: url(/static/media/dropdownArrow.9dab8a2f.svg);
  mask-image: url(/static/media/dropdownArrow.9dab8a2f.svg);
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg); }

.cert-showcase-courseSnapshot-tag {
  background: #8C61CB;
  border-radius: 24px;
  text-transform: uppercase;
  font-weight: bold;
  color: #FFFFFF;
  padding: 1.44928vw 7.72947vw;
  font-size: 3.38164vw !important; }
  @media (min-width: 1300px) {
    .cert-showcase-courseSnapshot-tag {
      padding: 0.3125vw 1.66667vw;
      font-size: 0.83333vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cert-showcase-courseSnapshot-tag {
      padding: 0.34722vw 1.85185vw;
      font-size: 0.92593vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cert-showcase-courseSnapshot-tag {
      padding: 0.3125vw 1.66667vw;
      font-size: 0.83333vw !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cert-showcase-courseSnapshot-tag {
      padding: 1.20773vw 6.44122vw;
      font-size: 2.81804vw !important; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cert-showcase-courseSnapshot-tag {
      padding: 0.96618vw 5.15298vw;
      font-size: 2.25443vw !important; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cert-showcase-courseSnapshot-tag {
      padding: 0.85251vw 4.54675vw;
      font-size: 1.9892vw !important; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cert-showcase-courseSnapshot-tag {
      padding: 0.76278vw 4.06814vw;
      font-size: 1.77981vw !important; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cert-showcase-courseSnapshot-tag {
      padding: 0.69013vw 3.6807vw;
      font-size: 1.61031vw !important; } }

.cert-showcase-bookBtn {
  margin-top: 6.76329vw !important; }
  @media (min-width: 1300px) {
    .cert-showcase-bookBtn {
      margin-top: 2.08333vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cert-showcase-bookBtn {
      margin-top: 2.31481vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cert-showcase-bookBtn {
      margin-top: 2.08333vw !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cert-showcase-bookBtn {
      margin-top: 5.63607vw !important; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cert-showcase-bookBtn {
      margin-top: 4.50886vw !important; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cert-showcase-bookBtn {
      margin-top: 3.9784vw !important; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cert-showcase-bookBtn {
      margin-top: 3.55962vw !important; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cert-showcase-bookBtn {
      margin-top: 3.22061vw !important; } }

.cert-showcase-header-login-btn {
  display: flex;
  padding: 2.41546vw;
  letter-spacing: 0.18116vw;
  font-size: 3.86473vw;
  display: none;
  border-radius: 100px;
  background-color: transparent;
  justify-content: center;
  text-transform: unset;
  align-items: center;
  text-align: left;
  font-weight: 500;
  border: 1.5px solid #00ADE5;
  color: #00ADE5;
  opacity: 0.8;
  cursor: pointer;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
  @media (min-width: 450px) and (max-width: 500px) {
    .cert-showcase-header-login-btn {
      padding: 2.01288vw;
      letter-spacing: 0.15097vw;
      font-size: 3.22061vw; }
      .cert-showcase-header-login-btn else {
        padding: 10px; }
      .cert-showcase-header-login-btn else {
        letter-spacing: 0.75px; }
      .cert-showcase-header-login-btn else {
        font-size: 16px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cert-showcase-header-login-btn {
      padding: 1.61031vw;
      letter-spacing: 0.12077vw;
      font-size: 2.57649vw; }
      .cert-showcase-header-login-btn else {
        padding: 10px; }
      .cert-showcase-header-login-btn else {
        letter-spacing: 0.75px; }
      .cert-showcase-header-login-btn else {
        font-size: 16px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cert-showcase-header-login-btn {
      padding: 1.42086vw;
      letter-spacing: 0.10656vw;
      font-size: 2.27337vw; }
      .cert-showcase-header-login-btn else {
        padding: 10px; }
      .cert-showcase-header-login-btn else {
        letter-spacing: 0.75px; }
      .cert-showcase-header-login-btn else {
        font-size: 16px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cert-showcase-header-login-btn {
      padding: 1.27129vw;
      letter-spacing: 0.09535vw;
      font-size: 2.03407vw; }
      .cert-showcase-header-login-btn else {
        padding: 10px; }
      .cert-showcase-header-login-btn else {
        letter-spacing: 0.75px; }
      .cert-showcase-header-login-btn else {
        font-size: 16px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cert-showcase-header-login-btn {
      padding: 1.15022vw;
      letter-spacing: 0.08627vw;
      font-size: 1.84035vw; }
      .cert-showcase-header-login-btn else {
        padding: 10px; }
      .cert-showcase-header-login-btn else {
        letter-spacing: 0.75px; }
      .cert-showcase-header-login-btn else {
        font-size: 16px; } }
  @media (min-width: 1300px) {
    .cert-showcase-header-login-btn {
      padding: 0.72917vw 1.45833vw;
      font-size: 1.25vw;
      letter-spacing: 0.02604vw;
      display: flex; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cert-showcase-header-login-btn {
      padding: 0.81019vw 1.62037vw;
      font-size: 1.38889vw;
      letter-spacing: 0.02894vw;
      display: flex; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cert-showcase-header-login-btn {
      padding: 0.72917vw 1.45833vw;
      font-size: 1.25vw;
      letter-spacing: 0.02604vw;
      display: flex; } }
  .cert-showcase-header-login-btn:hover {
    opacity: 1;
    box-shadow: 0px 5px 8px 0 rgba(0, 0, 0, 0.16); }

.cert-showcase-component-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  transition: all .3s;
  margin-top: 2.77778vw;
  width: 100%; }
  @media (min-width: 1300px) {
    .cert-showcase-component-buttons-container {
      margin-top: 0.625vw;
      width: unset;
      position: static; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cert-showcase-component-buttons-container {
      margin-top: 0.69444vw;
      width: unset;
      position: static; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cert-showcase-component-buttons-container {
      margin-top: 0.625vw;
      width: unset;
      position: static; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cert-showcase-component-buttons-container {
      margin-top: 2.31481vw; }
      .cert-showcase-component-buttons-container else {
        margin-top: 15px; }
      .cert-showcase-component-buttons-container else {
        width: 100%; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cert-showcase-component-buttons-container {
      margin-top: 1.85185vw; }
      .cert-showcase-component-buttons-container else {
        margin-top: 15px; }
      .cert-showcase-component-buttons-container else {
        width: 100%; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cert-showcase-component-buttons-container {
      margin-top: 1.63399vw; }
      .cert-showcase-component-buttons-container else {
        margin-top: 15px; }
      .cert-showcase-component-buttons-container else {
        width: 100%; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cert-showcase-component-buttons-container {
      margin-top: 1.46199vw; }
      .cert-showcase-component-buttons-container else {
        margin-top: 15px; }
      .cert-showcase-component-buttons-container else {
        width: 100%; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cert-showcase-component-buttons-container {
      margin-top: 1.32275vw; }
      .cert-showcase-component-buttons-container else {
        margin-top: 15px; }
      .cert-showcase-component-buttons-container else {
        width: 100%; } }
  .cert-showcase-component-buttons-container .cert-showcase-component-download-button {
    font-family: Nunito;
    font-weight: bold;
    -webkit-user-select: none;
            user-select: none;
    letter-spacing: 0.03em;
    cursor: pointer;
    color: #FFFFFF;
    display: grid;
    place-items: center;
    text-decoration: none;
    background: conic-gradient(from -3.29deg at 100% -13%, #35E4E9 0deg, #00ADE6 360deg);
    border-radius: 40px;
    transition: all .3s;
    font-size: 2.89855vw;
    padding: 1.93237vw 2.89855vw;
    margin: 1.93237vw 0vw; }
    @media (min-width: 1300px) {
      .cert-showcase-component-buttons-container .cert-showcase-component-download-button {
        font-size: 0.9375vw;
        padding: 0.625vw 1.35417vw;
        margin: 0.625vw 0vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cert-showcase-component-buttons-container .cert-showcase-component-download-button {
        font-size: 1.04167vw;
        padding: 0.69444vw 1.50463vw;
        margin: 0.69444vw 0vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cert-showcase-component-buttons-container .cert-showcase-component-download-button {
        font-size: 0.9375vw;
        padding: 0.625vw 1.35417vw;
        margin: 0.625vw 0vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .cert-showcase-component-buttons-container .cert-showcase-component-download-button {
        font-size: 2.41546vw;
        padding: 1.61031vw 2.41546vw;
        margin: 1.61031vw 0vw; }
        .cert-showcase-component-buttons-container .cert-showcase-component-download-button else {
          font-size: 12px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .cert-showcase-component-buttons-container .cert-showcase-component-download-button {
        font-size: 1.93237vw;
        padding: 1.28824vw 1.93237vw;
        margin: 1.28824vw 0vw; }
        .cert-showcase-component-buttons-container .cert-showcase-component-download-button else {
          font-size: 12px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .cert-showcase-component-buttons-container .cert-showcase-component-download-button {
        font-size: 1.70503vw;
        padding: 1.13669vw 1.70503vw;
        margin: 1.13669vw 0vw; }
        .cert-showcase-component-buttons-container .cert-showcase-component-download-button else {
          font-size: 12px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .cert-showcase-component-buttons-container .cert-showcase-component-download-button {
        font-size: 1.52555vw;
        padding: 1.01704vw 1.52555vw;
        margin: 1.01704vw 0vw; }
        .cert-showcase-component-buttons-container .cert-showcase-component-download-button else {
          font-size: 12px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .cert-showcase-component-buttons-container .cert-showcase-component-download-button {
        font-size: 1.38026vw;
        padding: 0.92017vw 1.38026vw;
        margin: 0.92017vw 0vw; }
        .cert-showcase-component-buttons-container .cert-showcase-component-download-button else {
          font-size: 12px; } }
  .cert-showcase-component-buttons-container .cert-showcase-component-share-button {
    font-family: Nunito;
    font-weight: bold;
    cursor: pointer;
    letter-spacing: 0.03em;
    color: #00ADE6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 2.89855vw;
    padding: 1.93237vw 2.89855vw;
    margin: 1.93237vw 0vw;
    transition: all .3s;
    opacity: 0.8; }
    @media (min-width: 1300px) {
      .cert-showcase-component-buttons-container .cert-showcase-component-share-button {
        font-size: 0.9375vw;
        padding: 0.625vw 1.35417vw;
        margin: 0.625vw 0vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cert-showcase-component-buttons-container .cert-showcase-component-share-button {
        font-size: 1.04167vw;
        padding: 0.69444vw 1.50463vw;
        margin: 0.69444vw 0vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cert-showcase-component-buttons-container .cert-showcase-component-share-button {
        font-size: 0.9375vw;
        padding: 0.625vw 1.35417vw;
        margin: 0.625vw 0vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .cert-showcase-component-buttons-container .cert-showcase-component-share-button {
        font-size: 2.41546vw;
        padding: 1.61031vw 2.41546vw;
        margin: 1.61031vw 0vw; }
        .cert-showcase-component-buttons-container .cert-showcase-component-share-button else {
          font-size: 12px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .cert-showcase-component-buttons-container .cert-showcase-component-share-button {
        font-size: 1.93237vw;
        padding: 1.28824vw 1.93237vw;
        margin: 1.28824vw 0vw; }
        .cert-showcase-component-buttons-container .cert-showcase-component-share-button else {
          font-size: 12px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .cert-showcase-component-buttons-container .cert-showcase-component-share-button {
        font-size: 1.70503vw;
        padding: 1.13669vw 1.70503vw;
        margin: 1.13669vw 0vw; }
        .cert-showcase-component-buttons-container .cert-showcase-component-share-button else {
          font-size: 12px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .cert-showcase-component-buttons-container .cert-showcase-component-share-button {
        font-size: 1.52555vw;
        padding: 1.01704vw 1.52555vw;
        margin: 1.01704vw 0vw; }
        .cert-showcase-component-buttons-container .cert-showcase-component-share-button else {
          font-size: 12px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .cert-showcase-component-buttons-container .cert-showcase-component-share-button {
        font-size: 1.38026vw;
        padding: 0.92017vw 1.38026vw;
        margin: 0.92017vw 0vw; }
        .cert-showcase-component-buttons-container .cert-showcase-component-share-button else {
          font-size: 12px; } }
    .cert-showcase-component-buttons-container .cert-showcase-component-share-button:hover {
      opacity: 1; }
    .cert-showcase-component-buttons-container .cert-showcase-component-share-button > .cert-showcase-component-shareIcon {
      background-image: url(/static/media/shareIcon.52d5759c.svg);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 4.07407vw;
      height: 4.07407vw;
      margin-left: 0.74074vw; }
      @media (min-width: 1300px) {
        .cert-showcase-component-buttons-container .cert-showcase-component-share-button > .cert-showcase-component-shareIcon {
          width: 1.45833vw;
          height: 1.45833vw;
          margin-left: 0.3125vw; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .cert-showcase-component-buttons-container .cert-showcase-component-share-button > .cert-showcase-component-shareIcon {
          width: 1.62037vw;
          height: 1.62037vw;
          margin-left: 0.34722vw; } }
      @media (min-width: 1000px) and (max-width: 1300px) {
        .cert-showcase-component-buttons-container .cert-showcase-component-share-button > .cert-showcase-component-shareIcon {
          width: 1.45833vw;
          height: 1.45833vw;
          margin-left: 0.3125vw; } }
      @media (min-width: 450px) and (max-width: 500px) {
        .cert-showcase-component-buttons-container .cert-showcase-component-share-button > .cert-showcase-component-shareIcon {
          width: 3.39506vw;
          height: 3.39506vw;
          margin-left: 0.61728vw; }
          .cert-showcase-component-buttons-container .cert-showcase-component-share-button > .cert-showcase-component-shareIcon else {
            width: 22px; }
          .cert-showcase-component-buttons-container .cert-showcase-component-share-button > .cert-showcase-component-shareIcon else {
            height: 22px; }
          .cert-showcase-component-buttons-container .cert-showcase-component-share-button > .cert-showcase-component-shareIcon else {
            margin-left: 4px; } }
      @media (min-width: 500px) and (max-width: 600px) {
        .cert-showcase-component-buttons-container .cert-showcase-component-share-button > .cert-showcase-component-shareIcon {
          width: 2.71605vw;
          height: 2.71605vw;
          margin-left: 0.49383vw; }
          .cert-showcase-component-buttons-container .cert-showcase-component-share-button > .cert-showcase-component-shareIcon else {
            width: 22px; }
          .cert-showcase-component-buttons-container .cert-showcase-component-share-button > .cert-showcase-component-shareIcon else {
            height: 22px; }
          .cert-showcase-component-buttons-container .cert-showcase-component-share-button > .cert-showcase-component-shareIcon else {
            margin-left: 4px; } }
      @media (min-width: 600px) and (max-width: 700px) {
        .cert-showcase-component-buttons-container .cert-showcase-component-share-button > .cert-showcase-component-shareIcon {
          width: 2.39651vw;
          height: 2.39651vw;
          margin-left: 0.43573vw; }
          .cert-showcase-component-buttons-container .cert-showcase-component-share-button > .cert-showcase-component-shareIcon else {
            width: 22px; }
          .cert-showcase-component-buttons-container .cert-showcase-component-share-button > .cert-showcase-component-shareIcon else {
            height: 22px; }
          .cert-showcase-component-buttons-container .cert-showcase-component-share-button > .cert-showcase-component-shareIcon else {
            margin-left: 4px; } }
      @media (min-width: 700px) and (max-width: 800px) {
        .cert-showcase-component-buttons-container .cert-showcase-component-share-button > .cert-showcase-component-shareIcon {
          width: 2.14425vw;
          height: 2.14425vw;
          margin-left: 0.38986vw; }
          .cert-showcase-component-buttons-container .cert-showcase-component-share-button > .cert-showcase-component-shareIcon else {
            width: 22px; }
          .cert-showcase-component-buttons-container .cert-showcase-component-share-button > .cert-showcase-component-shareIcon else {
            height: 22px; }
          .cert-showcase-component-buttons-container .cert-showcase-component-share-button > .cert-showcase-component-shareIcon else {
            margin-left: 4px; } }
      @media (min-width: 800px) and (max-width: 899px) {
        .cert-showcase-component-buttons-container .cert-showcase-component-share-button > .cert-showcase-component-shareIcon {
          width: 1.94004vw;
          height: 1.94004vw;
          margin-left: 0.35273vw; }
          .cert-showcase-component-buttons-container .cert-showcase-component-share-button > .cert-showcase-component-shareIcon else {
            width: 22px; }
          .cert-showcase-component-buttons-container .cert-showcase-component-share-button > .cert-showcase-component-shareIcon else {
            height: 22px; }
          .cert-showcase-component-buttons-container .cert-showcase-component-share-button > .cert-showcase-component-shareIcon else {
            margin-left: 4px; } }

.cert-showcase-btnDisabled {
  cursor: no-drop !important; }
  .cert-showcase-btnDisabled > .cert-showcase-component-download-button {
    background: #bebebe !important;
    pointer-events: none; }
  .cert-showcase-btnDisabled.cert-showcase-component-share-button {
    color: #bebebe !important;
    -webkit-user-select: none;
            user-select: none; }
    .cert-showcase-btnDisabled.cert-showcase-component-share-button > .cert-showcase-component-shareIcon {
      opacity: 0; }

@font-face {
  font-family: Monaco;
  src: url(/static/media/monaco.5152b5a6.ttf); }

.cheatsheet-contentArea {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  padding: 25px 20px 0 20px;
  box-sizing: border-box; }

.cheatsheet-text {
  font-weight: 500;
  background-color: white;
  padding-left: 0;
  align-self: flex-start;
  max-width: 100%;
  color: #222222;
  line-height: 1.4;
  font-family: Nunito;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin: 2.89855vw 0;
  font-size: 3.86473vw; }
  @media (min-width: 1300px) {
    .cheatsheet-text {
      font-size: 1.04167vw;
      margin: 0.83333vw 0; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cheatsheet-text {
      font-size: 1.15741vw;
      margin: 0.92593vw 0; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cheatsheet-text {
      font-size: 1.04167vw;
      margin: 0.83333vw 0; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cheatsheet-text {
      margin: 2.41546vw 0;
      font-size: 3.22061vw; }
      .cheatsheet-text else {
        font-size: 16px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cheatsheet-text {
      margin: 1.93237vw 0;
      font-size: 2.57649vw; }
      .cheatsheet-text else {
        font-size: 16px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cheatsheet-text {
      margin: 1.70503vw 0;
      font-size: 2.27337vw; }
      .cheatsheet-text else {
        font-size: 16px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cheatsheet-text {
      margin: 1.52555vw 0;
      font-size: 2.03407vw; }
      .cheatsheet-text else {
        font-size: 16px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cheatsheet-text {
      margin: 1.38026vw 0;
      font-size: 1.84035vw; }
      .cheatsheet-text else {
        font-size: 16px; } }

.cheatsheet-message {
  margin-bottom: 3.86473vw;
  align-self: flex-start;
  width: 100%; }
  @media (min-width: 1300px) {
    .cheatsheet-message {
      margin-bottom: 2.08333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cheatsheet-message {
      margin-bottom: 2.31481vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cheatsheet-message {
      margin-bottom: 2.08333vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cheatsheet-message {
      margin-bottom: 3.22061vw; }
      .cheatsheet-message else {
        margin-bottom: 16px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cheatsheet-message {
      margin-bottom: 2.57649vw; }
      .cheatsheet-message else {
        margin-bottom: 16px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cheatsheet-message {
      margin-bottom: 2.27337vw; }
      .cheatsheet-message else {
        margin-bottom: 16px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cheatsheet-message {
      margin-bottom: 2.03407vw; }
      .cheatsheet-message else {
        margin-bottom: 16px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cheatsheet-message {
      margin-bottom: 1.84035vw; }
      .cheatsheet-message else {
        margin-bottom: 16px; } }

.cheatsheet-imageContainer {
  display: inline-block;
  background-color: white;
  margin-bottom: 0.52083vw;
  padding: 0.625vw;
  border-radius: 0.26042vw 0.26042vw 0.26042vw 0vw; }

.cheatsheet-image {
  display: block;
  width: 54.58937vw;
  height: 38.16425vw; }
  @media (min-width: 1300px) {
    .cheatsheet-image {
      width: 18.22917vw;
      height: 18.22917vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cheatsheet-image {
      width: 20.25463vw;
      height: 20.25463vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cheatsheet-image {
      width: 18.22917vw;
      height: 18.22917vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cheatsheet-image {
      width: 45.49114vw;
      height: 31.80354vw; }
      .cheatsheet-image else {
        width: 226px; }
      .cheatsheet-image else {
        height: 158px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cheatsheet-image {
      width: 36.39291vw;
      height: 25.44283vw; }
      .cheatsheet-image else {
        width: 226px; }
      .cheatsheet-image else {
        height: 158px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cheatsheet-image {
      width: 32.1114vw;
      height: 22.44956vw; }
      .cheatsheet-image else {
        width: 226px; }
      .cheatsheet-image else {
        height: 158px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cheatsheet-image {
      width: 28.73125vw;
      height: 20.08645vw; }
      .cheatsheet-image else {
        width: 226px; }
      .cheatsheet-image else {
        height: 158px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cheatsheet-image {
      width: 25.99494vw;
      height: 18.17345vw; }
      .cheatsheet-image else {
        width: 226px; }
      .cheatsheet-image else {
        height: 158px; } }

.cheatsheet-animationContainer {
  height: 180px;
  margin-bottom: 20px; }

.cheatsheet-terminalInputContainer {
  padding: 1.66667vw 2.08333vw;
  background-color: #eae9ee;
  width: 100%;
  color: #000000;
  box-sizing: border-box;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 3.86473vw 0.96618vw 0 0;
  padding: 1.93237vw 2.89855vw;
  background-color: #eae9ee; }
  @media (min-width: 1300px) {
    .cheatsheet-terminalInputContainer {
      margin: 0.88542vw 0 0;
      padding: 1.09375vw 1.30208vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cheatsheet-terminalInputContainer {
      margin: 0.9838vw 0 0;
      padding: 1.21528vw 1.44676vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cheatsheet-terminalInputContainer {
      margin: 0.88542vw 0 0;
      padding: 1.09375vw 1.30208vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cheatsheet-terminalInputContainer {
      margin: 3.22061vw 0.80515vw 0 0;
      padding: 1.61031vw 2.41546vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cheatsheet-terminalInputContainer {
      margin: 2.57649vw 0.64412vw 0 0;
      padding: 1.28824vw 1.93237vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cheatsheet-terminalInputContainer {
      margin: 2.27337vw 0.56834vw 0 0;
      padding: 1.13669vw 1.70503vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cheatsheet-terminalInputContainer {
      margin: 2.03407vw 0.50852vw 0 0;
      padding: 1.01704vw 1.52555vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cheatsheet-terminalInputContainer {
      margin: 1.84035vw 0.46009vw 0 0;
      padding: 0.92017vw 1.38026vw; } }
  .cheatsheet-terminalInputContainer code {
    letter-spacing: 0.08696vw;
    font-size: 2.89855vw;
    color: black !important;
    opacity: 0.7;
    font-family: Monaco;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    text-align: left; }
    @media (min-width: 1300px) {
      .cheatsheet-terminalInputContainer code {
        font-size: 0.9375vw;
        letter-spacing: 0.02813vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cheatsheet-terminalInputContainer code {
        font-size: 1.04167vw;
        letter-spacing: 0.03125vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cheatsheet-terminalInputContainer code {
        font-size: 0.9375vw;
        letter-spacing: 0.02813vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .cheatsheet-terminalInputContainer code {
        letter-spacing: 0.07246vw;
        font-size: 2.41546vw; }
        .cheatsheet-terminalInputContainer code else {
          letter-spacing: 0.36px; }
        .cheatsheet-terminalInputContainer code else {
          font-size: 12px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .cheatsheet-terminalInputContainer code {
        letter-spacing: 0.05797vw;
        font-size: 1.93237vw; }
        .cheatsheet-terminalInputContainer code else {
          letter-spacing: 0.36px; }
        .cheatsheet-terminalInputContainer code else {
          font-size: 12px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .cheatsheet-terminalInputContainer code {
        letter-spacing: 0.05115vw;
        font-size: 1.70503vw; }
        .cheatsheet-terminalInputContainer code else {
          letter-spacing: 0.36px; }
        .cheatsheet-terminalInputContainer code else {
          font-size: 12px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .cheatsheet-terminalInputContainer code {
        letter-spacing: 0.04577vw;
        font-size: 1.52555vw; }
        .cheatsheet-terminalInputContainer code else {
          letter-spacing: 0.36px; }
        .cheatsheet-terminalInputContainer code else {
          font-size: 12px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .cheatsheet-terminalInputContainer code {
        letter-spacing: 0.04141vw;
        font-size: 1.38026vw; }
        .cheatsheet-terminalInputContainer code else {
          letter-spacing: 0.36px; }
        .cheatsheet-terminalInputContainer code else {
          font-size: 12px; } }

.cheatsheet-terminalInputContainerLeft {
  background-color: #002f3e;
  opacity: 1;
  flex-grow: 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding: 2.41546vw 2.17391vw; }
  @media (min-width: 1300px) {
    .cheatsheet-terminalInputContainerLeft {
      padding: 1.14583vw 1.30208vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cheatsheet-terminalInputContainerLeft {
      padding: 1.27315vw 1.44676vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cheatsheet-terminalInputContainerLeft {
      padding: 1.14583vw 1.30208vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cheatsheet-terminalInputContainerLeft {
      padding: 2.01288vw 1.81159vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cheatsheet-terminalInputContainerLeft {
      padding: 1.61031vw 1.44928vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cheatsheet-terminalInputContainerLeft {
      padding: 1.42086vw 1.27877vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cheatsheet-terminalInputContainerLeft {
      padding: 1.27129vw 1.14416vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cheatsheet-terminalInputContainerLeft {
      padding: 1.15022vw 1.0352vw; } }
  .cheatsheet-terminalInputContainerLeft code {
    font-family: Monaco !important;
    font-size: 2.89855vw; }
    @media (min-width: 1300px) {
      .cheatsheet-terminalInputContainerLeft code {
        font-size: 1.04167vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cheatsheet-terminalInputContainerLeft code {
        font-size: 1.15741vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cheatsheet-terminalInputContainerLeft code {
        font-size: 1.04167vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .cheatsheet-terminalInputContainerLeft code {
        font-size: 2.41546vw; }
        .cheatsheet-terminalInputContainerLeft code else {
          font-size: 12px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .cheatsheet-terminalInputContainerLeft code {
        font-size: 1.93237vw; }
        .cheatsheet-terminalInputContainerLeft code else {
          font-size: 12px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .cheatsheet-terminalInputContainerLeft code {
        font-size: 1.70503vw; }
        .cheatsheet-terminalInputContainerLeft code else {
          font-size: 12px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .cheatsheet-terminalInputContainerLeft code {
        font-size: 1.52555vw; }
        .cheatsheet-terminalInputContainerLeft code else {
          font-size: 12px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .cheatsheet-terminalInputContainerLeft code {
        font-size: 1.38026vw; }
        .cheatsheet-terminalInputContainerLeft code else {
          font-size: 12px; } }

.cheatsheet-terminalOutputContainer {
  width: 100%;
  background-color: #015f7c;
  color: white;
  font-family: Monaco;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;
  padding: 2.41546vw 2.17391vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: 2.89855vw;
  color: white !important; }
  @media (min-width: 1300px) {
    .cheatsheet-terminalOutputContainer {
      padding: 1.14583vw 1.30208vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cheatsheet-terminalOutputContainer {
      padding: 1.27315vw 1.44676vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cheatsheet-terminalOutputContainer {
      padding: 1.14583vw 1.30208vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cheatsheet-terminalOutputContainer {
      padding: 2.01288vw 1.81159vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cheatsheet-terminalOutputContainer {
      padding: 1.61031vw 1.44928vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cheatsheet-terminalOutputContainer {
      padding: 1.42086vw 1.27877vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cheatsheet-terminalOutputContainer {
      padding: 1.27129vw 1.14416vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cheatsheet-terminalOutputContainer {
      padding: 1.15022vw 1.0352vw; } }
  @media (min-width: 1300px) {
    .cheatsheet-terminalOutputContainer {
      font-size: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cheatsheet-terminalOutputContainer {
      font-size: 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cheatsheet-terminalOutputContainer {
      font-size: 1.04167vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cheatsheet-terminalOutputContainer {
      font-size: 2.41546vw; }
      .cheatsheet-terminalOutputContainer else {
        font-size: 12px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cheatsheet-terminalOutputContainer {
      font-size: 1.93237vw; }
      .cheatsheet-terminalOutputContainer else {
        font-size: 12px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cheatsheet-terminalOutputContainer {
      font-size: 1.70503vw; }
      .cheatsheet-terminalOutputContainer else {
        font-size: 12px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cheatsheet-terminalOutputContainer {
      font-size: 1.52555vw; }
      .cheatsheet-terminalOutputContainer else {
        font-size: 12px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cheatsheet-terminalOutputContainer {
      font-size: 1.38026vw; }
      .cheatsheet-terminalOutputContainer else {
        font-size: 12px; } }

.cheatsheet-terminalPlayButton {
  background-image: url(/static/media/playButton.32a941f2.png);
  background-size: 120%;
  background-position-x: 43%;
  background-position-y: 44%;
  cursor: pointer;
  position: absolute;
  width: 10.62802vw;
  height: 10.62802vw;
  top: -4.83092vw;
  right: 7.00483vw;
  transition: 0.3s all ease-in-out; }
  @media (min-width: 1300px) {
    .cheatsheet-terminalPlayButton {
      width: 3.64583vw;
      height: 3.64583vw;
      right: 1.25521vw;
      top: -1.66667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cheatsheet-terminalPlayButton {
      width: 4.05093vw;
      height: 4.05093vw;
      right: 1.39468vw;
      top: -1.85185vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cheatsheet-terminalPlayButton {
      width: 3.64583vw;
      height: 3.64583vw;
      right: 1.25521vw;
      top: -1.66667vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cheatsheet-terminalPlayButton {
      width: 8.85668vw;
      height: 8.85668vw;
      top: -4.02576vw;
      right: 5.83736vw; }
      .cheatsheet-terminalPlayButton else {
        width: 44px; }
      .cheatsheet-terminalPlayButton else {
        height: 44px; }
      .cheatsheet-terminalPlayButton else {
        top: -20px; }
      .cheatsheet-terminalPlayButton else {
        right: 29px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cheatsheet-terminalPlayButton {
      width: 7.08535vw;
      height: 7.08535vw;
      top: -3.22061vw;
      right: 4.66989vw; }
      .cheatsheet-terminalPlayButton else {
        width: 44px; }
      .cheatsheet-terminalPlayButton else {
        height: 44px; }
      .cheatsheet-terminalPlayButton else {
        top: -20px; }
      .cheatsheet-terminalPlayButton else {
        right: 29px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cheatsheet-terminalPlayButton {
      width: 6.25178vw;
      height: 6.25178vw;
      top: -2.84172vw;
      right: 4.12049vw; }
      .cheatsheet-terminalPlayButton else {
        width: 44px; }
      .cheatsheet-terminalPlayButton else {
        height: 44px; }
      .cheatsheet-terminalPlayButton else {
        top: -20px; }
      .cheatsheet-terminalPlayButton else {
        right: 29px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cheatsheet-terminalPlayButton {
      width: 5.59369vw;
      height: 5.59369vw;
      top: -2.54259vw;
      right: 3.68675vw; }
      .cheatsheet-terminalPlayButton else {
        width: 44px; }
      .cheatsheet-terminalPlayButton else {
        height: 44px; }
      .cheatsheet-terminalPlayButton else {
        top: -20px; }
      .cheatsheet-terminalPlayButton else {
        right: 29px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cheatsheet-terminalPlayButton {
      width: 5.06096vw;
      height: 5.06096vw;
      top: -2.30044vw;
      right: 3.33563vw; }
      .cheatsheet-terminalPlayButton else {
        width: 44px; }
      .cheatsheet-terminalPlayButton else {
        height: 44px; }
      .cheatsheet-terminalPlayButton else {
        top: -20px; }
      .cheatsheet-terminalPlayButton else {
        right: 29px; } }
  .cheatsheet-terminalPlayButton:hover {
    opacity: 0.5; }

.cheatsheet-starIcons {
  height: 4.83092vw;
  width: 4.83092vw;
  margin-left: 1.44928vw;
  padding: 1.44928vw;
  cursor: pointer;
  display: flex;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 1300px) {
    .cheatsheet-starIcons {
      height: 1.04167vw;
      width: 1.04167vw;
      margin-left: 0.41667vw;
      padding: 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cheatsheet-starIcons {
      height: 1.15741vw;
      width: 1.15741vw;
      margin-left: 0.46296vw;
      padding: 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cheatsheet-starIcons {
      height: 1.04167vw;
      width: 1.04167vw;
      margin-left: 0.41667vw;
      padding: 0.41667vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cheatsheet-starIcons {
      height: 4.02576vw;
      width: 4.02576vw;
      margin-left: 1.20773vw;
      padding: 1.20773vw; }
      .cheatsheet-starIcons else {
        height: 20px; }
      .cheatsheet-starIcons else {
        width: 20px; }
      .cheatsheet-starIcons else {
        margin-left: 6px; }
      .cheatsheet-starIcons else {
        padding: 6px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cheatsheet-starIcons {
      height: 3.22061vw;
      width: 3.22061vw;
      margin-left: 0.96618vw;
      padding: 0.96618vw; }
      .cheatsheet-starIcons else {
        height: 20px; }
      .cheatsheet-starIcons else {
        width: 20px; }
      .cheatsheet-starIcons else {
        margin-left: 6px; }
      .cheatsheet-starIcons else {
        padding: 6px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cheatsheet-starIcons {
      height: 2.84172vw;
      width: 2.84172vw;
      margin-left: 0.85251vw;
      padding: 0.85251vw; }
      .cheatsheet-starIcons else {
        height: 20px; }
      .cheatsheet-starIcons else {
        width: 20px; }
      .cheatsheet-starIcons else {
        margin-left: 6px; }
      .cheatsheet-starIcons else {
        padding: 6px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cheatsheet-starIcons {
      height: 2.54259vw;
      width: 2.54259vw;
      margin-left: 0.76278vw;
      padding: 0.76278vw; }
      .cheatsheet-starIcons else {
        height: 20px; }
      .cheatsheet-starIcons else {
        width: 20px; }
      .cheatsheet-starIcons else {
        margin-left: 6px; }
      .cheatsheet-starIcons else {
        padding: 6px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cheatsheet-starIcons {
      height: 2.30044vw;
      width: 2.30044vw;
      margin-left: 0.69013vw;
      padding: 0.69013vw; }
      .cheatsheet-starIcons else {
        height: 20px; }
      .cheatsheet-starIcons else {
        width: 20px; }
      .cheatsheet-starIcons else {
        margin-left: 6px; }
      .cheatsheet-starIcons else {
        padding: 6px; } }
  .cheatsheet-starIcons:hover {
    opacity: 0.6;
    background-color: whitesmoke; }

.cheatsheet-contentTitle {
  color: #00ade6;
  display: flex;
  font-family: Lato;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  text-align: left;
  color: #00ade6;
  margin: 0 2.17391vw 0 0;
  font-size: 4.34783vw;
  letter-spacing: 0.13043vw;
  align-items: center; }
  @media (min-width: 1300px) {
    .cheatsheet-contentTitle {
      margin: 0 0.41667vw 0.83333vw 0;
      font-size: 1.30208vw;
      letter-spacing: 0.03906vw;
      align-items: flex-start; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cheatsheet-contentTitle {
      margin: 0 0.46296vw 0.92593vw 0;
      font-size: 1.44676vw;
      letter-spacing: 0.0434vw;
      align-items: flex-start; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cheatsheet-contentTitle {
      margin: 0 0.41667vw 0.83333vw 0;
      font-size: 1.30208vw;
      letter-spacing: 0.03906vw;
      align-items: flex-start; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cheatsheet-contentTitle {
      margin: 0 1.81159vw 0 0;
      font-size: 3.62319vw;
      letter-spacing: 0.1087vw; }
      .cheatsheet-contentTitle else {
        font-size: 18px; }
      .cheatsheet-contentTitle else {
        letter-spacing: 0.54px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cheatsheet-contentTitle {
      margin: 0 1.44928vw 0 0;
      font-size: 2.89855vw;
      letter-spacing: 0.08696vw; }
      .cheatsheet-contentTitle else {
        font-size: 18px; }
      .cheatsheet-contentTitle else {
        letter-spacing: 0.54px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cheatsheet-contentTitle {
      margin: 0 1.27877vw 0 0;
      font-size: 2.55754vw;
      letter-spacing: 0.07673vw; }
      .cheatsheet-contentTitle else {
        font-size: 18px; }
      .cheatsheet-contentTitle else {
        letter-spacing: 0.54px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cheatsheet-contentTitle {
      margin: 0 1.14416vw 0 0;
      font-size: 2.28833vw;
      letter-spacing: 0.06865vw; }
      .cheatsheet-contentTitle else {
        font-size: 18px; }
      .cheatsheet-contentTitle else {
        letter-spacing: 0.54px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cheatsheet-contentTitle {
      margin: 0 1.0352vw 0 0;
      font-size: 2.07039vw;
      letter-spacing: 0.06211vw; }
      .cheatsheet-contentTitle else {
        font-size: 18px; }
      .cheatsheet-contentTitle else {
        letter-spacing: 0.54px; } }

.cheatsheet-contentContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px; }
  .cheatsheet-contentContainer > div {
    width: 45%; }

.cheatsheet-loadingText {
  width: 230px;
  height: 30px;
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff; }

.cheatsheet-terminalInputContainerLoader {
  padding: 0 !important;
  height: 166.4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px; }

.cheatsheet-emptyText {
  width: 100%;
  text-align: center;
  height: 40vh; }
  .cheatsheet-emptyText h1 {
    color: black;
    opacity: 0.5;
    font-size: 4.83092vw; }
    @media (min-width: 1300px) {
      .cheatsheet-emptyText h1 {
        font-size: 1.5625vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cheatsheet-emptyText h1 {
        font-size: 1.73611vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cheatsheet-emptyText h1 {
        font-size: 1.5625vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .cheatsheet-emptyText h1 {
        font-size: 4.02576vw; }
        .cheatsheet-emptyText h1 else {
          font-size: 20px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .cheatsheet-emptyText h1 {
        font-size: 3.22061vw; }
        .cheatsheet-emptyText h1 else {
          font-size: 20px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .cheatsheet-emptyText h1 {
        font-size: 2.84172vw; }
        .cheatsheet-emptyText h1 else {
          font-size: 20px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .cheatsheet-emptyText h1 {
        font-size: 2.54259vw; }
        .cheatsheet-emptyText h1 else {
          font-size: 20px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .cheatsheet-emptyText h1 {
        font-size: 2.30044vw; }
        .cheatsheet-emptyText h1 else {
          font-size: 20px; } }
  .cheatsheet-emptyText h4 {
    color: black;
    opacity: 0.3;
    font-size: 4.34783vw;
    margin: 2.41546vw 0; }
    @media (min-width: 1300px) {
      .cheatsheet-emptyText h4 {
        font-size: 1.45833vw;
        margin: 0.52083vw 0; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cheatsheet-emptyText h4 {
        font-size: 1.62037vw;
        margin: 0.5787vw 0; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cheatsheet-emptyText h4 {
        font-size: 1.45833vw;
        margin: 0.52083vw 0; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .cheatsheet-emptyText h4 {
        font-size: 3.62319vw;
        margin: 2.01288vw 0; }
        .cheatsheet-emptyText h4 else {
          font-size: 18px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .cheatsheet-emptyText h4 {
        font-size: 2.89855vw;
        margin: 1.61031vw 0; }
        .cheatsheet-emptyText h4 else {
          font-size: 18px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .cheatsheet-emptyText h4 {
        font-size: 2.55754vw;
        margin: 1.42086vw 0; }
        .cheatsheet-emptyText h4 else {
          font-size: 18px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .cheatsheet-emptyText h4 {
        font-size: 2.28833vw;
        margin: 1.27129vw 0; }
        .cheatsheet-emptyText h4 else {
          font-size: 18px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .cheatsheet-emptyText h4 {
        font-size: 2.07039vw;
        margin: 1.15022vw 0; }
        .cheatsheet-emptyText h4 else {
          font-size: 18px; } }

@media (max-width: 579px) {
  .cheatsheet-text > img {
    width: 20px !important;
    height: 20px !important;
    object-fit: contain; }
  .cheatsheet-syntax {
    margin-top: 15px;
    overflow: hidden; }
  .cheatsheet-contentContainer {
    flex-direction: column; }
    .cheatsheet-contentContainer > div {
      width: 100%; }
    .cheatsheet-contentContainer > div:first-of-type {
      margin-bottom: 20px; }
  .cheatsheet-loadingText {
    width: 100%; } }

@media (max-width: 698px) and (min-width: 580px) {
  .cheatsheet-text > img {
    width: 20px !important;
    height: 20px !important;
    object-fit: contain; }
  .cheatsheet-contentContainer {
    flex-direction: column; }
    .cheatsheet-contentContainer > div {
      width: 100%; }
    .cheatsheet-contentContainer > div:first-of-type {
      margin-bottom: 30px; }
  .cheatsheet-loadingText {
    width: 100%; } }

@media (max-width: 799px) and (min-width: 699px) {
  .cheatsheet-text > img {
    width: 20px !important;
    height: 20px !important;
    object-fit: contain; }
  .cheatsheet-contentContainer {
    flex-direction: column; }
    .cheatsheet-contentContainer > div {
      width: 100%; }
    .cheatsheet-contentContainer > div:first-of-type {
      margin-bottom: 30px; }
  .cheatsheet-loadingText {
    width: 100%; } }

@media (max-width: 899px) and (min-width: 800px) {
  .cheatsheet-contentArea {
    padding: 20px 20px 0 20px; }
  .cheatsheet-text > img {
    width: 20px !important;
    height: 20px !important;
    object-fit: contain; }
  .cheatsheet-contentContainer {
    flex-direction: column; }
    .cheatsheet-contentContainer > div {
      width: 100%; }
    .cheatsheet-contentContainer > div:first-of-type {
      margin-bottom: 30px; }
  .cheatsheet-loadingText {
    width: 100%; } }

.cheatsheet-footer {
  margin: 28px auto;
  width: 100%;
  display: flex;
  justify-content: center; }

.cheatsheet-iconContainer {
  position: relative;
  width: 0.74479vw;
  height: 0.8125vw; }

.cheatsheet-iconContainer svg {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1); }

.cheatsheet-leftButton, .cheatsheet-rightButton {
  width: 35.50725vw;
  height: 12.31884vw; }
  @media (min-width: 1300px) {
    .cheatsheet-leftButton, .cheatsheet-rightButton {
      width: 10.41667vw;
      height: 2.91667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cheatsheet-leftButton, .cheatsheet-rightButton {
      width: 11.57407vw;
      height: 3.24074vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cheatsheet-leftButton, .cheatsheet-rightButton {
      width: 10.41667vw;
      height: 2.91667vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cheatsheet-leftButton, .cheatsheet-rightButton {
      width: 29.58937vw;
      height: 10.2657vw; }
      .cheatsheet-leftButton else, .cheatsheet-rightButton else {
        width: 147px; }
      .cheatsheet-leftButton else, .cheatsheet-rightButton else {
        height: 51px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cheatsheet-leftButton, .cheatsheet-rightButton {
      width: 23.6715vw;
      height: 8.21256vw; }
      .cheatsheet-leftButton else, .cheatsheet-rightButton else {
        width: 147px; }
      .cheatsheet-leftButton else, .cheatsheet-rightButton else {
        height: 51px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cheatsheet-leftButton, .cheatsheet-rightButton {
      width: 20.88662vw;
      height: 7.24638vw; }
      .cheatsheet-leftButton else, .cheatsheet-rightButton else {
        width: 147px; }
      .cheatsheet-leftButton else, .cheatsheet-rightButton else {
        height: 51px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cheatsheet-leftButton, .cheatsheet-rightButton {
      width: 18.68802vw;
      height: 6.4836vw; }
      .cheatsheet-leftButton else, .cheatsheet-rightButton else {
        width: 147px; }
      .cheatsheet-leftButton else, .cheatsheet-rightButton else {
        height: 51px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cheatsheet-leftButton, .cheatsheet-rightButton {
      width: 16.90821vw;
      height: 5.86611vw; }
      .cheatsheet-leftButton else, .cheatsheet-rightButton else {
        width: 147px; }
      .cheatsheet-leftButton else, .cheatsheet-rightButton else {
        height: 51px; } }

.cheatsheet-buttonContainer {
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  align-items: center;
  flex-direction: row;
  pointer-events: auto;
  cursor: pointer;
  color: white;
  text-decoration: none;
  margin: 0 30px;
  background-image: linear-gradient(to top, #00ade6, #34e4ea);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  font-family: Nunito;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.59;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  border-radius: 8px;
  font-size: 3.62319vw;
  width: 35.50725vw;
  height: 12.31884vw; }
  @media (min-width: 1300px) {
    .cheatsheet-buttonContainer {
      font-size: 1.09375vw;
      width: 10.41667vw;
      height: 2.91667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cheatsheet-buttonContainer {
      font-size: 1.21528vw;
      width: 11.57407vw;
      height: 3.24074vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cheatsheet-buttonContainer {
      font-size: 1.09375vw;
      width: 10.41667vw;
      height: 2.91667vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cheatsheet-buttonContainer {
      font-size: 3.01932vw;
      width: 29.58937vw;
      height: 10.2657vw; }
      .cheatsheet-buttonContainer else {
        font-size: 15px; }
      .cheatsheet-buttonContainer else {
        width: 147px; }
      .cheatsheet-buttonContainer else {
        height: 51px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cheatsheet-buttonContainer {
      font-size: 2.41546vw;
      width: 23.6715vw;
      height: 8.21256vw; }
      .cheatsheet-buttonContainer else {
        font-size: 15px; }
      .cheatsheet-buttonContainer else {
        width: 147px; }
      .cheatsheet-buttonContainer else {
        height: 51px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cheatsheet-buttonContainer {
      font-size: 2.13129vw;
      width: 20.88662vw;
      height: 7.24638vw; }
      .cheatsheet-buttonContainer else {
        font-size: 15px; }
      .cheatsheet-buttonContainer else {
        width: 147px; }
      .cheatsheet-buttonContainer else {
        height: 51px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cheatsheet-buttonContainer {
      font-size: 1.90694vw;
      width: 18.68802vw;
      height: 6.4836vw; }
      .cheatsheet-buttonContainer else {
        font-size: 15px; }
      .cheatsheet-buttonContainer else {
        width: 147px; }
      .cheatsheet-buttonContainer else {
        height: 51px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cheatsheet-buttonContainer {
      font-size: 1.72533vw;
      width: 16.90821vw;
      height: 5.86611vw; }
      .cheatsheet-buttonContainer else {
        font-size: 15px; }
      .cheatsheet-buttonContainer else {
        width: 147px; }
      .cheatsheet-buttonContainer else {
        height: 51px; } }

.cheatsheet-prevButton {
  background-image: unset;
  border: 1.5px solid #00ade6;
  color: #00ade6; }
  .cheatsheet-prevButton .iconCircle {
    box-shadow: unset; }

.cheatsheet-iconCircle {
  box-shadow: 0 0px 3px 0 #34e4ea;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  position: relative;
  width: 4.83092vw;
  height: 4.83092vw; }
  @media (min-width: 1300px) {
    .cheatsheet-iconCircle {
      height: 1.40625vw;
      width: 1.40625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cheatsheet-iconCircle {
      height: 1.5625vw;
      width: 1.5625vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cheatsheet-iconCircle {
      height: 1.40625vw;
      width: 1.40625vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cheatsheet-iconCircle {
      width: 4.02576vw;
      height: 4.02576vw; }
      .cheatsheet-iconCircle else {
        width: 20px; }
      .cheatsheet-iconCircle else {
        height: 20px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cheatsheet-iconCircle {
      width: 3.22061vw;
      height: 3.22061vw; }
      .cheatsheet-iconCircle else {
        width: 20px; }
      .cheatsheet-iconCircle else {
        height: 20px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cheatsheet-iconCircle {
      width: 2.84172vw;
      height: 2.84172vw; }
      .cheatsheet-iconCircle else {
        width: 20px; }
      .cheatsheet-iconCircle else {
        height: 20px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cheatsheet-iconCircle {
      width: 2.54259vw;
      height: 2.54259vw; }
      .cheatsheet-iconCircle else {
        width: 20px; }
      .cheatsheet-iconCircle else {
        height: 20px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cheatsheet-iconCircle {
      width: 2.30044vw;
      height: 2.30044vw; }
      .cheatsheet-iconCircle else {
        width: 20px; }
      .cheatsheet-iconCircle else {
        height: 20px; } }
  .cheatsheet-iconCircle:hover {
    -webkit-transform: scale(1.15);
            transform: scale(1.15); }
  .cheatsheet-iconCircle svg {
    height: 100%;
    width: 100%; }

.cheatsheet-reveseIcon {
  box-shadow: unset; }
  .cheatsheet-reveseIcon svg {
    height: 100%;
    width: 100%; }
    .cheatsheet-reveseIcon svg path {
      stroke: white !important; }

@media (max-width: 698px) {
  .cheatsheet-buttonContainer {
    margin: 0 12px; } }

.cheatsheet-topicContainer {
  background-color: #012a38;
  height: 50.72464vw;
  padding: 8.45411vw 0 8.45411vw 0;
  width: 100%;
  box-sizing: border-box; }
  @media (min-width: 1300px) {
    .cheatsheet-topicContainer {
      height: 18.22917vw;
      padding: 2.34375vw 0 2.34375vw 0; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cheatsheet-topicContainer {
      height: 20.25463vw;
      padding: 2.60417vw 0 2.60417vw 0; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cheatsheet-topicContainer {
      height: 18.22917vw;
      padding: 2.34375vw 0 2.34375vw 0; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cheatsheet-topicContainer {
      height: 42.27053vw;
      padding: 7.04509vw 0 7.04509vw 0; }
      .cheatsheet-topicContainer else {
        height: 210px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cheatsheet-topicContainer {
      height: 33.81643vw;
      padding: 5.63607vw 0 5.63607vw 0; }
      .cheatsheet-topicContainer else {
        height: 210px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cheatsheet-topicContainer {
      height: 29.83802vw;
      padding: 4.973vw 0 4.973vw 0; }
      .cheatsheet-topicContainer else {
        height: 210px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cheatsheet-topicContainer {
      height: 26.69718vw;
      padding: 4.44953vw 0 4.44953vw 0; }
      .cheatsheet-topicContainer else {
        height: 210px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cheatsheet-topicContainer {
      height: 24.15459vw;
      padding: 4.02576vw 0 4.02576vw 0; }
      .cheatsheet-topicContainer else {
        height: 210px; } }

.cheatsheet-topicContainer * {
  box-sizing: border-box; }

.cheatsheet-searchbar {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; }

.cheatsheet-modal {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  opacity: 0.32;
  display: flex;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  background-color: rgba(1, 42, 56, 0.5);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 7; }

.cheatsheet-modal.cheatsheet-showModal {
  opacity: 1;
  overflow: overlay;
  pointer-events: auto; }

.cheatsheet-modalBody {
  background-color: #fff;
  border-radius: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 15em);
  position: absolute;
  width: 80%;
  max-width: 73.91304vw;
  top: 12.07729vw;
  z-index: 9; }
  @media (min-width: 1300px) {
    .cheatsheet-modalBody {
      max-width: 35.72917vw;
      top: 4.16667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cheatsheet-modalBody {
      max-width: 39.69907vw;
      top: 4.62963vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cheatsheet-modalBody {
      max-width: 35.72917vw;
      top: 4.16667vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cheatsheet-modalBody {
      max-width: 61.5942vw;
      top: 10.06441vw; }
      .cheatsheet-modalBody else {
        max-width: 306px; }
      .cheatsheet-modalBody else {
        top: 50px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cheatsheet-modalBody {
      max-width: 49.27536vw;
      top: 8.05153vw; }
      .cheatsheet-modalBody else {
        max-width: 306px; }
      .cheatsheet-modalBody else {
        top: 50px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cheatsheet-modalBody {
      max-width: 43.47826vw;
      top: 7.10429vw; }
      .cheatsheet-modalBody else {
        max-width: 306px; }
      .cheatsheet-modalBody else {
        top: 50px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cheatsheet-modalBody {
      max-width: 38.9016vw;
      top: 6.35647vw; }
      .cheatsheet-modalBody else {
        max-width: 306px; }
      .cheatsheet-modalBody else {
        top: 50px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cheatsheet-modalBody {
      max-width: 35.19669vw;
      top: 5.75109vw; }
      .cheatsheet-modalBody else {
        max-width: 306px; }
      .cheatsheet-modalBody else {
        top: 50px; } }

.cheatsheet-showInput {
  visibility: hidden;
  opacity: 0; }

.cheatsheet-searchContainer {
  width: 80%;
  z-index: 9;
  background-color: #fefeff;
  border-radius: 999px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin: 0 auto;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #fefeff;
  height: 7.97101vw;
  padding: 2.41546vw;
  max-width: 73.91304vw; }
  @media (min-width: 1300px) {
    .cheatsheet-searchContainer {
      padding: 0.52083vw 1.04167vw;
      height: 2.86458vw;
      max-width: 35.72917vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cheatsheet-searchContainer {
      padding: 0.5787vw 1.15741vw;
      height: 3.18287vw;
      max-width: 39.69907vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cheatsheet-searchContainer {
      padding: 0.52083vw 1.04167vw;
      height: 2.86458vw;
      max-width: 35.72917vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cheatsheet-searchContainer {
      height: 6.64251vw;
      padding: 2.01288vw;
      max-width: 61.5942vw; }
      .cheatsheet-searchContainer else {
        height: 33px; }
      .cheatsheet-searchContainer else {
        padding: 10px; }
      .cheatsheet-searchContainer else {
        max-width: 306px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cheatsheet-searchContainer {
      height: 5.31401vw;
      padding: 1.61031vw;
      max-width: 49.27536vw; }
      .cheatsheet-searchContainer else {
        height: 33px; }
      .cheatsheet-searchContainer else {
        padding: 10px; }
      .cheatsheet-searchContainer else {
        max-width: 306px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cheatsheet-searchContainer {
      height: 4.68883vw;
      padding: 1.42086vw;
      max-width: 43.47826vw; }
      .cheatsheet-searchContainer else {
        height: 33px; }
      .cheatsheet-searchContainer else {
        padding: 10px; }
      .cheatsheet-searchContainer else {
        max-width: 306px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cheatsheet-searchContainer {
      height: 4.19527vw;
      padding: 1.27129vw;
      max-width: 38.9016vw; }
      .cheatsheet-searchContainer else {
        height: 33px; }
      .cheatsheet-searchContainer else {
        padding: 10px; }
      .cheatsheet-searchContainer else {
        max-width: 306px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cheatsheet-searchContainer {
      height: 3.79572vw;
      padding: 1.15022vw;
      max-width: 35.19669vw; }
      .cheatsheet-searchContainer else {
        height: 33px; }
      .cheatsheet-searchContainer else {
        padding: 10px; }
      .cheatsheet-searchContainer else {
        max-width: 306px; } }
  .cheatsheet-searchContainer > .cheatsheet-inputLoader {
    height: 3.62319vw;
    width: 3.62319vw; }
    @media (min-width: 1300px) {
      .cheatsheet-searchContainer > .cheatsheet-inputLoader {
        height: 1.30208vw;
        width: 1.30208vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cheatsheet-searchContainer > .cheatsheet-inputLoader {
        height: 1.44676vw;
        width: 1.44676vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cheatsheet-searchContainer > .cheatsheet-inputLoader {
        height: 1.30208vw;
        width: 1.30208vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .cheatsheet-searchContainer > .cheatsheet-inputLoader {
        height: 3.01932vw;
        width: 3.01932vw; }
        .cheatsheet-searchContainer > .cheatsheet-inputLoader else {
          height: 15px; }
        .cheatsheet-searchContainer > .cheatsheet-inputLoader else {
          width: 15px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .cheatsheet-searchContainer > .cheatsheet-inputLoader {
        height: 2.41546vw;
        width: 2.41546vw; }
        .cheatsheet-searchContainer > .cheatsheet-inputLoader else {
          height: 15px; }
        .cheatsheet-searchContainer > .cheatsheet-inputLoader else {
          width: 15px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .cheatsheet-searchContainer > .cheatsheet-inputLoader {
        height: 2.13129vw;
        width: 2.13129vw; }
        .cheatsheet-searchContainer > .cheatsheet-inputLoader else {
          height: 15px; }
        .cheatsheet-searchContainer > .cheatsheet-inputLoader else {
          width: 15px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .cheatsheet-searchContainer > .cheatsheet-inputLoader {
        height: 1.90694vw;
        width: 1.90694vw; }
        .cheatsheet-searchContainer > .cheatsheet-inputLoader else {
          height: 15px; }
        .cheatsheet-searchContainer > .cheatsheet-inputLoader else {
          width: 15px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .cheatsheet-searchContainer > .cheatsheet-inputLoader {
        height: 1.72533vw;
        width: 1.72533vw; }
        .cheatsheet-searchContainer > .cheatsheet-inputLoader else {
          height: 15px; }
        .cheatsheet-searchContainer > .cheatsheet-inputLoader else {
          width: 15px; } }
  .cheatsheet-searchContainer svg {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
    opacity: 0.4;
    height: 3.62319vw;
    width: 3.62319vw; }
    @media (min-width: 1300px) {
      .cheatsheet-searchContainer svg {
        height: 1.14583vw;
        width: 1.14583vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cheatsheet-searchContainer svg {
        height: 1.27315vw;
        width: 1.27315vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cheatsheet-searchContainer svg {
        height: 1.14583vw;
        width: 1.14583vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .cheatsheet-searchContainer svg {
        height: 3.01932vw;
        width: 3.01932vw; }
        .cheatsheet-searchContainer svg else {
          height: 15px; }
        .cheatsheet-searchContainer svg else {
          width: 15px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .cheatsheet-searchContainer svg {
        height: 2.41546vw;
        width: 2.41546vw; }
        .cheatsheet-searchContainer svg else {
          height: 15px; }
        .cheatsheet-searchContainer svg else {
          width: 15px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .cheatsheet-searchContainer svg {
        height: 2.13129vw;
        width: 2.13129vw; }
        .cheatsheet-searchContainer svg else {
          height: 15px; }
        .cheatsheet-searchContainer svg else {
          width: 15px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .cheatsheet-searchContainer svg {
        height: 1.90694vw;
        width: 1.90694vw; }
        .cheatsheet-searchContainer svg else {
          height: 15px; }
        .cheatsheet-searchContainer svg else {
          width: 15px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .cheatsheet-searchContainer svg {
        height: 1.72533vw;
        width: 1.72533vw; }
        .cheatsheet-searchContainer svg else {
          height: 15px; }
        .cheatsheet-searchContainer svg else {
          width: 15px; } }
  .cheatsheet-searchContainer input {
    font-family: Lato;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #403f3f;
    flex: 1 1;
    border: 0;
    outline: none;
    width: 100%;
    font-size: 2.89855vw; }
    .cheatsheet-searchContainer input::-webkit-input-placeholder {
      opacity: 0.3; }
    .cheatsheet-searchContainer input::placeholder {
      opacity: 0.3; }
    @media (min-width: 1300px) {
      .cheatsheet-searchContainer input {
        font-size: 1.04167vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cheatsheet-searchContainer input {
        font-size: 1.15741vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cheatsheet-searchContainer input {
        font-size: 1.04167vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .cheatsheet-searchContainer input {
        font-size: 2.41546vw; }
        .cheatsheet-searchContainer input else {
          font-size: 12px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .cheatsheet-searchContainer input {
        font-size: 1.93237vw; }
        .cheatsheet-searchContainer input else {
          font-size: 12px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .cheatsheet-searchContainer input {
        font-size: 1.70503vw; }
        .cheatsheet-searchContainer input else {
          font-size: 12px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .cheatsheet-searchContainer input {
        font-size: 1.52555vw; }
        .cheatsheet-searchContainer input else {
          font-size: 12px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .cheatsheet-searchContainer input {
        font-size: 1.38026vw; }
        .cheatsheet-searchContainer input else {
          font-size: 12px; } }
  .cheatsheet-searchContainer > img {
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
    margin-right: 15px;
    opacity: 0.4;
    height: 3.62319vw;
    width: 3.62319vw;
    object-fit: contain; }
    @media (min-width: 1300px) {
      .cheatsheet-searchContainer > img {
        height: 1.40625vw;
        width: 1.40625vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cheatsheet-searchContainer > img {
        height: 1.5625vw;
        width: 1.5625vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cheatsheet-searchContainer > img {
        height: 1.40625vw;
        width: 1.40625vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .cheatsheet-searchContainer > img {
        height: 3.01932vw;
        width: 3.01932vw; }
        .cheatsheet-searchContainer > img else {
          height: 15px; }
        .cheatsheet-searchContainer > img else {
          width: 15px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .cheatsheet-searchContainer > img {
        height: 2.41546vw;
        width: 2.41546vw; }
        .cheatsheet-searchContainer > img else {
          height: 15px; }
        .cheatsheet-searchContainer > img else {
          width: 15px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .cheatsheet-searchContainer > img {
        height: 2.13129vw;
        width: 2.13129vw; }
        .cheatsheet-searchContainer > img else {
          height: 15px; }
        .cheatsheet-searchContainer > img else {
          width: 15px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .cheatsheet-searchContainer > img {
        height: 1.90694vw;
        width: 1.90694vw; }
        .cheatsheet-searchContainer > img else {
          height: 15px; }
        .cheatsheet-searchContainer > img else {
          width: 15px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .cheatsheet-searchContainer > img {
        height: 1.72533vw;
        width: 1.72533vw; }
        .cheatsheet-searchContainer > img else {
          height: 15px; }
        .cheatsheet-searchContainer > img else {
          width: 15px; } }

.cheatsheet-modalInput {
  width: 100% !important; }

.cheatsheet-topicCards {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: 2px solid #225169;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  background-color: #0a3145;
  text-align: center;
  transition: all 0.1s ease-in-out;
  margin: 0 1.93237vw;
  width: 31.40097vw;
  height: 21.73913vw;
  background-position: -70px;
  background-size: 120% 120%; }
  @media (min-width: 1300px) {
    .cheatsheet-topicCards {
      width: 12.5vw;
      height: 7.29167vw;
      margin: 0 1.04167vw;
      background-position: -140px;
      background-size: 140% 140%; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cheatsheet-topicCards {
      width: 13.88889vw;
      height: 8.10185vw;
      margin: 0 1.15741vw;
      background-position: -140px;
      background-size: 140% 140%; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cheatsheet-topicCards {
      width: 12.5vw;
      height: 7.29167vw;
      margin: 0 1.04167vw;
      background-position: -140px;
      background-size: 140% 140%; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cheatsheet-topicCards {
      margin: 0 1.61031vw;
      width: 26.16747vw;
      height: 18.11594vw; }
      .cheatsheet-topicCards else {
        width: 130px; }
      .cheatsheet-topicCards else {
        height: 90px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cheatsheet-topicCards {
      margin: 0 1.28824vw;
      width: 20.93398vw;
      height: 14.49275vw; }
      .cheatsheet-topicCards else {
        width: 130px; }
      .cheatsheet-topicCards else {
        height: 90px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cheatsheet-topicCards {
      margin: 0 1.13669vw;
      width: 18.47116vw;
      height: 12.78772vw; }
      .cheatsheet-topicCards else {
        width: 130px; }
      .cheatsheet-topicCards else {
        height: 90px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cheatsheet-topicCards {
      margin: 0 1.01704vw;
      width: 16.52682vw;
      height: 11.44165vw; }
      .cheatsheet-topicCards else {
        width: 130px; }
      .cheatsheet-topicCards else {
        height: 90px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cheatsheet-topicCards {
      margin: 0 0.92017vw;
      width: 14.95284vw;
      height: 10.35197vw; }
      .cheatsheet-topicCards else {
        width: 130px; }
      .cheatsheet-topicCards else {
        height: 90px; } }
  .cheatsheet-topicCards .cheatsheet-starIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 4.34783vw;
    width: 4.34783vw; }
    @media (min-width: 1300px) {
      .cheatsheet-topicCards .cheatsheet-starIcon {
        height: 1.30208vw;
        width: 1.30208vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cheatsheet-topicCards .cheatsheet-starIcon {
        height: 1.44676vw;
        width: 1.44676vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cheatsheet-topicCards .cheatsheet-starIcon {
        height: 1.30208vw;
        width: 1.30208vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .cheatsheet-topicCards .cheatsheet-starIcon {
        height: 3.62319vw;
        width: 3.62319vw; }
        .cheatsheet-topicCards .cheatsheet-starIcon else {
          height: 18px; }
        .cheatsheet-topicCards .cheatsheet-starIcon else {
          width: 18px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .cheatsheet-topicCards .cheatsheet-starIcon {
        height: 2.89855vw;
        width: 2.89855vw; }
        .cheatsheet-topicCards .cheatsheet-starIcon else {
          height: 18px; }
        .cheatsheet-topicCards .cheatsheet-starIcon else {
          width: 18px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .cheatsheet-topicCards .cheatsheet-starIcon {
        height: 2.55754vw;
        width: 2.55754vw; }
        .cheatsheet-topicCards .cheatsheet-starIcon else {
          height: 18px; }
        .cheatsheet-topicCards .cheatsheet-starIcon else {
          width: 18px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .cheatsheet-topicCards .cheatsheet-starIcon {
        height: 2.28833vw;
        width: 2.28833vw; }
        .cheatsheet-topicCards .cheatsheet-starIcon else {
          height: 18px; }
        .cheatsheet-topicCards .cheatsheet-starIcon else {
          width: 18px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .cheatsheet-topicCards .cheatsheet-starIcon {
        height: 2.07039vw;
        width: 2.07039vw; }
        .cheatsheet-topicCards .cheatsheet-starIcon else {
          height: 18px; }
        .cheatsheet-topicCards .cheatsheet-starIcon else {
          width: 18px; } }
    .cheatsheet-topicCards .cheatsheet-starIcon svg {
      width: 100%;
      height: 100%; }
  .cheatsheet-topicCards h3 {
    font-size: 3.38164vw; }
    @media (min-width: 1300px) {
      .cheatsheet-topicCards h3 {
        font-size: 0.9375vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cheatsheet-topicCards h3 {
        font-size: 1.04167vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cheatsheet-topicCards h3 {
        font-size: 0.9375vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .cheatsheet-topicCards h3 {
        font-size: 2.81804vw; }
        .cheatsheet-topicCards h3 else {
          font-size: 14px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .cheatsheet-topicCards h3 {
        font-size: 2.25443vw; }
        .cheatsheet-topicCards h3 else {
          font-size: 14px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .cheatsheet-topicCards h3 {
        font-size: 1.9892vw; }
        .cheatsheet-topicCards h3 else {
          font-size: 14px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .cheatsheet-topicCards h3 {
        font-size: 1.77981vw; }
        .cheatsheet-topicCards h3 else {
          font-size: 14px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .cheatsheet-topicCards h3 {
        font-size: 1.61031vw; }
        .cheatsheet-topicCards h3 else {
          font-size: 14px; } }
  .cheatsheet-topicCards a {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: white; }

.cheatsheet-selectedCard {
  -webkit-transform: scale(1.2) !important;
          transform: scale(1.2) !important;
  border-radius: 12px;
  box-shadow: 0 4px 12px 0 rgba(7, 43, 61, 0.49);
  position: relative; }
  .cheatsheet-selectedCard::before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 12px;
    padding: 3px;
    background: linear-gradient(to top, #35e4e9, #00ade6);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude; }

.cheatsheet-leftArrow, .cheatsheet-rightArrow {
  padding: 10px;
  position: absolute;
  z-index: 5;
  cursor: pointer;
  width: 60px;
  transition: all 0.1s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, #00171f 0, rgba(0, 23, 31, 0) 100%);
  box-sizing: border-box;
  height: 21.73913vw; }
  @media (min-width: 1300px) {
    .cheatsheet-leftArrow, .cheatsheet-rightArrow {
      height: 7.29167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cheatsheet-leftArrow, .cheatsheet-rightArrow {
      height: 8.10185vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cheatsheet-leftArrow, .cheatsheet-rightArrow {
      height: 7.29167vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cheatsheet-leftArrow, .cheatsheet-rightArrow {
      height: 18.11594vw; }
      .cheatsheet-leftArrow else, .cheatsheet-rightArrow else {
        height: 90px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cheatsheet-leftArrow, .cheatsheet-rightArrow {
      height: 14.49275vw; }
      .cheatsheet-leftArrow else, .cheatsheet-rightArrow else {
        height: 90px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cheatsheet-leftArrow, .cheatsheet-rightArrow {
      height: 12.78772vw; }
      .cheatsheet-leftArrow else, .cheatsheet-rightArrow else {
        height: 90px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cheatsheet-leftArrow, .cheatsheet-rightArrow {
      height: 11.44165vw; }
      .cheatsheet-leftArrow else, .cheatsheet-rightArrow else {
        height: 90px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cheatsheet-leftArrow, .cheatsheet-rightArrow {
      height: 10.35197vw; }
      .cheatsheet-leftArrow else, .cheatsheet-rightArrow else {
        height: 90px; } }

.cheatsheet-leftArrow {
  left: 0;
  -webkit-filter: blur(0.5);
          filter: blur(0.5); }

.cheatsheet-rightArrow {
  right: 0;
  background-image: linear-gradient(to right, rgba(0, 23, 31, 0) 0, #00171f 100%); }

.cheatsheet-leftArrow span:hover, .cheatsheet-rightArrow span:hover {
  transition: all 0.1s ease-in-out;
  -webkit-transform: scale(1.2);
          transform: scale(1.2); }

.cheatsheet-rightArrow svg {
  -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg); }

.cheatsheet-leftArrow svg, .cheatsheet-rightArrow svg {
  height: 20px;
  width: 20px; }

.cheatsheet-leftArrow svg path, .cheatsheet-rightArrow svg path {
  fill: white; }

.cheatsheet-sliderOuter {
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  transition: all 0.1s ease-in-out;
  padding: 0 10px; }

.cheatsheet-sliderHolder {
  position: relative;
  height: 100%;
  margin: 0 auto;
  transition: all 0.1s ease-in-out;
  margin: 0 auto;
  position: relative;
  width: 100%;
  vertical-align: middle;
  display: -webkit-inline-box;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-top: 5.7971vw; }
  @media (min-width: 1300px) {
    .cheatsheet-sliderHolder {
      padding-top: 3.125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cheatsheet-sliderHolder {
      padding-top: 3.47222vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cheatsheet-sliderHolder {
      padding-top: 3.125vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cheatsheet-sliderHolder {
      padding-top: 4.83092vw; }
      .cheatsheet-sliderHolder else {
        padding-top: 24px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cheatsheet-sliderHolder {
      padding-top: 3.86473vw; }
      .cheatsheet-sliderHolder else {
        padding-top: 24px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cheatsheet-sliderHolder {
      padding-top: 3.41006vw; }
      .cheatsheet-sliderHolder else {
        padding-top: 24px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cheatsheet-sliderHolder {
      padding-top: 3.05111vw; }
      .cheatsheet-sliderHolder else {
        padding-top: 24px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cheatsheet-sliderHolder {
      padding-top: 2.76052vw; }
      .cheatsheet-sliderHolder else {
        padding-top: 24px; } }

.cheatsheet-card {
  border-radius: 10px;
  border: 2px solid #225169;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #225169;
  margin: 0 1.93237vw;
  width: 31.40097vw;
  height: 21.73913vw; }
  @media (min-width: 1300px) {
    .cheatsheet-card {
      width: 12.5vw;
      height: 7.29167vw;
      margin: 0 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cheatsheet-card {
      width: 13.88889vw;
      height: 8.10185vw;
      margin: 0 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cheatsheet-card {
      width: 12.5vw;
      height: 7.29167vw;
      margin: 0 1.04167vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cheatsheet-card {
      margin: 0 1.61031vw;
      width: 26.16747vw;
      height: 18.11594vw; }
      .cheatsheet-card else {
        width: 130px; }
      .cheatsheet-card else {
        height: 90px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cheatsheet-card {
      margin: 0 1.28824vw;
      width: 20.93398vw;
      height: 14.49275vw; }
      .cheatsheet-card else {
        width: 130px; }
      .cheatsheet-card else {
        height: 90px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cheatsheet-card {
      margin: 0 1.13669vw;
      width: 18.47116vw;
      height: 12.78772vw; }
      .cheatsheet-card else {
        width: 130px; }
      .cheatsheet-card else {
        height: 90px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cheatsheet-card {
      margin: 0 1.01704vw;
      width: 16.52682vw;
      height: 11.44165vw; }
      .cheatsheet-card else {
        width: 130px; }
      .cheatsheet-card else {
        height: 90px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cheatsheet-card {
      margin: 0 0.92017vw;
      width: 14.95284vw;
      height: 10.35197vw; }
      .cheatsheet-card else {
        width: 130px; }
      .cheatsheet-card else {
        height: 90px; } }

.cheatsheet-loaderContainer {
  position: relative;
  display: inline-flex;
  left: 0%;
  align-items: center;
  transition: all .1s ease-in-out;
  height: 100%;
  margin: 0 auto;
  width: 100%; }

.cheatsheet-modalSearchResult {
  display: flex;
  flex-direction: column;
  width: 100%; }

.cheatsheet-modalTopicTitle {
  width: 100%;
  opacity: 0.4;
  font-family: Lato;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  text-align: left;
  color: #000000;
  background-color: #A8A7A7;
  text-transform: uppercase;
  padding: 1.93237vw 3.62319vw;
  font-size: 2.657vw;
  letter-spacing: 0.1087vw; }
  @media (min-width: 1300px) {
    .cheatsheet-modalTopicTitle {
      font-size: 0.83333vw;
      letter-spacing: 0.025vw;
      padding: 0.52083vw 1.19792vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .cheatsheet-modalTopicTitle {
      font-size: 0.92593vw;
      letter-spacing: 0.02778vw;
      padding: 0.5787vw 1.33102vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .cheatsheet-modalTopicTitle {
      font-size: 0.83333vw;
      letter-spacing: 0.025vw;
      padding: 0.52083vw 1.19792vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .cheatsheet-modalTopicTitle {
      padding: 1.61031vw 3.01932vw;
      font-size: 2.21417vw;
      letter-spacing: 0.09058vw; }
      .cheatsheet-modalTopicTitle else {
        font-size: 11px; }
      .cheatsheet-modalTopicTitle else {
        letter-spacing: 0.45px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .cheatsheet-modalTopicTitle {
      padding: 1.28824vw 2.41546vw;
      font-size: 1.77134vw;
      letter-spacing: 0.07246vw; }
      .cheatsheet-modalTopicTitle else {
        font-size: 11px; }
      .cheatsheet-modalTopicTitle else {
        letter-spacing: 0.45px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .cheatsheet-modalTopicTitle {
      padding: 1.13669vw 2.13129vw;
      font-size: 1.56294vw;
      letter-spacing: 0.06394vw; }
      .cheatsheet-modalTopicTitle else {
        font-size: 11px; }
      .cheatsheet-modalTopicTitle else {
        letter-spacing: 0.45px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .cheatsheet-modalTopicTitle {
      padding: 1.01704vw 1.90694vw;
      font-size: 1.39842vw;
      letter-spacing: 0.05721vw; }
      .cheatsheet-modalTopicTitle else {
        font-size: 11px; }
      .cheatsheet-modalTopicTitle else {
        letter-spacing: 0.45px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .cheatsheet-modalTopicTitle {
      padding: 0.92017vw 1.72533vw;
      font-size: 1.26524vw;
      letter-spacing: 0.05176vw; }
      .cheatsheet-modalTopicTitle else {
        font-size: 11px; }
      .cheatsheet-modalTopicTitle else {
        letter-spacing: 0.45px; } }

.cheatsheet-modalCheatSheetTitle {
  padding: 15px 23px;
  transition: all 0.1s ease-in-out;
  overflow: hidden;
  position: relative;
  width: 100%;
  display: block;
  color: inherit;
  text-decoration: none; }
  .cheatsheet-modalCheatSheetTitle h3 {
    font-family: Nunito;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    color: #00ade6;
    margin: 0;
    display: grid;
    grid-template-columns: 90% 10%;
    justify-content: space-between;
    align-items: end;
    width: 100%;
    font-size: 3.86473vw;
    letter-spacing: 0.12077vw; }
    @media (min-width: 1300px) {
      .cheatsheet-modalCheatSheetTitle h3 {
        font-size: 1.25vw;
        letter-spacing: 0.0375vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cheatsheet-modalCheatSheetTitle h3 {
        font-size: 1.38889vw;
        letter-spacing: 0.04167vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cheatsheet-modalCheatSheetTitle h3 {
        font-size: 1.25vw;
        letter-spacing: 0.0375vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .cheatsheet-modalCheatSheetTitle h3 {
        font-size: 3.22061vw;
        letter-spacing: 0.10064vw; }
        .cheatsheet-modalCheatSheetTitle h3 else {
          font-size: 16px; }
        .cheatsheet-modalCheatSheetTitle h3 else {
          letter-spacing: 0.5px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .cheatsheet-modalCheatSheetTitle h3 {
        font-size: 2.57649vw;
        letter-spacing: 0.08052vw; }
        .cheatsheet-modalCheatSheetTitle h3 else {
          font-size: 16px; }
        .cheatsheet-modalCheatSheetTitle h3 else {
          letter-spacing: 0.5px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .cheatsheet-modalCheatSheetTitle h3 {
        font-size: 2.27337vw;
        letter-spacing: 0.07104vw; }
        .cheatsheet-modalCheatSheetTitle h3 else {
          font-size: 16px; }
        .cheatsheet-modalCheatSheetTitle h3 else {
          letter-spacing: 0.5px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .cheatsheet-modalCheatSheetTitle h3 {
        font-size: 2.03407vw;
        letter-spacing: 0.06356vw; }
        .cheatsheet-modalCheatSheetTitle h3 else {
          font-size: 16px; }
        .cheatsheet-modalCheatSheetTitle h3 else {
          letter-spacing: 0.5px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .cheatsheet-modalCheatSheetTitle h3 {
        font-size: 1.84035vw;
        letter-spacing: 0.05751vw; }
        .cheatsheet-modalCheatSheetTitle h3 else {
          font-size: 16px; }
        .cheatsheet-modalCheatSheetTitle h3 else {
          letter-spacing: 0.5px; } }
  .cheatsheet-modalCheatSheetTitle p {
    flex-grow: 0;
    font-family: Nunito;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    margin: 0;
    margin-top: 8px;
    word-wrap: break-word;
    color: #282828;
    display: grid;
    grid-template-columns: 90% 10%;
    justify-content: space-between;
    align-items: end;
    width: 100%;
    font-size: 3.38164vw;
    padding-left: 1.93237vw; }
    @media (min-width: 1300px) {
      .cheatsheet-modalCheatSheetTitle p {
        font-size: 1.04167vw;
        padding-left: 0.52083vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cheatsheet-modalCheatSheetTitle p {
        font-size: 1.15741vw;
        padding-left: 0.5787vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cheatsheet-modalCheatSheetTitle p {
        font-size: 1.04167vw;
        padding-left: 0.52083vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .cheatsheet-modalCheatSheetTitle p {
        font-size: 2.81804vw;
        padding-left: 1.61031vw; }
        .cheatsheet-modalCheatSheetTitle p else {
          font-size: 14px; }
        .cheatsheet-modalCheatSheetTitle p else {
          padding-left: 8px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .cheatsheet-modalCheatSheetTitle p {
        font-size: 2.25443vw;
        padding-left: 1.28824vw; }
        .cheatsheet-modalCheatSheetTitle p else {
          font-size: 14px; }
        .cheatsheet-modalCheatSheetTitle p else {
          padding-left: 8px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .cheatsheet-modalCheatSheetTitle p {
        font-size: 1.9892vw;
        padding-left: 1.13669vw; }
        .cheatsheet-modalCheatSheetTitle p else {
          font-size: 14px; }
        .cheatsheet-modalCheatSheetTitle p else {
          padding-left: 8px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .cheatsheet-modalCheatSheetTitle p {
        font-size: 1.77981vw;
        padding-left: 1.01704vw; }
        .cheatsheet-modalCheatSheetTitle p else {
          font-size: 14px; }
        .cheatsheet-modalCheatSheetTitle p else {
          padding-left: 8px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .cheatsheet-modalCheatSheetTitle p {
        font-size: 1.61031vw;
        padding-left: 0.92017vw; }
        .cheatsheet-modalCheatSheetTitle p else {
          font-size: 14px; }
        .cheatsheet-modalCheatSheetTitle p else {
          padding-left: 8px; } }
  .cheatsheet-modalCheatSheetTitle:hover {
    cursor: pointer;
    opacity: 0.8;
    background-color: whitesmoke; }
  .cheatsheet-modalCheatSheetTitle .cheatsheet-starIcon {
    height: 3.62319vw;
    width: 3.62319vw;
    margin-left: auto;
    cursor: pointer;
    opacity: 0.4; }
    @media (min-width: 1300px) {
      .cheatsheet-modalCheatSheetTitle .cheatsheet-starIcon {
        height: 1.45833vw;
        width: 1.45833vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .cheatsheet-modalCheatSheetTitle .cheatsheet-starIcon {
        height: 1.62037vw;
        width: 1.62037vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .cheatsheet-modalCheatSheetTitle .cheatsheet-starIcon {
        height: 1.45833vw;
        width: 1.45833vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .cheatsheet-modalCheatSheetTitle .cheatsheet-starIcon {
        height: 3.01932vw;
        width: 3.01932vw; }
        .cheatsheet-modalCheatSheetTitle .cheatsheet-starIcon else {
          height: 15px; }
        .cheatsheet-modalCheatSheetTitle .cheatsheet-starIcon else {
          width: 15px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .cheatsheet-modalCheatSheetTitle .cheatsheet-starIcon {
        height: 2.41546vw;
        width: 2.41546vw; }
        .cheatsheet-modalCheatSheetTitle .cheatsheet-starIcon else {
          height: 15px; }
        .cheatsheet-modalCheatSheetTitle .cheatsheet-starIcon else {
          width: 15px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .cheatsheet-modalCheatSheetTitle .cheatsheet-starIcon {
        height: 2.13129vw;
        width: 2.13129vw; }
        .cheatsheet-modalCheatSheetTitle .cheatsheet-starIcon else {
          height: 15px; }
        .cheatsheet-modalCheatSheetTitle .cheatsheet-starIcon else {
          width: 15px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .cheatsheet-modalCheatSheetTitle .cheatsheet-starIcon {
        height: 1.90694vw;
        width: 1.90694vw; }
        .cheatsheet-modalCheatSheetTitle .cheatsheet-starIcon else {
          height: 15px; }
        .cheatsheet-modalCheatSheetTitle .cheatsheet-starIcon else {
          width: 15px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .cheatsheet-modalCheatSheetTitle .cheatsheet-starIcon {
        height: 1.72533vw;
        width: 1.72533vw; }
        .cheatsheet-modalCheatSheetTitle .cheatsheet-starIcon else {
          height: 15px; }
        .cheatsheet-modalCheatSheetTitle .cheatsheet-starIcon else {
          width: 15px; } }
    .cheatsheet-modalCheatSheetTitle .cheatsheet-starIcon svg {
      height: 100%;
      width: 100%; }
  .cheatsheet-modalCheatSheetTitle .cheatsheet-line {
    position: absolute;
    bottom: 0;
    left: -16px;
    width: 107%;
    height: 1px;
    background-image: linear-gradient(to right, transparent 27%, #000000 23%);
    background-size: 15px 100%; }

@media (max-width: 698px) {
  .cheatsheet-selectedCard {
    -webkit-transform: scale(1.11) !important;
            transform: scale(1.11) !important;
    border-radius: 8px; }
    .cheatsheet-selectedCard::before {
      border-radius: 8px;
      padding: 1.5px; }
  .cheatsheet-searchContainer > img {
    margin: 0 5px; }
  .cheatsheet-leftArrow, .cheatsheet-rightArrow {
    width: 25px; }
  .cheatsheet-leftArrow svg, .cheatsheet-rightArrow svg {
    height: 10px;
    width: 10px; }
  .cheatsheet-modalCheatSheetTitle {
    padding: 10px 15px; } }

@media (max-width: 799px) and (min-width: 699px) {
  .cheatsheet-selectedCard {
    -webkit-transform: scale(1.15) !important;
            transform: scale(1.15) !important; }
    .cheatsheet-selectedCard::before {
      padding: 2px; }
  .cheatsheet-searchContainer > img {
    margin-right: 10px; }
  .cheatsheet-leftArrow, .cheatsheet-rightArrow {
    width: 40px; }
  .cheatsheet-leftArrow svg, .cheatsheet-rightArrow svg {
    height: 12px;
    width: 12px; }
  .cheatsheet-modalCheatSheetTitle {
    padding: 12px 18px; } }

@media (max-width: 899px) and (min-width: 800px) {
  .cheatsheet-selectedCard {
    border: 2px solid #35e4e9 !important; }
    .cheatsheet-selectedCard::before {
      padding: 2px; }
  .cheatsheet-searchContainer > img {
    margin-right: 15px; }
  .cheatsheet-leftArrow, .cheatsheet-rightArrow {
    width: 50px; }
  .cheatsheet-leftArrow span:hover, .cheatsheet-rightArrow span:hover {
    transition: all 0.1s ease-in-out;
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  .cheatsheet-leftArrow svg, .cheatsheet-rightArrow svg {
    height: 15px;
    width: 15px; }
  .cheatsheet-modalCheatSheetTitle {
    padding: 15px 20px; } }

.cheatsheet-cheatSheetsContainer {
  flex: 1 1;
  height: calc(100vh - 4.58333vw);
  overflow-x: hidden; }

body {
  margin: 0;
  padding: 0;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Gilroy", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
  overflow-x: hidden; }

html,
body {
  height: 100%; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", Gilroy monospace; }

@font-face {
  font-family: "Nunito-Bold";
  src: local(Nunito-Bold), url(/static/media/Nunito-Bold.1cd294a7.ttf) format("truetype"); }

@font-face {
  font-family: "Nunito-Bold";
  src: local(Gilroy-Light), url(/static/media/Gilroy-Light.da979280.woff) format("truetype"); }

@font-face {
  font-family: "Nunito-Bold";
  src: local(Gilroy-Bold), url(/static/media/Gilroy-ExtraBold.871422df.woff) format("truetype"); }

@font-face {
  font-family: "Nunito-SemiBold";
  src: local(Nunito-SemiBold), url(/static/media/Nunito-SemiBold.76ae20d4.ttf) format("truetype"); }

@font-face {
  font-family: "Gilroy";
  src: url(/static/media/Gilroy-Medium.6444f14a.ttf) format("woff2");
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gilroy-light";
  src: url(/static/media/gilroy-light-webfont.69c677ec.woff2) format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

::-webkit-scrollbar {
  max-width: 0.78125vw;
  height: 1.20773vw;
  display: flex; }
  @media (min-width: 450px) and (max-width: 500px) {
    ::-webkit-scrollbar {
      height: 1.00644vw; }
      ::-webkit-scrollbar else {
        height: 5px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    ::-webkit-scrollbar {
      height: 0.80515vw; }
      ::-webkit-scrollbar else {
        height: 5px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    ::-webkit-scrollbar {
      height: 0.71043vw; }
      ::-webkit-scrollbar else {
        height: 5px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    ::-webkit-scrollbar {
      height: 0.63565vw; }
      ::-webkit-scrollbar else {
        height: 5px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    ::-webkit-scrollbar {
      height: 0.57511vw; }
      ::-webkit-scrollbar else {
        height: 5px; } }
  @media (min-width: 1300px) {
    ::-webkit-scrollbar {
      height: 0; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    ::-webkit-scrollbar {
      height: 0; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    ::-webkit-scrollbar {
      height: 0; } }

::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 0.52083vw; }

::-webkit-resizer {
  display: none; }

@media all and (display-mode: standalone) {
  div,
  span,
  input,
  button {
    -webkit-user-select: none !important;
            user-select: none !important;
    -webkit-tap-highlight-color: transparent !important; } }

.errorBoundaryFallback_mainContainer__2XC41 {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #00171f;
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px); }

.errorBoundaryFallback_responsiveImage__2Ft6Y {
  width: 72.46377vw;
  height: 48.30918vw;
  object-fit: contain; }
  @media (min-width: 1300px) {
    .errorBoundaryFallback_responsiveImage__2Ft6Y {
      width: 30.13021vw;
      height: 25.70312vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .errorBoundaryFallback_responsiveImage__2Ft6Y {
      width: 33.47801vw;
      height: 28.55903vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .errorBoundaryFallback_responsiveImage__2Ft6Y {
      width: 30.13021vw;
      height: 25.70312vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .errorBoundaryFallback_responsiveImage__2Ft6Y {
      width: 60.38647vw;
      height: 40.25765vw; }
      .errorBoundaryFallback_responsiveImage__2Ft6Y else {
        width: 300px; }
      .errorBoundaryFallback_responsiveImage__2Ft6Y else {
        height: 200px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .errorBoundaryFallback_responsiveImage__2Ft6Y {
      width: 48.30918vw;
      height: 32.20612vw; }
      .errorBoundaryFallback_responsiveImage__2Ft6Y else {
        width: 300px; }
      .errorBoundaryFallback_responsiveImage__2Ft6Y else {
        height: 200px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .errorBoundaryFallback_responsiveImage__2Ft6Y {
      width: 42.62575vw;
      height: 28.41716vw; }
      .errorBoundaryFallback_responsiveImage__2Ft6Y else {
        width: 300px; }
      .errorBoundaryFallback_responsiveImage__2Ft6Y else {
        height: 200px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .errorBoundaryFallback_responsiveImage__2Ft6Y {
      width: 38.13883vw;
      height: 25.42588vw; }
      .errorBoundaryFallback_responsiveImage__2Ft6Y else {
        width: 300px; }
      .errorBoundaryFallback_responsiveImage__2Ft6Y else {
        height: 200px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .errorBoundaryFallback_responsiveImage__2Ft6Y {
      width: 34.50656vw;
      height: 23.00437vw; }
      .errorBoundaryFallback_responsiveImage__2Ft6Y else {
        width: 300px; }
      .errorBoundaryFallback_responsiveImage__2Ft6Y else {
        height: 200px; } }

.errorBoundaryFallback_somethingCooking__2zi3P {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  color: #FFFFFF;
  margin-bottom: 0;
  font-size: 8.21256vw;
  line-height: 11.35266vw;
  text-shadow: 3px 3px 0px #218DBF; }
  @media (min-width: 1300px) {
    .errorBoundaryFallback_somethingCooking__2zi3P {
      font-size: 2.8125vw;
      line-height: 4.53125vw;
      text-shadow: 3px 3px 0px #218DBF; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .errorBoundaryFallback_somethingCooking__2zi3P {
      font-size: 3.125vw;
      line-height: 5.03472vw;
      text-shadow: 3px 3px 0px #218DBF; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .errorBoundaryFallback_somethingCooking__2zi3P {
      font-size: 2.8125vw;
      line-height: 4.53125vw;
      text-shadow: 3px 3px 0px #218DBF; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .errorBoundaryFallback_somethingCooking__2zi3P {
      font-size: 6.8438vw;
      line-height: 9.46055vw; }
      .errorBoundaryFallback_somethingCooking__2zi3P else {
        font-size: 34px; }
      .errorBoundaryFallback_somethingCooking__2zi3P else {
        line-height: 47px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .errorBoundaryFallback_somethingCooking__2zi3P {
      font-size: 5.47504vw;
      line-height: 7.56844vw; }
      .errorBoundaryFallback_somethingCooking__2zi3P else {
        font-size: 34px; }
      .errorBoundaryFallback_somethingCooking__2zi3P else {
        line-height: 47px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .errorBoundaryFallback_somethingCooking__2zi3P {
      font-size: 4.83092vw;
      line-height: 6.67803vw; }
      .errorBoundaryFallback_somethingCooking__2zi3P else {
        font-size: 34px; }
      .errorBoundaryFallback_somethingCooking__2zi3P else {
        line-height: 47px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .errorBoundaryFallback_somethingCooking__2zi3P {
      font-size: 4.3224vw;
      line-height: 5.97508vw; }
      .errorBoundaryFallback_somethingCooking__2zi3P else {
        font-size: 34px; }
      .errorBoundaryFallback_somethingCooking__2zi3P else {
        line-height: 47px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .errorBoundaryFallback_somethingCooking__2zi3P {
      font-size: 3.91074vw;
      line-height: 5.40603vw; }
      .errorBoundaryFallback_somethingCooking__2zi3P else {
        font-size: 34px; }
      .errorBoundaryFallback_somethingCooking__2zi3P else {
        line-height: 47px; } }

.errorBoundaryFallback_stayTunned__3nNX6 {
  margin: 0;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.8);
  font-size: 4.83092vw; }
  @media (min-width: 1300px) {
    .errorBoundaryFallback_stayTunned__3nNX6 {
      font-size: 1.30208vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .errorBoundaryFallback_stayTunned__3nNX6 {
      font-size: 1.44676vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .errorBoundaryFallback_stayTunned__3nNX6 {
      font-size: 1.30208vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .errorBoundaryFallback_stayTunned__3nNX6 {
      font-size: 4.02576vw; }
      .errorBoundaryFallback_stayTunned__3nNX6 else {
        font-size: 20px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .errorBoundaryFallback_stayTunned__3nNX6 {
      font-size: 3.22061vw; }
      .errorBoundaryFallback_stayTunned__3nNX6 else {
        font-size: 20px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .errorBoundaryFallback_stayTunned__3nNX6 {
      font-size: 2.84172vw; }
      .errorBoundaryFallback_stayTunned__3nNX6 else {
        font-size: 20px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .errorBoundaryFallback_stayTunned__3nNX6 {
      font-size: 2.54259vw; }
      .errorBoundaryFallback_stayTunned__3nNX6 else {
        font-size: 20px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .errorBoundaryFallback_stayTunned__3nNX6 {
      font-size: 2.30044vw; }
      .errorBoundaryFallback_stayTunned__3nNX6 else {
        font-size: 20px; } }

