@import "scss/size.scss";

.sf-feedback-modal--close-btn{
        cursor: pointer;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        background: #fff;
        right: -16px;
        top: -16px;
        padding: 10px;
        box-sizing: border-box;
        @include resD((
            width: 52px,
            height: 52px,
      

        ));
        svg{
            width: 100%;
            height: 100%;
        }
    }