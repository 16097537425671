@import "scss/size.scss";

.modalPopup{
    font-family: 'Inter';
    position: fixed;
    cursor: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    background: white;
    flex-direction: column;
    align-items: center;
    z-index: 99999999;
    @include resD((
      width: 421px,
      border-radius: 12px,
      height: 400px
    ));
  }
  
.modalOverlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999999;
}

.attendanceText{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: -webkit-fill-available;
  @include resD((
    padding: 12px 16px,
    border-radius: 16px 16px 0px 0px,
  ));
  background: var(--tekie-light-amethyst, #FAF6FF);
}

.profileText{
  display: flex;
  align-items: center;
  flex-shrink: 0;
  @include resD((
    gap: 12px,
  ));
  >p{
    color: #000;
    font-family: Inter;
    font-style: normal;
    line-height: normal;
    @include resD((
      font-weight: 600,
      font-size: 16px,
    ))
  }
}

.usernameContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  @include resD((
    gap: 8px,
    margin-top: 22px,
    margin-left: 45px,
  ));
  >p{
    color: var(--tekie-grey-3, #666);
    font-family: Inter;
    font-style: normal;
    line-height: normal;
    @include resD((
      font-weight: 400,
      font-size: 14px,
    ))
  }
}

.buttonContainer{
  display: flex;
  align-items: space-between;
  width: 85%;
  justify-content: space-between;
  >p{
    color: var(--tekie-grey-3, #666);
    font-family: Inter;
    font-style: normal;
    line-height: normal;
    align-self: center;
    @include resD((
      font-weight: 400,
      font-size: 14px,
    ))
  };
  >h2{
    color: var(--tekie-amethyst, #8C61CB);
    /* Body/Subtext12-medium */
    font-family: Inter;
    font-style: normal;
    justify-content: center;
    align-items: center;
    
    @include resD((
      border-bottom: 1px dashed #8C61CB,
      margin: 0,
      padding: 4px,
      gap: 8px,
      font-weight: 500,
      line-height: 18px,
      font-size: 12px,
    ))    
  }
}

.content, .inputClass{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background: var(--primary-white, #FFF);
  @include resD((
    padding: 12px 24px,
    gap: 8px,
    border-radius: 7.5px,
    border: 1px solid #E2E2E2,
    width:75%,
  ));
  >p{
    color: var(--neutral-emperor-25, #403F3F);
    font-family: Inter;
    font-style: normal;
      @include resD((
        font-weight: 700,
        line-height: 140%,
        font-size: 16px,
      ))
  };
}

.saveChangesButton{
  position: absolute;
  @include resD((
    bottom: 10px,
    right: 11px,
  ));
}

.passwordError{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  width: -webkit-fill-available;
  @include resD((
    margin-top: 12.5px,
    margin-left: 25px,
  ));
  >span{
    color: #F02A13;
    font-family: Inter;
    font-style: normal;
    align-self: center;
    @include resD((
      font-size: 14px,
      font-weight: 400,
      line-height: 120%,
      letter-spacing: -0.28px,
    ));
  }
}