@import '../../../scss/size.scss';
@import '../../../scss/theme.scss';

.code-showcase-row {
  z-index: 0;
  display: flex;
}

.code-showcase-hero-master {
  display: flex;
  @include resD((
    flex-direction: row,
    padding: 0 0 $s-l
  ));
  @include resM((
    flex-direction: column-reverse,
    padding: 0 0 $s-m
  ));
}
.code-showcase-hero-container { 
  font-family: 'Nunito';
  flex: 1 1 50%; 
  color: #403F3F;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @include resD((
    padding: 0,
    padding-right: hs($s-l)
  ));
  @include resM((
    padding: 0px $s-m
  ));

  & .code-showcase-hero-header {
    font-weight: bold;
    
    @include resD((
      font-size: 28px 
    ));
    @include resM((
      font-size: 22px 
    ));
  }

  & .code-showcase-hero-description {
    font-weight: 400;
    text-align: justify;
    @include resD((
      font-size: 20px,
      padding-top: .5rem
    ));
    @include resM((
      font-size: 14px,
      padding-top: .3rem
    ));
  }

  & .code-showcase-hero-btn {
    font-weight: 400;
    padding-top: $s-s;

    @include resD((
      font-size: 16px,
    ));
    @include resD((
      font-size: 14px,
    ));

    & .code-showcase-playground-buttonContainer {
      width: max-content;
      display: flex;
      align-items: center;
      flex-direction: row;
      pointer-events:auto;
      cursor: pointer;
      text-decoration: none;
      background-image: linear-gradient(to top, #00ade6, #34e4ea);
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      padding: 0px 10px;
      opacity: 0.9;
      @include resD((
        border-radius: 50px,
        height: 44px,
      ));
      @include resM((
        border-radius: 50px,
        height: 35px,
      ));

      &:hover {
        opacity: 1;
        box-shadow: 0px 5px 8px 0 rgba(0, 0, 0, 0.16);
      }
    }

    & .code-showcase-buttonText {
      user-select: none;
      font-weight: bold;
      color: #fff;
      text-decoration: none;
      @include resD((
        font-size: 20px,
        margin-left: 12px,
        margin-right: 12px,
        ));
      @include resM((
        font-size: 14px,
        margin-left: 10px,
        margin-right: 10px,
      ));
    }
  }
}

.code-showcase-hero-img-container {
  @include resD((
    flex: 1 1 60%,
    display: flex,
    justify-content: flex-start
  ));
  @include resM((
    flex: 1 1 60%,
    display: flex,
    justify-content: flex-start
  ));
}

.code-showcase-hero-img {
  width: 100%;
  height: 350px;
  background-image: url('../../../assets/theoPoster.svg');
  background-size: contain;
  background-repeat: no-repeat;
  
  @include resD((
    background-position: top right,
    margin: 0
  ));
  @include resM((
    background-position: center,
    margin-bottom: $s-s
  ));

}

@media screen and (min-width: 1440px) {
    .code-showcase-hero-img {
      height: 400px;
    }
}
@media screen and (max-width: 1440px) {
    .code-showcase-hero-img {
      height: 300px;
    }
}
@media screen and (max-width: 1024px) {
  .code-showcase-hero-img {
      height: 250px;
  }
}
@media screen and (max-width: 899px) {
  .code-showcase-hero-img {
      height: 200px;
  }
}
@media screen and (max-width: 768px) {
  .code-showcase-hero-img {
      height: 150px;
  }
}

.code-showcase-master-container {
  @include resD((
    padding: $s-xxl 150px,
  ));
  @include resM((
      padding: 0,
      padding-top: $s-xxl,
  ));

  & > .code-showcase-row {
    position: relative;
    @include resD((
      display: flex
    ));
    @include resM((
      display: block,
    ));
  }
}

.code-showcase-mobile-hero-text {
  @include resD((
    display: none
  ));
  @include resM((
    font-weight: bold,
    line-height: 19px,
    color: $deep-sky-blue,
    opacity: 0.8,
    padding: $s-xxxs $s-s $s-s,
    display: flex,
    justify-content: center,
    align-items: center
  ));
}

.code-showcase-m-rxl {
  @include resD((
    margin-right: $s-xl,
  ));
}

.code-showcase-filter-options {
  box-shadow: $box-shadow;
  @include resD((
    background: $white,
    width: fit-content,
  ));

  @include resM((
    background: rgba(130, 225, 243, 0.23),
    width: 100%,
  ));

  & > .code-showcase-row {
    justify-content: space-evenly;
  }
}

.code-showcase-section-link {
  font-family: Nunito;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  color: $emperor;
  opacity: 0.5;
  @include resD((
    padding: $s-m $s-xm,
    font-size: 20px,
  ));
  @include resM((
    padding: $s-s $s-m,
    font-size: 12px,
  ));

  &:hover, &.code-showcase-option-active {
    opacity: 1;
    color: $deep-sky-blue;
    span {

      @include resD((
        padding: hs($s-xs) 0px,
      ));
      @include resM((
        padding: $s-xxs 0px,
      ));
      border-bottom: 2px solid $deep-sky-blue;
    }
  }
}

.code-showcase-ph-card {
  box-shadow: $box-shadow;
  background: $white;
}

.code-showcase-card-container {
  @include resD((
    min-width: 60vw,
    max-width: 65vw, 
    min-height: 45vh
  ));
  @include resM((
    min-width: 100%, 
    min-height: 100%
  ));
}

.code-showcase-trending-tag-card {
  @include resD((
    display: block
  ));
  @include resM((
    display: none
  ))
}

.code-showcase-divider {
  border-bottom: 1px solid $emperor-86;
  width: 95%;
  height: 2px;
  margin: hs($s-m) auto;
}

.code-showcase-block {
  position: relative;
  & > .code-showcase-row {
    @include resD((
      padding: $s-xm,
      justify-content: flex-start,
      align-items: flex-start,
      flex-direction: row
    ));
    @include resM((
      padding: $s-m 0,
      justify-content: center,
      align-items: center,
      flex-direction: column
    ));
  }

  .code-showcase-divider {
    margin: 0 auto;
  }
  
  &:nth-last-child(1) {
    & > .code-showcase-divider {
      border: none;
    }
  }
}

.code-showcase-code-view {
  height: 15rem;
  padding: $s-s 0; 
  background: #002f3e;
  position: relative;
  overflow: hidden;
  
  @include resD((
    border-radius: 2px 20px 20px 20px,
    margin: .8rem 0rem 0rem, 
  ));
  @include resM((
    margin: .8rem .5rem 0rem, 
    border-radius: 2px,
  ));
}

.code-showcase-author-details {
  color: $emperor;
  font-weight: normal;
  
  @include resD((
    width: 9vw,
    text-align: left,
    margin-right: $s-s
  ));
    @include resM ((
    text-align: center
  ));

  & .code-showcase-author-profile-img-container {
    width: 100%;
    height: 100%;
    justify-content: center;
    
    @include resD((
      display:block
    ));

    @include resM((
      display:flex
    ));

    & .code-showcase-profile-img {
      display: block;
      box-sizing: border-box;
      border-radius: 100%;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-position: top center !important;
      border: 2px solid $swamp;

      @include resD((
        width: 100px,
        height: 100px,
      ));
      @include resM((
        width: 70px,
        height: 70px,
      ));
    }
  }

  & .code-showcase-author-name {
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    line-height: 32px;
    
    @include resD((
      margin-top: hs(10px),
      font-size: 20px
    ));
    @include resM((
      font-size: 18px
    ));
  }
  
  & .code-showcase-author-grade {
    opacity: 0.6;
    
    @include resD((
      line-height: 20px,
      font-size: 18px,
    ));
    @include resM((
      line-height: 18px,
      font-size: 16px
    ));
  }
}

.code-showcase-trending-text {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;

  @include resD((
    font-size: 18px,
    margin: hs($s-m) hs($s-xm),
  ));
  @include resM((
    font-size: 12px,
    margin: hs($s-l),
  ));

  & > div > span {
    @include resD((
      display: block
    ));
    @include resM((
      display: none
    ))
  } 
}

.code-showcase-details {
  width: 100%;
  box-sizing: border-box;

  @include resD((
    padding: 0px,
    margin-top: 0
  ));
  @include resM((
    padding: 0px $s-m,
    margin-top: $s-s
  ));
  
  & .code-showcase-title {
    margin: 0;
    color: $emperor;
    @include resD((
      font-weight: 600,
      font-size: 26px,
    ));
    @include resM((
      font-weight: bold,
      font-size: 22px
    ))
  }

  & .code-showcase-timestamp {
    color: $emperor-66;
    font-family: inherit;
    font-weight: normal;
    letter-spacing: 0.03em;
    margin: 0;

    @include resD((
      font-size: 18px,
    ));
    @include resM((
      font-size: 16px,
    ));
  }

  & .code-showcase-description {
    text-align: justify;
    color: $emperor;
    margin: 0;
    @include resD((
      font-size: 20px,
    ));

    @include resM((
      font-size: 16px,
    ));
  }
}

.code-showcase-details-footer {
  justify-content: space-between;
  align-items: center;
  @include resD((
      padding: $s-s 0 0
  ));
  @include resM((
      padding: $s-s
  ));

  & .code-showcase-reactions {
    user-select: none;
    min-width: hs($s-l);
    color: $emperor;
    text-align: center;
    border-radius: 100px;
    cursor: pointer;
    margin: 0 $s-xxs;
    
    @include resD((
      font-size: hs(18px),
      padding: $s-xs $s-m
    ));
    @include resM((
      font-size: 14px,
      padding: $s-xxs $s-s
    ));

    &.hot.active, &.hot:hover {
      background: #FFE2E0;
    }
    &.heart.active, &.heart:hover {
      background: #FCE3F2;
    }
    &.celebrate.active, &.celebrate:hover{
      background: rgba(249, 231, 63, 0.2);
    }
  }

  & .code-showcase-share-container {
    cursor: pointer;

    @include resD((
      font-size: hs(22px)
    ));
    @include resM((
      font-size: 18px
    ));
  }
}

.code-showcase-tags-wrapper {
  @include resD((
    margin: $s-xs 0px
  ));
  @include resM((
    margin: $s-xs 0px
  ));
}
.code-showcase-tags {
  cursor: pointer;
  border: 1px solid $deep-sky-blue;
  border-radius: 100px;
  width: fit-content;
  height: fit-content;
  color: $deep-sky-blue;
  transition: background-color .3s;
  
  @include resD((
    margin: hs($s-xxs) hs($s-xs) hs($s-xs) 0,
    font-size: hs(18px),
    padding: hs($s-xxs) hs($s-s),
  ));
  @include resM((
    margin: $s-xs $s-xs $s-xxs 0,
    font-size: 16px,
    padding: $s-xxxs $s-s,
  ));
  
  & span {
    font-weight: normal;
    color: $emperor;
  }
  
  &:hover, &.code-showcase-tags-active {
    transition: background-color .3s;
    background-color: $deep-sky-blue;
    color: #fff;
    
    & span {
      color: #fff;
    }
  }
}

.code-showcase-clearTags {
  cursor: pointer;
  border: 1px solid $tomato;
  border-radius: 100px;
  width: fit-content;
  height: fit-content;
  color: $tomato;
  transition: background-color .3s;
  
  @include resD((
    margin: hs($s-xs) hs($s-xxs) hs($s-xxs),
    font-size: hs(18px),
    padding: hs($s-xxs) hs($s-s),
  ));
  @include resM((
    margin: $s-s $s-xs $s-s 0,
    font-size: 16px,
    padding: $s-xxxs $s-s,
  ));
  
  &:hover {
    transition: background-color .3s;
    background-color: $tomato;
    color: $white;
  }
}

.code-showcase-shareIcon {
  display: block;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url('../../../assets/shareCode.svg');
  
  @include resD((
    width: 17px,
    margin-right: hs(10px)
  ));
  @include resM((
    width: 15px,
    margin-right: hsm(8px)
  ));
}

.code-showcase-trendingIcon {
  margin-right: 10px;
  display: block;
  position: relative;
  width: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url('../../../assets/trending.svg');
}

.code-showcase-uhohMsgContainer {
  margin-top: hs(100px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 600;
  color: $swamp;
  opacity: 0.6;
  
  @include resD((
    font-size: hs(30px)
  ));
  @include resM((
    font-size: 18px
  ));
}

.code-showcase-emptyMsgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 500;
  color: $swamp;
  opacity: 0.5;
  line-height: 32px;

  @include resD((
    font-size: hs(26px)
  ));
  @include resM((
    font-size: 18px
  ));
}

.code-showcase-mainContainer {
  width: 100%;
  padding: 1rem 0;
}

.code-showcase-trending-tag-container {
  min-width: 15vw; 
  position: sticky;
  top: 100px;
  padding: .5rem;

  & .code-showcase-trending-header {
    color: $emperor;
    font-family: Nunito;
    font-weight: 600;

    @include resD((
      font-size: 20px,
      padding: $s-xs $s-s,
    ));
  }

  & > .code-showcase-divider { 
    margin: 0 auto $s-xs;
  }

  & .code-showcase-tags-body {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: scroll;
    max-height: 50vh;
    padding: 0 $s-xxs $s-xs;
    
    & .code-showcase-tags {
      @include resD((
        margin: hs($s-xs) hs($s-xs) hs($s-xs) 0,
      ));
    }

    & .code-showcase-uhohMsgContainer {
      margin: 0;
      font-size: hs(22px);
     }
  }

}

.code-showcase-code-view #editor {
  width: inherit !important;
}

.code-showcase-code-view #editor1#editor1, div.interpretor[id^='interpretor'], div.interpretor[id^='__interpretor'] {
  background-color: transparent !important;
}
.code-showcase-code-view .ace_editor {
  background-color: #002f3e !important;
  padding-top: hs(20px) !important;
  box-sizing: border-box;
}

.code-showcase-code-view ::-webkit-scrollbar {
  max-width: hs(10px);
}

.code-showcase-code-view .ace_gutter {
  background-color: transparent !important;
}

.code-showcase-code-view .ace-dracula .ace_gutter-active-line {
  background-color: transparent !important;
}

.code-showcase-code-view .ace-dracula .ace_marker-layer .ace_active-line {
  background-color: transparent !important;
}

.code-showcase-code-view .ace_scrollbar-v::-webkit-scrollbar {
  max-width: hs(2px);
};

.code-showcase-code-view .ace_scrollbar-v::-webkit-scrollbar-thumb {
  background-color: rgba(#34e4ea, 0.5);
  border-radius: 0;
};

.code-showcase-playButton {
  position: absolute;
  background-image: url("../../../assets/playButton.png");
  background-size: 117%;
  background-position: 47% 42%;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  &:hover {
    opacity: 0.5;
  }
  
  @include resD((
    width: 70px,
    height: 70px,
    bottom: $s-s,
    right: $s-s,
  ));
  
  @include resM((
    width: 50px,
    height: 50px,
    bottom: $s-xs,
    right: $s-xs,
  ));
}

.code-showcase-fullScreenIcon {
  background-image: url('../../../assets/fullscreen.svg');
  position: absolute;
  background-size: 100%;
  z-index: 999;
  margin: 8px 12px;
  background-position: 47% 42%;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  &:hover {
    opacity: 0.5;
  }
  
  @include resD((
    width: 28px,
    height: 28px,
    top: $s-s,
    right: $s-s,
  ));
  
  @include resM((
    width: 22px,
    height: 22px,
    top: $s-xs,
    right: $s-xs,
  ));
}