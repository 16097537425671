@import 'scss/size.scss';
.hp-container{
    box-sizing: border-box;
    width: 90%;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    background: #FAFAFA;
    border: 1px solid #A8A7A7;
    color: #504F4F;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    @include resD((
         gap: 11px,
         border-radius: 24px,
         padding-left: 24px,
         padding-right: 24px,

         padding-top: 16px,
         padding-bottom: 16px,
    ));
    &.fromSubmit{
    box-shadow: none;
    border: none;
    padding: 0;

    }
    & .hp-container--title{
        font-weight: 700;
        // font-size: 24px;
        // font-weight: 600;
        // margin-bottom: 16px;
        @include resD((
            font-size: 16px,
        ));
    }
    & .hp-container--progress{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        @include resD(
            (
                gap: 8px,
            )
        );
        &.fromSubmit{
        grid-template-columns: 1fr;
        justify-content: center;

        }
    }
    & .hp-container--progress--bar{
        position: relative;
        height: 100%;
        background: #E6F7FD;
        overflow: hidden;
        border-radius: 24px;
        @include resD((
            height: 14px,
        ));
    }
    & .hp-container--progress--bar--fill{
        position: absolute;
        height: 100%;
        background: #00ADE6;
        border-radius: 24px;
        inset: 0;
    }
    & .hp-container--progress--text{
        width: fit-content;
        margin-inline: auto;
        font-weight: 700;
        color: #858585;
        // font-size: 24px;
        // font-weight: 600;
        // margin-bottom: 16px;
        @include resD((
            font-size: 20px,
        ));
    }
    & .hp-container--button{
        @include resD(
            (
                padding: 16px 20px 16px 24px,
            )
        )
    }
     &.fromSubmit{
        grid-template-columns: 1fr;
        justify-content: center;
        & .hp-container--progress {
            justify-self: center;
        }
    }
}

.submit-btn-skeleton{
    @include resD((
        width: 245px,
        border-radius: 30px,
        height: 47px,
    ));
    overflow: hidden;
}