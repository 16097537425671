@import 'scss/size.scss';


 .input {
    width: hs(200px);
    border: none;
    border-bottom: 1.5px solid #FFFFFF;;
    font-family: Nunito;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #00ADE6;
    text-align: center;
    background-color: inherit;
    @include resD((
      font-size: hs(20px),
    ));
    @include resSm((
      font-size: hs(70px),
    ))
 }

 .input:focus{
    border: none;
    outline: none;
    border-bottom: 1px solid #44e7f7;
 }

 .dropArea{
    width: hs(38px);
    height: hs(28px);
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #1ac9e8;
    display: inline-block;
 }
