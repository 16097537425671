@import 'scss/size.scss';
@import 'scss/utils.scss';
.cheatsheet-topicContainer{
  background-color: #012a38;
  @include resD((
        height: 350px,
        padding: 45px 0 45px 0,
    ));
    @include resM((
        height: 210px,
        padding: 35px 0 35px 0,
    ));
  width: 100%;
  box-sizing: border-box;
}
.cheatsheet-topicContainer{
    *{
        box-sizing: border-box;
    }
}
.cheatsheet-searchbar{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.cheatsheet-modal {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  opacity: 0.32;
  display: flex;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  background-color: rgba(1, 42, 56, 0.5);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 7;
}
.cheatsheet-modal.cheatsheet-showModal {
    opacity: 1;
    overflow: overlay;
    pointer-events: auto;
}
.cheatsheet-modalBody {
  background-color: #fff;
  border-radius: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 15em);
  position: absolute;
  width: 80%;
  @include resD((
    max-width: 686px,
    top: 80px,
  ));
  @include resM((
    max-width: 306px,
    top: 50px,
  ));
  z-index: 9;
}
.cheatsheet-showInput{
    visibility: hidden;
    opacity: 0;
}
.cheatsheet-searchContainer{
    width: 80%;
    z-index: 9;
    background-color: #fefeff;
    border-radius:999px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin: 0 auto;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
    background-color: #fefeff;
    @include resD((
        padding: 10px 20px,
        height: 55px,
        max-width: 686px,
    ));
    @include resM((
        height: 33px,
        padding: 10px,
        max-width: 306px,
    ));
    & > .cheatsheet-inputLoader{
         @include resD((
            height: 25px,
            width: 25px,
        ));
        @include resM((
            height: 15px,
            width: 15px,
        ));
    }
    & svg{
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        filter: brightness(0.5);
        opacity: 0.4;
        @include resD((
            height: 22px,
            width: 22px,
        ));
        @include resM((
            height: 15px,
            width: 15px,
        ));
    }
    & input{
        font-family: Lato;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #403f3f;
        flex: 1;
        border: 0;
        outline: none;
        width: 100%;
        &::placeholder{
            opacity: 0.3;
        }
        @include resD((
            font-size: 20px,
        ));
        @include resM((
           font-size: 12px,
        ));
    }
    & > img{
        filter: brightness(0.5);
        margin-right: 15px;
        opacity: 0.4;
        @include resD((
            height: 27px,
            width: 27px,
        ));
        @include resM((
            height: 15px,
            width: 15px,
        ));
        object-fit: contain;
    }
}
.cheatsheet-modalInput{
    width: 100% !important;
}
.cheatsheet-topicCards{
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border: 2px solid #225169;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    background-color: rgba(10, 49, 69, 1);
    text-align: center;
    transition: all 0.1s ease-in-out;
    @include resD((
        width: 240px,
        height: 140px,
        margin: 0 20px,
        background-position: -140px,
        background-size: 140% 140%,
    ));
    @include resM((
        margin: 0 8px,
        width: 130px,
        height: 90px,
        background-position: -70px,
        background-size: 120% 120%,
    ));
    & .cheatsheet-starIcon{
        position: absolute;
        top: 10px;
        right: 10px;
        @include resD((
            height: 25px,
            width: 25px,
        ));
        @include resM((
            height: 18px,
            width: 18px,
        ));  
        & svg{
            width: 100%;
            height: 100%;
        }
    }
    & h3{
        @include resD((
            font-size: 18px,
        ));
        @include resM((
            font-size: 14px,
        ));
    }
    & a{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        padding: 8px;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: white;
    }
}
.cheatsheet-selectedCard{
    transform: scale(1.2) !important;
    border-radius: 12px;
    box-shadow: 0 4px 12px 0 rgba(7, 43, 61, 0.49);
    position: relative;
     &::before{
        content:"";
        position:absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        border-radius: 12px;
        padding: 3px;
        background:linear-gradient(to top, #35e4e9, #00ade6);
        -webkit-mask: 
            linear-gradient(#fff 0 0) content-box, 
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out; 
        mask-composite: exclude; 
     }
}
.cheatsheet-leftArrow, .cheatsheet-rightArrow{
    padding: 10px;
    position: absolute;
    z-index: 5;
    cursor: pointer;
    width: 60px;
    transition: all 0.1s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to right, #00171f 0, rgba(0, 23, 31, 0) 100%);
    box-sizing: border-box;
    @include resD((
        height: 140px,
    ));
    @include resM((
        height: 90px,
    ));
}
.cheatsheet-leftArrow{
    left: 0;
    filter: blur(0.5);
}
.cheatsheet-rightArrow{
    right: 0;
    background-image: linear-gradient(to right, rgba(0, 23, 31, 0) 0, #00171f 100%);
}
.cheatsheet-leftArrow span:hover, .cheatsheet-rightArrow span:hover{
    transition: all 0.1s ease-in-out;
    transform: scale(1.2);
}
.cheatsheet-rightArrow svg{
    transform: rotateZ(180deg);
}
.cheatsheet-leftArrow svg, .cheatsheet-rightArrow svg{
    height: 20px;
    width: 20px;
}
.cheatsheet-leftArrow svg path, .cheatsheet-rightArrow svg path{
    fill: white
}
.cheatsheet-sliderOuter{
    width: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.1s ease-in-out;
    padding: 0 10px;
}
.cheatsheet-sliderHolder{
    position: relative;
    height: 100%;
    margin: 0 auto;
    transition: all 0.1s ease-in-out;
    margin: 0 auto;
    position: relative;
    width: 100%;
    vertical-align: middle;
    display: -webkit-inline-box;
    overflow-x: scroll;
    overflow-y: hidden;
     @include resD((
        padding-top: 60px,
    ));
    @include resM((
        padding-top: 24px,
    ));
}
.cheatsheet-card{
    border-radius: 10px;
    border: 2px solid #225169;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #225169;
    @include resD((
        width: 240px,
        height: 140px,
        margin: 0 20px,
    ));
    @include resM((
        margin: 0 8px,
        width: 130px,
        height: 90px,
    ));
}
.cheatsheet-loaderContainer{
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    left: 0%;
    align-items: center;
    transition: all .1s ease-in-out;
    height: 100%;
    margin: 0 auto;
    width: 100%;
}
.cheatsheet-modalSearchResult{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.cheatsheet-modalTopicTitle{
    width: 100%;
    opacity: 0.4;
    font-family: Lato;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    text-align: left;
    color: #000000;
    background-color: #A8A7A7;
    text-transform: uppercase;
    @include resD((
        font-size: 16px,
        letter-spacing: 0.48px,
        padding: 10px 23px,
    ));
    @include resM((
        padding: 8px 15px,
        font-size: 11px,
        letter-spacing: 0.45px,
    ));
  
}
.cheatsheet-modalCheatSheetTitle{
    padding: 15px 23px;
    transition: all 0.1s ease-in-out;
    overflow: hidden;
    position: relative;
    width: 100%;
    display: block;
    color: inherit;
    text-decoration: none;
    h3{
        font-family: Nunito;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        color: #00ade6;
        margin: 0;
        display: grid;
        grid-template-columns: 90% 10%;
        justify-content: space-between;
        align-items: end;
        width: 100%;
        @include resD((
            font-size: 24px,
            letter-spacing: 0.72px,
        ));
        @include resM((
            font-size: 16px,
            letter-spacing: 0.5px,
        ));
    }
    p{
        flex-grow: 0;
        font-family: Nunito;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: left;
        margin: 0;
        margin-top: 8px;
        word-wrap: break-word;
        color: #282828;
        display: grid;
        grid-template-columns: 90% 10%;
        justify-content: space-between;
        align-items: end;
        width: 100%;
        @include resD((
            font-size: 20px,
            padding-left: 10px,
        ));
        @include resM((
            font-size: 14px,
            padding-left: 8px,
        ));
    }
    &:hover{
        cursor: pointer;
        opacity: 0.8;
        background-color: whitesmoke;
    }
    & .cheatsheet-starIcon{
        @include resD((
            height: 28px,
            width: 28px,
        ));
        @include resM((
            height: 15px,
            width: 15px,
        ));
        & svg{
            height: 100%;
            width: 100%;
        }
        margin-left:auto;
        cursor: pointer;
        opacity: 0.4;
    }
    & .cheatsheet-line {
        position: absolute;
        bottom: 0;
        left: -16px;
        width: 107%;
        height: 1px;
        background-image: linear-gradient(to right, transparent 27%, #000000 23%);
        background-size: 15px 100%;
    }
}
@media (max-width: 698px) {
.cheatsheet-selectedCard{
    transform: scale(1.11) !important;
    border-radius: 8px;
    &::before{
        border-radius: 8px;
        padding: 1.5px;
    }
}
.cheatsheet-searchContainer > img{
    margin: 0 5px;
}
.cheatsheet-leftArrow, .cheatsheet-rightArrow{
    width: 25px;
}
.cheatsheet-leftArrow svg, .cheatsheet-rightArrow svg{
    height: 10px;
    width: 10px;
}
.cheatsheet-modalCheatSheetTitle{
    padding: 10px 15px;
}}
@media (max-width: 799px) and (min-width: 699px){
.cheatsheet-selectedCard{
    transform: scale(1.15) !important;
    &::before{
        padding: 2px;
    }
}
.cheatsheet-searchContainer > img{
    margin-right: 10px;
}
.cheatsheet-leftArrow, .cheatsheet-rightArrow{
    width: 40px;
}
.cheatsheet-leftArrow svg, .cheatsheet-rightArrow svg{
    height: 12px;
    width: 12px;
}
.cheatsheet-modalCheatSheetTitle{
    padding: 12px 18px;
}
}
@media (max-width: 899px) and (min-width: 800px){
.cheatsheet-selectedCard{
    border: 2px solid #35e4e9 !important;
    &::before{
        padding: 2px;
    }
}
.cheatsheet-searchContainer > img{
    margin-right: 15px;
}
.cheatsheet-leftArrow, .cheatsheet-rightArrow{
    width: 50px;
}
.cheatsheet-leftArrow span:hover, .cheatsheet-rightArrow span:hover{
    transition: all 0.1s ease-in-out;
    transform: scale(1.1);
}
.cheatsheet-leftArrow svg, .cheatsheet-rightArrow svg{
    height: 15px;
    width: 15px;
}
.cheatsheet-modalCheatSheetTitle{
    padding: 15px 20px;
}
}