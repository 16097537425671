@import 'scss/size.scss';

.container {
  background: #eff2f5;
  height: 36px;
  width: 100vw;
  -webkit-app-region: drag;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.emptySpace {
  height: 36px;
  width: 100vw;
}

.toolbarActions {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  margin-left: 35px;
  width: 100%;
}


.systemButtons {
  margin-left: 0px;
  justify-content: flex-end;
  user-select: none;
}

.arrowIcon {
  height: 22px;
  width: 22px; 
  -webkit-app-region: no-drag;
  padding: 3px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 75%;
  border-radius: 50%;
  background-color: rgba(#ffffff, 0);
  &:hover {
    background-color: rgba(#dadada, 1);
  }
  transition: 0.1 all ease-in-out;
}
.arrowLeft {
  @extend .arrowIcon;
  background-image: url('./assets/toolbarWindow/left.svg');
  margin-right: 20px;
}

.arrowRight {
  @extend .arrowIcon;
  margin-right: 20px;
  background-image: url('./assets/toolbarWindow/right.svg') 
}

.reload {
  @extend .arrowIcon;
  background-size: 60%;
  background-image: url('./assets/toolbarWindow/reload.svg') 
}

.systemAction {
  width: 50px;
  height: 100%;
  -webkit-app-region: no-drag;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: #cbcccd;
  }
  &:active {
    background: #979798;
  }

}

@media (-webkit-device-pixel-ratio: 1.5), (device-pixel-ratio: 1.5),
(-webkit-device-pixel-ratio: 2), (device-pixel-ratio: 2),
(-webkit-device-pixel-ratio: 3), (device-pixel-ratio: 3) {
  .systemAction .icon {
    width: 10px;
    height: 10px;
  }
}

.close {
  transition: 0.2s all ease-in-out;
}
.close:hover {
  background: #E81123 !important;
  .icon {
    filter: invert(1);
  }
}


.close:active {
  background: #F1707A !important;
}
.systemAction:hover {
}