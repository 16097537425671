@import "scss/size.scss";

.tooltip {
	position: absolute;
	background: #c1e8f4;
	font-weight: 600;
	color: #023343;
	z-index: 99999999;

	@include resD(
		(
			font-size: 16px,
			line-height: 150%,
			padding: 12px 16px,
			border-radius: 8px,
			// width: 276px,
		)
	);
	& .beak{
		color: #c1e8f4;
	}

}
.secondary{
	background: #403F3F;
	color: #fff;
	& .beak{
		color: #403F3F;
	}

}

.beak {
	position: absolute;
	display: flex;
}

.beakLeft {
	top: -14px;
	right: 10px;
}

.beakRight {
	top: -14px;
	left: 10px;
	transform: rotateY(180deg);
}
.beakBottom{
	top: 100%;
	left: 50%;
	transform: translateX(-50%) rotateX(180deg);

}
.beakMiddleLeft{
    top: 40%;
    right: 99%;
    transform: rotate(0deg);
}
.beakBottomRight{
	top: 100%;
	transform: rotate(180deg);
    right: 10px;

}
.beakBottomLeft{
	top: 100%;
	transform: rotate(180deg);
	left: 15px;
}
.tertiary{
	background-color: #4A336C;
	color: #fff;
	& .beak{
		color: #403F3F;
	}
}
.open{
    opacity: 1;
    pointer-events: all;
}
.close{
    opacity: 0;
    pointer-events: none;
}