@import 'scss/size.scss';
@import 'scss/lato.scss';

.signup-modal-container {
  width: 100vw;
  height: 100vh;
  background: rgba(#000, 0.6);
  position: fixed;
  z-index: 99;
  top: 20px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @include resD((
    padding: unset,
  ));
}

.signup-modal-popup {
  position: relative;
  background: #fff;
  box-shadow: 0px 20px 30px rgba(0, 23, 31, 0.15);
  box-sizing: border-box;
  @include resD((
    width: fit-content,
    border-radius: 12px,
    padding: 0px,
  ));
  @include resM((
    width: 380px,
    border-radius: 12px,
    padding: 24px 15px 0px,
  ));
}

.signup-modal-close {
  position: absolute;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);
  @include resD((
    width: 50px,
    height: 50px,
    top: -50px / 2,
    right: -50px / 2,
  ));
  @include resM((
    width: 30px,
    height: 30px,
    top: -30px / 2,
    right: -20px / 2,
  ));
  transition: 0.3s all ease-in-out;
  &:hover {
    background: #ccf2ff;
    box-shadow: 0px 6px 6px rgba(51, 51, 51, 0.2), 0px 5px 20px rgba(51, 51, 51, 0.08);
  }
}

.signup-modal-closeIcon {
  position: relative;
  @include resD((
    width: 20px,
    height: 20px,
    top: -1px,
  ));
  @include resM((
    width: 12px,
    height: 12px,
    top: -6px,
  ));
  path {
    fill: #00ADE5;
    opacity: 1;
  }
}

.signup-modal-body {
  align-self: center;
  display: flex;
  @include resD((
    background: #fff,
    flex-direction: row,
    min-width: 1250px,
    border-radius: 25px,
    padding: 0px 32px 0px 0px
  ));
  @include resM((
    background: transparent,
    flex-direction: column-reverse,
    padding: 0 10px,
  ))
}